import { gql } from '@apollo/client'

import { ClinicInfoFragment } from '../fragment'

export const createClinicMutation = gql`
  mutation CreateClinic($payload: CreateClinicInput!) {
    createClinic(payload: $payload) {
      ...ClinicInfo
    }
  }
  ${ClinicInfoFragment}
`
