import { gql, useQuery } from '@apollo/client'
import { FormQuery, MyStringParam } from '@sov/common'
import { Link } from '@sov/ui'
import {
  Button,
  Descriptions,
  Form,
  InputNumber,
  Row,
  Space,
  Spin,
  Statistic,
  Typography,
} from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { keys, map, sum, values } from 'ramda'
import React, { useState } from 'react'

import type {
  PatientCostDocs,
  PatientCostPatient,
  PatientCostQuery,
  PatientCostQueryVariables,
  PatientCostStages,
} from '../../../graphql/types'
import {
  StageType,
} from '../../../graphql/types'
import { ClinicSelect, PatientSelect } from '../../components/form/Select'
import Page, { Section } from '../../components/layout/Page'
import type {
  WorkOrderType,
} from './utils'
import {
  getSalaryRate,
  getWorkTimeAndCostMap,
  workOrderTypes,
} from './utils'

const { ClinicLink, DoctorLink, PatientLink } = Link

const workOrderTranslationMap: Record<WorkOrderType, string> = {
  mold: '建模',
  eval: '評估',
  compare: '比對',
  design: '設計',
  print: '製造',
  accessory: '配件',
}

const patientCostQuery = gql`
  query PatientCost($id: ID!, $query: StagesQuery!) {
    patient(id: $id) {
      id
      ...PatientLink
      clinic {
        ...ClinicLink
      }
      doctor {
        ...DoctorLink
      }
    }
    stages(query: $query, limit: 100) {
      docs {
        id
        type
        __typename
        ... on EvalStage {
          isBeforePrint
        }
        tasks {
          type
          startedByEmployee
          completedByOwner
        }
      }
    }
  }
  ${ClinicLink.fragment}
  ${DoctorLink.fragment}
  ${PatientLink.fragment}
`

const formInput = {
  patientId: MyStringParam,
  clinicId: MyStringParam,
}

const defaultSalary = 40000

interface AnalysisPanelProps {
  salary: number
  patientItem: PatientCostPatient
  stagesItem: PatientCostStages
}

function AnalysisPanel(props: AnalysisPanelProps) {
  const { salary, patientItem, stagesItem } = props
  const stages = stagesItem.docs

  const stagesMap: { [key in WorkOrderType]: PatientCostDocs[] } = {
    mold: stages.filter(stage => stage.type === StageType.Mold),
    eval: stages.filter(
      stage =>
        stage.__typename === 'EvalStage' && stage.isBeforePrint === true,
    ),
    compare: stages.filter(
      stage =>
        stage.__typename === 'EvalStage' && stage.isBeforePrint === false,
    ),
    design: stages.filter(stage => stage.type === StageType.Design),
    print: stages.filter(stage => stage.type === StageType.Print),
    accessory: stages.filter(stage => stage.type === StageType.Accessory),
  }

  const workTimeAndCostMap = keys(stagesMap).reduce(
    (accumlateMap, workOrderType) => ({
      ...accumlateMap,
      [workOrderType]: getWorkTimeAndCostMap({
        salaryRate: getSalaryRate(salary),
        stagesMap,
        workOrderType,
      }),
    }),
    {} as { [key in WorkOrderType]: ReturnType<typeof getWorkTimeAndCostMap> },
  )
  const totalCost = sum(
    map(x => x.averageLaborCost * x.count, values(workTimeAndCostMap)),
  )

  return (
    <>
      <Descriptions title="病患基本資料" column={6}>
        <Descriptions.Item label="診所">
          <ClinicLink item={patientItem.clinic} />
        </Descriptions.Item>
        <Descriptions.Item label="姓名">
          <PatientLink item={patientItem} />
        </Descriptions.Item>
        <Descriptions.Item label="醫師">
          <DoctorLink item={patientItem.doctor} />
        </Descriptions.Item>
      </Descriptions>
      <Space direction="vertical" size="large">
        <Descriptions title="工單數量" column={6} bordered>
          {map(
            workOrderType => (
              <Descriptions.Item label={workOrderTranslationMap[workOrderType]}>
                {workTimeAndCostMap[workOrderType].count}
                {' '}
                個
              </Descriptions.Item>
            ),
            workOrderTypes,
          )}
        </Descriptions>
        <Descriptions
          title="各工單總計工時"
          extra={(
            <Typography.Text type="secondary">
              加總「進行」類任務的花費時間
            </Typography.Text>
          )}
          column={6}
          bordered
        >
          {map(
            workOrderType => (
              <Descriptions.Item label={workOrderTranslationMap[workOrderType]}>
                {workTimeAndCostMap[workOrderType].totalWorkTime}
                {' '}
                分鐘
              </Descriptions.Item>
            ),
            workOrderTypes,
          )}
        </Descriptions>
        <Descriptions
          title="各工單平均工時"
          extra={(
            <Typography.Text type="secondary">
              統計工時 / 工單數量
            </Typography.Text>
          )}
          column={6}
          bordered
        >
          {map(
            workOrderType => (
              <Descriptions.Item label={workOrderTranslationMap[workOrderType]}>
                {workTimeAndCostMap[workOrderType].averageWorkTime}
                {' '}
                分鐘
              </Descriptions.Item>
            ),
            workOrderTypes,
          )}
        </Descriptions>
        <Descriptions
          title="各工單平均人工成本"
          extra={(
            <Typography.Text type="secondary">
              平均工時 * 每分工資
            </Typography.Text>
          )}
          column={6}
          bordered
        >
          {map(
            workOrderType => (
              <Descriptions.Item label={workOrderTranslationMap[workOrderType]}>
                $
                {workTimeAndCostMap[workOrderType].averageLaborCost}
              </Descriptions.Item>
            ),
            workOrderTypes,
          )}
        </Descriptions>
        <Row justify="space-between">
          <Statistic title="預估成本" value={totalCost} />
          <Typography.Text type="secondary">
            各類「工單平均人工成本 * 工單數量」的加總
          </Typography.Text>
        </Row>
      </Space>
    </>
  )
}

interface SearchFormProps {
  form: FormInstance
  salary: number
  initialValues: any
  handleReset: () => void
  handleClick: () => void
  handleChangeSalary: (monthlySalary: any) => void
}

function SearchForm(props: SearchFormProps) {
  const {
    form,
    salary,
    initialValues,
    handleReset,
    handleClick,
    handleChangeSalary,
  } = props
  return (
    <Form form={form} initialValues={initialValues} layout="inline">
      <Form.Item name="clinicId" label="診所">
        <ClinicSelect />
      </Form.Item>
      <Form.Item dependencies={['clinicId']} noStyle>
        {() => (
          <Form.Item name="patientId" label="病患">
            <PatientSelect query={{ clinic: form.getFieldValue('clinicId') }} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item label="員工月薪">
        <InputNumber value={salary} onChange={handleChangeSalary} />
      </Form.Item>
      <Form.Item label="每分工資">
        <div>{getSalaryRate(salary)}</div>
      </Form.Item>
      <Button type="primary" onClick={handleClick}>
        取得資料
      </Button>
      <Button onClick={handleReset}>重置</Button>
    </Form>
  )
}

function PatientCost() {
  const [form] = Form.useForm()

  const [salary, setSalary] = useState(defaultSalary)
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)

  const { data, loading } = useQuery<
    PatientCostQuery,
    PatientCostQueryVariables
  >(patientCostQuery, {
    skip: !formQuery.patientId,
    variables: {
      id: formQuery.patientId as string,
      query: {
        patient: formQuery.patientId,
      },
    },
  })

  const initialValues = {
    patientId: formQuery.patientId,
    clinicId: formQuery.clinicId,
  }
  const patientItem = data?.patient
  const stagesItem = data?.stages

  const handleChangeSalary = (monthlySalary) => {
    setSalary(monthlySalary as number)
  }

  const handleClick = () => {
    handleFormChange(form.getFieldsValue())
  }
  const handleReset = () => {
    form.resetFields()
    handleFormReset()
  }

  return (
    <Page>
      <Section flex={0}>
        <Typography.Title level={3}>人工成本計算</Typography.Title>
        <SearchForm
          salary={salary}
          form={form}
          initialValues={initialValues}
          handleClick={handleClick}
          handleReset={handleReset}
          handleChangeSalary={handleChangeSalary}
        />
      </Section>
      <Section>
        {loading
          ? (
            <Spin />
            )
          : (
              patientItem
              && stagesItem && (
                <AnalysisPanel
                  salary={salary}
                  patientItem={patientItem}
                  stagesItem={stagesItem}
                />
              )
            )}
      </Section>
    </Page>
  )
}

export default PatientCost
