import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import React from 'react'

import type {
  AccountBindEntityQuery,
  AccountBindEntityQueryVariables,
} from '../../../../../../graphql/types'
import type { TabKey } from '../../components/TabConfig'
import EntityBindForm from './EntityBindForm'

const accountBindEntityQuery = gql`
  query AccountBindEntity($id: ID!) {
    account(id: $id) {
      ...EntityBindForm
    }
  }
  ${EntityBindForm.fragments.EntityBindForm}
`

interface AccountBindEntityProps {
  accountId: string
  tabKey: TabKey
}

function AccountBindEntity(props: AccountBindEntityProps) {
  const { accountId } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, error, loading, refetch } = useQuery<
    AccountBindEntityQuery,
    AccountBindEntityQueryVariables
  >(accountBindEntityQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: accountId,
    },
  })

  if (loading)
    return null

  if (error) {
    toErrorPage({
      message: error.message,
      redirect: {
        name: '帳戶總覽',
        url: '/accounts',
      },
    })
    return null
  }

  if (!data?.account) {
    toErrorPage({
      message: '不存在的帳戶',
      redirect: {
        name: '帳戶總覽',
        url: '/accounts',
      },
    })
    return null
  }

  const handleBindEntityToAccountCompleted = () => {
    refetch()
  }

  const handleRemoveEntityBindingFromAccountCompleted = () => {
    refetch()
  }

  const account = data?.account

  if (!account)
    return null

  return (
    <EntityBindForm
      account={account}
      onBindEntityToAccountCompleted={handleBindEntityToAccountCompleted}
      onRemoveEntityBindingFromAccountCompleted={
        handleRemoveEntityBindingFromAccountCompleted
      }
    />
  )
}

export default AccountBindEntity
