import { useMutation } from '@apollo/client'
import { Button, Form, Spin, message } from 'antd'
import type { GraphQLError } from 'graphql'
import { includes } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { createClinicMutation } from '../../../graphql/clinic/mutation/create'
import type {
  CreateClinicInput,
  CreateClinicMutation,
  CreateClinicVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
  Country,
} from '../../../graphql/types'
import { OnceButton } from '../../components/common/button'
import type {
  FormGeneralFields,
  FormInitialValues,
} from '../../components/form/clinic'
import FormClinic from '../../components/form/clinic'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import { TaiwanDistricts } from '../../customType'
import { useLoadingLayer } from '../../helpers/hooks'

const FormItem = Form.Item

export function CreateClinic() {
  const [form] = Form.useForm<FormGeneralFields>()
  const history = useHistory()
  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [create] = useMutation<CreateClinicMutation, CreateClinicVariables>(
    createClinicMutation,
  )

  const handleSubmit = async () => {
    setLoadingLayer({ loading: true, tip: '新增中...' })
    try {
      const values = (await form.validateFields()) as CreateClinicInput
      await create({
        variables: {
          payload: values,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已新增診所')
            history.push('/clinics')
          }
        },
      })
    }
    catch (error) {
      const e = error as GraphQLError
      const isDuplicatedClinicNameError = includes(
        'duplicated clinic name',
        e.message,
      )
      if (isDuplicatedClinicNameError === true)
        message.error(`診所名稱重複，建議在診所名之後加上(地區)做區隔`)
      else
        message.error(`新增診所失敗: ${e.message}`)
    }
    setLoadingLayer({ loading: false, tip: '' })
  }

  const formInitialValues: FormInitialValues = {
    name: '',
    fullname: '',
    phone: '',
    email: '',
    location: {
      country: Country.Tw,
      district: TaiwanDistricts.TPE,
      address: '',
    },
    doctors: [],
    accountManagers: [],
    technicians: [],
    sales: [],
    customerService: undefined,
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ClinicCreate' }]}
          />
          <Title route={{ key: 'ClinicCreate' }} />
        </>
      )}
      loading={loading}
      loadingComponent={<Spin size="large" tip={tip} />}
    >
      <Section>
        <Form>
          <FormClinic form={form} initialValues={formInitialValues} />
          <FormItem
            wrapperCol={{ span: 16, offset: 6 }}
            style={{ marginTop: 24 }}
          >
            <OnceButton
              label="新增資料"
              onClick={handleSubmit}
              requiredPrivilege={AllPrivilege.ClinicCreate}
              type="primary"
            />
            <Button style={{ marginLeft: 24 }}>
              <Link to="/clinics">回診所清單</Link>
            </Button>
          </FormItem>
        </Form>
      </Section>
    </Page>
  )
}

export default CreateClinic
