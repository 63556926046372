import { Radio, Space } from 'antd'
import type { RadioGroupProps } from 'antd/lib/radio'
import React from 'react'
import styled from 'styled-components'

import NoteTooltipTitle from './NoteTooltipTitle'
import QuestionIconWithTooltip from './QuestionIconWithTooltip'
import type { HandleInstructionChange } from '.'

enum EvalReportType {
  BrandNew = 'BRAND_NEW',
  Modified = 'MODIFIED',
}

const evalReportTypeMap: Record<EvalReportType, string> = {
  [EvalReportType.BrandNew]: '全新報告 (治療方向跟手段不同)',
  [EvalReportType.Modified]: '修改自舊報告 (治療方向跟手段類似)',
}

const Content = styled.div`
  color: rgba(0, 0, 0, 0.65);
`

const ExplanationContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 18px;
  margin-bottom: 8px;
`

const Explanation = styled.div`
  margin-left: 3px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

const CustomizedRadioGroup = styled(Radio.Group)`
  margin: 8px 0;
`

interface ReportRadioButtonGroupProps {
  handleInstructionChange: HandleInstructionChange
}

function ReportRadioButtonGroup(props: ReportRadioButtonGroupProps) {
  const { handleInstructionChange } = props

  const handleRadioButtonChange: RadioGroupProps['onChange'] = (e) => {
    const selectedEvalReportType: EvalReportType = e.target.value
    const updatedInstruction = evalReportTypeMap[selectedEvalReportType]
    handleInstructionChange(updatedInstruction)
  }

  return (
    <>
      <Content>新病患的評估報告，請填寫醫師的指示。</Content>
      <ExplanationContainer>
        <Explanation>請盡量將指示填寫詳實，可參考建議詢問內容方向</Explanation>
        <QuestionIconWithTooltip title={<NoteTooltipTitle />} />
      </ExplanationContainer>
      <CustomizedRadioGroup onChange={handleRadioButtonChange}>
        <Space direction="vertical" size={8}>
          {Object.keys(evalReportTypeMap).map(evalReportType => (
            <Radio key={evalReportType} value={evalReportType}>
              {evalReportTypeMap[evalReportType]}
            </Radio>
          ))}
        </Space>
      </CustomizedRadioGroup>
    </>
  )
}

export default ReportRadioButtonGroup
