import { useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button } from 'antd'
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { leavesQuery } from '../../../graphql/leave/query/list'
import type {
  LeavesQuery,
  LeavesQueryDocs,
  LeavesQueryQuery,
  LeavesQueryVariables,
} from '../../../graphql/types'
import {
  Role,
} from '../../../graphql/types'
import { authContext } from '../../context'
import { getUserEntityIdFromAuth, isInRoles } from '../../utils'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Page, { Section } from '../layout/Page'
import Title from '../layout/Title'
import LeaveTable from '../table/Leave'

export type LeaveFilterType = Pick<LeavesQuery, 'isApproved'>

export type LeaveSorterField = 'startDate' | 'endDate'

function CalendarButtonLink() {
  return (
    <Button>
      <Link to="/leaves/calendar">請假行事曆</Link>
    </Button>
  )
}

function LeaveIndex() {
  const location = useLocation()
  const auth = useContext(authContext)
  if (!auth)
    return null

  const { tableQuery, paginateQuery, handleTableChange }
    = TableQuery.useTableQuery<LeaveFilterType, LeaveSorterField>({
      sort: '-startDate',
    })

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const list = useQuery<LeavesQueryQuery, LeavesQueryVariables>(leavesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        ...tableQuery.filters,
        employee: isInRoles([Role.God, Role.Manager], auth)
          ? undefined
          : getUserEntityIdFromAuth(auth),
      },
      ...paginateQuery,
    },
  })

  const { data, loading } = list
  const source = data?.leaves ? data.leaves : undefined

  return (
    <Page
      header={(
        <div style={{ display: 'flex' }}>
          <div>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'LeaveList' }]}
            />
            <Title route={{ key: 'LeaveList' }} />
          </div>
          <div>
            <CalendarButtonLink />
          </div>
        </div>
      )}
    >
      <Section>
        <LeaveTable<LeavesQueryDocs>
          currentPathName={location.pathname}
          filterInfo={tableQuery.filters}
          handleChange={handleTableChange}
          loading={loading}
          sortInfo={tableQuery.sort}
          source={source}
        />
      </Section>
    </Page>
  )
}

export default LeaveIndex
