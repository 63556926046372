import './index.less'

import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyArrayParam,
  MyStringParam,
  getDateIntervalQueryString,
} from '@sov/common'
import { Role } from '@sov/common/src/types'
import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import { omit } from 'ramda'
import type { FC } from 'react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactToPrint from 'react-to-print'
import { withDefault } from 'use-query-params'

import type {
  ReportInvoiceQueryQuery,
  ReportInvoiceQueryQueryVariables,
} from '../../../../graphql/types'
import {
  PatientPaymentType,
  PatientSource,
} from '../../../../graphql/types'
import {
  ClinicSelect,
  DoctorSelect,
  EmployeeSelect,
} from '../../../components/form/Select'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { ClinicReport } from './ClinicReport'
import { CustomerServiceReport } from './CustomerServiceReport'

const reportInvoiceQuery = gql`
  query ReportInvoiceQuery(
    $query: InvoiceQuery = {}
    $page: Int = 1
    $limit: Int = 1000
    $sort: String = "-updated"
  ) {
    invoices(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        totalPrice
        tax
        ...ClinicReportInvoiceItem
        ...CustomerServiceReportInvoiceItem
        ... on InvoiceWithStage {
          patient {
            payment {
              type
            }
          }
        }
      }
      total
      limit
      page
    }
  }
  ${ClinicReport.fragments.ClinicReportInvoiceItem}
  ${CustomerServiceReport.fragments.CustomerServiceReportInvoiceItem}
`

enum ReportType {
  Clinic = 'Clinic',
  CustomerService = 'CustomerService',
}

const formInput = {
  reportType: withDefault(MyStringParam, ReportType.Clinic),
  patientSources: MyArrayParam,
  patientPaymentTypes: MyArrayParam,
  clinicId: MyStringParam,
  doctorIds: MyArrayParam,
  customerServiceId: MyStringParam,
  creditDateInterval: withDefault(DateIntervalParam, [
    moment().subtract(1, 'months').startOf('months'),
    moment().subtract(1, 'months').endOf('months'),
  ]),
}

interface FormField {
  reportType?: string
  patientSources?: string[]
  patientPaymentTypes?: string[]
  clinicId?: string
  doctorIds?: string[]
  customerServiceId?: string
  creditDateInterval?: [moment.Moment, moment.Moment]
}

export const ReportInvoice: FC = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [form] = useForm<FormField>()
  const componentRef = useRef<any>()
  const { t } = useTranslation()
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)

  const { data, loading } = useQuery<
    ReportInvoiceQueryQuery,
    ReportInvoiceQueryQueryVariables
  >(reportInvoiceQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    skip: !formQuery.clinicId && !formQuery.customerServiceId,
    variables: {
      query: {
        ...omit(['reportType'], formQuery),
        doctorIds: formQuery.doctorIds as string[],
        patientSources: formQuery.patientSources as PatientSource[],
        patientPaymentTypes:
          formQuery.patientPaymentTypes as PatientPaymentType[],
        creditDateInterval: getDateIntervalQueryString(
          formQuery.creditDateInterval,
        ),
      },
      limit: 1000,
      sort: '-creditDate',
    },
  })

  const docs = data?.invoices?.docs ?? []

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'InvoiceReportAccount' }]}
          />
          <Title route={{ key: 'InvoiceReportAccount' }} />
        </>
      )}
    >
      <Section>
        <Form
          form={form}
          initialValues={formQuery}
          onValuesChange={(changedValues) => {
            if (changedValues.reportType) {
              if (changedValues.reportType === ReportType.Clinic) {
                form.setFieldsValue({ customerServiceId: undefined })
              }
              else {
                form.setFieldsValue({
                  clinicId: undefined,
                  doctorIds: undefined,
                  patientSources: undefined,
                  patientPaymentTypes: undefined,
                })
              }
            }
            handleFormChange(form.getFieldsValue())
          }}
        >
          <Row gutter={16}>
            <Col>
              <Form.Item name="reportType">
                <Select>
                  <Select.Option
                    value={ReportType.Clinic}
                    key={ReportType.Clinic}
                  >
                    應收帳
                  </Select.Option>
                  <Select.Option
                    value={ReportType.CustomerService}
                    key={ReportType.CustomerService}
                  >
                    轉介費
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="creditDateInterval" label="區間">
                <DatePicker.RangePicker
                  picker="date"
                  ranges={{
                    [`${moment()
                      .startOf('months')
                      .subtract(3, 'months')
                      .format('YYYY-MM')}`]: [
                      moment().subtract(3, 'months').startOf('months'),
                      moment().subtract(3, 'months').endOf('months'),
                    ],
                    [`${moment()
                      .startOf('months')
                      .subtract(2, 'months')
                      .format('YYYY-MM')}`]: [
                      moment().subtract(2, 'months').startOf('months'),
                      moment().subtract(2, 'months').endOf('months'),
                    ],
                    [`${moment()
                      .subtract(1, 'months')
                      .startOf('months')
                      .format('YYYY-MM')}`]: [
                      moment().subtract(1, 'months').startOf('months'),
                      moment().subtract(1, 'months').endOf('months'),
                    ],
                    [`${moment().startOf('months').format('YYYY-MM')}`]: [
                      moment().startOf('months'),
                      moment().endOf('months'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
            {formQuery.reportType === ReportType.Clinic && (
              <Col>
                <Form.Item name="patientSources" label="客源">
                  <Select style={{ width: 120 }} allowClear mode="multiple">
                    {Object.values(PatientSource).map(source => (
                      <Select.Option value={source} key={source}>
                        {t(`patient.source.${source}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {formQuery.reportType === ReportType.Clinic && (
              <Col>
                <Form.Item name="patientPaymentTypes" label="付款">
                  <Select style={{ width: 120 }} allowClear mode="multiple">
                    {Object.values(PatientPaymentType).map(type => (
                      <Select.Option value={type} key={type}>
                        {t(`patient.type.${type}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {formQuery.reportType === ReportType.Clinic && (
              <Col>
                <Form.Item name="clinicId" label="診所">
                  <ClinicSelect allowClear style={{ width: 150 }} />
                </Form.Item>
              </Col>
            )}
            {formQuery.reportType === ReportType.Clinic && (
              <Col>
                <Form.Item name="doctorIds" label="醫師">
                  <DoctorSelect
                    mode="multiple"
                    query={{ clinic: formQuery.clinicId }}
                    allowClear
                    style={{ width: 260 }}
                  />
                </Form.Item>
              </Col>
            )}
            {formQuery.reportType === ReportType.CustomerService && (
              <Col>
                <Form.Item name="customerServiceId" label="客服">
                  <EmployeeSelect
                    query={{ roles: [Role.CustomerService] }}
                    allowClear
                    style={{ width: 120 }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col>
              <ReactToPrint
                trigger={() => (
                  <Button className="btn-print" type="primary">
                    列印
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
        </Form>
        {loading
          ? (
            <Spin />
            )
          : (
            <>
              {formQuery.reportType === ReportType.Clinic
              && (formQuery.clinicId
                ? (
                  <div
                    className="invoice-account-table-container"
                    ref={componentRef}
                  >
                    <ClinicReport
                      clinicId={formQuery.clinicId}
                      invoices={docs}
                      creditDateInterval={
                      getDateIntervalQueryString(formQuery.creditDateInterval)!
                    }
                    />
                  </div>
                  )
                : (
                    '請選擇診所'
                  ))}
              {formQuery.reportType === ReportType.CustomerService
              && (formQuery.customerServiceId
                ? (
                  <div
                    className="invoice-account-table-container"
                    ref={componentRef}
                  >
                    <CustomerServiceReport
                      customerServiceId={formQuery.customerServiceId}
                      invoices={docs}
                      creditDateInterval={
                      getDateIntervalQueryString(formQuery.creditDateInterval)!
                    }
                    />
                  </div>
                  )
                : (
                    '請選擇客服'
                  ))}
            </>
            )}
      </Section>
    </Page>
  )
}

export default ReportInvoice
