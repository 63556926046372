import type { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { Input, Spin } from 'antd'
import moment from 'moment'
import {
  addIndex,
  append,
  compose,
  defaultTo,
  isEmpty,
  map,
  pathOr,
  range,
  reduce,
  splitEvery,
  when,
} from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { isEmptyOrNil } from '../../../../utils'
import GoodsCode from '../../../common/GoodsCode'
import type { NewBagLabelPrintData } from './index'

type LabelType = 'StepLabel' | 'InfoLabel'

interface BagLabelsProps {
  printData: NewBagLabelPrintData[]
}

interface BagLabelsPreviewModalProps {
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  className?: string
  loading: boolean
  printData: NewBagLabelPrintData[]
}

interface StepLabelProps {
  id?: string
  description?: string
  isNext?: boolean
}

interface InfoLabelProps {
  stageNumber?: number | string
  clinicName?: string
  patientName?: string
  stageCode?: string
  expectedShippingDate?: Date
  patientCode?: string
  serialNumber?: number
}

interface LabelProps extends InfoLabelProps, StepLabelProps {
  key: string
  labelType: LabelType
}

const { TextArea } = Input

function getSecuredName(name: string | undefined) {
  if (!name)
    return ''
  else
    return `${name.substring(0, 1)}O${name.substring(2, name.length)}`
}

function getStepInitialValue(step) {
  if (typeof step === 'string')
    return step

  const getCleanDescription = (description: string) =>
    description.replace(/\n/, '')

  return `${
    pathOr(false, ['upper'], step)
      ? `上顎：  ${getCleanDescription(pathOr('', ['upper'], step))}\n`
      : ''
  }${
    pathOr(false, ['lower'], step)
      ? `下顎：  ${getCleanDescription(pathOr('', ['lower'], step))}`
      : ''
  }`
}

function InfoLabel(props: InfoLabelProps) {
  const {
    stageNumber,
    clinicName,
    patientName,
    patientCode,
    serialNumber,
    stageCode,
    expectedShippingDate,
  } = props
  // 沒有設定病患代號或者是配件單的話 貨號直接呈現空白
  return (
    <div className="item-header">
      <div className="header-left">
        <span className="wrapper-number-text">{stageNumber}</span>
      </div>

      <div className="header-right">
        <div className="wrapper-info">
          <div className="wrapper-info-row">
            <h4>{clinicName}</h4>
            <h4>
              Step：
              {stageCode}
            </h4>
          </div>
          <h4>{getSecuredName(patientName)}</h4>
          <div className="date">
            製造日期/批號：
            {moment(expectedShippingDate).format('YYYYMMDD')}
          </div>
          <div className="goodsCodeItem">
            貨號：
            {' '}
            <GoodsCode
              patientCode={patientCode!}
              serialNumber={serialNumber!}
              expectedShippingDate={moment(expectedShippingDate)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function StepLabel(props: StepLabelProps) {
  const { id, isNext, description } = props

  return (
    <div className="item-content">
      <div className="step-head">{`${isNext ? '下' : '此'}Step內容`}</div>
      <div className="step-content">
        {String(description)
          .split('\n')
          .map((desc, index) => (
            <div key={`${id}-${index}`}>{desc}</div>
          ))}
      </div>
    </div>
  )
}

const EmptyLabel = () => <div className="item-header" />

function PrintPage({
  pageIndex,
  dataPerPage,
}: {
  pageIndex: number
  dataPerPage: LabelProps[]
}) {
  const mapIndex = addIndex<LabelProps, JSX.Element>(map)
  return (
    <div
      key={`transform-container-${pageIndex}`}
      className="transform-container"
    >
      {mapIndex((item, index) => {
        if (isEmptyOrNil(item))
          return <EmptyLabel key={`${index}-empty`} />

        const { key, labelType, ...restProps } = item
        if (labelType === 'InfoLabel')
          return <InfoLabel key={key} {...restProps} />

        if (labelType === 'StepLabel')
          return <StepLabel key={key} id={key} {...restProps} />

        return <EmptyLabel key={`${index}-fallback-empty`} />
      }, dataPerPage)}
    </div>
  )
}

function NewBagLabels({ printData }: BagLabelsProps) {
  const itemsPerPrintedPage = 30
  const mapIndex = addIndex<LabelProps[], JSX.Element>(map)

  const transformedData = compose<
    NewBagLabelPrintData[],
    (LabelProps | [])[],
      (LabelProps | [])[],
      LabelProps[][]
      >(
      splitEvery(itemsPerPrintedPage),
      when(item => item.length % 3 === 2, append([])),
      reduce<NewBagLabelPrintData, (LabelProps | [])[]>((acc, patientData) => {
      // 這裡是將一筆資料拆成五個列印用的標籤
        if (isEmpty(patientData))
          return [...acc, []]

        const {
          taskId,
          patientName,
          clinicName,
          stageCode,
          patientCode,
          serialNumber,
          expectedShippingDate,
          currentStep,
          nextStep,
        } = patientData
        const patientInfos = map<number, LabelProps>(index => ({
          key: `${taskId}-${index}`,
          id: `${taskId}-${index}`,
          labelType: 'InfoLabel',
          stageNumber: index,
          clinicName,
          patientName,
          stageCode,
          patientCode,
          serialNumber,
          expectedShippingDate,
        }))(range(1, 4))

        return [
          ...acc,
          ...patientInfos,
          {
            key: `${taskId}-currentStep`,
            labelType: 'StepLabel',
            description: getStepInitialValue(currentStep),
          },
          {
            key: `${taskId}-next`,
            labelType: 'StepLabel',
            description: getStepInitialValue(nextStep),
            isNext: true,
          },
        ]
      }, []),
      )(printData)
  /*
   * 這裡的transformedData 為二維陣列是因為每三十個標籤要放在同一頁
   * 如果是60個標籤 會變成[array(30),array(30)]
   */
  const printPages = mapIndex(
    (dataPerPage, pageIndex) => (
      <PrintPage
        key={pageIndex}
        dataPerPage={dataPerPage}
        pageIndex={pageIndex}
      />
    ),
    transformedData,
  )

  return <div className="qc-newbagwrapper-container">{printPages}</div>
}

function UnStyledNewBagLabelsPreviewModal(props: BagLabelsPreviewModalProps) {
  const { getFieldDecorator, className, loading, printData } = props
  const mapIndex = addIndex<NewBagLabelPrintData, JSX.Element>(map)
  return (
    <div className={className}>
      <section>
        <h4 className="patient">病患</h4>
        <h4 className="stage">Step</h4>
        <h4>此 Step 內容</h4>
        <h4>下 Step 內容</h4>
      </section>
      {loading && (
        <div className="spin">
          <Spin />
        </div>
      )}
      {mapIndex((patientItems, index) => {
        const { taskId, patientName, stageCode, currentStep, nextStep }
          = patientItems

        return (
          <section key={`${index}-${taskId}`}>
            <h4 className="patient">{defaultTo('', patientName)}</h4>
            {getFieldDecorator(`${taskId}-stageCode`, {
              initialValue:
                Number.parseInt(stageCode, 10) < 10 ? `0${stageCode}` : stageCode,
            })(<Input className="stage" />)}
            {getFieldDecorator(`${taskId}-step-current`, {
              initialValue: getStepInitialValue(currentStep),
            })(<TextArea rows={2} />)}
            {getFieldDecorator(`${taskId}-step-next`, {
              initialValue: getStepInitialValue(nextStep),
            })(<TextArea rows={2} />)}
          </section>
        )
      }, printData)}
    </div>
  )
}

const NewBagLabelsPreviewModal = styled(UnStyledNewBagLabelsPreviewModal)`
  section {
    display: flex;

    & > * {
      flex: 1;
      margin: 0 6px 6px 0;
    }

    .patient,
    .stage {
      max-width: 80px;
    }
  }

  .spin {
    text-align: center;
  }
`

export { NewBagLabels, NewBagLabelsPreviewModal }
