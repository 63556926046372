import { gql } from '@apollo/client'

import { ProductInfoFragment } from '../fragment'

export const productStatQuery = gql`
  query ProductStatQuery($query: ProductStatQuery) {
    productStat(query: $query) {
      product {
        ...ProductInfo
      }
      count
    }
  }
  ${ProductInfoFragment}
`
