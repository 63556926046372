import { find, isNil, keys } from 'ramda'
import { matchPath } from 'react-router-dom'

import { routeMap } from '../../routes/routes'

/** 根據當前 pathname，找出對應的 route */
export function getMatchRoute<
  Params extends { [K in keyof Params]?: string | undefined },
>(pathname: string) {
  const routeKey = find((key) => {
    const { path } = routeMap[key]
    return !isNil(matchPath(pathname, { path, exact: true }))
  }, keys(routeMap))

  return routeKey
    ? {
        key: routeKey,
        route: routeMap[routeKey],
        matchResult: matchPath<Params>(pathname, {
          path: routeMap[routeKey].path,
          exact: true,
        }),
      }
    : undefined
}
