import { gql } from '@apollo/client'

import { ClinicInfoFragment } from '../fragment'

export const updateClinicMutation = gql`
  mutation UpdateClinic($id: ID!, $payload: UpdateClinicInput!) {
    updateClinic(id: $id, payload: $payload) {
      ...ClinicInfo
    }
  }
  ${ClinicInfoFragment}
`
