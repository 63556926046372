import { EditOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { isEmpty } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import type {
  StageOrderInstructionQuery,
  StageOrderInstructionQueryVariables,
  StageOrderInstructionStage,
} from '../../../../../../graphql/types'
import { Section } from '../../../../../components/layout/Page'
import OrderInstructionModal from '../../../../../components/modal/OrderInstructionModal'
import BoardIcon from './BoardIcon'
import OrangeCircleIcon from './OrangeCircleIcon'

const placeholder = '請填寫指示'

function getOrderFromStage(stage: StageOrderInstructionStage) {
  if (!stage.__typename)
    return undefined

  switch (stage.__typename) {
    case 'EvalStage':
    case 'PrintStage':
    case 'AccessoryStage':
      return stage.order
    case 'DesignStage':
      return stage.latestPrintStage?.order
    case 'MoldStage':
      return stage.latestEvalStage?.order
  }
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`
const Left = styled.div`
  display: flex;
`
const IconContainer = styled.div`
  position: relative;
  margin-right: 24px;
`
const BoardIconContainer = styled.div`
  position: absolute;
  top: 34%;
  left: 37%;
`
const Label = styled.div`
  margin-right: 16px;
  color: rgba(0, 0, 0, 0.65);
`
const Instruction = styled.span`
  color: rgba(0, 0, 0, 0.85);
`
const Placeholder = styled.span`
  color: rgba(0, 0, 0, 0.2);
`

const InstructionInfoSection = styled(Section as any)`
  margin-bottom: 0;
`

interface CustomizedEditOutlinedProps {
  isHovered: boolean
}
const CustomizedEditOutlined = styled(
  EditOutlined,
)<CustomizedEditOutlinedProps>`
  color: ${props => (props.isHovered ? '#1890ff' : 'rgba(0, 0, 0, 0.85)')};

  &:hover {
    color: #1890ff;
  }
`

const fragments = {
  OrderInstruction: gql`
    fragment OrderInstruction on Order {
      id
      type
      instruction
    }
  `,
}

const stageOrderInstructionQuery = gql`
  query StageOrderInstruction($stageId: ID!) {
    stage(id: $stageId) {
      ... on EvalStage {
        order {
          ...OrderInstruction
        }
      }
      ... on PrintStage {
        order {
          ...OrderInstruction
        }
      }
      ... on AccessoryStage {
        order {
          ...OrderInstruction
        }
      }
      ... on DesignStage {
        latestPrintStage {
          id
          order {
            ...OrderInstruction
          }
        }
      }
      ... on MoldStage {
        latestEvalStage {
          id
          order {
            ...OrderInstruction
          }
        }
      }
    }
  }
  ${fragments.OrderInstruction}
`

interface OrderInstructionProps {
  stageId: string
}

function OrderInstruction(props: OrderInstructionProps) {
  const { stageId } = props

  const [isHovered, setIsHovered] = useState(false)
  const handleMouseOver = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const handleOrderInstuctionModalOpen = () => {
    setIsModalVisible(true)
  }
  const handleOrderInstuctionModalClose = () => {
    setIsModalVisible(false)
  }

  const { data, refetch } = useQuery<
    StageOrderInstructionQuery,
    StageOrderInstructionQueryVariables
  >(stageOrderInstructionQuery, {
    variables: { stageId },
  })

  const handleOrderInstuctionModalOk = () => {
    handleOrderInstuctionModalClose()
    refetch()
  }

  const stage = data?.stage

  if (!stage)
    return null

  const order = getOrderFromStage(stage)

  if (!order)
    return null

  return (
    <>
      <InstructionInfoSection>
        <Container
          onClick={handleOrderInstuctionModalOpen}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <Left>
            <IconContainer>
              <OrangeCircleIcon />
              <BoardIconContainer>
                <BoardIcon />
              </BoardIconContainer>
            </IconContainer>
            <Label>訂單指示:</Label>
            {isEmpty(order.instruction)
              ? (
                <Placeholder>{placeholder}</Placeholder>
                )
              : (
                <Instruction>{order.instruction}</Instruction>
                )}
          </Left>
          <div>
            <CustomizedEditOutlined isHovered={isHovered} />
          </div>
        </Container>
      </InstructionInfoSection>
      <OrderInstructionModal
        onOk={handleOrderInstuctionModalOk}
        onCancel={handleOrderInstuctionModalClose}
        selectedOrder={order}
        visible={isModalVisible}
      />
    </>
  )
}

export default OrderInstruction
