import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path

        d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
        fill="#FAAD14"
        stroke="#FAAD14"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path

        d="M9.76078 14C9.96995 14 10.1443 13.9259 10.2837 13.7778C10.2992 13.763 10.3147 13.7482 10.3302 13.7333C10.3457 13.7185 10.3612 13.7037 10.3767 13.6889L17.7907 6.55556C17.9302 6.42222 17.9999 6.25556 17.9999 6.05556C17.9999 5.85556 17.9302 5.68889 17.7907 5.55556C17.6358 5.40741 17.4576 5.33334 17.2562 5.33334C17.0548 5.33334 16.8766 5.40741 16.7216 5.55556L9.74915 12.2667L6.61154 9.26667C6.4566 9.11852 6.27841 9.04445 6.07698 9.04445C5.87556 9.04445 5.69737 9.11852 5.54243 9.26667C5.40298 9.4 5.33325 9.56667 5.33325 9.76667C5.33325 9.96667 5.40298 10.1333 5.54243 10.2667L9.23784 13.7778C9.37729 13.9259 9.5516 14 9.76078 14Z"
        fill="white"
      />
    </svg>
  )
}

const CheckPin = props => <Icon {...props} component={Svg} />

export default CheckPin
