import { gql, useQuery } from '@apollo/client'
import Loadable from '@loadable/component'
import { map, values } from 'ramda'
import type { FC } from 'react'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import type {
  PatientConversationQueryQuery,
  PatientConversationQueryQueryVariables,
} from '../../graphql/types'
import {
  AllPrivilege,
} from '../../graphql/types'
import ConversationDrawer from '../components/common/conversation/ConversationDrawer'
import Layout from '../components/layout'
import Page from '../components/layout/Page'
import PatientPageHeader from '../components/pageHeader/patient'
import type { Route as RouteMap } from './routes'

export const patientRouteWithMenuKeys = [
  'PatientDetail',
  'PatientStageList',
  'PatientAnalysisList',
  'PatientTaskList',
  'PatientInvoiceList',
  'PatientEventList',
  'PatientTracksList',
  'PatientOrdersList',
  'PatientAppointmentsList',
  'StageDetail',
  'StageAnalysis',
  'StageInvoiceDetail',
] as const

export const patientRouteWithoutMenuKeys = [
  'PatientList',
  'PatientListPayment',
  'PatientCreate',
  'PatientTransfer',
  'PatientTreatment',
] as const

export type PatientWithMenuRouteKey = typeof patientRouteWithMenuKeys[number]
export type PatientWithoutMenuRouteKey =
  typeof patientRouteWithoutMenuKeys[number]

export const patientWithMenuRoutes: RouteMap<PatientWithMenuRouteKey> = {
  PatientDetail: {
    path: '/patients/:patientId',
    title: '病患詳情',
    component: Loadable(() => import('../pages/patient/PatientDetail')),
    privileges: [],
  },
  PatientStageList: {
    path: '/patients/:patientId/stages',
    title: '病患工單列表',
    component: Loadable(() => import('../pages/patient/PatientStage/index')),
    privileges: [],
  },
  PatientAnalysisList: {
    path: '/patients/:patientId/analyses',
    title: '病患報告列表',
    component: Loadable(
      () => import('../components/index/patient/PatientAnalysis'),
    ),
    privileges: [],
  },
  PatientTaskList: {
    path: '/patients/:patientId/tasks',
    title: '病患任務列表',
    component: Loadable(
      () => import('../components/index/patient/PatientTask'),
    ),
    privileges: [],
  },
  PatientInvoiceList: {
    path: '/patients/:patientId/invoices',
    title: '病患出貨單列表',
    component: Loadable(() => import('../pages/patient/PatientInvoiceList')),
    privileges: [],
  },
  PatientEventList: {
    path: '/patients/:patientId/events',
    title: '事件列表',
    component: Loadable(() => import('../pages/patient/PatientEventList')),
    privileges: [],
  },
  PatientTracksList: {
    path: '/patients/:patientId/tracks',
    title: '追蹤列表',
    component: Loadable(() => import('../pages/patient/PatientTracksList')),
    privileges: [],
  },
  PatientOrdersList: {
    path: '/patients/:patientId/orders',
    title: '訂單列表',
    component: Loadable(() => import('../pages/patient/PatientOrdersList')),
    privileges: [],
  },
  PatientAppointmentsList: {
    path: '/patients/:patientId/appointments',
    title: '約診列表',
    component: Loadable(
      () => import('../pages/patient/PatientAppointmentsList'),
    ),
    privileges: [],
  },
  StageDetail: {
    path: '/patients/:patientId/stages/:stageId',
    title: '工單詳情',
    component: Loadable(() => import('../pages/stage/StageDetail')),
    privileges: [],
  },
  StageAnalysis: {
    path: '/patients/:patientId/stages/:stageId/analysis',
    title: '工單報告編輯',
    component: Loadable(() => import('../pages/patient/PatientReportEdit')),
    privileges: [],
  },
  StageInvoiceDetail: {
    path: '/patients/:patientId/stages/:stageId/invoice',
    title: '工單出貨資訊',
    component: Loadable(() => import('../pages/invoice/StageInvoiceDetail')),
    privileges: [],
  },
}

export const patientWithoutMenuRoutes: RouteMap<PatientWithoutMenuRouteKey> = {
  PatientList: {
    path: '/patients',
    title: '病患列表',
    component: Loadable(() => import('../pages/patient/PatientList')),
    privileges: [],
  },
  PatientListPayment: {
    path: '/patients/payment',
    title: '病患成本',
    component: Loadable(() => import('../pages/patient/PatientListPayment')),
    privileges: [],
  },
  PatientCreate: {
    path: '/patients/create',
    title: '病患新增',
    component: Loadable(() => import('../components/create/Patient')),
    privileges: [AllPrivilege.PatientCreate],
  },
  PatientTransfer: {
    path: '/patients/transfer',
    title: '病患批次轉移',
    component: Loadable(() => import('../pages/patient/PatientTransfer')),
    // @todo: 高權限者才能看到，但目前沒有對應的權限先用統計權限代替
    privileges: [AllPrivilege.StatisticRead],
  },
  PatientTreatment: {
    path: '/patients/:patientId/treatment',
    title: '病患療程',
    component: Loadable(() => import('../pages/patient/PatientTreatment')),
    privileges: [],
  },
}

const patientConversationQuery = gql`
  query PatientConversationQuery($id: ID!) {
    patient(id: $id) {
      id
      conversation {
        id
      }
    }
  }
`

interface Params {
  patientId: string
}

function Conversation() {
  const match = useRouteMatch<Params>()
  const { patientId } = match.params

  const { data } = useQuery<
    PatientConversationQueryQuery,
    PatientConversationQueryQueryVariables
  >(patientConversationQuery, {
    variables: {
      id: patientId,
    },
  })

  return data?.patient
    ? (
      <ConversationDrawer conversationId={data.patient.conversation.id} />
      )
    : null
}

const PatientRoutes: FC = () => (
  <Switch>
    {map(
      ({ path, component: Component }) => (
        <Route exact key={path} path={path}>
          {(props: any) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        </Route>
      ),
      values(patientWithoutMenuRoutes),
    )}
    <Route path="/patients/:patientId">
      <Layout>
        <Page header={<PatientPageHeader />} headerStyle={{ padding: 0 }}>
          <Switch>
            {map(
              ({ path, component: Component }) => (
                <Route exact key={path} path={path}>
                  {(props: any) => <Component {...props} />}
                </Route>
              ),
              values(patientWithMenuRoutes),
            )}
          </Switch>
        </Page>
      </Layout>
      <Conversation />
    </Route>
  </Switch>
)

export default PatientRoutes
