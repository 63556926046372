import { gql } from '@apollo/client'

export const removeOrderMutation = gql`
  mutation RemoveOrder($id: ID!) {
    removeOrder(id: $id) {
      id
      message
    }
  }
`
