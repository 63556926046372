import { UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { DisplayPatientBrand } from '@sov/ui'
import StageName from '@sov/ui/src/components/StageName'
import {
  Avatar,
  Descriptions,
  Space,
  Tag,
  Timeline,
  Typography,
  message,
} from 'antd'
import moment from 'moment'
import { head, map, tail } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import type {
  ConversationPatientInfoFragment,
  PatientStagePreviewFragment,
  PatientStagePreviewPrintStageInlineFragment,
  UpdatePatientNoteMutation,
  UpdatePatientNoteVariables,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import { EditNoteModal } from './EditNoteModal'

const { Paragraph } = Typography

const ConversationPatientInfoContainer = styled.div`
  padding: 8px 24px;
  margin-left: 5px;
  width: 300px;
  overflow: scroll;
  overscroll-behavior: none;
  height: calc(100vh - 200px);
  border-left: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
`
const Patient = styled.div`
  text-align: center;
`
const PatientName = styled.div`
  margin: 16px auto;
  font-size: 20px;
  font-weight: 500;
`
const BasicInfo = styled.div`
  padding: auto 14px;
`
const TwoCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SectionHeader = styled.div`
  /* padding-top: 24px; */
  padding-bottom: 8px;
  border-bottom: 2px solid #f6f6f6;
`

const SectionContent = styled.div`
  white-space: pre-wrap;
  padding-top: 16px;
`

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
`

const SectionAction = styled.div`
  font-size: 14px;
`

const RecentStages = styled.div`
  margin-top: 24px;
`

const StageExpectedShippingDate = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

const StageDescription = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`
const PatientNotes = styled.div``

const patientStagePreviewFragment = gql`
  fragment PatientStagePreview on Stage {
    __typename
    ... on PrintStage {
      ...StageName
      id
      serialNumber
      note
      expectedShippingDate
    }
  }
  ${StageName.fragment}
`

interface PatientStagePreviewProps {
  patientStages?: PatientStagePreviewFragment[]
}

function PatientStagePreview(props: PatientStagePreviewProps) {
  const { patientStages } = props

  const printStages = patientStages
    ? patientStages.filter(
      (stage): stage is PatientStagePreviewPrintStageInlineFragment =>
        stage.__typename === 'PrintStage',
    )
    : []
  const latestPrintStage = head(printStages)

  return latestPrintStage
    ? (
      <Timeline>
        <Timeline.Item>
          <TwoCol>
            <StageName item={latestPrintStage} showAffix={false} />
            <StageExpectedShippingDate>
              {moment(latestPrintStage?.expectedShippingDate).format(
                'YYYY-MM-DD',
              )}
            </StageExpectedShippingDate>
          </TwoCol>
          <StageDescription>{latestPrintStage?.note}</StageDescription>
        </Timeline.Item>
        {map(
          stage => (
            <Timeline.Item>
              <TwoCol>
                <StageName item={stage} showAffix={false} />
                <StageExpectedShippingDate>
                  {moment(stage.expectedShippingDate).format('YYYY-MM-DD')}
                </StageExpectedShippingDate>
              </TwoCol>
            </Timeline.Item>
          ),
          tail(printStages),
        )}
      </Timeline>
      )
    : (
      <div style={{ marginBottom: '40px' }}>尚無 Step</div>
      )
}

const conversationPatientInfoFragment = gql`
  fragment ConversationPatientInfo on Patient {
    id
    payment {
      brand
      source
      type
    }
    name
    photos {
      frontFace {
        id
        thumbnailPath
      }
    }
    clinic {
      id
      name
    }
    doctor {
      id
      name
    }
    accountManager {
      id
      name
    }
    technician {
      id
      name
    }
    note {
      design
    }
    stages(query: { type: [${StageType.Print}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, page: 1, limit: 4, sort: "-created") {
      docs {
        ...PatientStagePreview
      }
    }
  }
  ${patientStagePreviewFragment}
`

const updatePatientNoteMutation = gql`
  mutation UpdatePatientNote($id: ID!, $payload: UpdatePatientInput!) {
    updatePatient(id: $id, payload: $payload) {
      id
      ...ConversationPatientInfo
    }
  }
  ${conversationPatientInfoFragment}
`

interface ConversationPatientInfoProps {
  patient?: ConversationPatientInfoFragment
}

function ConversationPatientInfo(props: ConversationPatientInfoProps) {
  const { patient } = props
  if (!patient)
    return null

  const patientStages = patient.stages?.docs

  const { t } = useTranslation()
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [updatePatient] = useMutation<
    UpdatePatientNoteMutation,
    UpdatePatientNoteVariables
  >(updatePatientNoteMutation)

  const handleSubmitNote = async (designNote: string) => {
    try {
      const id = patient.id
      await updatePatient({
        variables: {
          id,
          payload: {
            note: {
              design: designNote,
            },
          },
        },
        update: (cache, { data }) => {
          if (data)
            message.info('已更新病患備註')

          setEditModalVisible(false)
        },
      })
    }
    catch (error) {
      message.error(`更新失敗: ${error.message}`)
    }
  }
  return (
    <ConversationPatientInfoContainer>
      <>
        <EditNoteModal
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          onSubmit={handleSubmitNote}
          initialValue={patient.note.design}
        />
        <Patient>
          <Avatar
            icon={<UserOutlined />}
            size={100}
            src={patient.photos.frontFace?.thumbnailPath}
          />
          <PatientName>
            <Link to={`/patients/${patient.id}`}>{patient.name}</Link>
          </PatientName>
        </Patient>
        <BasicInfo>
          <Descriptions
            column={1}
            colon={false}
            contentStyle={{ textAlign: 'right', display: 'inline' }}
          >
            {patient.clinic && (
              <Descriptions.Item label="診所">
                {patient.clinic.name}
              </Descriptions.Item>
            )}
            {patient.doctor && (
              <Descriptions.Item label="醫師">
                {patient.doctor.name}
              </Descriptions.Item>
            )}
            {patient.accountManager && (
              <Descriptions.Item label="AM">
                {patient.accountManager.name}
              </Descriptions.Item>
            )}
            {patient.technician && (
              <Descriptions.Item label="技師">
                {patient.technician.name}
              </Descriptions.Item>
            )}
          </Descriptions>
          <Space>
            <DisplayPatientBrand value={patient.payment.brand} />
            <Tag>{t(`patient.source.${patient.payment.source}`)}</Tag>
            <Tag>{t(`patient.type.${patient.payment.type}`)}</Tag>
          </Space>
        </BasicInfo>
        <RecentStages>
          <SectionHeader>
            <TwoCol>
              <SectionTitle>最近 Step</SectionTitle>
              <Link to={`/patients/${patient.id}/stages`}>
                <SectionAction>查看全部</SectionAction>
              </Link>
            </TwoCol>
          </SectionHeader>
          <SectionContent>
            <PatientStagePreview patientStages={patientStages} />
          </SectionContent>
        </RecentStages>
        <PatientNotes>
          <SectionHeader>
            <TwoCol>
              <SectionTitle>病患備註</SectionTitle>
              <SectionAction onClick={() => setEditModalVisible(true)}>
                <a>編輯備註</a>
              </SectionAction>
            </TwoCol>
          </SectionHeader>
          <SectionContent>
            <Paragraph>{patient.note.design}</Paragraph>
          </SectionContent>
        </PatientNotes>
      </>
    </ConversationPatientInfoContainer>
  )
}

ConversationPatientInfo.fragment = conversationPatientInfoFragment

export default ConversationPatientInfo
