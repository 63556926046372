/* eslint max-len: 'off' */
import React from 'react'

function Normal() {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>normal</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="normal"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-9" transform="translate(3.000000, 8.000000)">
          <rect
            id="Rectangle-9"
            fill="#ED9893"
            x="5"
            y="1"
            width="21"
            height="6"
          />
          <rect
            id="Rectangle-9-Copy"
            fill="#ED9893"
            x="5"
            y="14"
            width="21"
            height="6"
          />
          <polygon
            id="Rectangle-9-Copy-2"
            fill="#D18684"
            points="26 1 31 0 31 6 26 7"
          />
          <polygon
            id="Rectangle-9-Copy-4"
            fill="#D18684"
            points="26 14 31 13 31 19 26 20"
          />
          <polygon
            id="Rectangle-9-Copy-3"
            fill="#D18684"
            points="0 0 5 1 5 7 0 6"
          />
          <polygon
            id="Rectangle-9-Copy-5"
            fill="#D18684"
            points="0 13 5 14 5 20 0 19"
          />
        </g>
      </g>
    </svg>
  )
}

export default Normal
