import { Progress } from 'antd'
import React from 'react'
import styled from 'styled-components'

// import { PatientStatus } from '../../codegen/types'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  margin-right: 8px;
`

interface Props {
  currentStepNumber: number
  finalStepNumber: number
}

function PatientProgress(props: Props) {
  const { currentStepNumber, finalStepNumber } = props
  const percent
    = currentStepNumber <= finalStepNumber
      ? (currentStepNumber / finalStepNumber) * 100
      : 100
  const leftSteps
    = finalStepNumber - currentStepNumber >= 0
      ? finalStepNumber - currentStepNumber
      : 0

  return (
    <Container>
      <Progress
        percent={Math.round(percent)}
        style={{ marginRight: 8 }}
        status="active"
      />
      {leftSteps > 0 && (
        <Title>
          剩下
          {leftSteps}
          {' '}
          Steps
        </Title>
      )}
      {finalStepNumber > 0 && (
        <Title>
          (
          {finalStepNumber}
          {' '}
          Steps)
        </Title>
      )}
    </Container>
  )
}

export { PatientProgress }
