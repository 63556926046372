import React from 'react'

function NoteTooltipTitle() {
  return (
    <div>
      <div>注意事項:</div>
      <div>1. 牙位需使用代號（ex.11.21等）（NG: 左側後牙，前牙區）</div>
      <div>
        2. 說明移動需包含”移動方向“與“移動目標”，移動方向需使用英文專有名詞
        （Ex: Buccal, Extrude,
        Procline等），移動目標需包含相對觀察點或是解剖觀察點（Ex:
        咬合平面，繫帶，中線等）
      </div>
      <div>
        3. 說明轉動需使用英文專有名詞“Rotation”，並指名進心或遠心轉動方向（Ex :
        Mesial in / Distal in等）
      </div>
      <div>
        4. 描述搭配工具請使用英文專有名詞（Elastic / Button / Attachment等）（NG
        : 小豆豆 / 鈕扣）
      </div>
      <div>
        5. 描述牙齒的移動或轉動需有數字或解剖學上的觀察點（Ex:
        上顎11.12.21.22內縮3 mm / 下顎31.32.41.42 Intrude 到跟34.44一樣高等）
      </div>
    </div>
  )
}

export default NoteTooltipTitle
