
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Stage": [
      "AccessoryStage",
      "EvalStage",
      "DesignStage",
      "PrintStage",
      "MoldStage"
    ],
    "Entity": [
      "Patient",
      "Clinic",
      "Doctor",
      "Employee"
    ],
    "Node": [
      "AccessoryStage",
      "Patient",
      "Clinic",
      "Doctor",
      "Employee",
      "Order",
      "Appointment",
      "Leave",
      "EvalStage",
      "Task",
      "CheckPoint",
      "File",
      "DesignStage",
      "PrintStage",
      "SubStage",
      "Wear",
      "MoldStage",
      "PatientConversation",
      "Event",
      "Diary",
      "Track",
      "Account",
      "ConversationsWithLatestMessageNode",
      "Feedback",
      "Product",
      "InvoiceWithStage",
      "InvoiceWithoutStage",
      "MinimumRequiredAppVersionSystem",
      "EntityTypeToPrivilegesListSystem",
      "StageCapacitySystem",
      "RoleToPrivilegesListSystem",
      "StageCostSystem",
      "TextMessage",
      "ImageMessage",
      "AppointmentNotification",
      "NormalNotification"
    ],
    "Schedule": [
      "Appointment"
    ],
    "Invoice": [
      "InvoiceWithStage",
      "InvoiceWithoutStage"
    ],
    "Conversation": [
      "PatientConversation"
    ],
    "Message": [
      "TextMessage",
      "ImageMessage"
    ],
    "Notification": [
      "AppointmentNotification",
      "NormalNotification"
    ],
    "System": [
      "MinimumRequiredAppVersionSystem",
      "EntityTypeToPrivilegesListSystem",
      "StageCapacitySystem",
      "RoleToPrivilegesListSystem",
      "StageCostSystem"
    ]
  }
};
      export default result;
    