import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery, getEmptyText } from '@sov/common'
import { AllPrivilege } from '@sov/common/src/types'
import { Link as SOVLinks, useAuth } from '@sov/ui'
import { Button, Space, Table, Tooltip, message } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type {
  AccountTableInfoFragment,
  AccountsQuery,
  PagedAccount,
} from '../../../../graphql/types'
import {
  EntityType,
} from '../../../../graphql/types'
import AccountAvatar from '../../../components/common/AccountAvatar'
import AccountLink from '../../../components/link/account'
import EmployeeLink from '../../../components/link/employee'

interface VerificationStatusIconProps {
  isVerified: boolean
  style: React.CSSProperties
}
function VerificationStatusIcon(props: VerificationStatusIconProps) {
  const { isVerified, style } = props
  return isVerified
    ? (
      <CheckOutlined style={{ color: '#52c41a', ...style }} />
      )
    : (
      <CloseOutlined style={{ color: '#f5222d', ...style }} />
      )
}

export type AccountFilterType = Pick<AccountsQuery, 'entityType' | 'isActive'>
export type AccountSorterField = 'lastLogin' | 'created'

interface PagedAccountInfo
  extends Pick<PagedAccount, 'total' | 'limit' | 'page'> {
  docs: AccountTableInfoFragment[]
}

const loginByRootMutation = gql`
  mutation LoginByRootMutation($id: ID!) {
    loginByRoot(id: $id) {
      id
    }
  }
`

interface Props {
  handleTableChange: TableProps<AccountTableInfoFragment>['onChange']
  source?: PagedAccountInfo
  loading: boolean
  tableFilterInfo?: AccountFilterType
  sortInfo?: AntSorterType<AccountSorterField>
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

function AccountTable(props: Props) {
  const {
    source = defaultSource,
    loading,
    handleTableChange,
    tableFilterInfo = {},
    sortInfo,
  } = props
  const { user } = useAuth()
  const { t } = useTranslation()

  // 在 Root 帳號下可以直接登入該用戶
  const [login] = useMutation(loginByRootMutation)
  const loginByRoot = async (id: string) => {
    await login({
      variables: {
        id,
      },
      update: async (_cache, { data }) => {
        if (data) {
          await message.info('你已成功登入該用戶, 2 秒後跳轉', 2)
          window.location.replace('/')
        }
      },
    })
  }

  const columns: ColumnProps<AccountTableInfoFragment>[] = [
    {
      title: '綁定身份',
      align: 'left',
      width: 80,
      key: 'entityType',
      dataIndex: 'entityType',
      filters: map(
        x => ({ text: t(`account.entityType.${x}`), value: x }),
        values(EntityType),
      ),
      filteredValue: tableFilterInfo.entityType
        ? [`${tableFilterInfo.entityType}`]
        : [],
      filterMultiple: false,
      render: (text, record) => {
        let display

        switch (record.entity?.__typename) {
          case 'Clinic':
            display = <SOVLinks.ClinicLink item={record.entity} />
            break
          case 'Doctor':
            display = <SOVLinks.DoctorLink item={record.entity} />
            break
          case 'Patient':
            display = <SOVLinks.PatientLink item={record.entity} />
            break
          case 'Employee':
            display = <EmployeeLink item={record.entity} />
            break
          default:
            display = <CloseOutlined style={{ color: '#f5222d' }} />
        }
        return (
          <>
            {t(`account.entityType.${text}`)}
            {' '}
            /
            {display}
          </>
        )
      },
    },
    {
      title: '帳戶名稱',
      align: 'left',
      width: 80,
      key: 'account',
      dataIndex: 'account',
      render: (_text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AccountAvatar
              src={record.avatar?.thumbnailPath}
              entityType={record.entityType}
              size={30}
            />
            <AccountLink item={record} style={{ marginLeft: 8 }} />
          </div>
        )
      },
    },
    {
      title: '驗證信箱/手機',
      align: 'left',
      width: 80,
      key: 'email',
      dataIndex: 'email',
      render: (text, record) => {
        return (
          <>
            {record.email && (
              <div>
                <VerificationStatusIcon
                  style={{ marginRight: '9px' }}
                  isVerified={record.isEmailVerified}
                />
                {record.email ?? getEmptyText()}
              </div>
            )}
            {record.phone && (
              <div>
                <VerificationStatusIcon
                  style={{ marginRight: '9px' }}
                  isVerified={record.isPhoneVerified}
                />
                {record.phone ?? getEmptyText()}
              </div>
            )}
          </>
        )
      },
    },
    {
      title: '狀態',
      align: 'center',
      width: 60,
      key: 'isActive',
      dataIndex: 'isActive',
      filters: [
        {
          text: '使用中',
          value: 'true',
        },
        {
          text: '暫停使用',
          value: 'false',
        },
      ],
      filteredValue:
        tableFilterInfo.isActive !== undefined
          ? [`${tableFilterInfo.isActive}`]
          : [],
      filterMultiple: false,
      render: (_text, record) => (
        <div
          style={{ color: record.isActive ? '#43ba10' : 'rgba(0, 0, 0, 0.25)' }}
        >
          {record.isActive ? '使用中' : '暫停使用'}
        </div>
      ),
    },
    {
      title: '上次登入',
      align: 'center',
      width: 60,
      key: 'lastLogin',
      dataIndex: 'lastLogin',
      sorter: true,
      sortOrder: sortInfo?.field === 'lastLogin' ? sortInfo.order : undefined,
      sortDirections: ['descend', 'ascend'],
      render: text => (
        <Tooltip title={moment(text).format('YYYY-MM-DD HH:mm')}>
          {text ? moment(text).fromNow() : getEmptyText()}
        </Tooltip>
      ),
    },
    {
      title: '新增時間',
      align: 'center',
      width: 60,
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      sortDirections: ['descend', 'ascend'],
      render: text => (
        <Tooltip title={moment(text).format('YYYY-MM-DD HH:mm')}>
          {text ? moment(text).fromNow() : getEmptyText()}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      align: 'center',
      width: 60,
      key: 'action',
      render: (_text, record) => (
        <Space>
          <Button size="small" type="primary">
            <Link to={`/accounts/${record.id}`}>編輯</Link>
          </Button>
          {user?.entityType === EntityType.Employee
          && user.privileges.includes(AllPrivilege.AccountDelete) && (
            <Button
              size="small"
              type="primary"
              danger
              onClick={() => loginByRoot(record.id)}
            >
              登入
            </Button>
          )}
        </Space>
      ),
    },
  ]

  const { docs, page, limit, total } = source

  return (
    <Table<AccountTableInfoFragment>
      size="small"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何帳戶' }}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
      rowKey="id"
    />
  )
}

AccountTable.fragments = {
  AccountTableInfo: gql`
    fragment AccountTableInfo on Account {
      id
      nickname
      email
      isEmailVerified
      phone
      isPhoneVerified
      entityType
      avatar {
        id
        thumbnailPath
      }
      lastLogin
      entity {
        ... on Employee {
          role
          ...EmployeeLink
        }
        ... on Clinic {
          ...ClinicLink
        }
        ... on Doctor {
          ...DoctorLink
        }
        ... on Patient {
          ...PatientLink
        }
      }
      providers {
        facebook {
          id
        }
        google {
          id
        }
        line {
          id
        }
        wechat {
          id
        }
      }
      isActive
      created
    }
    ${SOVLinks.ClinicLink.fragment}
    ${SOVLinks.DoctorLink.fragment}
    ${SOVLinks.PatientLink.fragment}
    ${EmployeeLink.fragment}
  `,
}

export default AccountTable
