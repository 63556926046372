import { gql } from '@apollo/client'
import { Button, Form, Input, Radio, Select, Tag } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { map, path, values, without } from 'ramda'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  AccountFormInfoFragment,
} from '../../../../graphql/types'
import {
  AllPrivilege,
  EntityType,
  Role,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { getAccountIdFromAuth, isInRoles } from '../../../utils'
import SimpleIcon from '../../auth/SimpleIcon'
import { EntitySelect } from '../Select'

const Option = Select.Option
// account.entity query 來會是 object，但在 form 裡頭的值是 ID(string)
export interface AccountFormFields
  extends Omit<AccountFormInfoFragment, '__typename' | 'entity' | 'id'> {
  password: string
  confirm: string
  entity: string
}

const FormItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

type Platform = 'facebook' | 'google' | 'line'

function getSocialBindUrl(platform: Platform) {
  const redirect = window.location.href
  const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}&type=bind`
  return url
}

function getSocialUnbindUrl(platform: Platform) {
  const redirect = window.location.href
  const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}&type=unbind`
  return url
}

interface Props {
  form: FormInstance<AccountFormFields>
  item?: AccountFormInfoFragment
}

function AccountForm(props: Props) {
  const { form, item } = props
  const auth = useContext(authContext)
  const { t } = useTranslation()
  const isSelf = getAccountIdFromAuth(auth) === item?.id
  const isGodOrManager = isInRoles([Role.God, Role.Manager], auth)
  const canUpdatePrivateFields = isSelf || isGodOrManager

  return (
    <Form
      form={form}
      initialValues={{
        entityType: EntityType.Doctor,
      }}
      onValuesChange={(changedValues) => {
        if (changedValues.entityType)
          form.resetFields(['entity'])
      }}
    >
      <Form.Item
        name="entityType"
        label="實體類型"
        rules={[
          {
            required: true,
            message: '必填欄位',
          },
        ]}
        {...FormItemLayout}
      >
        <Radio.Group disabled={Boolean(item)}>
          <Radio key={EntityType.Doctor} value={EntityType.Doctor}>
            {t(`account.entityType.${EntityType.Doctor}`)}
          </Radio>
          <Radio key={EntityType.Clinic} value={EntityType.Clinic}>
            {t(`account.entityType.${EntityType.Clinic}`)}
          </Radio>
          <Radio key={EntityType.Patient} value={EntityType.Patient}>
            {t(`account.entityType.${EntityType.Patient}`)}
          </Radio>
          <Radio key={EntityType.Employee} value={EntityType.Employee}>
            {t(`account.entityType.${EntityType.Employee}`)}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item dependencies={['entityType']} noStyle>
        {(form) => {
          const entityType = form.getFieldValue('entityType')

          return (
            <Form.Item
              name="entity"
              label="對應實體"
              rules={[
                {
                  required: true,
                  message: '必填欄位',
                },
              ]}
              {...FormItemLayout}
            >
              <EntitySelect
                entityType={entityType}
                showSearch
                disabled={item && !isGodOrManager}
                initItem={item?.entity as any}
              />
            </Form.Item>
          )
        }}
      </Form.Item>
      <Form.Item
        {...FormItemLayout}
        label="顯示名稱"
        name="nickname"
        rules={[
          {
            required: true,
            message: '必填欄位',
          },
        ]}
      >
        <Input disabled={item && !isGodOrManager} />
      </Form.Item>
      <Form.Item
        {...FormItemLayout}
        label="電子郵件"
        name="email"
        dependencies={['phone']}
        rules={[
          {
            type: 'email',
            message: '需要 Email 格式',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (value || getFieldValue('phone'))
                return Promise.resolve()

              return Promise.reject(Error('至少要有電子郵件或手機其一'))
            },
          }),
        ]}
      >
        <Input disabled={Boolean(item)} />
      </Form.Item>
      <Form.Item
        name="phone"
        dependencies={['email']}
        rules={[
          {
            min: 10,
            max: 10,
            pattern: new RegExp(/^09\d{8}$/),
            message: '需輸入正確手機',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (value || getFieldValue('email'))
                return Promise.resolve()

              return Promise.reject(Error('至少要有電子郵件或手機其一'))
            },
          }),
        ]}
        {...FormItemLayout}
        label="手機"
      >
        <Input disabled={item && !canUpdatePrivateFields} />
      </Form.Item>
      <Form.Item
        {...FormItemLayout}
        name="password"
        rules={[
          {
            min: 4,
            max: 16,
            pattern: new RegExp('^[a-z0-9]', 'i'),
            message: '需輸入英文或數字 4 到 16 位',
          },
          {
            required: true,
            message: '必填欄位',
          },
        ]}
        label={item ? '重設密碼' : '密碼'}
      >
        <Input.Password disabled={item ? !canUpdatePrivateFields : false} />
      </Form.Item>
      <Form.Item
        {...FormItemLayout}
        name="confirm"
        dependencies={['password']}
        rules={[
          {
            min: 4,
            max: 16,
            pattern: new RegExp('^[a-z0-9]', 'i'),
            message: '需輸入英文或數字 4 到 16 位',
          },
          {
            required: true,
            message: '必填欄位',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value)
                return Promise.resolve()

              return Promise.reject(Error('必須要跟密碼相同'))
            },
          }),
        ]}
        label="確認密碼"
      >
        <Input.Password disabled={item ? !canUpdatePrivateFields : false} />
      </Form.Item>
      {item && (
        <Form.Item {...FormItemLayout} label="角色預設權限">
          {map(
            (x: string) => (
              <Tag key={x}>{t(`account.privileges.${x}`)}</Tag>
            ),
            without(item?.extraPrivileges ?? [], item?.privileges ?? []),
          )}
        </Form.Item>
      )}
      {item && (
        <Form.Item name="extraPrivileges" {...FormItemLayout} label="額外權限">
          <Select
            mode="multiple"
            placeholder="添加額外權限"
            disabled={!isGodOrManager}
          >
            {map(
              x => (
                <Option key={x} value={x}>
                  {t(`account.privileges.${x}`)}
                </Option>
              ),
              values(AllPrivilege),
            )}
          </Select>
        </Form.Item>
      )}
      {item && (
        <Form.Item name="isActive" {...FormItemLayout} label="狀態">
          <Radio.Group disabled={!isGodOrManager}>
            <Radio key="active" value>
              使用中
            </Radio>
            <Radio key="inactive" value={false}>
              已封存
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}
      {item && canUpdatePrivateFields && (
        <Form.Item {...FormItemLayout} label="綁定平台">
          <div>
            {map(
              (platform: Platform) => {
                const btnStyle = {
                  display: 'inline-block',
                  verticalAlign: 'top',
                  marginTop: '3px',
                }
                return (
                  <div key={platform}>
                    {path(['providers', platform, 'id'], item)
                      ? (
                        <div>
                          <SimpleIcon name={platform} />
                          <Button
                            style={btnStyle}
                            size="small"
                            danger
                            href={getSocialUnbindUrl(platform)}
                          >
                            解除綁定
                          </Button>
                        </div>
                        )
                      : (
                        <div>
                          <SimpleIcon name={platform} />
                          <Button
                            style={btnStyle}
                            size="small"
                            type="primary"
                            href={getSocialBindUrl(platform)}
                          >
                            綁定
                          </Button>
                        </div>
                        )}
                  </div>
                )
              },
              ['facebook', 'google', 'line'],
            )}
          </div>
        </Form.Item>
      )}
    </Form>
  )
}

AccountForm.fragments = {
  AccountFormInfo: gql`
    fragment AccountFormInfo on Account {
      id
      email
      phone
      nickname
      backupEmail
      isActive
      privileges
      extraPrivileges
      entityType
      entity {
        ...EntitySelect
      }
      providers {
        facebook {
          id
        }
        google {
          id
        }
        line {
          id
        }
        wechat {
          id
        }
      }
    }
    ${EntitySelect.fragments.EntitySelect}
  `,
}

export default AccountForm
