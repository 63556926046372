import type {
  PossibleTypesMap,
} from '@apollo/client'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import type { WebSocketParams } from '@apollo/client/link/ws'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { message as AntdMessage } from 'antd'
import { createUploadLink } from 'apollo-upload-client'
import { any, either, map, pathEq } from 'ramda'

import type { AuthContextType } from './auth/context'
import customFetch from './customFetch'
import { responseHeaderKey } from './keyOfGitHeadCommitHash'

interface EnvSetting {
  apiHost: string
  socketHost: string
  nodeEnv: string
}

interface Args {
  clientStoredHashUpdatedCallback: (updatedHash: string) => void
  envSetting: EnvSetting
  possibleTypes: PossibleTypesMap
  user?: AuthContextType
}

export function getApolloClient(args: Args) {
  const { clientStoredHashUpdatedCallback, envSetting, possibleTypes, user }
    = args
  const cache = new InMemoryCache({
    possibleTypes,
  })

  // 上傳用
  const uploadLink = createUploadLink({
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
    },
    uri: `${envSetting.apiHost}/graphql`,
    fetch: customFetch as any,
  })

  // 吃到錯誤用
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (
        any(
          either(
            pathEq(['extensions', 'code'], 'FORBIDDEN'),
            pathEq(['extensions', 'code'], 'UNAUTHENTICATED'),
          ),
        )(graphQLErrors)
      )
        window.location.replace('/login')

      map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )
      }, graphQLErrors)
    }

    if (networkError) {
      AntdMessage.error(networkError)
      console.log(`[Network error]: ${networkError}`)
    }
  })

  // Socket 用
  const wsLink = new WebSocketLink({
    uri: `${envSetting.socketHost}/graphql`,
    options: {
      reconnect: true,
      connectionParams: {
        user,
      },
      reconnectionAttempts: 3,
    } as WebSocketParams['options'],
  })

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key, value) =>
        key === '__typename' ? undefined : value
      operation.variables = {
        ...JSON.parse(JSON.stringify(operation.variables), omitTypename),
        ...(operation.variables.file && {
          file: operation.variables.file,
        }),
      }
    }

    if (forward) {
      return forward(operation).map((data) => {
        return data
      })
    }

    return null
  })

  const updateClientStoredHashLink = new ApolloLink((operation, forward) => {
    if (forward) {
      return forward(operation).map((data) => {
        const context = operation.getContext()
        const headers = context.response.headers

        const serverGitCommitHash = headers.get(responseHeaderKey)
        clientStoredHashUpdatedCallback(serverGitCommitHash)

        return data
      })
    }

    return null
  })

  const httpLink = ApolloLink.from([
    errorLink,
    cleanTypeName,
    updateClientStoredHashLink,
    uploadLink,
  ])

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )

  const client = new ApolloClient({
    connectToDevTools: envSetting.nodeEnv === 'development',
    link,
    cache,
  })

  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  }

  return client
}
