import { blue, grey } from '@ant-design/colors'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, getEmptyText } from '@sov/common'
import Analysis from '@sov/ui/src/components/AnalysisPreview/Analysis'
import CheckPointList from '@sov/ui/src/components/AnalysisPreview/CheckPointList'
import StepList from '@sov/ui/src/components/AnalysisPreview/StepList'
import VersionList from '@sov/ui/src/components/AnalysisPreview/VersionList'
import StageLink from '@sov/ui/src/components/Link/Stage'
import StageName from '@sov/ui/src/components/StageName'
import { Button, Col, Row, Space, Spin, Typography } from 'antd'
import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import type {
  PatientAnalysisQueryDocs,
  PatientAnalysisQueryQuery,
  PatientAnalysisQueryVariables,
} from '../../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../../graphql/types'
import Page, { Section } from '../../../layout/Page'

const patientAnalysisQuery = gql`
  query PatientAnalysisQuery($id: ID!) {
    patient(id: $id) {
      id
      currentEvalStage {
        id
        ...StageLink
      }
      stages(query: { type: [${StageType.Eval}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, limit: 20, sort: "-expectedShippingDate") {
        docs {
          __typename
          shippingDate
          ...StageName
          ... on EvalStage {
            isCPMode
            shouldShowCheckPoint
            ...VersionList
            ...CheckPointList
            ...StepList
            ...AnalysisPreviewAnalysis
          }
        }
      }
    }
  }
  ${StageLink.fragment}
  ${StageName.fragment}
  ${Analysis.fragment}
  ${StepList.fragment}
  ${CheckPointList.fragments.CheckPointList}
  ${VersionList.fragments.VersionList}
`

const Label = styled.span`
  color: ${grey.primary};
`
const PageSection = styled(Space)`
  padding-top: 20px;
  width: 100%;
`

interface RouteProps {
  patientId: string
}

function PatientAnalysis() {
  const match = useRouteMatch<RouteProps>()
  const patientId = match.params.patientId

  const [selectedStageId, setSelectedStageId] = useState<string>()

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientAnalysisQueryQuery,
    PatientAnalysisQueryVariables
  >(patientAnalysisQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    onCompleted: (data) => {
      const defaultSelectedStage
        = data.patient?.currentEvalStage ?? data.patient?.stages?.docs?.[0]
      const defaultSelectedStageId = defaultSelectedStage?.id
      setSelectedStageId(defaultSelectedStageId)
    },
    variables: {
      id: patientId,
    },
  })

  const patientItem = data?.patient
  const patientStages = patientItem?.stages?.docs || []
  const evalStageItems = patientStages.filter(
    (
      stage,
    ): stage is Extract<
      PatientAnalysisQueryDocs,
      { __typename: 'EvalStage' }
    > => stage.__typename === 'EvalStage',
  )

  if (loading)
    return <Page loading />

  if (!patientItem)
    return null

  const selectedStage = evalStageItems.find(
    stage => stage.id === selectedStageId,
  )

  const handleChange = (index: number) => {
    const stageId = evalStageItems[index].id
    setSelectedStageId(stageId)
  }

  return (
    <Row>
      <Col span={4}>
        <PageSection
          direction="vertical"
          size="large"
          style={{ paddingLeft: '24px' }}
        >
          <div>
            <Label>主要報告：</Label>
            {patientItem.currentEvalStage
              ? (
                <StageLink
                  item={patientItem.currentEvalStage}
                  style={{ color: blue.primary }}
                />
                )
              : (
                  getEmptyText()
                )}
          </div>
          <VersionList
            currentId={selectedStageId}
            list={evalStageItems}
            handleChange={handleChange}
          />
        </PageSection>
      </Col>
      <Col span={16}>
        <Section>
          {selectedStage
            ? (
              <>
                <Row justify="center">
                  <Typography.Title level={4}>
                    數位矯正報告
                    {' '}
                    <StageName item={selectedStage} />
                  </Typography.Title>
                </Row>
                {selectedStage.isCPMode && selectedStage.shouldShowCheckPoint && (
                  <CheckPointList stage={selectedStage} />
                )}
                <Analysis stage={selectedStage} />
                {!selectedStage.isCPMode && <StepList stage={selectedStage} />}
              </>
              )
            : (
              <Spin />
              )}
        </Section>
      </Col>
      <Col span={4}>
        <PageSection
          direction="vertical"
          style={{ paddingLeft: '12px', paddingRight: '12px' }}
        >
          <a
            href={`https://crm.sov.dental/viewer?patientId=${patientItem.id}&stageId=${selectedStageId}`}
          >
            <Button block>進入 CRM 檢視 Viewer</Button>
          </a>
          <a href={`https://crm.sov.dental/stages/${selectedStageId}`}>
            <Button block>進入 CRM 檢視報告</Button>
          </a>
          <Link to={`/patients/${patientId}/stages/${selectedStageId}`}>
            <Button block>進入報告工單</Button>
          </Link>
        </PageSection>
      </Col>
    </Row>
  )
}

export default PatientAnalysis
