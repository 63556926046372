import { gql, useMutation, useQuery } from '@apollo/client'
import { useErrorHandling } from '@sov/common/src/errorHandling'
import { Form, Select, message } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  CreateAccessoryStageMutation,
  CreateAccessoryStageMutationVariables,
  CreateAccessoryStageQueryQuery,
  CreateAccessoryStageQueryVariables,
} from '../../../../graphql/types'
import {
  AccessoryCode,
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import type {
  BaseModalFormFields,
  BaseModalProps,
} from './BaseModal'
import BaseModal, { formItemLayout } from './BaseModal'

const createAccessoryStage = gql`
  mutation createAccessoryStage($payload: CreateAccessoryStageInput!) {
    createAccessoryStage(payload: $payload) {
      id
      ...ForwardStageTaskForm
    }
  }
  ${BaseModal.fragments.ForwardStageTaskForm}
`

interface AccessoryFormFields extends BaseModalFormFields {
  accessoryCode: AccessoryCode
}
interface AccessoryFormProps {
  form: FormInstance<AccessoryFormFields>
}

const AccessoryForm: React.FC<AccessoryFormProps> = (
  props: AccessoryFormProps,
) => {
  const { form } = props
  const { t } = useTranslation()

  return (
    <Form {...formItemLayout} form={form} requiredMark={false}>
      <Form.Item
        name="accessoryCode"
        rules={[{ required: true }]}
        label="配件編號"
      >
        <Select>
          {map(
            accessoryCode => (
              <Select.Option value={accessoryCode}>
                {t(`stage.accessoryCode.${accessoryCode}`)}
              </Select.Option>
            ),
            values(AccessoryCode),
          )}
        </Select>
      </Form.Item>
    </Form>
  )
}

const createAccessoryStageQuery = gql`
  query CreateAccessoryStageQuery(
    $id: ID!
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      id
      ...BaseModal
    }
  }
  ${BaseModal.fragments.BaseModal}
`

interface CreateAccessoryStageModalProps
  extends Pick<BaseModalProps, 'visible' | 'onCancel' | 'onSubmit'> {
  patientId: string
}

function CreateAccessoryStageModal(props: CreateAccessoryStageModalProps) {
  const { visible, patientId, onCancel, onSubmit } = props

  const { toErrorPage } = useErrorHandling()
  const [create] = useMutation<
    CreateAccessoryStageMutation,
    CreateAccessoryStageMutationVariables
  >(createAccessoryStage)

  const { data, loading } = useQuery<
    CreateAccessoryStageQueryQuery,
    CreateAccessoryStageQueryVariables
  >(createAccessoryStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        type: [
          StageType.Print,
          StageType.Eval,
          StageType.Design,
          StageType.Mold,
        ],
        status: [StageStatus.Completed, StageStatus.Started],
      },
      limit: 5, // 給 stage timeline 使用，只需要 5 個
      sort: '-expectedShippingDate',
    },
    skip: !visible || !patientId,
  })

  const handleCreate = async (
    fieldsValue: AccessoryFormFields & BaseModalFormFields,
  ) => {
    let stageItem
    try {
      await create({
        variables: {
          payload: {
            ...fieldsValue,
            patient: patientId,
          },
        },
        update: (cache, { data }) => {
          if (data?.createAccessoryStage) {
            message.info('已新增配件單，請選擇下個任務負責人')
            stageItem = data.createAccessoryStage
          }
        },
      })
      return stageItem
    }
    catch (error) {
      message.error(`新增失敗: ${error.graphQLErrors[0].message}`, 3)
      onCancel({ shouldRefresh: false })
    }
  }

  const patientItem = data?.patient

  return (
    <>
      <BaseModal
        loading={loading}
        visible={visible}
        patientItem={patientItem}
        stageType={StageType.Accessory}
        getCreatedStage={handleCreate}
        onCancel={onCancel}
        onSubmit={onSubmit}
      >
        {({ form }) => <AccessoryForm form={form} />}
      </BaseModal>
    </>
  )
}

export default CreateAccessoryStageModal
