/* eslint max-len: 'off' */
import React from 'react'

function BothJaw() {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>上下顎</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="上下顎"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="002-teeth-copy-2"
          transform="translate(2.000000, 12.000000)"
          fillRule="nonzero"
        >
          <path
            d="M23.7191724,18.7586207 C19.3131034,18.7586207 18.2102069,18.5853793 17.0554483,14.9451034 C16.214069,12.2918621 16.0242759,8.96827586 16,6.17158621 C15.9884138,4.82868966 17.1657931,2.15724138 18.7453793,1.1062069 C21.3633103,-0.635586207 24.937931,-0.0435862069 27.9144828,0.990344828 C29.8228966,1.65296552 31.6,3.52827586 31.4377931,6.17158621 C31.2584828,9.09627586 30.5550345,12.7652414 29.7395862,15.5862069 C28.9401379,18.3525517 26.2427586,18.7586207 23.7191724,18.7586207 Z"
            id="Shape"
            fill="#E9F0F2"
          />
          <path
            d="M8.28082759,18.7586207 C12.6868966,18.7586207 13.7897931,18.5853793 14.9445517,14.9451034 C15.785931,12.2924138 15.9751724,8.96882759 16,6.17213793 C16.0115862,4.82924138 14.8342069,2.1577931 13.2546207,1.10675862 C10.6366897,-0.635586207 7.06206897,-0.0435862069 4.08551724,0.990344828 C2.17710345,1.65296552 0.4,3.52827586 0.562206897,6.17158621 C0.741517241,9.09627586 1.44496552,12.7652414 2.26041379,15.5862069 C3.05986207,18.3525517 5.75724138,18.7586207 8.28082759,18.7586207 Z"
            id="Shape"
            fill="#E9F0F2"
          />
          <path
            d="M4.99917241,23.1724138 L27.0008276,23.1724138 C29.7616552,23.1724138 32,20.934069 32,18.1732414 L32,12.7966897 C32,12.128 31.4582069,11.5862069 30.7895172,11.5862069 L30.6968276,11.5862069 C30.4198621,12.9914483 30.0921379,14.3685517 29.7401379,15.5862069 C28.9401379,18.3525517 26.2427586,18.7586207 23.7191724,18.7586207 C19.3131034,18.7586207 18.2102069,18.5853793 17.0554483,14.9451034 C16.7255172,13.9051034 16.4982069,12.7608276 16.3393103,11.5862069 L15.6606897,11.5862069 C15.5017931,12.7602759 15.2744828,13.9045517 14.9445517,14.9451034 C13.7897931,18.5853793 12.6868966,18.7586207 8.28082759,18.7586207 C5.75724138,18.7586207 3.05931034,18.3525517 2.25986207,15.5862069 C1.90786207,14.3685517 1.58068966,12.9914483 1.30317241,11.5862069 L1.21048276,11.5862069 C0.541793103,11.5862069 0,12.128 0,12.7966897 L0,18.1732414 C0,20.934069 2.23834483,23.1724138 4.99917241,23.1724138 Z"
            id="Shape"
            fill="#ED9893"
          />
          <path
            d="M12.2383448,3.68717241 C13.6044138,5.19227586 14.0364138,7.19724138 13.4554483,9.1382069 C13.2507586,9.8217931 14.3161379,10.1114483 14.5197241,9.43172414 C15.2148966,7.10896552 14.6438621,4.69737931 13.0190345,2.90703448 C12.5390345,2.37903448 11.7611034,3.16193103 12.2383448,3.68717241 Z"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M3.26841379,6.55117241 C3.27062069,6.55668966 3.27227586,6.56275862 3.27448276,6.56882759 C4.24882759,5.25682759 5.69268966,4.384 7.3257931,4.24827586 C7.66068966,3.5702069 7.92110345,2.92082759 8.00827586,2.35034483 C8.07227586,1.93213793 7.77158621,1.57351724 7.32965517,1.67172414 C5.15255172,2.15613793 2.05296552,3.36165517 2.90262069,6.19365517 C2.96441379,6.39944828 3.10786207,6.50758621 3.26841379,6.55117241 Z"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M27.7351724,3.68717241 C29.1012414,5.19227586 29.5332414,7.19724138 28.9522759,9.1382069 C28.7475862,9.8217931 29.8129655,10.1114483 30.0165517,9.43172414 C30.7117241,7.10896552 30.1406897,4.69737931 28.5158621,2.90703448 C28.0364138,2.37903448 27.2584828,3.16193103 27.7351724,3.68717241 Z"
            id="Shape"
            fill="#FFFFFF"
          />
        </g>
        <g
          id="001-teeth-1-copy-2"
          transform="translate(2.000000, 1.000000)"
          fillRule="nonzero"
        >
          <path
            d="M8.28082759,4.37574316 C12.6868966,4.37574316 13.7897931,4.54749108 14.9445517,8.15638526 C15.785931,10.7862069 15.9751724,14.0811415 16,16.8537218 C16.0115862,18.1850416 14.8342069,20.8334602 13.2546207,21.875434 C10.6372414,23.6016647 7.06206897,23.0147681 4.08551724,21.9897503 C2.17710345,21.3328419 0.4,19.473698 0.562206897,16.8531748 C0.741517241,13.953698 1.44496552,10.3163615 2.26041379,7.51971463 C3.05986207,4.77831153 5.75724138,4.37574316 8.28082759,4.37574316 Z"
            id="Shape"
            fill="#E7ECED"
          />
          <path
            d="M23.7191724,4.37574316 C19.3131034,4.37574316 18.2102069,4.54749108 17.0554483,8.15638526 C16.214069,10.7867539 16.0242759,14.0816885 16,16.8542687 C15.9884138,18.1855886 17.1657931,20.8340071 18.7453793,21.875981 C21.3627586,23.6022117 24.937931,23.0153151 27.9144828,21.9902973 C29.8228966,21.3333888 31.6,19.4742449 31.4377931,16.8537218 C31.2584828,13.9542449 30.5550345,10.3169084 29.7395862,7.52026159 C28.9401379,4.77831153 26.2427586,4.37574316 23.7191724,4.37574316 Z"
            id="Shape"
            fill="#E7ECED"
          />
          <path
            d="M27.0008276,0 L4.99917241,0 C2.23834483,0 0,2.21904875 0,4.9560761 L0,10.2862782 C0,10.9492033 0.541793103,11.4863258 1.21048276,11.4863258 L1.30317241,11.4863258 C1.58013793,10.0931986 1.90786207,8.72796671 2.25986207,7.52080856 C3.05986207,4.77831153 5.75724138,4.37574316 8.28082759,4.37574316 C12.6868966,4.37574316 13.7897931,4.54749108 14.9445517,8.15638526 C15.2744828,9.18741974 15.5017931,10.3218312 15.6606897,11.4863258 L16.3393103,11.4863258 C16.4982069,10.3223781 16.7255172,9.18796671 17.0554483,8.15638526 C18.2102069,4.54749108 19.3131034,4.37574316 23.7191724,4.37574316 C26.2427586,4.37574316 28.9406897,4.77831153 29.7401379,7.52080856 C30.0921379,8.72796671 30.4198621,10.0931986 30.6968276,11.4863258 L30.7895172,11.4863258 C31.4582069,11.4863258 32,10.9492033 32,10.2862782 L32,4.9560761 C32,2.21904875 29.7616552,0 27.0008276,0 Z"
            id="Shape"
            fill="#ED9893"
          />
        </g>
      </g>
    </svg>
  )
}

export default BothJaw
