import { UploadOutlined } from '@ant-design/icons'
import { gql, useApolloClient } from '@apollo/client'
import type { AuthContextType } from '@sov/common'
import { Auth } from '@sov/common'
import { GqlUpload } from '@sov/ui'
import { Button, Typography } from 'antd'
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { includes } from 'ramda'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import type {
  AvatarWithNicknameFragment,
  File,
  UpdateAccountInput,
} from '../../../../../../graphql/types'
import {
  AllPrivilege,
} from '../../../../../../graphql/types'
import AccountAvatar from '../../../../../components/common/AccountAvatar'
import { authContext } from '../../../../../context'

const { Text } = Typography

const AvatarContainer = styled.div`
  margin-top: 15px;
`

const FileUploadContainer = styled.div`
  margin-top: 30px;
`

const NicknameContainer = styled.div`
  margin-top: 24px;
  font-size: 16px;
`

interface Props {
  account: AvatarWithNicknameFragment
  handleSubmit: (payload: UpdateAccountInput) => Promise<void>
}
function AvatarWithNickname(props: Props) {
  const { account, handleSubmit } = props

  const [avatarUploading, setAvatarUploading] = useState(false)
  const client = useApolloClient()
  const auth = useContext<AuthContextType>(authContext)

  const isSelf = account.id === Auth.utils.getAccountIdFromAuth(auth)
  const canUpdate
    = isSelf
    || includes(AllPrivilege.AccountUpdate, Auth.utils.getAuthPrivileges(auth))

  const handleFileChange = async (
    fileInfo: UploadChangeParam<UploadFile<File>>,
  ) => {
    setAvatarUploading(true)
    if (fileInfo.file.response) {
      await handleSubmit({ avatar: fileInfo.file.response.id })
      setAvatarUploading(false)
    }
  }

  const handleNicknameChange = async (nickname: string) => {
    if (nickname)
      await handleSubmit({ nickname })
  }

  return (
    <>
      <AvatarContainer>
        <AccountAvatar
          entityType={account.entityType}
          size={136}
          src={account.avatar?.thumbnailPath}
          loading={avatarUploading}
        />
      </AvatarContainer>
      {canUpdate && (
        <FileUploadContainer>
          <GqlUpload.Upload
            client={client}
            fileType="image"
            uploadOptions={{ needThumbnail: true, prefix: 'account' }}
            accept="image/*"
            onChange={handleFileChange}
            showUploadList={false}
          >
            <Button>
              <UploadOutlined style={{ marginRight: 8 }} />
              上傳照片
            </Button>
          </GqlUpload.Upload>
        </FileUploadContainer>
      )}
      <NicknameContainer>
        <Text
          style={{ color: '#1890ff' }}
          editable={canUpdate ? { onChange: handleNicknameChange } : false}
        >
          {account.nickname}
        </Text>
      </NicknameContainer>
    </>
  )
}

AvatarWithNickname.fragment = gql`
  fragment AvatarWithNickname on Account {
    id
    nickname
    avatar {
      id
      thumbnailPath
    }
    entityType
  }
`

export default AvatarWithNickname
