import './index.less'

import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Input, Space } from 'antd'
import { includes } from 'ramda'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { clinicsQuery } from '../../../graphql/clinic/query/list'
import type {
  ClinicsQueryQuery,
  ClinicsQueryVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
} from '../../../graphql/types'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import ClinicTable from '../../components/table/Clinic'
import { authContext } from '../../context'
import { getAuthPrivileges } from '../../utils'

interface ButtonProps {
  style?: React.CSSProperties
  onClick?: () => void
}

function CreateButtonLink(props: ButtonProps) {
  const { style } = props
  const auth = useContext(authContext)

  if (!includes(AllPrivilege.ClinicCreate, getAuthPrivileges(auth)))
    return null

  return (
    <Button type="primary" style={style}>
      <Link to="/clinics/create">新增診所</Link>
    </Button>
  )
}

function RefreshBtn(props: ButtonProps) {
  const { style, onClick } = props

  return (
    <Button style={style} onClick={onClick}>
      清除
    </Button>
  )
}

function SearchBtn(props: ButtonProps) {
  const { style, onClick } = props

  return (
    <Button style={style} onClick={onClick}>
      搜索
    </Button>
  )
}

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const formInput = {
  name: MyStringParam,
}

interface ClinicFromInput {
  name?: string
}

type ClinicFormProps = {
  handleSearch: (x: FormComponentProps['form']) => void
  handleReset: (x: FormComponentProps['form']) => void
  formQuery: ClinicFromInput
} & FormComponentProps<ClinicFromInput>

const ClinicForm = Form.create<ClinicFormProps>()(
  ({ form, handleSearch, handleReset, formQuery }: ClinicFormProps) => {
    const { getFieldDecorator } = form

    return (
      <Form layout="inline">
        <FormItem {...formItemLayout} label="診所">
          {getFieldDecorator('name', {
            initialValue: formQuery.name,
          })(
            <Input
              style={{ width: 100 }}
              onPressEnter={() => handleSearch(form)}
            />,
          )}
        </FormItem>
        <FormItem className="btns">
          <SearchBtn onClick={() => handleSearch(form)} />
          <RefreshBtn
            style={{ marginLeft: 16 }}
            onClick={() => handleReset(form)}
          />
          <CreateButtonLink style={{ marginLeft: 16 }} />
        </FormItem>
      </Form>
    )
  },
)

// 診所總覽 (route: /clinics)
function ClinicIndex() {
  const { paginateQuery, handleTableChange } = TableQuery.useTableQuery<
    any,
    any
  >()
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)

  const variables = {
    query: {
      name: formQuery.name,
    },
    ...paginateQuery,
  } as ClinicsQueryVariables

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<ClinicsQueryQuery, ClinicsQueryVariables>(
    clinicsQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables,
    },
  )

  const handleSearch = (form: FormComponentProps['form']) => {
    handleFormChange(form.getFieldsValue())
  }

  const handleReset = (form: FormComponentProps['form']) => {
    form.resetFields()
    handleFormReset()
  }

  const source = data?.clinics

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ClinicList' }]}
          />
          <Title route={{ key: 'ClinicList' }} />
        </>
      )}
    >
      <Section>
        <Space direction="vertical">
          <ClinicForm
            handleSearch={handleSearch}
            handleReset={handleReset}
            formQuery={formQuery}
          />
          <ClinicTable
            source={source}
            loading={loading}
            handleTableChange={handleTableChange}
          />
        </Space>
      </Section>
    </Page>
  )
}

export default ClinicIndex
