import {
  all,
  any,
  equals,
  find,
  findIndex,
  map,
  or,
  pick,
  pipe,
  split,
} from 'ramda'

import type {
  Auxiliary,
  ToothStatus,
} from '../../codegen/types'
import {
  AuxiliaryPosition,
  AuxiliaryType,
  ToothPosition,
  ToothStatusType,
} from '../../codegen/types'
import config from './config'

// User-Defined Type Guards function for AuxiliaryType
export function isValidAuxiliaryType<T extends Partial<AuxiliaryType>>(
  type: AuxiliaryType,
  validTypes: T[],
): type is T {
  return any(equals(type), validTypes)
}

// User-Defined Type Guards function for AuxiliaryPosition
export function isValidAuxiliaryPosition<T extends Partial<AuxiliaryPosition>>(
  position: AuxiliaryPosition,
  validPositions: T[],
): position is T {
  return any(equals(position), validPositions)
}

export function getQuadrant(position: ToothPosition) {
  return Number.parseInt(split('_', position)[1], 10)
}
export function getIndex(position: ToothPosition) {
  return Number.parseInt(split('_', position)[2], 10)
}

/* 是否為上排牙齒 */
export function isTopTeeth(position: ToothPosition) {
  return getQuadrant(position) === 1 || getQuadrant(position) === 2
}
export function isRightTeeth(position: ToothPosition) {
  return getQuadrant(position) === 1 || getQuadrant(position) === 4
}
/* 相應的隔壁牙齒index該加或減1 */
export function getNeighborSign(quadrant: number, position: string) {
  const isRight = or(quadrant === 1, quadrant === 4)
  const isR = equals('R', position)
  return isRight ? (isR ? 1 : -1) : isR ? -1 : 1
}

// 根據齒位以及 auxiliary 位置決定 x 軸偏移方向
export function getOffsetXSign(position: ToothPosition, auxiliaryPosition: AuxiliaryPosition.Far | AuxiliaryPosition.Near) {
  if (isRightTeeth(position))
    return auxiliaryPosition === AuxiliaryPosition.Far ? -1 : 1
  else
    return auxiliaryPosition === AuxiliaryPosition.Far ? 1 : -1
}

// 根據齒位以及 auxiliary 位置決定 y 軸偏移方向
export function getOffsetYSign(position: ToothPosition, auxiliaryPosition: AuxiliaryPosition.Incisal | AuxiliaryPosition.Cervical) {
  if (isTopTeeth(position))
    return auxiliaryPosition === AuxiliaryPosition.Incisal ? 1 : -1
  else
    return auxiliaryPosition === AuxiliaryPosition.Incisal ? -1 : 1
}

// 根據齒位, auxiliary 位置以及寬度決定 x 軸偏移值
// 目前只有遠心、近心 x 軸會偏移
export function getOffsetX(position: ToothPosition, auxiliaryPosition: AuxiliaryPosition, width: number) {
  if (
    auxiliaryPosition === AuxiliaryPosition.Far
    || auxiliaryPosition === AuxiliaryPosition.Near
  )
    return width * getOffsetXSign(position, auxiliaryPosition)
  else
    return 0
}

// 根據 auxiliary 位置以及寬度決定 y 軸偏移值
// 目前只有齒頸、切端 y 軸會偏移
export function getOffsetY(position: ToothPosition, auxiliaryPosition: AuxiliaryPosition, height: number) {
  if (
    auxiliaryPosition === AuxiliaryPosition.Incisal
    || auxiliaryPosition === AuxiliaryPosition.Cervical
  )
    return height * getOffsetYSign(position, auxiliaryPosition)
  else
    return 0
}

export const getNeighborPosition: (
  toothPosition: ToothPosition,
  auxiliaryPosition: AuxiliaryPosition.Far | AuxiliaryPosition.Near
) => ToothPosition | null = (toothPosition, auxiliaryPosition) => {
  return config.tooth[toothPosition].neighbor[auxiliaryPosition]
}

export enum StrippingType {
  Left,
  Right,
  Full,
}
export const getStrippingType: (
  type: AuxiliaryType.StrippingAll | AuxiliaryType.StrippingHalf,
  toothPosition: ToothPosition,
  auxiliaryPosition: AuxiliaryPosition.Far | AuxiliaryPosition.Near
) => StrippingType = (type, toothPosition, auxiliaryPosition) => {
  if (type === AuxiliaryType.StrippingAll) {
    return StrippingType.Full
  }
  else {
    const quadrant = getQuadrant(toothPosition)
    if (auxiliaryPosition === AuxiliaryPosition.Near) {
      if (quadrant === 2 || quadrant === 4)
        return StrippingType.Left
      else
        return StrippingType.Right
    }
    else {
      if (quadrant === 2 || quadrant === 4)
        return StrippingType.Right
      else
        return StrippingType.Left
    }
  }
}

export function findIndexFromData<T>(data: T[], value: T) {
  return findIndex(equals(value), data)
}
function isToothStatusType(type: ToothStatusType, toothStatus: ToothStatus) {
  return toothStatus.type === type
}
function isToothStatusPosition(position: ToothPosition, toothStatus: ToothStatus) {
  return toothStatus.position === position
}
export function findCurrentPositionToothStatusTypes(data: ToothStatus[], position: ToothPosition) {
  return Object.values(ToothStatusType).reduce((acc, type) => {
    const toothStatus = find(
      item =>
        isToothStatusType(type, item) && isToothStatusPosition(position, item),
      data,
    )
    return toothStatus ? [...acc, type] : acc
  }, [] as ToothStatusType[])
}

export function newPosToOldPos(quadrant: number, auxiliaryPosition: AuxiliaryPosition.Far | AuxiliaryPosition.Near) {
  const isRight = quadrant === 1 || quadrant === 4
  const isNear = auxiliaryPosition === AuxiliaryPosition.Near
  if (isRight) {
    if (isNear)
      return 'L'
    else
      return 'R'
  }
  else {
    if (isNear)
      return 'R'
    else
      return 'L'
  }
}

/**
 * 只有 11, 21, 31, 41 牙位的近心會跟其他牙位近心重疊
 * 其他牙位的近心，只會跟另一個牙位遠心重疊
 */
function getOverlapAuxiliaryPosition(toothPosition, auxiliaryPosition) {
  if (
    any(equals(toothPosition), [
      ToothPosition.Fdi_1_1,
      ToothPosition.Fdi_2_1,
      ToothPosition.Fdi_3_1,
      ToothPosition.Fdi_4_1,
    ])
    && auxiliaryPosition === AuxiliaryPosition.Near
  ) {
    return AuxiliaryPosition.Near
  }
  else {
    return auxiliaryPosition === AuxiliaryPosition.Near
      ? AuxiliaryPosition.Far
      : AuxiliaryPosition.Near
  }
}

/** 取得可能衝突的牙位及位置 */
function getOverlapAuxiliary(toothPosition: ToothPosition, auxiliaryPosition: AuxiliaryPosition.Far | AuxiliaryPosition.Near) {
  const overlapAuxiliaryPosition = getOverlapAuxiliaryPosition(
    toothPosition,
    auxiliaryPosition,
  )
  const overlapAuxiliaryTooth = getNeighborPosition(
    toothPosition,
    auxiliaryPosition,
  )

  const overlapAuxiliary = {
    toothPosition: overlapAuxiliaryTooth,
    auxiliaryPosition: overlapAuxiliaryPosition,
  }

  return overlapAuxiliary
}
// @todo 等之後改成比較符合新的 toothPosition 的版本
/* 檢查是否有 Stripping 重疊, ex. 11 遠心與 12 近心重疊、或是同樣位置已有 stripping */
export function isValidStripping(allStrippings: Auxiliary[], stripping: Auxiliary) {
  if (
    !isValidAuxiliaryPosition<AuxiliaryPosition.Far | AuxiliaryPosition.Near>(
      stripping.auxiliaryPosition,
      [AuxiliaryPosition.Far, AuxiliaryPosition.Near],
    )
  )
    return false

  const overlapAuxiliary = getOverlapAuxiliary(
    stripping.toothPosition,
    stripping.auxiliaryPosition,
  )
  const selfAuxiliary = {
    toothPosition: stripping.toothPosition,
    auxiliaryPosition: stripping.auxiliaryPosition,
  }

  const result = pipe<
    Auxiliary[],
    Pick<Auxiliary, 'toothPosition' | 'auxiliaryPosition'>[],
    number[]
  >(map(pick(['toothPosition', 'auxiliaryPosition'])), data =>
    map(t => findIndexFromData(data, t), [overlapAuxiliary, selfAuxiliary]))(allStrippings)

  return all(equals(-1), result)
}
