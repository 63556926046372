import { gql } from '@apollo/client'
import { Row, Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import type { CheckPointListFragment } from '../../codegen/types'
import CheckPoint from '../CheckPoint'
import { PageTitle } from './Page'

const Wrapper = styled.div`
  margin: 0 5%;
`
const PreviewCardWrapper = styled(Space)`
  margin: 16px 0;
  width: 100%;
`

interface Props {
  stage: CheckPointListFragment
}

function CheckPointList(props: Props) {
  const { stage } = props

  const checkPoints = stage.checkPoints
  return (
    <Wrapper>
      <PageTitle>預計治療內容</PageTitle>
      <Typography.Text type="secondary">
        (以下為簡略各階段目標，實際移動數量，須待醫師確認最終矯正結果後才進行計算）
      </Typography.Text>

      <Row style={{ margin: '16px' }}>
        <CheckPoint.CheckPointProgress checkPoints={checkPoints} />
      </Row>

      <PreviewCardWrapper direction="vertical" size="large">
        {checkPoints.map(checkPoint => (
          <CheckPoint.PreviewCard
            key={`${checkPoint.serialNumber}-${checkPoint.goalStep}`}
            checkPoint={checkPoint}
          />
        ))}
      </PreviewCardWrapper>
    </Wrapper>
  )
}

CheckPointList.fragments = {
  CheckPointList: gql`
    fragment CheckPointList on EvalStage {
      checkPoints {
        ...PreviewCard
        ...CheckPointProgress
      }
    }
    ${CheckPoint.PreviewCard.fragments.PreviewCard}
    ${CheckPoint.CheckPointProgress.fragments.CheckPointProgress}
  `,
}

export default CheckPointList
