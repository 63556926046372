import { gql } from '@apollo/client'
import { Link } from '@sov/ui'
import { Form, Input, Radio, message } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import React, { useContext, useState } from 'react'

import type {
  Clinic,
  ClinicSelectDocs,
  CreatePatientInput,
  Doctor,
  Employee,
  FormPatientBasicFragment,
} from '../../../../graphql/types'
import {
  Gender,
  Role,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import EmployeeLink from '../../link/employee'
import { ClinicDoctorSelect, ClinicSelect, EmployeeSelect } from '../Select'
import type {
  PaymentFormFields,
  PaymentFormInitialValues,
} from './Payment'
import FormPayment from './Payment'
import { formItemLayout } from './utils/layouts'

const { ClinicLink, DoctorLink } = Link

export type FormGeneralFields = Pick<
  CreatePatientInput,
  | 'name'
  | 'patientCode'
  | 'cabinetCode'
  | 'gender'
  | 'clinic'
  | 'doctor'
  | 'sales'
  | 'technician'
  | 'customerService'
  | 'treatArches'
  | 'note'
  | 'accountManager'
> &
PaymentFormFields

export interface FormGeneralDisplayInfo {
  clinicInfo: FormPatientBasicFragment['clinic']
  doctorInfo: FormPatientBasicFragment['doctor']
  salesInfo: FormPatientBasicFragment['sales']
  technicianInfo?: FormPatientBasicFragment['technician']
  customerServiceInfo?: FormPatientBasicFragment['customerService']
  accountManagerInfo?: FormPatientBasicFragment['accountManager']
}

type FormInitialValue = Partial<FormGeneralFields> & PaymentFormInitialValues
export type FormGeneralInitialValues = FormInitialValue &
  Partial<FormGeneralDisplayInfo>

interface Props {
  form: FormInstance<FormGeneralFields>
  initialValues?: FormGeneralInitialValues
}

type EmployeeSelectType = Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
type DoctorSelect = Pick<Doctor, 'id' | 'name'>
type ClinicSelectType = Pick<Clinic, 'id' | 'name' | 'specialContract'> & {
  accountManagers: EmployeeSelectType[]
  technicians: EmployeeSelectType[]
  sales: EmployeeSelectType[]
  customerService?: EmployeeSelectType
}

function FormPatientBasic(props: Props) {
  const { form, initialValues } = props
  const { setFieldsValue } = form
  const auth = useContext(authContext)
  if (!auth)
    return null

  const [selectedClinic, setSelectedClinic] = useState<
    ClinicSelectType | undefined
  >(initialValues?.clinicInfo)
  const [selectedDoctor, setSelectedDoctor] = useState<
    DoctorSelect | undefined
  >(initialValues?.doctorInfo)
  const [selectedAccountManager, setSelectedAccountManager] = useState<
    EmployeeSelectType | undefined
  >(initialValues?.accountManagerInfo)
  const [selectedTechnician, setSelectedTechnician] = useState<
    EmployeeSelectType | undefined
  >(initialValues?.technicianInfo)
  const [selectedSales, setSelectedSales] = useState<
    EmployeeSelectType | undefined
  >(initialValues?.salesInfo)
  const [selectedCustomerService, setSelectedCustomerService] = useState<
    EmployeeSelectType | undefined
  >(initialValues?.customerServiceInfo)

  // 選擇診所
  const handleClinicSelect = (selectedClinic: ClinicSelectDocs) => {
    setSelectedClinic({
      ...selectedClinic,
      accountManagers: selectedClinic.accountManagers,
      technicians: selectedClinic.technicians,
      sales: selectedClinic.salesList,
      customerService: selectedClinic.customerService,
    })

    if (selectedClinic.accountManagers.length > 0) {
      setSelectedAccountManager(selectedClinic.accountManagers[0])
      setFieldsValue({ accountManager: selectedClinic.accountManagers[0].id })
    }
    else {
      setFieldsValue({ accountManager: undefined })
      message.warning('診所尚未有對應的AM，請先至「診所編輯頁面」新增AM')
    }

    if (selectedClinic.technicians.length > 0) {
      setSelectedTechnician(selectedClinic.technicians[0])
      setFieldsValue({ technician: selectedClinic.technicians[0].id })
    }
    else {
      setFieldsValue({ technician: undefined })
      message.warning('診所尚未有對應的技師，請先至「診所編輯頁面」新增技師')
    }

    if (selectedClinic.salesList.length > 0) {
      setSelectedSales(selectedClinic.salesList[0])
      setFieldsValue({ sales: selectedClinic.salesList[0].id })
    }
    else {
      setFieldsValue({ sales: undefined })
      message.warning('診所尚未有對應的業務，請先至「診所編輯頁面」新增業務')
    }

    if (selectedClinic.customerService) {
      setSelectedCustomerService(selectedClinic.customerService)
      setFieldsValue({ customerService: selectedClinic.customerService.id })
    }
    else {
      setFieldsValue({ customerService: undefined })
    }
  }

  const handleCabinetChange = (rule, value: string, callback) => {
    if (!value || /^\S/.exec(value)) {
      callback()
    }
    else {
      const error = { message: '不能以空格開頭！' }
      callback(error)
    }
  }

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        label="姓名"
        name="name"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <Input width={120} />
      </Form.Item>

      <Form.Item label="代號" name="patientCode">
        <Input width={120} />
      </Form.Item>

      <Form.Item
        label="塔位"
        name="cabinetCode"
        rules={[{ validator: handleCabinetChange }]}
      >
        <Input width={120} />
      </Form.Item>

      <Form.Item label="負責診所" required>
        <Form.Item
          name="clinic"
          rules={[{ required: true, message: '請輸入' }]}
          noStyle
        >
          <ClinicSelect handleItemSelect={handleClinicSelect} />
        </Form.Item>
        {selectedClinic && (
          <ClinicLink item={selectedClinic} style={{ color: 'black' }}>
            <i className="fa fa-external-link" />
          </ClinicLink>
        )}
      </Form.Item>

      <Form.Item label="負責醫生" required>
        <Form.Item
          name="doctor"
          rules={[{ required: true, message: '請輸入' }]}
          noStyle
        >
          <ClinicDoctorSelect
            disabled={!selectedClinic?.id}
            handleItemSelect={setSelectedDoctor}
            query={{ clinic: selectedClinic?.id }}
          />
        </Form.Item>
        {selectedDoctor && (
          // @ts-ignore
          <DoctorLink item={selectedDoctor} style={{ color: 'black' }}>
            <i className="fa fa-external-link" />
          </DoctorLink>
        )}
      </Form.Item>

      <Form.Item label="負責AM">
        <Form.Item name="accountManager" noStyle>
          <EmployeeSelect
            disabled={!selectedClinic?.id}
            handleItemSelect={setSelectedAccountManager}
          />
        </Form.Item>
        {selectedAccountManager && (
          <EmployeeLink
            item={selectedAccountManager}
            style={{ color: 'black' }}
          >
            <i className="fa fa-external-link" />
          </EmployeeLink>
        )}
      </Form.Item>

      <Form.Item label="負責技師">
        <Form.Item name="technician" noStyle>
          <EmployeeSelect
            disabled={!selectedClinic?.id}
            handleItemSelect={setSelectedTechnician}
            query={{ technicianClinic: selectedClinic?.id }}
          />
        </Form.Item>
        {selectedTechnician && (
          <EmployeeLink item={selectedTechnician} style={{ color: 'black' }}>
            <i className="fa fa-external-link" />
          </EmployeeLink>
        )}
      </Form.Item>

      <Form.Item label="負責業務">
        <Form.Item
          name="sales"
          rules={[{ required: true, message: '請輸入' }]}
          noStyle
        >
          <EmployeeSelect
            disabled={!selectedClinic?.id}
            handleItemSelect={setSelectedSales}
          />
        </Form.Item>
        {selectedSales && (
          <EmployeeLink item={selectedSales} style={{ color: 'black' }}>
            <i className="fa fa-external-link" />
          </EmployeeLink>
        )}
      </Form.Item>

      <Form.Item label="負責客服">
        <Form.Item name="customerService" noStyle>
          <EmployeeSelect
            handleItemSelect={setSelectedCustomerService}
            query={{ roles: [Role.CustomerService] }}
          />
        </Form.Item>
        {selectedCustomerService && (
          <EmployeeLink
            item={selectedCustomerService}
            style={{ color: 'black' }}
          >
            <i className="fa fa-external-link" />
          </EmployeeLink>
        )}
      </Form.Item>

      <Form.Item label="性別" name="gender">
        <Radio.Group>
          <Radio value={Gender.Male}>男性</Radio>
          <Radio value={Gender.Female}>女性</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="設計組注意事項" name={['note', 'design']}>
        <Input.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>

      <Form.Item label="牙套組注意事項" name={['note', 'braces']}>
        <Input.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>

      <Form.Item label="業務組注意事項(約診)" name={['note', 'sales']}>
        <Input.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>

      <Form.Item label="主訴" name="chiefComplaint">
        <Input.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>
    </Form>
  )
}

FormPatientBasic.fragments = {
  FormPatientBasic: gql`
    fragment FormPatientBasic on Patient {
      id
      name
      patientCode
      cabinetCode
      gender
      creator {
        id
        name
      }
      clinic {
        id
        # fields required by clinic select before using fragment
        name
        specialContract
        accountManagers {
          id
          name
          role
          taskOwner
          ...EmployeeLink
        }
        technicians {
          id
          name
          role
          taskOwner
          ...EmployeeLink
        }
        sales {
          id
          name
          role
          taskOwner
          ...EmployeeLink
        }
        customerService {
          id
          name
          role
          taskOwner
          ...EmployeeLink
        }
        ...ClinicLink
      }
      doctor {
        id
        # fields required by clinic doctor select before using fragment
        name
        ...DoctorLink
      }
      accountManager {
        id
        # fields required by employee select before using fragment
        name
        role
        taskOwner
        ...EmployeeLink
      }
      technician {
        id
        # fields required by employee select before using fragment
        name
        role
        taskOwner
        ...EmployeeLink
      }
      sales {
        id
        name
        role
        taskOwner
        ...EmployeeLink
      }
      customerService {
        id
        # fields required by employee select before using fragment
        name
        role
        taskOwner
        ...EmployeeLink
      }
      treatArches {
        upper
        lower
      }
      note {
        design
        braces
        sales
      }
      chiefComplaint
      ...Payment
    }
    ${ClinicLink.fragment}
    ${DoctorLink.fragment}
    ${EmployeeLink.fragment}
    ${FormPayment.fragments.Payment}
  `,
}

export { FormPatientBasic }

export default FormPatientBasic
