import { SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Popover } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import type { ConversationMemberInfoFragment } from '../../../../graphql/types'
import SettingModal from './SettingModal'

const MenuItem = Menu.Item

const ConversationTitleContainer = styled.div`
  padding: 0px 12px;
  color: black;
`
const TitleContainer = styled.div`
  padding: 0px 20px;
  border-bottom: solid 1px #e0e0e0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-height: 60px;
`
interface UserMemberPopoverProps {
  members: ConversationMemberInfoFragment[]
}
function UserMemberPopover(props: UserMemberPopoverProps) {
  const { members } = props
  const content = () => (
    <div>
      {members.map((member, index) => (
        <div key={index} style={{ marginTop: '10px' }}>
          <Avatar
            shape="square"
            icon={<UserOutlined />}
            style={{ marginRight: '10px' }}
            size="small"
          />
          {member.name}
        </div>
      ))}
    </div>
  )

  return (
    <div style={{ marginLeft: '10px', marginTop: '2px' }}>
      <Popover content={content()} title="聊天室成員" placement="rightTop">
        <UserOutlined />
      </Popover>
    </div>
  )
}

interface SettingMenuProps {
  handleSettingMenuOnClick: () => void
}

export function SettingMenu({ handleSettingMenuOnClick }: SettingMenuProps) {
  const Overlay = () => (
    <Menu>
      <MenuItem key="0" onClick={handleSettingMenuOnClick}>
        <div>管理成員</div>
      </MenuItem>
    </Menu>
  )
  return (
    <div style={{ marginLeft: 'auto' }}>
      <Dropdown overlay={Overlay} trigger={['click']}>
        <SettingOutlined
          style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.7)' }}
        />
      </Dropdown>
    </div>
  )
}

interface ConversationTitleProps {
  title?: React.ReactNode
  conversationId: string
  members?: ConversationMemberInfoFragment[]
}

export function ConversationTitle(props: ConversationTitleProps) {
  const { title, conversationId, members } = props
  const [settingModalVisible, setSettingModaVisible] = useState(false)
  const handleOpenModal = () => {
    setSettingModaVisible(true)
  }
  const handleCloseModal = () => {
    setSettingModaVisible(false)
  }

  return (
    <ConversationTitleContainer>
      <TitleContainer>
        {title}
        {members && <UserMemberPopover members={members} />}
        <SettingMenu handleSettingMenuOnClick={handleOpenModal} />
        <SettingModal
          members={members}
          conversationId={conversationId}
          handleCloseModal={handleCloseModal}
          visible={settingModalVisible}
        />
      </TitleContainer>
    </ConversationTitleContainer>
  )
}
