import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { message } from 'antd'
import type { GraphQLError } from 'graphql'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import type {
  AccountInfoQueryQuery,
  AccountInfoQueryVariables,
  UpdateAccountInfoMutation,
  UpdateAccountInfoVariables,
  UpdateAccountInput,
  VerificationType,
  VerifyEmployeeAccountMutation,
  VerifyEmployeeAccountMutationVariables,
} from '../../../../../../graphql/types'
import LoadingLayer from '../../../../../components/common/LoadingLayer'
import { useLoadingLayer } from '../../../../../helpers/hooks'
import type { TabKey } from '../../components/TabConfig'
import TabTitle from '../../components/TabTitle'
import type { AccountDetailFormField } from './AccountDetailForm'
import AccountDetailForm from './AccountDetailForm'
import AvatarWithNickname from './AvatarWithNickname'

const AccountDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const AvatarWithNicknameContainer = styled.div`
  margin-left: 94px;
  margin-right: 32px;
  text-align: center;
  width: 166px;
`

const TitleContainer = styled.div`
  text-align: right;
  padding-right: 25px;
  width: 25%;
  min-width: 100px;
`

const AccountMainFormContainer = styled.div`
  width: 60%;
  flex-direction: column;
`

const accountInfoQuery = gql`
  query AccountInfoQuery($id: ID!) {
    account(id: $id) {
      ...AccountDetailForm
      ...AvatarWithNickname
    }
  }
  ${AccountDetailForm.fragment}
  ${AvatarWithNickname.fragment}
`

const updateAccountInfoMutation = gql`
  mutation UpdateAccountInfo($id: ID!, $payload: UpdateAccountInput!) {
    updateAccount(id: $id, payload: $payload) {
      ...AccountDetailForm
      ...AvatarWithNickname
    }
  }
  ${AccountDetailForm.fragment}
  ${AvatarWithNickname.fragment}
`

const verifyEmployeeAccountMutation = gql`
  mutation VerifyEmployeeAccount(
    $id: ID!
    $verificationType: VerificationType!
  ) {
    verifyEmployeeAccount(id: $id, verificationType: $verificationType) {
      ...AccountDetailForm
      ...AvatarWithNickname
    }
  }
  ${AccountDetailForm.fragment}
  ${AvatarWithNickname.fragment}
`

interface Props extends FormComponentProps<AccountDetailFormField> {
  accountId: string
  tabKey: TabKey
}

function AccountInfoForm(props: Props) {
  const { form, accountId, tabKey } = props
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data, loading: queryLoading } = useQuery<
    AccountInfoQueryQuery,
    AccountInfoQueryVariables
  >(accountInfoQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onCompleted: (data) => {
      if (!data?.account) {
        toErrorPage({
          message: '不存在的帳戶',
          redirect: {
            name: '帳戶總覽',
            url: '/accounts',
          },
        })
      }
    },
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '帳戶總覽',
          url: '/accounts',
        },
      })
    },
    variables: {
      id: accountId,
    },
  })
  const [update] = useMutation<
    UpdateAccountInfoMutation,
    UpdateAccountInfoVariables
  >(updateAccountInfoMutation)
  const [verify] = useMutation<
    VerifyEmployeeAccountMutation,
    VerifyEmployeeAccountMutationVariables
  >(verifyEmployeeAccountMutation)

  const handleSubmit = async (payload: UpdateAccountInput) => {
    try {
      setLoadingLayer({ loading: true, tip: '更新中...' })
      // @TODO 這邊 type 沒有保護到，若是更改 schema 或者 form 欄位不容易察覺到問題
      await update({
        variables: {
          id: accountId,
          payload,
        },
        update: (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data)
            message.success('更新帳戶成功！')
        },
      })
    }
    catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`更新帳戶失敗: ${e.message}`)
    }
  }

  const handleVerify = async (verificationType: VerificationType) => {
    try {
      setLoadingLayer({ loading: true, tip: '更新中...' })
      await verify({
        variables: {
          id: accountId,
          verificationType,
        },
        update: (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data)
            message.success('驗證帳戶成功！')
        },
      })
    }
    catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`驗證帳戶失敗: ${e.message}`)
    }
  }

  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  const account = data?.account

  if (!account)
    return null

  return (
    <LoadingLayer loading={loading} tip={tip}>
      <Form style={{ marginTop: 16 }}>
        <AccountDetailContainer>
          <AvatarWithNicknameContainer>
            <AvatarWithNickname account={account} handleSubmit={handleSubmit} />
          </AvatarWithNicknameContainer>
          <AccountMainFormContainer>
            <TitleContainer>
              <TabTitle tabKey={tabKey} />
            </TitleContainer>
            <AccountDetailForm
              form={form}
              item={account}
              handleSubmit={handleSubmit}
              handleVerify={handleVerify}
            />
          </AccountMainFormContainer>
        </AccountDetailContainer>
      </Form>
    </LoadingLayer>
  )
}

export default Form.create<Props>()(AccountInfoForm)
