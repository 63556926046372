import Icon from '@ant-design/icons'
import React from 'react'

function ClinicSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.789 7.89062L8.75151 1.85625L8.34682 1.45156C8.15463 1.26094 7.84526 1.26094 7.65307 1.45156L1.21088 7.89062C1.01869 8.08281 0.915569 8.3375 0.917132 8.60938C0.923382 9.15938 1.38119 9.59844 1.93119 9.59844H2.59526V14.6875H13.4046V9.59844H14.0828C14.3499 9.59844 14.6015 9.49375 14.7906 9.30469C14.9796 9.11563 15.0828 8.86406 15.0828 8.59688C15.0828 8.33125 14.9781 8.07969 14.789 7.89062ZM12.2796 13.5625V8.47344H13.7812L8.36244 3.05781L8.0015 2.69687L2.22026 8.47344H3.72026V13.5625H5.45828H10.5416H12.2796ZM8.6787 7.49866V8.60621H9.84564C9.93082 8.60621 9.99995 8.68028 9.99995 8.77154V9.89524C9.99995 9.9865 9.93082 10.0606 9.84564 10.0606H8.6787V11.168C8.6787 11.2593 8.60957 11.3333 8.52439 11.3333H7.4755C7.39033 11.3333 7.32119 11.2593 7.32119 11.168V10.0606H6.15426C6.06908 10.0606 5.99995 9.9865 5.99995 9.89524V8.77154C5.99995 8.68028 6.06908 8.60621 6.15426 8.60621H7.32119V7.49866C7.32119 7.4074 7.39033 7.33333 7.4755 7.33333H8.52439C8.60957 7.33333 8.6787 7.4074 8.6787 7.49866Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  )
}

function DoctorSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6956 9.54749C12.1809 10.0387 12.5635 10.6111 12.8294 11.2489C13.091 11.874 13.2303 12.536 13.2443 13.2166C13.2457 13.2807 13.1951 13.3333 13.1318 13.3333H12.2878C12.2259 13.3333 12.1767 13.2849 12.1753 13.2223C12.1472 12.1231 11.7111 11.0937 10.9403 10.3135C10.1428 9.5062 9.08358 9.06198 7.95549 9.06198C6.82739 9.06198 5.76822 9.5062 4.97067 10.3135C4.19985 11.0937 3.76381 12.1231 3.73567 13.2223C3.73427 13.2835 3.68504 13.3333 3.62315 13.3333H2.77918C2.71589 13.3333 2.66525 13.2807 2.66665 13.2166C2.68072 12.536 2.81997 11.874 3.0816 11.2503C3.34886 10.6125 3.73005 10.0401 4.21533 9.54891C4.7006 9.05628 5.26606 8.67043 5.89622 8.40134C5.89903 8.39992 5.90184 8.39885 5.90466 8.39778C5.90747 8.39671 5.91028 8.39564 5.9131 8.39422C5.03678 7.75209 4.46711 6.70846 4.46711 5.53099C4.46711 3.5804 6.02844 2 7.95549 2C9.88253 2 11.4439 3.5804 11.4439 5.53099C11.4439 6.70846 10.8742 7.75209 9.99788 8.3928C10.0007 8.39422 10.0035 8.39529 10.0063 8.39636C10.0091 8.39742 10.0119 8.39849 10.0148 8.39992C10.6449 8.67043 11.2104 9.05628 11.6956 9.54749ZM6.24507 7.26231C6.70221 7.72504 7.30987 7.9799 7.9555 7.9799C8.60113 7.9799 9.20878 7.72504 9.66593 7.26231C10.1231 6.79958 10.3749 6.1845 10.3749 5.53099C10.3749 4.87747 10.1231 4.26239 9.66593 3.79966C9.20878 3.33693 8.60113 3.08208 7.9555 3.08208C7.30987 3.08208 6.70221 3.33693 6.24507 3.79966C5.78792 4.26239 5.53614 4.87747 5.53614 5.53099C5.53614 6.1845 5.78792 6.79958 6.24507 7.26231ZM8.63425 9.49866V10.6062H9.80119C9.88636 10.6062 9.9555 10.6803 9.9555 10.7715V11.8952C9.9555 11.9865 9.88636 12.0606 9.80119 12.0606H8.63425V13.168C8.63425 13.2593 8.56512 13.3333 8.47994 13.3333H7.43105C7.34587 13.3333 7.27674 13.2593 7.27674 13.168V12.0606H6.1098C6.02463 12.0606 5.9555 11.9865 5.9555 11.8952V10.7715C5.9555 10.6803 6.02463 10.6062 6.1098 10.6062H7.27674V9.49866C7.27674 9.4074 7.34587 9.33333 7.43105 9.33333H8.47994C8.56512 9.33333 8.63425 9.4074 8.63425 9.49866Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  )
}

export const ClinicIcon = props => <Icon component={ClinicSvg} {...props} />
export const DoctorIcon = props => <Icon component={DoctorSvg} {...props} />
