import { gql } from '@apollo/client'

import { FeedbackInfoFragment } from '../fragment'

export const feedbacksQuery = gql`
  query FeedbacksQuery(
    $query: FeedbackQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    feedbacks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...FeedbackInfo
      }
      total
      limit
      page
    }
  }
  ${FeedbackInfoFragment}
`
