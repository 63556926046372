import type { TFunction } from 'i18next'

import { exhaustiveCheck } from './exhaustiveCheck'
import type { AccessoryCode } from './types'
import { StageType } from './types'

export interface StageNameConfig {
  isExternal?: boolean
}

interface StageItem {
  type: StageType
  serialNumber?: number
  isBeforePrint?: boolean
  accessoryCode?: AccessoryCode
}

export function getStageTypeLabel(t: TFunction, item: StageItem, config?: StageNameConfig) {
  if (item.type === StageType.Eval && item.isBeforePrint === false)
    return '比對'

  if (item.type === StageType.Print && config?.isExternal === true) {
    /** 製造單對外顯示「牙套」 */
    return '牙套'
  }
  return t(`stage.type.${item.type}`)
}

export function getStageAffix(item: StageItem) {
  switch (item.type) {
    case StageType.Mold:
      return 'M'
    case StageType.Eval:
      return item.isBeforePrint ? 'V' : 'E'
    case StageType.Design:
      return 'D'
    case StageType.Print:
      return 'Step'
    case StageType.Accessory:
      return ''
    default:
      exhaustiveCheck(item.type)
      return ''
  }
}

export function getStageSerialNumber(t: TFunction, item: StageItem) {
  if (item.type === StageType.Accessory)
    return t(`stage.accessoryCode.${item.accessoryCode}`)

  if (
    item.type === StageType.Mold
    || item.type === StageType.Eval
    || item.type === StageType.Design
    || item.type === StageType.Print
  )
    return String(item.serialNumber)

  exhaustiveCheck(item.type)
  return ''
}

export function getStageName(t: TFunction, item: StageItem, config?: StageNameConfig): [string, string, string] {
  return [
    getStageTypeLabel(t, item, config),
    getStageAffix(item),
    getStageSerialNumber(t, item),
  ]
}
