import { blue } from '@ant-design/colors'
import { gql } from '@apollo/client'
import { getCategoryFromNotificationTypes } from '@sov/common'
import { Avatar, Badge, List } from 'antd'
import moment from 'moment'
import { isNil } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import type { NotificationItemFragment } from '../../../codegen/types'
import { getAvatarIconByCategory } from '../utils'

const TitleBadge = styled(Badge)`
  width: 8px;
  height: 8px;
`

const ItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Wrapper = styled(List.Item)<{ canRead: boolean }>`
  padding: 16px 16px 12px 16px;
  ${props =>
    props.canRead
    && `
    cursor: pointer;
    &:hover {
      background-color: ${blue[0]};
    }
  `}
`

interface NotificationListItemProps {
  notification: NotificationItemFragment
  handleRead?: (id: string, read: boolean) => Promise<void>
}

function NotificationItem(props: NotificationListItemProps) {
  const { notification, handleRead } = props
  const { id, read, created, type } = notification
  const avatarIcon = getAvatarIconByCategory(
    getCategoryFromNotificationTypes(type),
  )

  return (
    <Wrapper
      canRead={!isNil(handleRead)}
      onClick={() => handleRead && handleRead(id, read)}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatarIcon} />}
        title={(
          <ItemTitleWrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: notification.content,
              }}
            />
            <TitleBadge dot={!read} />
          </ItemTitleWrapper>
        )}
        description={moment(created).fromNow()}
      />
    </Wrapper>
  )
}

NotificationItem.fragment = gql`
  fragment NotificationItem on Notification {
    id
    type
    read
    created
    content
  }
`

export default NotificationItem
