import { Button, Modal, Row } from 'antd'
import type { ModalProps } from 'antd/lib/modal'
import type { FC } from 'react'
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'

const a4PageWidth = '210mm'
const a4PageHeight = '297mm'

const Page = styled.div<{ width: string, height: string }>`
  @page {
    ${props => `size: ${props.width} ${props.height};`}
  }
`

interface Props extends ModalProps {
  width?: string
  height?: string
  printOnHead?: boolean
}

const PrinterModal: FC<Props> = (props) => {
  const {
    width = a4PageWidth,
    height = a4PageHeight,
    children,
    printOnHead = false,
  } = props

  const componentRef = useRef<any>()

  return (
    <Modal
      {...props}
      width={width}
      closable={false}
      footer={[
        <ReactToPrint
          key="print-button"
          trigger={() => <Button type="primary">列印</Button>}
          content={() => componentRef.current}
        />,
      ]}
      bodyStyle={{ padding: 0 }}
    >
      {printOnHead && (
        <Row justify="end">
          <ReactToPrint
            key="print-button"
            trigger={() => <Button type="primary">列印</Button>}
            content={() => componentRef.current}
          />
        </Row>
      )}
      <Page width={width} height={height} ref={componentRef}>
        {children}
      </Page>
    </Modal>
  )
}

export default PrinterModal
