import {
  EditOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import type { QueryResult } from '@apollo/client'
import { gql } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery, getEmptyText } from '@sov/common'
import {
  DateTimeInterval,
  InstructionPopover,
  Link,
  TablePatientInfo,
} from '@sov/ui'
import { Badge, Space, Table } from 'antd'
import type { BadgeProps } from 'antd/lib/badge'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import type { SortOrder } from 'antd/lib/table/interface'
import moment from 'moment'
import { always, cond, equals, map, sort, values } from 'ramda'
import type { DOMAttributes } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import styled from 'styled-components'

import type {
  ExpectedShippingDateModalFragment,
  StageListQuery,
  StageTableFragment,
} from '../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../graphql/types'
import type { StageFilterType, StageSorterField } from '../../pages/stage/StageList'
import { getNullableDateSorter } from '../../utils'
import ViewerFileIcon from '../common/ViewerFileIcon'
import ExpectedShippingDateModal from '../modal/ExpectedShippingDateModal'

const Clickable = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1890ff;
  }
`

const { StageLink } = Link

function getOrderSourceStage(stage: StageTableFragment) {
  if (!stage.__typename)
    return undefined

  switch (stage.__typename) {
    case 'EvalStage':
    case 'PrintStage':
    case 'AccessoryStage':
      return stage
    case 'DesignStage':
      return stage.latestPrintStage
    case 'MoldStage':
      return stage.latestEvalStage
  }
}

export interface StageTableProps {
  source?: StageListQuery['stages']
  loading?: boolean
  scroll?: any
  filterInfo?: StageFilterType
  sortInfo?: AntSorterType<StageSorterField>
  handleTableChange?: TableProps<StageTableFragment>['onChange']
  handleRemoveStageInstruction: (stageId: string) => void
  refetch: QueryResult['refetch']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

function StageTable(props: StageTableProps) {
  const {
    source = defaultSource,
    loading = false,
    scroll,
    filterInfo = {},
    sortInfo,
    handleTableChange,
    handleRemoveStageInstruction,
    refetch,
  } = props
  const { t } = useTranslation()

  const [appointmentSortOrder, setAppointmentSortOrder] = useState<SortOrder>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedStage, setSelectedStage] = useState<
    ExpectedShippingDateModalFragment | undefined
  >(undefined)

  const getExpectedShippingDateClickHandler: (
    stage: ExpectedShippingDateModalFragment
  ) => DOMAttributes<HTMLDivElement>['onClick'] = stage => () => {
    setIsModalVisible(true)
    setSelectedStage(stage)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedStage(undefined)
  }

  const handleExpectedShippingDateUpdated = () => {
    refetch()
  }

  const handleSortAppointment = () =>
    setAppointmentSortOrder(
      cond<SortOrder | undefined, SortOrder | undefined>([
        [equals<SortOrder | undefined>('ascend'), always('descend')],
        [equals<SortOrder | undefined>('descend'), always(undefined)],
        [equals<SortOrder | undefined>(undefined), always('ascend')],
      ]),
    )

  const columns: ColumnProps<StageTableFragment>[] = [
    {
      title: '病患',
      width: 120,
      align: 'center',
      dataIndex: 'patient',
      key: 'patient',
      render: (_, record) => <TablePatientInfo patient={record.patient} />,
    },
    {
      title: '工單',
      width: 80,
      align: 'center',
      dataIndex: 'type',
      key: 'type',
      filteredValue: filterInfo.type ?? [],
      filters: map(
        type => ({ text: t(`stage.type.${type}`), value: type }),
        values(StageType),
      ),
      render: (_, record) => <StageLink item={record} />,
    },
    {
      title: '預計出貨',
      width: 120,
      align: 'center',
      dataIndex: 'expectedShippingDate',
      key: 'expectedShippingDate',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'expectedShippingDate' ? sortInfo.order : undefined,
      render: (_, record) => {
        return (
          <Clickable onClick={getExpectedShippingDateClickHandler(record)}>
            {record.expectedShippingDate
              ? (
                <Space size={8}>
                  <div>
                    {moment(record.expectedShippingDate).format('YYYY-MM-DD')}
                  </div>
                  <EditOutlined />
                </Space>
                )
              : (
                  getEmptyText()
                )}
          </Clickable>
        )
      },
    },
    {
      title: '所屬訂單',
      width: 80,
      align: 'center',
      dataIndex: 'order',
      key: 'order',
      filteredValue: filterInfo.type ?? [],
      filters: map(
        type => ({ text: t(`stage.type.${type}`), value: type }),
        values(StageType),
      ),
      render: (_, record) => {
        const orderSourceStage = getOrderSourceStage(record)
        if (!orderSourceStage?.order)
          return null

        const { id, displayId } = orderSourceStage.order
        return (
          <ReactRouterDomLink to={`/orders/${id}`}>
            {displayId}
          </ReactRouterDomLink>
        )
      },
    },
    {
      className: appointmentSortOrder ? 'ant-table-column-sort' : '',
      title: (
        <span>
          預計回診日期
          {' '}
          {appointmentSortOrder
            ? (
                appointmentSortOrder === 'ascend'
                  ? (
                    <SortAscendingOutlined />
                    )
                  : (
                    <SortDescendingOutlined />
                    )
              )
            : (
                ''
              )}
        </span>
      ),
      width: 80,
      align: 'center',
      dataIndex: 'appointment',
      key: 'appointment',
      onHeaderCell: () => ({
        onClick: handleSortAppointment,
        style: { cursor: 'pointer' },
      }),
      render: (_, record) => {
        const orderSourceStage = getOrderSourceStage(record)
        if (!orderSourceStage?.order || !orderSourceStage.order.appointment)
          return null

        const { startDate, endDate } = orderSourceStage.order.appointment
        return (
          <DateTimeInterval start={moment(startDate)} end={moment(endDate)} />
        )
      },
    },
    {
      title: '重取模',
      width: 80,
      align: 'center',
      dataIndex: 'retrieve',
      key: 'retrieve',
      render: (text, record) => {
        if (
          record.__typename === 'DesignStage'
          || record.__typename === 'PrintStage'
        ) {
          return (
            <div style={{ textAlign: 'center' }}>
              {record.instructionCard?.retrieve ? 'V' : ''}
            </div>
          )
        }
      },
    },
    {
      title: '指示卡',
      width: 80,
      align: 'center',
      dataIndex: 'instructionCard',
      key: 'instructionCard',
      render: (_, record) => {
        if (
          record.__typename === 'DesignStage'
          || record.__typename === 'PrintStage'
          || record.__typename === 'AccessoryStage'
        ) {
          return (
            <InstructionPopover
              instructionItem={record.instructionCard}
              handleRemove={() => handleRemoveStageInstruction(record.id)}
            />
          )
        }
        else {
          return null
        }
      },
    },
    {
      title: '工單注意事項',
      width: 200,
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      render: (text) => {
        return <div style={{ paddingRight: 16 }}>{text}</div>
      },
    },
    {
      title: '工單狀態',
      width: 80,
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      filteredValue: filterInfo.status ?? [],
      filters: map(
        type => ({ text: t(`stage.status.${type}`), value: type }),
        values(StageStatus),
      ),
      render: (text) => {
        const status = cond<StageStatus, BadgeProps['status']>([
          [
            equals<StageStatus>(StageStatus.Pending),
            always<BadgeProps['status']>('default'),
          ],
          [
            equals<StageStatus>(StageStatus.Started),
            always<BadgeProps['status']>('processing'),
          ],
          [
            equals<StageStatus>(StageStatus.Completed),
            always<BadgeProps['status']>('success'),
          ],
          [
            equals<StageStatus>(StageStatus.Dropped),
            always<BadgeProps['status']>('default'),
          ],
        ])(text)

        return (
          <div>
            <Badge status={status} />
            {t(`stage.status.${text}`)}
          </div>
        )
      },
    },
    {
      title: '模型狀態',
      width: 80,
      dataIndex: 'hasFilesForViewer',
      key: 'hasFilesForViewer',
      align: 'center',
      /** client filter */
      filters: [
        { text: '有模型', value: 'true' },
        { text: '尚無模型', value: 'false' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        if (
          record.__typename === 'EvalStage'
          || record.__typename === 'MoldStage'
          || record.__typename === 'DesignStage'
        )
          return String(record.hasFilesForViewer) === value

        return false
      },
      render: (_, record) => {
        if (
          record.__typename === 'EvalStage'
          || record.__typename === 'MoldStage'
          || record.__typename === 'DesignStage'
        ) {
          return (
            <ViewerFileIcon
              hasFilesForViewer={record.hasFilesForViewer}
              stageItem={record}
            />
          )
        }
        return null
      },
    },
  ]

  const { docs, page, total, limit } = source
  const getAppointmentStart = (record: StageTableFragment) =>
    getOrderSourceStage(record)?.order?.appointment?.startDate
  const sorter = getNullableDateSorter(appointmentSortOrder)
  const sortedDocs = sort(
    (a, b) => sorter(getAppointmentStart(a), getAppointmentStart(b)),
    docs,
  )

  return (
    <>
      <Table<StageTableFragment>
        rowKey="id"
        columns={columns}
        dataSource={sortedDocs}
        loading={loading}
        scroll={scroll}
        pagination={TableQuery.getAntdPagination({ page, total, limit })}
        onChange={handleTableChange}
      />
      {selectedStage && (
        <ExpectedShippingDateModal
          handleExpectedShippingDateUpdated={handleExpectedShippingDateUpdated}
          handleModalClose={handleModalClose}
          isVisible={isModalVisible}
          selectedStage={selectedStage}
        />
      )}
    </>
  )
}

const orderCellFragments = {
  OrderCell: gql`
    fragment OrderCell on Order {
      id
      displayId
      appointment {
        id
        startDate
        endDate
      }
    }
  `,
}

StageTable.fragments = {
  StageTable: gql`
    fragment StageTable on Stage {
      id
      ...ExpectedShippingDateModal
      ...StageLink
      status
      type
      note
      expectedShippingDate
      patient {
        ...TablePatientInfo
      }
      ... on MoldStage {
        hasFilesForViewer
        latestEvalStage {
          id
          order {
            ...OrderCell
          }
        }
      }
      ... on EvalStage {
        hasFilesForViewer
        order {
          ...OrderCell
        }
      }
      ... on DesignStage {
        hasFilesForViewer
        instructionCard {
          retrieve
          ...InstructionPopover
        }
        latestPrintStage {
          id
          order {
            ...OrderCell
          }
        }
      }
      ... on PrintStage {
        instructionCard {
          retrieve
          ...InstructionPopover
        }
        order {
          ...OrderCell
        }
      }
      ... on AccessoryStage {
        instructionCard {
          ...InstructionPopover
        }
        order {
          ...OrderCell
        }
      }
      ...ViewerFileIcon
    }
    ${ExpectedShippingDateModal.fragments.ExpectedShippingDateModal}
    ${StageLink.fragment}
    ${InstructionPopover.fragments.InstructionPopover}
    ${ViewerFileIcon.fragments.ViewerFileIcon}
    ${orderCellFragments.OrderCell}
    ${TablePatientInfo.fragment}
  `,
}

export default StageTable
