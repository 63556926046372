import moment from 'moment'
import { complement, groupWith, head, isEmpty, map, reverse } from 'ramda'

import type {
  ConversationMessagesQueryNode,
  ConversationMessagesQueryQuery,
  MessageEdge,
} from '../../../../graphql/types'

interface GetMessageNodesProps {
  conversationMessagesData?: ConversationMessagesQueryQuery
  subscriptionData: MessageEdge[]
}

export const isNotEmpty = complement(isEmpty)

export function groupSameCreatedDay(messageNodes: ConversationMessagesQueryNode[]) {
  const groupOfSameCreatedDay = groupWith(
    (a, b) =>
      moment(a.created).format('YYYYMMDD')
      === moment(b.created).format('YYYYMMDD'),
    messageNodes,
  )
  return map(
    group => ({
      date: isNotEmpty(group)
        ? moment(head<ConversationMessagesQueryNode>(group)!.created)
        : '',
      nodes: group,
    }),
    groupOfSameCreatedDay,
  )
}

export function getMessageNodes({
  conversationMessagesData,
  subscriptionData,
}: GetMessageNodesProps) {
  const queryMessages = conversationMessagesData?.conversationMessages
    ? conversationMessagesData.conversationMessages.edges
    : []
  const combinedData = [...subscriptionData, ...queryMessages]
  const messageNodes = map(message => message.node, reverse(combinedData))
  return messageNodes as ConversationMessagesQueryNode[]
}
