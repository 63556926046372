import { gql } from '@apollo/client'
import { filteredWorkingTaskTypeList } from '@sov/common'
import { StageName } from '@sov/ui'
import type { FormInstance } from 'antd/lib/form'
import Form from 'antd/lib/form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ForwardStageTaskFormFragment } from '../../../../../graphql/types'
import TaskStatistics from '../../../../pages/stage/StageDetail/components/TaskSteps/TaskStatistics'
import { EmployeeSelect } from '../../../form/Select'
import EmployeeLink from '../../../link/employee'

const FormTitle = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
`

const FormItem = Form.Item

interface ForwardStageTaskFormFields {
  nextOwner: string
}

interface ForwardStageTaskFormProps {
  item: ForwardStageTaskFormFragment
  form: FormInstance<ForwardStageTaskFormFields>
}

export function ForwardStageTaskForm(props: ForwardStageTaskFormProps) {
  const { item, form } = props
  const { t } = useTranslation()
  const { currentTask } = item

  const nextTaskType = currentTask?.nextTask?.type
  const isShowingTaskStatistic
    = currentTask?.nextTask
    && filteredWorkingTaskTypeList.includes(currentTask?.nextTask?.type)

  return (
    <Form form={form} requiredMark={false}>
      <FormTitle>傳遞任務</FormTitle>
      <FormItem label="目前工單">
        <StageName item={item} />
      </FormItem>
      <FormItem label="負責技師">
        <EmployeeLink item={item.patient.technician} />
      </FormItem>
      <FormItem label="目前任務">
        {currentTask && t(`task.type.${currentTask.type}`)}
      </FormItem>
      <FormItem label="負責人">{currentTask?.owner?.name}</FormItem>
      <FormItem label="下一份任務">
        {currentTask?.nextTask && t(`task.type.${currentTask.nextTask.type}`)}
      </FormItem>
      {nextTaskType && (
        <>
          <FormItem
            label="下一位負責人"
            name="nextOwner"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <EmployeeSelect
              query={{
                taskOwner: [nextTaskType],
              }}
              showSearch
            />
          </FormItem>
          {isShowingTaskStatistic && (
            <TaskStatistics currentTask={currentTask} />
          )}
        </>
      )}
    </Form>
  )
}

ForwardStageTaskForm.fragment = gql`
  fragment ForwardStageTaskForm on Stage {
    id
    expectedShippingDate
    currentTask {
      id
      type
      status
      owner {
        id
        name
      }
      nextTask {
        id
        type
      }
    }
    patient {
      id
      technician {
        id
        ...EmployeeLink
      }
    }
    ...StageName
  }
  ${StageName.fragment}
  ${EmployeeLink.fragment}
`
