import { Column } from '@ant-design/charts'
import { useQuery } from '@apollo/client'
import { DateIntervalParam, FormQuery, MyStringParam } from '@sov/common'
import { Card, Col, Form, Radio, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'

import type {
  StatisticPatientQueryQuery,
  StatisticPatientQueryVariables,
} from '../../../../graphql/types'
import {
  PatientBrand,
  PatientSource,
  PatientStatisticIndicatorType,
  StatisticInterval,
} from '../../../../graphql/types'
import RangePicker from '../../../components/common/RangePicker'
import { ClinicSelect } from '../../../components/form/Select'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { dateText, defaultInterval } from '../utils'

// import { useTranslation } from 'react-i18next'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const FormItem = Form.Item

const formInput = {
  interval: MyStringParam,
  startDateInterval: DateIntervalParam,
  clinicId: MyStringParam,
  indicatorType: MyStringParam,
}

const statisticPatientQuery = gql`
  query StatisticPatientQuery($query: PatientStatisticQuery!) {
    patientStatistic(query: $query) {
      startDate
      endDate
      segment {
        title
        brands
        sources
      }
      indicators {
        type
        value
      }
    }
  }
`

function StatisticPatient() {
  const [form] = useForm()
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)

  const initialValues = {
    ...formQuery,
    interval: (formQuery.interval
    ?? StatisticInterval.Month) as StatisticInterval,
    startDateInterval:
      formQuery.startDateInterval ?? defaultInterval(StatisticInterval.Month),
    indicatorType:
      formQuery.indicatorType ?? PatientStatisticIndicatorType.CreatedCount,
  }

  const { data, loading } = useQuery<
    StatisticPatientQueryQuery,
    StatisticPatientQueryVariables
  >(statisticPatientQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query: {
        interval: initialValues.interval as StatisticInterval,
        startDate: initialValues.startDateInterval[0],
        endDate: initialValues.startDateInterval[1],
        indicatorType:
          initialValues.indicatorType as PatientStatisticIndicatorType,
        segments: [
          { title: '全部', clinicId: initialValues.clinicId },
          {
            title: '舒服美',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Sov],
          },
          {
            title: '樂齒',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Smilux],
          },
          {
            title: '舒服力',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Retainer],
          },
          {
            title: '品牌待定',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Unknown],
          },
          {
            title: '轉介',
            clinicId: initialValues.clinicId,
            sources: [PatientSource.Sov],
          },
          {
            title: '自約',
            clinicId: initialValues.clinicId,
            sources: [PatientSource.Clinic],
          },
          {
            title: '客源待定',
            clinicId: initialValues.clinicId,
            sources: [PatientSource.Unknown],
          },
          {
            title: '舒服美轉介',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Sov],
            sources: [PatientSource.Sov],
          },
          {
            title: '舒服美自約',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Sov],
            sources: [PatientSource.Clinic],
          },
          {
            title: '樂齒轉介',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Smilux],
            sources: [PatientSource.Sov],
          },
          {
            title: '樂齒自約',
            clinicId: initialValues.clinicId,
            brands: [PatientBrand.Smilux],
            sources: [PatientSource.Clinic],
          },
        ],
      },
    },
  })

  const keyData
    = data?.patientStatistic
      ?.filter(x =>
        ['全部', '舒服美', '樂齒', '舒服力', '舒服美轉介', '樂齒轉介'].includes(
          x.segment.title,
        ),
      )
      .map(x => ({
        type: x.segment.title,
        date: dateText(initialValues.interval, x),
        value: x.indicators?.[0].value,
      })) ?? []

  const brandData
    = data?.patientStatistic
      ?.filter(x =>
        ['全部', '舒服美', '樂齒', '舒服力', '品牌待定'].includes(
          x.segment.title,
        ),
      )
      .map(x => ({
        type: x.segment.title,
        date: dateText(initialValues.interval, x),
        value: x.indicators?.[0].value,
      })) ?? []

  const sourceData
    = data?.patientStatistic
      ?.filter(x =>
        ['全部', '轉介', '自約', '客源待定'].includes(x.segment.title),
      )
      .map(x => ({
        type: x.segment.title,
        date: dateText(initialValues.interval, x),
        value: x.indicators?.[0].value,
      })) ?? []

  return (
    <Page
      header={(
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'StatisticPatient' }]}
            />
            <Title
              route={{
                key: 'StatisticPatient',
                renderSubtitle: () => '線上即時數據',
              }}
            />
            <Form
              style={{ marginBottom: '-16px' }}
              form={form}
              initialValues={initialValues}
              onValuesChange={(changedValues, values) => {
                // 更新區間類型的話，要自動更新區間
                if (changedValues.interval) {
                  const startDateInterval = defaultInterval(
                    changedValues.interval,
                  )
                  form.setFieldsValue({
                    startDateInterval,
                  })
                  handleFormChange({
                    ...values,
                    startDateInterval,
                  })
                }
                else {
                  handleFormChange(values)
                }
              }}
            >
              <Row gutter={16}>
                <Col>
                  <FormItem name="interval" label="單位">
                    <RadioGroup>
                      <RadioButton value={StatisticInterval.Year}>
                        年
                      </RadioButton>
                      <RadioButton value={StatisticInterval.Quarter}>
                        季
                      </RadioButton>
                      <RadioButton value={StatisticInterval.Month}>
                        月
                      </RadioButton>
                      <RadioButton value={StatisticInterval.Day}>
                        日
                      </RadioButton>
                    </RadioGroup>
                  </FormItem>
                </Col>
                <Col>
                  <FormItem name="startDateInterval" label="區間">
                    <RangePicker
                      allowClear={false}
                      disabledDate={date =>
                        date.isBefore(moment('2017-01-01'))
                        || date.isAfter(moment('2023-01-01'))}
                      picker={
                        (form.getFieldValue('interval')
                        === StatisticInterval.Year
                          ? 'year'
                          : form.getFieldValue('interval')
                          === StatisticInterval.Quarter
                            ? 'quarter'
                            : form.getFieldValue('interval')
                            === StatisticInterval.Month
                              ? 'month'
                              : 'date') as any
                      }
                    />
                  </FormItem>
                </Col>
                <Col>
                  <FormItem name="clinicId" label="診所">
                    <ClinicSelect allowClear></ClinicSelect>
                  </FormItem>
                </Col>
                <Col>
                  <FormItem name="indicatorType" label="數據">
                    <RadioGroup>
                      <RadioButton
                        value={PatientStatisticIndicatorType.CreatedCount}
                      >
                        新增病患數
                      </RadioButton>
                      <RadioButton
                        value={PatientStatisticIndicatorType.StartEvalCount}
                      >
                        開始評估病患數
                      </RadioButton>
                      <RadioButton
                        value={PatientStatisticIndicatorType.StartPrintCount}
                      >
                        開始療程病患數
                      </RadioButton>
                      <RadioButton
                        value={PatientStatisticIndicatorType.FinishedCount}
                      >
                        完結病患數
                      </RadioButton>
                    </RadioGroup>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Card size="small" loading={loading} title="關鍵目標：樂齒">
            {!loading && (
              <Column
                height={200}
                style={{ padding: '12px' }}
                data={keyData}
                xField="date"
                yField="value"
                seriesField="type"
                isGroup
                legend={{
                  selected: {
                    舒服美轉介: false,
                    樂齒轉介: false,
                  },
                }}
                meta={{
                  type: { alias: '類別' },
                  date: { alias: '日期' },
                  value: { alias: '病患數' },
                }}
                label={{
                  position: 'top',
                  layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                  ],
                }}
              />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small" loading={loading} title="品牌分眾">
            {!loading && (
              <Column
                height={200}
                style={{ padding: '12px' }}
                data={brandData}
                xField="date"
                yField="value"
                seriesField="type"
                isGroup
                legend={{
                  selected: {
                    全部: false,
                  },
                }}
                meta={{
                  type: { alias: '類別' },
                  date: { alias: '日期' },
                  value: { alias: '病患數' },
                }}
                label={{
                  position: 'top',
                  layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                  ],
                }}
              />
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small" loading={loading} title="客源分眾">
            {!loading && (
              <Column
                height={200}
                style={{ padding: '12px' }}
                data={sourceData}
                xField="date"
                yField="value"
                seriesField="type"
                isGroup
                legend={{
                  selected: {
                    全部: false,
                  },
                }}
                meta={{
                  type: { alias: '類別' },
                  date: { alias: '日期' },
                  value: { alias: '病患數' },
                }}
                label={{
                  position: 'top',
                  layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                  ],
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Page>
  )
}

export default StatisticPatient
