import { gql } from '@apollo/client'

export const LeaveInfoFragment = gql`
  fragment LeaveInfo on Leave {
    id
    created
    updated
    startDate
    endDate
    type
    reason
    isApproved
    employee {
      id
      name
      role
      approvers {
        id
        name
      }
      leaveStatus {
        remainAnnual
        usedSickLeave
        usedPersonalLeave
      }
    }
  }
`
