import type { ButtonProps } from 'antd'
import { Button, DatePicker } from 'antd'
import type { RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import type { Moment } from 'moment'
import moment from 'moment'
import business from 'moment-business'
import React from 'react'
import styled from 'styled-components'

const extraFooterOffsetDays = [3, 7]

interface ExtraFooterProps {
  getOffsetWorkDayChangeHandler: (offsetDay: number) => ButtonProps['onClick']
}

function ExtraFooter(props: ExtraFooterProps) {
  const { getOffsetWorkDayChangeHandler } = props

  return (
    <>
      {extraFooterOffsetDays.map(defaultOffsetDay => (
        <Button
          key={`defaultOffsetDay-${defaultOffsetDay}`}
          onClick={getOffsetWorkDayChangeHandler(defaultOffsetDay)}
          type="link"
        >
          距今
          {' '}
          {defaultOffsetDay}
          {' '}
          個工作天
        </Button>
      ))}
    </>
  )
}

const { RangePicker } = DatePicker

const CustomizedStyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input {
    color: #1890ff;
  }
`

export interface CustomizedRangePickerProps {
  defaultValue: RangePickerProps<Moment>['defaultValue']
  handleDateIntervalChange: RangePickerProps<Moment>['onChange']
}

function CustomizedRangePicker(props: CustomizedRangePickerProps) {
  const { defaultValue, handleDateIntervalChange } = props

  const getOffsetWorkDayChangeHandler: ExtraFooterProps['getOffsetWorkDayChangeHandler']
    = offsetDay => () => {
      /** 目前開發使用的版本 moment 和 moment-businees 中的 Moment type 對不起來，這邊先跳過 type 檢查 */
      const updateDates = [
        moment(),
        // @ts-ignore
        business.addWeekDays(moment(), offsetDay),
      ] as [Moment, Moment]
      handleDateIntervalChange?.(
        updateDates,
        updateDates.map(date => date.toISOString()) as [string, string],
      )
    }

  return (
    <CustomizedStyledRangePicker
      allowClear={false}
      bordered={false}
      defaultValue={defaultValue}
      onChange={handleDateIntervalChange}
      renderExtraFooter={() => (
        <ExtraFooter
          getOffsetWorkDayChangeHandler={getOffsetWorkDayChangeHandler}
        />
      )}
    />
  )
}

export default CustomizedRangePicker
