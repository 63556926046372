import { Spin } from 'antd'
import type { SpinProps } from 'antd/lib/spin'
import { omit, pick } from 'ramda'
import type { CSSProperties } from 'react'
import React from 'react'

const style: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, .8)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1000,
}

function computeLocation(window, rootEle) {
  if (window.innerHeight === window.document.body.scrollHeight) {
    return {
      position: 'absolute',
      positionY: rootEle.offsetHeight / 2,
    }
  }
  else {
    return {
      position: 'fixed',
      positionY: window.innerHeight / 2,
    }
  }
}

class LoadingLayer extends React.PureComponent<any> {
  state = {
    position: 'absolute',
    positionY: 0,
    loading: false,
    rootEle: null,
  }

  componentDidMount() {
    this.setState({
      // @ts-ignore
      rootEle: this.root,
      // @ts-ignore
      ...computeLocation(window, this.root),
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.rootEle && nextProps.loading !== prevState.loading) {
      return {
        ...prevState,
        ...computeLocation(window, prevState.rootEle),
        loading: false,
      }
    }
    return null
  }

  render() {
    const _spinProps = pick(
      ['delay', 'indicator', 'size', 'spinning', 'tip', 'wrapperClassName'],
      this.props,
    )
    const rest = omit(
      [
        'delay',
        'indicator',
        'size',
        'spinning',
        'tip',
        'wrapperClassName',
        'loading',
      ],
      this.props,
    )
    const spinProps: SpinProps = {
      size: 'large',
      tip: 'Loading...',
      ..._spinProps,
    }

    return (
      <div
        style={{ position: 'relative', width: '100%' }}
        ref={(n) => {
          // @ts-ignore
          this.root = n
        }}
        {...rest}
      >
        {this.props.loading && (
          <div style={style}>
            <Spin
              style={{
                position: this.state.position as CSSProperties['position'],
                top: `${this.state.positionY}px`,
                marginTop: 16,
              }}
              {...spinProps}
            />
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}

export default LoadingLayer
