import { Column } from '@ant-design/charts'
import { useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  FormQuery,
  MyArrayParam,
  MyStringParam,
} from '@sov/common'
import { Form, Radio, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import gql from 'graphql-tag'
import moment from 'moment'
import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  StatisticStageQueryQuery,
  StatisticStageQueryVariables,
} from '../../../../graphql/types'
import {
  PatientBrand,
  StageType,
  StatisticInterval,
} from '../../../../graphql/types'
import RangePicker from '../../../components/common/RangePicker'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { dateText, defaultInterval } from '../utils'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const FormItem = Form.Item

const formInput = {
  interval: MyStringParam,
  startDateInterval: DateIntervalParam,
  stageTypes: MyArrayParam,
  type: MyStringParam,
  patientBrand: MyStringParam,
}

const statisticStageQuery = gql`
  query StatisticStageQuery($query: StageCapacityQuery!) {
    stageCapacity(query: $query) {
      stageType
      startDate
      endDate
      actualCount
      expectedCount
      forecastCount
    }
  }
`

const StatisticStage: FC = () => {
  const [form] = useForm()
  const { t } = useTranslation()

  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)

  const initialValues = {
    ...formQuery,
    interval: (formQuery.interval
    ?? StatisticInterval.Day) as StatisticInterval,
    startDateInterval: formQuery.startDateInterval ?? [
      moment().startOf('month'),
      moment().endOf('month'),
    ],
    stageTypes: Object.values(StageType),
    type: formQuery.type ?? 'expected',
    patientBrand: formQuery.patientBrand,
  }

  const { data, loading } = useQuery<
    StatisticStageQueryQuery,
    StatisticStageQueryVariables
  >(statisticStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query: {
        interval: initialValues.interval as StatisticInterval,
        startDate: initialValues.startDateInterval[0],
        endDate: initialValues.startDateInterval[1],
        stageTypes: initialValues.stageTypes as StageType[],
        patientBrand: initialValues.patientBrand as PatientBrand,
      },
    },
  })

  const chartData
    = data?.stageCapacity?.map(x => ({
      type: t(`stage.type.${x.stageType}`),
      date: dateText(initialValues.interval, x),
      value:
        initialValues.type === 'expected'
          ? x.expectedCount
          : initialValues.type === 'actual'
            ? x.actualCount
            : x.forecastCount,
    })) ?? []

  return (
    <Page
      header={(
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'StatisticStage' }]}
            />
            <Title route={{ key: 'StatisticStage' }} />
          </div>
        </div>
      )}
    >
      <Section>
        <Form
          form={form}
          initialValues={initialValues}
          layout="inline"
          onValuesChange={(changedValues, values) => {
            // 更新區間類型的話，要自動更新區間
            if (changedValues.interval) {
              const startDateInterval = defaultInterval(changedValues.interval)
              form.setFieldsValue({
                startDateInterval,
              })
              handleFormChange({
                ...values,
                startDateInterval,
              })
            }
            else {
              handleFormChange(values)
            }
          }}
        >
          <FormItem name="interval" label="單位">
            <RadioGroup>
              <RadioButton value={StatisticInterval.Year}>年</RadioButton>
              <RadioButton value={StatisticInterval.Quarter}>季</RadioButton>
              <RadioButton value={StatisticInterval.Month}>月</RadioButton>
              <RadioButton value={StatisticInterval.Day}>日</RadioButton>
            </RadioGroup>
          </FormItem>
          <FormItem name="startDateInterval" label="區間">
            <RangePicker
              allowClear={false}
              disabledDate={date =>
                date.isBefore(moment('2017-01-01'))
                || date.isAfter(moment('2023-01-01'))}
              picker={
                (form.getFieldValue('interval') === StatisticInterval.Year
                  ? 'year'
                  : form.getFieldValue('interval') === StatisticInterval.Quarter
                    ? 'quarter'
                    : form.getFieldValue('interval') === StatisticInterval.Month
                      ? 'month'
                      : 'date') as any
              }
            />
          </FormItem>
          <FormItem name="patientBrand" label="品牌">
            <RadioGroup>
              <RadioButton value={undefined}>不分</RadioButton>
              <RadioButton value={PatientBrand.Sov}>舒服美</RadioButton>
              <RadioButton value={PatientBrand.Smilux}>樂齒</RadioButton>
            </RadioGroup>
          </FormItem>
          <FormItem name="type" label="">
            <RadioGroup>
              <RadioButton value="expected">下單數量</RadioButton>
              <RadioButton value="actual">實際出貨數量</RadioButton>
              <RadioButton value="forecast">預測數量</RadioButton>
            </RadioGroup>
          </FormItem>
        </Form>
        {loading
          ? (
            <Spin size="large" tip="讀取中..." style={{ marginTop: 24 }} />
            )
          : (
            <Column
              style={{ padding: '12px' }}
              data={chartData}
              xField="date"
              yField="value"
              seriesField="type"
              isGroup
              legend={{
                selected: {
                  建模: false,
                  配件: false,
                },
              }}
              meta={{
                type: { alias: '類別' },
                date: { alias: '日期' },
                value: { alias: '張數' },
              }}
            />
            )}
      </Section>
    </Page>
  )
}

export default StatisticStage
