import { UserOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { InstructionPopover, Link, StageName } from '@sov/ui'
import { Avatar, Col, Row } from 'antd'
import moment from 'moment'
import React from 'react'

import type { AccountManagerStageListRowFragment } from '../../../../../graphql/types'
import TableHeaderAndTableRow from '../TableHeaderAndTableRow'
import LinkButton from './LinkButton'

interface RowProps {
  stage: AccountManagerStageListRowFragment
}

function StageRow(props: RowProps) {
  const { stage } = props

  if (stage.__typename !== 'PrintStage')
    return null

  const { designStage, id, patient, shippingDate } = stage

  return (
    <Row align="middle">
      <Col span={2}>
        <Avatar
          src={patient.photos.frontFace?.thumbnailPath}
          icon={<UserOutlined />}
        />
      </Col>
      <Col span={3}>
        <TableHeaderAndTableRow headerTitle="病患">
          <Link.PatientLink item={patient} />
        </TableHeaderAndTableRow>
      </Col>
      <Col span={3}>
        <TableHeaderAndTableRow headerTitle="工單編號">
          <StageName item={stage} showType={false} />
        </TableHeaderAndTableRow>
      </Col>
      <Col span={6}>
        <TableHeaderAndTableRow headerTitle="出貨時間">
          <div>
            {shippingDate
              ? moment(shippingDate).format('YYYY-MM-DD HH:mm')
              : getEmptyText()}
          </div>
        </TableHeaderAndTableRow>
      </Col>
      <Col span={5}>
        <TableHeaderAndTableRow headerTitle="指示卡">
          {designStage?.instructionCard
            ? (
              <div>
                <InstructionPopover
                  instructionItem={designStage.instructionCard}
                />
              </div>
              )
            : (
                '此階段無指示'
              )}
        </TableHeaderAndTableRow>
      </Col>
      <Col span={5}>
        <LinkButton to={`/patients/${patient.id}/stages/${id}`} />
      </Col>
    </Row>
  )
}

StageRow.fragments = {
  AccountManagerStageListRow: gql`
    fragment AccountManagerStageListRow on Stage {
      id
      patient {
        ...PatientLink
        photos {
          frontFace {
            id
            thumbnailPath
          }
        }
      }
      ...StageName
      ... on PrintStage {
        designStage {
          ... on DesignStage {
            instructionCard {
              ...InstructionPopover
            }
          }
        }
      }
      shippingDate
    }
    ${Link.PatientLink.fragment}
    ${StageName.fragment}
    ${InstructionPopover.fragments.InstructionPopover}
  `,
}

export default StageRow
