import { EditOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { TablePatientInfo } from '@sov/ui'
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  List,
  Row,
  Space,
} from 'antd'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { sort } from 'ramda'
import React, { useState } from 'react'

import type {
  AppointmentCalendarQueryQuery,
  AppointmentCalendarQueryQueryVariables,
  AppointmentFragment,
} from '../../../../graphql/types'
import AppointmentForm from '../../../components/form/appointment'
import { ClinicSelect } from '../../../components/form/Select'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import AppointmentModal from '../../../components/modal/AppointmentModal'

// @ts-ignore
const moment = extendMoment(Moment)

const query = gql`
  query AppointmentCalendarQuery(
    $query: AppointmentsQuery = {}
    $page: Int
    $limit: Int = 1000
    $sort: String
  ) {
    appointments(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...Appointment
      }
      limit
      total
      page
    }
  }
  ${AppointmentForm.fragment.Appointment}
`

interface AppointmentListProps {
  date: Moment.Moment
  clinicId?: string
  currentDate: any
  setCurrentDate: any
}

function AppointmentList(props: AppointmentListProps) {
  const { date, clinicId, currentDate, setCurrentDate } = props
  const startDateInterval = [
    moment(date).startOf('day'),
    moment(date).endOf('day'),
  ]
  const [selectedAppointment, setSelectedAppointment]
    = useState<AppointmentFragment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const { data, loading, refetch } = useQuery<
    AppointmentCalendarQueryQuery,
    AppointmentCalendarQueryQueryVariables
  >(query, {
    variables: {
      query: {
        startDateInterval,
        clinic: clinicId,
      },
    },
  })

  const reset = () => {
    setSelectedAppointment(undefined)
    setModalVisible(false)
  }

  const appointments = data?.appointments.docs ?? []
  const appointmentsSorted = sort((a, b) => {
    return moment(a.startDate).isSameOrAfter(b.startDate) ? 1 : -1
  }, appointments)

  return (
    <>
      <List
        style={{
          borderLeft: '1px solid #f0f0f0',
          borderRight: '1px solid #f0f0f0',
        }}
        loading={loading}
        size="small"
        header={(
          <div
            style={{ cursor: 'pointer', paddingLeft: 16, textAlign: 'left' }}
            onClick={() => {
              setCurrentDate(currentDate ? null : moment(date))
            }}
          >
            <h1>{moment(date).format('MM-DD ddd')}</h1>
            <Space>
              <div>
                約診數
                {appointments.length}
              </div>
            </Space>
          </div>
        )}
        dataSource={appointmentsSorted}
        renderItem={(item) => {
          return (
            <List.Item>
              <List.Item.Meta
                title={(
                  <Space
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedAppointment(item)
                      setModalVisible(true)
                    }}
                  >
                    {moment(item.startDate).format('HH:mm')}
                    <EditOutlined></EditOutlined>
                  </Space>
                )}
                description={
                  <TablePatientInfo patient={item.patient} avatar={false} />
                }
              />
            </List.Item>
          )
        }}
      />
      <AppointmentModal
        item={selectedAppointment}
        visible={modalVisible}
        reset={reset}
        refetch={refetch}
      />
    </>
  )
}

function AppointmentCalendar() {
  const [form] = Form.useForm()
  const initialValues = {
    clinicId: undefined,
    date: moment().startOf('day'),
    showWeekend: true,
  }
  const [formState, setFormState] = useState(initialValues)

  const [currentDate, setCurrentDate] = useState<Moment.Moment | null>(null)

  const dateRange = Array.from(
    moment
      .range([
        moment(formState.date).startOf('week').startOf('day'),
        moment(formState.date).endOf('week').startOf('day'),
      ])
      .by('day'),
  ).filter((x) => {
    if (formState.showWeekend)
      return true
    else
      return x.day() !== 0 && x.day() !== 6
  })

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'AppointmentCalendar' }]}
          />
          <Title
            route={{
              key: 'AppointmentCalendar',
            }}
          />
        </>
      )}
    >
      <Card size="small">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form
            layout="inline"
            form={form}
            initialValues={initialValues}
            onChange={() => setFormState(form.getFieldsValue())}
          >
            <Form.Item>
              <Button.Group>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      date: formState.date.subtract(1, 'week'),
                    })
                    setFormState(form.getFieldsValue())
                  }}
                >
                  前一週
                </Button>
                <Button
                  onClick={() => {
                    form.setFieldsValue({ date: moment() })
                    setFormState(form.getFieldsValue())
                  }}
                >
                  本週
                </Button>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      date: formState.date.add(1, 'week'),
                    })
                    setFormState(form.getFieldsValue())
                  }}
                >
                  後一週
                </Button>
              </Button.Group>
            </Form.Item>
            <Form.Item label="診所" name="clinicId">
              <ClinicSelect
                allowClear
                onChange={() => setFormState(form.getFieldsValue())}
              />
            </Form.Item>
            <Form.Item label="區間" name="date">
              <DatePicker
                picker="week"
                format={value =>
                  `${moment(value)
                    .startOf('week')
                    .format('YYYY-MM-DD')} ~ ${moment(value)
                    .endOf('week')
                    .format('YYYY-MM-DD')}`}
              >
              </DatePicker>
            </Form.Item>
            <Form.Item
              label="顯示週末"
              name="showWeekend"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Form>
          <Row style={{ width: '100%', border: '1px solid #f0f0f0' }}>
            {dateRange.map((date, index) => {
              return (
                <Col flex={`${100 / dateRange.length}%`} key={index}>
                  <AppointmentList
                    date={date}
                    clinicId={formState.clinicId}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                  />
                </Col>
              )
            })}
          </Row>
        </Space>
      </Card>
    </Page>
  )
}

export default AppointmentCalendar
