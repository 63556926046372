import { FormQuery, MyStringParam } from '@sov/common'
import { Card, Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import LatestMessageDrawer from './LatestMessageDrawer'
import LatestMessageList from './LatestMessageList'

const MessageIndexContainer = styled.div`
  display: flex;
`

const queryString = {
  conversationId: MyStringParam,
}

function MessageIndex() {
  const { formQuery } = FormQuery.useFormQuery(queryString)
  const { conversationId } = formQuery

  const [selectedConversation, setSelectedConversation] = useState('')

  const handleBlockClick = (conversation: string) => {
    setSelectedConversation(conversation)
  }

  useEffect(() => {
    if (conversationId)
      setSelectedConversation(conversationId)
  }, [conversationId])

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'ConversationList' }]}
          />
          <Title route={{ key: 'ConversationList' }} />
        </>
      )}
    >
      <Card size="small">
        <MessageIndexContainer>
          <LatestMessageList
            selectedConversation={selectedConversation}
            handleBlockClick={handleBlockClick}
          />
          {selectedConversation
            ? (
              <LatestMessageDrawer selectedConversation={selectedConversation} />
              )
            : (
              <Empty
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '200px',
                }}
                description="尚無聊天訊息，請至病患頁面新增"
              />
              )}
        </MessageIndexContainer>
      </Card>
    </Page>
  )
}

export default MessageIndex
