import { blue, gold } from '@ant-design/colors'
import { EnvironmentOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Tooltip, Typography } from 'antd'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import type { CheckPointProgressFragment } from '../../codegen/types'
import CheckPin from './CheckPin'

const RoundTooltipCorner = createGlobalStyle`
  .ant-tooltip-inner {
    border-radius: 8px !important; 
  }
`

const BarWrapper = styled.div<{ isActive: boolean }>`
  flex: 1;
  height: 12px;
  border-radius: 16px;
  background-color: rgba(
    250,
    173,
    20,
    ${props => (props.isActive ? 1 : 0.3)}
  );

  position: relative;
`
const AnchorPoint = styled.div<{ right: number }>`
  border: solid 1px ${blue.primary};
  background-color: ${blue.primary};
  width: 12px;
  height: 12px;
  border-radius: 50%;

  position: absolute;
  right: ${props => props.right}%;
`
const PointerWrapper = styled.div<{ isFinal: boolean }>`
  text-align: center;
  margin: 0 ${props => (props.isFinal ? 0 : 8)}px 0 8px;
`
const CheckPointerWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export interface AnchorProps {
  currentStep: number
  right: number
}

function DefaultAnchor(props: AnchorProps) {
  const { currentStep, right } = props

  return (
    <>
      <RoundTooltipCorner />
      <Tooltip
        title={`Step${currentStep}`}
        color="blue"
        placement="bottom"
        visible
        overlayStyle={{ zIndex: 700 }}
      >
        <AnchorPoint right={right} />
      </Tooltip>
    </>
  )
}

interface BarProps {
  startStep: number
  currentStep: number
  showProgress: boolean
  checkPoint: CheckPointProgressFragment
  showAnchor: boolean
  Anchor: (props: AnchorProps) => JSX.Element
}
function Bar(props: BarProps) {
  const {
    startStep,
    currentStep,
    checkPoint,
    showProgress,
    showAnchor,
    Anchor,
  } = props
  const { isCompleted, goalStep } = checkPoint

  const shouldShowCurrentStep
    = currentStep > startStep && currentStep <= goalStep
  const right
    = currentStep > 0
      ? Math.floor(((goalStep - currentStep) * 100) / (goalStep - startStep))
      : 100

  return (
    <BarWrapper isActive={isCompleted && showProgress}>
      {shouldShowCurrentStep && showAnchor && (
        <Anchor currentStep={currentStep} right={right} />
      )}
    </BarWrapper>
  )
}

interface PointProps {
  checkPoint: CheckPointProgressFragment
  showProgress: boolean
}

function Point(props: PointProps) {
  const { checkPoint, showProgress } = props
  const cpName = checkPoint.isFinal ? 'Final' : `CP${checkPoint.serialNumber}`

  return (
    <PointerWrapper isFinal={checkPoint.isFinal}>
      {checkPoint.isCompleted && showProgress
        ? (
          <CheckPin />
          )
        : (
          <EnvironmentOutlined
            style={{ color: gold.primary, fontSize: '24px' }}
          />
          )}
      <Typography.Title level={5} style={{ color: gold.primary, margin: 0 }}>
        {cpName}
      </Typography.Title>
      <Typography.Text type="secondary" style={{ margin: 0 }}>
        Step
        {' '}
        {checkPoint.goalStep}
      </Typography.Text>
    </PointerWrapper>
  )
}

export interface CheckPointProgressProps {
  className?: string
  checkPoints: CheckPointProgressFragment[]
  currentStep?: number
  showProgress?: boolean
  showAnchor?: boolean
  Anchor?: (props: AnchorProps) => JSX.Element
}

function CheckPointProgress(props: CheckPointProgressProps) {
  const {
    className,
    checkPoints,
    currentStep = 0,
    showProgress = false,
    showAnchor = false,
    Anchor = DefaultAnchor,
  } = props

  return (
    <Wrapper className={className}>
      {checkPoints.map((checkPoint, index, list) => (
        <CheckPointerWrapper
          key={`${checkPoint.serialNumber}-${checkPoint.goalStep}`}
        >
          <Bar
            showProgress={showProgress}
            startStep={index === 0 ? 0 : list[index - 1].goalStep}
            currentStep={currentStep}
            checkPoint={checkPoint}
            showAnchor={showAnchor}
            Anchor={Anchor}
          />
          <Point showProgress={showProgress} checkPoint={checkPoint} />
        </CheckPointerWrapper>
      ))}
    </Wrapper>
  )
}

CheckPointProgress.fragments = {
  CheckPointProgress: gql`
    fragment CheckPointProgress on CheckPoint {
      serialNumber
      goalStep
      isCompleted
      isFinal
    }
  `,
}

export default CheckPointProgress
