import type { SortOrder } from 'antd/lib/table/interface'
import moment from 'moment'
import {
  always,
  compose,
  filter,
  find,
  identity,
  ifElse,
  includes,
  isNil,
  pipe,
  propEq,
  reduce,
  sort,
} from 'ramda'

import type {
  BatchUpdateOrderInput,
  CreateOrderInput,
  StageOrderContent,
} from '../../../graphql/types'
import {
  OrderStatus,
  OrderType,
  StageType,
} from '../../../graphql/types'
import type { IOrder } from '../../components/table/Order'
import type { OrderForm } from '../../components/table/Order/TableCells'
import {
  transformStageOrder,
  transformStageProvide,
} from '../../components/table/Order/utils'
import { isEmptyOrNil } from '../../utils'

export interface SeprateResultType {
  ordersToBeCreate: CreateOrderInput[]
  ordersToBeUpdate: BatchUpdateOrderInput[]
}

export function isDueDateExpired(dueDate: moment.Moment, stageOrder: StageOrderContent[]) {
  return compose<StageOrderContent[], StageOrderContent, number, boolean>(
    printStageCount =>
      moment().isAfter(moment(dueDate).add(printStageCount * 30, 'day')),
    /** 非製造的訂單，都固定以一個月當過期時限 */
    ifElse(isNil, always(1), printStageOrder => printStageOrder.number),
    find(propEq('stageType', StageType.Print)),
  )(stageOrder)
}

// 避免對同一個 order 重複 copy 時產生一樣的暫時 id
export const getRandomHundredNumber = () => Math.round(Math.random() * 1000)

export function getEmptyOrder(): IOrder {
  return {
    id: `temp-create-${getRandomHundredNumber()}`,
    patient: {
      id: '',
      name: '',
      clinic: {
        id: '',
        name: '',
      },
      sales: {
        id: '',
        name: '',
      },
    },
    description: '',
    type: OrderType.NormalPrint,
    stageOrder: [
      {
        stageType: StageType.Print,
        number: 1,
        items: [],
      },
    ],
    stageProvide: [],
    isLatest: false,
    status: OrderStatus.Pending,
  }
}

export function getNewOrder(order: IOrder): IOrder {
  return {
    id: `${order.id}-new-${getRandomHundredNumber()}`,
    type: OrderType.NormalPrint,
    patient: order.patient,
    isLatest: false,
    stageOrder: [
      {
        stageType: StageType.Print,
        number: 1,
        items: [],
      },
    ],
    description: '',
    stageProvide: [],
    status: OrderStatus.Pending,
  }
}

// 在出貨日已過期（紅字）的訂單下面，自動塞入新的訂單欄位
export const insertNewOrder = reduce<IOrder, IOrder[]>((docs, order) => {
  const { dueDate, stageOrder } = order
  if (isDueDateExpired(dueDate, stageOrder)) {
    const newOrder = getNewOrder(order)
    return [...docs, order, newOrder]
  }
  return [...docs, order]
}, [])

export function getCreatePayload(order: IOrder, formValue: OrderForm): CreateOrderInput {
  return {
    patient: order.patient.id || formValue.patient,
    stageOrder: transformStageOrder(formValue.stageOrder),
    type: formValue.type,
    description: formValue.description,
    appointmentDate: isEmptyOrNil(formValue.appointmentDate)
      ? undefined
      : formValue.appointmentDate,
  }
}

export function getUpdatePayload(formValue: OrderForm, orderId: string): BatchUpdateOrderInput {
  return {
    id: orderId,
    stageOrder: formValue.stageOrder && transformStageOrder(formValue.stageOrder),
    stageProvide:
    formValue.stageProvide && transformStageProvide(formValue.stageProvide),
    type: formValue.type,
    description: formValue.description,
    appointmentDate: isEmptyOrNil(formValue.appointmentDate)
      ? undefined
      : formValue.appointmentDate,
  }
}

export function sortByAppointmentDate(appointmentSortOrder?: SortOrder) {
  return appointmentSortOrder
    ? sort<IOrder>((a, b) => {
      /** 都沒有回診日排序不變 */
      if (!a.appointment && !b.appointment)
        return 0

      /** 沒有回診日往後排 */
      if (!a.appointment)
        return 1

      /** 沒有回診日往後排 */
      if (!b.appointment)
        return -1

      const isAEarlier = moment(a.appointment.startDate).isBefore(
        moment(b.appointment.startDate),
        'day',
      )
      /** 日期升冪排列（舊到新） */
      if (appointmentSortOrder === 'ascend')
        return isAEarlier ? -1 : 1

      /** 日期降冪排列（新到舊） */
      if (appointmentSortOrder === 'descend')
        return isAEarlier ? 1 : -1

      return 0
    })
    : identity
}

export enum AdvanceSearchOption {
  NEED_NEXT = 'NEED_NEXT',
  NOT_YET_BINDING = 'NOT_YET_BINDING',
  IGNORE_ACCESSORY = 'IGNORE_ACCESSORY',
}

export const advanceSearchOptions = {
  NEED_NEXT: '沒按預期時間出下一副',
  NOT_YET_BINDING: '回診日在 15 工作天內但訂單未綁定',
  IGNORE_ACCESSORY: '去除最新一筆為配件訂單',
}

const ignoreAccessoryFilter = filter<IOrder>(
  order => order.type !== OrderType.Accessory,
)
const notYetBindingFilter = filter<IOrder>((order) => {
  const isAppointmentDateComing = order.appointment
    ? moment(order.appointment.startDate).isSameOrBefore(
      moment().add(3, 'week'),
    )
    : false
  return order.status === OrderStatus.Pending && isAppointmentDateComing
})
const needNextFilter = filter<IOrder>(order =>
  isDueDateExpired(order.dueDate, order.stageOrder),
)

export function advanceSearch(options: AdvanceSearchOption[]) {
  return pipe<IOrder[], IOrder[], IOrder[], IOrder[]>(
    includes(AdvanceSearchOption.NEED_NEXT, options)
      ? needNextFilter
      : identity,
    includes(AdvanceSearchOption.NOT_YET_BINDING, options)
      ? notYetBindingFilter
      : identity,
    includes(AdvanceSearchOption.IGNORE_ACCESSORY, options)
      ? ignoreAccessoryFilter
      : identity,
  )
}
