import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button, Checkbox } from 'antd'
import { map, values, without } from 'ramda'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import type {
  PatientStageIndexQueryQuery,
  PatientStageIndexQueryVariables,
  StagesQuery,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import Page, { Section } from '../../../components/layout/Page'
import PatientStageTable from '../../../components/table/PatientStage'
import { authContext } from '../../../context'
import { isInternalRoles } from '../../../utils'
import CreateStageButtonGroup from './CreateStageButtonGroup'

const patientStageIndexQuery = gql`
  query PatientStageIndexQuery(
    $id: ID!
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      stages(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          id
          type
          expectedShippingDate
          ...PatientStageTableStageInfo
        }
      }
      ...CreateStageButtonGroup
      ...PatientStageTablePatientInfo
    }
  }
  ${CreateStageButtonGroup.fragments.CreateStageButtonGroup}
  ${PatientStageTable.fragments.PatientStageTableStageInfo}
  ${PatientStageTable.fragments.PatientStageTablePatientInfo}
`

const CheckboxWrapper = styled.div`
  .buttons {
    visibility: hidden;
  }
  &:hover {
    .buttons {
      visibility: visible;
    }
  }
`

export type PatientStageFilterType = Pick<StagesQuery, 'type' | 'status'>
export type PatientStageSorterField = 'expectedShippingDate'

interface RouteProps {
  patientId: string
}

export function PatientStageIndex() {
  const match = useRouteMatch<RouteProps>()
  const patientId = match.params.patientId

  const { t } = useTranslation()
  const auth = useContext(authContext)

  const commonTypes = without(
    [StageType.Accessory],
    isInternalRoles(auth)
      ? values(StageType)
      : [StageType.Eval, StageType.Print],
  )

  const { tableQuery, paginateQuery, handleTableChange }
    = TableQuery.useTableQuery<PatientStageFilterType, any>(
      // 目前病患工單不會超過的數字
      { limit: 300, sort: '-expectedShippingDate' },
      {
        type: commonTypes,
        status: [
          StageStatus.Completed,
          StageStatus.Started,
          StageStatus.Dropped,
        ],
      },
    )
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const history = useHistory()
  const { data, loading, refetch } = useQuery<
    PatientStageIndexQueryQuery,
    PatientStageIndexQueryVariables
  >(patientStageIndexQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {},
      ...paginateQuery,
    },
  })

  const handleChangeVisibleTypes = (visibleTypes: StageType[]) => {
    handleTableChange(
      { current: paginateQuery.page },
      { status: tableQuery.filters?.status ?? [], type: visibleTypes },
      tableQuery.sort ?? [],
      {} as any,
    )
  }

  if (loading)
    return <Page loading />

  if (!data?.patient)
    return null

  const patient = data.patient
  const stageItems = patient.stages?.docs || []

  const handleRefresh = () => {
    refetch()
  }

  const handleSubmit = (newStageId: string) => {
    history.push(`/patients/${patientId}/stages/${newStageId}`)
  }

  const StageTypeCheckbox = () => (
    <CheckboxWrapper>
      <Checkbox.Group
        options={map(
          type => ({ label: t(`stage.type.${type}`), value: type }),
          values(StageType),
        )}
        value={tableQuery.filters?.type}
        onChange={value => handleChangeVisibleTypes(value as StageType[])}
      />
      <span className="buttons">
        <Button
          onClick={() => handleChangeVisibleTypes(values(StageType))}
          size="small"
          style={{ marginLeft: 8 }}
        >
          全選
        </Button>
        <Button
          onClick={() => handleChangeVisibleTypes([])}
          size="small"
          style={{ marginLeft: 8 }}
        >
          全不選
        </Button>
      </span>
    </CheckboxWrapper>
  )

  return (
    <Section>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StageTypeCheckbox />
        <CreateStageButtonGroup
          patientItem={patient}
          onRefresh={handleRefresh}
          handleSubmit={handleSubmit}
        />
      </div>
      <br />
      <PatientStageTable
        patientItem={patient}
        source={stageItems}
        filterInfo={tableQuery.filters}
        sortInfo={tableQuery.sort}
        onChange={handleTableChange}
        onRefresh={handleRefresh}
        refetch={refetch}
      />
    </Section>
  )
}

export default PatientStageIndex
