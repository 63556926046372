import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Card,
  Popconfirm,
  Space,
  Steps,
  Typography,
  message,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import type { StepProps } from 'antd/lib/steps'
import moment from 'moment'
import { findIndex, propEq } from 'ramda'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  BackwardTaskMutation,
  BackwardTaskVariables,
  ForwardTaskMutation,
  ForwardTaskVariables,
  StageTasksQueryQuery,
  StageTasksQueryVariables,
} from '../../../../../../graphql/types'
import {
  TaskStatus,
} from '../../../../../../graphql/types'
import ForwardTaskModal from './ForwardTaskModal'

const stageTasksQuery = gql`
  query StageTasksQuery($id: ID!) {
    stage(id: $id) {
      id
      type
      currentTask {
        id
        status
        patient {
          id
        }
        nextTask {
          type
        }
      }
      tasks {
        id
        status
        type
        completedByOwner
        owner {
          name
        }
      }
    }
  }
`

const forwardTaskMutation = gql`
  mutation ForwardTask($id: ID!, $payload: ForwardTaskInput!) {
    forwardTask(id: $id, payload: $payload) {
      id
    }
  }
`

const backwardTaskMutation = gql`
  mutation BackwardTask($id: ID!) {
    backwardTask(id: $id) {
      id
    }
  }
`

interface TaskStepsProps {
  stageId: string
}

const TaskSteps: FC<TaskStepsProps> = (props) => {
  const { stageId } = props
  const [form] = useForm()
  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery<
    StageTasksQueryQuery,
    StageTasksQueryVariables
  >(stageTasksQuery, { fetchPolicy: 'no-cache', variables: { id: stageId } })
  const [backwardTask] = useMutation<
    BackwardTaskMutation,
    BackwardTaskVariables
  >(backwardTaskMutation)
  const [forwardTask] = useMutation<ForwardTaskMutation, ForwardTaskVariables>(
    forwardTaskMutation,
  )

  if (loading)
    return <Card loading />

  if (!data?.stage)
    return <Card>無資料</Card>

  const tasks = data.stage.tasks
  const currentTask = data.stage.currentTask
  const currentTaskIndex = findIndex(
    task => propEq('id', currentTask?.id, task),
    tasks,
  )
  const handleOpenModal = () => setVisible(true)
  const handleCloseModal = () => setVisible(false)
  const handleRefetch = () => {
    form.resetFields()
    refetch()
  }
  const handleBackward = async (taskId: string) => {
    try {
      await backwardTask({
        variables: { id: taskId },
        update: (_cache, { data }) => {
          if (data?.backwardTask) {
            handleRefetch()
            message.warn('任務已退回')
          }
        },
      })
    }
    catch (error) {
      const errorMessage = error?.message ?? '退回任務時發生錯誤'
      message.error(errorMessage)
    }
  }
  const handleForward = async (currentTaskId: string) => {
    try {
      const values = await form.validateFields()
      await forwardTask({
        variables: {
          id: currentTaskId,
          payload: {
            nextOwner: values.nextOwner,
          },
        },
        update: (_cache, { data }) => {
          if (data?.forwardTask) {
            handleCloseModal()
            handleRefetch()
            message.success('成功完成任務')
          }
        },
      })
    }
    catch (error) {
      const errorMessage = error?.message ?? '完成任務時發生錯誤'
      message.error(errorMessage)
    }
  }

  return (
    <Card size="small" bodyStyle={{ overflow: 'scroll' }}>
      <Steps size="small" progressDot current={currentTaskIndex}>
        {tasks.map((task, index) => {
          const isFirst = index === 0
          const isCurrent = task.id === data.stage?.currentTask?.id
          const isWorking = task.status === TaskStatus.Working

          const status: StepProps['status']
            = task.status === TaskStatus.Completed
              ? 'finish'
              : task.status === TaskStatus.Working
                ? 'process'
                : 'wait'

          return (
            <Steps.Step
              key={task.id}
              status={status}
              title={t(`task.type.${task.type}`)}
              subTitle={(
                <>
                  <div>{task.owner?.name}</div>
                  {task.completedByOwner && (
                    <>
                      <Typography.Paragraph
                        type="secondary"
                        style={{ margin: 0 }}
                      >
                        {moment(task.completedByOwner).format('YYYY-MM-DD')}
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        type="secondary"
                        style={{ margin: 0 }}
                      >
                        {moment(task.completedByOwner).format('HH:mm')}
                      </Typography.Paragraph>
                    </>
                  )}
                  {isCurrent && isWorking && (
                    <Space>
                      {currentTask && (
                        <>
                          <Button
                            size="small"
                            type="link"
                            onClick={handleOpenModal}
                          >
                            完成
                          </Button>
                          <ForwardTaskModal
                            form={form}
                            visible={visible}
                            currentTask={currentTask}
                            stageType={data.stage.type}
                            handleCloseModal={handleCloseModal}
                            handleRefetch={handleRefetch}
                            handleForward={handleForward}
                          />
                        </>
                      )}
                      {!isFirst && (
                        <Popconfirm
                          title="確定要退回此任務？"
                          onConfirm={() => handleBackward(task.id)}
                        >
                          <Button size="small" type="link" danger>
                            退回
                          </Button>
                        </Popconfirm>
                      )}
                    </Space>
                  )}
                </>
              )}
            />
          )
        })}
      </Steps>
    </Card>
  )
}

export default TaskSteps
