import { gql, useQuery } from '@apollo/client'
import { TableQuery } from '@sov/common'
import { Card } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import Page from '../../components/layout/Page'
import type {
  EventFilterType,
  EventSorterField,
} from '../../components/table/event'
import EventTable from '../../components/table/event'

const patientEventsQuery = gql`
  query PatientEventsQuery(
    $id: ID!
    $query: EventsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patient(id: $id) {
      id
      name
      events(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...EventTable
        }
        total
        limit
        page
      }
    }
  }
  ${EventTable.fragment}
`

interface RouterProps {
  patientId: string
}

// 病患事件列表
function PatientEventIndex() {
  const match = useRouteMatch<RouterProps>()
  const patientId = match.params.patientId
  const { tableQuery, paginateQuery, handleTableChange }
    = TableQuery.useTableQuery<EventFilterType, EventSorterField>({
      limit: 100,
      sort: '-created',
    })
  const { data, loading } = useQuery(patientEventsQuery, {
    variables: { id: patientId, ...paginateQuery },
  })
  if (loading)
    return <Page loading />

  return (
    <Card size="small">
      <EventTable
        source={data.patient.events}
        sortInfo={tableQuery.sort}
        filterInfo={tableQuery.filters}
        loading={loading}
        handleChange={handleTableChange}
      />
    </Card>
  )
}

export default PatientEventIndex
