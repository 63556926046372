import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Button, Input } from 'antd'
import type moment from 'moment'
import React from 'react'

import RangePicker from '../../../components/common/RangePicker'

const FormItem = Form.Item

interface AppointmentQueryFormInput {
  clinicName?: string
  doctorName?: string
  patientName?: string
  startDateInterval?: [moment.Moment, moment.Moment]
}

type Props = {
  handleSearch: (x: FormComponentProps['form']) => void
  handleClear: (x: FormComponentProps['form']) => void
  formQuery: AppointmentQueryFormInput
} & FormComponentProps<AppointmentQueryFormInput>

function AppointmentQueryForm(props: Props) {
  const { form, formQuery, handleSearch, handleClear } = props
  const { getFieldDecorator } = form

  return (
    <Form layout="inline">
      <FormItem label="診所">
        {getFieldDecorator('clinicName', {
          initialValue: formQuery.clinicName,
        })(
          <Input
            allowClear
            style={{ width: 100 }}
            onPressEnter={() => handleSearch(form)}
          />,
        )}
      </FormItem>
      <FormItem label="醫師">
        {getFieldDecorator('doctorName', {
          initialValue: formQuery.doctorName,
        })(
          <Input
            allowClear
            style={{ width: 100 }}
            onPressEnter={() => handleSearch(form)}
          />,
        )}
      </FormItem>
      <FormItem label="病患">
        {getFieldDecorator('patientName', {
          initialValue: formQuery.patientName,
        })(
          <Input
            allowClear
            style={{ width: 100 }}
            onPressEnter={() => handleSearch(form)}
          />,
        )}
      </FormItem>
      <FormItem label="約診時間">
        {getFieldDecorator('startDateInterval', {
          initialValue: formQuery.startDateInterval,
        })(<RangePicker allowClear style={{ width: 250 }} />)}
      </FormItem>
      <FormItem>
        <Button type="primary" onClick={() => handleSearch(form)}>
          搜索
        </Button>
      </FormItem>
      <FormItem>
        <Button onClick={() => handleClear(form)}>清除</Button>
      </FormItem>
    </Form>
  )
}

export default Form.create<Props>()(AppointmentQueryForm)
