import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import type { Moment } from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  InvoiceListQuery,
  InvoiceListQueryVariables,
} from '../../../graphql/types'
import {
  PatientSource,
} from '../../../graphql/types'
import RangePicker from '../../components/common/RangePicker'
import { ClinicSelect } from '../../components/form/Select'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import type { InvoiceSortType } from '../../components/table/Invoice'
import InvoiceTable from '../../components/table/Invoice'

const invoiceList = gql`
  query InvoiceList(
    $query: InvoiceQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    invoices(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...InvoiceTable
      }
      total
      limit
      page
    }
  }
  ${InvoiceTable.fragment}
`

const formInput = {
  clinicId: MyStringParam,
  doctorId: MyStringParam,
  salesName: MyStringParam,
  patientName: MyStringParam,
  customerServiceName: MyStringParam,
  shippingDateInterval: DateIntervalParam,
  creditDateInterval: DateIntervalParam,
  patientSources: MyStringParam,
}

interface SearchForm {
  clinicId?: string
  doctorId?: string
  salesName?: string
  customerServiceName?: string
  patientName?: string
  shippingDateInterval?: [Moment, Moment]
  creditDateInterval?: [Moment, Moment]
  patientSources?: PatientSource
}

function InvoiceIndex() {
  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<any, InvoiceSortType>({ limit: 1000 })
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { t } = useTranslation()

  const [searchForm] = Form.useForm<SearchForm>()
  const {
    shippingDateInterval,
    creditDateInterval,
    patientSources,
    ...restFormQuery
  } = formQuery

  const { data, loading } = useQuery<
    InvoiceListQuery,
    InvoiceListQueryVariables
  >(invoiceList, {
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        shippingDateInterval: getDateIntervalQueryString(shippingDateInterval),
        creditDateInterval: getDateIntervalQueryString(creditDateInterval),
        patientSources: patientSources
          ? [patientSources as PatientSource]
          : undefined,
        ...restFormQuery,
      },
      ...paginateQuery,
    },
  })

  const invoices = data?.invoices
  const totalPrice = invoices?.docs?.reduce(
    (total, item) => total + item.totalPrice,
    0,
  )
  const totalReferral = invoices?.docs?.reduce(
    (total, item) => total + item.middlemanFee,
    0,
  )

  const handleSearch = () => {
    handleFormChange(searchForm.getFieldsValue())
  }

  const handleReset = () => {
    searchForm.resetFields()
    handleTableReset()
    handleFormReset()
  }

  return (
    <Page
      header={(
        <Row>
          <Col flex={1}>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'InvoiceList' }]}
            />
            <Title route={{ key: 'InvoiceList' }} />
          </Col>
          <Col>
            <h3>
              目前顯示總金額合計 $
              {totalPrice}
            </h3>
            <h3>
              目前顯示轉介費合計 $
              {totalReferral}
            </h3>
          </Col>
        </Row>
      )}
    >
      <Section>
        <Space size="small" style={{ marginBottom: 8 }}>
          <Form form={searchForm} initialValues={formQuery} layout="inline">
            <Form.Item name="clinicId" label="診所">
              <ClinicSelect style={{ width: 80 }} />
            </Form.Item>
            <Form.Item name="salesName" label="業務">
              <Input style={{ width: 80 }} onPressEnter={handleSearch} />
            </Form.Item>
            <Form.Item name="customerServiceName" label="客服">
              <Input style={{ width: 80 }} onPressEnter={handleSearch} />
            </Form.Item>
            <Form.Item name="patientName" label="病患">
              <Input style={{ width: 80 }} onPressEnter={handleSearch} />
            </Form.Item>
            <Form.Item name="patientSources" label="病患方案">
              <Select style={{ width: 80 }}>
                {Object.values(PatientSource).map(source => (
                  <Select.Option key={source} value={source}>
                    {t(`patient.source.${source}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="shippingDateInterval" label="出貨日">
              <RangePicker />
            </Form.Item>
            <Form.Item name="creditDateInterval" label="入帳日">
              <RangePicker />
            </Form.Item>
          </Form>
          <Button onClick={handleSearch}>搜索</Button>
          <Button onClick={handleReset}>清除</Button>
        </Space>
        <InvoiceTable
          source={invoices}
          sortInfo={tableQuery.sort}
          handleChange={handleTableChange}
          loading={loading}
        />
      </Section>
    </Page>
  )
}

export default InvoiceIndex
