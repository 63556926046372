import type { SortOrder, SorterResult } from 'antd/lib/table/interface'
import moment from 'moment'
import {
  all,
  compose,
  fromPairs,
  isEmpty,
  isNil,
  join,
  map,
  reject,
  replace,
  split,
  toPairs,
} from 'ramda'
import type {
  QueryParamConfig,
} from 'use-query-params'
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params'

import { isEmptyOrNil } from './isEmptyOrNil'

/**
 * 撰寫 encode/decode function 可參考 serialize
 * {@link https://github.com/pbeshai/serialize-query-params/blob/master/src/serialize.ts}
 */

const keyValueSeparator = '-'
const valuesSeparator = ','
const entrySeparator = '&'

/**
 * Encodes antd table filter
 *
 * @template FilterType
 * @param {Record<keyof FilterType, string[]>} filters
 * @returns {(string | undefined)} Example: `"filters"="category-feature_status-pending"`
 */
function encodeFilters<FilterType>(filters: Record<keyof FilterType, string[]>): string | undefined {
  if (isNil(filters))
    return undefined

  return compose<
    Record<keyof FilterType, string[]>,
    [string, string[]][],
    (string | undefined)[],
      string[],
      string
      >(
      join(entrySeparator),
      reject(isNil),
      map(([key, value]) => {
        if (typeof value === 'undefined')
          return undefined

        if (value === null || isEmpty(value))
          return `${key}`

        return `${key}${keyValueSeparator}${join(valuesSeparator, value)}`
      }),
      toPairs,
      )(filters)
}

/**
 * Decodes url to FilterType
 * Return array of string if filter is multiple
 *
 * @template FilterType
 * @param {(string | string[] | null | undefined)} input
 * @returns {(FilterType | undefined)}
 */
function decodeFilters<FilterType>(input: string | string[] | (string | null)[] | null | undefined): FilterType | undefined {
  if (input == null)
    return undefined

  const filtersStr = Array.isArray(input) ? input[0] : input

  if (!filtersStr || !filtersStr.length)
    return undefined

  const splitedByEntry = split(entrySeparator)(filtersStr)
  const keyValuePairList = splitedByEntry.map((entry) => {
    const [key, valueString] = split(keyValueSeparator, entry) as [
      keyof FilterType,
      string | undefined,
    ]
    if (typeof valueString === 'undefined') {
      const pair: [keyof FilterType, undefined] = [key, undefined]
      return pair
    }
    const values = isEmpty(valueString)
      ? []
      : split(valuesSeparator, valueString)

    if (values[0] === 'true' || values[0] === 'false') {
      /** boolean filters */
      const pair: [keyof FilterType, boolean] = [key, values[0] === 'true']
      return pair
    }

    const pair: [keyof FilterType, string[]] = [key, values]
    return pair
  })

  // @ts-ignore
  const filterList = fromPairs(keyValuePairList) as FilterType
  return filterList
}

export function FiltersParam<FilterType>(): QueryParamConfig<
  Record<keyof FilterType, string[]>,
  FilterType | undefined
> {
  return {
    encode: encodeFilters,
    decode: decodeFilters,
  }
}

export type AntSorterType<T> = Pick<SorterResult<T>, 'order' | 'field'> | null

/**
 * Encodes antd table sorter
 *
 * @template SorterField
 * @param {AntSorterType<SorterField>} { field, order } Only pick `order`, `field` fields.
 * @returns {(string | undefined)} Example: `"sort"="-created"`
 */
type EncodeSorterArgs<SorterField> = Pick<
  SorterResult<SorterField>,
  'order' | 'field'
> | null
export function encodeSorter<SorterField>(args: EncodeSorterArgs<SorterField>): string | null | undefined {
  if (isNil(args))
    return null

  let { field, order } = args

  if (isNil(order))
    return null

  if (Array.isArray(field))
    field = field.join('.')

  return order === 'descend' ? `-${field}` : `${field}`
}

/**
 * Decodes url to antd sorter type
 *
 * @template SorterField
 * @param {(string | string[])} sort
 * @returns {(AntSorterType<SorterField> | undefined)} Only pick `order`, `field` fields. Example: `{ "order": "descend", "field": "created" }`
 */
export function decodeSorter<SorterField extends string>(sort: string | null | undefined | (string | null)[]): AntSorterType<SorterField> | undefined {
  if (sort === null)
    return null

  /** not allow multiple fields sorting */
  if (isNil(sort) || Array.isArray(sort))
    return undefined

  const order: SortOrder = sort.charAt(0) === '-' ? 'descend' : 'ascend'
  return {
    order,
    field: replace('-', '', sort) as SorterField,
  }
}

export function SorterParam<SorterField>(): QueryParamConfig<
  EncodeSorterArgs<SorterField>,
  AntSorterType<SorterField> | undefined
> {
  return {
    encode: encodeSorter,
    decode: decodeSorter,
  }
}

/**
 * Encodes array of momnet if array of momnet is validate
 *
 * @param {([moment.Moment, moment.Moment] | moment.Moment)} dateInterval
 * @returns {(string | undefined)} Example: `"2019-10-07_2019-10-10"`
 */
function encodeDateInterval(dateInterval: [moment.Moment, moment.Moment] | moment.Moment | null): string | undefined {
  if (Array.isArray(dateInterval) && !all(moment.isMoment, dateInterval))
    return undefined
  if (!Array.isArray(dateInterval) && !moment.isMoment(dateInterval))
    return undefined

  const _dateInterval = Array.isArray(dateInterval)
    ? dateInterval
    : [dateInterval.startOf('m'), dateInterval.endOf('m')]

  const getDateString = (moment: moment.Moment) => moment.format('YYYY-MM-DD')

  return compose(join(entrySeparator), map(getDateString))(_dateInterval)
}

/**
 * Decodes url to array of moment
 *
 * @param {(string | string[] | null | undefined)} input Example: `"2019-10-07_2019-10-10"`
 * @returns {([moment.Moment, moment.Moment] | undefined)} Example: `[moment('2019-10-07'),moment('2019-10-10')]`
 */
function decodeDateInterval(input: string | string[] | (string | null)[] | null | undefined): [moment.Moment, moment.Moment] | undefined {
  if (isEmptyOrNil(input))
    return undefined

  const dateIntervalString = Array.isArray(input) ? input[0] : input
  if (isNil(dateIntervalString))
    return undefined

  return compose<string, [string, string], [moment.Moment, moment.Moment]>(
    ([startDateString, endDateString]) => [
      moment(startDateString).startOf('day'),
      moment(endDateString).endOf('day'),
    ],
    (input: string) => {
      const [startDateString, endDateString] = split(entrySeparator, input)
      return [startDateString, endDateString]
    },
  )(dateIntervalString)
}

export function getDateIntervalQueryString(dateInterval?: [moment.Moment, moment.Moment]) {
  return isEmptyOrNil(dateInterval)
    ? undefined
    : map(moment => `${moment.toISOString()}`, dateInterval)
}

export const DateIntervalParam: QueryParamConfig<
  [moment.Moment, moment.Moment],
  [moment.Moment, moment.Moment] | undefined
> = {
  encode: encodeDateInterval,
  decode: decodeDateInterval,
}

/**
 * Encodes momnet to `YYYY-MM-DD` format
 *
 * @param {(moment.Moment | null | undefined)} date Example: `moment('2019-08-28')`
 * @returns {(string | undefined)} Example: `"2019-08-28"`
 */
function encodeDateMoment(date: moment.Moment | null | undefined): string | undefined {
  return isNil(date) ? undefined : date.format('YYYY-MM-DD')
}

/**
 * Decodes url to single moment. Reject multiple selected date.
 *
 * @param {(string | string[] | null | undefined)} dateString Example: `"2019-08-28"`
 * @returns {(moment.Moment | undefined)} Example: `moment('2019-08-28')`
 */
function decodeDateMoment(dateString: string | string[] | (string | null)[] | null | undefined): moment.Moment | undefined {
  /** not allow multiple selected date */
  if (isNil(dateString) || Array.isArray(dateString))
    return undefined

  return moment(dateString, 'YYYY-MM-DD')
}

export const DateMomentParam: QueryParamConfig<
  moment.Moment,
  moment.Moment | undefined
> = {
  encode: encodeDateMoment,
  decode: decodeDateMoment,
}

export const MyNumberParam = withDefault(NumberParam, undefined, true)
export const MyStringParam = withDefault(StringParam, undefined, true)
export const MyArrayParam = withDefault(ArrayParam, undefined, true)
export const MyBooleanParam = withDefault(BooleanParam, undefined, true)
