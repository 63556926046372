import { getEmptyText } from '@sov/common'
import React from 'react'
import { Link } from 'react-router-dom'

import type { Invoice } from '../../../../graphql/types'

type IInvoice = Pick<Invoice, 'id'>

interface Props {
  invoiceItem: IInvoice
  style?: { [key: string]: string | number }
}

export function InvoiceLink(props: Props) {
  const { invoiceItem, style } = props

  if (!invoiceItem)
    return <div>{getEmptyText()}</div>

  const id = invoiceItem.id
  return (
    <Link style={style} to={`/invoices/${id}`}>
      (非製造) - 出貨單
    </Link>
  )
}

export default InvoiceLink
