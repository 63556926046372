import { gql, useMutation, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { DisplayPatientStatus, Link } from '@sov/ui/src'
import { Button, Form, Input, Modal, Row, Typography, message } from 'antd'
import type { FormInstance } from 'antd/lib/form/Form'
import { useForm } from 'antd/lib/form/Form'
import type { ModalProps } from 'antd/lib/modal'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import Table from 'antd/lib/table'
import { map } from 'ramda'
import React, { useEffect, useState } from 'react'

import type {
  PatientTransferListQueryQuery,
  PatientTransferListQueryQueryVariables,
  PatientTransferMutation,
  PatientTransferMutationVariables,
  PatientTransferTableFragment,
} from '../../../graphql/types'
import {
  PatientStatus,
  Role,
} from '../../../graphql/types'
import { EmployeeSelect } from '../../components/form/Select'
import Page, { Section } from '../../components/layout/Page'
import EmployeeLink from '../../components/link/employee'

const { ClinicLink, DoctorLink, PatientLink } = Link

export type SelectChangeHandler = NonNullable<
  TableProps<PatientTransferTableFragment>['rowSelection']
>['onChange']
interface PatientTransfewrTableProps {
  handleChange: TableProps<PatientTransferTableFragment>['onChange']
  loading?: boolean
  source?: {
    docs: PatientTransferTableFragment[]
    page?: number
    total: number
    limit: number
  }
  rowSelection: TableProps<PatientTransferTableFragment>['rowSelection']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

function PatientTransferTable(props: PatientTransfewrTableProps) {
  const { handleChange, loading, source = defaultSource, rowSelection } = props

  const columns: ColumnProps<PatientTransferTableFragment>[] = [
    {
      title: '診所',
      align: 'center',
      width: 60,
      dataIndex: 'clinic',
      key: 'clinic',
      render: val => <ClinicLink item={val} />,
    },
    {
      title: '醫師',
      align: 'center',
      width: 60,
      dataIndex: 'doctor',
      key: 'doctor',
      render: val => <DoctorLink item={val} />,
    },
    {
      title: '病患',
      align: 'center',
      width: 60,
      dataIndex: 'patient',
      key: 'patient',
      render: (_val, record) => <PatientLink item={record} />,
    },
    {
      title: 'AM',
      align: 'center',
      width: 60,
      dataIndex: 'accountManager',
      key: 'accountManager',
      render: val => <EmployeeLink item={val} />,
    },
    {
      title: '業務',
      align: 'center',
      width: 60,
      dataIndex: 'sales',
      key: 'sales',
      render: val => <EmployeeLink item={val} />,
    },
    {
      title: '技師',
      align: 'center',
      width: 60,
      dataIndex: 'technician',
      key: 'technician',
      render: val => <EmployeeLink item={val} />,
    },
    {
      title: '客服',
      align: 'center',
      width: 60,
      dataIndex: 'customerService',
      key: 'customerService',
      render: val => <EmployeeLink item={val} />,
    },
    {
      title: '狀態',
      align: 'center',
      width: 60,
      dataIndex: 'status',
      key: 'status',
      render: (_val, record) => {
        return <DisplayPatientStatus value={record.status} />
      },
    },
  ]

  const { docs, page, total, limit } = source

  const paginationConfig = TableQuery.getAntdPagination({
    page: page || 1,
    total,
    limit,
  })

  return (
    <Table<PatientTransferTableFragment>
      rowKey="id"
      size="small"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何病患' }}
      pagination={paginationConfig}
      onChange={handleChange}
      rowSelection={rowSelection}
    />
  )
}

PatientTransferTable.fragment = gql`
  fragment PatientTransferTable on Patient {
    id
    status
    clinic {
      id
      ...ClinicLink
    }
    doctor {
      id
      ...DoctorLink
    }
    accountManager {
      id
      ...EmployeeLink
    }
    sales {
      id
      ...EmployeeLink
    }
    technician {
      id
      ...EmployeeLink
    }
    customerService {
      id
      ...EmployeeLink
    }
    ...PatientLink
  }
  ${ClinicLink.fragment}
  ${DoctorLink.fragment}
  ${EmployeeLink.fragment}
  ${PatientLink.fragment}
`

interface TransferModalProps extends ModalProps {
  form: FormInstance
  handleSubmitForm: () => void
}

const TransferModal: React.FC<TransferModalProps> = (props) => {
  const { form, handleSubmitForm } = props

  const onOk = () => {
    handleSubmitForm()
  }

  return (
    <Modal title="選擇要轉移的對象" {...props} onOk={onOk}>
      <Form form={form}>
        <Form.Item name="accountManager" label="新AM">
          <EmployeeSelect showSearch />
        </Form.Item>
        <Form.Item name="sales" label="新業務">
          <EmployeeSelect
            showSearch
            query={{
              roles: [Role.Sales],
            }}
          />
        </Form.Item>
        <Form.Item name="technician" label="新技師">
          <EmployeeSelect showSearch />
        </Form.Item>
        <Form.Item name="customerService" label="新客服">
          <EmployeeSelect
            showSearch
            query={{
              roles: [Role.CustomerService],
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const patientTransferListQuery = gql`
  query PatientTransferListQuery(
    $query: PatientsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...PatientTransferTable
      }
      total
      limit
      page
    }
  }
  ${PatientTransferTable.fragment}
`

const patientTransferMutation = gql`
  mutation PatientTransfer($ids: [ID!]!, $payload: TransferPatientsInput!) {
    transferPatients(ids: $ids, payload: $payload) {
      id
      ...PatientTransferTable
    }
  }
  ${PatientTransferTable.fragment}
`

const formInput = {
  clinicName: MyStringParam,
  doctorName: MyStringParam,
  accountManager: MyStringParam,
  sales: MyStringParam,
  technician: MyStringParam,
  customerService: MyStringParam,
}

interface PatientFormInput {
  clinicName?: string
  doctorName?: string
  accountManager?: string
  sales?: string
  technician?: string
  customerService?: string
}

interface SearchFormProps {
  form: FormInstance<PatientFormInput>
  handleSearch: () => void
  handleClear: () => void
  formQuery: PatientFormInput
}

const SearchForm: React.FC<SearchFormProps> = (props) => {
  const { form, handleSearch, handleClear, formQuery } = props

  return (
    <Form form={form} initialValues={formQuery} layout="inline">
      <Form.Item name="clinicName" label="診所">
        <Input style={{ width: 100 }} onPressEnter={handleSearch} />
      </Form.Item>
      <Form.Item name="doctorName" label="醫師">
        <Input style={{ width: 100 }} onPressEnter={handleSearch} />
      </Form.Item>
      <Form.Item name="accountManager" label="AM">
        <EmployeeSelect showSearch allowClear />
      </Form.Item>
      <Form.Item name="sales" label="業務">
        <EmployeeSelect
          showSearch
          allowClear
          query={{
            roles: [Role.Sales],
          }}
        />
      </Form.Item>
      <Form.Item name="technician" label="技師">
        <EmployeeSelect showSearch allowClear />
      </Form.Item>
      <Form.Item name="customerService" label="客服">
        <EmployeeSelect
          showSearch
          allowClear
          query={{
            roles: [Role.CustomerService],
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button onClick={handleSearch}>搜索</Button>
      </Form.Item>
      <Form.Item>
        <Button onClick={handleClear}>重設</Button>
      </Form.Item>
    </Form>
  )
}

function PatientTransfer() {
  const { paginateQuery, handleTableChange } = TableQuery.useTableQuery({
    limit: 300,
  })
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientTransferListQueryQuery,
    PatientTransferListQueryQueryVariables
  >(patientTransferListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        clinicName: formQuery.clinicName,
        doctorName: formQuery.doctorName,
        accountManager: formQuery.accountManager,
        technician: formQuery.technician,
        sales: formQuery.sales,
        customerService: formQuery.customerService,
        // 除了已完成的病患，都有轉移業務的可能與需求
        status: [
          PatientStatus.Inactive,
          PatientStatus.Initial,
          PatientStatus.OnEval,
          PatientStatus.OnPrint,
        ],
      },
      ...paginateQuery,
    },
  })
  const [transferPatientSales] = useMutation<
    PatientTransferMutation,
    PatientTransferMutationVariables
  >(patientTransferMutation)
  const [searchForm] = useForm()
  const [transferForm] = useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [modalVisible, setModalVisible] = useState(false)

  const onSelectChange: SelectChangeHandler = (_, selectedRow) => {
    setSelectedRowKeys(map(selectedRow => selectedRow.id, selectedRow))
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleOpenModal = () => setModalVisible(true)
  const handleCloseModal = () => {
    transferForm.resetFields()
    setModalVisible(false)
  }

  const handleTransferPatient = async () => {
    try {
      const values = await transferForm.getFieldsValue()

      await transferPatientSales({
        variables: {
          ids: selectedRowKeys,
          payload: values,
        },
        update: async (_cache, { data }) => {
          if (data)
            message.success('批次轉移病患成功')
        },
      })
    }
    catch (error) {
      message.error(error)
    }
    handleCloseModal()
  }

  const handleSearch = () => {
    handleFormChange(searchForm.getFieldsValue())
  }

  const handleClear = () => {
    handleFormReset()
  }

  // 如果 formQuery 改變時，會自動 reset searchForm
  useEffect(() => {
    searchForm.setFieldsValue(formQuery)
  }, [formQuery])

  return (
    <Page>
      <Section flex={0}>
        <SearchForm
          form={searchForm}
          handleSearch={handleSearch}
          handleClear={handleClear}
          formQuery={formQuery}
        />
      </Section>
      <Section>
        <Row justify="space-between">
          <Typography.Title level={4}>病患批次轉移</Typography.Title>
          <Button type="primary" danger onClick={handleOpenModal}>
            批次轉移
          </Button>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '8px',
          }}
        />
        <PatientTransferTable
          source={data?.patients}
          handleChange={handleTableChange}
          loading={loading}
          rowSelection={rowSelection}
        />
        <TransferModal
          form={transferForm}
          visible={modalVisible}
          onCancel={handleCloseModal}
          handleSubmitForm={handleTransferPatient}
        />
      </Section>
    </Page>
  )
}

export default PatientTransfer
