import moment from 'moment'

export function sortByAppointmentStart(a, b) {
  /** 都沒有回診日排序不變 */
  if (!a.appointment && !b.appointment)
    return 0

  /** 前項沒有回診日，往後排 */
  if (!a.appointment)
    return 1

  /** 後項沒有回診日，往後排 */
  if (!b.appointment)
    return -1

  /** 日期降冪排列（新到舊） */
  return moment(a.appointment.startDate).isBefore(
    moment(b.appointment.startDate),
    'day',
  )
    ? 1
    : -1
}
