import React from 'react'
import simpleIcons from 'simple-icons'

const capitalize = (x: string) => x.charAt(0).toUpperCase() + x.slice(1)

function SimpleIcon(props) {
  const { name, style } = props
  const data = simpleIcons[capitalize(name)]
  const { svg, hex } = data
  const defaultStyle = {
    marginRight: 8,
    width: 30,
    fill: `#${hex}`,
    display: 'inline-block',
  }
  return (
    <div
      style={{ ...defaultStyle, ...style }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

export default SimpleIcon
