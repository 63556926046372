import 'moment/locale/zh-tw'

import './wdyr'
import '../configs/i18n'
import './index.less'

import { ApolloProvider } from '@apollo/client'
import { getApolloClient, keyOfGitCommitHash } from '@sov/common'
import { openNotification, useAuth } from '@sov/ui'
import { message as AntdMessage, ConfigProvider } from 'antd'
import zhTW from 'antd/lib/locale-provider/zh_TW'
import moment from 'moment'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import fragmentTypes from '../graphql/fragmentTypes'
import { authContext } from './context'
import AppRoutes from './routes/AppRoutes'
import EntranceRoute from './routes/EntranceRoute'

const { localStorageKey } = keyOfGitCommitHash

// 設定 Antd Message
AntdMessage.config({ duration: 1 })

// 設定 moment
moment.locale('zh-tw')

function clientStoredHashUpdatedCallback(updatedHash: string) {
  const clientStoredHash
    = window.localStorage.getItem(localStorageKey) || undefined
  const notificationConfirmCallback = () => {
    window.location.reload()
    window.localStorage.setItem(localStorageKey, updatedHash)
  }
  if (updatedHash !== clientStoredHash) {
    openNotification({
      localStorageKeyOfClientStoredHash: localStorageKey,
      notificationConfirmCallback,
    })
  }
}

function Root() {
  const { user, init } = useAuth()

  const AuthProvider = authContext.Provider

  // 設定 apollo
  const client = getApolloClient({
    clientStoredHashUpdatedCallback,
    envSetting: {
      apiHost: process.env.API_HOST as string,
      socketHost: process.env.SOCKET_HOST as string,
      nodeEnv: process.env.NODE_ENV as string,
    },
    possibleTypes: fragmentTypes.possibleTypes,
    user,
  })

  if (init) {
    return (
      <ApolloProvider client={client}>
        <ConfigProvider locale={zhTW}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              {user
                ? (
                  <AuthProvider value={user}>
                    <AppRoutes />
                  </AuthProvider>
                  )
                : (
                  <EntranceRoute />
                  )}
            </QueryParamProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ApolloProvider>
    )
  }

  return null
}

// 渲染 Root
function renderRoot() {
  render(<Root />, document.getElementById('root'))
}

renderRoot()
