import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Input, Modal } from 'antd'
import React from 'react'

const { TextArea } = Input

interface Props extends FormComponentProps {
  visible: boolean
  initialValue?: string
  onCancel: () => void
  onSubmit: (value: string) => void
}

export const EditNoteModal = Form.create<Props>()((props: Props) => {
  const { visible, initialValue, onCancel, onSubmit, form } = props
  const { getFieldDecorator, getFieldValue } = form
  return (
    <Modal
      visible={visible}
      title="修改病患備註"
      onCancel={onCancel}
      onOk={() => onSubmit(getFieldValue('note.design'))}
      okText="送出"
    >
      <div>
        {getFieldDecorator('note.design', {
          initialValue,
        })(<TextArea rows={8} />)}
      </div>
    </Modal>
  )
})
