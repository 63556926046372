import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling, getEmptyText } from '@sov/common'
import { Button, Descriptions, Form, Switch, message } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import type {
  AccountAppInfoQuery,
  AccountAppInfoQueryVariables,
  UpdateAccountInput,
  UpdateAccountShouldClearStorageMutation,
  UpdateAccountShouldClearStorageMutationVariables,
} from '../../../../../../graphql/types'
import type { TabKey } from '../../components/TabConfig'

interface FormFields {
  shouldClearStorage: UpdateAccountInput['shouldClearStorage']
}

type FormInitialValues = FormFields

const updateAccountShouldClearStorageMutation = gql`
  mutation UpdateAccountShouldClearStorage(
    $id: ID!
    $payload: UpdateAccountInput!
  ) {
    updateAccount(id: $id, payload: $payload) {
      id
    }
  }
`

const accountAppInfoQuery = gql`
  query AccountAppInfo($id: ID!) {
    account(id: $id) {
      id
      lastLogin
      currentAppStorage
      shouldClearStorage
      lastStorageClearDate
      lastLoginClientInfo
    }
  }
`

const OverFlowWrap = styled.div`
  overflow-wrap: break-word;
`
interface AccountAppInfoProps {
  accountId: string
  tabKey: TabKey
}

function AccountAppInfo(props: AccountAppInfoProps) {
  const { accountId } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, error, loading } = useQuery<
    AccountAppInfoQuery,
    AccountAppInfoQueryVariables
  >(accountAppInfoQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      id: accountId,
    },
  })

  const [update] = useMutation<
    UpdateAccountShouldClearStorageMutation,
    UpdateAccountShouldClearStorageMutationVariables
  >(updateAccountShouldClearStorageMutation)

  const [form] = Form.useForm<FormFields>()

  if (loading)
    return null

  if (error) {
    toErrorPage({
      message: error.message,
      redirect: {
        name: '帳戶總覽',
        url: '/accounts',
      },
    })
    return null
  }

  if (!data?.account) {
    toErrorPage({
      message: '不存在的帳戶',
      redirect: {
        name: '帳戶總覽',
        url: '/accounts',
      },
    })
    return null
  }

  const account = data?.account

  if (!account)
    return null

  const initialValues: FormInitialValues = {
    shouldClearStorage: account.shouldClearStorage,
  }

  const handleSubmit = async () => {
    try {
      const formValues = (await form.validateFields()) as FormFields
      const variables: UpdateAccountShouldClearStorageMutationVariables = {
        id: accountId,
        payload: {
          shouldClearStorage: formValues.shouldClearStorage,
        },
      }
      await update({
        variables,
        update: (_cache, { data }) => {
          if (data)
            message.success('成功更新需要清除該帳戶 app 所有暫存的 flag！')
        },
      })
    }
    catch (error) {
      if (error?.message) {
        /** graphQL errors */
        message.error(error?.message)
      }
      else {
        /** form errors or other errors */
        message.error(error)
      }
    }
  }

  return (
    <Form form={form} initialValues={initialValues}>
      <Descriptions column={1}>
        <Descriptions.Item label="最近一次登入客戶端資訊">
          <OverFlowWrap>
            {account.lastLoginClientInfo
              ? JSON.stringify(account.lastLoginClientInfo, null, 2)
              : getEmptyText()}
          </OverFlowWrap>
        </Descriptions.Item>
        <Descriptions.Item label="使用者目前暫存">
          <OverFlowWrap>
            {account.currentAppStorage
              ? JSON.stringify(account.currentAppStorage, null, 2)
              : getEmptyText()}
          </OverFlowWrap>
        </Descriptions.Item>
        <Descriptions.Item label="最後一次 app 暫存清除時間">
          {account.lastStorageClearDate
            ? moment(account.lastStorageClearDate).format('YYYY-MM-DD HH:mm:ss')
            : getEmptyText()}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item>
        <Form.Item
          label="需要清除該帳戶 app 所有暫存嗎"
          name="shouldClearStorage"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Button onClick={handleSubmit}>更新</Button>
      </Form.Item>
    </Form>
  )
}

export default AccountAppInfo
