import { gql } from '@apollo/client'

import { DoctorInfoFragment } from '../fragment'

export const updateDoctorMutation = gql`
  mutation UpdateDoctor($id: ID!, $payload: UpdateDoctorInput!) {
    updateDoctor(id: $id, payload: $payload) {
      ...DoctorInfo
    }
  }
  ${DoctorInfoFragment}
`
