import { Pie } from '@ant-design/charts'
import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FiltersParam,
  MyStringParam,
} from '@sov/common'
import { Card, Col, DatePicker, Row, Space } from 'antd'
import type { DatePickerProps } from 'antd/lib/date-picker'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import { stringify } from 'query-string'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import type { QueryParamConfigMap } from 'use-query-params'
import { encodeQueryParams } from 'use-query-params'

import type {
  AccountManagerStatisticPageQuery,
  AccountManagerStatisticPageQueryVariables,
} from '../../../../graphql/types'
import {
  AllPrivilege,
  PatientStatus,
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import Page from '../../../components/layout/Page'
import EmployeeMenu, { EmployeeMenuKey } from '../../../components/pageHeader/employee'
import { authContext } from '../../../context'
import ComparisonLabel from './ComparisonLabel'
import CreatedPatientCountCard from './CreatedPatientCountCard'
import OrderList from './OrderList'
import PatientCard from './PatientCard'
import StageList from './StageList'
import UnAuthorizedPage from './UnAuthorizedPage'

const accountManagerStatisticPageQuery = gql`
  query AccountManagerStatisticPage($id: ID!, $query: AccountManagerStatisticQuery!, $ordersLimit: Int, $stagesLimit: Int) {
    employee(id: $id) {
      id
      name
      ...EmployeeMenu
      orders(
        query: {
          isLatest: true
        },
        limit: $ordersLimit,
        sort: "-created"
      ) {
        docs {
          ...AccountManagerOrder
        }
      }
      stages(
        query: {
          status: [${StageStatus.Completed}],
          type: [${StageType.Print}]
        },
        limit: $stagesLimit,
        sort: "-expectedShippingDate"
      ) {
        docs {
          ...AccountManagerStage
        }
      }
    }
    accountManagerStatistic(id: $id, query: $query) {
      patientStatusAndCountMapList {
        status
        count
      }
      patientCountCreatedDuringPreviousMonth
      patientCountCreatedDuringCurrentMonth
      patientCountCompletedDuringCurrentMonth
    }
  }
  ${EmployeeMenu.fragments.EmployeeMenu}
  ${OrderList.fragments.AccountManagerOrder}
  ${StageList.fragments.AccountManagerStage}
`

const GrayBackgroundSection = styled.div``

const AbsolutePosition = styled.div`
  position: absolute;
  top: 85px;
  left: 270px;
`

const CustomizedListCard = styled(Card)`
  /** 覆寫 ant design 預設樣式 */
  .ant-card-body {
    padding: 32px 40px;
    height: 240px;
    overflow: scroll;
  }
`

const CustomizedPieChartCard = styled(Card)`
  /** 覆寫 ant design 預設樣式 */
  .ant-card-body {
    padding: 24px 16px;
  }
`

const CardFooter = styled.div`
  color: rgba(0, 0, 0, 0.45);
`

const patientListParameterConfigMap: QueryParamConfigMap = {
  accountManagerName: MyStringParam,
  lastPrintStageAtInterval: DateIntervalParam,
  filters: FiltersParam<Record<string, any[]>>(),
}

const stageListParameterConfigMap: QueryParamConfigMap = {
  accountManagerId: MyStringParam,
  filters: FiltersParam<Record<string, any[]>>(),
  sort: MyStringParam,
}

interface RouteParams {
  employeeId: string
}

function Statistic() {
  const match = useRouteMatch<RouteParams>()
  const { t } = useTranslation()
  const { employeeId } = match.params
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const auth = useContext(authContext)

  const canUserViewThisPage
    = auth?.privileges.includes(AllPrivilege.StatisticRead)
    || auth?.entity.id === employeeId

  const [selectedDate, setSelectedDate] = useState<Moment>(moment())

  const {
    data: accountManagerStatisticPageQueryData,
    loading: accountManagerStatisticPageQueryLoading,
  } = useQuery<
    AccountManagerStatisticPageQuery,
    AccountManagerStatisticPageQueryVariables
  >(accountManagerStatisticPageQuery, {
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: employeeId,
      query: {
        date: selectedDate,
      },
      ordersLimit: 20,
      stagesLimit: 20,
    },
    skip: !canUserViewThisPage,
  })

  if (!canUserViewThisPage)
    return <UnAuthorizedPage />

  // 病患狀態數量
  const patientStatusCount
    = accountManagerStatisticPageQueryData?.accountManagerStatistic
      ?.patientStatusAndCountMapList ?? []
  const initialPatientCount
    = patientStatusCount.filter(x => x.status === PatientStatus.Initial)[0]
      ?.count ?? 0
  const evalPatientCount
    = patientStatusCount.filter(x => x.status === PatientStatus.OnEval)[0]
      ?.count ?? 0

  const employee = accountManagerStatisticPageQueryData?.employee
  const patientCountCreatedDuringCurrentMonth
    = accountManagerStatisticPageQueryData?.accountManagerStatistic
      ?.patientCountCreatedDuringCurrentMonth ?? 0
  const patientCountCreatedDuringPreviousMonth
    = accountManagerStatisticPageQueryData?.accountManagerStatistic
      ?.patientCountCreatedDuringPreviousMonth ?? 0
  const patientCountCompletedDuringCurrentMonth
    = accountManagerStatisticPageQueryData?.accountManagerStatistic
      ?.patientCountCompletedDuringCurrentMonth ?? 0

  const orderList = employee?.orders?.docs ?? []
  const stageList = employee?.stages?.docs ?? []

  const handleMonthChange: DatePickerProps['onChange'] = (date) => {
    setSelectedDate(date)
  }

  const currentMonthAddedPatientCardTitle = moment(selectedDate).isSame(
    moment(),
    'month',
  )
    ? '本月新增病患'
    : `${moment(selectedDate).format('YYYY-MM')}月新增病患`

  const header = (
    <EmployeeMenu
      item={employee}
      selectedKeys={[EmployeeMenuKey.EMPLOYEE_DASHBOARD]}
      title={employee?.name}
    />
  )

  const encodedNewPatientQuery = encodeQueryParams(
    patientListParameterConfigMap,
    {
      filters: {
        status: [PatientStatus.Initial],
      },
    },
  )
  const linkUrlOfNewPatient = `/employees/${employeeId}/patients?${stringify(
    encodedNewPatientQuery,
  )}`

  const encodedEvaluatingPatientQuery = encodeQueryParams(
    patientListParameterConfigMap,
    {
      filters: {
        status: [PatientStatus.OnEval],
      },
    },
  )
  const linkUrlOfEvaluatingatient = `/employees/${employeeId}/patients?${stringify(
    encodedEvaluatingPatientQuery,
  )}`

  const currentMonthDateInterval: [Moment, Moment] = [
    moment(selectedDate).tz('Asia/Taipei').startOf('month'),
    moment(selectedDate).tz('Asia/Taipei').endOf('month'),
  ]
  const encodedCompletedPatientOfCurrentMonthQuery = encodeQueryParams(
    patientListParameterConfigMap,
    {
      lastPrintStageAtInterval: currentMonthDateInterval,
      filters: {
        status: [PatientStatus.Completed],
      },
    },
  )
  const linkUrlOfCompletedPatientOfCurrentMonth = `/employees/${employeeId}/patients?${stringify(
    encodedCompletedPatientOfCurrentMonthQuery,
  )}`

  const currentMonthCompletedPatientCardTitle = moment(selectedDate).isSame(
    moment(),
    'month',
  )
    ? '本月已完成'
    : `${moment(selectedDate).format('YYYY-MM')}月已完成`

  const encodedCompletedStageQuery = encodeQueryParams(
    stageListParameterConfigMap,
    {
      id: employeeId,
      filters: {
        status: [StageStatus.Completed],
        type: [StageType.Print],
      },
      sort: '-expectedShippingDate',
    },
  )
  const linkUrlOfCompletedStage = `/employees/${employeeId}/stages?${stringify(
    encodedCompletedStageQuery,
  )}`

  if (accountManagerStatisticPageQueryLoading)
    return <Page header={header} loading />

  return (
    <Page header={header}>
      {
        /** @todo: 改成不是用有沒有「刪除帳戶」權限來判斷使用者是不是系統管理員 */
        auth?.privileges.includes(AllPrivilege.AccountDelete) && (
          <AbsolutePosition>
            <DatePicker
              defaultValue={selectedDate}
              onChange={handleMonthChange}
              picker="month"
            />
          </AbsolutePosition>
        )
      }
      <GrayBackgroundSection>
        <Space size={8} direction="vertical" style={{ width: '100%' }}>
          <Row gutter={8}>
            <Col span={6}>
              <PatientCard
                borderColor="#1890FF"
                comparisonLabel={(
                  <ComparisonLabel
                    previous={patientCountCreatedDuringPreviousMonth}
                    current={patientCountCreatedDuringCurrentMonth}
                  />
                )}
                displayNumber={patientCountCreatedDuringCurrentMonth}
                footer={(
                  <CardFooter>
                    <span>上個月病患數</span>
                    <span>
                      {' '}
                      {patientCountCreatedDuringPreviousMonth}
                    </span>
                    <span> 人 </span>
                  </CardFooter>
                )}
                iconType="AVATAR_ADD"
                title={currentMonthAddedPatientCardTitle}
                titleTooltip="計算區間為當月 1 日至最後 1 日，中間新增的病患總數。"
              />
            </Col>
            <Col span={6}>
              <PatientCard
                borderColor="#F5222D"
                displayNumber={initialPatientCount}
                footer={<Link to={linkUrlOfNewPatient}>查看詳情</Link>}
                iconType="AVATAR_IN_BOOK"
                title="新病患"
                titleTooltip="若病患加入系統後自動認列狀態為新病患，請向醫生確認是否有評估訂單的需求，若否，則轉為「不追蹤」。"
              />
            </Col>
            <Col span={6}>
              <PatientCard
                borderColor="#FAAD14"
                displayNumber={evalPatientCount}
                footer={<Link to={linkUrlOfEvaluatingatient}>查看詳情</Link>}
                iconType="DOCUMENT"
                title="評估中"
                titleTooltip="若病患開始評估報告的製作，狀態為評估中，請向醫生確認是否已確認主要報告， 待確認後進行療程後續的牙套製作流程。"
              />
            </Col>
            <Col span={6}>
              <PatientCard
                borderColor="#52C41A"
                displayNumber={patientCountCompletedDuringCurrentMonth}
                footer={(
                  <Link to={linkUrlOfCompletedPatientOfCurrentMonth}>
                    查看詳情
                  </Link>
                )}
                iconType="CHECK"
                title={currentMonthCompletedPatientCardTitle}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <CustomizedListCard
                title="最新訂單"
                extra={(
                  <Link to={`/employees/${employeeId}/orders`} target="_blank">
                    前往追蹤訂單列表
                  </Link>
                )}
              >
                <OrderList orderList={orderList} />
              </CustomizedListCard>
            </Col>
            <Col span={12}>
              <CustomizedListCard
                title="最新出貨"
                extra={(
                  <Link to={linkUrlOfCompletedStage} target="_blank">
                    前往追蹤工單列表
                  </Link>
                )}
              >
                <StageList stageList={stageList} />
              </CustomizedListCard>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={18}>
              <CreatedPatientCountCard />
            </Col>
            <Col span={6}>
              <CustomizedPieChartCard title="病患狀態比例">
                <Pie
                  {...{
                    data: patientStatusCount,
                    angleField: 'count',
                    colorField: 'status',
                    radius: 0.8,
                    innerRadius: 0.6,
                    legend: {
                      flipPage: false,
                      layout: 'horizontal',
                      position: 'bottom',
                    },
                    color: (_ref) => {
                      switch (_ref.status) {
                        case PatientStatus.Initial:
                          return '#F5222D'
                        case PatientStatus.OnEval:
                          return '#FAAD14'
                        case PatientStatus.OnPrint:
                          return '#1890FF'
                        case PatientStatus.Completed:
                          return '#52C41A'
                        case PatientStatus.Inactive:
                          return 'rgba(0, 0, 0, 0.25)'
                        default:
                          return 'rgba(0, 0, 0, 0.25)'
                      }
                    },
                    meta: {
                      status: {
                        formatter: v => t(`patient.status.${v}`),
                      },
                    },
                    tooltip: {
                      formatter: datum => ({
                        name: t(`patient.status.${datum.status}`),
                        value: datum.count,
                      }),
                    },
                    statistic: {
                      title: {
                        formatter: () => {
                          return '病患數'
                        },
                      },
                    },
                  }}
                  data={patientStatusCount}
                />
              </CustomizedPieChartCard>
            </Col>
          </Row>
        </Space>
      </GrayBackgroundSection>
    </Page>
  )
}

export default Statistic
