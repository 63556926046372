import { gql } from '@apollo/client'

import EmployeeLink from '../../client/components/link/employee'

// @TODO account migrate
export const EmployeeInfoFragment = gql`
  fragment EmployeeInfo on Employee {
    id
    name
    onBoardDate
    title
    taskOwner
    approvers {
      id
      name
    }
    resigned
    role
    ...EmployeeLink
  }
  ${EmployeeLink.fragment}
`
