import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, getStageName } from '@sov/common'
import { StageName } from '@sov/ui'
import { Select } from 'antd'
import type { SelectProps, SelectValue } from 'antd/lib/select'
import { find, map, propEq, union } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  PatientStageSelectDocs,
  PatientStageSelectQuery,
  PatientStageSelectQueryVariables,
  StagesQuery,
} from '../../../../graphql/types'
import CustomSelect from './CustomSelect'

const fragment = gql`
  fragment StageInPatientStageSelect on Stage {
    id
    ...StageName
  }
  ${StageName.fragment}
`

const patientStageSelectQuery = gql`
  query PatientStageSelect(
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...StageInPatientStageSelect
      }
    }
  }
  ${fragment}
`

type PatientStageSelectProps = SelectProps<PatientStageSelectDocs> & {
  handleItemSelect?: (selectedItem: PatientStageSelectDocs) => void
  initItem?: PatientStageSelectDocs
  query: StagesQuery
}

function PatientStageSelect(props: PatientStageSelectProps) {
  const {
    handleItemSelect,
    initItem,
    query = { patient: undefined },
    ...restProps
  } = props
  const { t } = useTranslation()
  const [fetchedItems, setFetchItems] = useState<PatientStageSelectDocs[]>([])

  const variables = {
    query,
    page: 1,
    limit: 100,
    sort: '-updated',
  }

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const result = useQuery<
    PatientStageSelectQuery,
    PatientStageSelectQueryVariables
  >(patientStageSelectQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    skip: true,
    variables,
  })

  const getAndSetSelectStages = async () => {
    const response = await result.refetch(variables)
    if (response.data?.stages)
      setFetchItems(response.data.stages.docs)
  }

  useEffect(() => {
    if (query.patient)
      getAndSetSelectStages()
  }, [query.patient])

  const handleSelect = (id: SelectValue) => {
    if (fetchedItems) {
      const selectedItem = find(propEq('id', id), fetchedItems)
      if (selectedItem && handleItemSelect)
        handleItemSelect(selectedItem)
    }
  }

  const items = initItem ? union(fetchedItems, [initItem]) : fetchedItems

  const getStageLabel = (item: PatientStageSelectDocs) => {
    const [label, affix, serialNumber] = getStageName(t, item)
    return `${label} ${affix}${serialNumber}`
  }

  return (
    <CustomSelect onSelect={handleSelect} {...restProps}>
      {map(
        item => (
          <Select.Option value={item.id} key={item.id}>
            {getStageLabel(item)}
          </Select.Option>
        ),
        items,
      )}
    </CustomSelect>
  )
}

export default PatientStageSelect
