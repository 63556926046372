import { gql } from '@apollo/client'

import { FeedbackInfoFragment } from '../fragment'

export const updateFeedbackMutation = gql`
  mutation UpdateFeedback($id: ID!, $payload: UpdateFeedbackInput!) {
    updateFeedback(id: $id, payload: $payload) {
      ...FeedbackInfo
    }
  }
  ${FeedbackInfoFragment}
`
