import './PatientStage.less'

import { EditOutlined } from '@ant-design/icons'
import type { QueryResult } from '@apollo/client'
import { gql, useMutation } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { getEmptyText } from '@sov/common'
import { InstructionPopover, Link } from '@sov/ui'
import StageName from '@sov/ui/src/components/StageName'
import { Modal, Space, message } from 'antd'
import type { BadgeProps } from 'antd/lib/badge'
import Badge from 'antd/lib/badge'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import Table from 'antd/lib/table'
import moment from 'moment'
import { always, cond, equals, map, values } from 'ramda'
import type { DOMAttributes } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  ExpectedShippingDateModalFragment,
  PatientStageTableDropStageMutation,
  PatientStageTableDropStageMutationVariables,
  PatientStageTablePatientInfoFragment,
  PatientStageTableStageInfoFragment,
  PatientStageTableUndropStageMutation,
  PatientStageTableUndropStageMutationVariables,
} from '../../../graphql/types'
import {
  StageStatus,
  StageType,
  TaskType,
} from '../../../graphql/types'
import { useInstructionRemove } from '../../helpers/hooks/useInstruction'
import type {
  PatientStageFilterType,
  PatientStageSorterField,
} from '../../pages/patient/PatientStage'
import DropPopover from '../../pages/patient/PatientStage/DropPopover'
import ViewerFileIcon from '../common/ViewerFileIcon'
import ClinicLink from '../link/clinic'
import EmployeeLink from '../link/employee'
import ExpectedShippingDateModal from '../modal/ExpectedShippingDateModal'

const Clickable = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1890ff;
  }
`

const dropStageMutation = gql`
  mutation PatientStageTableDropStage($id: ID!) {
    dropStage(id: $id) {
      status
    }
  }
`

const undropStageMutation = gql`
  mutation PatientStageTableUndropStage($id: ID!) {
    undropStage(id: $id) {
      status
    }
  }
`

const { StageLink } = Link

interface Props {
  patientItem: PatientStageTablePatientInfoFragment
  source?: PatientStageTableStageInfoFragment[]
  loading?: boolean
  filterInfo?: PatientStageFilterType
  sortInfo?: AntSorterType<PatientStageSorterField>
  onChange?: TableProps<PatientStageTableStageInfoFragment>['onChange']
  onRefresh: () => void
  refetch: QueryResult['refetch']
}

function PatientStageTable(props: Props) {
  const {
    patientItem,
    source = [],
    loading = false,
    filterInfo = {},
    sortInfo,
    onChange,
    onRefresh,
    refetch,
  } = props
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedStage, setSelectedStage] = useState<
    ExpectedShippingDateModalFragment | undefined
  >(undefined)

  const getExpectedShippingDateClickHandler: (
    stage: ExpectedShippingDateModalFragment
  ) => DOMAttributes<HTMLDivElement>['onClick'] = stage => () => {
    setIsModalVisible(true)
    setSelectedStage(stage)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedStage(undefined)
  }

  const handleExpectedShippingDateUpdated = () => {
    refetch()
  }

  const [dropStage] = useMutation<
    PatientStageTableDropStageMutation,
    PatientStageTableDropStageMutationVariables
  >(dropStageMutation)
  const [undropStage] = useMutation<
    PatientStageTableUndropStageMutation,
    PatientStageTableUndropStageMutationVariables
  >(undropStageMutation)
  const { removeStageInstructionCard } = useInstructionRemove({
    onRemoved: onRefresh,
  })

  const handleDrop = async (stageId: string) => {
    try {
      await dropStage({
        variables: {
          id: stageId,
        },
        update: (_cache, { data }) => {
          if (data) {
            message.info('已廢棄工單')
            onRefresh()
          }
        },
      })
    }
    catch (e) {
      const errorMessage = e?.message ?? '廢棄工單時發生錯誤'
      Modal.error({
        title: '廢棄失敗',
        content: errorMessage,
      })
    }
  }

  const handleUndrop = async (stageId: string) => {
    try {
      await undropStage({
        variables: {
          id: stageId,
        },
        update: (_cache, { data }) => {
          if (data) {
            message.info('已還原廢棄工單')
            onRefresh()
          }
        },
      })
    }
    catch (e) {
      const errorMessage = e?.message ?? '還原廢棄工單時發生錯誤'
      message.error(errorMessage)
    }
  }

  const columns: ColumnProps<PatientStageTableStageInfoFragment>[] = [
    {
      title: '工單',
      width: 60,
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      filters: map(
        type => ({ text: t(`stage.type.${type}`), value: type }),
        values(StageType),
      ),
      onFilter: (value, record) => record.type === value,
      filteredValue: filterInfo.type ?? [],
      render: (_text, record) => {
        const style = {
          color:
            patientItem.currentEvalStage?.id === record.id ? 'red' : 'inherit',
        }
        return <StageLink item={record} style={style} />
      },
    },
    {
      title: '任務',
      width: 80,
      dataIndex: 'tasks',
      key: 'tasks',
      align: 'center',
      /** client filter */
      filters: map(
        type => ({ text: t(`task.type.${type}`), value: type }),
        values(TaskType),
      ),
      onFilter: (value, record) => record.currentTask?.type === value,
      render: (_text, record) => {
        return (
          <>
            <div>
              {record.currentTask
                ? t(`task.type.${record.currentTask.type}`)
                : null}
            </div>
            <div>
              {record.currentTask?.owner?.__typename === 'Employee' && (
                <EmployeeLink
                  item={record.currentTask.owner}
                  style={{ color: 'inherit' }}
                />
              )}
              {record.currentTask?.owner?.__typename === 'Clinic' && (
                <ClinicLink
                  item={record.currentTask.owner}
                  style={{ color: 'inherit' }}
                />
              )}
            </div>
          </>
        )
      },
    },
    {
      title: '預計出貨',
      width: 80,
      dataIndex: 'expectedShippingDate',
      key: 'expectedShippingDate',
      align: 'center',
      sorter: (a, b) =>
        moment(a.expectedShippingDate).isBefore(moment(b.expectedShippingDate))
          ? -1
          : 1,
      sortOrder:
        sortInfo?.field === 'expectedShippingDate' ? sortInfo.order : undefined,
      render: (text, record) => {
        return (
          <Clickable onClick={getExpectedShippingDateClickHandler(record)}>
            {record.expectedShippingDate
              ? (
                <Space size={8}>
                  <div>
                    {moment(record.expectedShippingDate).format('YYYY-MM-DD')}
                  </div>
                  <EditOutlined />
                </Space>
                )
              : (
                  getEmptyText()
                )}
          </Clickable>
        )
      },
    },
    {
      title: '重取模',
      width: 80,
      dataIndex: 'retrieve',
      key: 'retrieve',
      align: 'center',
      render: (_text, record) => {
        if (
          record.__typename === 'DesignStage'
          || record.__typename === 'PrintStage'
        ) {
          return (
            <div style={{ textAlign: 'center' }}>
              {record.instructionCard?.retrieve ? 'V' : ''}
            </div>
          )
        }
      },
    },
    {
      title: '指示卡',
      width: 80,
      dataIndex: 'instructionCard',
      key: 'instructionCard',
      align: 'center',
      render: (_text, record) => {
        if (
          record.__typename === 'DesignStage'
          || record.__typename === 'AccessoryStage'
          || record.__typename === 'PrintStage'
        ) {
          return (
            <InstructionPopover
              instructionItem={record.instructionCard}
              handleRemove={() => removeStageInstructionCard(record.id)}
            />
          )
        }

        return null
      },
    },
    {
      title: '工單注意事項',
      width: 300,
      dataIndex: 'note',
      key: 'note',
      align: 'left',
      render: (text) => {
        return <div style={{ paddingRight: 16 }}>{text}</div>
      },
    },
    {
      title: '狀態',
      width: 60,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filters: map(
        type => ({ text: t(`stage.status.${type}`), value: type }),
        values(StageStatus),
      ),
      onFilter: (value, record) => record.status === value,
      filteredValue: filterInfo.status ?? [],
      render: (text, record) => {
        const status = cond<StageStatus, BadgeProps['status']>([
          [
            equals<StageStatus>(StageStatus.Pending),
            always<BadgeProps['status']>('default'),
          ],
          [
            equals<StageStatus>(StageStatus.Started),
            always<BadgeProps['status']>('processing'),
          ],
          [
            equals<StageStatus>(StageStatus.Completed),
            always<BadgeProps['status']>('success'),
          ],
          [
            equals<StageStatus>(StageStatus.Dropped),
            always<BadgeProps['status']>('default'),
          ],
        ])(text)

        return (
          <DropPopover
            stage={record}
            handleDrop={handleDrop}
            handleUnDrop={handleUndrop}
          >
            <Badge status={status} />
            <span>{t(`stage.status.${text}`)}</span>
          </DropPopover>
        )
      },
    },
    {
      title: '模型',
      width: 60,
      dataIndex: 'hasFilesForViewer',
      key: 'hasFilesForViewer',
      align: 'center',
      /** client filter */
      filters: [
        { text: '有模型', value: 'true' },
        { text: '尚無模型', value: 'false' },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        if (
          record.__typename === 'EvalStage'
          || record.__typename === 'MoldStage'
          || record.__typename === 'DesignStage'
        )
          return String(record.hasFilesForViewer) === value

        return false
      },
      render: (_text, record) => {
        if (
          record.__typename === 'EvalStage'
          || record.__typename === 'MoldStage'
          || record.__typename === 'DesignStage'
        ) {
          return (
            <ViewerFileIcon
              hasFilesForViewer={record.hasFilesForViewer}
              stageItem={record}
            />
          )
        }
        return null
      },
    },
  ]

  return (
    <>
      <Table<PatientStageTableStageInfoFragment>
        className="table-patient-stage"
        rowKey="id"
        columns={columns}
        dataSource={source}
        loading={loading}
        onChange={onChange}
        pagination={false}
        rowClassName={(record) => {
          switch (record.status) {
            case StageStatus.Started:
              return 'row-stage-status-started'
            case StageStatus.Completed:
              return ''
            case StageStatus.Dropped:
              return 'row-stage-status-dropped'
            case StageStatus.Pending:
              return ''
          }
        }}
      />
      {selectedStage && (
        <ExpectedShippingDateModal
          handleExpectedShippingDateUpdated={handleExpectedShippingDateUpdated}
          handleModalClose={handleModalClose}
          isVisible={isModalVisible}
          selectedStage={selectedStage}
        />
      )}
    </>
  )
}

PatientStageTable.fragments = {
  PatientStageTablePatientInfo: gql`
    fragment PatientStageTablePatientInfo on Patient {
      id
      currentEvalStage {
        id
      }
    }
  `,
  PatientStageTableStageInfo: gql`
    fragment PatientStageTableStageInfo on Stage {
      id
      __typename
      type
      ...ExpectedShippingDateModal
      note
      status
      ...StageName
      ...StageLink
      ...DropPopover
      currentTask {
        id
        type
        owner {
          ...EmployeeLink
        }
      }

      ... on MoldStage {
        hasFilesForViewer
      }

      ... on EvalStage {
        initialEvalStage {
          id
          ...StageLink
        }
        hasFilesForViewer
      }

      ... on DesignStage {
        instructionCard {
          retrieve
          ...InstructionPopover
        }
        hasFilesForViewer
      }

      ... on PrintStage {
        instructionCard {
          retrieve
          ...InstructionPopover
        }
      }

      ... on AccessoryStage {
        instructionCard {
          ...InstructionPopover
        }
      }

      ...ViewerFileIcon
    }
    ${ExpectedShippingDateModal.fragments.ExpectedShippingDateModal}
    ${StageName.fragment}
    ${StageLink.fragment}
    ${EmployeeLink.fragment}
    ${InstructionPopover.fragments.InstructionPopover}
    ${DropPopover.fragment}
    ${ViewerFileIcon.fragments.ViewerFileIcon}
  `,
}

export default PatientStageTable
