import { CaretRightOutlined, EditOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import { MultiLine } from '@sov/ui'
import { Card, Collapse, message } from 'antd'
import type { FC } from 'react'
import React, { useState } from 'react'
import styled from 'styled-components'

import type {
  StagePatientInfoQuery,
  StagePatientInfoQueryVariables,
  UpdateDoctorNoteMutation,
  UpdateDoctorNoteMutationVariables,
  UpdatePatientInfoMutation,
  UpdatePatientInfoMutationVariables,
} from '../../../../../graphql/types'
import EditModal from './EditModal'

export const patientInfoQuery = gql`
  query StagePatientInfo($id: ID!) {
    patient(id: $id) {
      id
      doctor {
        id
        note
      }
      doctorInstruction {
        note
        ipr
        tads
        otherOrthdontalTools
        functionalAppliance
        maintainSpace {
          isMaintainingSpace
          note
        }
        changeProsthesisToTemp {
          need
          instruction
        }
        extractToothWhenNoSpace {
          intention
          toothType
        }
      }
      note {
        design
        braces
      }
    }
  }
`

const updateDoctorNote = gql`
  mutation UpdateDoctorNote($id: ID!, $payload: UpdateDoctorInput!) {
    updateDoctor(id: $id, payload: $payload) {
      id
    }
  }
`

const updatePatientInfo = gql`
  mutation UpdatePatientInfo($id: ID!, $payload: UpdatePatientInput!) {
    updatePatient(id: $id, payload: $payload) {
      id
    }
  }
`

const StyledEditIcon = styled(EditOutlined)`
  &:hover {
    color: #40a9ff;
  }
`

enum PanelKey {
  DOCTOR_NOTE = 'DOCTOR_NOTE',
  DOCTOR_INSTRUCTION = 'DOCTOR_INSTRUCTION',
  DESIGN_NOTE = 'DESIGN_NOTE',
  BRACES_NOTE = 'BRACES_NOTE',
}

interface Props {
  patientId: string
}

function StagePatientInfo(props: Props) {
  const { patientId } = props

  const [editingPanel, setEditingPanel] = useState<PanelKey>()
  const { data, loading, refetch } = useQuery<
    StagePatientInfoQuery,
    StagePatientInfoQueryVariables
  >(patientInfoQuery, {
    variables: { id: patientId },
  })
  const [updateDoctor] = useMutation<
    UpdateDoctorNoteMutation,
    UpdateDoctorNoteMutationVariables
  >(updateDoctorNote)
  const [updatePatient] = useMutation<
    UpdatePatientInfoMutation,
    UpdatePatientInfoMutationVariables
  >(updatePatientInfo)

  const handleCloseModal = () => setEditingPanel(undefined)
  const handleRefetch = () => refetch()

  const EditIcon: FC<{ panelKey: PanelKey }> = props => (
    <StyledEditIcon
      onClick={(event) => {
        event.stopPropagation()
        setEditingPanel(props.panelKey)
      }}
    />
  )

  if (loading) {
    return (
      <Card
        title="病患備註"
        bordered={false}
        loading
        style={{ width: 300 }}
        bodyStyle={{ padding: 0 }}
      />
    )
  }
  if (!data?.patient) {
    return (
      <Card
        title="病患備註"
        bordered={false}
        style={{ width: 300 }}
        bodyStyle={{ padding: 0 }}
      />
    )
  }

  const patientInfo = data.patient

  const handleUpdateDoctor = async (note?: string) => {
    try {
      await updateDoctor({
        variables: {
          id: patientInfo.doctor.id,
          payload: { note },
        },
        update: async (cache, { data }) => {
          if (data?.updateDoctor) {
            message.success('已更新醫生')
            handleRefetch()
            handleCloseModal()
          }
        },
      })
    }
    catch (error) {
      message.error(error)
    }
  }

  const handleUpdatePatient = async (formValue: {
    doctorInstruction?: string
    designNote?: string
    bracesNote?: string
  }) => {
    try {
      await updatePatient({
        variables: {
          id: patientId,
          payload: {
            doctorInstruction: {
              ...patientInfo.doctorInstruction,
              note:
                formValue.doctorInstruction
                ?? patientInfo.doctorInstruction.note,
            },
            note: {
              design: formValue.designNote ?? patientInfo.note.design,
              braces: formValue.bracesNote ?? patientInfo.note.braces,
            },
          },
        },
        update: async (cache, { data }) => {
          if (data?.updatePatient) {
            message.success('已更新病患')
            handleRefetch()
            handleCloseModal()
          }
        },
      })
    }
    catch (error) {
      message.error(error)
    }
  }

  return (
    <Card
      title="病患備註"
      size="small"
      loading={loading}
      style={{ width: 300 }}
      bodyStyle={{ padding: 0, marginTop: '1px' }}
    >
      <Collapse
        bordered={false}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        defaultActiveKey={Object.values(PanelKey)}
        style={{ backgroundColor: 'white' }}
      >
        <Collapse.Panel
          key={PanelKey.DOCTOR_NOTE}
          header="醫生特性/偏好:"
          extra={<EditIcon panelKey={PanelKey.DOCTOR_NOTE} />}
        >
          <MultiLine text={patientInfo.doctor.note} />
          <EditModal
            visible={editingPanel === PanelKey.DOCTOR_NOTE}
            formLabel="醫生特性/偏好:"
            initialValue={patientInfo.doctor.note}
            handleUpdate={handleUpdateDoctor}
            handleClose={handleCloseModal}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={PanelKey.DOCTOR_INSTRUCTION}
          header="醫生指示備忘:"
          extra={<EditIcon panelKey={PanelKey.DOCTOR_INSTRUCTION} />}
        >
          <MultiLine text={patientInfo.doctorInstruction.note} />
          <EditModal
            visible={editingPanel === PanelKey.DOCTOR_INSTRUCTION}
            formLabel="醫生指示備忘:"
            initialValue={patientInfo.doctorInstruction.note}
            handleUpdate={doctorInstruction =>
              handleUpdatePatient({ doctorInstruction })}
            handleClose={handleCloseModal}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={PanelKey.DESIGN_NOTE}
          header="設計組注意事項:"
          extra={<EditIcon panelKey={PanelKey.DESIGN_NOTE} />}
        >
          <MultiLine text={patientInfo.note.design} />
          <EditModal
            visible={editingPanel === PanelKey.DESIGN_NOTE}
            formLabel="設計組注意事項:"
            initialValue={patientInfo.note.design}
            handleUpdate={designNote => handleUpdatePatient({ designNote })}
            handleClose={handleCloseModal}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={PanelKey.BRACES_NOTE}
          header="牙套組注意事項:"
          extra={<EditIcon panelKey={PanelKey.BRACES_NOTE} />}
        >
          <MultiLine text={patientInfo.note.braces} />
          <EditModal
            visible={editingPanel === PanelKey.BRACES_NOTE}
            formLabel="牙套組注意事項:"
            initialValue={patientInfo.note.braces}
            handleUpdate={bracesNote => handleUpdatePatient({ bracesNote })}
            handleClose={handleCloseModal}
          />
        </Collapse.Panel>
      </Collapse>
    </Card>
  )
}

export default StagePatientInfo
