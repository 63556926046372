/* eslint max-len: 'off' */
import React from 'react'

function Axes() {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>axes</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="rule"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeDasharray="3"
        strokeLinecap="square"
      >
        <g
          id="Group-2"
          transform="translate(3.000000, 3.000000)"
          stroke="#979797"
        >
          <path d="M0,16 L30,16" id="Line" />
          <path d="M14,30 L14,0" id="Line" />
        </g>
      </g>
    </svg>
  )
}

export default Axes
