import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 0.25);
`

interface TableHeaderAndTableRowProps {
  headerTitle: string
}

const TableHeaderAndTableRow: FC<TableHeaderAndTableRowProps> = (props) => {
  const { children, headerTitle } = props

  return (
    <Container>
      <Title>{headerTitle}</Title>
      {children}
    </Container>
  )
}

export default TableHeaderAndTableRow
