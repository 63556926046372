import { gql, useMutation } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery } from '@sov/common'
import {
  DisplayPatientBrand,
  DisplayPatientStatus,
  TablePatientInfo,
} from '@sov/ui'
import StageLink from '@sov/ui/src/components/Link/Stage'
import { Input, Space, Table, Typography, message } from 'antd'
import type { ColumnProps, TablePaginationConfig, TableProps } from 'antd/lib/table'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type {
  PatientTableFragment,
  PatientsQuery,
  UpdatePatientTableMutation,
  UpdatePatientTableVariables,
} from '../../../graphql/types'
import {
  PatientBrand,
  PatientSource,
  PatientStatus,
} from '../../../graphql/types'
import Editable from '../editable'
import EmployeeLink from '../link/employee'

const fragment = gql`
  fragment PatientTable on Patient {
    ...TablePatientInfo
    status
    patientCode
    cabinetCode
    ...PatientLink
    note {
      sales
      design
      braces
    }
    photos {
      frontFace {
        id
        thumbnailPath
      }
    }
    clinic {
      ...ClinicLink
      specialContract
    }
    doctor {
      id
      name
    }
    accountManager {
      id
      name
    }
    technician {
      id
      name
    }
    sales {
      id
      name
    }
    customerService {
      id
      name
    }
    currentEvalStage {
      ...StageLink
    }
    payment {
      brand
      source
    }
    meta {
      currentStepNumber
      finalStepNumber
      # 上次報告
      lastEvalStage {
        ...StageLink
      }
      lastEvalStageAt
      # 上次 Step
      lastPrintStage {
        ...StageLink
      }
      lastPrintStageAt
    }
    nextAppointmentDate
    # 創建
    created
    creator {
      id
      name
    }
  }
  ${TablePatientInfo.fragment}
  ${StageLink.fragment}
`

const updatePatientTableMutation = gql`
  mutation UpdatePatientTable($id: ID!, $payload: UpdatePatientInput!) {
    updatePatient(id: $id, payload: $payload) {
      id
      ...PatientTable
    }
  }
  ${fragment}
`

export type PatientFilterType = Pick<
  PatientsQuery,
  'status' | 'paymentBrand' | 'paymentSource'
>

export type PatientSorterField =
  | 'meta.lastEvalStageAt'
  | 'meta.lastPrintStageAt'
  | 'meta.finalStepNumber'
  | 'created'

interface Props extends TableProps<PatientTableFragment> {
  handleSearch?: () => void
  handleReset?: () => void
  filterInfo?: PatientFilterType
  handleChange: TableProps<PatientTableFragment>['onChange']
  sortInfo?: AntSorterType<PatientSorterField>
  source?: {
    docs: PatientTableFragment[]
    page?: number
    total: number
    limit: number
  }
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

export function PatientTable(props: Props) {
  const {
    filterInfo = {},
    handleChange,
    source = defaultSource,
    scroll,
    loading = false,
    sortInfo,
  } = props
  const [, setHoverIndex] = useState(null)
  const [updatePatient] = useMutation<
    UpdatePatientTableMutation,
    UpdatePatientTableVariables
  >(updatePatientTableMutation)
  const { t } = useTranslation()

  const columns: ColumnProps<PatientTableFragment>[] = [
    {
      title: '病患',
      width: 120,
      align: 'center',
      dataIndex: 'patient',
      key: 'patient',
      render: (_value, record) => {
        return <TablePatientInfo patient={record} />
      },
    },
    {
      title: '品牌',
      width: 60,
      align: 'center',
      dataIndex: 'paymentBrand',
      key: 'paymentBrand',
      filters: Object.values(PatientBrand).map(x => ({
        text: <DisplayPatientBrand value={x} />,
        value: x,
      })),
      filteredValue: filterInfo.paymentBrand ?? [],
      filterMultiple: true,
      render: (_value, record) => {
        return <DisplayPatientBrand value={record.payment.brand} />
      },
    },
    {
      title: '客源',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'source'],
      key: 'paymentSource',
      filters: Object.values(PatientSource).map(x => ({
        text: t(`patient.source.${x}`),
        value: x,
      })),
      filteredValue: filterInfo.paymentSource ?? [],
      filterMultiple: true,
      render: (_value, record) => {
        return <div>{t(`patient.source.${record.payment.source}`)}</div>
      },
    },
    {
      title: '代號 / 塔位',
      width: 60,
      align: 'center',
      dataIndex: 'patientCode',
      key: 'patientCode',
      render: (_, record) => {
        const updatePatientCode = async (value) => {
          try {
            if (record) {
              await updatePatient({
                variables: {
                  id: record.id,
                  payload: {
                    patientCode: value,
                  },
                },
                update: (_cache, { data }) => {
                  if (data)
                    message.info(`已更新病患代號: ${data.updatePatient?.name}`)
                },
              })
            }
          }
          catch (e) {
            message.error(`更新失敗: ${e.message}`)
          }
        }
        const updateCabinetCode = async (value) => {
          try {
            if (record) {
              await updatePatient({
                variables: {
                  id: record.id,
                  payload: {
                    cabinetCode: value,
                  },
                },
                update: (_cache, { data }) => {
                  if (data)
                    message.info(`已更新病患塔位: ${data.updatePatient?.name}`)
                },
              })
            }
          }
          catch (e) {
            message.error(`更新失敗: ${e.message}`)
          }
        }

        return (
          <>
            <Editable
              name="patientCode"
              value={record.patientCode}
              handleSubmit={updatePatientCode}
            >
              <Input onPressEnter={updatePatientCode} />
            </Editable>
            <br />
            <Editable
              name="cabinetCode"
              value={record.cabinetCode}
              handleSubmit={updateCabinetCode}
            >
              <Input onPressEnter={updateCabinetCode} />
            </Editable>
          </>
        )
      },
    },
    {
      title: 'AM / 技師',
      width: 60,
      align: 'center',
      dataIndex: 'accountManager',
      key: 'accountManager',
      render: (_value, record) => {
        const accountManager = record.accountManager
        const technician = record.technician
        return (
          <>
            <EmployeeLink item={accountManager} />
            <br />
            <EmployeeLink item={technician} />
          </>
        )
      },
    },
    {
      title: '業務 / 客服',
      width: 60,
      align: 'center',
      dataIndex: 'sales',
      key: 'sales',
      render: (_value, record) => {
        const sales = record.sales
        const customerService = record.customerService
        return (
          <>
            <EmployeeLink item={sales} />
            <br />
            <EmployeeLink item={customerService} />
          </>
        )
      },
    },
    {
      title: '上次報告',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'lastEvalStageAt'],
      key: 'lastEvalStageAt',
      sorter: (x, y) =>
        moment(x.meta.lastEvalStageAt).isBefore(moment(y.meta.lastEvalStageAt))
          ? -1
          : 1,
      sortOrder:
        sortInfo?.field === 'meta.lastEvalStageAt' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return (
          record.meta.lastEvalStage && (
            <>
              <StageLink item={record.meta.lastEvalStage} />
              <div>
                <Typography.Text type="secondary">
                  {moment(record.meta.lastEvalStageAt).format('YYYY-MM-DD')}
                </Typography.Text>
              </div>
            </>
          )
        )
      },
    },
    {
      title: '上次 Step',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'lastPrintStageAt'],
      key: 'lastPrintStageAt',
      sorter: (x, y) =>
        moment(x.meta.lastPrintStageAt).isBefore(
          moment(y.meta.lastPrintStageAt),
        )
          ? -1
          : 1,
      sortOrder:
        sortInfo?.field === 'meta.lastPrintStageAt'
          ? sortInfo.order
          : undefined,
      render: (_value, record) => {
        return (
          record.meta.lastPrintStage && (
            <>
              <StageLink item={record.meta.lastPrintStage} />
              <div>
                <Typography.Text type="secondary">
                  {moment(record.meta.lastPrintStageAt).format('YYYY-MM-DD')}
                </Typography.Text>
              </div>
            </>
          )
        )
      },
    },
    {
      title: 'Final',
      width: 30,
      align: 'center',
      dataIndex: ['meta', 'finalStepNumber'],
      key: 'finalStepNumber',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'meta.finalStepNumber' ? sortInfo.order : undefined,
    },
    {
      title: '約診',
      width: 60,
      align: 'center',
      dataIndex: ['nextAppointmentDate'],
      key: 'nextAppointmentDate',
      sorter: (x, y) =>
        moment(x.nextAppointmentDate).isBefore(moment(y.nextAppointmentDate))
          ? -1
          : 1,
      sortOrder:
        sortInfo?.field === 'nextAppointmentDate' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return (
          record.nextAppointmentDate && (
            <>
              <Link to={`patients/${record.id}/appointments`}>
                <Typography.Text type="secondary">
                  {moment(record.nextAppointmentDate).format('YYYY-MM-DD')}
                </Typography.Text>
                <br />
                <Typography.Text type="secondary">
                  {moment(record.nextAppointmentDate).format('HH:mm')}
                </Typography.Text>
              </Link>
            </>
          )
        )
      },
    },
    {
      title: '新增日/人',
      width: 60,
      align: 'center',
      dataIndex: ['created'],
      key: 'created',
      sorter: (x, y) =>
        moment(x.created).isBefore(moment(y.created)) ? -1 : 1,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return (
          <Space direction="vertical" size={0}>
            {record.creator?.name && (
              <Typography.Text type="secondary">
                {record.creator?.name}
              </Typography.Text>
            )}
            {record.created && (
              <Typography.Text type="secondary">
                {moment(record.created).format('YYYY-MM-DD')}
              </Typography.Text>
            )}
          </Space>
        )
      },
    },
    {
      title: '狀態',
      width: 60,
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      filters: Object.values(PatientStatus).map(status => ({
        text: <DisplayPatientStatus value={status} />,
        value: status,
      })),
      filteredValue: filterInfo.status ?? [],
      filterMultiple: true,
      render: (_value, record) => {
        return <DisplayPatientStatus value={record.status} />
      },
    },
  ]

  const { docs, page, total, limit } = source

  const handleRow = (_record, index) => ({
    onMouseEnter: () => {
      setHoverIndex(index)
    },
  })

  const paginationConfig: TablePaginationConfig = scroll
    ? {
        current: page || 1,
        total: total || 1,
        pageSize: limit || 1,
        size: 'small',
      }
    : TableQuery.getAntdPagination({ page: page || 1, total, limit })

  return (
    <Table<PatientTableFragment>
      scroll={scroll}
      rowKey="id"
      size="small"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何病患' }}
      pagination={paginationConfig}
      onChange={handleChange}
      onRow={handleRow}
    />
  )
}

PatientTable.fragment = fragment

export default PatientTable
