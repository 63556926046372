import type { FormItemProps } from 'antd/lib/form'

export const twoColumnLayout: FormItemProps = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  labelAlign: 'left',
}

export const defaultLayout: FormItemProps = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  labelAlign: 'left',
}
