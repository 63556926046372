import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyBooleanParam,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Checkbox, Form, Input } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import type { Moment } from 'moment'
import React from 'react'

import type {
  MoldStagesTrackQuery,
  MoldStagesTrackQueryQuery,
  MoldStagesTrackQueryVariables,
} from '../../../../graphql/types'
import RangePicker from '../../../components/common/RangePicker'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import TableMoldStage from '../../../components/table/MoldStage'

const moldStagesTrackQuery = gql`
  query MoldStagesTrackQuery(
    $query: MoldStagesTrackQuery
    $page: Int = 1
    $limit: Int = 30
    $sort: String = "-created"
  ) {
    moldStagesTrack(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...TableMoldStage
      }
      total
      limit
      page
    }
  }
  ${TableMoldStage.fragment}
`

export type MoldStageFilterType = Pick<MoldStagesTrackQuery, 'hasEvalStage'>

export type MoldStageSortFields = 'created'

export interface MoldStageIndexFormInput {
  patientName?: string
  createdInterval?: [Moment, Moment]
  firstTime?: boolean
}

export const moldStageIndexformInput = {
  patientName: MyStringParam,
  createdInterval: DateIntervalParam,
  firstTime: MyBooleanParam,
}

interface MoldStageFormProps {
  form: FormInstance<MoldStageIndexFormInput>
  handleSearch: (x: FormInstance<MoldStageIndexFormInput>) => void
  handleClear: (x: FormInstance<MoldStageIndexFormInput>) => void
  formQuery: MoldStageIndexFormInput
}

export function MoldStageForm(props: MoldStageFormProps) {
  const { form, handleSearch, handleClear, formQuery } = props

  return (
    <Form
      form={form}
      layout="inline"
      initialValues={{
        patientName: formQuery.patientName,
        createdInterval: formQuery.createdInterval,
      }}
    >
      <Form.Item label="病患" name="patientName">
        <Input style={{ width: 120 }} onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Form.Item label="收模日" name="createdInterval">
        <RangePicker style={{ width: 250 }} />
      </Form.Item>
      <Form.Item name="firstTime" valuePropName="checked">
        <Checkbox onChange={() => handleSearch(form)}>只顯示新病患</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button onClick={() => handleSearch(form)} data-testid="search-btn">
          搜索
        </Button>
      </Form.Item>
      <Form.Item>
        <Button onClick={() => handleClear(form)} data-testid="clear-input">
          清除
        </Button>
      </Form.Item>
    </Form>
  )
}

function MoldStage() {
  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<MoldStageFilterType, MoldStageSortFields>({
      limit: 20,
      sort: '-created',
    })
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(moldStageIndexformInput)

  const [form] = Form.useForm<MoldStageIndexFormInput>()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    MoldStagesTrackQueryQuery,
    MoldStagesTrackQueryVariables
  >(moldStagesTrackQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        patientName: formQuery.patientName,
        startOfCreated:
          formQuery.createdInterval
          && formQuery.createdInterval[0].toISOString(),
        endOfCreated:
          formQuery.createdInterval
          && formQuery.createdInterval[1].toISOString(),
        firstTime: formQuery.firstTime,
        hasEvalStage: tableQuery.filters?.hasEvalStage,
      },
      ...paginateQuery,
    },
  })

  const handleSearch = (form: FormInstance<MoldStageIndexFormInput>) => {
    handleFormChange(form.getFieldsValue())
  }

  const handleClear = (form: FormInstance<MoldStageIndexFormInput>) => {
    form.resetFields()
    handleFormReset()
    handleTableReset()
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'MoldStageList' }]}
          />
          <Title route={{ key: 'MoldStageList' }} />
        </>
      )}
    >
      <Section flex="0">
        <MoldStageForm
          form={form}
          handleSearch={handleSearch}
          handleClear={handleClear}
          formQuery={formQuery}
        />
      </Section>
      <Section>
        <TableMoldStage
          source={data?.moldStagesTrack}
          loading={loading}
          handleTableChange={handleTableChange}
          filterInfo={tableQuery.filters}
          sortInfo={tableQuery.sort}
        />
      </Section>
    </Page>
  )
}

export default MoldStage
