import { ifElse, isNil, mergeDeepRight, pathOr } from 'ramda'
import type { RouteComponentProps } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

export interface RedirectType {
  name: string
  url: string
}

export interface LocationStateErrorType {
  title?: string
  message: string
  redirect?: RedirectType
}

export type RequiredLocationStateErrorType = Required<LocationStateErrorType>

export const defaultError = {
  title: '發生錯誤',
  message: '抱歉，找無此頁面！',
  redirect: {
    name: '首頁',
    url: '/',
  },
}

export function getErrorFromLocation(location: RouteComponentProps['location']) {
  const error: RequiredLocationStateErrorType = ifElse(
    isNil,
    () => defaultError,
    mergeDeepRight(defaultError),
  )(pathOr(null, ['state', 'error'], location))

  return error
}

export function useErrorHandling() {
  const history = useHistory()
  const toErrorPage = (error: LocationStateErrorType | string) => {
    const errorIsString = typeof error === 'string'
    const locationStateError = errorIsString
      ? mergeDeepRight(defaultError, { message: error })
      : mergeDeepRight(defaultError, error)
    history.replace({
      pathname: '/error',
      state: {
        error: locationStateError,
      },
    })
  }

  return { toErrorPage }
}
