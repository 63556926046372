import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 0.65);
`

const ReadAllButton = styled.a<{ disable: boolean }>`
  ${props =>
    props.disable ? 'pointer-events: none; color: rgba(0, 0, 0, 0.25);' : ''}
`

interface PopoverTitleProps {
  unReadCount: number
  handleReadAll?: () => Promise<void>
}

function PopoverTitle(props: PopoverTitleProps) {
  const { unReadCount, handleReadAll } = props

  return (
    <Wrapper>
      <Title>最新</Title>
      {handleReadAll && (
        <ReadAllButton
          disable={unReadCount < 1}
          onClick={() => handleReadAll()}
        >
          全部標示已讀
        </ReadAllButton>
      )}
    </Wrapper>
  )
}

export default PopoverTitle
