import { Menu } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { gmpRoutes } from '../../routes/GMPRoutes'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Title from '../layout/Title'
import { getMatchRoute } from './utils'

function GMPMenu() {
  const matchRoute = getMatchRoute(useLocation().pathname)
  const selectedKeys = matchRoute ? [matchRoute.key] : undefined

  return (
    <div style={{ marginBottom: -16 }}>
      {/* 預設導向「訂單記錄」分頁 */}
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'FactoryOrders', render: () => 'GMP 工作紀錄表' },
        ]}
      />
      <Title
        route={{
          key: 'FactoryOrders',
          render: () => 'GMP 工作紀錄表',
          renderSubtitle: () => '依照各指定條件顯示表單資料',
        }}
      />

      <Menu mode="horizontal" selectedKeys={selectedKeys}>
        {Object.keys(gmpRoutes).map(routeKey => (
          <Menu.Item key={routeKey}>
            <Link to={gmpRoutes[routeKey].path}>
              {gmpRoutes[routeKey].title}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )
}

export default GMPMenu
