import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import type { UploadFile } from 'antd/lib/upload/interface'
import { either, isEmpty, isNil, join, lensPath, propEq, reject } from 'ramda'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import type { File, ToothPosition } from '../../codegen/types'
import GqlUpload from '../GqlUpload'
import { AutoResizeTextArea } from './AutoResizeTextArea'
import type { UploadConfig } from './UploadPreviewList'
import { UploadPreviewList } from './UploadPreviewList'

export const MessageFormContainer = styled.div`
  width: 100%;
  padding: 0px 12px 12px 12px;
  background-color: white;
`

const BorderTop = styled.div`
  border-top: solid 1px #e0e0e0;
`

export interface MessageFormInput {
  image?: UploadFile<File>[]
  content?: string
}

interface HandleSubmitPayload {
  image?: string
  content?: string
}

function isEmptyOrNil(value: any): value is null | undefined {
  return either(isEmpty, isNil)(value)
}

type MessageFormProps = {
  handleSubmit: (x: HandleSubmitPayload) => Promise<void>
  handleInputResize?: () => void
  uploadConfig: UploadConfig
  teethSelection?: {
    selectedTeeth: ToothPosition[] | []
    getDefaultTeethMessageText: (selectedTeeth: ToothPosition[]) => string
  }
} & FormComponentProps<MessageFormInput>

const MessageForm = Form.create<MessageFormProps>()(
  (props: MessageFormProps) => {
    const {
      handleSubmit,
      form,
      handleInputResize,
      uploadConfig,
      teethSelection,
    } = props
    const { setFieldsValue, getFieldsValue, resetFields } = form
    const { image, content } = getFieldsValue() as MessageFormInput

    useEffect(() => {
      if (teethSelection) {
        const { selectedTeeth, getDefaultTeethMessageText } = teethSelection
        setFieldsValue({
          content: isEmpty(selectedTeeth)
            ? ''
            : getDefaultTeethMessageText(selectedTeeth),
        })
      }
    }, [join('', teethSelection?.selectedTeeth || [])])

    const emptyInput = isEmptyOrNil(content) && isEmptyOrNil(image)
    const imageUploading = image && image.length > 0 && isNil(image[0].response)
    const submitDisabled = imageUploading || emptyInput

    const handleSubmitFormValue = async () => {
      const payload = GqlUpload.fileFieldTransformer<HandleSubmitPayload>(
        lensPath(['image']),
        form.getFieldsValue(),
      )

      await handleSubmit(payload)
      resetFields()
    }

    const handleRemoveImage = (uid: string) => {
      if (image)
        setFieldsValue({ image: reject(propEq('uid', uid), image) })
    }

    return (
      <BorderTop>
        <Form>
          {/* 目前只支援單圖片上傳 */}
          <UploadPreviewList
            imageList={image}
            handleRemoveImage={handleRemoveImage}
          />
          <AutoResizeTextArea
            form={form}
            submitDisabled={submitDisabled}
            uploadConfig={uploadConfig}
            handleResize={handleInputResize}
            handleSubmit={handleSubmitFormValue}
          />
        </Form>
      </BorderTop>
    )
  },
)

interface MessageInputProps {
  handleSubmit: any
  handleInputResize: any
  uploadConfig: UploadConfig
  /** @todo 待 conversation 元件全部搬到 ui 後，ConversationInput 應直接用 context 取得 teethSelection */
  teethSelection?: {
    selectedTeeth: ToothPosition[] | []
    getDefaultTeethMessageText: (selectedTeeth: ToothPosition[]) => string
  }
}

export function ConversationInput(props: MessageInputProps) {
  const { handleInputResize, handleSubmit, uploadConfig, teethSelection }
    = props

  return (
    <MessageFormContainer>
      <MessageForm
        handleSubmit={handleSubmit}
        handleInputResize={handleInputResize}
        uploadConfig={uploadConfig}
        teethSelection={teethSelection}
      />
    </MessageFormContainer>
  )
}
