import { gql } from '@apollo/client'
import type {
  CheckboxProps,
} from 'antd'
import {
  BackTop,
  Button,
  Checkbox,
  Divider,
  Row,
  Space,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import type {
  PanelCheckPointFragment,
  PanelCheckPointPatientItemFragment,
} from '../../../../graphql/types'
import {
  StageStatus,
} from '../../../../graphql/types'
import ContentCheckPoint from './ContentCheckPoint'
import ContentDesignStage from './ContentDesignStage'
import ModalBatchCreateDesign from './ModalBatchCreateDesign'
import ModalBatchEditDesign from './ModalBatchEditDesign'

const Wrapper = styled.div`
  background-color: white;
  padding: 24px;
`
const FooterRow = styled(Row)`
  background-color: rgba(0, 0, 0, 0.05);
  margin: 16px 0;
  padding: 4px 0;
  .ant-back-top {
    position: relative;
    right: initial;
    bottom: initial;
    width: initial;
    height: initial;
  }
`

interface PanelCheckPointProps {
  patientId: string
  stageId: string
  checkPoint: PanelCheckPointFragment
  patientItem: PanelCheckPointPatientItemFragment
  handleRefetch: () => Promise<void>
}

function PanelCheckPoint(props: PanelCheckPointProps) {
  const { checkPoint, patientId, stageId, patientItem, handleRefetch } = props
  const { isFinal, serialNumber, designStages, goalStep } = checkPoint

  const [editingContent, setEditingContent] = useState<string>()
  const [selectedSerialNumberList, setSelectedSerialNumberList] = useState<
    number[]
  >([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [visibleModal, setVisibleModal] = useState<
    'create' | 'edit' | undefined
  >()

  const hasSelected = selectedSerialNumberList.length > 0

  const getIsEditing = (contentKey: string) => contentKey === editingContent
  const handleEdit = (contentKey: string) => setEditingContent(contentKey)
  const handleOpen = (modalType: 'create' | 'edit') =>
    setVisibleModal(modalType)
  const handleClose = () => {
    setVisibleModal(undefined)
    setIsAllSelected(false)
    setIndeterminate(false)
    setSelectedSerialNumberList([])
  }
  const handleSelect = (serialNumber: number) => {
    let newList: number[] = []
    if (selectedSerialNumberList.includes(serialNumber))
      newList = selectedSerialNumberList.filter(id => id !== serialNumber)
    else
      newList = [...selectedSerialNumberList, serialNumber]

    setIndeterminate(newList.length !== designStages.length)
    setIsAllSelected(newList.length === designStages.length)
    setSelectedSerialNumberList(newList)
  }
  const handleSelectAll: CheckboxProps['onChange'] = (event) => {
    const checked = event.target.checked
    setIndeterminate(false)
    setSelectedSerialNumberList(
      checked
        ? designStages
          .filter(stage => stage.status === StageStatus.Started)
          .map(stage => stage.serialNumber)
        : [],
    )
    setIsAllSelected(checked)
  }

  return (
    <Wrapper>
      <ContentCheckPoint
        isEditing={getIsEditing('checkPoint')}
        checkPoint={checkPoint}
        patientId={patientId}
        stageId={stageId}
        handleRefetch={handleRefetch}
        handleEdit={handleEdit}
      />
      <Divider style={{ margin: '4px 0' }} />

      <Row justify="space-between" style={{ margin: '16px 0' }}>
        {hasSelected
          ? (
            <>
              <Space>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={indeterminate}
                  onChange={handleSelectAll}
                />
                <Typography.Text type="secondary">
                  已選擇
                  {' '}
                  {selectedSerialNumberList.length}
                  {' '}
                  張工單
                  {' '}
                </Typography.Text>
              </Space>
              <Button type="ghost" onClick={() => handleOpen('edit')}>
                匯入治療內容
              </Button>
            </>
            )
          : (
            <>
              <Space>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {isFinal ? 'Final' : `CP${serialNumber}`}
                  {' '}
                  區間的設計列表
                </Typography.Title>
                <Typography.Text type="secondary">
                  共
                  {' '}
                  {designStages.length}
                  {' '}
                  張設計工單
                </Typography.Text>
              </Space>
              <Button type="ghost" onClick={() => handleOpen('create')}>
                新增設計
              </Button>
            </>
            )}
        <ModalBatchCreateDesign
          item={patientItem}
          currentCheckPointGoal={goalStep}
          visible={visibleModal === 'create'}
          onCancel={handleClose}
          handleRefetch={handleRefetch}
        />
        <ModalBatchEditDesign
          item={patientItem}
          visible={visibleModal === 'edit'}
          selectedSerialNumberList={selectedSerialNumberList}
          onCancel={handleClose}
          handleRefetch={handleRefetch}
        />
      </Row>

      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        {designStages.map(designStage => (
          <ContentDesignStage
            key={designStage.id}
            isEditing={getIsEditing(designStage.id)}
            designStage={designStage}
            patientId={patientId}
            isSelected={selectedSerialNumberList.includes(
              designStage.serialNumber,
            )}
            handleRefetch={handleRefetch}
            handleEdit={handleEdit}
            handleSelect={handleSelect}
          />
        ))}
      </Space>

      <FooterRow justify="center">
        <Typography.Text type="secondary">
          這個 CP 區間的設計療程到此為止
          {!isFinal && '，想看後續設計請到下個 CP。'}
        </Typography.Text>
        <BackTop>
          <Typography.Link>回頂部</Typography.Link>
        </BackTop>
      </FooterRow>
    </Wrapper>
  )
}

PanelCheckPoint.fragments = {
  PanelCheckPoint: gql`
    fragment PanelCheckPoint on CheckPoint {
      ...ContentCheckPoint
      designStages {
        ...ContentDesignStage
        id
        serialNumber
        status
      }
    }
    ${ContentCheckPoint.fragments.ContentCheckPoint}
    ${ContentDesignStage.fragments.ContentDesignStage}
  `,
  PanelCheckPointPatientItem: gql`
    fragment PanelCheckPointPatientItem on Patient {
      id
      ...ModalBatchCreateDesign
      ...ModalBatchEditDesign
    }
    ${ModalBatchCreateDesign.fragments.ModalBatchCreateDesign}
    ${ModalBatchEditDesign.fragments.ModalBatchEditDesign}
  `,
}

export default PanelCheckPoint
