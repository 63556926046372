import { gql } from '@apollo/client'
import { exhaustiveCheck, getStageName } from '@sov/common'
import { Space } from 'antd'
import { isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import type { StageLinkFragment } from '../../codegen/types'
import { StageType } from '../../codegen/types'
import AvatarAccessoryStage from '../../static/images/stage_avatar_accessory.png'
import AvatarDesignStage from '../../static/images/stage_avatar_design.png'
import AvatarEvalStage from '../../static/images/stage_avatar_eval.png'
import AvatarMoldStage from '../../static/images/stage_avatar_mold.png'
import AvatarPrintStage from '../../static/images/stage_avatar_step.png'
import StageName from '../StageName'

const StyledLink = styled(Link)`
  color: black;
`
const Avatar = styled.div<{ backgroundImage: string }>`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-image: url(${props => props.backgroundImage});
  background-size: contain;
`

function getAvatarImage(stageType: StageType) {
  switch (stageType) {
    case StageType.Mold:
      return AvatarMoldStage
    case StageType.Eval:
      return AvatarEvalStage
    case StageType.Design:
      return AvatarDesignStage
    case StageType.Print:
      return AvatarPrintStage
    case StageType.Accessory:
      return AvatarAccessoryStage
    default:
      exhaustiveCheck(stageType)
  }
}

interface Props {
  item: StageLinkFragment
  to?: string
  target?: string
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  showAvatar?: boolean
}

function StageLink(props: Props) {
  const {
    item,
    target = '_self',
    to = `/patients/${item.patient.id}/stages/${item.id}`,
    className,
    style,
    children,
    showAvatar = false,
  } = props
  const { t } = useTranslation()

  if (!isNil(children)) {
    return (
      <Link className={className} target={target} style={style} to={to}>
        {children}
      </Link>
    )
  }

  const [label, affix, serialNumber] = getStageName(t, item)
  const name = `${label} ${affix}${serialNumber}`
  const avatarImage = getAvatarImage(item.type)

  return (
    <StyledLink className={className} target={target} style={style} to={to}>
      <Space>
        {showAvatar && <Avatar backgroundImage={avatarImage} />}
        <span>{name}</span>
      </Space>
    </StyledLink>
  )
}

StageLink.fragment = gql`
  fragment StageLink on Stage {
    id
    type
    patient {
      id
    }
    ...StageName
  }
  ${StageName.fragment}
`

export default StageLink
