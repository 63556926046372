import { Drawer, Layout } from 'antd'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { SIDER_MENU_WIDTH_COLLAPSED } from './constant'
import SiderMenu from './SiderMenu'

const StyledCustomSider = styled(Layout.Sider)`
  min-height: 100vh;
  height: 200px;
  padding: 8px 0 0 0;
  position: fixed;
  left: 0;
  z-index: 801;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.08);

  @media print {
    display: none;
  }
`

interface Props {
  collapsed: boolean
  openKeys: string[]
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>
}

function CustomSider(props: Props) {
  const { collapsed, openKeys, setCollapsed, setOpenKeys } = props
  const isDesktop = useMediaQuery({
    query: '(min-width: 576px)',
  })

  if (isDesktop) {
    return (
      <StyledCustomSider
        theme="light"
        collapsible
        collapsed={collapsed}
        collapsedWidth={isDesktop ? SIDER_MENU_WIDTH_COLLAPSED : 0}
        onCollapse={collapsed => setCollapsed(collapsed)}
      >
        <SiderMenu
          collapsed={collapsed}
          openKeys={openKeys}
          setCollapsed={setCollapsed}
          setOpenKeys={setOpenKeys}
        />
      </StyledCustomSider>
    )
  }
  else {
    return (
      <Drawer
        width={180}
        style={{ height: '100%', minHeight: '100%' }}
        bodyStyle={{ padding: '8px 0 0 0' }}
        visible={!collapsed}
        placement="left"
        closable={false}
        onClose={() => setCollapsed && setCollapsed(true)}
      >
        <SiderMenu
          collapsed={collapsed}
          openKeys={openKeys}
          setCollapsed={setCollapsed}
          setOpenKeys={setOpenKeys}
        />
      </Drawer>
    )
  }
}

export default CustomSider
