import React from 'react'
import styled from 'styled-components'

import Icons from './Icon/index'

interface ConversationIconProps {
  handleOnClick: () => void
  numberOfUnreadMessages: number
  isDrawerOpening: boolean
  drawerWidth: number
}

interface ConversationIconContainerProps {
  isDrawerOpening: boolean
  drawerWidth: number
}
const ConversationIconContainer = styled.div<ConversationIconContainerProps>`
  position: fixed;
  top: 200px;
  /* 這邊要根據 drawer 的寬度調整開啟後 Icon 的位置，目前先寫死，之後再調整成比較 flexible 的寫法 */
  right: ${props =>
    props.isDrawerOpening ? `${props.drawerWidth}px` : '0px'};
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 2px 4px 0 rgba(186, 186, 186, 0.5);
  background-color: #1890ff;
  z-index: 901;
  /* 配合 drawer 的動畫 */
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  cursor: pointer;
`

function ConversationIcon(props: ConversationIconProps) {
  const {
    handleOnClick,
    numberOfUnreadMessages,
    isDrawerOpening,
    drawerWidth,
  } = props

  return (
    <ConversationIconContainer
      onClick={handleOnClick}
      isDrawerOpening={isDrawerOpening}
      drawerWidth={drawerWidth}
      data-testid="patient-conversation-icon"
    >
      <img src={numberOfUnreadMessages > 0 ? Icons.notice : Icons.normal} />
    </ConversationIconContainer>
  )
}
export default ConversationIcon
