import { CloseOutlined, DownloadOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Modal, Spin } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import type { ImageCardFragment } from '../../codegen/types'

export enum IconType {
  REMOVE,
  DOWNLOAD,
}

const MAX_HEIGHT = 150

interface ImageIconProps {
  iconType: IconType
  imageUrl: string
  handleIconOnClick?: () => void
}

function ImageIcon(props: ImageIconProps) {
  const { iconType, imageUrl, handleIconOnClick } = props

  const Container = styled.div`
    position: absolute;
    z-index: 1001;
    top: 4px;
    right: 4px;
    font-size: 16px;
  `

  return (
    <Container
      onClick={(e) => {
        /** 點擊 link 會觸發上層的 onClick 打開預覽 modal，因此要阻止事件傳遞 */
        e.stopPropagation()
        handleIconOnClick && handleIconOnClick()
      }}
    >
      {/* 目前是兩種類型擇一 */}
      {iconType === IconType.REMOVE
        ? (
          <CloseOutlined style={{ color: 'white', cursor: 'pointer' }} />
          )
        : (
          <a href={imageUrl} target="_blank" rel="noopener noreferrer">
            <DownloadOutlined style={{ color: 'white' }} />
          </a>
          )}
    </Container>
  )
}

interface PreviewImageProps {
  imageFile: ImageCardFragment
  iconType: IconType
  handleOpenModal: () => void
  handleIconOnClick?: () => void
}

function PreviewImage(props: PreviewImageProps) {
  const { imageFile, iconType, handleOpenModal, handleIconOnClick } = props

  const thumbnailUrl = imageFile.thumbnailPath
  const imageUrl = imageFile.path

  const ImageOverlay = styled.div`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  `

  const Container = styled.div`
    display: inline-block;
    position: relative;
    cursor: pointer;
    :hover {
      ${ImageOverlay} {
        display: block;
      }
    }
  `

  return (
    <Container onClick={() => handleOpenModal()}>
      <ImageOverlay>
        <ImageIcon
          iconType={iconType}
          imageUrl={imageUrl}
          handleIconOnClick={handleIconOnClick}
        />
      </ImageOverlay>
      <img height={`${MAX_HEIGHT}px`} src={thumbnailUrl} />
    </Container>
  )
}

function LoadingImage() {
  const Container = styled.div`
    height: ${MAX_HEIGHT}px;
    width: ${MAX_HEIGHT}px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  return (
    <Container>
      <Spin />
    </Container>
  )
}

interface ImageCardProps {
  imageFile?: ImageCardFragment
  loading?: boolean
  iconType?: IconType
  handleIconOnClick?: () => void
}

export function ImageCard(props: ImageCardProps) {
  const {
    imageFile,
    loading = false,
    iconType = IconType.DOWNLOAD,
    handleIconOnClick,
  } = props
  const [modalVisible, setModalVisible] = useState(false)

  const handleOpenModal = () => setModalVisible(true)

  return (
    <>
      <Modal
        bodyStyle={{ padding: '40px' }}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <img src={imageFile?.path} width="100%" height="100%" />
      </Modal>
      {imageFile && !loading
        ? (
          <PreviewImage
            imageFile={imageFile}
            iconType={iconType}
            handleOpenModal={handleOpenModal}
            handleIconOnClick={handleIconOnClick}
          />
          )
        : (
          <LoadingImage />
          )}
    </>
  )
}

ImageCard.fragment = gql`
  fragment ImageCard on File {
    id
    path
    thumbnailPath
  }
`
