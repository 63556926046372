import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel } from 'antd'
import { isEmpty, isNil, map } from 'ramda'
import React, { useRef } from 'react'
import styled, { css } from 'styled-components'

const iconStyle = css`
  position: absolute;
  font-size: 24px;
  color: white;
  top: 50%;
`
const LeftIcon = styled(LeftOutlined)`
  ${iconStyle}
  transform: translate(-100%, -50%);
  left: -24px;
`
const RightIcon = styled(RightOutlined)`
  ${iconStyle}
  transform: translate(100%, -50%);
  right: -24px;
`
const CloseButton = styled(CloseOutlined)`
  ${iconStyle}
  font-size: 20px;
  top: 78px;
  right: 78px;
`

const ItemInfo = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
`
const ItemLabel = styled.span`
  font-size: 20px;
`
const ItemIndex = styled.div`
  font-size: 12px;
`
const Item = styled.div`
  img {
    width: 100%;
  }
`
const CarouselWrapper = styled.div`
  width: min(40%, 75vh);
  position: fixed;
  z-index: 10000;
  /* set carousel to center */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .slick-dots-bottom {
    bottom: -24px;
  }
`
const Mask = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  ${props => (props.visible ? 'display: block' : 'display: none')}
`

export interface LightboxItem {
  index?: number
  label?: string
  image?: string
  render?: React.ReactNode
}

interface LightBoxProps {
  visible?: boolean
  items: LightboxItem[]
  closeOnClickMask?: boolean
  initialIndex?: number
  handleClose?: () => void
}

function Lightbox(props: LightBoxProps) {
  const {
    visible = false,
    items,
    closeOnClickMask,
    initialIndex,
    handleClose,
  } = props
  // 升級 antd 後他沒有 export type
  const carouselRef = useRef<any>(null)

  if (isEmpty(items))
    return null

  const isSingleItem = items.length === 1

  return (
    <>
      <Mask
        visible={visible}
        onClick={() => closeOnClickMask && handleClose && handleClose()}
      >
        <CloseButton
          type="close"
          onClick={() => handleClose && handleClose()}
        />
      </Mask>
      {visible && (
        <CarouselWrapper>
          {!isSingleItem && (
            <LeftIcon type="left" onClick={() => carouselRef.current?.prev()} />
          )}
          <Carousel ref={carouselRef} initialSlide={initialIndex}>
            {map(
              ({ render, image, label, index }) =>
                render || (
                  <Item>
                    <ItemInfo>
                      {label && <ItemLabel>{label}</ItemLabel>}
                      {!isNil(index) && (
                        <ItemIndex>
                          {index + 1}
                          /
                          {items.length}
                        </ItemIndex>
                      )}
                    </ItemInfo>
                    {image && <img key={image} src={image} />}
                  </Item>
                ),
              items,
            )}
          </Carousel>
          {!isSingleItem && (
            <RightIcon
              type="right"
              onClick={() => carouselRef.current?.next()}
            />
          )}
        </CarouselWrapper>
      )}
    </>
  )
}

export default Lightbox
