import { gql } from '@apollo/client'

import { FeedbackInfoFragment } from '../fragment'

export const createFeedbackMutation = gql`
  mutation CreateFeedback($payload: CreateFeedbackInput!) {
    createFeedback(payload: $payload) {
      ...FeedbackInfo
    }
  }
  ${FeedbackInfoFragment}
`
