import type { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { Input, Spin } from 'antd'
import { addIndex, map, splitEvery } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import logo from './sov.svg'
import type { BoxLabelPrintData } from './index'

interface BoxLabelProps {
  boxLabelPrintdata: BoxLabelPrintData
}
interface BoxLabelsProps {
  printData: BoxLabelPrintData[]
}

interface BoxLabelsPreviewModalProps {
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  className?: string
  loading: boolean
  printData: BoxLabelPrintData[]
}

function BoxLabel({
  boxLabelPrintdata: { clinicName, patientName, firstStep, lastStep },
}: BoxLabelProps) {
  return (
    <section className="qc-item">
      <section className="qc-info">
        <h4>
          <span className="info-title">診所：</span>
          {clinicName}
        </h4>
        <h4>
          <span className="info-title">病患：</span>
          {patientName}
        </h4>
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <h4 className="step-text">
            <span className="info-title">Step：</span>
            {firstStep === lastStep
              ? (
                <span>{firstStep}</span>
                )
              : (
                  [
                    <span key="start">
                      {firstStep}
                      {' '}
                      ~
                      {' '}
                    </span>,
                    <span key="end">{lastStep}</span>,
                  ]
                )}
          </h4>
          <img src={logo} />
        </section>
      </section>
    </section>
  )
}

function BoxLabels({ printData }: BoxLabelsProps) {
  const labelNumberPerRow = 3
  const splitedData = splitEvery(labelNumberPerRow, printData)

  return (
    <div className="qc-label-container">
      {splitedData.map((boxLabelPrintdataListPerRow, index) => {
        const breakLine = (index + 1) % 10 === 0 ? 'break-line' : ''
        return (
          <section
            className={`qc-item-container ${breakLine}`}
            key={`qc-label-${index}`}
          >
            {addIndex<BoxLabelPrintData, JSX.Element>(map)(
              (boxLabelPrintdata, index) => (
                <BoxLabel
                  boxLabelPrintdata={boxLabelPrintdata}
                  key={`qc-${index}-${boxLabelPrintdata.taskId}`}
                />
              ),
              boxLabelPrintdataListPerRow,
            )}
          </section>
        )
      })}
    </div>
  )
}

function UnStyledBoxLabelsPreview(props: BoxLabelsPreviewModalProps) {
  const { getFieldDecorator, className, loading, printData } = props
  const mapIndex = addIndex<BoxLabelPrintData, JSX.Element>(map)
  return (
    <div className={className}>
      <section>
        <h4 className="patient">病患</h4>
        <h4>起始 Step</h4>
        <h4>結束 Step</h4>
      </section>
      {loading && (
        <div className="spin">
          <Spin />
        </div>
      )}
      {mapIndex(
        ({ taskId, patientName, firstStep, lastStep }, index) => (
          <section key={index}>
            <h4 className="patient">{patientName}</h4>
            {getFieldDecorator(`${taskId}-firstStep`, {
              initialValue: firstStep,
            })(<Input />)}
            {getFieldDecorator(`${taskId}-lastStep`, {
              initialValue: lastStep,
            })(<Input />)}
          </section>
        ),
        printData,
      )}
    </div>
  )
}

const BoxLabelsPreview = styled(UnStyledBoxLabelsPreview)`
  section {
    display: flex;

    & > * {
      flex: 1;
      margin: 0 6px 6px 0;
    }

    .patient {
      max-width: 80px;
    }
  }

  .spin {
    text-align: center;
  }
`

export { BoxLabels, BoxLabelsPreview }
