import React from 'react'

import Icon from './Icon'

function UndoIcon(props) {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M9.401 14.904c.922.843 1.133.969 1.184-.606.092-1.152 0-2.427 0-2.427.032-.072
        2.026-.82 5.321.606 3.295 1.428 5.519 5.883 5.91 7.278.032.806 1.082 2.361
        1.183-.031.013-3.906-1.512-7.995-5.399-10.34-3.624-2.187-6.638-1.964-6.97-1.85-.00
        7.032-.016.044-.032.025-.005-.006.009-.016.031-.024.029-.146-.058-.897-.067-2.31.056-1.27-.284-1.308-1.161-.632C7.793
        5.913 3.5 10.014 3.5 10.014s4.293 3.594 5.901 4.89z"
      />
    </Icon>
  )
}

function RedoIcon(props) {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M15.099 14.904c-.922.843-1.132.969-1.183-.606-.093-1.152 0-2.427 0-2.427-.033-.072-2.027-.82-5.322.606-3.294 1.428-5.519 5.883-5.91
        7.278-.032.806-1.082 2.361-1.183-.031-.013-3.906 1.513-7.995 5.399-10.34 3.624-2.187
        6.638-1.964 6.97-1.85.007.032.016.044.032.025.005-.006-.009-.016-.031-.024-.029-.146.058-.897.067-2.31-.055-1.27.284-1.308 1.161-.632C16.707
        5.913 21 10.014 21 10.014s-4.293 3.594-5.901 4.89z"
      />
    </Icon>
  )
}

function ButtonIcon(props) {
  return (
    <Icon {...props}>
      <circle cx="12" cy="12" r="6.5" fillRule="evenodd" stroke="#FFF" />
    </Icon>
  )
}

function AttachmentIcon(props) {
  return (
    <Icon {...props}>
      <path fillRule="evenodd" stroke="#FFF" d="M7.5 2.5H16.5V22.5H7.5z" />
    </Icon>
  )
}

function StrippingIcon(props) {
  return (
    <Icon {...props}>
      <g fillRule="evenodd">
        <path stroke="#FFF" d="M12 3L20 23 4 23z" />
        <path stroke="#FFF" strokeLinecap="square" d="M12 2L12 25" />
      </g>
    </Icon>
  )
}

function TADIcon(props) {
  return (
    <Icon {...props}>
      <path
      // fill='#52C41A'
        fillRule="evenodd"
        stroke="#FFF"
        d="M11.762 1.505c-1.467.063-2.785.7-3.735 1.692C6.975 4.295 6.375 5.829 6.522
      7.5h3.215l.789 15h2.948l.791-15.031 3.23-.219c.073-1.676-.599-3.196-1.75-4.252-1.01-.925-2.39-1.496-3.983-1.493z"
      />
    </Icon>
  )
}

export {
  UndoIcon,
  RedoIcon,
  AttachmentIcon,
  ButtonIcon,
  StrippingIcon,
  TADIcon,
}
