import { gql } from '@apollo/client'
import { Form, Radio } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { isNil } from 'ramda'
import React from 'react'

import type {
  CreatePatientInput,
} from '../../../../graphql/types'
import {
  CephAnalysis,
  FaceRatio,
  Instruction,
  LipsEstheticLineRelation,
  OcculusalMuscle,
  OcculusalPlaneStatus,
  Offset,
  SmileLine,
} from '../../../../graphql/types'
import {
  formItemLayout,
  formItemWithInstructionLayout,
  subFormItemLayout,
} from './utils/layouts'

export type FormProfileFields = Pick<CreatePatientInput, 'profile'>
export type FormProfileInitialValues = Partial<FormProfileFields>

interface Props {
  form: FormInstance<FormProfileFields>
  initialValues?: FormProfileFields
}

function FormPatientProfile(props: Props) {
  const { form, initialValues } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        {...formItemWithInstructionLayout}
        label="下顎位置是否對齊顏面中線"
        name={['profile', 'mandibularMidlineToFacialMidline', 'offset']}
      >
        <Radio.Group>
          <Radio value={Offset.Centered}>centered</Radio>
          <Radio value={Offset.ShiftedRight}>shiftedRight</Radio>
          <Radio value={Offset.ShiftedLeft}>shiftedLeft</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const offset = form.getFieldValue([
            'profile',
            'mandibularMidlineToFacialMidline',
            'offset',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label="是否調整上顎中線位置"
              name={[
                'profile',
                'mandibularMidlineToFacialMidline',
                'instruction',
              ]}
              hidden={isNil(offset) || offset === Offset.Centered}
            >
              <Radio.Group>
                <Radio value={Instruction.Maintain}>maintain</Radio>
                <Radio value={Instruction.Improve}>improve</Radio>
                <Radio value={Instruction.Idealize}>idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="咬合平面是否歪斜"
        name={['profile', 'occulusalPlane', 'occulusalPlaneStatus']}
      >
        <Radio.Group>
          <Radio value={OcculusalPlaneStatus.Skew}>skew</Radio>
          <Radio value={OcculusalPlaneStatus.Level}>level</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const status = form.getFieldValue([
            'profile',
            'occulusalPlane',
            'occulusalPlaneStatus',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label="是否調整咬合平面"
              name={['profile', 'occulusalPlane', 'instruction']}
              hidden={isNil(status) || status !== OcculusalPlaneStatus.Skew}
            >
              <Radio.Group>
                <Radio value={Instruction.Maintain}>maintain</Radio>
                <Radio value={Instruction.Improve}>improve</Radio>
                <Radio value={Instruction.Idealize}>idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="上下唇是否超出 E-line"
        name={['profile', 'estheticLine', 'lipsEstheticLineRelation']}
      >
        <Radio.Group>
          <Radio value={LipsEstheticLineRelation.Over}>over</Radio>
          <Radio value={LipsEstheticLineRelation.On}>on</Radio>
          <Radio value={LipsEstheticLineRelation.In}>in</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const relation = form.getFieldValue([
            'profile',
            'estheticLine',
            'lipsEstheticLineRelation',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label="是否內縮調整"
              name={['profile', 'estheticLine', 'instruction']}
              hidden={
                isNil(relation) || relation !== LipsEstheticLineRelation.Over
              }
            >
              <Radio.Group>
                <Radio value={Instruction.Maintain}>maintain</Radio>
                <Radio value={Instruction.Improve}>improve</Radio>
                <Radio value={Instruction.Idealize}>idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item label="Ceph 分析" name={['profile', 'cephAnalysis']}>
        <Radio.Group>
          <Radio value={CephAnalysis.Protrusion}>protrusion</Radio>
          <Radio value={CephAnalysis.Normal}>normal</Radio>
          <Radio value={CephAnalysis.BoneLess}>bone less</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="下臉部 1/3 比例" name={['profile', 'faceRatio']}>
        <Radio.Group>
          <Radio value={FaceRatio.Long}>long</Radio>
          <Radio value={FaceRatio.Normal}>normal</Radio>
          <Radio value={FaceRatio.Short}>short</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="咬肌狀況" name={['profile', 'occulusalMuscle']}>
        <Radio.Group>
          <Radio value={OcculusalMuscle.Strong}>strong</Radio>
          <Radio value={OcculusalMuscle.Normal}>normal</Radio>
          <Radio value={OcculusalMuscle.Weak}>weak</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="笑齦狀況" name={['profile', 'hasGummySmile']}>
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="微笑曲線" name={['profile', 'smileLine']}>
        <Radio.Group>
          <Radio value={SmileLine.Great}>great</Radio>
          <Radio value={SmileLine.Ugly}>ugly</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="齒頰間隙" name={['profile', 'hasBuccalCorridor']}>
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}

FormPatientProfile.fragments = {
  FormPatientProfile: gql`
    fragment FormPatientProfile on Patient {
      id
      profile {
        # 下顎位置是否對齊顏面中線 & 相關指示
        mandibularMidlineToFacialMidline {
          offset
          instruction
        }
        # 咬合平面是否歪斜 & 相關指示
        occulusalPlane {
          occulusalPlaneStatus
          instruction
        }
        # 上下唇是否超出 E-line & 相關指示
        estheticLine {
          lipsEstheticLineRelation
          instruction
        }
        # Ceph 分析
        cephAnalysis
        # 下臉部 1/3 比例
        faceRatio
        # 咬肌狀況
        occulusalMuscle
        # 笑齦狀況
        hasGummySmile
        # 微笑曲線
        smileLine
        # 齒頰間隙
        hasBuccalCorridor
      }
    }
  `,
}

export { FormPatientProfile }

export default FormPatientProfile
