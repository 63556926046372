import { gql } from '@apollo/client'
import type {
  AntSorterType,
} from '@sov/common'
import {
  TableQuery,
  getEmptyText,
  getStageAffix,
  getStageSerialNumber,
} from '@sov/common'
import {
  Link as CustomLink,
  DisplayPatientBrand,
  MultiLine,
  TablePatientInfo,
} from '@sov/ui'
import { Badge, Row, Table, Typography } from 'antd'
import type { BadgeProps } from 'antd/lib/badge'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { always, cond, equals, map, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { TableTaskFragment } from '../../../graphql/types'
import { TaskStatus, TaskType } from '../../../graphql/types'
import type {
  TaskFilterType,
  TaskSorterField,
} from '../../pages/task/TaskList/useTaskList'
import ClinicLink from '../link/clinic'
import EmployeeLink from '../link/employee'

const { StageLink } = CustomLink

type ColumnItem = TableTaskFragment

const DelayWarning = styled.div`
  .delayed {
    background-color: #fff1f0;
  }
`

interface Props extends TableProps<TableTaskFragment> {
  source?: {
    docs?: TableTaskFragment[]
    page?: number
    total?: number
    limit?: number
  }
  sortInfo?: AntSorterType<TaskSorterField>
  filterInfo?: TaskFilterType
  handleTableChange?: any
  rowSelection?: any
  taskTypes?: TaskType[]
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 1,
}

function TableTask(props: Props) {
  const {
    taskTypes,
    locale,
    scroll,
    loading = true,
    sortInfo,
    filterInfo = {},
    source = defaultSource,
    handleTableChange,
    rowSelection,
  } = props

  const { t } = useTranslation()
  const columns: ColumnProps<ColumnItem>[] = [
    {
      align: 'center',
      title: '病患',
      width: 120,
      key: 'patient',
      render: (_, record) => {
        return <TablePatientInfo patient={record.patient} />
      },
    },
    {
      title: '品牌',
      width: 60,
      align: 'center',
      dataIndex: 'paymentBrand',
      key: 'paymentBrand',
      render: (_value, record) => {
        return <DisplayPatientBrand value={record.patient.payment.brand} />
      },
    },
    {
      align: 'center',
      title: '工單',
      width: 80,
      key: 'stage',
      render: (_, record) => {
        const text = `${record.patient.clinic.name}-${
          record.patient.name
        }-${getStageAffix(record.stage)}${getStageSerialNumber(
          t,
          record.stage,
        )}`

        return (
          <Row justify="center" align="middle">
            <StageLink style={{ color: 'black' }} item={record.stage} />
            <Typography.Paragraph
              style={{ marginBottom: 0 }}
              copyable={{ text }}
            />
          </Row>
        )
      },
    },
    {
      align: 'center',
      title: '任務',
      width: 80,
      dataIndex: 'type',
      key: 'type',
      filters: handleTableChange
        ? values(taskTypes || TaskType).map((x: string) => ({
          text: t(`task.type.${x}`),
          value: x,
        }))
        : undefined,
      filteredValue: filterInfo.type ?? [],
      render: (_, record) => {
        const isCurrent = record.status === 'working'

        return (
          <>
            <div>{t(`task.type.${record.type}`)}</div>
            {record.owner?.__typename === 'Employee' && (
              <EmployeeLink
                style={{ color: isCurrent ? 'red' : 'black' }}
                item={record.owner}
              />
            )}
            {record.owner?.__typename === 'Clinic' && (
              <ClinicLink
                style={{ color: isCurrent ? 'red' : 'black' }}
                item={record.owner}
              />
            )}
          </>
        )
      },
    },
    {
      align: 'center',
      title: '開始時間',
      width: 60,
      dataIndex: 'startedByEmployee',
      key: 'startedByEmployee',
      sorter: !!handleTableChange,
      sortOrder:
        sortInfo?.field === 'startedByEmployee' ? sortInfo.order : undefined,
      render: (_, record) => (
        <>
          {record.startedByEmployee
            ? (
              <>
                <div>{moment(record.startedByEmployee).format('YYYY-MM-DD')}</div>
                <div>{moment(record.startedByEmployee).format('HH:mm')}</div>
              </>
              )
            : (
                getEmptyText()
              )}
        </>
      ),
    },
    {
      align: 'center',
      title: '重取模',
      width: 50,
      dataIndex: 'retrieve',
      key: 'retrieve',
      render: (_, record) => (
        <div>
          {record.stage.__typename === 'PrintStage'
          && record.stage.designStage?.instructionCard?.retrieve
            ? 'V'
            : getEmptyText()}
        </div>
      ),
    },
    {
      align: 'left',
      title: '注意事項',
      width: 150,
      render: (_, record) => <MultiLine text={record.stage.note} />,
    },
    {
      align: 'center',
      title: '預計出貨',
      width: 60,
      dataIndex: 'expectedShippingDate',
      key: 'expectedShippingDate',
      sorter: !!handleTableChange,
      sortOrder:
        sortInfo?.field === 'expectedShippingDate' ? sortInfo.order : undefined,
      render: text => (
        <div>{text ? moment(text).format('YYYY-MM-DD') : getEmptyText()}</div>
      ),
    },
    {
      align: 'center',
      title: '狀態',
      width: 40,
      dataIndex: 'status',
      key: 'status',
      filters: handleTableChange
        ? map(
          x => ({ text: t(`task.status.${x}`), value: x }),
          values(TaskStatus),
        )
        : undefined,
      filteredValue: filterInfo.status ?? [],
      render: (_, record) => {
        const status = cond<TaskStatus, BadgeProps['status']>([
          [
            equals<TaskStatus>(TaskStatus.Pending),
            always<BadgeProps['status']>('default'),
          ],
          [
            equals<TaskStatus>(TaskStatus.Working),
            always<BadgeProps['status']>('processing'),
          ],
          [
            equals<TaskStatus>(TaskStatus.Completed),
            always<BadgeProps['status']>('success'),
          ],
          [
            equals<TaskStatus>(TaskStatus.Dropped),
            always<BadgeProps['status']>('default'),
          ],
        ])(record.status)
        return (
          <div>
            <Badge status={status} />
            {t(`task.status.${record.status}`)}
          </div>
        )
      },
    },
  ]

  const { docs, ...cursor } = source

  return (
    <DelayWarning>
      <Table
        scroll={scroll}
        rowKey="id"
        columns={columns}
        rowClassName={record => (record.isDelayed ? 'delayed' : '')}
        dataSource={docs}
        loading={loading}
        locale={locale}
        pagination={TableQuery.getAntdPagination(cursor as any)}
        onChange={handleTableChange}
        rowSelection={rowSelection}
      />
    </DelayWarning>
  )
}

TableTask.fragment = gql`
  fragment TableTask on Task {
    id
    type
    status
    startedByEmployee
    expectedShippingDate
    isDelayed
    owner {
      id
      name
    }
    stage {
      note
      ...StageLink
      ... on PrintStage {
        designStage {
          id
          instructionCard {
            retrieve
          }
        }
      }
    }
    patient {
      ...TablePatientInfo
      payment {
        brand
      }
    }
  }
  ${TablePatientInfo.fragment}
  ${StageLink.fragment}
`

export default TableTask
