import { gql } from '@apollo/client'

import { FeedbackInfoFragment } from '../fragment'

export const removeFeedbackMutation = gql`
  mutation RemoveFeedback($id: ID!) {
    removeFeedback(id: $id) {
      ...FeedbackInfo
    }
  }
  ${FeedbackInfoFragment}
`
