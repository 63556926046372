export enum MeshColor {
  // tooth
  White = '#ffffff',
  Purple = '#897fd1',
  Grey = '#9097b2',
  Green = '#98e9c5',
  Yellow = '#f9eaa1',

  // jaw
  Pink = '#e68585',
}

export type MeshColorKey = keyof typeof MeshColor
