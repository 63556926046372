import { gql } from '@apollo/client'
import { ImageCard } from '@sov/ui'

export const ConversationMemberInfo = gql`
  fragment conversationMemberInfo on Entity {
    id
    name
    ... on Employee {
      role
    }
  }
`

export const ConversationInfo = gql`
  fragment conversationInfo on Conversation {
    id
    type
    members {
      ...conversationMemberInfo
    }
    ... on PatientConversation {
      patient {
        id
        name
        chiefComplaint
      }
    }
  }
  ${ConversationMemberInfo}
`

export const CommentInfoFragment = gql`
  fragment messageInfo on Message {
    id
    type
    created
    conversation {
      id
    }
    creator {
      id
      name
      ... on Employee {
        role
      }
    }
    ... on TextMessage {
      content
    }
    ... on ImageMessage {
      image {
        ...ImageCard
      }
    }
  }
  ${ImageCard.fragment}
`
