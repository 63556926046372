/* eslint max-len: 'off' */
import React from 'react'

function PartAll() {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>齒肉</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="齒肉" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M32.3484572,10.4 C30.4243858,10.4 28.6597429,11.1842857 27.3791715,12.4712857 C27.4871715,14.9019286 26.9388144,17.4855714 26.3628144,19.9104286 C26.3229572,20.2472857 26.2702429,20.5841429 26.1898858,20.9184286 C26.0548858,21.4809286 25.9231001,22.0325 25.8028858,22.568 C25.4281001,24.2407143 25.1143858,25.9539286 24.2536001,27.4620714 C23.8942429,28.0920714 23.5548144,28.3312143 23.2411001,28.3286429 C22.3314572,28.4282857 21.6333144,26.4097143 21.3099572,25.4139286 C20.7841001,23.7932857 20.6131001,21.7007857 18.9943858,20.7365 C18.5926001,20.4973571 18.1972429,20.3784286 17.8166715,20.3565714 C16.6987429,20.3257143 15.7074572,21.1196429 15.0208858,22.1244286 C14.0746001,23.5091429 13.7158858,25.1175714 13.1154572,26.6572143 C12.8377429,27.3695 12.5317429,28.0817857 11.7789572,28.4006429 C11.7622429,28.4006429 11.7474572,28.391 11.7307429,28.3897143 C11.7236715,28.3929286 11.7172429,28.3974286 11.7101715,28.4006429 C10.9111001,28.4006429 10.3563144,26.9407143 10.0625286,26.2316429 C8.46052864,22.3635714 7.96810007,17.9805714 7.3496715,13.8688571 C7.29438578,13.5037143 7.26224293,13.1295714 7.25195721,12.7528571 C6.18288578,11.7564286 4.8701715,11.0287143 3.42245721,10.6642143 C1.32031435,10.1351429 -0.527899932,12.182 0.138100068,14.2449286 L4.93702864,29.1212857 C5.16910007,29.8412857 5.83960007,30.3292143 6.59624293,30.3292143 L28.4861715,30.3292143 C29.2428144,30.3292143 29.9126715,29.8412857 30.1453858,29.1212857 L35.0066715,14.0520714 C35.5891001,12.2475714 34.2436001,10.4 32.3484572,10.4 Z"
          id="Shape-Copy-2"
          fill="#ED9893"
          fillRule="nonzero"
        />
        <path
          d="M25.8091979,29.1714286 C26.7169122,27.5713571 27.0839836,25.8999286 27.4394836,24.2831429 L27.5551979,23.7605 C27.6734836,23.234 27.8026979,22.6920714 27.9351265,22.1385714 C28.0174122,21.7972143 28.0816979,21.4391429 28.1344122,21.0225714 C28.6384122,18.8927857 29.1559122,16.4756429 29.1899836,14.1047857 C30.0629836,13.4522857 31.1146979,13.1 32.2191265,13.1 C32.6022694,13.1 32.8214836,13.3230714 32.9179122,13.4555 C33.0143408,13.5885714 33.1596265,13.8662857 33.0413408,14.2307857 L28.2205551,29.1714286 L25.8091979,29.1714286 Z M14.3104122,29.1714286 C14.5283408,28.7825 14.6723408,28.4096429 14.7822694,28.1287143 C14.9635551,27.6632857 15.1242694,27.1927143 15.2843408,26.7227857 C15.6289122,25.7115714 15.9541979,24.7556429 16.4826265,23.9835714 C16.9191265,23.3452143 17.3800551,23.0552857 17.6230551,23.0552857 C17.6584122,23.0578571 17.7599836,23.0951429 17.8763408,23.1645714 C18.4266265,23.4924286 18.6805551,24.332 19.0051979,25.5765714 C19.1131979,25.9899286 19.2205551,26.3962143 19.3446265,26.78 C19.5477694,27.4061429 19.8505551,28.3389286 20.3378408,29.1714286 L14.3104122,29.1714286 Z M6.60191222,29.1714286 L1.84476936,14.4242857 C1.72455508,14.0533571 1.87369794,13.7692143 1.97269794,13.6335714 C2.13405508,13.4117857 2.37641222,13.2793571 2.62005508,13.2793571 C2.68562651,13.2793571 2.75312651,13.2883571 2.82191222,13.3057143 C3.69941222,13.5262143 4.52869794,13.9247857 5.25384079,14.4712143 C5.27055508,14.6248571 5.29048365,14.7778571 5.31362651,14.9289286 C5.38819794,15.4290714 5.46148365,15.9343571 5.53605508,16.4422143 C6.08505508,20.2196429 6.65334079,24.125 8.15119794,27.7410714 L8.19876936,27.8567857 C8.34405508,28.2097143 8.54076936,28.6892857 8.81591222,29.1720714 L6.60191222,29.1720714 L6.60191222,29.1714286 Z"
          id="Shape-Copy-3"
          fill="#D18684"
          fillRule="nonzero"
        />
        <path
          d="M26.9909195,10.8666224 C28.0259195,14.7784081 26.5479909,19.4436224 25.6852766,23.2930509 C25.3104909,24.9657652 24.9967766,26.6789795 24.1359909,28.1864795 C22.7744195,30.5734081 21.6886338,27.4568367 21.2611338,26.1389795 C20.7352766,24.5183367 20.5642766,22.4258367 18.9455623,21.4621938 C17.3332766,20.5017652 15.8373481,21.4834081 14.9032766,22.8494795 C13.9569909,24.2341938 13.5982766,25.8426224 12.9978481,27.3822652 C12.7201338,28.0945509 12.4141338,28.8068367 11.6613481,29.1256938 C10.8622766,29.1256938 10.3074909,27.6657652 10.0137052,26.9566938 C8.4117052,23.0886224 7.91927663,18.7056224 7.30084805,14.5939081 C6.86563377,11.7010509 7.80356234,6.44633666 10.8082766,5.38112237 C12.4411338,4.80255094 14.6776338,5.33612237 16.3124195,5.81762237 C17.2516338,6.0946938 17.0787052,6.00405094 18.0147052,5.67940808 C18.0867052,5.65433666 18.1574195,5.62090808 18.2307052,5.5906938 C20.2332052,4.76205094 22.7949909,4.75562237 24.5577052,5.88897951 C25.7469909,6.65462237 26.6469909,9.56612237 26.9909195,10.8666224 Z"
          id="Shape"
          fill="#E7ECED"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default PartAll
