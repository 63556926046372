import { gql, useMutation } from '@apollo/client'
import { Button, Form, Spin, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { omit } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import type {
  CreateAccountInput,
  CreateAccountMutation,
  CreateAccountVariables,
} from '../../../../graphql/types'
import type {
  AccountFormFields,
} from '../../../components/form/account'
import FormAccount from '../../../components/form/account'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { useLoadingLayer } from '../../../helpers/hooks'

const createAccountMutation = gql`
  mutation CreateAccount($payload: CreateAccountInput!) {
    createAccount(payload: $payload) {
      ...AccountFormInfo
    }
  }
  ${FormAccount.fragments.AccountFormInfo}
`

function Account() {
  const [form] = useForm<AccountFormFields>()
  const history = useHistory()
  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [create] = useMutation<CreateAccountMutation, CreateAccountVariables>(
    createAccountMutation,
  )

  const handleSubmit = async () => {
    try {
      const value = await form.validateFields()
      const payload = omit(
        ['confirm', 'backupEmail', 'isActive', 'providers'],
        value,
      ) as CreateAccountInput
      await create({
        variables: {
          payload,
        },
        update: async (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data) {
            message.info('已新增帳戶')
            history.push('/accounts')
          }
        },
      })
    }
    catch (err) {
      if (err?.message) {
        /** graphQL errors */
        message.error(err.message)
      }
      else {
        /** form errors or other errors */
        console.log(err)
      }
    }
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'AccountCreate' }]}
          />
          <Title route={{ key: 'AccountCreate' }} />
        </>
      )}
      loading={loading}
      loadingComponent={<Spin size="large" tip={tip} />}
    >
      <Section>
        <>
          <FormAccount form={form} />
          <Form.Item
            wrapperCol={{ span: 16, offset: 6 }}
            style={{ marginTop: 24 }}
          >
            {/* 先將 OnceButton 改為 Button 因為太容易新增失敗，只要後端擋就好 */}
            <Button type="primary" onClick={handleSubmit}>
              新增
            </Button>
            <Button style={{ marginLeft: 24 }}>
              <Link to="/accounts">回列表</Link>
            </Button>
          </Form.Item>
        </>
      </Section>
    </Page>
  )
}

export default Account
