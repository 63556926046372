import { Button, notification } from 'antd'
import React from 'react'

interface OpenNotificationArgs {
  localStorageKeyOfClientStoredHash: string
  notificationConfirmCallback: () => void
}

function openNotification(args: OpenNotificationArgs) {
  const { localStorageKeyOfClientStoredHash, notificationConfirmCallback }
    = args

  const key
    = window.localStorage.getItem(localStorageKeyOfClientStoredHash) || 'no hash'

  const handleClick = () => {
    notificationConfirmCallback()
    notification.close(key)
  }

  const handleClose = () => {
    notification.close(key)
  }

  const closeIcon = <div />
  const title = '版本更新 ⭐'

  const description = (
    <>
      <div>為避免資料遺失，請先備份文字內容，</div>
      <div>點選更新後，將重整畫面並更新至最新版本。</div>
    </>
  )

  const btn = (
    <Button type="primary" size="small" onClick={handleClick}>
      更新
    </Button>
  )

  notification.open({
    btn,
    closeIcon,
    description,
    duration: null,
    key,
    message: title,
    onClose: handleClose,
  })
}

export default openNotification
