import { BellOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import React from 'react'

interface BellIconProps {
  count?: number
  className?: string
  handleTogglePopover?: () => void
}

function BellIcon(props: BellIconProps) {
  const { count, className, handleTogglePopover = () => {} } = props
  return (
    <Badge count={count} offset={[-12, 16]}>
      <BellOutlined
        className={className}
        onClick={() => handleTogglePopover()}
      />
    </Badge>
  )
}

export default BellIcon
