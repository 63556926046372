import PlaceholderFrontLoose from '../../../assets/frontLoose.png'
import PlaceholderFrontTight from '../../../assets/frontTight.png'
import PlaceholderLeftTight from '../../../assets/leftTight.png'
import PlaceholderRightTight from '../../../assets/rightTight.png'

export const photosWithBrace = [
  {
    key: 'frontLoose',
    title: '正面微張',
    aspect: 4 / 3,
    placeholder: PlaceholderFrontLoose,
  },
  {
    key: 'frontTight',
    title: '正面緊咬',
    aspect: 4 / 3,
    placeholder: PlaceholderFrontTight,
  },
  {
    key: 'leftTight',
    title: '左面緊咬',
    aspect: 4 / 3,
    placeholder: PlaceholderLeftTight,
  },
  {
    key: 'rightTight',
    title: '右面緊咬',
    aspect: 4 / 3,
    placeholder: PlaceholderRightTight,
  },
]

export const photosWithoutBrace = [
  {
    key: 'frontLoose',
    title: '正面微張',
    aspect: 4 / 3,
    placeholder: PlaceholderFrontLoose,
  },
  {
    key: 'frontTight',
    title: '正面緊咬',
    aspect: 4 / 3,
    placeholder: PlaceholderFrontTight,
  },
  {
    key: 'leftTight',
    title: '左面緊咬',
    aspect: 4 / 3,
    placeholder: PlaceholderLeftTight,
  },
  {
    key: 'rightTight',
    title: '右面緊咬',
    aspect: 4 / 3,
    placeholder: PlaceholderRightTight,
  },
]
