import type { Evolver } from 'ramda'
import { evolve } from 'ramda'

import type {
  CreatePatientInput,
  UpdatePatientInput,
} from '../../../../graphql/types'
import type { FormGeneralFields } from './Basic'
import type { FormDoctorInstructionFields } from './DoctorInstruction'
import type { FormIntraOralFields } from './IntraOral'
import type { FormPhotoFields } from './Photo'
import { mapItemPhotosToResponses } from './Photo'
import type { FormProfileFields } from './Profile'
import type { FormSymptomFields } from './Symptoms'

export * from './DoctorInstruction'
export * from './Basic'
export * from './IntraOral'
export * from './Photo'
export * from './Profile'
export * from './Symptoms'
export * from './Payment'

interface FormTransformer extends Evolver {
  photos: (value: any) => any
}

export type PatientForm = FormGeneralFields &
  FormPhotoFields &
  FormProfileFields &
  FormIntraOralFields &
  FormSymptomFields &
  FormDoctorInstructionFields

export function getPayloadTransformer(formValue: PatientForm): CreatePatientInput & UpdatePatientInput {
  return evolve<FormTransformer, PatientForm>(
    {
      photos: mapItemPhotosToResponses,
    },
    formValue,
  )
}
