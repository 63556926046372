import './DoctorInstruction.less'

import { gql } from '@apollo/client'
import { Form, Input, Radio } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import React from 'react'

import type { CreatePatientInput } from '../../../../graphql/types'
import { Need, ToothType } from '../../../../graphql/types'
import {
  formItemLayout,
  formItemWithInstructionLayout,
  subFormItemLayout,
  subFormItemWithoutBackgroundLayout,
} from './utils/layouts'

export type FormDoctorInstructionFields = Pick<
  CreatePatientInput,
  'doctorInstruction'
>
export type FormDoctorInstructionInitialValues =
  Partial<FormDoctorInstructionFields>

interface Props {
  form: FormInstance<FormDoctorInstructionFields>
  initialValues?: FormDoctorInstructionInitialValues
}

function NeedRadioGroup(props) {
  return (
    <Radio.Group {...props}>
      <Radio value={Need.Yes}>Yes</Radio>
      <Radio value={Need.No}>No</Radio>
      <Radio value={Need.OnlyIfNeeded}>Only If Needed</Radio>
    </Radio.Group>
  )
}

function FormPatientDoctorInstruction(props: Props) {
  const { form, initialValues } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item label="IPR" name={['doctorInstruction', 'ipr']}>
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item label="tads" name={['doctorInstruction', 'tads']}>
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item
        label="otherOrthdontalTools"
        name={['doctorInstruction', 'otherOrthdontalTools']}
      >
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item
        label="functionalAppliance"
        name={['doctorInstruction', 'functionalAppliance']}
      >
        <NeedRadioGroup />
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="是否保留空間"
        name={['doctorInstruction', 'maintainSpace', 'isMaintainingSpace']}
      >
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Form.Item
            {...subFormItemLayout}
            label="空間保留在"
            name={['doctorInstruction', 'maintainSpace', 'note']}
            hidden={
              !form.getFieldValue([
                'doctorInstruction',
                'maintainSpace',
                'isMaintainingSpace',
              ])
            }
          >
            <Input.TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="是否將假牙換成臨時假牙"
        name={['doctorInstruction', 'changeProsthesisToTemp', 'need']}
      >
        <NeedRadioGroup />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Form.Item
            {...subFormItemWithoutBackgroundLayout}
            label="備註"
            name={[
              'doctorInstruction',
              'changeProsthesisToTemp',
              'instruction',
            ]}
            hidden={
              form.getFieldValue([
                'doctorInstruction',
                'changeProsthesisToTemp',
                'need',
              ]) !== Need.No
            }
          >
            <Input.TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="若空間不足需要拔牙，是否可拔牙"
        name={['doctorInstruction', 'extractToothWhenNoSpace', 'intention']}
      >
        <NeedRadioGroup />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Form.Item
            {...subFormItemWithoutBackgroundLayout}
            label="可以拔的牙齒種類"
            name={['doctorInstruction', 'extractToothWhenNoSpace', 'toothType']}
            hidden={
              form.getFieldValue([
                'doctorInstruction',
                'extractToothWhenNoSpace',
                'intention',
              ]) !== Need.Yes
            }
          >
            <Radio.Group>
              <Radio value={ToothType.Wisdom}>Wisdom</Radio>
              <Radio value={ToothType.Incisor}>Incisor</Radio>
              <Radio value={ToothType.Premolar}>Premolar</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item label="醫師指示備忘" name={['doctorInstruction', 'note']}>
        <Input.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>
    </Form>
  )
}

FormPatientDoctorInstruction.fragments = {
  FormPatientDoctorInstruction: gql`
    fragment FormPatientDoctorInstruction on Patient {
      id
      doctorInstruction {
        note
        ipr
        tads
        otherOrthdontalTools
        functionalAppliance
        maintainSpace {
          isMaintainingSpace
          note
        }
        changeProsthesisToTemp {
          need
          instruction
        }
        extractToothWhenNoSpace {
          intention
          toothType
        }
      }
    }
  `,
}

export { FormPatientDoctorInstruction }

export default FormPatientDoctorInstruction
