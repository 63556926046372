import { useApolloClient } from '@apollo/client'
import type { LightboxItem } from '@sov/ui'
import { GqlUpload, Lightbox } from '@sov/ui'
import { Form, Input, Radio } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { isNil, map, prop, values } from 'ramda'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  CreateFeedbackInput,
  EntityType,
} from '../../../../graphql/types'
import {
  FeedbackCategory,
} from '../../../../graphql/types'

type FeedbackFormFields = Omit<CreateFeedbackInput, 'screenshotList'> & {
  screenshotList?: UploadFile[]
}

interface Props {
  form: FormInstance<FeedbackFormFields>
  initialValues?: Partial<FeedbackFormFields>
  entityName?: string
  onUploadScreenShots?: (info: UploadChangeParam) => void
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

const EntityTypeField: FC<{ entityType?: EntityType }> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <Form.Item name="entityType" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="實體類型">
        {props.entityType && t(`account.entityType.${props.entityType}`)}
      </Form.Item>
    </>
  )
}

const EntityField: FC<{ entityName?: string }> = props => (
  <>
    <Form.Item name="entity" hidden>
      <Input />
    </Form.Item>
    <Form.Item label="名稱">{props.entityName}</Form.Item>
  </>
)

function FormFeedback(props: Props) {
  const { form, initialValues, entityName, onUploadScreenShots } = props

  const [previewImageUrl, setPreviewImageUrl] = useState<string>()
  const { t } = useTranslation()
  const client = useApolloClient()

  const lightboxVisible = !isNil(previewImageUrl)
  const lightboxItems: LightboxItem[] = lightboxVisible
    ? [
        {
          index: 0,
          image: previewImageUrl,
        },
      ]
    : []

  const handleCloseLightbox = () => setPreviewImageUrl(undefined)
  const handleChangeScreenShot = (info: UploadChangeParam) =>
    onUploadScreenShots?.(info)

  return (
    <>
      <Form {...formItemLayout} form={form} initialValues={initialValues}>
        <EntityTypeField entityType={initialValues?.entityType} />
        <EntityField entityName={entityName} />
        <Form.Item name="category" label="回饋種類">
          <Radio.Group>
            {map(
              value => (
                <Radio key={value} value={value}>
                  {t(`feedback.category.${value}`)}
                </Radio>
              ),
              values(FeedbackCategory),
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="title"
          label="標題"
          rules={[{ required: true, message: '請輸入回饋標題' }]}
        >
          <Input maxLength={45} />
        </Form.Item>
        <Form.Item
          name="content"
          label="回饋內容"
          rules={[{ required: true, message: '請輸入回饋內容' }]}
        >
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name="screenshotList"
          label="螢幕截圖"
          valuePropName="fileList"
          getValueFromEvent={prop('fileList')}
        >
          <GqlUpload.Upload
            client={client}
            fileType="image"
            uploadOptions={{ needThumbnail: true, prefix: 'feedback' }}
            accept="image/*"
            listType="picture"
            dragger
            multiple
            isWithImageCrop={false}
            onPreview={file => setPreviewImageUrl(file.response.path)}
            onChange={handleChangeScreenShot}
          />
        </Form.Item>
      </Form>
      <Lightbox
        visible={lightboxVisible}
        items={lightboxItems}
        handleClose={handleCloseLightbox}
        closeOnClickMask
      />
    </>
  )
}

export default FormFeedback
