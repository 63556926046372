import moment from 'moment'

import { HomeDeliveryType, Payment } from '../../../graphql/types'
import type { FormInvoiceProps } from '../../components/form/invoice/index'

export const formInvoiceBasicDefaultValue: FormInvoiceProps['initialValues'] = {
  creditDate: moment(),
  shippingDate: moment().add(1, 'days'),
  products: [],
  middlemanFee: 0,
  discount: 0,
  hasTax: false,
  payment: Payment.Pending,
  homeDeliveryType: HomeDeliveryType.Self,
}
