import { gql } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery } from '@sov/common'
import { Badge } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import Table from 'antd/lib/table'
import moment from 'moment'
import { isEmpty, isNil, map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { PatientTrackTableFragment } from '../../../../graphql/types'
import type { PatientTrackSorterField } from '../../../pages/patient/PatientTracksList'
import PhotoThumbnailList from '../Track/PhotoThumbnailList'

interface TrackTableListProps {
  loading: boolean
  data: PatientTrackTableFragment[]
  sortInfo?: AntSorterType<PatientTrackSorterField>
  antdPagination: {
    page: number
    total: number
    limit: number
  }
  handleTableChange: TableProps<PatientTrackTableFragment>['onChange']
}

function TrackTable(props: TrackTableListProps) {
  const { loading, data, sortInfo, antdPagination, handleTableChange } = props
  const { t } = useTranslation()

  const columns: ColumnProps<PatientTrackTableFragment>[] = [
    {
      title: 'Step',
      width: 40,
      key: 'stage',
      render: (text, record) => (
        <div>
          Step
          {record.stage.serialNumber}
        </div>
      ),
    },
    {
      title: '戴牙套照片',
      width: 60,
      key: 'withBrace',
      render: (text, record) =>
        record.photo?.withBrace && (
          <PhotoThumbnailList photoMap={record.photo?.withBrace} />
        ),
    },
    {
      title: '未戴牙套照片',
      width: 60,
      key: 'withoutBrace',
      render: (text, record) =>
        record.photo?.withoutBrace && (
          <PhotoThumbnailList photoMap={record.photo?.withoutBrace} />
        ),
    },
    {
      title: '狀況',
      width: 40,
      key: 'mainIssues',
      render: (text, record) => (
        <div>
          {isEmpty(record.mainIssues)
            ? '無'
            : map(
              issue => <div>{t(`track.issue.${issue}`)}</div>,
              record.mainIssues,
            )}
        </div>
      ),
    },
    {
      title: '額外狀況',
      width: 40,
      key: 'extraIssue',
      render: (text, record) => <div>{record.extraIssue || '無'}</div>,
    },
    {
      title: '上傳日期',
      width: 40,
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      render: (text, record) => (
        <div>{moment(record.created).format('YYYY-MM-DD')}</div>
      ),
    },
    {
      title: '狀態',
      width: 30,
      key: 'isReply',
      render: (text, record) =>
        isNil(record.reply)
          ? (
            <div>
              <Badge status="error" />
              未回覆
            </div>
            )
          : (
            <div>
              <Badge status="success" />
              已回覆
            </div>
            ),
    },
  ]

  return (
    <Table<PatientTrackTableFragment>
      rowKey="id"
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination(antdPagination)}
    />
  )
}

/* ----- fragment ----- */
TrackTable.fragment = gql`
  fragment PatientTrackTable on Track {
    id
    stage {
      id
      serialNumber
    }
    photo {
      withBrace {
        frontLoose {
          ...PatientTrackTablePhoto
        }
        frontTight {
          ...PatientTrackTablePhoto
        }
        leftTight {
          ...PatientTrackTablePhoto
        }
        rightTight {
          ...PatientTrackTablePhoto
        }
      }
      withoutBrace {
        frontLoose {
          ...PatientTrackTablePhoto
        }
        frontTight {
          ...PatientTrackTablePhoto
        }
        leftTight {
          ...PatientTrackTablePhoto
        }
        rightTight {
          ...PatientTrackTablePhoto
        }
      }
    }
    created
    mainIssues
    extraIssue
    reply
  }
  fragment PatientTrackTablePhoto on File {
    id
    path
    thumbnailPath
  }
`

export default TrackTable
