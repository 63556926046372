import type { ButtonProps } from 'antd/lib/button'
import React from 'react'

import { AllPrivilege } from '../../../../graphql/types'
import { OnceButton } from '../../common/button'

type CreateButtonProps = ButtonProps

export function CreateButton(props: CreateButtonProps) {
  return (
    <OnceButton
      label="新增"
      requiredPrivilege={AllPrivilege.InvoiceCreate}
      {...props}
    />
  )
}

type UpdateButtonProps = ButtonProps

export function UpdateButton(props: UpdateButtonProps) {
  return (
    <OnceButton
      label="更新"
      requiredPrivilege={AllPrivilege.InvoiceUpdate}
      {...props}
    />
  )
}

export function MailButton(props: {
  loading: boolean
  onClick: () => void
}) {
  return <OnceButton label="寄信給醫生與業務" {...props} />
}
