import { ReloadOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { Auth, getNotificationTypesFromCategory } from '@sov/common'
import { Notification } from '@sov/ui'
import { Button, Radio } from 'antd'
import { concat, equals, map, not, prop, values } from 'ramda'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  NotificationCreatedSubscription,
  NotificationCreatedSubscriptionVariables,
  NotificationListQuery,
  NotificationListQueryVariables,
  NotificationsQuery,
  ReadAllNotificationMutationMutation,
  ReadAllNotificationMutationMutationVariables,
  ReadNotificationMutationMutation,
  ReadNotificationMutationMutationVariables,
} from '../../../graphql/types'
import {
  NotificationCategory,
  NotificationType,
  Platform,
} from '../../../graphql/types'
import {
  notificationCreatedSubscription,
  readAllNotificationMutation,
  readNotificationMutation,
} from '../../components/common/NoticeIcon'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import { authContext } from '../../context'

/* ----- query string ----- */
const notificationListQuery = gql`
  query NotificationList(
    $query: NotificationsQuery = {}
    $first: Int = 10
    $after: String
  ) {
    notifications(query: $query, first: $first, after: $after) {
      edges {
        node {
          ...NotificationItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${Notification.fragment}
`

/* ----- styled components ----- */
const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

/* ----- type and component ----- */
type Category = NotificationCategory | 'all'

function NotificationList() {
  const { t } = useTranslation()
  const [category, setCategory] = useState<Category>('all')
  const auth = useContext(authContext)
  const userId = Auth.utils.getUserEntityIdFromAuth(auth)

  const query: NotificationsQuery = {
    type:
      category === 'all'
        ? values(NotificationType)
        : getNotificationTypesFromCategory(category),
    receiver: [userId],
    platform: Platform.Erp,
  }

  const { loading, data, refetch, fetchMore } = useQuery<
    NotificationListQuery,
    NotificationListQueryVariables
  >(notificationListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    variables: { query },
  })
  const [readNotification] = useMutation<
    ReadNotificationMutationMutation,
    ReadNotificationMutationMutationVariables
  >(readNotificationMutation)
  const [readAllNotification] = useMutation<
    ReadAllNotificationMutationMutation,
    ReadAllNotificationMutationMutationVariables
  >(readAllNotificationMutation)

  useSubscription<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >(notificationCreatedSubscription, {
    variables: {
      receiver: userId,
      type: values(NotificationType),
      platform: Platform.Erp,
    },
    onSubscriptionData: () => refetch(),
  })

  const notificationData = map(prop('node'), data?.notifications?.edges || [])
  const hasMoreNotification = loading
    ? true
    : data?.notifications?.pageInfo.hasNextPage ?? false

  const handleChangeCategory = (category: Category) => setCategory(category)
  const handleRefresh = () => {
    refetch()
  }
  const handleRead = async (id: string, read: boolean) => {
    if (read)
      return
    try {
      await readNotification({
        variables: { id },
        update: (cache, { data }) => {
          if (data)
            refetch()
        },
      })
    }
    catch (error) {
      console.log(error)
    }
  }
  const handleReadAll = async () => {
    if (!userId)
      return
    try {
      await readAllNotification({
        variables: { receiver: userId },
        update: (cache, { data }) => {
          if (data && data.readAllNotification?.statusCode === '200')
            refetch()
        },
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        after: data?.notifications?.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const isNewResult = not(
          equals(
            prev.notifications?.pageInfo,
            fetchMoreResult?.notifications?.pageInfo,
          ),
        )
        if (fetchMoreResult?.notifications && isNewResult) {
          return {
            notifications: {
              edges: concat(
                prev.notifications?.edges || [],
                fetchMoreResult.notifications.edges,
              ),
              pageInfo: fetchMoreResult.notifications.pageInfo,
              __typename: prev.notifications?.__typename,
            },
          }
        }
        return prev
      },
    })
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'Notification' }]}
          />
          <Title route={{ key: 'Notification' }} />
        </>
      )}
    >
      <Section>
        <ButtonsRow>
          <Radio.Group
            value={category}
            onChange={e => handleChangeCategory(e.target.value)}
          >
            <Radio.Button value="all">全部</Radio.Button>
            {values(NotificationCategory).map(category => (
              <Radio.Button key={category} value={category}>
                {t(`notification.category.${category}`)}
              </Radio.Button>
            ))}
          </Radio.Group>
          <div>
            <Button type="primary" onClick={() => handleReadAll()}>
              全部標示已讀
            </Button>
            <Button onClick={() => handleRefresh()} style={{ marginLeft: 8 }}>
              <ReloadOutlined />
              {' '}
              重新整理
            </Button>
          </div>
        </ButtonsRow>
        <Notification.List
          loading={loading}
          notificationList={notificationData}
          hasMore={hasMoreNotification}
          handleRead={handleRead}
          handleFetchMore={handleFetchMore}
        />
      </Section>
    </Page>
  )
}

export default NotificationList
