import { Form } from '@ant-design/compatible'
import type { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { gql } from '@apollo/client'
import { TablePatientInfo } from '@sov/ui'
import { Checkbox, DatePicker, Input } from 'antd'
import moment from 'moment'
import React, { useContext } from 'react'

import type { AppointmentFragment } from '../../../../graphql/types'
import { authContext } from '../../../context'
import { PatientSelect } from '../Select'

interface Props {
  form: WrappedFormUtils
  item?: AppointmentFragment
}

function AppointmentForm(props: Props) {
  const auth = useContext(authContext)
  const { form, item } = props
  const { getFieldDecorator, setFieldsValue } = form

  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
      <Form.Item label="病患姓名">
        {!item && getFieldDecorator('patient')(<PatientSelect />)}
        {item
        && getFieldDecorator('patient', {
          initialValue: item?.patient.id,
        })(<div>{item.patient.name}</div>)}
      </Form.Item>
      <Form.Item label="約診日期">
        <>
          <>
            {getFieldDecorator('startDate', {
              initialValue: moment(item?.startDate),
            })(
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{ format: 'HH:mm' }}
                onChange={val =>
                  setFieldsValue({
                    endDate: val ? moment(val).add(30, 'm') : null,
                  })}
              />,
            )}
          </>
          <div>至</div>
          <>
            {getFieldDecorator('endDate', {
              initialValue: moment(item?.endDate),
            })(
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{ format: 'HH:mm' }}
              />,
            )}
          </>
        </>
      </Form.Item>
      <Form.Item label="約診備註">
        {getFieldDecorator('description', {
          initialValue: item?.description,
        })(<Input.TextArea />)}
      </Form.Item>
      <Form.Item label="是否到診">
        {getFieldDecorator('isAttended', {
          valuePropName: 'checked',
          initialValue: item?.isAttended ?? false,
        })(<Checkbox />)}
      </Form.Item>
      <Form.Item label="新增約診人員">
        {getFieldDecorator('creator', {
          initialValue: item?.creator.id ?? auth?.entity?.id,
        })(<div>{item?.creator.name ?? auth?.entity?.name}</div>)}
      </Form.Item>
    </Form>
  )
}

AppointmentForm.fragment = {
  Appointment: gql`
    fragment Appointment on Appointment {
      id
      startDate
      endDate
      description
      isAttended
      patient {
        id
        name
        clinic {
          id
          name
        }
        doctor {
          id
          name
        }
        ...TablePatientInfo
      }
      creator {
        id
        name
      }
    }
    ${TablePatientInfo.fragment}
  `,
}

export default AppointmentForm
