import { holidayUtils } from '@sov/utils'
import moment from 'moment'

// @TODO： 之後可能需要重構
export function getLeaveDays(startDate: moment.Moment | string, endDate: moment.Moment | string) {
  const _startDate = moment(startDate)
  const _endDate = moment(endDate)

  // 用小時來計算，扣掉經過的週末
  // @ts-ignore
  const holidayAndWeekendDays = holidayUtils.countHolidayAndWeekendDay(
    _startDate,
    _endDate,
  )
  const diffTotalHours
    = _endDate.diff(_startDate, 'hours') - holidayAndWeekendDays * 24
  // 先算超過一天的天數
  const diffCompleteDays = Math.floor(diffTotalHours / 24)
  const additionalHours = diffTotalHours % 24
  // 剩餘相差 9 小時代表請了整天假，其餘則為半天或者剛好整數不需額外計算
  const additioinalDay
    = additionalHours === 9 ? 1 : additionalHours === 4 ? 0.5 : 0

  let leaveDays = diffCompleteDays + additioinalDay
  if (leaveDays == -1)
    leaveDays = 1

  return leaveDays
}

export function getAMOrPM(startDate: moment.Moment) {
  return startDate.hour() === 9 ? 'am' : 'pm'
}

// @TODO: 之後可能需要重構
// 從 form 的 date, days, from 得到 startDate, endDate
export function getDatesFromDateField(date: moment.Moment, days: number, from: 'am' | 'pm') {
  const startDate
    = from === 'am'
      ? moment(date).startOf('day').add(9, 'hours')
      : moment(date).startOf('day').add(13, 'hours').add(30, 'minutes')
  let endDate: moment.Moment = moment(startDate)

  // 將天數拆成整數位和小數點
  const remainder = days % 1
  const integer = days - remainder

  // 先處理整數位，不包含只有半日的情況
  if (integer !== 0) {
    // 若為完整的一天，會少跨一日，因此計算時少加一天。
    // 其他情況：下午開始，或者到隔天上午都會正常跨日。
    const addDays = from === 'am' && remainder === 0 ? integer - 1 : integer
    // 若為上面的情況，計算時加 9 小時
    // 其他則不動，因為一次跨一個整日，因此都是 24 小時。
    const addHours = from === 'am' && remainder === 0 ? 9 : 0

    endDate = holidayUtils
      .addWeekDaysWithHoliday(endDate, addDays)
      .add(addHours, 'hours')
  }

  // 再處理小數位
  // 不論上午開始或者下午開始，半天等於加上 4.5 小時
  if (remainder !== 0)
    endDate = moment(endDate).add(4, 'hour').add(30, 'minutes')

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  }
}

// 拿到值日算年資(月)
export function calSeniority(onBoardDate: Date) {
  return moment().diff(moment(onBoardDate), 'months')
}

// 用 seniority 轉換成對應的中文輸出格式
export function formatSeniority(seniority: number | string) {
  const seniorityAsNumber = Number(seniority)
  if (isNaN(seniorityAsNumber))
    return Number.NaN

  const year = Math.floor(seniorityAsNumber / 12)
  const month = seniorityAsNumber % 12
  return month === 0
    ? `${year} 年`
    : year > 0
      ? `${year} 年 ${month} 個月`
      : `${month} 個月`
}

// 計算特定年資當年度特休
export function calAnnual(seniority: number) {
  // (1)6個月以上1年未滿者，3日。
  // (2)1年以上2年未滿者，7日。
  // (3)2年以上3年未滿者，10日。
  // (4)3年以上5年未滿者，每年14日。
  // (5)5年以上10年未滿者，每年15日。
  // (6)10年以上者，每1年加給1日，加至30日為止。
  if (seniority < 6)
    return 0
  else if (seniority >= 6 && seniority < 12)
    return 3
  else if (seniority >= 12 && seniority < 24)
    return 7
  else if (seniority >= 24 && seniority < 36)
    return 10
  else if (seniority >= 36 && seniority < 60)
    return 14
  else if (seniority >= 60 && seniority < 120)
    return 15
  else
    return Math.min(15 + Math.floor(seniority / 12) - 9, 30)
}

// 以年資計算累積特休天數
export function calAllAnnual(onBoardDate: Date) {
  const seniority = calSeniority(onBoardDate)
  if (seniority < 6) {
    return 0
  }
  else if (seniority >= 6 && seniority < 12) {
    return 3
  }
  else {
    let accumulateAnnual = 3
    for (let i = 12; i <= seniority; i += 12)
      accumulateAnnual += calAnnual(i)

    return accumulateAnnual
  }
}
