import { StageType, TaskType } from './types'

export const stage = {
  taskType: {
    [StageType.Mold]: [
      // 建模
      TaskType.StartScan,
      TaskType.AssignScan,
      TaskType.CompleteScan,
      TaskType.CompleteBase,
      TaskType.CompleteSplit,
      TaskType.SubmitFile,
    ],
    [StageType.Eval]: [
      // 評估
      TaskType.StartEval,
      TaskType.ConfirmMold,
      TaskType.AmCheck,
      TaskType.AssignAlign,
      TaskType.CompleteAlign,
      TaskType.AssignPredesign,
      TaskType.CompletePredesign,
      TaskType.SubmitPredesign,
      TaskType.AssignViewer,
      TaskType.CompleteViewer,
      TaskType.SubmitViewer,
    ],
    [StageType.Design]: [
      // 設計
      TaskType.AssignDesign,
      TaskType.CompleteDesign,
      TaskType.SubmitDesign,
    ],
    [StageType.Print]: [
      // 製造
      TaskType.StartPrint,
      TaskType.ConfirmDesign,
      TaskType.CompleteRp,
      TaskType.CompleteBraces,
      TaskType.CreateInvoice,
      TaskType.ConfirmShip,
    ],
    [StageType.Accessory]: [
      // 配件
      TaskType.StartPrint,
      TaskType.ConfirmDesign,
      TaskType.CompleteRp,
      TaskType.CompleteBraces,
      TaskType.CreateInvoice,
      TaskType.ConfirmShip,
    ],
  },
}
