import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql } from '@apollo/client'
import {
  CheckPoint,
  Link as CustomLink,
  InstructionCardPreview,
  MultiLine,
  StageName,
} from '@sov/ui'
import { Button, Popover, Row, Space, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'
import { Link } from 'react-router-dom'

import type {
  AnalysisSectionFormFragment,
  DesignCheckPointCardFragment,
  FormAnalysisFragment,
  InstructionCardFieldFragment,
  InstructionCardStageLinkFragment,
  StageNameFragment,
} from '../../../../graphql/types'
import {
  AccessoryCode,
} from '../../../../graphql/types'
import { OnceButton } from '../../common/button'
import InstructionTagList from '../../common/InstructionTagList'
import { defaultLayout } from './constant'

export interface StageUpdateAnalysisFields {
  step: {
    upperStep: string
    lowerStep: string
    attachment: boolean
    ipr: boolean
    button: boolean
    retrieve: boolean
  }
}

const FormItem = Form.Item
const StageLink = CustomLink.StageLink

interface InstructionCardFieldProps {
  instructionItem?: InstructionCardFieldFragment
  stage: InstructionCardStageLinkFragment
  readOnly?: boolean
  handleCreateInstructionCard?: () => Promise<void>
  handleRemoveInstructionCard?: () => Promise<void>
}

function InstructionCardField(props: InstructionCardFieldProps) {
  const {
    instructionItem,
    readOnly,
    handleCreateInstructionCard,
    handleRemoveInstructionCard,
    stage,
  } = props
  return (
    <FormItem {...defaultLayout} label="指示卡">
      {instructionItem
        ? (
          <>
            <Popover
              content={(
                <InstructionCardPreview
                  instructionItem={instructionItem}
                  handleRemove={handleRemoveInstructionCard}
                />
              )}
              title="IPR Card"
              placement="top"
            >
              <Button type="dashed">預覽</Button>
            </Popover>
            <Button style={{ marginLeft: '10px' }}>
              <Link to={`/stages/${stage.id}/instruction`}>指示卡編輯器</Link>
            </Button>
          </>
          )
        : readOnly
          ? (
            <>
              尚未新增指示卡，請先去所屬設計工單(
              <StageLink item={stage} />
              )頁面新增
            </>
            )
          : (
            <OnceButton
              label="新增"
              type="primary"
              onClick={handleCreateInstructionCard}
            />
            )}
    </FormItem>
  )
}

InstructionCardField.fragments = {
  InstructionCardField: gql`
    fragment InstructionCardField on InstructionCard {
      ...InstructionCardPreview
    }

    ${InstructionCardPreview.fragments.InstructionCardPreview}
  `,
  InstructionCardStageLink: gql`
    fragment InstructionCardStageLink on Stage {
      ...StageLink
    }

    ${StageLink.fragment}
  `,
}

interface CheckPointCardProps {
  patientId: string
  checkPoint?: DesignCheckPointCardFragment
  evalStage?: StageNameFragment
}

function CheckPointCard(props: CheckPointCardProps) {
  const { checkPoint, evalStage, patientId } = props

  const cpName = checkPoint
    ? checkPoint.isFinal
      ? 'Final'
      : `CP${checkPoint.serialNumber}`
    : ''

  return (
    <>
      <Space style={{ marginBottom: '16px' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Check Point 點
        </Typography.Title>
        {checkPoint && (
          <span>
            向
            {cpName}
            {' '}
            前進 ⛳️
          </span>
        )}
      </Space>
      {checkPoint
        ? (
          <CheckPoint.PreviewCard checkPoint={checkPoint} isForInternal />
          )
        : (
          <div>
            <Typography.Text type="danger">
              此設計 Step 並不在Check Point
              的目標裡，請確認或修改主要報告的療程內容，避免療程走歪哦！
            </Typography.Text>
          </div>
          )}
      {evalStage && (
        <Row justify="end" style={{ margin: '16px 0' }}>
          <Space style={{ padding: '8px 0' }}>
            <Typography.Text type="secondary">
              想更清楚目前整體療程的 Check Potint 規劃？
            </Typography.Text>
            <Link to={`/patients/${patientId}/analyses`}>
              檢視主要報告
              <StageName item={evalStage} showType={false} />
            </Link>
          </Space>
        </Row>
      )}
    </>
  )
}

CheckPointCard.fragments = {
  DesignCheckPointCard: gql`
    fragment DesignCheckPointCard on CheckPoint {
      serialNumber
      isFinal
      ...PreviewCard
    }
    ${CheckPoint.PreviewCard.fragments.PreviewCard}
  `,
  EvalStageName: gql`
    fragment EvalStageName on Stage {
      ...StageName
    }
    ${StageName.fragment}
  `,
}

interface AnalysisSectionFormProps
  extends FormComponentProps<StageUpdateAnalysisFields> {
  designStage: AnalysisSectionFormFragment
  readOnly?: boolean
  handleCreateInstructionCard?: () => Promise<void>
  handleRemoveInstructionCard?: () => Promise<void>
}

function AnalysisSectionForm(props: AnalysisSectionFormProps) {
  const {
    form: { getFieldDecorator },
    designStage,
    handleCreateInstructionCard,
    handleRemoveInstructionCard,
    readOnly = false,
  } = props

  return (
    <>
      <CheckPointCard
        patientId={designStage.patient.id}
        checkPoint={designStage.checkPoint}
        evalStage={designStage.patient.currentEvalStage}
      />
      <FormItem {...defaultLayout} label="治療內容 (上顎)">
        {readOnly
          ? (
            <MultiLine text={designStage.step.upperStep ?? ''} />
            )
          : (
              getFieldDecorator('step.upperStep', {
                initialValue: designStage.step.upperStep ?? '',
              })(<TextArea autoSize={{ minRows: 1 }} />)
            )}
      </FormItem>
      <FormItem {...defaultLayout} label="治療內容 (下顎)">
        {readOnly
          ? (
            <MultiLine text={designStage.step.lowerStep ?? ''} />
            )
          : (
              getFieldDecorator('step.lowerStep', {
                initialValue: designStage.step.lowerStep ?? '',
              })(<TextArea autoSize={{ minRows: 1 }} />)
            )}
      </FormItem>
      <FormItem {...defaultLayout} label="臨床指示">
        <InstructionTagList instructionCard={designStage.instructionCard} />
      </FormItem>
      <InstructionCardField
        readOnly={readOnly}
        instructionItem={designStage.instructionCard}
        handleRemoveInstructionCard={handleRemoveInstructionCard}
        handleCreateInstructionCard={handleCreateInstructionCard}
        stage={designStage}
      />
    </>
  )
}

AnalysisSectionForm.fragment = gql`
  fragment AnalysisSectionForm on DesignStage {
    ...InstructionCardStageLink
    patient {
      id
      currentEvalStage {
        ...EvalStageName
      }
    }
    step {
      upperStep
      lowerStep
    }
    checkPoint {
      ...DesignCheckPointCard
    }
    instructionCard {
      ...InstructionCardField
      ...InstructionTagList
    }
  }
  ${InstructionCardField.fragments.InstructionCardField}
  ${InstructionCardField.fragments.InstructionCardStageLink}
  ${CheckPointCard.fragments.DesignCheckPointCard}
  ${CheckPointCard.fragments.EvalStageName}
  ${InstructionTagList.fragments.InstructionTagList}
`

interface Props extends FormComponentProps<StageUpdateAnalysisFields> {
  item: FormAnalysisFragment
  handleCreateInstructionCard: () => Promise<void>
  handleRemoveInstructionCard: () => Promise<void>
}

function FormAnalysis(props: Props) {
  const {
    form,
    item,
    handleCreateInstructionCard,
    handleRemoveInstructionCard,
  } = props

  return (
    <>
      {item.__typename === 'DesignStage' && (
        <AnalysisSectionForm
          form={form}
          designStage={item}
          handleCreateInstructionCard={handleCreateInstructionCard}
          handleRemoveInstructionCard={handleRemoveInstructionCard}
        />
      )}
      {item.__typename === 'PrintStage'
      && (item.designStage
        ? (
          <>
            <AnalysisSectionForm
              form={form}
              designStage={item.designStage}
              readOnly
            />
            <FormItem {...defaultLayout} label="參考設計注意事項">
              {item.designStage.note}
            </FormItem>
          </>
          )
        : (
            '無參照設計'
          ))}
      {item.__typename === 'AccessoryStage'
      && (item.accessoryCode === AccessoryCode.Zero
      || item.accessoryCode === AccessoryCode.APlusZero) && (
        <InstructionCardField
          instructionItem={item.instructionCard}
          handleRemoveInstructionCard={handleRemoveInstructionCard}
          handleCreateInstructionCard={handleCreateInstructionCard}
          stage={item}
        />
      )}
    </>
  )
}

FormAnalysis.fragment = gql`
  fragment FormAnalysis on Stage {
    id
    __typename
    ... on DesignStage {
      ...AnalysisSectionForm
    }
    ... on PrintStage {
      designStage {
        note
        ...AnalysisSectionForm
      }
    }
    ... on AccessoryStage {
      ...InstructionCardStageLink
      instructionCard {
        ...InstructionCardPreview
      }
    }
  }
  ${AnalysisSectionForm.fragment}
  ${InstructionCardField.fragments.InstructionCardField}
  ${InstructionCardField.fragments.InstructionCardStageLink}
`
export default FormAnalysis
