import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { GqlUpload } from '@sov/ui'
import { Button, Col, Form, Row, message } from 'antd'
import type { GraphQLError } from 'graphql'
import { dissoc, evolve, includes, map, pick, pipe } from 'ramda'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import type {
  PatientDetailFormFragment,
  PatientDetailQueryQuery,
  PatientDetailQueryVariables,
  RemovePatientMutation,
  RemovePatientVariables,
  UpdatePatientMutation,
  UpdatePatientStatusMutation,
  UpdatePatientStatusVariables,
  UpdatePatientVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
  PatientStatus,
} from '../../../graphql/types'
import type {
  ConfirmButtonProps,
} from '../../components/common/button'
import {
  ConfirmButtonDropdownMenu,
  OnceButton,
} from '../../components/common/button'
import LoadingLayer from '../../components/common/LoadingLayer'
import type {
  FormGeneralDisplayInfo,
  FormGeneralInitialValues,
  FormIntraOralInitialValues,
  FormPhotoInitialValues,
  PaymentFormInitialValues,
} from '../../components/form/patient'
import {
  FormPatientBasic,
  FormPatientDoctorInstruction,
  FormPatientIntraOral,
  FormPatientPayment,
  FormPatientPhoto,
  FormPatientProfile,
  FormPatientSymptoms,
  getPayloadTransformer,
} from '../../components/form/patient'
import CollageModal from '../../components/form/patient/utils/CollageModal'
import Page, { Section } from '../../components/layout/Page'
import { useLoadingLayer } from '../../helpers/hooks'

const patientDetailFormFragment = gql`
  fragment PatientDetailForm on Patient {
    id
    status
    ...FormPatientBasic
    ...FormPatientPhoto
    ...FormPatientProfile
    ...FormPatientIntraOral
    ...FormPatientSymptoms
    ...FormPatientDoctorInstruction
  }
  ${FormPatientBasic.fragments.FormPatientBasic}
  ${FormPatientPhoto.fragments.FormPatientPhoto}
  ${FormPatientProfile.fragments.FormPatientProfile}
  ${FormPatientIntraOral.fragments.FormPatientIntraOral}
  ${FormPatientSymptoms.fragments.FormPatientSymptoms}
  ${FormPatientDoctorInstruction.fragments.FormPatientDoctorInstruction}
`
const patientDetailQuery = gql`
  query PatientDetailQuery($id: ID!) {
    patient(id: $id) {
      id
      ...PatientDetailForm
      ...CollageModal
    }
  }
  ${patientDetailFormFragment}
  ${CollageModal.fragments.CollageModal}
`

const updatePatientMutation = gql`
  mutation UpdatePatient($id: ID!, $payload: UpdatePatientInput!) {
    updatePatient(id: $id, payload: $payload) {
      id
      ...PatientDetailForm
    }
  }
  ${patientDetailFormFragment}
`

const updatePatientStatusMutation = gql`
  mutation UpdatePatientStatus($id: ID!, $status: PatientStatus) {
    updatePatientStatus(id: $id, status: $status) {
      id
      status
    }
  }
`

const removePatientMutation = gql`
  mutation RemovePatient($id: ID!) {
    removePatient(id: $id) {
      id
      message
    }
  }
`

interface PatientDetailFormProps {
  loading: boolean
  patient: PatientDetailFormFragment
  patientId: string
  setLoadingLayer: ReturnType<typeof useLoadingLayer>['setLoadingLayer']
  tip: ReturnType<typeof useLoadingLayer>['tip']
}

function PatientDetailForm(props: PatientDetailFormProps) {
  const { loading, patient, patientId, setLoadingLayer, tip } = props

  const history = useHistory()
  const [form] = Form.useForm()
  const [collageVisible, setCollageVisible] = useState(false)

  const [updatePatientStatus] = useMutation<
    UpdatePatientStatusMutation,
    UpdatePatientStatusVariables
  >(updatePatientStatusMutation)
  const [updatePatient] = useMutation<
    UpdatePatientMutation,
    UpdatePatientVariables
  >(updatePatientMutation)
  const [removePatient] = useMutation<
    RemovePatientMutation,
    RemovePatientVariables
  >(removePatientMutation)

  const pickedGeneralValue = pick(
    [
      'name',
      'patientCode',
      'cabinetCode',
      'gender',
      'creator',
      'clinic',
      'doctor',
      'accountManager',
      'sales',
      'technician',
      'customerService',
      'treatArches',
      'chiefComplaint',
      'note',
      'payment',
    ],
    patient,
  )

  const generalDisplayInfo: FormGeneralDisplayInfo = {
    clinicInfo: patient.clinic,
    doctorInfo: patient.doctor,
    accountManagerInfo: patient.accountManager,
    salesInfo: patient.sales,
    technicianInfo: patient.technician,
    customerServiceInfo: patient.customerService,
  }

  const generalInitialValues: FormGeneralInitialValues = {
    ...generalDisplayInfo,
    ...pickedGeneralValue,
    clinic: pickedGeneralValue.clinic.id,
    doctor: pickedGeneralValue.doctor.id,
    accountManager: pickedGeneralValue.accountManager?.id,
    sales: pickedGeneralValue.sales.id,
    technician: pickedGeneralValue.technician?.id,
    customerService: pickedGeneralValue.customerService?.id,
  }

  const paymentInitialValues: PaymentFormInitialValues = {
    treatArches: patient.treatArches,
    payment: patient.payment,
  }

  const photosInitialValues: FormPhotoInitialValues = pipe(
    pick(['photos']),
    evolve<any>({
      photos: {
        frontFace: GqlUpload.initialValueTransformer,
        sideFace45: GqlUpload.initialValueTransformer,
        sideFace: GqlUpload.initialValueTransformer,
        frontFaceWithTeeth: GqlUpload.initialValueTransformer,
        sideFace45WithTeeth: GqlUpload.initialValueTransformer,
        sideFaceWithTeeth: GqlUpload.initialValueTransformer,
        rightInside: GqlUpload.initialValueTransformer,
        frontInside: GqlUpload.initialValueTransformer,
        leftInside: GqlUpload.initialValueTransformer,
        upperJawInside: GqlUpload.initialValueTransformer,
        lowerJawInside: GqlUpload.initialValueTransformer,
        ceph: GqlUpload.initialValueTransformer,
        pano: GqlUpload.initialValueTransformer,
      },
    }),
  )(patient)

  const intraOralInitialValues: FormIntraOralInitialValues = pipe(
    pick(['intraOral']),
    evolve<any>({
      intraOral: {
        teethStatus: map(dissoc('__typename')),
      },
    }),
  )(patient)

  const handleUpdateStatus = async (status?: PatientStatus) => {
    const id = patientId
    try {
      await updatePatientStatus({
        variables: {
          id,
          status,
        },
        update: (cache, { data }) => {
          if (data)
            message.info('已更新病患狀態')
        },
      })
    }
    catch (e) {
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`更新失敗: ${e.message}`)
    }
  }

  const handleUpdate = async () => {
    try {
      // 檢查欄位
      await form.validateFields()
      // 只獲取有更動的欄位
      const values = form.getFieldsValue(true, ({ touched }) => touched)
      const payload = getPayloadTransformer(values)

      setLoadingLayer({ loading: true, tip: '更新中...' })
      const id = patientId
      await updatePatient({
        variables: {
          id,
          payload,
        },
        update: (cache, { data }) => {
          if (data) {
            setLoadingLayer({ loading: false, tip: '' })
            message.info('已更新病患詳情')
          }
        },
      })
    }
    catch (error) {
      if (error.errorFields)
        form.scrollToField(error.errorFields[0].name)

      const isDuplicatedPatientNameError = includes(
        'duplicated patient name',
        error.message,
      )
      if (isDuplicatedPatientNameError)
        message.error(`相同診所中病患名稱重複，建議在姓名後加上(出生年)做區隔`)
      else
        message.error(`更新失敗: ${error.message}`)
    }
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })
    const id = patientId
    try {
      await removePatient({
        variables: { id },
        refetchQueries: [
          {
            query: patientDetailQuery,
            variables: {
              query: {},
              page: 1,
              limit: 10,
              sort: '-updated',
            },
          },
        ],
        update: (cache, { data }) => {
          if (data) {
            setLoadingLayer({ loading: false, tip: '' })
            message.info('已刪除病患')
            history.push('/patients')
          }
        },
      })
    }
    catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`刪除失敗: ${e.message}`)
    }
  }

  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      confirmButtonType: 'completePatient',
      disabled: patient.status === PatientStatus.Completed,
      label: '已完結',
      modalProps: {
        onOk: () => handleUpdateStatus(PatientStatus.Completed),
      },
      requiredInputText: '已完結',
      requiredPrivilege: AllPrivilege.PatientClose,
      style: { color: 'gray' },
    },
    {
      confirmButtonType: 'inactivePatient',
      disabled: patient.status === PatientStatus.Inactive,
      label: '不追蹤',
      modalProps: {
        onOk: () => handleUpdateStatus(PatientStatus.Inactive),
      },
      requiredInputText: '不追蹤',
      requiredPrivilege: AllPrivilege.PatientClose,
      style: { color: 'gray' },
    },
    {
      confirmButtonType: 'activePatient',
      disabled:
        patient.status !== PatientStatus.Completed
        && patient.status !== PatientStatus.Inactive,
      label: '重新追蹤',
      modalProps: {
        onOk: () => handleUpdateStatus(),
      },
      requiredInputText: '重新追蹤',
      requiredPrivilege: AllPrivilege.PatientClose,
      style: { color: 'gray' },
    },
    {
      confirmButtonType: 'remove',
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除病患',
      requiredPrivilege: AllPrivilege.PatientDelete,
      style: { color: 'red' },
    },
  ]

  return (
    <LoadingLayer loading={loading} tip={tip}>
      <Row>
        <Col flex="1">
          <div style={{ float: 'right' }}>
            <Row>
              <OnceButton
                label="更新資料"
                onClick={handleUpdate}
                type="primary"
              />
              <ConfirmButtonDropdownMenu
                confirmButtonDropdownMenuItemsProps={
                  confirmButtonDropdownMenuItemsProps
                }
              />
            </Row>
          </div>

          {/* 基本資料區 */}
          <h3>基本資料</h3>
          <Form.Item label="建檔人" labelCol={{ span: 6 }}>
            {patient.creator?.name}
          </Form.Item>
          <FormPatientBasic form={form} initialValues={generalInitialValues} />

          {/* 付費方案區 */}
          <h3>付費方案</h3>
          <FormPatientPayment
            form={form}
            initialValues={paymentInitialValues}
          />

          {/* 照片區 */}
          <h3>照片</h3>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button onClick={() => setCollageVisible(true)}>
              照片合併預覽 (須先更新)
            </Button>
          </Form.Item>
          <FormPatientPhoto form={form} initialValues={photosInitialValues} />
          <CollageModal
            visible={collageVisible}
            item={patient}
            onCancel={() => setCollageVisible(false)}
            footer={null}
            maskClosable
            centered
          />

          {/* 顏面觀區域 */}
          <h3>顏面觀</h3>
          <FormPatientProfile
            form={form}
            initialValues={{ profile: patient.profile }}
          />

          {/* 口內觀區域 */}
          <h3>口內觀</h3>
          <FormPatientIntraOral
            form={form}
            initialValues={intraOralInitialValues}
          />

          {/* 矯正症狀區域 */}
          <h3>矯正症狀</h3>
          <FormPatientSymptoms
            form={form}
            initialValues={{ symptoms: patient.symptoms }}
          />

          {/* 醫生主要治療指示 */}
          <h3>醫生主要治療指示</h3>
          <FormPatientDoctorInstruction
            form={form}
            initialValues={{ doctorInstruction: patient.doctorInstruction }}
          />

          <Form.Item
            wrapperCol={{ span: 12, offset: 6 }}
            style={{ marginTop: 24 }}
          >
            <OnceButton
              label="更新資料"
              onClick={handleUpdate}
              type="primary"
            />
          </Form.Item>
        </Col>
      </Row>
    </LoadingLayer>
  )
}

interface RouteProps {
  patientId: string
}

const PatientDetail: FC = () => {
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const match = useRouteMatch<RouteProps>()
  const patientId = match.params.patientId
  const { data, loading } = useQuery<
    PatientDetailQueryQuery,
    PatientDetailQueryVariables
  >(patientDetailQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onCompleted: (data) => {
      if (!data || !data.patient) {
        toErrorPage({
          message: '不存在的病患',
          redirect: {
            name: '病患總覽',
            url: '/patients',
          },
        })
      }
    },
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
    },
  })

  const { tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const patient = data?.patient

  if (!patient)
    return <Page loading />

  return (
    <Section>
      <PatientDetailForm
        loading={loading}
        patient={patient}
        patientId={patientId}
        setLoadingLayer={setLoadingLayer}
        tip={tip}
      />
    </Section>
  )
}

export default PatientDetail
