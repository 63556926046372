import './InvoicePrint.less'

import { gql } from '@apollo/client'
import { StageName } from '@sov/ui'
import { Button, Modal } from 'antd'
import moment from 'moment'
import { equals, isEmpty, map, reduce } from 'ramda'
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'

import type { InvoicePrintModalFragment } from '../../../graphql/types'
import GoodsCode from '../common/GoodsCode'

interface ProfileTableWithPatientProps {
  invoiceItem: InvoicePrintModalFragment
}

function ProfileTableWithPatient({
  invoiceItem,
}: ProfileTableWithPatientProps) {
  const clinicItem = invoiceItem.clinic
  const doctorItem = invoiceItem.doctor

  return (
    <div className="profile-container">
      <div className="table">
        <div className="tr">
          <div className="td field">診所名稱</div>
          <div className="td field">負責醫師</div>
          <div className="td field">診所電話</div>
          <div className="td field">客戶地址</div>
        </div>
        <div className="tr">
          <div className="td">{clinicItem.fullname}</div>
          <div className="td">{doctorItem.name}</div>
          <div className="td">{clinicItem.phone}</div>
          <div className="td">{clinicItem.location.address}</div>
        </div>
        <div className="tr">
          {invoiceItem.__typename === 'InvoiceWithStage' && (
            <>
              <div className="td field">病患姓名</div>
              <div className="td field">報告</div>
              <div className="td field">工單</div>
            </>
          )}
          <div className="td field">出貨日</div>
        </div>
        <div className="tr">
          {invoiceItem.__typename === 'InvoiceWithStage' && (
            <>
              <div className="td">{invoiceItem.patient.name}</div>
              <div className="td">
                {invoiceItem.stage.__typename === 'PrintStage'
                && invoiceItem.stage.designStage
                  ? (
                    <StageName item={invoiceItem.stage.designStage.evalStage} />
                    )
                  : (
                      ''
                    )}
              </div>
              <div className="td">
                <StageName item={invoiceItem.stage} />
              </div>
            </>
          )}
          <div className="td">
            {invoiceItem.shippingDate
              ? moment(invoiceItem.shippingDate).format('YYYYMMDD')
              : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

interface SerailProductListTableProps {
  invoiceItem: InvoicePrintModalFragment
}

function SerailProductTable(props: SerailProductListTableProps) {
  const { invoiceItem } = props
  const { products } = invoiceItem

  if (isEmpty(products))
    return null

  /**
   * 製造單顯示「貨號」
   * 報告單、配件單顯示「貨品編號」
   */
  const showGoodsCode
    = invoiceItem.__typename === 'InvoiceWithStage'
    && invoiceItem.stage.__typename === 'PrintStage'

  return (
    <div className="product-list-container">
      <div className="table">
        <div className="tr">
          {showGoodsCode
            ? (
              <div className="td field">貨號</div>
              )
            : (
              <div className="td field" style={{ maxWidth: 80 }}>
                貨品編號
              </div>
              )}
          <div className="td field">貨品名稱</div>
          <div className="td field">規格</div>
          <div className="td field" style={{ maxWidth: 80 }}>
            數量
          </div>
          <div className="td field" style={{ maxWidth: 80 }}>
            單價
          </div>
          <div className="td field" style={{ maxWidth: 80 }}>
            總價
          </div>
        </div>
        {map(
          product => (
            <div className="tr">
              {/* 和 `showGoodsCode` 邏輯相同，為了 bypass ts-check */}
              {invoiceItem.__typename === 'InvoiceWithStage'
              && invoiceItem.stage.__typename === 'PrintStage'
                ? (
                  <div className="td">
                    <GoodsCode
                      patientCode={invoiceItem.patient.patientCode}
                      serialNumber={invoiceItem.stage.serialNumber}
                      expectedShippingDate={
                      invoiceItem.stage.expectedShippingDate
                    }
                    />
                  </div>
                  )
                : (
                  <div className="td" style={{ maxWidth: 80 }}>
                    {product.serialNumber}
                  </div>
                  )}
              <div className="td">{product.name}</div>
              <div className="td">{product.spec}</div>
              <div className="td" style={{ maxWidth: 80 }}>
                {product.count}
              </div>
              <div className="td" style={{ maxWidth: 80 }}>
                {product.price}
              </div>
              <div className="td" style={{ maxWidth: 80 }}>
                {product.count * product.price}
              </div>
            </div>
          ),
          products,
        )}
      </div>
    </div>
  )
}

/** @todo: 重構完出貨單中的出貨項目後，這個元件會需要重構，這邊暫時先註解 */
// interface PriceChangingProductsTableProps {
//   priceChangingProducts: PriceChangingProduct[]
// }

// const PriceChangingProductTable = ({ priceChangingProducts }: PriceChangingProductsTableProps) => {
//   if (isEmpty(priceChangingProducts)) {
//     return null
//   }

//   return (
//     <div className='product-list-container'>
//       <div className='table'>
//         <div className='tr'>
//           <div className='td field'>項目名稱</div>
//           <div className='td field' style={{ maxWidth: 80 }}>價格</div>
//         </div>
//         {map(product => (
//           <div className='tr'>
//             <div className='td'>{product.name}</div>
//             <div className='td' style={{ maxWidth: 80 }}>{product.price}</div>
//           </div>
//         ), priceChangingProducts)}
//       </div>
//     </div>
//   )
// }

interface ComponentToPrintProps {
  invoiceItem: InvoicePrintModalFragment
}

class ComponentToPrint extends React.Component<ComponentToPrintProps> {
  render() {
    const { invoiceItem } = this.props

    const serialProducts = invoiceItem.products
    const serialProductsTotal = reduce(
      (total, item) => total + item.count * item.price,
      0,
      serialProducts,
    )
    /** @todo: 重構完出貨單中的出貨項目後，這邊會需要重構，這邊暫時先註解 */
    // const priceChangingProducts = invoiceItem.priceChangingProducts
    // const priceChangingProductsTotal = reduce((total, item) => total + item.price, 0, priceChangingProducts)

    return (
      <div className="report-invoice-container">
        <div className="main-container">
          <div className="title-container">
            <div className="name">舒服美牙體技術所 出貨單</div>
            <div className="meta">
              <div>電話: (02) 2550 5042</div>
              <div>地址: 104台北市中山區民權西路19號8樓</div>
            </div>
          </div>
          <ProfileTableWithPatient invoiceItem={invoiceItem} />
          <SerailProductTable invoiceItem={invoiceItem} />
          {/** @todo: 重構完出貨單中的出貨項目後，這個元件會需要重構，這邊暫時先註解 */}
          {/* <PriceChangingProductTable priceChangingProducts={priceChangingProducts} /> */}
          <div className="summary-container">
            <div className="table">
              {!equals(0, serialProductsTotal) && (
                <div className="tr">
                  <div className="td">貨品小計</div>
                  <div className="td">
                    $
                    {serialProductsTotal}
                  </div>
                </div>
              )}
              {invoiceItem.tax && (
                <div className="tr">
                  <div className="td">稅</div>
                  <div className="td">
                    $
                    {serialProductsTotal * 0.05}
                  </div>
                </div>
              )}
              {!equals(0, invoiceItem.middlemanFee) && (
                <div className="tr">
                  <div className="td">轉介費</div>
                  <div className="td">
                    $
                    {invoiceItem.middlemanFee}
                  </div>
                </div>
              )}
              {/** @todo: 重構完出貨單中的出貨項目後，這邊會需要重構，這邊暫時先註解 */}
              {/* {!equals(0, priceChangingProductsTotal) && (
                <div className='tr'>
                  <div className='td'>其他項目</div>
                  <div className='td'>${priceChangingProductsTotal}</div>
                </div>
              )} */}
              {!equals(0, invoiceItem.discount) && (
                <div className="tr">
                  <div className="td">折扣</div>
                  <div className="td">
                    $
                    {invoiceItem.discount}
                  </div>
                </div>
              )}
              <div className="tr">
                <div className="td" style={{ fontSize: '16px' }}>
                  合計
                </div>
                <div className="td" style={{ fontSize: '16px' }}>
                  $
                  {invoiceItem.totalPrice}
                </div>
              </div>
            </div>
          </div>
          <div className="check-container">
            <div className="table">
              <div className="tr">
                <div className="td field" style={{ minWidth: '200px' }}>
                  客戶簽收
                </div>
                <div className="td field">業務</div>
                <div className="td field">會計</div>
                <div className="td field">出納</div>
                <div className="td field">審核</div>
                <div className="td field">填表</div>
              </div>
              <div className="tr content">
                <div className="td" style={{ minWidth: '200px' }} />
                <div className="td" />
                <div className="td" />
                <div className="td" />
                <div className="td" />
                <div className="td">Tina Lin</div>
              </div>
            </div>
            <div>
              1. 貨品若有規格，數量品質不符，請於 7 日內提出，逾期恕不受理
            </div>
            <div>
              2.
              依動產擔保法第三章，買方貨款未付或票據未兌現，貨品所有權歸賣方所有
            </div>
          </div>
        </div>
      </div>
    )
  }
}

interface InvoicePrintModalProps {
  handleModalClose: () => void
  isModalVisible: boolean
  invoiceItem: InvoicePrintModalFragment
}

function InvoicePrintModal(props: InvoicePrintModalProps) {
  const componentRef = useRef<any>()
  const { handleModalClose, isModalVisible, invoiceItem } = props

  return (
    <Modal
      footer={(
        <ReactToPrint
          trigger={() => <Button type="primary">列印</Button>}
          content={() => componentRef.current}
        />
      )}
      onCancel={handleModalClose}
      title="出貨單預覽列印"
      visible={isModalVisible}
      width={1024}
    >
      <ComponentToPrint invoiceItem={invoiceItem} ref={componentRef} />
    </Modal>
  )
}

InvoicePrintModal.fragment = gql`
  fragment InvoicePrintModal on Invoice {
    id
    __typename
    discount
    middlemanFee
    shippingDate
    tax
    totalPrice
    clinic {
      name
      fullname
      phone
      location {
        address
      }
    }
    doctor {
      name
    }
    products {
      count
      name
      price
      serialNumber
      spec
    }
    ... on InvoiceWithStage {
      patient {
        name
        patientCode
      }
      stage {
        ...StageName
        ... on PrintStage {
          serialNumber
          expectedShippingDate
          designStage {
            evalStage {
              ...StageName
            }
          }
        }
      }
    }
  }
  ${StageName.fragment}
`

export default InvoicePrintModal
