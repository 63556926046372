import { AllPrivilege } from '../../graphql/types'

/**
 * key: url, value: required privilege of corresponding page
 */
const requiredPrivilegesOfPages = {
  '/clinics/create': [AllPrivilege.ClinicCreate],
  '/doctors/create': [AllPrivilege.DoctorCreate],
  '/employees/create': [AllPrivilege.EmployeeCreate],
  '/employees/:employeeId': [AllPrivilege.EmployeeRead],
  '/feedbacks': [AllPrivilege.FeedbackRead],
  '/feedbacks/create': [AllPrivilege.FeedbackCreate],
  '/feedbacks/:feedbackId': [AllPrivilege.FeedbackRead],
  '/leaves': [AllPrivilege.LeaveRead],
  '/leaves/calendar': [AllPrivilege.LeaveRead],
  '/leaves/create': [AllPrivilege.LeaveCreate],
  '/leaves/:leaveId': [AllPrivilege.LeaveRead],
  '/patients/create': [AllPrivilege.PatientCreate],
  '/products': [AllPrivilege.ProductRead],
  '/products/create': [AllPrivilege.ProductCreate],
  '/products/:productId': [AllPrivilege.ProductRead],
  '/statistic': [AllPrivilege.StatisticRead],
  '/accounts/create': [AllPrivilege.AccountCreate],
}

export { requiredPrivilegesOfPages }
