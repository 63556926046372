import { useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import {
  calAnnual,
  calSeniority,
  formatSeniority,
} from '@sov/common/src/leaveTools'
import { Button, Statistic } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

import { employeeLeavesQuery } from '../../../../graphql/employee/query/employeeLeaves'
import { leavesCanApproveQuery } from '../../../../graphql/leave/query/canApprove'
import type {
  EmployeeLeavesQueryDocs,
  EmployeeLeavesQueryQuery,
  EmployeeLeavesQueryVariables,
  LeavesCanApproveQuery,
  LeavesQueryVariables,
} from '../../../../graphql/types'
import Page, { Section } from '../../layout/Page'
import EmployeeMenu, { EmployeeMenuKey } from '../../pageHeader/employee'
import LeaveTable from '../../table/Leave'
import type { LeaveFilterType, LeaveSorterField } from '../Leave'

function CreateButtonLink() {
  return (
    <Button style={{ marginLeft: 16 }}>
      <Link to="/leaves/create">新增假單</Link>
    </Button>
  )
}

function CalendarButtonLink() {
  return (
    <Button style={{ marginLeft: 16 }}>
      <Link to="/leaves/calendar">請假行事曆</Link>
    </Button>
  )
}

interface LeaveMenuProps {
  filter: string
  onClick: (filter: 'self' | 'approve') => void
}

export function LeaveMenu({ filter, onClick }: LeaveMenuProps) {
  return (
    <Button.Group>
      <Button
        type={filter === 'self' ? 'primary' : 'default'}
        onClick={() => onClick('self')}
      >
        自己的
      </Button>
      <Button
        type={filter === 'approve' ? 'primary' : 'default'}
        onClick={() => onClick('approve')}
      >
        可審核
      </Button>
    </Button.Group>
  )
}

interface RouteProps {
  employeeId: string
}

function EmployeeLeave() {
  const location = useLocation()
  const match = useRouteMatch<RouteProps>()
  const employeeId = match.params.employeeId

  const { tableQuery, paginateQuery, handleTableChange }
    = TableQuery.useTableQuery<LeaveFilterType, LeaveSorterField>({
      sort: '-startDate',
    })

  const [filter, setFilter] = useState<'self' | 'approve'>('self')
  const [loading, setLoading] = useState<boolean>(false)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const approvable = useQuery<LeavesCanApproveQuery, LeavesQueryVariables>(
    leavesCanApproveQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query: {
          ...tableQuery.filters,
        },
        ...paginateQuery,
      },
    },
  )
  const list = useQuery<EmployeeLeavesQueryQuery, EmployeeLeavesQueryVariables>(
    employeeLeavesQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        id: employeeId,
        query: {
          ...tableQuery.filters,
        },
        ...paginateQuery,
      },
    },
  )
  useEffect(() => {
    setLoading(list.loading || approvable.loading)
  }, [list.loading, approvable.loading])

  const { data } = list
  const { data: approvableData } = approvable
  const leavesCanApprove
    = approvableData && approvableData.leavesCanApprove
      ? approvableData.leavesCanApprove
      : undefined
  const hasApprovable = leavesCanApprove && leavesCanApprove.docs.length > 0
  if (!data || !data.employee || !data.employee.leaves)
    return <Page loading />

  const source = filter === 'self' ? data.employee.leaves : leavesCanApprove
  // 根據到職日計算年資(月)
  const seniority = calSeniority(data.employee.onBoardDate)
  // 取得總剩餘特休
  const { remainAnnual } = data.employee.leaveStatus
  // 根據年資取得年度特休天數
  const maxAnnualThisYear = calAnnual(seniority)
  // 剩餘特休超過年度特休，有積假的情形
  const needRest = remainAnnual > maxAnnualThisYear

  return (
    <Page
      header={(
        <EmployeeMenu
          item={data.employee}
          selectedKeys={[EmployeeMenuKey.EMPLOYEE_LEAVES]}
        />
      )}
      loading={loading}
    >
      <Section>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <h1>請假清單</h1>
            <h3>特休以年資作為計算，並包含過去未修完特休</h3>
          </div>
          <div>
            {hasApprovable && <LeaveMenu filter={filter} onClick={setFilter} />}
            <CreateButtonLink />
            <CalendarButtonLink />
          </div>
        </div>
        {filter === 'self' && (
          <div style={{ display: 'flex', marginBottom: 16 }}>
            <Statistic
              style={{ flex: 1 }}
              title="累計年資"
              value={seniority}
              formatter={formatSeniority}
            />
            <Statistic
              style={{ flex: 1, color: needRest ? 'red' : 'black' }}
              title="今年剩餘特休"
              suffix={`/ ${maxAnnualThisYear}`}
              value={remainAnnual}
            />
            <Statistic
              style={{ flex: 1 }}
              title="今年已請病假"
              value={data.employee.leaveStatus.usedSickLeave}
            />
            <Statistic
              style={{ flex: 1 }}
              title="今年已請事假"
              value={data.employee.leaveStatus.usedPersonalLeave}
            />
          </div>
        )}
        <LeaveTable<EmployeeLeavesQueryDocs>
          currentPathName={location.pathname}
          filterInfo={tableQuery.filters}
          handleChange={handleTableChange}
          loading={loading}
          sortInfo={tableQuery.sort}
          source={source}
        />
      </Section>
    </Page>
  )
}

export default EmployeeLeave
