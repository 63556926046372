import { gql } from '@apollo/client'

export const removeStageMutation = gql`
  mutation RemoveStage($id: ID!) {
    removeStage(id: $id) {
      id
      message
    }
  }
`
