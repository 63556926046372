import { Button, Modal } from 'antd'
import React from 'react'
import styled from 'styled-components'

import inDevelopmentImage from './in-development.png'

const CustomizedFooterButton = styled(Button)`
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
`

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 16px;
`

const Content = styled.div`
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
`

export { Content, CustomizedFooterButton, ModalBody, Title }

interface EditOrderModalProps {
  handleModalClose: () => void
  isModalVisible: boolean
}

function EditOrderModal(props: EditOrderModalProps) {
  const { handleModalClose, isModalVisible } = props

  return (
    <Modal
      bodyStyle={{
        padding: 0,
      }}
      closable={false}
      footer={[
        <CustomizedFooterButton key="close" onClick={handleModalClose}>
          我知道了
        </CustomizedFooterButton>,
      ]}
      visible={isModalVisible}
      onCancel={handleModalClose}
      width={480}
    >
      <ModalBody>
        <div>
          <img src={inDevelopmentImage} />
        </div>
        <Title>功能開發中</Title>
        <Content>
          <div>此功能目前仍在開發中，</div>
          <div>若您需要編輯或刪除訂單，請透過討論室與我們聯繫。</div>
        </Content>
      </ModalBody>
    </Modal>
  )
}

export default EditOrderModal
