import Axes from './Axes'
import Back from './Back'
import BothJaw from './BothJaw'
import Bottom from './Bottom'
import BottomJaw from './BottomJaw'
import Front from './Front'
import Hourglass from './Hourglass'
import Left from './Left'
import Normal from './Normal'
import PartAll from './PartAll'
import PartGums from './PartGums'
import PartTeeth from './PartTeeth'
import Right from './Right'
import Split from './Split'
import Top from './Top'
import TopJaw from './TopJaw'

export default {
  Axes,
  Back,
  BothJaw,
  Bottom,
  BottomJaw,
  Front,
  Left,
  Normal,
  PartAll,
  PartGums,
  PartTeeth,
  Right,
  Split,
  Top,
  TopJaw,
  Hourglass,
}
