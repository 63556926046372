import { Layout } from 'antd'
import type { ReactNode } from 'react'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled, { css } from 'styled-components'

import { SIDER_MENU_WIDTH, SIDER_MENU_WIDTH_COLLAPSED } from './constant'
import Header from './Header'
import Sider from './Sider'

const mobileContentStyle = css`
  margin-left: 0;
  min-width: 1366px;
  overflow-x: auto;
`

const StyledLayout = styled(Layout)`
  height: 100vh;

  @media print {
    * {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`

interface MainContentProps {
  collapsed: boolean
  isDesktop: boolean
}

const MainContent = styled.div<MainContentProps>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: any) =>
    props.collapsed ? SIDER_MENU_WIDTH_COLLAPSED : SIDER_MENU_WIDTH}px;

  ${(props: any) => !props.isDesktop && mobileContentStyle}

  width: ${(props: any) =>
    props.isDesktop
      ? props.collapsed
        ? `calc(100vw - ${SIDER_MENU_WIDTH_COLLAPSED}px)`
        : `calc(100vw - ${SIDER_MENU_WIDTH}px)`
      : '100%'};

  @media print {
    width: 100%;
  }
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 1;
`

interface Props {
  children: ReactNode
}

function CustomLayout(props: Props) {
  const { children } = props
  const [collapsed, setCollapsed] = useState(true)
  const [openKeys, setOpenKeys] = useState<string[]>([])

  const isDesktop = useMediaQuery({
    query: '(min-width: 576px)',
  })

  return (
    <StyledLayout hasSider>
      <Sider
        collapsed={collapsed}
        openKeys={openKeys}
        setCollapsed={setCollapsed}
        setOpenKeys={setOpenKeys}
      />
      <MainContent collapsed={collapsed} isDesktop={isDesktop}>
        <Header
          isDesktop={isDesktop}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          setOpenKeys={setOpenKeys}
        />
        <PageContent>{children}</PageContent>
      </MainContent>
    </StyledLayout>
  )
}

export default CustomLayout
