import { gql } from '@apollo/client'
import { identity, isNil } from 'ramda'
import type { ReactElement } from 'react'
import React from 'react'
import styled from 'styled-components'

import type {
  Auxiliary,
  InstructionTeethInfoFragment,
  ToothPosition,
} from '../../codegen/types'
import {
  AuxiliaryType,
} from '../../codegen/types'
import Auxiliaries from './Auxiliaries'
import Teeth from './Teeth'

const blue = 'rgba(0, 0, 255, 0.945)'
const brown = 'rgb(165, 103, 22)'
const red = 'red'
const clearBlue = '#1890ff'
const backgroundBlue = '#e6f7ff'

interface ContainerProps {
  editable: boolean
  editType?: AuxiliaryType
}

const Container = styled.div<ContainerProps>`
  .blue {
    color: ${blue};
    fill: ${blue};
  }
  .brown {
    color: ${brown};
    fill: ${brown};
  }
  .red {
    color: ${red};
    fill: ${red};
  }

  font-family: 'Heiti TC', 'Microsoft JhengHei' !important;
  background: white;
  height: 100%;
  padding: 0 16px;
  svg.instruction-container {
    .teeth {
      path {
        fill: white;
        stroke: black;
        stroke-width: 15px;
        &.disappear,
        &.disappear:hover {
          stroke-opacity: 0.1;
          fill: white;
          stroke: black;
          cursor: default;
        }
        &.dont-move,
        &.dont-move:hover {
          fill: #e5e5e5;
          stroke: black;
          cursor: default;
        }
        &:hover {
          cursor: ${props => (props.editable ? 'pointer' : 'default')};
          fill: ${props => (props.editable ? backgroundBlue : 'default')};
          stroke: ${props => (props.editable ? clearBlue : 'default')};
        }
        &.hidden {
          display: none;
        }
      }
    }
    .instruction {
      text {
        font-size: 1.2em;
      }
      .stripping-editable:hover {
        cursor: ${props =>
          // 只有在編輯 stripping 的時候才會顯示 stripping 的刪除 icon
          props.editable
          && (props.editType === AuxiliaryType.StrippingAll
          || props.editType === AuxiliaryType.StrippingHalf)
            ? 'url(https://cdn3.iconfinder.com/data/icons/objects/512/Bin-16.png), auto'
            : 'default'};
      }
      rect,
      circle {
        pointer-events: none;
      }
    }
  }
`

interface InstructionTeethInfoProps {
  instructionItem: InstructionTeethInfoFragment
  renderMenu?: (position: ToothPosition) => ReactElement | null
  handleClickTooth?: (position: ToothPosition) => void
  handleRemoveStripping?: (item: Auxiliary) => void
  editType?: AuxiliaryType
}

function InstructionTeethInfo(props: InstructionTeethInfoProps) {
  const {
    instructionItem,
    renderMenu,
    handleClickTooth = identity,
    handleRemoveStripping = identity,
    editType,
  } = props

  const auxiliaries = instructionItem.auxiliaries
  const teethStatus = instructionItem.teethStatus
  const editable = !isNil(editType)
  return (
    <Container editable={editable} editType={editType}>
      <svg
        width="100%"
        height="100%"
        className="instruction-container"
        viewBox="0 0 600 120"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="36" y="57" width="520" height="2" />
        <rect x="293" y="30" width="2" height="60" />
        <text x="0" y="64">
          右
        </text>
        <text x="576" y="64">
          左
        </text>
        <Teeth
          onClick={editable ? handleClickTooth : undefined}
          renderMenu={editable ? renderMenu : undefined}
          teethStatus={teethStatus}
        />
        <g className="instruction">
          <Auxiliaries
            items={auxiliaries}
            handleRemoveStripping={handleRemoveStripping}
          />
        </g>
      </svg>
    </Container>
  )
}

InstructionTeethInfo.fragments = {
  InstructionTeethInfo: gql`
    fragment InstructionTeethInfo on InstructionCard {
      teethStatus {
        position
        type
      }
      auxiliaries {
        value
        type
        toothPosition
        auxiliaryPosition
      }
    }
  `,
}

export default InstructionTeethInfo
