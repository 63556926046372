import { gql } from '@apollo/client'

import { OrderFragment } from '../fragment'

export const updateOrdersMutation = gql`
  mutation UpdateOrders($payloads: [BatchUpdateOrderInput!]!) {
    updateOrders(payloads: $payloads) {
      ...Order
      appointment {
        id
        startDate
      }
    }
  }
  ${OrderFragment}
`
