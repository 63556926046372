import { gql } from '@apollo/client'

export const DoctorInfoFragment = gql`
  fragment DoctorInfo on Doctor {
    id
    created
    updated
    clinics {
      id
      name
      sales {
        id
        name
        phone
      }
    }
    name
    phone
    note
    email
    patients {
      docs {
        id
        name
      }
      total
      limit
      page
    }
  }
`
