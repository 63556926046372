import type { AntSorterType } from '@sov/common'
import { LeaveTools, TableQuery } from '@sov/common'
import { Badge, Button, Table } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { identity } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type { Employee, Leave } from '../../../graphql/types'
import type { LeaveFilterType, LeaveSorterField } from '../index/Leave'
import EmployeeLink from '../link/employee'

interface Props<T> {
  currentPathName?: string
  filterInfo?: LeaveFilterType
  handleChange?: TableProps<T>['onChange']
  loading?: boolean
  sortInfo?: AntSorterType<LeaveSorterField>
  source?: {
    docs: T[]
    page?: number
    total: number
    limit: number
  }
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

type TableFields = Pick<Leave, Exclude<keyof Leave, 'employee'>> & {
  employee: Pick<Employee, 'id' | 'name'> & { approvers: Partial<Employee>[] }
}

export function LeaveTable<T extends TableFields>({
  currentPathName,
  filterInfo = {},
  handleChange = identity,
  loading,
  sortInfo,
  source = defaultSource,
}: Props<T>) {
  const { t } = useTranslation()

  const columns: ColumnProps<T>[] = [
    {
      title: '請假者',
      width: 60,
      render: (text, record) => <EmployeeLink item={record.employee} />,
    },
    {
      title: '開始時間',
      width: 80,
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      sortOrder: sortInfo?.field === 'startDate' ? sortInfo.order : undefined,
      render: (text) => {
        return <div>{moment(text).format('YYYY-MM-DD kk:mm')}</div>
      },
    },
    {
      title: '結束時間',
      width: 90,
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      sortOrder: sortInfo?.field === 'endDate' ? sortInfo.order : undefined,
      render: (text) => {
        return <div>{moment(text).format('YYYY-MM-DD kk:mm')}</div>
      },
    },
    {
      title: '類型',
      width: 60,
      dataIndex: 'type',
      key: 'type',
      render: text => <div>{t(`leave.${text}`)}</div>,
    },
    {
      title: '天數',
      width: 60,
      key: 'days',
      render: (text, record) => {
        return (
          <div>{LeaveTools.getLeaveDays(record.startDate, record.endDate)}</div>
        )
      },
    },
    {
      title: '已審核',
      width: 30,
      dataIndex: 'isApproved',
      key: 'isApproved',
      filters: [
        {
          text: '已審核',
          value: true,
        },
        {
          text: '未審核',
          value: false,
        },
      ],
      filterMultiple: false,
      filteredValue:
        typeof filterInfo.isApproved === 'boolean'
          ? [`${filterInfo.isApproved}`]
          : [],
      render: (text, record) =>
        text
          ? (
            <div>
              <Badge status="success" text="已審核" />
              <Button type="primary" size="small" style={{ marginLeft: 16 }}>
                <Link
                  to={{
                    pathname: `/leaves/${record.id}`,
                    state: { from: currentPathName },
                  }}
                >
                  編輯
                </Link>
              </Button>
            </div>
            )
          : (
            <div>
              <Badge status="default" text="待審核" />
              <Button type="primary" size="small" style={{ marginLeft: 16 }}>
                <Link
                  to={{
                    pathname: `/leaves/${record.id}`,
                    state: { from: currentPathName },
                  }}
                >
                  編輯
                </Link>
              </Button>
            </div>
            ),
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<T>
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '尚無請假紀錄' }}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
      rowKey="id"
      size="small"
    />
  )
}

export default LeaveTable
