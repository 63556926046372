import { UserOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { Avatar, Col, Row } from 'antd'
import moment from 'moment'
import { isEmpty } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import type { AccountManagerOrderListRowFragment } from '../../../../../graphql/types'
import TableHeaderAndTableRow from '../TableHeaderAndTableRow'

const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

interface RowProps {
  order: AccountManagerOrderListRowFragment
}

function OrderRow(props: RowProps) {
  const { order } = props

  const { created, description, displayId, patient } = order

  return (
    <Row align="middle">
      <Col span={2}>
        <Avatar
          src={patient.photos.frontFace?.thumbnailPath}
          icon={<UserOutlined />}
        />
      </Col>
      <Col span={3}>
        <TableHeaderAndTableRow headerTitle="病患">
          <Link to={`/patients/${patient.id}`}>{patient.name}</Link>
        </TableHeaderAndTableRow>
      </Col>
      <Col span={3}>
        <TableHeaderAndTableRow headerTitle="訂單編號">
          <div>{displayId}</div>
        </TableHeaderAndTableRow>
      </Col>
      <Col span={10}>
        <TableHeaderAndTableRow headerTitle="備註">
          <Text>{isEmpty(description) ? getEmptyText() : description}</Text>
        </TableHeaderAndTableRow>
      </Col>
      <Col span={6}>
        <TableHeaderAndTableRow headerTitle="訂單建立時間">
          <div>{moment(created).format('YYYY-MM-DD HH:mm')}</div>
        </TableHeaderAndTableRow>
      </Col>
    </Row>
  )
}

OrderRow.fragments = {
  AccountManagerOrderListRow: gql`
    fragment AccountManagerOrderListRow on Order {
      id
      patient {
        id
        name
        photos {
          frontFace {
            id
            thumbnailPath
          }
        }
      }
      displayId
      description
      created
    }
  `,
}

export default OrderRow
