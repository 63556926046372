import { gold } from '@ant-design/colors'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useErrorHandling } from '@sov/common/src/errorHandling'
import { StageName } from '@sov/ui'
import { Form, InputNumber, Space, Tag, message } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import type { DocumentNode } from 'graphql'
import { isEmpty } from 'ramda'
import React from 'react'

import type {
  CreateDesignStageMutation,
  CreateDesignStageMutationVariables,
  CreateDesignStageQueryQuery,
  CreateDesignStageQueryVariables,
  DesignFormDocs,
  DesignFormFragment,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import type {
  BaseModalFormFields,
  BaseModalProps,
} from './BaseModal'
import BaseModal, { formItemLayout } from './BaseModal'

const FormItem = Form.Item

const createDesignStage = gql`
  mutation createDesignStage($payload: CreateDesignStageInput!) {
    createDesignStage(payload: $payload) {
      id
      ...ForwardStageTaskForm
    }
  }
  ${BaseModal.fragments.ForwardStageTaskForm}
`

interface DesignFormFields extends BaseModalFormFields {
  serialNumber: number
  stepIndex: number
}

interface DesignFormProps {
  form: FormInstance<DesignFormFields>
  patientItem?: DesignFormFragment
}

const DesignForm: React.FC<DesignFormProps> & { fragment: DocumentNode } = (
  props,
) => {
  const { form, patientItem } = props
  if (!patientItem)
    return null

  const stageItems = patientItem.stages?.docs || []
  const currentEvalStage = patientItem.currentEvalStage

  const designStageSerialNumberList = stageItems
    .filter(
      (item): item is Extract<DesignFormDocs, { __typename: 'DesignStage' }> =>
        item.__typename === 'DesignStage' && item.status !== StageStatus.Dropped,
    )
    .map(item => item.serialNumber)
  const maxDesignStageSerialNumber = isEmpty(designStageSerialNumberList)
    ? 0
    : Math.max(...designStageSerialNumberList)

  return (
    <Form {...formItemLayout} form={form} requiredMark={false}>
      <FormItem label="主要報告">
        <Space>
          {currentEvalStage && <StageName item={currentEvalStage} />}
          {currentEvalStage?.isCPMode
            ? (
              <Tag color={gold.primary}>新版 CP 報告</Tag>
              )
            : (
              <Tag color="#cccccc">舊版預設計報告</Tag>
              )}
        </Space>
      </FormItem>
      {currentEvalStage?.isCPMode
        ? (
          <FormItem
            name="serialNumber"
            rules={[{ required: true, message: '請輸入 Step' }]}
            label="Step"
            initialValue={maxDesignStageSerialNumber + 1}
          >
            <InputNumber />
          </FormItem>
          )
        : (
          <FormItem
            name="stepIndex"
            rules={[{ required: true, message: '請輸入對應預設計' }]}
            label="對應預設計"
            initialValue={maxDesignStageSerialNumber + 1}
          >
            <InputNumber />
          </FormItem>
          )}
    </Form>
  )
}

DesignForm.fragment = gql`
  fragment DesignForm on Patient {
    id
    currentEvalStage {
      ...StageName
      isCPMode
    }
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        __typename
        status
        ... on DesignStage {
          serialNumber
        }
      }
    }
  }
  ${StageName.fragment}
`

const createDesignStageQuery = gql`
  query CreateDesignStageQuery(
    $id: ID!
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      id
      ...BaseModal
      ...DesignForm
    }
  }
  ${BaseModal.fragments.BaseModal}
  ${DesignForm.fragment}
`

interface CreateDesignStageModalProps
  extends Pick<BaseModalProps, 'visible' | 'onCancel' | 'onSubmit'> {
  patientId: string
}

function CreateDesignStageModal(props: CreateDesignStageModalProps) {
  const { visible, patientId, onCancel, onSubmit } = props

  const { toErrorPage } = useErrorHandling()
  const [create] = useMutation<
    CreateDesignStageMutation,
    CreateDesignStageMutationVariables
  >(createDesignStage)

  const { data, loading } = useQuery<
    CreateDesignStageQueryQuery,
    CreateDesignStageQueryVariables
  >(createDesignStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        type: [
          StageType.Print,
          StageType.Eval,
          StageType.Design,
          StageType.Mold,
        ],
        status: [StageStatus.Completed, StageStatus.Started],
      },
      limit: 300, // 目前 patient 總工單數不會超過
      sort: '-expectedShippingDate',
    },
    skip: !visible || !patientId,
  })

  const handleCreate = async (
    fieldsValue: DesignFormFields & BaseModalFormFields,
  ) => {
    let stageItem
    try {
      await create({
        variables: {
          payload: {
            ...fieldsValue,
            stepIndex: fieldsValue.stepIndex
              ? fieldsValue.stepIndex - 1
              : undefined,
            patient: patientId,
          },
        },
        update: async (cache, { data }) => {
          if (data?.createDesignStage) {
            message.info('已新增設計單，請選擇下個任務負責人')
            stageItem = data.createDesignStage
          }
        },
      })
      return stageItem
    }
    catch (error) {
      message.error(`新增失敗: ${error.graphQLErrors[0].message}`, 3)
      onCancel({ shouldRefresh: false })
    }
  }

  const patientItem = data?.patient

  return (
    <>
      <BaseModal
        visible={visible}
        loading={loading}
        patientItem={patientItem}
        stageType={StageType.Design}
        onCancel={onCancel}
        getCreatedStage={handleCreate}
        onSubmit={onSubmit}
      >
        {({ form }) => <DesignForm form={form} patientItem={patientItem} />}
      </BaseModal>
    </>
  )
}

export default CreateDesignStageModal
