import './index.less'

import {
  MinusOutlined,
  PlusOutlined,
  RedoOutlined,
  SwapOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { Modal, Slider } from 'antd'
import React, { forwardRef, useCallback, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'

function noop() {}

const MEDIA_CLASS = `antd-img-crop-media`
const MODAL_TITLE = '編輯圖片'

const MIN_ZOOM = 1
const MAX_ZOOM = 3
const ZOOM_STEP = 0.1

const MIN_ROTATE = 0
const MAX_ROTATE = 360
const ROTATE_STEP = 1

interface EasyCropProps {
  src: string
  aspect: number
  shape: 'rect' | 'round'
  grid: boolean
  hasZoom: boolean
  zoomVal: number
  rotateVal: number
  flip: {
    horizontal: boolean
    vertical: boolean
  }
  setZoomVal: (zoom: number) => void
  setRotateVal: (rotation: number) => void
  onComplete: any
}

const EasyCrop = forwardRef((props: EasyCropProps, ref: any) => {
  const {
    src,
    aspect,
    shape,
    grid,
    hasZoom,
    zoomVal,
    rotateVal,
    flip,
    setZoomVal,
    setRotateVal,
    onComplete,
  } = props

  const [crop, setCrop] = useState({ x: 0, y: 0 })

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onComplete(croppedAreaPixels)
    },
    [onComplete],
  )

  return (
    <Cropper
      ref={ref}
      image={src}
      aspect={aspect}
      cropShape={shape}
      showGrid={grid}
      zoomWithScroll={hasZoom}
      crop={crop}
      zoom={zoomVal}
      rotation={rotateVal}
      transform={[
        `translate(${crop.x}px, ${crop.y}px)`,
        `rotateZ(${rotateVal}deg)`,
        `rotateY(${flip.horizontal ? 180 : 0}deg)`,
        `rotateX(${flip.vertical ? 180 : 0}deg)`,
        `scale(${zoomVal})`,
      ].join(' ')}
      onCropChange={setCrop}
      onZoomChange={setZoomVal}
      onRotationChange={setRotateVal}
      onCropComplete={onCropComplete}
      classes={{
        containerClassName: `antd-img-crop-container`,
        mediaClassName: MEDIA_CLASS,
      }}
    />
  )
})

interface ImgCropProps {
  aspect?: number
  shape?: 'rect' | 'round'
  zoom?: boolean
  grid?: boolean
  rotate: boolean
  modalTitle?: string
  modalWidth?: number | string
  modalOk?: string
  modalCancel?: string
  children: React.ReactNode
}

const ImgCrop = forwardRef((props: ImgCropProps, ref: any) => {
  const {
    aspect = 1,
    shape = 'rect',
    grid = false,
    zoom = true,
    rotate = true,
    modalTitle = MODAL_TITLE,
    modalWidth = 520,
    modalOk,
    modalCancel,
    children,
  } = props

  const hasZoom = zoom === true
  const hasRotate = rotate === true

  const modalTextProps = { okText: modalOk, cancelText: modalCancel }
  Object.keys(modalTextProps).forEach((key) => {
    if (!modalTextProps[key])
      delete modalTextProps[key]
  })

  const [src, setSrc] = useState('')
  const [zoomVal, setZoomVal] = useState(1)
  const [rotateVal, setRotateVal] = useState(0)
  const [flip, setFlip] = useState({ horizontal: false, vertical: false })

  const beforeUploadRef = useRef<any>()
  const fileRef = useRef<any>()
  const resolveRef = useRef<any>(noop)
  const rejectRef = useRef<any>(noop)

  const cropPixelsRef = useRef()

  /**
   * Upload
   */
  const renderUpload = useCallback(() => {
    const upload = (Array.isArray(children) ? children[0] : children) as any
    const { beforeUpload, accept, ...restUploadProps } = upload.props
    beforeUploadRef.current = beforeUpload

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || 'image/*',
        beforeUpload: file =>
          new Promise((resolve, reject) => {
            fileRef.current = file
            resolveRef.current = resolve
            rejectRef.current = reject

            const reader = new FileReader() as any
            reader.addEventListener('load', () => {
              setSrc(reader.result)
            })
            reader.readAsDataURL(file)
          }),
      },
    }
  }, [children])

  /**
   * EasyCrop
   */
  const onComplete = useCallback((croppedAreaPixels) => {
    cropPixelsRef.current = croppedAreaPixels
  }, [])

  /**
   * Controls
   */
  const isMinZoom = zoomVal === MIN_ZOOM
  const isMaxZoom = zoomVal === MAX_ZOOM
  const isMinRotate = rotateVal === MIN_ROTATE
  const isMaxRotate = rotateVal === MAX_ROTATE

  const subZoomVal = useCallback(() => {
    if (!isMinZoom)
      setZoomVal(zoomVal - ZOOM_STEP)
  }, [isMinZoom, zoomVal])

  const addZoomVal = useCallback(() => {
    if (!isMaxZoom)
      setZoomVal(zoomVal + ZOOM_STEP)
  }, [isMaxZoom, zoomVal])

  const subRotateVal = useCallback(() => {
    if (!isMinRotate)
      setRotateVal(rotateVal - ROTATE_STEP)
  }, [isMinRotate, rotateVal])

  const addRotateVal = useCallback(() => {
    if (!isMaxRotate)
      setRotateVal(rotateVal + ROTATE_STEP)
  }, [isMaxRotate, rotateVal])

  /**
   * Modal
   */
  const onClose = useCallback(() => {
    setSrc('')
    setZoomVal(1)
    setRotateVal(0)
    setFlip({ horizontal: false, vertical: false })
  }, [])

  const onOk = useCallback(async () => {
    onClose()

    const naturalImg = document.querySelector(`.${MEDIA_CLASS}`)
    const { naturalWidth, naturalHeight } = naturalImg as any
    const { width, height, x, y } = cropPixelsRef.current as any
    const canvas = document.createElement('canvas') as any
    const ctx = canvas.getContext('2d') as any

    // create a max canvas to cover the source image after rotated
    const maxLen = Math.sqrt(
      naturalWidth ** 2 + naturalHeight ** 2,
    )
    canvas.width = maxLen
    canvas.height = maxLen

    const halfMax = maxLen / 2

    // rotate the image
    if (hasRotate && rotateVal > 0 && rotateVal < 360) {
      ctx.translate(halfMax, halfMax)
      ctx.rotate((rotateVal * Math.PI) / 180)
      ctx.translate(-halfMax, -halfMax)
    }

    // flip the image
    if (flip.horizontal || flip.vertical) {
      const scaleH = flip.horizontal ? -1 : 1
      const scaleV = flip.vertical ? -1 : 1
      ctx.translate(halfMax, halfMax)
      ctx.scale(scaleH, scaleV)
      ctx.translate(-halfMax, -halfMax)
    }

    // draw the source image in the center of the max canvas
    const left = (maxLen - naturalWidth) / 2
    const top = (maxLen - naturalHeight) / 2
    ctx.drawImage(naturalImg, left, top)

    // shrink the max canvas to the crop area size, then align two center points
    const maxImgData = ctx.getImageData(0, 0, maxLen, maxLen)
    canvas.width = width
    canvas.height = height
    ctx.putImageData(maxImgData, Math.round(-left - x), Math.round(-top - y))

    // get the new image
    const { type, name, uid } = fileRef.current as any
    canvas.toBlob(
      async (blob) => {
        let newFile = blob

        newFile.lastModifiedDate = Date.now()
        newFile.name = name
        newFile.uid = uid

        if (typeof beforeUploadRef.current !== 'function')
          return resolveRef.current(newFile)

        const res = beforeUploadRef.current(newFile, [newFile])

        if (typeof res !== 'boolean' && !res) {
          console.error('beforeUpload must return a boolean or Promise')
          return
        }

        if (res === true)
          return resolveRef.current(newFile)
        if (res === false)
          return rejectRef.current('not upload')
        if (res && typeof res.then === 'function') {
          try {
            const passedFile = await res
            const type = Object.prototype.toString.call(passedFile)
            if (type === '[object File]' || type === '[object Blob]')
              newFile = passedFile
            resolveRef.current(newFile)
          }
          catch (err) {
            rejectRef.current(err)
          }
        }
      },
      type,
      0.4,
    )
  }, [hasRotate, onClose, rotateVal, flip])

  return (
    <>
      {renderUpload()}
      {src && (
        <Modal
          visible
          wrapClassName="antd-img-crop-modal"
          title={modalTitle}
          width={modalWidth}
          onOk={onOk}
          onCancel={onClose}
          maskClosable={false}
          destroyOnClose
          {...modalTextProps}
        >
          <EasyCrop
            ref={ref}
            src={src}
            aspect={aspect}
            shape={shape}
            grid={grid}
            hasZoom={hasZoom}
            zoomVal={zoomVal}
            rotateVal={rotateVal}
            flip={flip}
            setZoomVal={setZoomVal}
            setRotateVal={setRotateVal}
            onComplete={onComplete}
          />
          <div className="antd-img-crop-control list">
            <div className="antd-img-crop-control zoom">
              <MinusOutlined onClick={subZoomVal} />
              <Slider
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                step={ZOOM_STEP}
                value={zoomVal}
                onChange={setZoomVal as any}
              />
              <PlusOutlined onClick={addZoomVal} />
            </div>
            <div className="antd-img-crop-control rotate">
              <UndoOutlined onClick={subRotateVal} />
              <Slider
                min={MIN_ROTATE}
                max={MAX_ROTATE}
                step={ROTATE_STEP}
                value={rotateVal}
                onChange={setRotateVal as any}
              />
              <RedoOutlined onClick={addRotateVal} />
            </div>
            <div className="antd-img-crop-control flip">
              <SwapOutlined
                onClick={() =>
                  setFlip(prev => ({ ...flip, horizontal: !prev.horizontal }))}
              />
              <SwapOutlined
                rotate={90}
                onClick={() =>
                  setFlip(prev => ({ ...flip, vertical: !prev.vertical }))}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
})

export default ImgCrop
