import { gql } from '@apollo/client'

import { DoctorInfoFragment } from '../fragment'

export const createDoctorMutation = gql`
  mutation CreateDoctor($payload: CreateDoctorInput!) {
    createDoctor(payload: $payload) {
      ...DoctorInfo
    }
  }
  ${DoctorInfoFragment}
`
