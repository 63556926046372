import { Dropdown } from 'antd'
import { identity, includes, map } from 'ramda'
import type { ReactElement } from 'react'
import React from 'react'

import type {
  ToothStatus,
} from '../../codegen/types'
import {
  ToothPosition,
  ToothStatusType,
} from '../../codegen/types'
import { findCurrentPositionToothStatusTypes } from '../InstructionUtils'

interface TeethSVG {
  position: ToothPosition
  d: string
}

// 牙齒 SVG 位置
const topTeeth: TeethSVG[] = [
  {
    position: ToothPosition.Fdi_1_8,
    d: `M-80 246 c-64 -18 -87 -40 -87 -85 0 -74 60 -117 146 -107 31 4 55 2
59 -4 10 -15 71 -12 85 5 7 8 15 35 17 59 6 52 -20 105 -60 123 -42 19 -110
23 -160 9z`,
  },
  {
    position: ToothPosition.Fdi_1_7,
    d: `M229 246 c-64 -18 -87 -40 -87 -85 0 -74 60 -117 146 -107 31 4 55 2
59 -4 10 -15 71 -12 85 5 7 8 15 35 17 59 6 52 -20 105 -60 123 -42 19 -110
23 -160 9z`,
  },
  {
    position: ToothPosition.Fdi_1_6,
    d: `M510 221 c-27 -12 -43 -27 -46 -42 -3 -13 -9 -33 -13 -44 -5 -11 -4
-17 0 -13 5 5 15 -1 22 -12 25 -35 72 -53 120 -46 25 3 53 1 65 -5 33 -18 65
-3 78 37 20 58 18 66 -16 96 -57 51 -138 62 -210 29z`,
  },
  {
    position: ToothPosition.Fdi_1_5,
    d: `M790 224 c-63 -66 -56 -114 20 -153 35 -17 56 -21 81 -17 41 8 75 48
75 87 0 47 -76 119 -126 119 -9 0 -32 -16 -50 -36z`,
  },
  {
    position: ToothPosition.Fdi_1_4,
    d: `M1035 235 c-30 -17 -74 -83 -69 -105 4 -19 27 -37 79 -61 36 -17 49
-19 75 -10 43 16 77 50 77 79 0 25 -71 102 -95 102 -7 0 -19 2 -27 5 -7 2 -25
-2 -40 -10z`,
  },
  {
    position: ToothPosition.Fdi_1_3,
    d: `M1250 253 c-12 -10 -30 -35 -41 -55 -30 -55 -19 -100 32 -138 58 -43
97 -41 136 7 17 21 31 46 31 56 0 48 -78 147 -116 147 -12 0 -31 -8 -42 -17z`,
  },
  {
    position: ToothPosition.Fdi_1_2,
    d: `M1450 239 c-28 -16 -47 -64 -47 -118 0 -59 14 -71 84 -71 73 0 92 38
62 123 -25 68 -57 90 -99 66z`,
  },
  {
    position: ToothPosition.Fdi_1_1,
    d: `M1635 239 c-18 -11 -38 -33 -44 -49 -6 -17 -15 -30 -19 -30 -4 0 -8
-16 -8 -35 0 -49 27 -72 94 -80 96 -12 135 9 136 73 0 55 -67 142 -110 142 -8
0 -30 -9 -49 -21z`,
  },
  {
    position: ToothPosition.Fdi_2_1,
    d: `M1896 217 c-52 -68 -59 -125 -20 -156 30 -23 86 -27 145 -11 52 15 62
30 53 87 -10 67 -56 113 -112 113 -34 0 -45 -5 -66 -33z`,
  },
  {
    position: ToothPosition.Fdi_2_2,
    d: `M2150 233 c-18 -7 -61 -102 -61 -137 0 -16 8 -35 18 -42 26 -20 105
-17 132 4 20 16 22 24 15 72 -10 76 -56 122 -104 103z`,
  },
  {
    position: ToothPosition.Fdi_2_3,
    d: `M2330 245 c-35 -24 -62 -69 -69 -112 -5 -35 -2 -44 26 -76 37 -43 67
-47 116 -16 42 25 54 47 54 94 0 43 -21 88 -52 109 -28 20 -48 20 -75 1z`,
  },
  {
    position: ToothPosition.Fdi_2_4,
    d: `M2530 231 c-42 -26 -75 -67 -75 -94 0 -28 45 -73 80 -82 17 -4 43 3
80 22 53 27 55 29 54 67 -1 32 -9 46 -38 73 -41 38 -59 40 -101 14z`,
  },
  {
    position: ToothPosition.Fdi_2_5,
    d: `M2740 225 c-20 -14 -39 -34 -43 -45 -3 -11 -11 -20 -16 -20 -5 0 -9
-12 -9 -26 0 -30 61 -94 88 -94 28 1 92 27 114 46 31 29 19 90 -26 131 -43 40
-60 41 -108 8z`,
  },
  {
    position: ToothPosition.Fdi_2_6,
    d: `M2990 249 c-19 -5 -50 -26 -68 -47 -30 -33 -32 -41 -26 -77 12 -77 41
-98 95 -71 22 11 37 13 56 5 34 -13 80 -3 119 27 59 44 53 117 -13 148 -46 22
-117 28 -163 15z`,
  },
  {
    position: ToothPosition.Fdi_2_7,
    d: `M3290 230 c-17 -5 -43 -23 -58 -40 -22 -25 -26 -37 -20 -65 8 -46 26
-67 55 -66 13 1 53 1 89 1 185 0 200 166 15 176 -27 2 -64 -1 -81 -6z`,
  },
  {
    position: ToothPosition.Fdi_2_8,
    d: `M3580 230 c-17 -5 -43 -23 -58 -40 -22 -25 -26 -37 -20 -65 8 -46 26
    -67 55 -66 13 1 53 1 89 1 185 0 200 166 15 176 -27 2 -64 -1 -81 -6z`,
  },
]

const bottomTeeth = [
  {
    position: ToothPosition.Fdi_4_8,
    d: `M90 211 c-3 -5 -32 -7 -64 -4 -55 5 -61 4 -95 -27 -22 -19 -37 -41
    -37 -55 0 -103 234 -127 279 -28 17 37 13 88 -7 106 -20 18 -67 23 -76 8z`,
  },
  {
    position: ToothPosition.Fdi_4_7,
    d: `M380 211 c-3 -5 -32 -7 -64 -4 -55 5 -61 4 -95 -27 -22 -19 -37 -41
-37 -55 0 -103 234 -127 279 -28 17 37 13 88 -7 106 -20 18 -67 23 -76 8z`,
  },
  {
    position: ToothPosition.Fdi_4_6,
    d: `M670 211 c-3 -5 -32 -7 -64 -4 -55 5 -61 4 -95 -27 -22 -19 -37 -41
-37 -55 0 -103 234 -127 279 -28 17 37 13 88 -7 106 -20 18 -67 23 -76 8z`,
  },
  {
    position: ToothPosition.Fdi_4_5,
    d: `M820 204 c-65 -39 -67 -80 -5 -146 39 -42 84 -38 133 11 50 49 52 89
10 132 -37 37 -81 38 -138 3z`,
  },
  {
    position: ToothPosition.Fdi_4_4,
    d: `M1040 200 c-51 -27 -63 -44 -55 -82 10 -49 52 -88 95 -88 40 1 66 16
93 57 27 42 23 77 -13 107 -36 31 -70 32 -120 6z`,
  },
  {
    position: ToothPosition.Fdi_4_3,
    d: `M1250 218 c-47 -35 -59 -51 -58 -78 1 -57 57 -140 94 -140 30 0 85 55
104 105 17 44 17 46 -4 80 -37 60 -84 71 -136 33z`,
  },
  {
    position: ToothPosition.Fdi_4_2,
    d: `M1557 220 c33 -33 -10 -218 -51 -226 -31 -6 -64 35 -77 96 -16 78 -11 114 20 129 32 16
    92 17 108 1z`,
  },
  {
    position: ToothPosition.Fdi_4_1,
    d: `M1735 210 c15 -22 16 -34 7 -83 -23 -124
    -68 -171 -111 -116 -22 28 -42 104 -42 160 0 41 33 64 92 65 30 0 41 -6 54
    -26z`,
  },
  {
    position: ToothPosition.Fdi_3_1,
    d: `M1960 217 c18 -15 20 -86
    4 -145 -18 -67 -70 -106 -96 -73 -18 23 -46 121 -47 164 -1 60 17 77 76 72 26
    -3 54 -11 63 -18z`,
  },
  {
    position: ToothPosition.Fdi_3_2,
    d: `M2128 215 c18 -15 19 -63 4 -128 -14 -56 -45 -94 -74 -90 -44 6 -87 192 -52 227 15 16 99 10
    122 -9z`,
  },
  {
    position: ToothPosition.Fdi_3_3,
    d: `M2222 218 c-47 -35 -59 -51 -58 -78 1 -57 57 -140 94 -140 30 0 85 55
104 105 17 44 17 46 -4 80 -37 60 -84 71 -136 33z`,
  },
  {
    position: ToothPosition.Fdi_3_4,
    d: `M2427 205 c-37 -24 -40 -30 -37 -61 6 -48 69 -104 118 -104 51 0 94 44
94 98 0 35 -4 43 -35 62 -55 34 -94 35 -140 5z`,
  },
  {
    position: ToothPosition.Fdi_3_5,
    d: `M2650 210 c-51 -41 -41 -114 22 -159 43 -31 87 -27 122 10 33 36 47 92
28 115 -20 24 -85 54 -117 54 -16 0 -41 -9 -55 -20z`,
  },
  {
    position: ToothPosition.Fdi_3_6,
    d: `M2850 208 c-7 -7 -12 -36 -12 -65 0 -45 4 -58 26 -78 37 -35 112 -50
    174 -35 70 17 100 43 100 86 0 63 -84 115 -150 93 -20 -7 -35 -7 -49 0 -26 15
    -74 14 -89 -1z`,
  },
  {
    position: ToothPosition.Fdi_3_7,
    d: `M3150 208 c-7 -7 -12 -36 -12 -65 0 -45 4 -58 26 -78 37 -35 112 -50
174 -35 70 17 100 43 100 86 0 63 -84 115 -150 93 -20 -7 -35 -7 -49 0 -26 15
-74 14 -89 -1z`,
  },
  {
    position: ToothPosition.Fdi_3_8,
    d: `M3450 208 c-7 -7 -12 -36 -12 -65 0 -45 4 -58 26 -78 37 -35 112 -50
    174 -35 70 17 100 43 100 86 0 63 -84 115 -150 93 -20 -7 -35 -7 -49 0 -26 15
    -74 14 -89 -1z`,
  },
]

interface TeethProps {
  onClick?: (position: ToothPosition) => void
  renderMenu?: (position: ToothPosition) => ReactElement | null
  teethStatus: ToothStatus[]
}

/* 顯示牙齒 */
function Teeth(props: TeethProps) {
  const { onClick = identity, renderMenu, teethStatus } = props
  const renderTeeth = (teethSVG: TeethSVG[]) =>
    map((toothSVG) => {
      const { position, d } = toothSVG
      const currentPositionStatus = findCurrentPositionToothStatusTypes(
        teethStatus,
        position,
      )
      const isMissingTooth = includes(
        ToothStatusType.Missing,
        currentPositionStatus,
      )
      const isToothDontMove = includes(
        ToothStatusType.DontMove,
        currentPositionStatus,
      )
      const overlay = renderMenu ? renderMenu(position) : null

      if (isMissingTooth) {
        return <path key={position} id={position} d={d} className="disappear" />
      }
      else if (isToothDontMove) {
        return <path key={position} id={position} d={d} className="dont-move" />
      }
      else if (overlay) {
        return (
          /** @tofix 暫時使用右鍵點擊開啟 dropdown */
          <Dropdown key={position} overlay={overlay} trigger={['contextMenu']}>
            <path key={position} id={position} d={d} />
          </Dropdown>
        )
      }
      else {
        return (
          <path
            key={position}
            id={position}
            d={d}
            onClick={() => onClick(position)}
          />
        )
      }
    }, teethSVG)

  return (
    <g className="teeth">
      <g transform="translate(39,60) scale(0.14,-0.14)" fill="black">
        {renderTeeth(topTeeth)}
      </g>
      <g transform="translate(44,95) scale(0.14,-0.14)" fill="black">
        {renderTeeth(bottomTeeth)}
      </g>
    </g>
  )
}

export default Teeth
