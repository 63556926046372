import { gql } from '@apollo/client'
import { getBadgeImageByLevel } from '@sov/common'
import { Space } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import type { DoctorLinkFragment } from '../../codegen/types'
import { Images } from '../../static'

interface Props {
  item: DoctorLinkFragment
  to?: string
  target?: string
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  showLabel?: boolean
}

function DoctorLink(props: Props) {
  const {
    item,
    target = '_self',
    to,
    className,
    style,
    children,
    showLabel = false,
  } = props

  return (
    <Link
      className={className}
      target={target}
      style={style}
      to={to || `/doctors/${item.id}`}
    >
      <Space>
        {children || item.name}
        {showLabel && <img src={getBadgeImageByLevel(Images, item.level)} />}
      </Space>
    </Link>
  )
}

DoctorLink.fragment = gql`
  fragment DoctorLink on Doctor {
    id
    name
    level
  }
`

export default DoctorLink
