import styled from 'styled-components'

const Label = styled.div`
  text-align: right;
`

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`

export { Label, SectionTitle }
