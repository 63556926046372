import { gql } from '@apollo/client'
import { GqlUpload } from '@sov/ui'

export const FeedbackInfoFragment = gql`
  fragment FeedbackInfo on Feedback {
    id
    created
    entity {
      id
      name
    }
    entityType
    category
    title
    content
    screenshotList {
      ...fileInfo
    }
    status
  }
  ${GqlUpload.Upload.fragment}
`
