import { gql } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery, getEmptyText } from '@sov/common'
import { Link } from '@sov/ui'
import { Table } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'

import type { DiariesQueryDocs, DiariesQueryQuery } from '../../../../graphql/types'
import type { DiarySorterField } from '../../../pages/diary'

enum ColumnKeys {
  DiaryDate = 'diaryDate',
  Created = 'created',
}

interface Props {
  handleChange?: TableProps<DiariesQueryDocs>['onChange']
  loading?: boolean
  sortInfo?: AntSorterType<DiarySorterField>
  source?: DiariesQueryQuery['diaries']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 100,
}

export function DiaryTable(props: Props) {
  const { handleChange, loading, source = defaultSource, sortInfo } = props

  const columns: ColumnProps<DiariesQueryDocs>[] = [
    {
      title: '診所',
      key: 'clinic',
      render: (_text, record) => (
        <Link.ClinicLink item={record.patient.clinic} />
      ),
    },
    {
      title: '病患',
      key: 'patient',
      render: (_text, record) => <Link.PatientLink item={record.patient} />,
    },
    {
      title: '日記日期',
      key: 'diaryDate',
      dataIndex: 'diaryDate',
      sortOrder:
        sortInfo?.field === ColumnKeys.DiaryDate ? sortInfo.order : undefined,
      sorter: true,
      render: (_text, record) => {
        return (
          <div>
            {record.diaryDate
              ? moment(record.diaryDate).format('YYYY-MM-DD')
              : getEmptyText()}
          </div>
        )
      },
    },
    {
      title: '日記新增日期',
      key: 'created',
      dataIndex: 'created',
      sortOrder:
        sortInfo?.field === ColumnKeys.Created ? sortInfo.order : undefined,
      sorter: true,
      render: (_text, record) => (
        <div>
          {record.created
            ? moment(record.created).format('YYYY-MM-DD HH:mm')
            : getEmptyText()}
        </div>
      ),
    },
    {
      title: '牙套編號',
      key: 'braceSerialNumber',
      dataIndex: 'braceSerialNumber',
      render: (_text, record) => (
        <div>{`${record.wear.subStage.stage.serialNumber}-${record.wear.subStage.serialNumber}`}</div>
      ),
    },
    {
      title: '牙套配戴日期',
      key: 'braceWearDate',
      dataIndex: 'braceWearDate',
      render: (_text, record) => (
        <div>
          {moment(record.wear.subStage.startDate).format('YYYY-MM-DD HH:mm')}
        </div>
      ),
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<DiariesQueryDocs>
      rowKey="id"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何日記單' }}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
    />
  )
}

DiaryTable.fragment = gql`
  fragment DiaryTable on Diary {
    id
    diaryDate
    patient {
      id
      name
      clinic {
        id
        name
      }
    }
    wear {
      id
      subStage {
        id
        stage {
          id
          serialNumber
        }
        serialNumber
        startDate
      }
    }
    created
  }
`

export default DiaryTable
