import { gql, useQuery } from '@apollo/client'
import {
  DateMomentParam,
  ErrorHandling,
  FormQuery,
  TableQuery,
} from '@sov/common'
import { Currency, DisplayPatientBrand, TablePatientInfo } from '@sov/ui'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Statistic,
  Table,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormItem from 'antd/lib/form/FormItem'
import moment from 'moment'
import { uniq } from 'ramda'
import React, { useEffect, useState } from 'react'

import type {
  FinishedPatientListQueryDocs,
  FinishedPatientListQueryQuery,
  FinishedPatientListQueryQueryVariables,
} from '../../../../graphql/types'
import {
  PatientStatus,
} from '../../../../graphql/types'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import EmployeeLink from '../../../components/link/employee'
import type {
  PatientFilterType,
  PatientSorterField,
} from '../../../components/table/Patient'

const query = gql`
  query FinishedPatientListQuery(
    $query: PatientsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        ...TablePatientInfo
        treatArches {
          upper
          lower
        }
        accountManager {
          id
          name
        }
        technician {
          id
          name
        }
        payment {
          base
          brand
        }
        meta {
          # 財務
          currentStepNumber
          finalStepNumber
          techRevenue
          techCost
          techProfit
          # 療程
          firstEvalStageAt
          firstPrintStageAt
          lastPrintStageAt
        }
      }
      total
      limit
      page
    }
  }
  ${TablePatientInfo.fragment}
`

interface PatientFormInput {
  bonusInterval: moment.Moment
}

const formInput = {
  bonusInterval: DateMomentParam,
}

interface Props {
  className: any
}

function StatisticFinishedPatient(props: Props) {
  const { className } = props
  const [form] = useForm<PatientFormInput>()
  const { tableQuery, paginateQuery } = TableQuery.useTableQuery<
    PatientFilterType,
    PatientSorterField
  >({ sort: '-meta.lastPrintStageAt', limit: 500 })
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data } = useQuery<
    FinishedPatientListQueryQuery,
    FinishedPatientListQueryQueryVariables
  >(query, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        status: [PatientStatus.Completed],
        ...tableQuery.filters,
        lastPrintStageAtInterval: formQuery.bonusInterval
          ? [
              moment(formQuery.bonusInterval).subtract(3, 'Q').startOf('Q'),
              moment(formQuery.bonusInterval).subtract(3, 'Q').endOf('Q'),
            ]
          : [
              moment().subtract(3, 'Q').startOf('Q'),
              moment().subtract(3, 'Q').endOf('Q'),
            ],
      },
      ...paginateQuery,
    },
  })

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [employeeId, setEmployeeId] = useState<string | null>(null)

  useEffect(() => {
    form.setFieldsValue(formQuery)
  }, [formQuery])

  const patients = data?.patients?.docs ?? []
  const employeeIds = uniq(
    patients
      .flatMap(x => [x.accountManager?.id, x.technician?.id])
      .filter(x => x),
  ) as string[]

  // 結案獎金
  const finished = 500

  const getBonus = (item: FinishedPatientListQueryDocs) => {
    // 營運成本比例 = 50%
    const operationRate = 0.5
    // 營運成本 = 技工收入 * 0.5
    const operationCost = item.meta.techRevenue * operationRate
    // 淨值 = 技工收入 - 技工成本 - 營運成本
    const netValue = item.meta.techRevenue - item.meta.techCost - operationCost
    // 獎金 = 淨值 + 結案獎金 / 2
    let reward = netValue >= 1000 ? Math.min(netValue / 2, 2500) : finished
    // 如果 AM 跟 技師是同一個人，獎金 * 1.5
    if (item.accountManager?.id === item.technician?.id)
      reward = reward * 1.5

    return {
      operationCost,
      netValue,
      reward,
    }
  }

  return (
    <div className={className}>
      <Page
        header={(
          <>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'StatisticFinishedPatient' }]}
            />
            <Title
              route={{
                key: 'StatisticFinishedPatient',
                renderSubtitle: () =>
                  `獎金公式 = 分潤率為 5 成，個案上限為 2500 下限為 500，AM 技師同一人則 * 1.5`,
              }}
            />
          </>
        )}
      >
        <Card>
          <Row gutter={32}>
            <Col>
              <Form
                form={form as any}
                initialValues={formQuery}
                onValuesChange={handleFormChange}
              >
                <FormItem name="bonusInterval" label="季獎金發放區間">
                  <DatePicker allowClear={false} picker="quarter" />
                </FormItem>
              </Form>
            </Col>
            <Col flex={1} />
            <Col>
              <Statistic title="總完結病患數" value={patients.length} />
            </Col>
            <Col>
              <Statistic
                title="病患 Step Final 區間"
                value={`${moment(formQuery?.bonusInterval)
                  .subtract(3, 'Q')
                  .startOf('Q')
                  .format('YYYY-MM-DD')} ~ ${moment(formQuery?.bonusInterval)
                  .subtract(3, 'Q')
                  .endOf('Q')
                  .format('YYYY-MM-DD')}`}
              />
            </Col>
          </Row>
          <Divider />
          {employeeIds
            .map((employeeId) => {
              const ownPatients = patients.filter(
                y =>
                  y.accountManager?.id === employeeId
                  || y.technician?.id === employeeId,
              )
              const bonus = ownPatients.reduce((pre, cur) => {
                if (cur.accountManager?.id === cur.technician?.id)
                  return pre + getBonus(cur).reward * 1.5
                else
                  return pre + getBonus(cur).reward
              }, 0)
              return {
                employeeId,
                ownPatients,
                bonus,
              }
            })
            .sort((a, b) => b.bonus - a.bonus)
            .map(({ employeeId, ownPatients, bonus }) => {
              const name
                = ownPatients[0].accountManager?.id === employeeId
                  ? ownPatients[0].accountManager?.name
                  : ownPatients[0].technician?.name

              return (
                <Row gutter={16} key={employeeId} style={{ marginTop: 16 }}>
                  <Col span={6}>
                    <Statistic title="姓名" value={name} />
                  </Col>
                  <Col span={6}>
                    <Statistic title="完結病患數" value={ownPatients.length} />
                  </Col>
                  <Col span={6}>
                    <Statistic title="合計獎金" value={bonus} />
                  </Col>
                  <Col
                    span={6}
                    onClick={() => {
                      setEmployeeId(employeeId)
                      setIsModalVisible(true)
                    }}
                  >
                    <Button>點擊查看個人詳情</Button>
                  </Col>
                </Row>
              )
            })}
          {employeeId && (
            <Modal
              style={{ top: 16 }}
              width="768"
              title="完結病患詳情"
              visible={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
            >
              <Table
                dataSource={patients.filter(
                  y =>
                    y.accountManager?.id === employeeId
                    || y.technician?.id === employeeId,
                )}
                columns={[
                  {
                    align: 'center',
                    title: '病患',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_value, record) => {
                      return <TablePatientInfo patient={record} />
                    },
                  },
                  {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'brand',
                    key: 'brand',
                    render: (_value, record) => {
                      return (
                        <DisplayPatientBrand value={record.payment.brand} />
                      )
                    },
                  },
                  {
                    align: 'center',
                    title: 'AM',
                    dataIndex: 'accountManager',
                    key: 'accountManager',
                    render: (_value, record) => {
                      return <EmployeeLink item={record.accountManager} />
                    },
                  },
                  {
                    align: 'center',
                    title: '技師',
                    dataIndex: 'technician',
                    key: 'technician',
                    render: (_value, record) => {
                      return <EmployeeLink item={record.technician} />
                    },
                  },
                  {
                    align: 'center',
                    title: '開始評估時間',
                    dataIndex: ['meta', 'fistEvalStageAt'],
                    key: 'fistEvalStageAt',
                    render: (_value, record) => {
                      return moment(record.meta.firstEvalStageAt).format(
                        'YYYY-MM-DD',
                      )
                    },
                  },
                  {
                    align: 'center',
                    title: 'Step 1 時間',
                    dataIndex: ['meta', 'firstPrintStageAt'],
                    key: 'firstPrintStageAt',
                    render: (_value, record) => {
                      return moment(record.meta.firstPrintStageAt).format(
                        'YYYY-MM-DD',
                      )
                    },
                  },
                  {
                    align: 'center',
                    title: 'Step Final 時間',
                    dataIndex: ['meta', 'lastPrintStageAt'],
                    key: 'lastPrintStageAt',
                    render: (_value, record) => {
                      return moment(record.meta.lastPrintStageAt).format(
                        'YYYY-MM-DD',
                      )
                    },
                  },
                  {
                    align: 'center',
                    title: 'Step 數',
                    dataIndex: ['meta', 'currentStepNumber'],
                    key: 'currentStepNumber',
                  },
                  {
                    align: 'center',
                    title: '顎數',
                    key: 'archCount',
                    render: (_value, record) => {
                      const archCount
                        = (record.treatArches?.upper ? 1 : 0)
                        + (record.treatArches?.lower ? 1 : 0) ?? 2
                      return archCount
                    },
                  },
                  {
                    align: 'center',
                    title: '技工收入',
                    dataIndex: ['meta', 'techRevenue'],
                    key: 'techRevenue',
                    render: (_value, record) => {
                      return <Currency value={record.meta.techRevenue} />
                    },
                  },
                  {
                    align: 'center',
                    title: '技工成本',
                    dataIndex: ['meta', 'techCost'],
                    key: 'techCost',
                    render: (_value, record) => {
                      return <Currency value={record.meta.techCost} />
                    },
                  },
                  {
                    align: 'center',
                    title: '運營成本',
                    key: 'operationCost',
                    render: (_value, record) => {
                      return <Currency value={getBonus(record).operationCost} />
                    },
                  },
                  {
                    align: 'center',
                    title: '殘值',
                    key: 'rest',
                    render: (_value, record) => {
                      return <Currency value={getBonus(record).netValue} />
                    },
                  },
                  {
                    align: 'center',
                    title: '個人獎金',
                    dataIndex: ['meta', 'techProfit'],
                    key: 'bonus',
                    render: (_value, record) => {
                      return <Currency value={getBonus(record).reward} />
                    },
                  },
                ]}
                pagination={false}
              />
            </Modal>
          )}
        </Card>
      </Page>
    </div>
  )
}

export default StatisticFinishedPatient
