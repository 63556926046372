import { Card, Skeleton } from 'antd'
import type { CSSProperties, ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'

const Header = styled.div`
  background-color: white;
  padding: 20px 20px 16px 20px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
`

interface SectionProps {
  padding?: string
  margin?: string
  flex?: string | number
}

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: ${props => props.padding ?? '16px'};
  flex: ${props => props.flex ?? 1};
`

const Pagelayout = styled.div`
  background: #f0f2f5;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DefaultLoadingComponent = <Skeleton active paragraph={{ rows: 12 }} />

interface Props {
  children?: ReactNode
  className?: string
  header?: ReactNode
  headerStyle?: CSSProperties
  contentStyle?: CSSProperties
  loading?: boolean
  loadingComponent?: ReactNode
}

function Page(props: Props) {
  const {
    className,
    header,
    headerStyle,
    children,
    loading,
    loadingComponent = DefaultLoadingComponent,
    contentStyle,
  } = props

  if (loading) {
    return (
      <Pagelayout className={className}>
        {header && (
          <Header>
            <Skeleton active />
          </Header>
        )}
        <Card size="small">{loadingComponent}</Card>
      </Pagelayout>
    )
  }

  return (
    <Pagelayout className={className}>
      {header && <Header style={headerStyle}>{header}</Header>}
      <Content style={contentStyle}>{children}</Content>
    </Pagelayout>
  )
}

export default Page
