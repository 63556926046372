import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Form, Input, Space } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { includes } from 'ramda'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import type {
  DoctorsQuery,
  DoctorsQueryQuery,
  DoctorsQueryVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
} from '../../../graphql/types'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import DoctorTable from '../../components/table/Doctor'
import { authContext } from '../../context'
import { getAuthPrivileges } from '../../utils'

export type DoctorFilterType = Pick<DoctorsQuery, 'level'>

const doctorsQuery = gql`
  query DoctorsQuery(
    $query: DoctorsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    doctors(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        ...DoctorTable
      }
      total
      limit
      page
    }
  }
  ${DoctorTable.fragments.DoctorTable}
`

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const formInput = {
  name: MyStringParam,
}

function CreateButtonLink() {
  const auth = useContext(authContext)

  if (!includes(AllPrivilege.DoctorCreate, getAuthPrivileges(auth)))
    return null

  return (
    <Button>
      <Link to="/doctors/create">新增醫生</Link>
    </Button>
  )
}

function SearchBtn(props) {
  return (
    <Button onClick={props.onClick} style={{ marginLeft: 8 }}>
      搜索
    </Button>
  )
}

interface DoctorFormInput {
  name?: string
}

interface DoctorFormProps {
  form: FormInstance
  handleSearch: (x: FormInstance) => void
  handleClear: (x: FormInstance) => void
  formQuery: DoctorFormInput
}

function DoctorForm(props: DoctorFormProps) {
  const { form, handleSearch, handleClear, formQuery } = props
  return (
    <Form layout="inline" style={{ marginBottom: '24px' }} form={form}>
      <Form.Item
        {...formItemLayout}
        label="醫師"
        name="name"
        initialValue={formQuery.name}
      >
        <Input style={{ width: 100 }} onPressEnter={() => handleSearch(form)} />
      </Form.Item>
      <Form.Item>
        <Space>
          <SearchBtn onClick={() => handleSearch(form)} />
          <Button onClick={() => handleClear(form)}>清除</Button>
          <CreateButtonLink />
        </Space>
      </Form.Item>
    </Form>
  )
}

export function DoctorIndex() {
  const { tableQuery, paginateQuery, handleTableChange }
    = TableQuery.useTableQuery<DoctorFilterType, any>()
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [form] = Form.useForm()
  const { data, loading } = useQuery<DoctorsQueryQuery, DoctorsQueryVariables>(
    doctorsQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      variables: {
        query: {
          name: formQuery.name,
          level: tableQuery.filters?.level,
        },
        ...paginateQuery,
      },
    },
  )

  const handleClear = (form: FormInstance) => {
    const { resetFields } = form
    resetFields()
    handleFormReset()
  }
  const handleSearch = (form: FormInstance) => {
    handleFormChange(form.getFieldsValue())
  }
  const source = data?.doctors

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'DoctorList' }]}
          />
          <Title route={{ key: 'DoctorList' }} />
        </>
      )}
    >
      <Section>
        <DoctorForm
          form={form}
          handleSearch={handleSearch}
          handleClear={handleClear}
          formQuery={formQuery}
        />
        <DoctorTable
          source={source}
          loading={loading}
          filterInfo={tableQuery.filters}
          handleTableChange={handleTableChange}
        />
      </Section>
    </Page>
  )
}

export default DoctorIndex
