import { gql, useMutation, useQuery } from '@apollo/client'
import { useErrorHandling } from '@sov/common/src/errorHandling'
import { StageName } from '@sov/ui'
import { Form, Radio, Select, message } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import type { RadioChangeEvent } from 'antd/lib/radio'
import type { DocumentNode } from 'graphql'
import { apply, filter, map, omit } from 'ramda'
import React from 'react'

import type {
  CreateEvalStageMutation,
  CreateEvalStageMutationVariables,
  CreateEvalStageQueryQuery,
  CreateEvalStageQueryVariables,
  EvalFormDocs,
  EvalFormFragment,
} from '../../../../graphql/types'
import {
  PatientStatus,
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import type {
  BaseModalFormFields,
  BaseModalProps,
} from './BaseModal'
import BaseModal, { formItemLayout } from './BaseModal'

const FormItem = Form.Item

const createEvalStage = gql`
  mutation createEvalStage($payload: CreateEvalStageInput!) {
    createEvalStage(payload: $payload) {
      id
      ...ForwardStageTaskForm
    }
  }
  ${BaseModal.fragments.ForwardStageTaskForm}
`

enum Cause {
  BRAND_NEW_EVAL = 'BRAND_NEW_EVAL',
  EDITED_EVAL = 'EDITED_EVAL',
  RECHECK = 'RECHECK',
  FAILURE_TO_WARE = 'FAILURE_TO_WARE',
}

function causeText(cause: Cause) {
  switch (cause) {
    case Cause.BRAND_NEW_EVAL:
      return `全新報告 (治療方向跟手段不同)`
    case Cause.EDITED_EVAL:
      return `修改自舊報告 (治療方向跟手段類似)`
    case Cause.RECHECK:
      return `上次戴成功 (僅純比對不出新牙套)`
    case Cause.FAILURE_TO_WARE:
      return `上次戴失敗 (戴第 x Step 失敗)`
  }
}

interface EvalFormFields extends BaseModalFormFields {
  cause: string
  initialEvalStage: string
  serialNumber: number
}

interface EvalFormProps {
  form: FormInstance<EvalFormFields>
  patientItem?: EvalFormFragment
}

const EvalForm: React.FC<EvalFormProps> & { fragment: DocumentNode } = (
  props,
) => {
  const { form, patientItem } = props

  if (!patientItem)
    return null

  const stageItems = patientItem.stages?.docs || []
  const { setFieldsValue } = form

  const isBeforePrint
    = patientItem.status === PatientStatus.OnEval
      ? true
      : patientItem.status !== PatientStatus.OnPrint
  const evalStageSerialNumberList = stageItems
    .filter(
      (stage): stage is Extract<EvalFormDocs, { __typename: 'EvalStage' }> =>
        stage.__typename === 'EvalStage'
        && stage.isBeforePrint === isBeforePrint
        && stage.status !== StageStatus.Dropped,
    )
    .map(evalStage => evalStage.serialNumber)
  const nextSerialNumber
    = evalStageSerialNumberList.length > 0
      ? apply(Math.max, evalStageSerialNumberList) + 1
      : 1

  // 新增報告的自動編號，最新報告的下一個
  const nextEvalCode = `報告 ${isBeforePrint ? 'V' : 'E'}${nextSerialNumber}`

  const initialValues = {
    serialNumber: nextSerialNumber,
  }
  const handleChangeCause = (e: RadioChangeEvent) => {
    setFieldsValue({ note: causeText(e.target.value) })
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      requiredMark={false}
    >
      <FormItem name="serialNumber" label="編號">
        <div>{nextEvalCode}</div>
      </FormItem>
      <FormItem
        name="cause"
        rules={[{ required: true, message: '請選擇原因' }]}
        label="下報告原因"
        {...formItemLayout}
      >
        <Radio.Group onChange={handleChangeCause}>
          <>
            {map(
              cause => (
                <Radio key={cause} value={cause} style={{ display: 'flex' }}>
                  <span style={{ whiteSpace: 'normal' }}>
                    {causeText(cause)}
                  </span>
                </Radio>
              ),
              isBeforePrint
                ? [Cause.BRAND_NEW_EVAL, Cause.EDITED_EVAL]
                : [Cause.RECHECK, Cause.FAILURE_TO_WARE],
            )}
          </>
        </Radio.Group>
      </FormItem>
      <FormItem dependencies={['cause']} noStyle>
        {form =>
          isBeforePrint
          && form.getFieldValue('cause') === Cause.EDITED_EVAL && (
            <FormItem
              name="initialEvalStage"
              rules={[{ required: true, message: '請選擇舊報告' }]}
              label="修改舊報告"
            >
              <Select style={{ width: 200 }}>
                {map(
                  item => (
                    <Select.Option key={item.id} value={item.id}>
                      <StageName item={item} />
                    </Select.Option>
                  ),
                  filter<EvalFormDocs>(
                    stage =>
                      stage.__typename === 'EvalStage'
                      && stage.isBeforePrint === true,
                    stageItems,
                  ),
                )}
              </Select>
            </FormItem>
          )}
      </FormItem>
    </Form>
  )
}

EvalForm.fragment = gql`
  fragment EvalForm on Patient {
    id
    status
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        __typename
        status
        ...StageName
        ... on EvalStage {
          isBeforePrint
          serialNumber
        }
      }
    }
  }
  ${StageName.fragment}
`

const createEvalStageQuery = gql`
  query CreateEvalStageQuery(
    $id: ID!
    $query: StagesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      id
      ...BaseModal
      ...EvalForm
    }
  }
  ${BaseModal.fragments.BaseModal}
  ${EvalForm.fragment}
`

interface CreateEvalStageModalProps
  extends Pick<BaseModalProps, 'visible' | 'onCancel' | 'onSubmit'> {
  orderId?: string
  patientId: string
}

function CreateEvalStageModal(props: CreateEvalStageModalProps) {
  const { visible, onCancel, onSubmit, orderId, patientId } = props

  const { toErrorPage } = useErrorHandling()
  const [create] = useMutation<
    CreateEvalStageMutation,
    CreateEvalStageMutationVariables
  >(createEvalStage)

  const { data, loading } = useQuery<
    CreateEvalStageQueryQuery,
    CreateEvalStageQueryVariables
  >(createEvalStageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {
        type: [StageType.Print, StageType.Eval],
        status: [StageStatus.Completed, StageStatus.Started],
      },
      limit: 300, // 目前 patient 總工單數不會超過
      sort: '-expectedShippingDate',
    },
    skip: !visible || !patientId,
  })

  const handleCreate = async (
    fieldsValue: EvalFormFields & BaseModalFormFields,
  ) => {
    let stageItem
    try {
      await create({
        variables: {
          payload: {
            ...omit(['cause'], fieldsValue),
            patient: patientId,
            ...(orderId ? { order: orderId } : {}),
          },
        },
        update: async (cache, { data }) => {
          if (data?.createEvalStage) {
            message.info('已新增報告單，請選擇下個任務負責人')
            stageItem = data.createEvalStage
          }
        },
      })
      return stageItem
    }
    catch (error) {
      message.error(`新增失敗: ${error.graphQLErrors[0].message}`, 3)
      onCancel({ shouldRefresh: false })
    }
  }

  const patientItem = data?.patient

  return (
    <>
      <BaseModal
        loading={loading}
        visible={visible}
        patientItem={patientItem}
        stageType={StageType.Eval}
        getCreatedStage={handleCreate}
        onCancel={onCancel}
        onSubmit={onSubmit}
      >
        {({ form }) => <EvalForm form={form} patientItem={patientItem} />}
      </BaseModal>
    </>
  )
}

export default CreateEvalStageModal
