import { gql } from '@apollo/client'

import { ClinicInfoFragment } from '../fragment'

export const clinicsQuery = gql`
  query ClinicsQuery(
    $query: ClinicsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    clinics(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...ClinicInfo
      }
      total
      limit
      page
    }
  }
  ${ClinicInfoFragment}
`
