import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { ChangePasswordModal } from '@sov/ui'
import { Button, Input, Tooltip } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import { map, path } from 'ramda'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import type {
  AccountDetailFormFragment,
  UpdateAccountInput,
} from '../../../../../../graphql/types'
import {
  Role,
  VerificationType,
} from '../../../../../../graphql/types'
import {
  ConfirmButton,
  OnceButton,
} from '../../../../../components/common/button'
import SocialIcon from '../../../../../components/common/SocialIcon'
import { authContext } from '../../../../../context'
import { getAccountIdFromAuth, isInRoles } from '../../../../../utils'

const FormContainer = styled.div`
  margin-top: 24px;
`

const FlexFormInputContainer = styled.div`
  display: flex;
`

const StyledInput = styled(Input)`
  width: 348px;
`

type VerifyButtonProps = ButtonProps & {
  isVerified: boolean
}
function VerifyButton(props: VerifyButtonProps) {
  const { isVerified, ...restProps } = props
  return (
    <OnceButton
      {...restProps}
      style={{ marginLeft: '16px' }}
      label={isVerified ? '已驗證' : '驗證'}
      type="primary"
      disabled={isVerified}
    />
  )
}

function VerifyLink() {
  /**
   * @todo
   * 補上複製連結行為
   */
  return (
    <div style={{ lineHeight: '33px', flexShrink: 0, marginLeft: '10px' }}>
      <a>複製連結</a>
    </div>
  )
}

function AccountIsActiveForm(props) {
  const { isActive, isGodOrManager, handleActiveChange } = props
  return isActive
    ? (
      <>
        <span style={{ color: '#52c41a', marginRight: '10px' }}>使用中</span>
        <ConfirmButton
          confirmButtonType="update"
          label="關閉帳號"
          requiredInputText="關閉帳號"
          modalProps={{ onOk: () => handleActiveChange(false) }}
          disabled={!isGodOrManager}
        />
      </>
      )
    : (
      <>
        <span style={{ color: '#f5222d', marginRight: '10px' }}>暫停使用</span>
        <Button
          type="primary"
          onClick={() => handleActiveChange(true)}
          disabled={!isGodOrManager}
        >
          開啟帳號
        </Button>
      </>
      )
}

export type AccountDetailFormField = Pick<
  AccountDetailFormFragment,
  'email' | 'phone' | 'isActive'
>

type Props = FormComponentProps<AccountDetailFormField> & {
  item: AccountDetailFormFragment
  handleSubmit: (payload: UpdateAccountInput) => Promise<void>
  handleVerify: (verificationType: VerificationType) => Promise<void>
}

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

type Platform = 'facebook' | 'google' | 'line'

function AccountDetailForm({
  form,
  item,
  handleSubmit,
  handleVerify,
}: Props) {
  const auth = useContext(authContext)
  const [modalVisible, setModalVisible] = useState(false)
  const { getFieldDecorator } = form

  const isSelf = getAccountIdFromAuth(auth) === item.id
  const isGodOrManager = isInRoles([Role.God, Role.Manager], auth)
  const canUpdatePrivateFields = isSelf || isGodOrManager
  const isAccountVerified = item.isEmailVerified || item.isPhoneVerified

  const getSocialBindUrl = (platform: Platform) => {
    const redirect = window.location.href
    const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}&type=bind`
    return url
  }

  const getSocialUnbindUrl = (platform: Platform) => {
    const redirect = window.location.href
    const url = `${process.env.API_HOST}/auth/${platform}?redirect=${redirect}&type=unbind`
    return url
  }

  const handleActiveChange = async (isActive: boolean) => {
    const payload = {
      isActive,
    }
    await handleSubmit(payload)
  }

  const handleChangePassword = async (password: string) => {
    const payload = {
      password,
    }
    await handleSubmit(payload)
    setModalVisible(false)
  }

  return (
    <FormContainer>
      <ChangePasswordModal
        visible={modalVisible}
        onSubmit={handleChangePassword}
        onCancel={() => setModalVisible(false)}
      />
      <FormItem {...formItemLayout} label="email帳號">
        <FlexFormInputContainer>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: '需要 Email 格式',
              },
              {
                required: true,
                message: '必填欄位',
              },
            ],
            initialValue: item.email,
          })(<StyledInput disabled={item.isEmailVerified} />)}
          <VerifyButton
            onClick={() => handleVerify(VerificationType.Email)}
            isVerified={item.isEmailVerified}
          />
          {!item.isEmailVerified && <VerifyLink />}
        </FlexFormInputContainer>
      </FormItem>
      <FormItem {...formItemLayout} label="手機帳號">
        <FlexFormInputContainer>
          {getFieldDecorator('phone', {
            rules: [
              {
                type: 'integer',
                message: '請符合手機格式',
              },
              {
                required: true,
                message: '必填欄位',
              },
            ],
            initialValue: item.phone,
          })(<StyledInput disabled={item.isPhoneVerified} />)}
          <VerifyButton
            onClick={() => handleVerify(VerificationType.Phone)}
            isVerified={item.isPhoneVerified}
          />
          {!item.isPhoneVerified && <VerifyLink />}
        </FlexFormInputContainer>
      </FormItem>
      {canUpdatePrivateFields && (
        <FormItem {...formItemLayout} label="登入密碼" required>
          <a onClick={() => setModalVisible(true)}>重設密碼</a>
        </FormItem>
      )}
      {canUpdatePrivateFields && (
        <FormItem
          {...formItemLayout}
          label={(
            <span>
              <Tooltip title="請先驗證過信箱或手機才能綁定社交帳號">
                <ExclamationCircleFilled style={{ opacity: 0.45 }} />
              </Tooltip>
              <span style={{ marginLeft: '6px' }}>社交綁定狀況 </span>
            </span>
          )}
        >
          <div>
            {map(
              (platform: Platform) => {
                const btnStyle = {
                  display: 'inline-block',
                  verticalAlign: 'center',
                  marginTop: '3px',
                  marginLeft: '10px',
                }
                return (
                  <div key={platform}>
                    {path(['providers', platform, 'id'], item)
                      ? (
                        <div>
                          <SocialIcon name={platform} />
                          <Button
                            style={btnStyle}
                            size="small"
                            danger
                            href={getSocialUnbindUrl(platform)}
                          >
                            解除綁定
                          </Button>
                        </div>
                        )
                      : (
                        <div>
                          <SocialIcon
                            disabled={!isAccountVerified}
                            name={platform}
                          />
                          <Button
                            disabled={!isAccountVerified}
                            style={btnStyle}
                            size="small"
                            type="primary"
                            href={getSocialBindUrl(platform)}
                          >
                            綁定
                          </Button>
                        </div>
                        )}
                  </div>
                )
              },
              ['facebook', 'google', 'line'],
            )}
          </div>
        </FormItem>
      )}
      <FormItem {...formItemLayout} label="狀態">
        {getFieldDecorator('isActive', {
          initialValue: item.isActive,
          valuePropName: 'checked',
        })(
          <AccountIsActiveForm
            isActive={item.isActive}
            isGodOrManager={isGodOrManager}
            handleActiveChange={handleActiveChange}
          />,
        )}
      </FormItem>
    </FormContainer>
  )
}

AccountDetailForm.fragment = gql`
  fragment AccountDetailForm on Account {
    id
    email
    isEmailVerified
    phone
    isPhoneVerified
    isActive
    providers {
      facebook {
        id
      }
      google {
        id
      }
      line {
        id
      }
      wechat {
        id
      }
    }
  }
`

export default AccountDetailForm
