import { gql, useMutation } from '@apollo/client'
import type {
  ModalProps,
  TableColumnType,
} from 'antd'
import {
  Button,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  message,
} from 'antd'
import type { TextAreaProps } from 'antd/lib/input'
import {
  any,
  isNil,
  map,
  prop,
  replace,
  split,
  splitWhen,
  tail,
  test,
  trim,
  uniq,
} from 'ramda'
import React, { useState } from 'react'

import type {
  BatchUpdateDesignStageMutation,
  BatchUpdateDesignStageMutationVariables,
  ModalBatchEditDesignDesignStageInlineFragment,
  ModalBatchEditDesignFragment,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'

const batchUpdateDesignStage = gql`
  mutation BatchUpdateDesignStage($id: ID!, $payload: UpdateDesignStageInput!) {
    updateDesignStage(id: $id, payload: $payload) {
      id
    }
  }
`

function getDesignStepList(rawContent: string): DesignStep[] {
  /** 斷行 */
  const splitByLine = split('\n', rawContent)
  /** 用「下顎」區分上下顎內容 */
  const splitByJaw = splitWhen(test(/下顎/), splitByLine)

  const upperContent = tail(splitByJaw[0])
  const lowerContent = tail(splitByJaw[1])

  const upperList = upperContent.map((step) => {
    /** 用全型冒號區分 serialNumber 及治療內容 */
    const splitByColon = split('：', step)
    /** 去除「step」及空白 */
    const serialNumber = trim(replace(/step/i, '', splitByColon[0]))
    return {
      serialNumber,
      upperStep: splitByColon[1],
    }
  })

  const lowerList = lowerContent.map((step) => {
    const splitByColon = split('：', step)
    const serialNumber = trim(replace(/step/i, '', splitByColon[0]))
    return {
      serialNumber,
      lowerStep: splitByColon[1],
    }
  })

  /** 合併所有 serialNumber，並排序 */
  const serialNumberList = uniq([
    ...map(prop('serialNumber'), upperList),
    ...map(prop('serialNumber'), lowerList),
  ]).sort((a, b) => Number(a) - Number(b))
  /** 以 serialNumber 當 key，取得上下顎治療內容 */
  const stepList = serialNumberList.map(serialNumber => ({
    serialNumber: Number(serialNumber),
    upperStep:
      upperList.find(item => item.serialNumber === serialNumber)?.upperStep
      ?? '',
    lowerStep:
      lowerList.find(item => item.serialNumber === serialNumber)?.lowerStep
      ?? '',
  }))

  return stepList
}

interface DesignStep {
  serialNumber: number
  upperStep: string
  lowerStep: string
}

interface ModalBatchEditDesignProps extends ModalProps {
  item: ModalBatchEditDesignFragment
  selectedSerialNumberList: number[]
  onCancel: () => void
  handleRefetch: () => Promise<void>
}

function ModalBatchEditDesign(props: ModalBatchEditDesignProps) {
  const {
    item,
    selectedSerialNumberList,
    onCancel,
    handleRefetch,
    ...restProps
  } = props

  const [loading, setLoading] = useState(false)
  const [rawContent, setRawContent] = useState('')
  const [updateDesign] = useMutation<
    BatchUpdateDesignStageMutation,
    BatchUpdateDesignStageMutationVariables
  >(batchUpdateDesignStage)

  const designStageList = (item.startedDesignStages?.docs
    ?? []) as ModalBatchEditDesignDesignStageInlineFragment[]
  const designStepList = getDesignStepList(rawContent).filter(step =>
    selectedSerialNumberList.includes(step.serialNumber),
  )

  const columns: TableColumnType<DesignStep>[] = [
    {
      key: 'step',
      title: 'Step',
      width: '80px',
      dataIndex: 'serialNumber',
      render: text => `Step ${text}`,
    },
    {
      title: '上顎治療內容',
      dataIndex: 'upperStep',
    },
    {
      title: '下顎治療內容',
      dataIndex: 'lowerStep',
    },
  ]

  const handleClose = () => {
    setRawContent('')
    onCancel()
  }
  const handleChangeRawContent: TextAreaProps['onChange'] = (e) => {
    setRawContent(e.target.value)
  }
  const handleSubmitForm = async () => {
    try {
      setLoading(true)
      const designStepListWithId = designStepList.map(step => ({
        ...step,
        id: designStageList.find(
          stage => stage.serialNumber === step.serialNumber,
        )?.id,
      }))

      if (any(isNil, designStepListWithId.map(prop('id'))))
        throw new Error('僅能填入進行中的設計單')

      const promiseList = designStepListWithId.map(step =>
        updateDesign({
          variables: {
            id: step.id as string,
            payload: {
              step: {
                upperStep: step.upperStep,
                lowerStep: step.lowerStep,
              },
            },
          },
        }),
      )

      await Promise.all(promiseList)
      await handleRefetch()
      message.success('已匯入設計內容')
      setLoading(false)
      handleClose()
    }
    catch (error) {
      setLoading(false)
      message.error(error.message, 3)
    }
  }

  const Footer = (
    <Row justify="space-between">
      <Button disabled={loading} onClick={handleClose}>
        取消
      </Button>
      <Button type="primary" loading={loading} onClick={handleSubmitForm}>
        確定
      </Button>
    </Row>
  )

  return (
    <Modal
      {...restProps}
      width="800px"
      title="批次匯入設計"
      footer={Footer}
      onCancel={handleClose}
    >
      <Space
        direction="vertical"
        style={{ width: '100%', height: '400px', overflow: 'scroll' }}
      >
        <Typography.Text>請貼上療程內容文字</Typography.Text>
        <Input.TextArea
          value={rawContent}
          placeholder="請貼上療程內容文字"
          autoSize={{ minRows: 3 }}
          onChange={handleChangeRawContent}
        />
        <Table
          columns={columns}
          dataSource={designStepList}
          pagination={false}
        />
      </Space>
    </Modal>
  )
}

ModalBatchEditDesign.fragments = {
  ModalBatchEditDesign: gql`
    fragment ModalBatchEditDesign on Patient {
      id
      startedDesignStages: stages(query: { type: [${StageType.Design}], status: [${StageStatus.Started}] }, limit: 100, sort: "serialNumber") {
        docs {
          id
          __typename
          ...on DesignStage {
            serialNumber
          }
        }
      }
    }
  `,
}

export default ModalBatchEditDesign
