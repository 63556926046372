import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { Notification } from '@sov/ui'
import { message } from 'antd'
import { isNil, map, prop, values } from 'ramda'
import React, { useContext, useState } from 'react'

import type {
  NotificationCreatedSubscription,
  NotificationCreatedSubscriptionVariables,
  NotificationIconQuery,
  NotificationIconQueryVariables,
  ReadAllNotificationMutationMutation,
  ReadAllNotificationMutationMutationVariables,
  ReadNotificationMutationMutation,
  ReadNotificationMutationMutationVariables,
  UnreadNotificationCountQuery,
  UnreadNotificationCountQueryVariables,
} from '../../../../graphql/types'
import {
  NotificationType,
  Platform,
} from '../../../../graphql/types'
import { authContext } from '../../../context'

/* ----- query string ----- */
const notificationIconQuery = gql`
  query NotificationIcon($query: NotificationsQuery = {}, $first: Int = 10) {
    notifications(query: $query, first: $first) {
      edges {
        node {
          ...NotificationItem
        }
      }
    }
  }
  ${Notification.fragment}
`
const unreadNotificationCountQuery = gql`
  query UnreadNotificationCount(
    $receiver: ID!
    $type: [NotificationType!]!
    $platform: Platform!
  ) {
    unreadNotificationCount(
      receiver: $receiver
      type: $type
      platform: $platform
    )
  }
`
export const readNotificationMutation = gql`
  mutation ReadNotificationMutation($id: ID!) {
    readNotification(id: $id) {
      id
    }
  }
`
export const readAllNotificationMutation = gql`
  mutation ReadAllNotificationMutation($receiver: ID!) {
    readAllNotification(receiver: $receiver) {
      statusCode
      message
    }
  }
`
export const notificationCreatedSubscription = gql`
  subscription NotificationCreated(
    $receiver: ID!
    $type: [NotificationType!]!
    $platform: Platform!
  ) {
    notificationCreated(receiver: $receiver, type: $type, platform: $platform) {
      id
    }
  }
`

interface NoticeIconProps {
  entityId: string
}

function NoticeIcon(props: NoticeIconProps) {
  const { entityId } = props

  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const { loading, data, refetch } = useQuery<
    NotificationIconQuery,
    NotificationIconQueryVariables
  >(notificationIconQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query: {
        type: values(NotificationType),
        receiver: [entityId],
        platform: Platform.Erp,
      },
    },
  })
  const { data: countQuery, refetch: refetchCount } = useQuery<
    UnreadNotificationCountQuery,
    UnreadNotificationCountQueryVariables
  >(unreadNotificationCountQuery, {
    variables: {
      receiver: entityId,
      type: values(NotificationType),
      platform: Platform.Erp,
    },
  })

  const [readNotification] = useMutation<
    ReadNotificationMutationMutation,
    ReadNotificationMutationMutationVariables
  >(readNotificationMutation)
  const [readAllNotification] = useMutation<
    ReadAllNotificationMutationMutation,
    ReadAllNotificationMutationMutationVariables
  >(readAllNotificationMutation)

  const handleRefetch = () => {
    refetch()
    refetchCount()
  }

  useSubscription<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >(notificationCreatedSubscription, {
    variables: {
      receiver: entityId,
      type: values(NotificationType),
      platform: Platform.Erp,
    },
    onSubscriptionData: () => {
      handleRefetch()
    },
  })

  const notificationList = map(prop('node'), data?.notifications?.edges || [])
  const unReadCount = countQuery?.unreadNotificationCount ?? 0

  const handleTogglePopover = (visible?: boolean) =>
    setIsPopoverVisible(state => (isNil(visible) ? !state : visible))

  const handleRead = async (id: string, read: boolean) => {
    /** 只有未讀的訊息才需要打 API */
    if (read)
      return
    try {
      await readNotification({
        variables: { id },
        update: (cache, { data }) => {
          if (data)
            handleRefetch()
        },
      })
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleReadAll = async () => {
    if (!entityId)
      return
    try {
      await readAllNotification({
        variables: { receiver: entityId },
        update: (cache, { data }) => {
          if (data && data.readAllNotification?.statusCode === '200')
            handleRefetch()
        },
      })
      message.info('已讀所有通知！')
    }
    catch (e) {
      console.log(e)
    }
    handleTogglePopover(false)
  }

  return (
    <Notification.Popover
      loading={loading}
      unReadCount={unReadCount}
      isPopoverVisible={isPopoverVisible}
      iconClassName="icon"
      notificationList={notificationList}
      listPagePath="/notifications"
      handleTogglePopover={handleTogglePopover}
      handleRead={handleRead}
      handleReadAll={handleReadAll}
    />
  )
}

export default () => {
  const auth = useContext(authContext)
  return auth ? <NoticeIcon entityId={auth.entity.id} /> : null
}
