import { gql } from '@apollo/client'

import { LeaveInfoFragment } from '../fragment'

export const updateLeaveMutation = gql`
  mutation UpdateLeave($id: ID!, $payload: UpdateLeaveInput!) {
    updateLeave(id: $id, payload: $payload) {
      ...LeaveInfo
    }
  }
  ${LeaveInfoFragment}
`
