import Badge0 from './images/level_badge_0.png'
import Badge1 from './images/level_badge_1.png'
import Badge2 from './images/level_badge_2.png'
import Badge3 from './images/level_badge_3.png'
import Badge4 from './images/level_badge_4.png'
import Badge5 from './images/level_badge_5.png'
import BadgeDecorated0 from './images/level_badge_decorated_0.png'
import BadgeDecorated1 from './images/level_badge_decorated_1.png'
import BadgeDecorated2 from './images/level_badge_decorated_2.png'
import BadgeDecorated3 from './images/level_badge_decorated_3.png'
import BadgeDecorated4 from './images/level_badge_decorated_4.png'
import BadgeDecorated5 from './images/level_badge_decorated_5.png'

export const Images = {
  Badge0,
  Badge1,
  Badge2,
  Badge3,
  Badge4,
  Badge5,
  BadgeDecorated0,
  BadgeDecorated1,
  BadgeDecorated2,
  BadgeDecorated3,
  BadgeDecorated4,
  BadgeDecorated5,
}
