import { gold } from '@ant-design/colors'
import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { EditOutlined } from '@ant-design/icons'
import type { QueryResult } from '@apollo/client'
import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { Link, StageName } from '@sov/ui'
import { Col, InputNumber, Row, Space, Tag } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  AccessoryCode,
  FormBasicFragment,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { isInternalRoles } from '../../../utils'
import PatientLink from '../../link/patient'
import ExpectedShippingDateModal from '../../modal/ExpectedShippingDateModal'
import { StageSelect } from '../Select'

const Clickable = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #1890ff;
  }
`

const FormItem = Form.Item
export interface StageUpdateBasicFields {
  accessoryCode: AccessoryCode
  serialNumber: number
  moldStage: string
  designStage: string
  expectedShippingDate: moment.Moment
  note: string
}

interface Props extends FormComponentProps<StageUpdateBasicFields> {
  item: FormBasicFragment
  refetch: QueryResult['refetch']
}

function FormBasic(props: Props) {
  const { form, item, refetch } = props
  const { getFieldDecorator } = form
  const auth = useContext(authContext)
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleModalOpen = () => {
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleExpectedShippingDateUpdated = () => {
    refetch()
  }

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Row>
        <Col span={12}>
          <FormItem label="病患">
            <PatientLink item={item.patient} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="工單">
            <Space>
              {isInternalRoles(auth)
                ? (
                  <div>
                    <StageName item={item} showSerialNumber={false} />
                    {item.__typename === 'AccessoryStage'
                    && t(`stage.accessoryCode.${item.accessoryCode}`)}
                    {(item.__typename === 'EvalStage'
                    || item.__typename === 'MoldStage')
                    && item.serialNumber}
                    {(item.__typename === 'DesignStage'
                    || item.__typename === 'PrintStage')
                    && getFieldDecorator('serialNumber', {
                      initialValue: item.serialNumber,
                      rules: [
                        {
                          required: true,
                          message: '不得為空白',
                        },
                      ],
                    })(
                      <InputNumber
                        style={{ maxWidth: 100, marginLeft: '5px' }}
                        min={1}
                        max={99}
                        type="number"
                      />,
                    )}
                  </div>
                  )
                : (
                  <Link.StageLink item={item} />
                  )}
              {item.__typename === 'EvalStage'
              && (item.isCPMode
                ? (
                  <Tag color={gold.primary}>新版 CP 報告</Tag>
                  )
                : (
                  <Tag color="#cccccc">舊版預設計報告</Tag>
                  ))}
            </Space>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="預計出貨">
            <Clickable onClick={handleModalOpen}>
              <Space size={8}>
                <div>
                  {item.expectedShippingDate
                    ? moment(item.expectedShippingDate).format('YYYY-MM-DD')
                    : getEmptyText()}
                </div>
                <EditOutlined />
              </Space>
            </Clickable>
            <ExpectedShippingDateModal
              handleExpectedShippingDateUpdated={
                handleExpectedShippingDateUpdated
              }
              handleModalClose={handleModalClose}
              isVisible={isModalVisible}
              selectedStage={item}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          {item.__typename === 'EvalStage' && (
            <FormItem label="參照建模">
              {getFieldDecorator('moldStage', {
                initialValue: item.moldStage ? item.moldStage.id : null,
              })(
                <StageSelect
                  style={{ width: 200 }}
                  query={{
                    patient: item.patient.id,
                    type: [StageType.Mold],
                    status: [StageStatus.Started, StageStatus.Completed],
                  }}
                  allowClear
                  dropdownMatchSelectWidth={false}
                />,
              )}
            </FormItem>
          )}
          {item.__typename === 'DesignStage' && (
            <FormItem label="所屬報告">
              <Space>
                <Link.StageLink item={item.evalStage} />
                {item.evalStage.isCPMode
                  ? (
                    <Tag color={gold.primary}>新版 CP 報告</Tag>
                    )
                  : (
                    <Tag color="#cccccc">舊版預設計報告</Tag>
                    )}
              </Space>
            </FormItem>
          )}
          {item.__typename === 'PrintStage' && (
            <FormItem label="參考設計">
              {getFieldDecorator('designStage', {
                initialValue: item.designStage ? item.designStage.id : null,
              })(
                <StageSelect
                  style={{ width: 200 }}
                  query={{ patient: item.patient.id, type: [StageType.Design] }}
                  allowClear
                />,
              )}
            </FormItem>
          )}
        </Col>
      </Row>
      <FormItem
        label="本工單注意事項"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        {getFieldDecorator('note', {
          initialValue: item.note,
        })(<TextArea autoSize={{ minRows: 3 }} />)}
      </FormItem>
    </Form>
  )
}

FormBasic.fragment = gql`
  fragment FormBasic on Stage {
    id
    ...ExpectedShippingDateModal
    ...StageName
    __typename
    expectedShippingDate
    note
    patient {
      id
      name
    }
    ... on EvalStage {
      isCPMode
      moldStage {
        id
      }
    }
    ... on DesignStage {
      evalStage {
        id
        ...StageLink
        isCPMode
      }
    }
    ... on PrintStage {
      designStage {
        id
      }
    }
  }
  ${ExpectedShippingDateModal.fragments.ExpectedShippingDateModal}
  ${StageName.fragment}
  ${Link.StageLink.fragment}
`

export default FormBasic
