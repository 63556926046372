import { gql } from '@apollo/client'
import { Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import type { ClinicMenuFragment } from '../../../../graphql/types'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Title from '../../layout/Title'

export const clinicMenuFragment = gql`
  fragment ClinicMenu on Clinic {
    id
    name
  }
`

interface Props {
  item: ClinicMenuFragment
  selectedKeys: string[]
}

function ClinicMenu(props: Props) {
  const { item, selectedKeys } = props

  return (
    <div style={{ marginBottom: -16 }}>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'ClinicList' },
          {
            key: 'ClinicDetail',
            render: () => <Link to={`/clinics/${item.id}`}>{item.name}</Link>,
          },
        ]}
      />
      <Title route={{ key: 'ClinicDetail', render: () => item.name }} />
      <Menu mode="horizontal" selectedKeys={selectedKeys}>
        <Menu.Item key="clinic-detail">
          <Link to={`/clinics/${item.id}`}>診所詳情</Link>
        </Menu.Item>
        <Menu.Item key="clinic-invoice-index">
          <Link to={`/clinics/${item.id}/invoices`}>診所出貨單</Link>
        </Menu.Item>
      </Menu>
    </div>
  )
}

ClinicMenu.fragments = {
  ClinicMenu: clinicMenuFragment,
}

export default ClinicMenu
