import { Card } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  margin: 16px;
`

interface EmptyInstructionCardProps {
  loading: boolean
}

function EmptyInstructionCard(props: EmptyInstructionCardProps) {
  const { loading } = props
  return (
    <Card
      size="small"
      title="訂單資訊"
      bordered={false}
      loading={loading}
      style={{ width: 300 }}
      bodyStyle={{ padding: 0 }}
    >
      <Content>無訂單來源，如對醫生指示有疑問請詢問業務。</Content>
    </Card>
  )
}

export default EmptyInstructionCard
