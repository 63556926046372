import { gql } from '@apollo/client'
import { always, cond, equals } from 'ramda'
import React from 'react'

import { EntityType } from '../../../../graphql/types'
import type { ClinicSelectProps } from './ClinicSelect'
import ClinicSelect from './ClinicSelect'
import type { DoctorSelectProps } from './DoctorSelect'
import DoctorSelect from './DoctorSelect'
import type { EmployeeSelectProps } from './EmployeeSelect'
import EmployeeSelect from './EmployeeSelect'
import type { PatientSelectProps } from './PatientSelect'
import PatientSelect from './PatientSelect'

function getEntitySelect(entityType: EntityType) {
  return cond([
    [equals(EntityType.Clinic), always(ClinicSelect)],
    [equals(EntityType.Doctor), always(DoctorSelect)],
    [equals(EntityType.Employee), always(EmployeeSelect)],
    [equals(EntityType.Patient), always(PatientSelect)],
  ])(entityType)
}

type SelectProps =
  | ClinicSelectProps
  | DoctorSelectProps
  | EmployeeSelectProps
  | PatientSelectProps

type EntitySelectProps = SelectProps & {
  entityType: EntityType
}

function EntitySelect(props: EntitySelectProps) {
  const { entityType, ...restProps } = props

  const RenderedSelect = getEntitySelect(entityType)

  return <RenderedSelect {...restProps} />
}

EntitySelect.fragments = {
  EntitySelect: gql`
    fragment EntitySelect on Entity {
      ... on Clinic {
        ...ClinicInClinicSelect
      }
      ... on Doctor {
        ...DoctorInDoctorSelect
      }
      ... on Employee {
        ...EmployeeInEmployeeSelect
      }
      ... on Patient {
        ...PatientInPatientSelect
      }
    }
    ${ClinicSelect.fragments?.ClinicInClinicSelect}
    ${DoctorSelect.fragments.DoctorInDoctorSelect}
    ${EmployeeSelect.fragments.EmployeeInEmployeeSelect}
    ${PatientSelect.fragments?.PatientInPatientSelect}
  `,
}

export default EntitySelect
