import { gql, useMutation } from '@apollo/client'
import { Button, Card, Form, Select, message } from 'antd'
import type { SelectProps } from 'antd/lib/select'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  EntityTypeToPrivilegesListSystem,
  UpdateSystemEntityTypeToPrivilegesListMutation,
  UpdateSystemEntityTypeToPrivilegesListMutationVariables,
} from '../../../../graphql/types'
import {
  AllPrivilege,
  SystemConfigType,
} from '../../../../graphql/types'

const updateSystemEntityTypeToPrivilegesListMutation = gql`
  mutation UpdateSystemEntityTypeToPrivilegesList(
    $configType: SystemConfigType!
    $payload: UpdateSystemEntityTypeToPrivilegesInput!
  ) {
    updateSystemEntityTypeToPrivilegesList(
      configType: $configType
      payload: $payload
    ) {
      id
    }
  }
`

interface SystemEntityTypeToPrivilegesListFormCardProps {
  initialValues: EntityTypeToPrivilegesListSystem
}

function SystemEntityTypeToPrivilegesListFormCard(props: SystemEntityTypeToPrivilegesListFormCardProps) {
  const { initialValues } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [updateSystemEntityTypeToPrivilegesList] = useMutation<
    UpdateSystemEntityTypeToPrivilegesListMutation,
    UpdateSystemEntityTypeToPrivilegesListMutationVariables
  >(updateSystemEntityTypeToPrivilegesListMutation)

  const handleUpdateSystemEntityTypeToPrivilegesListClick = async () => {
    try {
      const fieldsValue
        = (await form.validateFields()) as UpdateSystemEntityTypeToPrivilegesListMutationVariables['payload']
      await updateSystemEntityTypeToPrivilegesList({
        variables: {
          configType: SystemConfigType.EntityTypeToPrivileges,
          payload: fieldsValue,
        },
        update: async () => {
          await message.info('已更新系統設定中的非員工實體預設權限')
        },
      })
    }
    catch (e) {
      if (e?.message) {
        /** graphQL errors */
        message.error(e?.message)
      }
      else {
        /** form errors or other errors */
        message.error(e.toString())
      }
    }
  }

  const selectFilterOption: SelectProps<AllPrivilege>['filterOption'] = (
    input,
    option,
  ) => {
    const label = option?.props.children
    return label.includes(input)
  }

  return (
    <Card
      title="非員工實體預設權限"
      size="small"
      extra={(
        <Button
          danger
          onClick={handleUpdateSystemEntityTypeToPrivilegesListClick}
        >
          更新
        </Button>
      )}
    >
      <Form form={form} initialValues={initialValues}>
        {initialValues.entityTypeToPrivilegesList.map(
          (entityTypeToPrivileges, index) => (
            <div key={`system-config-entityTypeToPrivileges-${index}`}>
              <Form.Item
                label="實體類型"
                name={['entityTypeToPrivilegesList', index, 'entityType']}
              >
                <div>
                  {t(`account.entityType.${entityTypeToPrivileges.entityType}`)}
                </div>
              </Form.Item>
              <Form.Item
                label="權限"
                name={['entityTypeToPrivilegesList', index, 'privileges']}
              >
                <Select
                  filterOption={selectFilterOption}
                  optionFilterProp="children"
                  mode="multiple"
                >
                  {Object.values(AllPrivilege).map(privilege => (
                    <Select.Option
                      key={`system-config-${entityTypeToPrivileges.entityType}-selecting-${privilege}`}
                      value={privilege}
                    >
                      {t(`account.privileges.${privilege}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          ),
        )}
      </Form>
    </Card>
  )
}

export default SystemEntityTypeToPrivilegesListFormCard
