import { AuxiliaryPosition, ToothPosition } from '../../codegen/types'
// 給 SVG 使用
// 這裡放了所有牙齒的參考位置
// 還有牙齒邊緣的上邊界跟下邊界

const config = {
  tooth: {
    // 第一象限
    [ToothPosition.Fdi_1_1]: {
      centerＸ: 273,
      attachment: 270,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_2,
      },
    },
    [ToothPosition.Fdi_1_2]: {
      centerＸ: 245,
      attachment: 243,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_3,
      },
    },
    [ToothPosition.Fdi_1_3]: {
      centerＸ: 220,
      attachment: 217,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_2,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_4,
      },
    },
    [ToothPosition.Fdi_1_4]: {
      centerＸ: 192,
      attachment: 187,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_3,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_5,
      },
    },
    [ToothPosition.Fdi_1_5]: {
      centerＸ: 160,
      attachment: 157,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_4,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_6,
      },
    },
    [ToothPosition.Fdi_1_6]: {
      centerＸ: 125,
      attachment: 113,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_5,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_7,
      },
    },
    [ToothPosition.Fdi_1_7]: {
      centerＸ: 78,
      attachment: 73,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_6,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_1_8,
      },
    },
    [ToothPosition.Fdi_1_8]: {
      centerＸ: 37,
      attachment: 30,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_7,
        [AuxiliaryPosition.Far]: null,
      },
    },
    // 第二象限
    [ToothPosition.Fdi_2_1]: {
      centerＸ: 314,
      attachment: 311,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_1_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_2,
      },
    },
    [ToothPosition.Fdi_2_2]: {
      centerＸ: 343,
      attachment: 340,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_3,
      },
    },
    [ToothPosition.Fdi_2_3]: {
      centerＸ: 370,
      attachment: 366,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_2,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_4,
      },
    },
    [ToothPosition.Fdi_2_4]: {
      centerＸ: 398,
      attachment: 395,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_3,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_5,
      },
    },
    [ToothPosition.Fdi_2_5]: {
      centerＸ: 427,
      attachment: 425,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_4,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_6,
      },
    },
    [ToothPosition.Fdi_2_6]: {
      centerＸ: 463,
      attachment: 456,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_5,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_7,
      },
    },
    [ToothPosition.Fdi_2_7]: {
      centerＸ: 512,
      attachment: 498,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_6,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_2_8,
      },
    },
    [ToothPosition.Fdi_2_8]: {
      centerＸ: 545,
      attachment: 540,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_2_7,
        [AuxiliaryPosition.Far]: null,
      },
    },
    // 第三象限
    [ToothPosition.Fdi_3_1]: {
      centerＸ: 310,
      attachment: 307,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_2,
      },
    },
    [ToothPosition.Fdi_3_2]: {
      centerＸ: 333,
      attachment: 331,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_3,
      },
    },
    [ToothPosition.Fdi_3_3]: {
      centerＸ: 361,
      attachment: 359,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_2,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_4,
      },
    },
    [ToothPosition.Fdi_3_4]: {
      centerＸ: 395,
      attachment: 393,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_3,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_5,
      },
    },
    [ToothPosition.Fdi_3_5]: {
      centerＸ: 424,
      attachment: 422,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_4,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_6,
      },
    },
    [ToothPosition.Fdi_3_6]: {
      centerＸ: 462,
      attachment: 452,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_5,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_7,
      },
    },
    [ToothPosition.Fdi_3_7]: {
      centerＸ: 505,
      attachment: 495,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_6,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_3_8,
      },
    },
    [ToothPosition.Fdi_3_8]: {
      centerＸ: 545,
      attachment: 535,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_7,
        [AuxiliaryPosition.Far]: null,
      },
    },
    // 第四象限
    [ToothPosition.Fdi_4_1]: {
      centerＸ: 277,
      attachment: 274,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_3_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_2,
      },
    },
    [ToothPosition.Fdi_4_2]: {
      centerＸ: 253,
      attachment: 250,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_1,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_3,
      },
    },
    [ToothPosition.Fdi_4_3]: {
      centerＸ: 224,
      attachment: 222,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_2,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_4,
      },
    },
    [ToothPosition.Fdi_4_4]: {
      centerＸ: 195,
      attachment: 193,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_3,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_5,
      },
    },
    [ToothPosition.Fdi_4_5]: {
      centerＸ: 167,
      attachment: 164,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_4,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_6,
      },
    },
    [ToothPosition.Fdi_4_6]: {
      centerＸ: 132,
      attachment: 123,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_5,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_7,
      },
    },
    [ToothPosition.Fdi_4_7]: {
      centerＸ: 90,
      attachment: 82,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_6,
        [AuxiliaryPosition.Far]: ToothPosition.Fdi_4_8,
      },
    },
    [ToothPosition.Fdi_4_8]: {
      centerＸ: 50,
      attachment: 40,
      neighbor: {
        [AuxiliaryPosition.Near]: ToothPosition.Fdi_4_7,
        [AuxiliaryPosition.Far]: null,
      },
    },
  },
  top: {
    cy: 37,
  },
  bottom: {
    cy: 80,
  },
  xBoundery: {
    [ToothPosition.Fdi_1_1]: 310,
    [ToothPosition.Fdi_2_1]: 275,
    [ToothPosition.Fdi_3_1]: 278,
    [ToothPosition.Fdi_4_1]: 277,
    [ToothPosition.Fdi_1_8]: -8,
    [ToothPosition.Fdi_2_8]: 590,
    [ToothPosition.Fdi_3_8]: 574,
    [ToothPosition.Fdi_4_8]: 10,
  },
}
export default config
