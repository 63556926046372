import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import type { ApolloQueryResult } from '@apollo/client'
import { gql, useMutation } from '@apollo/client'
import { Button, Col, Modal, Row, message } from 'antd'
import { evolve } from 'ramda'
import React from 'react'

import type {
  AppointmentCalendarQueryQuery,
  AppointmentCalendarQueryQueryVariables,
  AppointmentFragment,
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables,
  RemoveAppointmentMutation,
  RemoveAppointmentMutationVariables,
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables,
} from '../../../graphql/types'
import AppointmentForm from '../form/appointment'

export const createAppointmentMutation = gql`
  mutation CreateAppointment($payload: CreateAppointmentInput!) {
    createAppointment(payload: $payload) {
      ...Appointment
    }
  }
  ${AppointmentForm.fragment.Appointment}
`

export const updateAppointmentMutation = gql`
  mutation UpdateAppointment($id: ID!, $payload: UpdateAppointmentInput!) {
    updateAppointment(id: $id, payload: $payload) {
      ...Appointment
    }
  }
  ${AppointmentForm.fragment.Appointment}
`

export const removeAppointmentMutation = gql`
  mutation RemoveAppointment($id: ID!) {
    removeAppointment(id: $id) {
      ...Appointment
    }
  }
  ${AppointmentForm.fragment.Appointment}
`

type Props = FormComponentProps & {
  item: AppointmentFragment | undefined
  visible: boolean
  reset: () => void
  refetch: (
    variables?: AppointmentCalendarQueryQueryVariables | undefined
  ) => Promise<ApolloQueryResult<AppointmentCalendarQueryQuery>>
}

function AppointmentModal(props: Props) {
  const { form, item, visible, reset, refetch } = props
  const [createAppointment] = useMutation<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >(createAppointmentMutation)
  const [updateAppointment] = useMutation<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >(updateAppointmentMutation)
  const [removeAppointment] = useMutation<
    RemoveAppointmentMutation,
    RemoveAppointmentMutationVariables
  >(removeAppointmentMutation)

  const create = async () => {
    const payload = evolve<any>({
      startDate: moment => moment.toISOString(),
      endDate: moment => moment.toISOString(),
    })(form.getFieldsValue()) as any

    await createAppointment({
      variables: {
        payload,
      },
      update: async (_cache, { data }) => {
        if (data) {
          reset()
          form.resetFields()
          await refetch()
          await message.info('已新增約診')
        }
      },
    })
  }

  const update = async () => {
    if (!item?.id)
      return

    const payload = evolve<any>({
      startDate: moment => moment.toISOString(),
      endDate: moment => moment.toISOString(),
    })(form.getFieldsValue()) as any

    await updateAppointment({
      variables: {
        id: item.id,
        payload,
      },
      update: async (_cache, { data }) => {
        if (data) {
          reset()
          form.resetFields()
          await refetch()
          await message.info('已更新約診')
        }
      },
    })
  }

  const remove = async () => {
    if (item) {
      await removeAppointment({
        variables: {
          id: item.id,
        },
        update: async (_cache, { data }) => {
          if (data) {
            reset()
            await refetch()
            await message.info('已刪除約診')
          }
        },
      })
    }
  }

  const handleSubmit = async () => {
    if (item)
      await update()
    else
      await create()
  }

  const handleCancel = () => {
    reset()
    form.resetFields()
  }

  const handleRemove = async () => {
    await remove()
  }

  return (
    <Modal
      zIndex={1001}
      title={item ? '編輯約診' : '新增約診'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={(
        <Row>
          {item && (
            <Col>
              <Button danger onClick={handleRemove}>
                刪除
              </Button>
            </Col>
          )}
          <Col flex={1}>
            <Button type="primary" onClick={handleSubmit}>
              送出
            </Button>
            <Button onClick={handleCancel}>取消</Button>
          </Col>
        </Row>
      )}
    >
      <AppointmentForm form={form} item={item} />
    </Modal>
  )
}

export default Form.create<any>()(AppointmentModal)
