import { gold } from '@ant-design/colors'
import {
  EnvironmentOutlined,
  FileTextOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import { Layout, PageHeader, Row, Space, Tabs, Typography } from 'antd'
import { isNil } from 'ramda'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import type {
  PatientTreatmentQuery,
  PatientTreatmentQueryVariables,
} from '../../../../graphql/types'
import Page, { Section } from '../../../components/layout/Page'
import StagePatientInfo from '../../stage/StageDetail/components/StagePatientInfo'
import CheckPointOverview from './CheckPointOverview'
import PanelAnalysis from './PanelAnalysis'
import PanelCheckPoint from './PanelCheckPoint'
import PanelStepsOverview from './PanelStepsOverview'
import UnsetGoalImg from './unset-goal.png'

const InfoSection = styled(Section as any)`
  background-color: initial;
  margin-left: 0;
  padding: 0;
  flex: 0;
  align-self: flex-start;
`
const MainSection = styled(Section as any)`
  background-color: transparent;
  padding: 0;
`
const CenteredSection = styled(Section as any)`
  align-items: center;
  justify-content: center;
`
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab-active {
    background-color: white;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    padding: 16px 24px !important;
    font-size: 16px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: ${gold.primary};
  }
  .ant-tabs-ink-bar {
    background-color: ${gold.primary};
    left: 0 !important;
    width: 4px !important;
  }
  .ant-tabs-content {
    min-height: 100%;
  }
`
const StyledPanel = styled(Tabs.TabPane)`
  background-color: white;
  padding: 0 !important;
`

const patientTreatment = gql`
  query PatientTreatment($id: ID!) {
    patient(id: $id) {
      ...CheckPointOverviewPatientItem
      ...PanelCheckPointPatientItem
      id
      name
      currentEvalStage {
        ...CheckPointOverview
        analysis {
          digiAnalysis
        }
        stepsOverview {
          ...fileInfo
        }
        checkPoints {
          ...PanelCheckPoint
        }
      }
    }
  }
  ${CheckPointOverview.fragments.CheckPointOverview}
  ${CheckPointOverview.fragments.CheckPointOverviewPatientItem}
  ${PanelCheckPoint.fragments.PanelCheckPoint}
  ${PanelCheckPoint.fragments.PanelCheckPointPatientItem}
  ${GqlUpload.Upload.fragment}
`

function Empty() {
  return (
    <CenteredSection>
      <img src={UnsetGoalImg} />
      <Typography.Text type="secondary">病患尚未設定主要報告</Typography.Text>
      <Typography.Text type="secondary">
        若要編輯病患 CP 間的整體設計療程，請先設定主要報告
      </Typography.Text>
    </CenteredSection>
  )
}

interface RouteProps {
  patientId: string
}

function PatientTreatment() {
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const patientId = match.params.patientId

  const { data, loading, refetch } = useQuery<
    PatientTreatmentQuery,
    PatientTreatmentQueryVariables
  >(patientTreatment, {
    variables: {
      id: patientId,
    },
  })

  const patient = data?.patient

  if (loading)
    return <Page loading />

  if (isNil(patient))
    return null

  const currentEvalStage = patient.currentEvalStage
  const checkPoints = currentEvalStage?.checkPoints ?? []

  const handleRefetch = async () => {
    await refetch()
  }
  const handleBack = () => {
    history.push(`/patients/${patient.id}/stages`)
  }

  return (
    <>
      <PageHeader
        title=""
        subTitle={`返回 ${patient.name} 工單列表`}
        onBack={handleBack}
      >
        <CheckPointOverview
          currentEvalStage={currentEvalStage}
          patientItem={patient}
          handleRefetch={handleRefetch}
        />
      </PageHeader>
      <Layout>
        <Row>
          {currentEvalStage
            ? (
              <MainSection>
                <StyledTabs tabPosition="left">
                  <StyledPanel
                    key="analysis"
                    tab={(
                      <Space size="small">
                        <FileTextOutlined />
                        <span>數位分析內容</span>
                      </Space>
                    )}
                  >
                    <PanelAnalysis
                      stageId={currentEvalStage.id}
                      analysis={currentEvalStage.analysis?.digiAnalysis}
                    />
                  </StyledPanel>
                  <StyledPanel
                    key="stepsOverview"
                    tab={(
                      <Space size="small">
                        <PaperClipOutlined />
                        <span>齒列移動表</span>
                      </Space>
                    )}
                  >
                    <PanelStepsOverview
                      stageId={currentEvalStage.id}
                      stepsOverview={currentEvalStage.stepsOverview}
                    />
                  </StyledPanel>
                  {checkPoints.map(checkPoint => (
                    <StyledPanel
                      key={checkPoint.serialNumber}
                      tab={(
                        <Space size="small">
                          <EnvironmentOutlined />
                          <span>
                            {checkPoint.isFinal
                              ? 'Final'
                              : `CP${checkPoint.serialNumber}`}
                          </span>
                        </Space>
                      )}
                    >
                      <PanelCheckPoint
                        stageId={currentEvalStage.id}
                        patientId={patientId}
                        checkPoint={checkPoint}
                        patientItem={patient}
                        handleRefetch={handleRefetch}
                      />
                    </StyledPanel>
                  ))}
                </StyledTabs>
              </MainSection>
              )
            : (
              <Empty />
              )}
          <InfoSection>
            <Space direction="vertical" size={16}>
              <StagePatientInfo patientId={patientId} />
            </Space>
          </InfoSection>
        </Row>
      </Layout>
    </>
  )
}

export default PatientTreatment
