import './IntraOral.less'

import { QuestionCircleFilled } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { TeethStatusInput } from '@sov/ui'
import { Form, Radio, Tooltip } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { isNil } from 'ramda'
import React from 'react'

import type {
  CreatePatientInput,
} from '../../../../graphql/types'
import {
  ImpactedToothInstruction,
  Instruction,
  Offset,
  ResidualRootInstruction,
  ToothStatusType,
  TreatStatus,
  UneruptedWisdomToothInstruction,
} from '../../../../graphql/types'
import {
  formItemLayout,
  formItemWithInstructionLayout,
  subFormItemLayout,
} from './utils/layouts'

export type FormIntraOralFields = Pick<CreatePatientInput, 'intraOral'>
export type FormIntraOralInitialValues = Partial<FormIntraOralFields>

interface Props {
  form: FormInstance<FormIntraOralFields>
  initialValues?: FormIntraOralInitialValues
}

function FormPatientIntraOral(props: Props) {
  const { form, initialValues } = props
  const teethStatusTypeConfig = [
    {
      type: ToothStatusType.Missing,
      label: (
        <Tooltip placement="right" title="病患現況缺少的牙齒。">
          Missing teeth
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [ToothStatusType.WillExtract],
      activeIconStyle: {
        border: '1px solid rgba(0,0,0,0.25)',
        color: 'rgba(0, 0, 0, 0.25)',
      },
    },
    {
      type: ToothStatusType.DontMove,
      label: (
        <Tooltip
          placement="right"
          title="因牙橋、牙冠、植牙、牙周等問題，判定為不能移動的牙齒。"
        >
          {`Don't move teeth`}
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [ToothStatusType.Missing, ToothStatusType.WillExtract],
      activeIconStyle: { backgroundColor: '#e5e5e5' },
    },
    {
      type: ToothStatusType.DontPutAttachment,
      label: (
        <Tooltip
          placement="right"
          title="因牙齒現況或為金屬、陶瓷假牙，判定為無法黏貼 Attachment 的牙齒。"
        >
          {`Don't put attachment`}
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [
        ToothStatusType.DontMove,
        ToothStatusType.Missing,
        ToothStatusType.WillExtract,
      ],
    },
    {
      type: ToothStatusType.WillExtract,
      label: (
        <Tooltip placement="right" title="在治療前欲拔除的牙齒。">
          Will extract teeth
          <QuestionCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Tooltip>
      ),
      disabledBy: [ToothStatusType.Missing],
    },
  ]
  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        label="牙位狀態"
        valuePropName="teethStatus"
        name={['intraOral', 'teethStatus']}
      >
        <TeethStatusInput teethStatusTypeConfig={teethStatusTypeConfig} />
      </Form.Item>
      <Form.Item
        label="是否有TMD"
        name={['intraOral', 'temporomandibularJointDisordersTreatStatus']}
      >
        <Radio.Group>
          <Radio value={TreatStatus.No}>no</Radio>
          <Radio value={TreatStatus.Treated}>treated</Radio>
          <Radio value={TreatStatus.Untreat}>untreat</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="是否有牙周問題"
        name={['intraOral', 'periodontalDiseaseTreatStatus']}
      >
        <Radio.Group>
          <Radio value={TreatStatus.No}>no</Radio>
          <Radio value={TreatStatus.Treated}>treated</Radio>
          <Radio value={TreatStatus.Untreat}>untreat</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="是否有蛀牙(殘根)"
        name={['intraOral', 'residualRoot', 'hasResidualRoot']}
      >
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Form.Item
            {...subFormItemLayout}
            label="是否拔除"
            name={['intraOral', 'residualRoot', 'instruction']}
            hidden={
              form.getFieldValue([
                'intraOral',
                'residualRoot',
                'hasResidualRoot',
              ]) !== true
            }
          >
            <Radio.Group>
              <Radio value={ResidualRootInstruction.Extract}>extract</Radio>
              <Radio value={ResidualRootInstruction.Restore}>restore</Radio>
              <Radio value={ResidualRootInstruction.Maintain}>maintain</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="是否有阻生齒"
        name={['intraOral', 'impactedTooth', 'hasImpactedTooth']}
      >
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Form.Item
            {...subFormItemLayout}
            label="是否拔除"
            name={['intraOral', 'impactedTooth', 'instruction']}
            hidden={
              form.getFieldValue([
                'intraOral',
                'impactedTooth',
                'hasImpactedTooth',
              ]) !== true
            }
          >
            <Radio.Group>
              <Radio value={ImpactedToothInstruction.Extract}>extract</Radio>
              <Radio value={ImpactedToothInstruction.DontMove}>
                {`Don't move`}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="是否有未萌發智齒"
        name={['intraOral', 'uneruptedWisdomTooth', 'hasUneruptedWisdomTooth']}
      >
        <Radio.Group>
          <Radio value={false}>No</Radio>
          <Radio value>Yes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Form.Item
            {...subFormItemLayout}
            label="是否拔除"
            name={['intraOral', 'uneruptedWisdomTooth', 'instruction']}
            hidden={
              form.getFieldValue([
                'intraOral',
                'uneruptedWisdomTooth',
                'hasUneruptedWisdomTooth',
              ]) !== true
            }
          >
            <Radio.Group>
              <Radio value={UneruptedWisdomToothInstruction.Extract}>
                extract
              </Radio>
              <Radio value={UneruptedWisdomToothInstruction.DontDistalize}>
                {`Don't distalize`}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="上顎門齒中線是否對齊顏面中線"
        name={['intraOral', 'maxillarMidlineToFacialMidline', 'offset']}
      >
        <Radio.Group>
          <Radio value={Offset.Centered}>centered</Radio>
          <Radio value={Offset.ShiftedRight}>shiftedRight</Radio>
          <Radio value={Offset.ShiftedLeft}>shiftedLeft</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const offset = form.getFieldValue([
            'intraOral',
            'maxillarMidlineToFacialMidline',
            'offset',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label="是否調整上顎中線位置"
              name={[
                'intraOral',
                'maxillarMidlineToFacialMidline',
                'instruction',
              ]}
              hidden={isNil(offset) || offset === Offset.Centered}
            >
              <Radio.Group>
                <Radio value={Instruction.Maintain}>maintain</Radio>
                <Radio value={Instruction.Improve}>improve</Radio>
                <Radio value={Instruction.Idealize}>idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item
        {...formItemWithInstructionLayout}
        label="下顎門齒中線是否對齊上顎中線"
        name={['intraOral', 'mandibularMidlineToMaxillarMidline', 'offset']}
      >
        <Radio.Group>
          <Radio value={Offset.Centered}>centered</Radio>
          <Radio value={Offset.ShiftedRight}>shiftedRight</Radio>
          <Radio value={Offset.ShiftedLeft}>shiftedLeft</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(form) => {
          const offset = form.getFieldValue([
            'intraOral',
            'mandibularMidlineToMaxillarMidline',
            'offset',
          ])
          return (
            <Form.Item
              {...subFormItemLayout}
              label="是否調整下顎中線位置"
              name={[
                'intraOral',
                'mandibularMidlineToMaxillarMidline',
                'instruction',
              ]}
              hidden={isNil(offset) || offset === Offset.Centered}
            >
              <Radio.Group>
                <Radio value={Instruction.Maintain}>maintain</Radio>
                <Radio value={Instruction.Improve}>improve</Radio>
                <Radio value={Instruction.Idealize}>idealize</Radio>
              </Radio.Group>
            </Form.Item>
          )
        }}
      </Form.Item>
    </Form>
  )
}

FormPatientIntraOral.fragments = {
  FormPatientIntraOral: gql`
    fragment FormPatientIntraOral on Patient {
      id
      intraOral {
        teethStatus {
          position
          type
        }
        # TMJ
        temporomandibularJointDisordersTreatStatus
        # 牙周問題
        periodontalDiseaseTreatStatus
        # 蛀牙(殘根)
        residualRoot {
          hasResidualRoot
          instruction
        }
        # 阻生齒
        impactedTooth {
          hasImpactedTooth
          instruction
        }
        # 未萌發智齒
        uneruptedWisdomTooth {
          hasUneruptedWisdomTooth
          instruction
        }
        # 上顎門齒是否對齊顏面中線 & 相關指示
        maxillarMidlineToFacialMidline {
          offset
          instruction
        }
        # 下顎門齒是否對齊上顎中線 & 相關指示
        mandibularMidlineToMaxillarMidline {
          offset
          instruction
        }
      }
    }
  `,
}

export { FormPatientIntraOral }

export default FormPatientIntraOral
