import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import DevelopingIcon from './DevelopingIcon'

const Container = styled.div`
  background-color: #e5e5e5;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`

const Title = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px;
  margin-top: 24px;
`

const Content = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-top: 8px;
`

const Link = styled.div`
  cursor: pointer;
  color: #1890ff;
  font-size: 14px;
  margin-top: 20px;
`

function UnAuthorizedPage() {
  const history = useHistory()

  const handleClick = () => {
    history.goBack()
  }

  return (
    <Container>
      <DevelopingIcon />
      <Title>此路不通</Title>
      <Content>你的權限不足</Content>
      <Link onClick={handleClick}>返回上一頁</Link>
    </Container>
  )
}

export default UnAuthorizedPage
