import { gql, useMutation } from '@apollo/client'
import { Button, Modal, message } from 'antd'
import React from 'react'

import type {
  RemoveTrackMutation,
  RemoveTrackMutationVariables,
  TracksQueryDocs,
} from '../../../graphql/types'

const removeTrackMutation = gql`
  mutation RemoveTrack($id: ID!) {
    removeTrack(id: $id) {
      id
    }
  }
`

interface TrackDeleteModalProps {
  selectedRowKeys: string[]
  setSelectedTaskItems: React.Dispatch<React.SetStateAction<TracksQueryDocs[]>>
  visible?: boolean
  handleClose: () => void
  refetch?: () => void
}

function TrackDeleteModal(props: TrackDeleteModalProps) {
  const {
    selectedRowKeys,
    setSelectedTaskItems,
    visible,
    handleClose,
    refetch,
  } = props

  const [remove] = useMutation<
    RemoveTrackMutation,
    RemoveTrackMutationVariables
  >(removeTrackMutation)
  const removing = async (trackId: string) => {
    await remove({
      variables: {
        id: trackId,
      },
    })
  }
  const handleRemove = async (selectedRowKeys) => {
    Promise.all([
      selectedRowKeys.map(async (id) => {
        await removing(id)
      }),
    ]).then(() => {
      setSelectedTaskItems([])
      message.info('已刪除追蹤')
      refetch && refetch()
    })
  }
  return (
    <Modal
      width={368}
      title="刪除生理追蹤報告"
      visible={visible}
      onCancel={handleClose}
      footer={[
        <Button
          key="cancel"
          onClick={handleClose}
          style={{ color: 'rgba(0, 0, 0, 0.45)' }}
        >
          取消
        </Button>,
        <Button
          key="remove"
          danger
          onClick={() => {
            handleRemove(selectedRowKeys)
            handleClose()
          }}
        >
          確認刪除
        </Button>,
      ]}
    >
      <p style={{ color: '#F5222D' }}>
        確定要刪除
        {' '}
        {selectedRowKeys.length}
        {' '}
        筆生理追蹤報告
      </p>
      <p>一旦刪除後無法找回資料</p>
    </Modal>
  )
}

export default TrackDeleteModal
