import { blue, gold } from '@ant-design/colors'
import { Form } from '@ant-design/compatible'
import type {
  FormComponentProps,
  WrappedFormUtils,
} from '@ant-design/compatible/lib/form/Form'
import { T, always, cond } from 'ramda'
import React, { memo, useEffect, useState } from 'react'

import type { TabTypes } from '../../../pages/order'
import type { IOrder } from '../Order'
import type { OrderForm } from './TableCells'
import { isRowEdited } from './utils'

export interface EditContextType extends FormComponentProps<OrderForm> {
  editing: boolean
  handleToggle: () => void
  handleUnDo: () => void
  handleCopy: (orderId: string) => void
  handleDelete: (orderId: string) => void
}

interface EditableRowProps extends FormComponentProps<OrderForm> {
  order: IOrder
  loading: boolean
  tab: TabTypes
  isOrderNew: (orderId: string) => boolean
  editOrder: (order: WrappedFormUtils<OrderForm>) => void
  undoOrder: (orderId: string) => void
  copyOrder: (orderId: string) => void
  deleteOrder: (orderId: string) => void
}

// @ts-ignore
export const EditContext = React.createContext<EditContextType>({})

const EditableRow = memo((props: EditableRowProps) => {
  const {
    form,
    order,
    loading,
    tab,
    isOrderNew,
    editOrder,
    undoOrder,
    copyOrder,
    deleteOrder,
    ...restProps
  } = props
  if (!order)
    return <tr />

  // 手動塞 id 進 form
  form.getFieldDecorator('id', {
    initialValue: order.id,
  })

  const formValue = form.getFieldsValue() as OrderForm

  const isNew = isOrderNew(order.id)
  const [editing, setEditing] = useState(isNew)

  const handleToggle = () => setEditing(state => !state)

  const isEdited = isNew || isRowEdited(formValue, order)
  const backgroundColor = cond([
    [() => isEdited && isNew, always(gold[0])],
    [() => isEdited && !isNew, always(blue[0])],
    [T, always('transparent')],
  ])()

  useEffect(() => {
    if (isEdited)
      editOrder(form)
    else
      undoOrder(order.id)
  }, [isEdited])

  useEffect(() => {
    if (!loading) {
      setEditing(false)
      form.setFields({
        id: {
          value: order.id,
        },
      })
    }
  }, [loading])

  useEffect(() => {
    setEditing(false)
  }, [tab])

  const rowProps = {
    form,
    editing: isNew || editing,
    handleToggle,
    handleUnDo: () => {
      handleToggle()
      form.resetFields()
      undoOrder(order.id)
    },
    handleCopy: () => copyOrder(order.id),
    handleDelete: () => deleteOrder(order.id),
  }

  return (
    /**
     * TableRow 與 TableCell 非 parent-child，中間有 ant.design 自己插入的結構
     * 所以只能使用 context 傳遞 data
     */
    <EditContext.Provider value={rowProps}>
      <tr {...restProps} style={{ backgroundColor }} />
    </EditContext.Provider>
  )
})

export default Form.create<FormComponentProps<OrderForm>>()(EditableRow)
