import { gql } from '@apollo/client'

import { ClinicInfoFragment } from '../fragment'

export const removeClinicMutation = gql`
  mutation RemoveClinic($id: ID!) {
    removeClinic(id: $id) {
      ...ClinicInfo
    }
  }
  ${ClinicInfoFragment}
`
