import React from 'react'
import { Link } from 'react-router-dom'

import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Title from '../../layout/Title'

interface Props {
  item: string
}

function LeaveMenu(props: Props) {
  const { item } = props

  return (
    <div style={{ marginBottom: -16 }}>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'LeaveList' },
          {
            key: 'LeaveDetail',
            render: () => <Link to={`/leaves/${item}`}>假勤詳情</Link>,
          },
        ]}
      />
      <Title route={{ key: 'LeaveDetail' }} />
    </div>
  )
}

export default LeaveMenu
