import { append, equals, init, last, tail } from 'ramda'
import type { Reducer } from 'react'
import { useCallback, useReducer } from 'react'

enum RecoveryActionType {
  Undo = 'UNDO',
  Redo = 'REDO',
  Set = 'SET',
  Reset = 'RESET',
}

interface RecoveryState<T> {
  past: T[]
  present: T | undefined
  future: T[]
}

interface RecoveryAction<T> {
  type: RecoveryActionType
  payload?: T
}

// 參考： https://github.com/homerchen19/use-undo/blob/master/index.js
function getRecoveryReducerWithMaxSize(maxSize: number) {
  return <T>(state: RecoveryState<T>, action: RecoveryAction<T>) => {
    const { past, present, future } = state

    switch (action.type) {
      case RecoveryActionType.Undo: {
        if (!present)
          return state

        const previous = last(past)
        const newPast = init(past)

        return {
          past: newPast,
          present: previous,
          future:
            future.length < maxSize
              ? append(present, future)
              : append(present, tail(future)),
        }
      }

      case RecoveryActionType.Redo: {
        if (!present)
          return state

        const next = last(future)
        const newFuture = init(future)

        return {
          past:
            past.length < maxSize
              ? append(present, past)
              : append(present, tail(past)),
          present: next,
          future: newFuture,
        }
      }

      case RecoveryActionType.Set: {
        const { payload } = action

        if (payload) {
          if (equals(payload, present))
            return state

          return {
            past: present ? append(present, past) : past,
            present: payload,
            future: [],
          }
        }
        else {
          return state
        }
      }

      case RecoveryActionType.Reset: {
        const { payload } = action

        if (payload) {
          return {
            past: [],
            present: payload,
            future: [],
          }
        }
        else {
          return state
        }
      }
      default: {
        return state
      }
    }
  }
}

interface UseRecoveryParams<T> {
  initialState?: T
  maxSize?: number
}
export function useRecovery<T>(params: UseRecoveryParams<T>) {
  const { initialState, maxSize = 20 } = params
  const reducer = getRecoveryReducerWithMaxSize(maxSize)
  const [state, dispatch] = useReducer<
    Reducer<RecoveryState<T>, RecoveryAction<T>>
  >(reducer, {
    past: [],
    future: [],
    present: initialState,
  })

  const canUndo = state.past.length > 0
  const canRedo = state.future.length > 0

  const undo = useCallback(() => {
    if (canUndo)
      dispatch({ type: RecoveryActionType.Undo })
  }, [canUndo])

  const redo = useCallback(() => {
    if (canRedo)
      dispatch({ type: RecoveryActionType.Redo })
  }, [canRedo])

  const set = useCallback((newState: T) => {
    dispatch({ type: RecoveryActionType.Set, payload: newState })
  }, [])

  const reset = useCallback((initialState: T) => {
    dispatch({ type: RecoveryActionType.Reset, payload: initialState })
  }, [])

  return {
    state,
    undo,
    redo,
    set,
    reset,
    canUndo,
    canRedo,
  }
}
