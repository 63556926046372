import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"

        d="M6.69918 2.14624C6.33997 2.14624 6.04878 2.43744 6.04878 2.79665C6.04878 3.15586 6.33998 3.44705 6.69918 3.44705H9.30081C9.66002 3.44705 9.95122 3.15586 9.95122 2.79665C9.95122 2.43744 9.66002 2.14624 9.30081 2.14624H6.69918ZM4.77832 2.47144C4.9475 2.47144 5.07317 2.62747 5.07317 2.79665C5.07317 2.96583 4.9475 3.12185 4.77832 3.12185H4.74796C4.20915 3.12185 3.77235 3.55865 3.77235 4.09746V12.5527C3.77235 13.0916 4.20915 13.5284 4.74796 13.5284H11.252C11.7908 13.5284 12.2276 13.0916 12.2276 12.5527V4.09746C12.2276 3.55865 11.7908 3.12185 11.252 3.12185H11.2217C11.0525 3.12185 10.9268 2.96583 10.9268 2.79665C10.9268 2.62747 11.0525 2.47144 11.2217 2.47144H11.252C12.1501 2.47144 12.878 3.19944 12.878 4.09746V12.5527C12.878 13.4508 12.1501 14.1788 11.252 14.1788H4.74796C3.84994 14.1788 3.12195 13.4508 3.12195 12.5527V4.09746C3.12195 3.19944 3.84994 2.47144 4.74796 2.47144H4.77832ZM5.39837 6.37388C5.39837 6.19428 5.54397 6.04868 5.72357 6.04868H10.2764C10.456 6.04868 10.6016 6.19428 10.6016 6.37388C10.6016 6.55349 10.456 6.69909 10.2764 6.69909H5.72357C5.54397 6.69909 5.39837 6.55349 5.39837 6.37388ZM5.72357 7.9999C5.54397 7.9999 5.39837 8.1455 5.39837 8.3251C5.39837 8.50471 5.54397 8.65031 5.72358 8.65031H10.2764C10.456 8.65031 10.6016 8.50471 10.6016 8.3251C10.6016 8.1455 10.456 7.9999 10.2764 7.9999H5.72357ZM5.39837 10.2763C5.39837 10.0967 5.54397 9.95112 5.72357 9.95112H10.2764C10.456 9.95112 10.6016 10.0967 10.6016 10.2763C10.6016 10.4559 10.456 10.6015 10.2764 10.6015H5.72358C5.54397 10.6015 5.39837 10.4559 5.39837 10.2763Z"
        fill="black"
        fillOpacity="0.65"
      />
    </svg>
  )
}

const BoardIcon = props => <Icon {...props} component={Svg} />

export default BoardIcon
