import { gql, useMutation } from '@apollo/client'
import {
  TableQuery,
  getBadgeImageByLevel,
  getDecoratedBadgeImageByLevel,
} from '@sov/common'
import { Images, MultiLine } from '@sov/ui'
import ClinicLink from '@sov/ui/src/components/Link/Clinic'
import { Button, Select, Table, message } from 'antd'
import type { SelectProps } from 'antd/lib/select'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import type {
  DoctorTableFragment,
  UpdateDoctorLevelMutation,
  UpdateDoctorLevelMutationVariables,
} from '../../../graphql/types'
import {
  DoctorLevel,
} from '../../../graphql/types'
import type { DoctorFilterType } from '../../pages/doctor/DoctorList'

const doctorTableFragment = gql`
  fragment DoctorTable on Doctor {
    id
    clinics {
      ...ClinicLink
    }
    name
    email
    phone
    note
    level
  }
  ${ClinicLink.fragment}
`

const updateDoctorLevel = gql`
  mutation UpdateDoctorLevel($id: ID!, $payload: UpdateDoctorInput!) {
    updateDoctor(id: $id, payload: $payload) {
      ...DoctorTable
    }
  }
  ${doctorTableFragment}
`

interface Props {
  filterInfo?: DoctorFilterType
  source?: {
    docs: DoctorTableFragment[]
    page?: number
    total: number
    limit: number
  }
  loading?: boolean
  handleTableChange: TableProps<DoctorTableFragment>['onChange']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

export function DoctorTable(props: Props) {
  const {
    source = defaultSource,
    loading,
    filterInfo = {},
    handleTableChange,
  } = props

  const [updateLevel] = useMutation<
    UpdateDoctorLevelMutation,
    UpdateDoctorLevelMutationVariables
  >(updateDoctorLevel)

  const [isEditing, setIsEditing] = useState(false)

  const columns: ColumnProps<DoctorTableFragment>[] = [
    {
      title: '醫師姓名',
      width: 80,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '醫生分級',
      width: 105,
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      filteredValue: filterInfo.level ?? [],
      filters: Object.values(DoctorLevel).map(level => ({
        text: level,
        value: level,
      })),
      render: (_value, record) => {
        const handleClick = () => {
          setIsEditing(true)
        }
        const handleDropdownVisibleChange: SelectProps<DoctorLevel>['onDropdownVisibleChange']
          = (open) => {
            if (!open)
              setIsEditing(false)
          }
        const handleSelect = async (value) => {
          updateLevel({
            variables: {
              id: record.id,
              payload: {
                level: value as DoctorLevel,
              },
            },
            update: (cache, { errors }) => {
              if (!errors?.length) {
                message.success('已更新醫師等級')
                setIsEditing(false)
              }
            },
          })
        }

        return (
          <div style={{ cursor: 'pointer' }} onClick={handleClick}>
            {isEditing
              ? (
                <Select
                  defaultValue={record.level}
                  dropdownMatchSelectWidth={false}
                  onSelect={handleSelect}
                  onDropdownVisibleChange={handleDropdownVisibleChange}
                >
                  {Object.values(DoctorLevel).map(level => (
                    <Select.Option key={level} value={level}>
                      <img src={getBadgeImageByLevel(Images, level)} />
                    </Select.Option>
                  ))}
                </Select>
                )
              : (
                <img src={getDecoratedBadgeImageByLevel(Images, record.level)} />
                )}
          </div>
        )
      },
    },
    {
      title: '所屬診所',
      width: 120,
      dataIndex: 'clinics',
      key: 'clinics',
      render: (text, record) =>
        record.clinics.map(clinic => (
          <div key={clinic.id}>
            <ClinicLink key={clinic.id} item={clinic} />
          </div>
        )),
    },
    {
      title: '醫師 email',
      width: 240,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '醫師電話',
      width: 160,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '醫師特性/偏好',
      width: 400,
      dataIndex: 'note',
      key: 'note',
      render: text => <MultiLine text={text} />,
    },
    {
      title: '動作',
      width: 120,
      key: 'actions',
      render: (text, record) => (
        <Button>
          <Link to={`/doctors/${record.id}`}>編輯</Link>
        </Button>
      ),
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<DoctorTableFragment>
      rowKey="id"
      size="small"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何醫師' }}
      onChange={handleTableChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
    />
  )
}

DoctorTable.fragments = {
  DoctorTable: doctorTableFragment,
}

export default DoctorTable
