import ClinicDoctorSelect from './ClinicDoctorSelect'
import ClinicSelect from './ClinicSelect'
import DoctorSelect from './DoctorSelect'
import EmployeeSelect from './EmployeeSelect'
import EntitySelect from './EntitySelect'
import PatientSelect from './PatientSelect'
import PatientStageSelect from './PatientStageSelect'
import StageSelect from './StageSelect'

export {
  ClinicSelect,
  ClinicDoctorSelect,
  DoctorSelect,
  EmployeeSelect,
  EntitySelect,
  PatientSelect,
  PatientStageSelect,
  StageSelect,
}
