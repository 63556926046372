import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { Descriptions } from 'antd'
import { isNil, prop, uniqBy } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { DiliveryInfoFragment } from '../../../../../graphql/types'

interface DiliveryInfoProps {
  orderItem: DiliveryInfoFragment
}

function DiliveryInfo(props: DiliveryInfoProps) {
  const { orderItem } = props

  const { t } = useTranslation()

  const stages = orderItem.stageProvide.flatMap(provide => provide.stages)
  /** 每種工單只顯示一張出貨單 */
  const stagesUniqByType = uniqBy(prop('type'), stages)
  const invoices = stagesUniqByType
    .flatMap(stage => ('invoice' in stage ? stage.invoice : null))
    .filter(invoice => !isNil(invoice))

  return (
    <Descriptions title="配送資訊" column={2}>
      {invoices.map(invoice => (
        <>
          <Descriptions.Item label="宅配種類">
            {t(`invoice.homeDeliveryType.${invoice?.homeDeliveryType}`)}
          </Descriptions.Item>
          <Descriptions.Item label="宅配單號">
            {invoice?.homeDeliveryId || getEmptyText()}
          </Descriptions.Item>
        </>
      ))}
    </Descriptions>
  )
}

DiliveryInfo.fragments = {
  DiliveryInfo: gql`
    fragment DiliveryInfo on Order {
      stageProvide {
        stages {
          id
          type
          ... on PrintStage {
            invoice {
              homeDeliveryType
              homeDeliveryId
            }
          }
          ... on EvalStage {
            invoice {
              homeDeliveryType
              homeDeliveryId
            }
          }
          ... on AccessoryStage {
            invoice {
              homeDeliveryType
              homeDeliveryId
            }
          }
        }
      }
    }
  `,
}

export default DiliveryInfo
