import { gold } from '@ant-design/colors'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { CheckPoint, StageName } from '@sov/ui'
import { Button, Popconfirm, Row, Space, Tag, Typography, message } from 'antd'
import { isNil } from 'ramda'
import React, { useState } from 'react'

import type {
  CheckPointOverviewFragment,
  CheckPointOverviewPatientItemFragment,
  CreateCheckPointMutation,
  CreateCheckPointMutationVariables,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import { createCheckPoint } from '../PatientReportEdit'

interface CheckPointOverviewProps {
  currentEvalStage?: CheckPointOverviewFragment
  patientItem: CheckPointOverviewPatientItemFragment
  handleRefetch: () => Promise<void>
}

function CheckPointOverview(props: CheckPointOverviewProps) {
  const { currentEvalStage, patientItem, handleRefetch } = props
  const checkPoints = currentEvalStage?.checkPoints ?? []

  const [addCPLoading, setAddCPLoading] = useState(false)
  const [createCP] = useMutation<
    CreateCheckPointMutation,
    CreateCheckPointMutationVariables
  >(createCheckPoint)

  if (isNil(currentEvalStage)) {
    return (
      <>
        <Row align="middle" style={{ marginBottom: '8px' }}>
          <Typography.Title level={3} style={{ margin: '0 16px 0 0' }}>
            病患療程
          </Typography.Title>
          <Space>
            <div>主要報告:</div>
            <div>未設定</div>
          </Space>
        </Row>
      </>
    )
  }

  const patientStageList = patientItem.stages?.docs[0]
  const currentStep
    = patientStageList?.__typename === 'PrintStage'
      ? patientStageList.serialNumber
      : undefined

  const handleAddCP = async () => {
    setAddCPLoading(true)
    await createCP({
      variables: {
        id: currentEvalStage.id,
      },
      update: async (_cache, { data }) => {
        if (data?.createCheckPoint) {
          await handleRefetch()
          message.info('已新增 check point')
        }
        setAddCPLoading(false)
      },
    })
  }

  return (
    <>
      <Row align="middle" style={{ marginBottom: '8px' }}>
        <Typography.Title level={3} style={{ margin: '0 16px 0 0' }}>
          病患療程
        </Typography.Title>
        <Space>
          <div>主要報告:</div>
          <StageName item={currentEvalStage} />
          {currentEvalStage.isCPMode
            ? (
              <Tag color={gold.primary}>新版 CP 報告</Tag>
              )
            : (
              <Tag color="#cccccc">舊版預設計報告</Tag>
              )}
        </Space>
      </Row>
      <Row justify="space-between">
        <Space size="large">
          <Typography.Title level={4} style={{ margin: 0 }}>
            Check Point
          </Typography.Title>
          <Typography.Text type="secondary">
            目前共
            {' '}
            {checkPoints.length}
            {' '}
            個 CP
          </Typography.Text>
        </Space>
        <Popconfirm
          title="確定要新增一個 Check Point？"
          onConfirm={handleAddCP}
        >
          <Button
            icon={addCPLoading ? <LoadingOutlined /> : <PlusOutlined />}
            type="ghost"
          >
            {addCPLoading ? '新增中...' : '新增'}
          </Button>
        </Popconfirm>
      </Row>
      <Row style={{ margin: '24px 0' }}>
        <CheckPoint.CheckPointProgress
          checkPoints={checkPoints}
          currentStep={currentStep}
          showAnchor
          showProgress
        />
      </Row>
    </>
  )
}

CheckPointOverview.fragments = {
  CheckPointOverview: gql`
    fragment CheckPointOverview on EvalStage {
      id
      isCPMode
      ...StageName
      checkPoints {
        ...CheckPointProgress
      }
    }
    ${CheckPoint.CheckPointProgress.fragments.CheckPointProgress}
    ${StageName.fragment}
  `,
  CheckPointOverviewPatientItem: gql`
    fragment CheckPointOverviewPatientItem on Patient {
      stages(query: { type: [${StageType.Print}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, limit: 1, sort: "-serialNumber") {
        docs {
          id
          __typename
          ... on PrintStage {
            serialNumber
          }
        }
      }
    }
  `,
}

export default CheckPointOverview
