import { Space } from 'antd'
import React from 'react'

import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import SystemExport from './SystemExport'
import SystemStageCapacity from './SystemStageCapacity'
import SystemStageCost from './SystemStageCost'

function SystemSetting() {
  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'SystemSetting' }]}
          />
          <Title route={{ key: 'SystemSetting' }} />
        </>
      )}
    >
      <Space>
        <SystemStageCapacity />
        <SystemStageCost />
        <SystemExport />
      </Space>
    </Page>
  )
}

export default SystemSetting
