import { gql } from '@apollo/client'
import StageLink from '@sov/ui/src/components/Link/Stage'

export const OrderFragment = gql`
  fragment Order on Order {
    id
    dueDate
    isLatest
    type
    status
    description
    patient {
      id
      name
      clinic {
        id
        name
      }
      sales {
        id
        name
      }
    }
    stageOrder {
      stageType
      number
      items
    }
    stageProvide {
      stageType
      stages {
        ...StageLink
      }
    }
  }
  ${StageLink.fragment}
`
