import type { FormProps } from 'antd/lib/form'
import Form from 'antd/lib/form'
import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'

const StyledForm = styled(Form)<{ active: boolean }>`
  border-radius: 8px;
  ${props => !props.active && `cursor: pointer;`}
  &:hover {
    background-color: #fafafa;
  }
`

interface ToggleFormProps extends FormProps {
  className?: string
  active: boolean
}

const ToggleForm: FC<ToggleFormProps> = (props) => {
  const { className, children, active, ...restProps } = props
  return (
    <StyledForm className={className} active={active} {...restProps}>
      {children}
    </StyledForm>
  )
}

export default ToggleForm
