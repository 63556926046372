import type { Document, Model, Types } from 'mongoose'
import { last, splitAt } from 'ramda'

/*

在 db collection 數量太多，或是單次作業會佔據 cpu 情況下
這裡可以設定批次作業的單次上限，具體作法是利用 _id 來作為掃描依據
可參考 sov-api/migration 中的一些 code

*/

interface AsyncBatchConfig {
  verbose?: boolean
  lean?: boolean
}

interface AsyncBatchParams<T extends Document> {
  Model: Model<T>
  query?: any
  projection?: any
  populate?: any
  limit?: number
  callback: (pagedDocuments: T[]) => Promise<void>
  config?: AsyncBatchConfig
  lastStop?: Types.ObjectId
}

async function asyncBatch<T extends Document>(params: AsyncBatchParams<T>) {
  const {
    Model,
    query = {},
    projection,
    populate,
    limit = 1000,
    callback,
    config = {},
    lastStop,
  } = params
  const { lean = true, verbose = true } = config

  const total = await Model.countDocuments(query)

  let migratedCount = 0
  let currentPage = 0
  let endCursor: Types.ObjectId | null = lastStop || null
  let hasNextPage = true

  if (verbose) {
    console.log('----- Start batch migration -----')
    console.log(`[INFO] total length: ${total}`)
    console.log(`[INFO] batch limit: ${limit}`)
    console.log(`[INFO] pages: ${Math.ceil(total / limit)}`)
  }

  while (hasNextPage) {
    currentPage += 1

    if (verbose) {
      console.log(`Running... search documents after [${endCursor}]`)
      console.time(`Page ${currentPage} spend time`)
    }
    const documents: T[] = await Model.find(
      {
        ...query,
        ...(endCursor ? { _id: { $gt: endCursor } } : {}),
      },
      projection,
    )
      .populate(populate)
      .sort({ _id: 1 })
      .limit(limit + 1)
      .lean(lean ? { virtuals: true } : false)

    if (documents.length > 0) {
      /**
       * documents: ${limit} + 1 筆資料。+1 是為了判斷有無下一頁較有效率的做法
       * pagedDocuments: ${limit} 筆資料
       */
      const pagedDocuments = splitAt(limit, documents)[0]
      await callback(pagedDocuments)
      migratedCount += pagedDocuments.length
      /** 設 pagedDocuments 最後一筆的 _id 為 endCursor */
      endCursor = last(pagedDocuments)?._id
    }
    /** 終止條件: find 出來的資料量少於 limit，無下一頁 */
    hasNextPage = documents.length > limit

    if (verbose)
      console.timeEnd(`Page ${currentPage} spend time`)
  }

  if (total !== migratedCount) {
    console.warn(
      `實際遷移資料(${migratedCount})與預計(${total})不符！請檢查相關參數(query, callback)`,
    )
  }

  if (verbose)
    console.log('----- Finish batch migration -----')
}

export { asyncBatch }
