import { gql, useMutation } from '@apollo/client'
import { message } from 'antd'

import type {
  RemoveInstructionCardMutation,
  RemoveInstructionCardMutationVariables,
} from '../../../graphql/types'

const removeInstructionCardMutation = gql`
  mutation RemoveInstructionCard($id: ID!) {
    removeInstructionCard(id: $id) {
      id
    }
  }
`

interface Params {
  onRemoved?: () => void
  showMessage?: boolean
}
function useInstructionRemove(params: Params) {
  const { onRemoved, showMessage = true } = params
  const [removeInstructionCard] = useMutation<
    RemoveInstructionCardMutation,
    RemoveInstructionCardMutationVariables
  >(removeInstructionCardMutation)

  const removeStageInstructionCard = async (stageId: string) => {
    await removeInstructionCard({
      variables: {
        id: stageId,
      },
      update: async (cache, { data }) => {
        if (data) {
          if (showMessage)
            message.info('已移除指示卡')

          if (onRemoved)
            onRemoved()
        }
      },
    })
  }

  return {
    removeStageInstructionCard,
  }
}

export { useInstructionRemove }
