import { gql, useQuery } from '@apollo/client'
import {
  DateMomentParam,
  FormQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import {
  Button,
  Col,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Space,
  Table,
  Typography,
  message,
} from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import type { FC } from 'react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { withDefault } from 'use-query-params'

import type {
  CompletedRpQueryDocs,
  CompletedRpQueryQuery,
  CompletedRpQueryQueryVariables,
} from '../../../graphql/types'
import {
  TaskStatus,
  TaskType,
} from '../../../graphql/types'
import GoodsCode from '../../components/common/GoodsCode'
import PrinterModal from '../../components/common/PrinterModal'

const completedRPQuery = gql`
  query CompletedRPQuery(
    $query: TasksQuery!
    $page: Int
    $limit: Int
    $sort: String
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        patient {
          id
          patientCode
        }
        stage {
          id
          __typename
          expectedShippingDate
          ... on PrintStage {
            serialNumber
            designStage {
              rpModelNumber
              upperModelCount
              lowerModelCount
            }
          }
        }
        owner {
          id
          name
        }
        startedByEmployee
        completedByOwner
      }
      total
      limit
      page
    }
  }
`

const formInput = {
  completedDate: withDefault(DateMomentParam, moment().subtract(1, 'd')),
}

const columns: ColumnProps<CompletedRpQueryDocs>[] = [
  {
    title: '#',
    width: 30,
    align: 'center',
    render: (text, record, index: number) => <div>{index + 1}</div>,
  },
  {
    title: '貨號',
    width: 240,
    render: (text, record) => {
      if (
        record.stage.__typename === 'PrintStage'
        && record.stage.expectedShippingDate
      ) {
        return (
          <GoodsCode
            patientCode={record.patient.patientCode}
            serialNumber={record.stage.serialNumber}
            expectedShippingDate={record.stage.expectedShippingDate}
          />
        )
      }
      return '無預計出貨'
    },
  },
  {
    title: '牙套數量',
    width: 128,
    align: 'center',
    render: (text, record) => {
      if (
        record.stage.__typename === 'PrintStage'
        && record.stage.designStage
      ) {
        return (
          <div>
            {(record.stage.designStage.lowerModelCount ?? 0)
            + (record.stage.designStage.upperModelCount ?? 0)}
          </div>
        )
      }
    },
  },
  {
    title: '檔案接收日期',
    width: 128,
    align: 'center',
    dataIndex: 'startedByEmployee',
    render: text => text && <div>{moment(text).format('YYYY-MM-DD')}</div>,
  },
  {
    title: '預計模具排程日期',
    width: 128,
    align: 'center',
    dataIndex: 'completedByOwner',
    render: text => text && <div>{moment(text).format('YYYY-MM-DD')}</div>,
  },
]

const Footer: FC<{ data?: CompletedRpQueryQuery }> = (props) => {
  const { data } = props
  const modelTotal = data?.tasks?.docs.reduce((total, task) => {
    if (task.stage.__typename === 'PrintStage' && task.stage.designStage) {
      const upperModelCount = task.stage.designStage.upperModelCount ?? 0
      const lowerModelCount = task.stage.designStage.lowerModelCount ?? 0
      return total + upperModelCount + lowerModelCount
    }
    return total
  }, 0)

  return (
    <Space>
      <span>牙套數量總計：</span>
      <span>{modelTotal}</span>
    </Space>
  )
}

const customColumnWidth = [
  { width: '5mm' },
  { width: 'auto' },
  { width: '15mm' },
  { width: '42mm' },
  { width: '42mm' },
]

const Content = styled(Col)`
  background-color: #fff;
  margin: 24px auto;
  padding: 24px;
`
const TitleRow = styled(Row)`
  margin-bottom: 16px;
`
const ReportName = styled.div`
  margin: 16px 0;
  font-weight: 500;
  font-size: 24px;
`
const DateRow = styled(Row)`
  margin: 14px 24px 16px 24px;
`
const CompanyName = styled(Row)`
  padding-top: 24px;
`
const InfoRow = styled(Row)`
  margin-top: 24px;
  margin-right: 24px;
`
const TotalCount = styled.span`
  font-size: 16px;
  padding: 0 4px;
`

const limit = 500

function CompletedRP() {
  const [visible, setVisible] = useState(false)
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const [form] = Form.useForm()

  const { data, loading } = useQuery<
    CompletedRpQueryQuery,
    CompletedRpQueryQueryVariables
  >(completedRPQuery, {
    variables: {
      query: {
        type: [TaskType.CompleteRp],
        status: [TaskStatus.Completed],
        completedByOwnerInterval: getDateIntervalQueryString([
          moment(formQuery.completedDate).startOf('d'),
          moment(formQuery.completedDate).endOf('d'),
        ]),
      },
      limit,
      sort: 'startedByEmployee',
    },
  })

  /** limit 不夠時的防呆 */
  if (!loading && data?.tasks && data?.tasks.total > limit)
    message.warn('資料筆數超過限制，請聯絡 IT')

  /** 僅篩選出製造單的任務 */
  const tasks
    = data?.tasks?.docs.filter(
      task => task.stage.__typename === 'PrintStage',
    ) ?? []
  const customColumn = columns.map((column, index) => ({
    ...column,
    ...customColumnWidth[index],
  }))

  const handleClick = () => setVisible(true)
  const handleCancel = () => setVisible(false)
  const handleChangeCompletedDate = () => {
    handleFormChange(form.getFieldsValue())
  }

  return (
    <>
      <Row>
        <Content offset={2} span={20}>
          <TitleRow align="middle" justify="space-between">
            <PageHeader
              title="模具排程紀錄表"
              subTitle="顯示該日 [製造工單] 中 [進行 RP] 的 [已完成]任務"
              style={{ padding: 0 }}
            />
            <Space size="large">
              <Form.Item label="列印表號" style={{ margin: 0 }}>
                RP-021-002
              </Form.Item>
              <Button type="primary" onClick={handleClick}>
                預覽列印
              </Button>
            </Space>
          </TitleRow>

          <Form form={form}>
            <Form.Item
              name="completedDate"
              label="預計模具排程日期:"
              initialValue={formQuery.completedDate}
            >
              <DatePicker onChange={handleChangeCompletedDate} />
            </Form.Item>
          </Form>

          <Table
            rowKey="id"
            size="small"
            loading={loading}
            columns={columns}
            dataSource={tasks}
            pagination={false}
            footer={() => <Footer data={data} />}
          />
        </Content>

        <PrinterModal visible={visible} onCancel={handleCancel}>
          <CompanyName justify="center">
            <Typography.Text strong>舒服美生技股份有限公司</Typography.Text>
          </CompanyName>
          <Row justify="center">
            <ReportName>模具排程紀錄表</ReportName>
          </Row>
          <DateRow justify="space-between">
            <span>
              預計模具排程日期:
              {' '}
              {moment(formQuery.completedDate).format('YYYY-MM-DD')}
            </span>
            <div>
              排程者:
              {tasks?.[0]?.owner?.name}
            </div>
          </DateRow>
          <Table
            rowKey="id"
            size="small"
            loading={loading}
            columns={customColumn}
            dataSource={tasks}
            pagination={false}
            footer={() => <Footer data={data} />}
          />
          <InfoRow justify="end">
            <Space size="large">
              <div>RP-021-002</div>
              <div>生效時間: 2021-01-04</div>
              <div>版次: 1</div>
            </Space>
          </InfoRow>
          <InfoRow justify="end" align="bottom">
            總共
            <TotalCount>{tasks.length}</TotalCount>
            項結果
          </InfoRow>
        </PrinterModal>
      </Row>
    </>
  )
}

export default CompletedRP
