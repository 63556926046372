import { Avatar } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { NotificationCategory } from '../../../codegen/types'
import { getAvatarIconByCategory } from '../utils'

interface NotificationCategoryItemProps {
  notificationCategory: NotificationCategory
}

const ItemTitle = styled.div`
  margin-left: 8px;
  font-family: 'Noto Sans CJK TC';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45);
`

const Wrapper = styled.div`
  margin-bottom: 8px;
  margin-top: 24px;
  display: flex;
  align-items: center;
`

function NotificationCategoryItem(props: NotificationCategoryItemProps) {
  const { notificationCategory } = props
  const { t } = useTranslation()
  const avatarIcon = getAvatarIconByCategory(notificationCategory)

  return (
    <Wrapper>
      <Avatar src={avatarIcon} size={40} />
      <ItemTitle>
        {t(`notification.category.${notificationCategory}`)}
      </ItemTitle>
    </Wrapper>
  )
}

export default NotificationCategoryItem
