import BellIcon from './BellIcon'
import NotificationCategoryItem from './NotificationCategoryItem'
import NotificationItem from './NotificationItem'
import NotificationList from './NotificationList'
import NotificationPopover from './NotificationPopover'
import NotificationSettingForm from './NotificationSettingForm'

const Notification = {
  Icon: BellIcon,
  Popover: NotificationPopover,
  List: NotificationList,
  fragment: NotificationItem.fragment,
  category: NotificationCategoryItem,
  SettingForm: NotificationSettingForm,
}

export default Notification
