import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { ErrorHandling } from '@sov/common'
import { useRequest } from '@sov/ui'
import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

interface ResetPasswordEmailFormInput {
  email: string
}

type ResetPasswordFormProps = {
  handleSubmit: (x: FormComponentProps['form']) => void
  loading: boolean
} & FormComponentProps<ResetPasswordEmailFormInput>

const FormTitle = styled.div`
  font-size: 28px;
`

const FormDescription = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const FormItem = Form.Item

const ResetPasswordEmailForm = Form.create<ResetPasswordFormProps>()(
  ({ form, handleSubmit, loading }: ResetPasswordFormProps) => {
    const { getFieldDecorator } = form
    const { t } = useTranslation()
    return (
      <Form>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              {
                pattern: new RegExp('^[a-z0-9]', 'i'),
                message: t(`validate.password.required`),
              },
              {
                max: 20,
                message: t(`validate.password.max`),
              },
              {
                min: 4,
                message: t(`validate.password.min`),
              },
              {
                required: true,
                message: t(`validate.required`),
              },
            ],
          })(<Input.Password placeholder="密碼" type="password" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('confirmPassword', {
            rules: [
              {
                validator: (rule, value, cb) => {
                  if (value && value !== form.getFieldValue('password'))
                    cb('輸入密碼不同')
                  else
                    cb()
                },
              },
              {
                required: true,
                message: t(`validate.required`),
              },
            ],
          })(<Input.Password placeholder="確認密碼" type="password" />)}
        </FormItem>
        <FormItem style={{ marginTop: '24px' }}>
          <Button
            type="primary"
            style={{ width: '100%' }}
            loading={loading}
            onClick={() => handleSubmit(form)}
          >
            重新設定密碼
          </Button>
        </FormItem>
      </Form>
    )
  },
)

interface RouteProps {
  token: string
  accountId: string
}

function ResetPassword() {
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const accountId = match.params.accountId
  const token = match.params.token
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(false)
  const request = useRequest()

  useEffect(() => {
    // 進到頁面先檢查 token
    const checkToken = async () => {
      const res = await request.post(`/auth/confirmResetPasswordEmailToken`, {
        accountId,
        token,
      })

      if (res.status === 200)
        setInit(true)
      else if (res.status === 404)
        history.replace('/invalidResetPasswordUrl')
      else
        toErrorPage(res.message)
    }
    checkToken()
  }, [])

  const resetPassword = async (password: string) => {
    const res = await request.post('/auth/resetPassword', {
      accountId,
      token,
      plainNewPassword: password,
    })
    if (res.status === 200)
      history.replace('/resetPasswordComplete')
    else
      toErrorPage(res.message)
  }

  const handleSubmit = async (form: FormComponentProps['form']) => {
    const { getFieldValue, validateFields } = form
    validateFields(async (err) => {
      if (err)
        return null

      setLoading(true)
      const password = getFieldValue('password')
      await resetPassword(password)
      return null
    })
  }

  return (
    <>
      {init && (
        <>
          <FormTitle>重新設定密碼</FormTitle>
          <FormDescription>請在下方輸入新的密碼</FormDescription>
          <ResetPasswordEmailForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
          <Link to="/login">{'<返回'}</Link>
        </>
      )}
    </>
  )
}

export default ResetPassword
