import { gql, useQuery } from '@apollo/client'
import {
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Card, Col, Form, Input, Row } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React from 'react'

import type {
  PatientListPaymentQueryQuery,
  PatientListPaymentQueryQueryVariables,
} from '../../../graphql/types'
import {
  PatientSource,
  PatientStatus,
} from '../../../graphql/types'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import type {
  PatientFilterType,
  PatientSorterField,
} from '../../components/table/PatientPayment'
import PatientTablePayment from '../../components/table/PatientPayment'

const patientListQuery = gql`
  query PatientListPaymentQuery(
    $query: PatientsQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    patients(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...PatientTablePayment
      }
      total
      limit
      page
    }
  }
  ${PatientTablePayment.fragment}
`

export interface PatientFormInput {
  name?: string
  clinicName?: string
  doctorName?: string
  accountManagerName?: string
  technicianName?: string
  salesName?: string
  customerServiceName?: string
}

const formInput = {
  name: MyStringParam,
  clinicName: MyStringParam,
  doctorName: MyStringParam,
  accountManagerName: MyStringParam,
  technicianName: MyStringParam,
  salesName: MyStringParam,
  customerServiceName: MyStringParam,
}

interface PatientFormProps {
  form: FormInstance<PatientFormInput>
  formQuery: PatientFormInput
  handleSearch: () => void
  handleReset: () => void
}

export const PatientForm: FC<PatientFormProps> = (props) => {
  const { form, formQuery, handleSearch, handleReset } = props

  return (
    <Form form={form} initialValues={formQuery}>
      <Row gutter={8}>
        <Col>
          <Form.Item label="病患" name="name">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="診所" name="clinicName">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="AM" name="accountManagerName">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="醫師" name="doctorName">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="技師" name="technicianName">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="業務" name="salesName">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="客服" name="customerServiceName">
            <Input onPressEnter={handleSearch} style={{ width: 80 }} />
          </Form.Item>
        </Col>
        <Col>
          <Button onClick={handleSearch} type="primary">
            搜索
          </Button>
        </Col>
        <Col>
          <Button onClick={handleReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

interface Props {
  className: any
}

const PatientListPayment: FC<Props> = (props) => {
  const { className } = props
  const [form] = useForm<PatientFormInput>()
  const { tableQuery, paginateQuery, handleTableChange, handleTableReset }
    = TableQuery.useTableQuery<PatientFilterType, PatientSorterField>(
      {
        limit: 100,
        sort: '-meta.risk',
      },
      {
        status: Object.values(PatientStatus),
        paymentSource: [PatientSource.Clinic, PatientSource.Sov],
      },
    )
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    PatientListPaymentQueryQuery,
    PatientListPaymentQueryQueryVariables
  >(patientListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        name: formQuery.name,
        clinicName: formQuery.clinicName,
        doctorName: formQuery.doctorName,
        accountManagerName: formQuery.accountManagerName,
        technicianName: formQuery.technicianName,
        salesName: formQuery.salesName,
        customerServiceName: formQuery.customerServiceName,
        ...tableQuery.filters,
      },
      ...paginateQuery,
    },
  })

  const handleSearch = () => {
    const value = form.getFieldsValue()
    handleFormChange(value)
  }

  const handleReset = () => {
    handleFormReset()
    handleTableReset()
  }

  return (
    <div className={className}>
      <Page
        header={(
          <>
            <BreadcrumbCreator
              routes={[{ key: 'Home' }, { key: 'PatientListPayment' }]}
            />
            <Title
              route={{
                key: 'PatientListPayment',
                renderSubtitle: () => '療程中技師成本由療程預計 Step 推估而來',
              }}
            />
          </>
        )}
      >
        <Card size="small">
          <PatientForm
            form={form}
            formQuery={formQuery}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
          <PatientTablePayment
            source={data?.patients}
            sortInfo={tableQuery.sort}
            filterInfo={tableQuery.filters}
            handleChange={handleTableChange}
            loading={loading}
          />
        </Card>
      </Page>
    </div>
  )
}

export default PatientListPayment
