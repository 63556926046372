import React from 'react'

import type { TabKey } from './TabConfig'
import { accountSubRoutes } from './TabConfig'

interface Props {
  accountId: string
  tabKey: TabKey
}

function TabContent(props: Props) {
  const { accountId, tabKey } = props
  const Component = accountSubRoutes[tabKey].component

  return <Component accountId={accountId} tabKey={tabKey} />
}

export default TabContent
