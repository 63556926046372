import { gql } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery, getEmptyText } from '@sov/common'
import { Link as CustomLink } from '@sov/ui'
import { Button, Table } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { filter, includes } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { RpListTableFragment, RpTasksQueryQuery } from '../../../graphql/types'
import { getIsClinicNearCompany } from '../../utils'
import EmployeeLink from '../link/employee'
import type { RPTableSorterColumn } from '../report/tasks/RP'

const { ClinicLink, PatientLink, StageLink } = CustomLink

type ColumnItem = RpListTableFragment

type Props = TableProps<ColumnItem> & {
  source?: RpTasksQueryQuery['tasks']
  sortInfo?: AntSorterType<RPTableSorterColumn>
  data: ColumnItem[]
  handleChange: TableProps<ColumnItem>['onChange']
  isPrintMode?: boolean
  isShowingOnlySelected: boolean
  selectedRowKeys: string[] | number[]
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

function RPListTable({
  source = defaultSource,
  sortInfo,
  data,
  handleChange,
  isPrintMode,
  locale,
  loading,
  rowSelection,
  selectedRowKeys,
}: Props) {
  const { t } = useTranslation()
  const columns: ColumnProps<ColumnItem>[] = [
    {
      title: '#',
      width: 30,
      key: '#',
      render: (text, record, index: number) => <div>{index + 1}</div>,
    },
    {
      title: '診所',
      width: 80,
      dataIndex: 'clinic',
      key: 'clinic',
      render: (text, record) => (
        <ClinicLink
          item={record.patient.clinic}
          style={
            getIsClinicNearCompany(record.patient.clinic.deliveryMethod)
              ? {}
              : { backgroundColor: 'yellow' }
          }
        />
      ),
    },
    {
      title: '病患',
      width: 60,
      dataIndex: 'patient',
      key: 'patient',
      render: (text, record) => (
        <PatientLink item={record.patient} style={{ color: 'black' }} />
      ),
    },
    {
      title: '工單',
      width: 60,
      dataIndex: 'stage',
      key: 'stage',
      render: (text, record) => <StageLink item={record.stage} />,
    },
    {
      title: '任務',
      width: 80,
      dataIndex: 'task',
      key: 'task',
      render: (text, record) => t(`task.type.${record.type}`),
    },
    {
      title: '負責人',
      width: 80,
      dataIndex: 'owner',
      key: 'owner',
      render: (text) => {
        return <EmployeeLink item={text} />
      },
    },
    {
      title: '數量',
      width: 40,
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => {
        const designStage
          = record.stage.__typename === 'PrintStage'
            ? record.stage.designStage
            : undefined
        const lowerModelCount = designStage?.lowerModelCount ?? 0
        const upperModelCount = designStage?.upperModelCount ?? 0
        const totalCount = lowerModelCount + upperModelCount
        return (
          <div>
            {' '}
            {totalCount}
            {' '}
          </div>
        )
      },
    },
    {
      title: '出貨',
      width: 60,
      dataIndex: 'expectedShippingDate',
      key: 'expectedShippingDate',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'expectedShippingDate' ? sortInfo.order : undefined,
      render: (text: ColumnItem['expectedShippingDate']) => {
        return (
          <div>
            {text
              ? isPrintMode
                ? moment(text).format('MM-DD')
                : moment(text).format('YYYY-MM-DD HH:mm')
              : getEmptyText()}
          </div>
        )
      },
    },
    {
      title: '批次',
      width: 80,
      dataIndex: 'rpBatchNumber',
      key: 'rpBatchNumber',
      render: (text, record) => {
        return record.stage.__typename === 'PrintStage'
          || record.stage.__typename === 'AccessoryStage'
          ? (
            <div>{record.stage.rpBatchNumber}</div>
            )
          : null
      },
    },
    {
      title: '編號',
      width: 100,
      dataIndex: 'rpModelNumber',
      key: 'rpModelNumber',
      render: (text, record) => {
        return record.stage.__typename === 'PrintStage'
          ? (
            <div>{record.stage.designStage?.rpModelNumber}</div>
            )
          : null
      },
    },
    {
      title: '塔位',
      width: 50,
      dataIndex: 'patient',
      key: 'cabinetCode',
      render: (text, record) => {
        return <div>{record.patient.cabinetCode}</div>
      },
    },
    {
      title: '備註',
      width: 150,
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => {
        return <div>{record.stage.note}</div>
      },
    },
    {
      title: '行動',
      width: 60,
      key: 'actions',
      render: (text, record) => (
        <StageLink item={record.stage}>
          <Button type="primary" size="small">
            進行
          </Button>
        </StageLink>
      ),
    },
  ]

  const printColumns = filter<ColumnProps<ColumnItem>>((x) => {
    const keys = [
      'clinic',
      'patient',
      'stage',
      'number',
      'expectedShippingDate',
      'rpModelNumber',
      'rpBatchNumber',
      'cabinetCode',
      'note',
    ]
    return includes(x.key, keys)
  })(columns)

  const { page, total, limit } = source

  return (
    <Table<ColumnItem>
      columns={isPrintMode ? printColumns : columns}
      dataSource={data}
      expandedRowKeys={selectedRowKeys}
      expandIconColumnIndex={1}
      loading={loading}
      locale={locale}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({ page, total, limit })}
      rowKey="id"
      rowSelection={rowSelection}
      size="small"
    />
  )
}

RPListTable.fragment = gql`
  fragment RPListTable on Task {
    id
    status
    type
    expectedShippingDate
    owner {
      id
      name
    }
    stage {
      ...StageLink
      note
      ... on PrintStage {
        rpBatchNumber
        designStage {
          rpModelNumber
          lowerModelCount
          upperModelCount
        }
      }
      ... on AccessoryStage {
        rpBatchNumber
      }
    }
    patient {
      ...PatientLink
      cabinetCode
      clinic {
        deliveryMethod
        ...ClinicLink
      }
    }
  }
  ${ClinicLink.fragment}
  ${PatientLink.fragment}
  ${StageLink.fragment}
`

export default RPListTable
