import { blue, gold, grey } from '@ant-design/colors'
import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { Space, Steps, Tag } from 'antd'
import type { StepsProps } from 'antd/lib/steps'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import type { VersionListFragment } from '../../codegen/types'
import StageName from '../StageName'

const Label = styled.span`
  color: ${grey.primary};
`
const StyledStageName = styled(StageName)<{ isSelected: boolean }>`
  ${props => props.isSelected && `color: ${blue.primary}`}
`

interface VersionListProps {
  className?: string
  currentId?: string
  list: VersionListFragment[]
  handleChange?: StepsProps['onChange']
}

function VersionList(props: VersionListProps) {
  const { className, currentId, list, handleChange } = props

  const getIsSelected = (stageId: string) => stageId === currentId

  return (
    <Space className={className} direction="vertical">
      <Label>所有版本紀錄</Label>
      <Steps
        current={-1}
        direction="vertical"
        size="small"
        progressDot
        onChange={handleChange}
      >
        {list.map(item => (
          <Steps.Step
            key={item.id}
            status={getIsSelected(item.id) ? 'finish' : 'wait'}
            title={(
              <StyledStageName
                item={item}
                isSelected={getIsSelected(item.id)}
              />
            )}
            subTitle={
              item.isCPMode
                ? (
                  <Tag color={gold.primary}>新版 CP 報告</Tag>
                  )
                : (
                  <Tag color={grey[0]}>舊版預設計報告</Tag>
                  )
            }
            description={
              item.shippingDate
                ? moment(item.shippingDate).format('YYYY-MM-DD')
                : getEmptyText('進行中')
            }
          />
        ))}
      </Steps>
    </Space>
  )
}

VersionList.fragments = {
  VersionList: gql`
    fragment VersionList on EvalStage {
      id
      isCPMode
      shippingDate
      ...StageName
    }
    ${StageName.fragment}
  `,
}

export default VersionList
