import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import type { AnalysisPreviewProfileFragment } from '../../codegen/types'
import Link from '../Link'
import StageLink from '../Link/Stage'

const Container = styled.div`
  display: flex;
  color: black;
  padding-bottom: 24px;
  border-bottom: 1px solid #ececec;
  margin: 0 60px 24px 60px;
`

const AvatarContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
`

interface AvatarProps {
  src?: string
}

const Avatar = styled.img<AvatarProps>`
  width: 100%;
  object-fit: cover;
`

const Meta = styled.div`
  font-size: 16px;
  flex: 1;
  margin-left: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'stage patient'
    'doctor clinic'
    'complaint';

  > span {
    padding: 0 0 16px 0;

    &:nth-child(5) {
      grid-column: 1 / 3;
    }
  }
`

interface Props {
  stage: AnalysisPreviewProfileFragment
}

function Profile(props: Props) {
  const { stage } = props
  const patient = stage.patient

  return (
    <Container>
      <AvatarContainer>
        <Avatar src={patient.photos.frontFace?.thumbnailPath} />
      </AvatarContainer>
      <Meta>
        <span>
          報告版本：
          <Link.StageLink item={stage} />
        </span>
        <span>
          病患：
          {patient.name}
        </span>
        <span>
          醫師：
          {patient.doctor.name}
        </span>
        <span>
          診所：
          {patient.clinic.name}
        </span>
        <span>
          主訴：
          {patient.chiefComplaint}
        </span>
      </Meta>
    </Container>
  )
}

Profile.fragment = gql`
  fragment AnalysisPreviewProfile on EvalStage {
    ...StageLink
    patient {
      id
      name
      chiefComplaint
      clinic {
        id
        name
      }
      doctor {
        id
        name
      }
      photos {
        frontFace {
          id
          filename
          path
          thumbnailPath
        }
      }
    }
  }
  ${StageLink.fragment}
`

export default Profile
