import './index.less'

import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import type { Clinic, Doctor, Patient } from '../../../../graphql/types'
import type { RouteKey } from '../../../routes/routes'
import { routeMap } from '../../../routes/routes'

interface Props {
  routes: {
    key: RouteKey
    render?: any
  }[]
  params?: { [key: string]: string }
  patientItem?: Pick<Patient, 'id' | 'name'>
  clinicItem?: Pick<Clinic, 'id' | 'name'>
  doctorItem?: Pick<Doctor, 'id' | 'name'>
}

function BreadcrumbCreator(props: Props) {
  const { routes } = props

  return (
    <Breadcrumb className="components-layout-breadcrumb">
      {routes.map(({ key, render }) => {
        return (
          <Breadcrumb.Item key={key}>
            {render
              ? (
                  render()
                )
              : (
                <Link to={routeMap[key].path}>{routeMap[key].title}</Link>
                )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}

export default BreadcrumbCreator
