import { useMutation } from '@apollo/client'
import type { IAllProps } from '@tinymce/tinymce-react'
import { Typography, message } from 'antd'
import React from 'react'
import styled from 'styled-components'

import type {
  UpdateAnalysisMutation,
  UpdateAnalysisVariables,
} from '../../../../graphql/types'
import RichTextEditor from '../../../components/common/RichTextEditor'
import { updateAnalysisMutation } from '../PatientReportEdit'
import useDebounceSave from '../PatientReportEdit/useDebounceSave'

const Wrapper = styled.div`
  background-color: white;
  padding: 24px;
`

interface PanelAnalysisProps {
  stageId: string
  analysis?: string
}

function PanelAnalysis(props: PanelAnalysisProps) {
  const { analysis, stageId } = props

  const [updateAnalysis] = useMutation<
    UpdateAnalysisMutation,
    UpdateAnalysisVariables
  >(updateAnalysisMutation)

  const handleUpdateAnalysis: IAllProps['onEditorChange'] = async (value) => {
    await updateAnalysis({
      variables: {
        id: stageId,
        payload: {
          digiAnalysis: value,
        },
      },
    })
    message.info({ content: '已更數位分析內容' })
  }
  const { handleChange } = useDebounceSave({ onSave: handleUpdateAnalysis })

  return (
    <Wrapper>
      <Typography.Title level={4}>數位分析內容</Typography.Title>
      <RichTextEditor value={analysis} onEditorChange={handleChange} />
    </Wrapper>
  )
}

export default PanelAnalysis
