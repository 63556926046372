import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import rightArrowImage from './right-arrow.svg'

const Container = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

interface PatientOrderListLinkProps {
  patientId: string
}

function PatientOrderListLink(props: PatientOrderListLinkProps) {
  const { patientId } = props

  return (
    <Container>
      <Link to={`/patients/${patientId}/orders`}>
        <TextContainer>
          <div>檢視該病患所有訂單</div>
          <IconContainer>
            <img src={rightArrowImage} />
          </IconContainer>
        </TextContainer>
      </Link>
    </Container>
  )
}

export default PatientOrderListLink
