import { gql } from '@apollo/client'

import { OrderFragment } from '../fragment'

export const createOrdersMutation = gql`
  mutation CreateOrders($payloads: [CreateOrderInput!]!) {
    createOrders(payloads: $payloads) {
      ...Order
      appointment {
        id
        startDate
      }
    }
  }
  ${OrderFragment}
`
