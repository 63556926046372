import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import emailIcon from './images/email.png'
import phoneIcon from './images/phone.png'
import { RecoverPasswordMethods } from './RecoverPassword'

interface SelectBlockProps {
  title: string
  image: string
}

interface SelectBlockIconProps {
  image: string
}

const Title = styled.div`
  font-size: 28px;
`

const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  font-size: 16px;
`

const SelectBlockContainer = styled.div`
  margin: auto;
  width: 288px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;
`

const Block = styled.div`
  width: 140px;
  height: 184px;
  padding-top: 29px;
  background-color: white;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgba(138, 153, 160, 0.2);
  border-radius: 4px;
  &:hover {
    transform: translateY(-5px);
  }
`

const SelectBlockIcon = styled.div<SelectBlockIconProps>`
  background-size: cover;
  background-image: url(${props => props.image});
  width: 90px;
  height: 90px;
  padding: 15px 18px;
  margin: auto;
`

const SelectBlockTitle = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`

function SelectBlock({ title, image }: SelectBlockProps) {
  return (
    <Block>
      <SelectBlockIcon image={image} />
      <SelectBlockTitle>{title}</SelectBlockTitle>
    </Block>
  )
}

function SelectRecoverPasswordMethod() {
  return (
    <>
      <Title>忘記密碼？</Title>
      <Description>請選擇其中一種驗證方式來重設密碼。</Description>
      <SelectBlockContainer>
        <Link to={`/recoverPassword/${RecoverPasswordMethods.EMAIL}`}>
          <SelectBlock title="以電子郵件驗證" image={emailIcon} />
        </Link>
        <Link to={`/recoverPassword/${RecoverPasswordMethods.PHONE}`}>
          <SelectBlock title="以手機簡訊驗證" image={phoneIcon} />
        </Link>
      </SelectBlockContainer>
      <Link to="/">{'<返回'}</Link>
    </>
  )
}

export default SelectRecoverPasswordMethod
