import Loadable from '@loadable/component'
import type { FC } from 'react'
import React from 'react'

import { AllPrivilege } from '../../graphql/types'
import Page from '../components/layout/Page'
import { gmpRouteKeys, gmpRoutes } from './GMPRoutes'
import {
  patientRouteWithMenuKeys,
  patientRouteWithoutMenuKeys,
  patientWithMenuRoutes,
  patientWithoutMenuRoutes,
} from './PatientRoutes'

export const defaultLoadingComponent: FC = () => <Page loading />

// 定義所有 route
export const routeKeys = [
  'Home',
  'Error',
  // 診所相關
  ...['ClinicList', 'ClinicCreate', 'ClinicDetail', 'ClinicInvoiceList'],
  // 醫生相關
  ...['DoctorList', 'DoctorCreate', 'DoctorDetail', 'DoctorInvoiceList'],
  // 病患相關
  ...patientRouteWithMenuKeys,
  ...patientRouteWithoutMenuKeys,
  // 工單相關
  ...[
    'StageList',
    'StageCalendar',
    'StageViewer',
    'StageReportRp',
    'StageReportQc',
    'StageInstruction',
  ],
  // 任務相關
  ...['TaskList'],
  // 員工相關
  ...[
    'EmployeeList',
    'EmployeeCreate',
    'EmployeeDetail',
    'EmployeeTaskList',
    'EmployeeLeaveList',
    'EmployeeDashboard',
    'EmployeePatientList',
    'EmployeeStageList',
    'EmployeeOrderList',
    'EmployeeTaskOwnerSetting',
  ],
  // 貨品相關
  ...['ProductList', 'ProductCreate', 'ProductReport', 'ProductDetail'],
  // 更名後出貨相關
  ...['InvoiceList', 'InvoiceCreate', 'InvoiceDetail', 'InvoiceReportAccount'],
  // 回饋相關
  ...['FeedbackList', 'FeedbackCreate', 'FeedbackDetail'],
  // 假勤相關
  ...['LeaveList', 'LeaveCreate', 'LeaveCalendar', 'LeaveDetail'],
  // 帳戶相關
  ...['AccountList', 'AccountCreate', 'AccountDetail'],
  // 約診相關
  ...['AppointmentList', 'AppointmentCalendar'],
  // 統計資料
  ...[
    'Statistic',
    'StatisticPatient',
    'StatisticStage',
    'StatisticInvoice',
    'StatisticFinishedPatient',
    'StatisticEmployeeTask',
  ],
  // 系統設定
  ...['SystemSetting', 'PrivilegesConfig', 'MinimumRequiredAppVersion'],
  // 其他
  ...[
    'ConversationList',
    'OrderList',
    'OrderDetail',
    'MoldStageList',
    'TrackList',
    'DiaryList',
    'Notification',
  ],
  ...gmpRouteKeys,
] as const

// 提供 route 做 type checking
export type RouteKey = typeof routeKeys[number]

// 限制 route mapping 的形狀
export type Route<T extends string> = Record<
  T,
  {
    readonly path: string
    readonly title: string
    readonly subtitle?: string
    readonly isLayoutVisible?: boolean
    readonly component: any
    readonly privileges?: AllPrivilege[]
  }
>

const clinicRoutes = {
  ClinicList: {
    path: '/clinics',
    title: '診所列表',
    component: Loadable(() => import('../pages/clinic/index')),
    privileges: [],
  },
  ClinicCreate: {
    path: '/clinics/create',
    title: '診所新增',
    component: Loadable(() => import('../pages/clinic/ClinicCreate')),
    privileges: [AllPrivilege.ClinicCreate],
  },
  ClinicDetail: {
    path: '/clinics/:clinicId',
    title: '診所詳情',
    component: Loadable(() => import('../pages/clinic/ClinicDetail')),
    privileges: [],
  },
  ClinicInvoiceList: {
    path: '/clinics/:clinicId/invoices',
    title: '診所出貨單列表',
    component: Loadable(() => import('../pages/clinic/ClinicInvoice')),
    privileges: [],
  },
}

const doctorRoutes = {
  DoctorList: {
    path: '/doctors',
    title: '醫師列表',
    component: Loadable(() => import('../pages/doctor/DoctorList')),
    privileges: [],
  },
  DoctorCreate: {
    path: '/doctors/create',
    title: '醫師新增',
    component: Loadable(() => import('../pages/doctor/DoctorCreate')),
    privileges: [AllPrivilege.DoctorCreate],
  },
  DoctorDetail: {
    path: '/doctors/:doctorId',
    title: '醫師詳情',
    component: Loadable(() => import('../pages/doctor/DoctorDetail')),
    privileges: [],
  },
  DoctorInvoiceList: {
    path: '/doctors/:doctorId/invoices',
    title: '醫師出貨單列表',
    component: Loadable(() => import('../pages/doctor/DoctorInvoice')),
    privileges: [],
  },
}

const stageRoutes = {
  StageList: {
    path: '/stages',
    title: '工單列表',
    component: Loadable(() => import('../pages/stage/StageList')),
    privileges: [AllPrivilege.StageRead],
  },
  StageCalendar: {
    path: '/stages/calendar',
    title: '工單行事曆',
    component: Loadable(() => import('../pages/stage/StageCalendar')),
    privileges: [],
  },
  StageReportRp: {
    path: '/stages/report/rp',
    title: 'RP 大單',
    component: Loadable(() => import('../components/report/tasks/RP')),
    privileges: [AllPrivilege.StageRead],
  },
  StageReportQc: {
    path: '/stages/report/qc',
    title: 'QC 大單',
    component: Loadable(() => import('../components/report/tasks/QC')),
    privileges: [AllPrivilege.StageRead],
  },
  StageInstruction: {
    path: '/stages/:stageId/instruction',
    title: '指示卡編輯',
    component: Loadable(() => import('../pages/stage/StageCard')),
    privileges: [],
  },
  StageAnalysisPreview: {
    path: '/stages/:stageId/analysis/preview',
    title: '報告預覽',
    isLayoutVisible: false,
    component: Loadable(() => import('../pages/stage/StageAnalysisPreivew')),
    privileges: [],
  },
  StageViewer: {
    path: '/viewer',
    title: 'Viewer',
    isLayoutVisible: false,
    component: Loadable(() => import('../pages/viewer')),
    privileges: [],
  },
}

const taskRoutes = {
  TaskList: {
    path: '/tasks',
    title: '任務列表',
    component: Loadable(() => import('../pages/task/TaskList')),
    privileges: [AllPrivilege.TaskRead],
  },
}

const employeeRoutes = {
  EmployeeList: {
    path: '/employees',
    title: '員工列表',
    component: Loadable(() => import('../pages/employee/EmployeeList')),
    privileges: [AllPrivilege.EmployeeRead],
  },
  EmployeeCreate: {
    path: '/employees/create',
    title: '員工新增',
    component: Loadable(() => import('../pages/employee/EmployeeCreate')),
    privileges: [AllPrivilege.EmployeeCreate],
  },
  EmployeeTaskOwnerSetting: {
    path: '/employees/byTaskOwner',
    title: '任務流程設定',
    component: Loadable(() => import('../pages/employee/EmployeeByTaskOwner')),
    privileges: [AllPrivilege.EmployeeUpdate],
  },
  EmployeeDetail: {
    path: '/employees/:employeeId',
    title: '員工詳情',
    component: Loadable(() => import('../pages/employee/EmployeeDetail')),
    privileges: [],
  },
  EmployeeTaskList: {
    path: '/employees/:employeeId/tasks',
    title: '員工任務列表',
    component: Loadable(() => import('../pages/employee/EmployeeTaskList')),
    privileges: [],
  },
  EmployeeLeaveList: {
    path: '/employees/:employeeId/leaves',
    title: '員工假勤列表',
    component: Loadable(
      () => import('../components/index/employee/EmployeeLeave'),
    ),
    privileges: [],
  },
  EmployeePatientList: {
    path: '/employees/:employeeId/patients',
    title: '員工病患列表',
    component: Loadable(() => import('../pages/employee/EmployeePatientList')),
    privileges: [],
  },
  EmployeeOrderList: {
    path: '/employees/:employeeId/orders',
    title: '員工訂單列表',
    component: Loadable(() => import('../pages/employee/EmployeeOrderList')),
    privileges: [],
  },
  EmployeeStageList: {
    path: '/employees/:employeeId/stages',
    title: '員工工單列表',
    component: Loadable(() => import('../pages/employee/EmployeeStageList')),
    privileges: [],
  },
  EmployeeDashboard: {
    path: '/employees/:employeeId/dashboard',
    title: '員工儀錶板',
    component: Loadable(() => import('../pages/employee/Dashboard')),
    privileges: [AllPrivilege.StatisticRead],
  },
}

const productRoutes = {
  ProductList: {
    path: '/products',
    title: '貨品列表',
    component: Loadable(() => import('../components/index/Product')),
    privileges: [AllPrivilege.ProductRead],
  },
  ProductCreate: {
    path: '/products/create',
    title: '貨品新增',
    component: Loadable(() => import('../components/create/Product')),
    privileges: [AllPrivilege.ProductCreate],
  },
  ProductReport: {
    path: '/products/report',
    title: '貨品月報表',
    component: Loadable(() => import('../components/report/product')),
    privileges: [],
  },
  ProductDetail: {
    path: '/products/:productId',
    title: '貨品詳情',
    component: Loadable(() => import('../components/detail/Product')),
    privileges: [],
  },
}

const invoiceRoutes = {
  InvoiceList: {
    path: '/invoices',
    title: '出貨列表',
    component: Loadable(() => import('../pages/invoice/InvoiceList')),
    privileges: [AllPrivilege.InvoiceRead],
  },
  InvoiceCreate: {
    path: '/invoices/create',
    title: '出貨單新增',
    component: Loadable(() => import('../pages/invoice/InvoiceCreate')),
    privileges: [AllPrivilege.InvoiceCreate],
  },
  InvoiceDetail: {
    path: '/invoices/:invoiceId',
    title: '出貨單詳情',
    component: Loadable(() => import('../pages/invoice/InvoiceDetail')),
    privileges: [],
  },
  InvoiceReportAccount: {
    path: '/invoices/report/account',
    title: '應收帳款',
    component: Loadable(() => import('../pages/report/invoice/index')),
    privileges: [],
  },
}

const moldStageRoutes = {
  MoldStageList: {
    path: '/moldStages',
    title: '建模單列表',
    component: Loadable(() => import('../pages/stage/MoldStageList')),
    privileges: [],
  },
}

const feedbackRoutes = {
  FeedbackList: {
    path: '/feedbacks',
    title: '回饋列表',
    component: Loadable(
      () => import('../components/index/feedback/FeedbackIndex'),
    ),
    privileges: [AllPrivilege.FeedbackRead],
  },
  FeedbackCreate: {
    path: '/feedbacks/create',
    title: '回饋新增',
    component: Loadable(() => import('../components/create/Feedback')),
    privileges: [],
  },
  FeedbackDetail: {
    path: '/feedbacks/:feedbackId',
    title: '回饋詳情',
    component: Loadable(() => import('../components/detail/Feedback')),
    privileges: [],
  },
}

const statisticRoutes = {
  Statistic: {
    path: '/statistic',
    title: '統計資料',
    component: Loadable(() => import('../pages/statistic')),
    privileges: [AllPrivilege.StatisticRead],
  },
  StatisticPatient: {
    path: '/statistic/patients',
    title: '病患統計',
    component: Loadable(() => import('../pages/statistic/Patient')),
    privileges: [AllPrivilege.StatisticRead],
  },
  StatisticStage: {
    path: '/statistic/stages',
    title: '工單統計',
    component: Loadable(() => import('../pages/statistic/Stage')),
    privileges: [AllPrivilege.StatisticRead],
  },
  StatisticInvoice: {
    path: '/statistic/invoice',
    title: '營收統計',
    component: Loadable(() => import('../pages/statistic/Invoice')),
    privileges: [AllPrivilege.StatisticRead],
  },
  StatisticFinishedPatient: {
    path: '/statistic/patients/finished',
    title: '完結病患統計',
    component: Loadable(() => import('../pages/statistic/Patient/finished')),
    privileges: [AllPrivilege.StatisticRead],
  },
  StatisticEmployeeTask: {
    path: '/statistic/employees/tasks',
    title: '員工任務統計',
    component: Loadable(() => import('../pages/statistic/Employee/task')),
  },
}

const notificationRoutes = {
  Notification: {
    path: '/notifications',
    title: '通知中心',
    component: Loadable(() => import('../pages/notification')),
    privileges: [AllPrivilege.NotificationRead],
  },
}

const orderRoutes = {
  OrderList: {
    path: '/orders',
    title: '訂單列表',
    component: Loadable(() => import('../pages/order')),
    privileges: [AllPrivilege.OrderRead],
  },
  OrderDetail: {
    title: '訂單詳情',
    path: '/orders/:orderId',
    component: Loadable(() => import('../pages/order/orderDetail')),
    privileges: [],
  },
}

const trackRoutes = {
  TrackList: {
    path: '/tracks',
    title: '追蹤列表',
    component: Loadable(() => import('../pages/track')),
    privileges: [],
  },
}

const diaryRoutes = {
  DiaryList: {
    path: '/diaries',
    title: '日記列表',
    component: Loadable(() => import('../pages/diary')),
    privileges: [],
  },
}

const leaveRoutes = {
  LeaveList: {
    path: '/leaves',
    title: '假勤列表',
    component: Loadable(() => import('../components/index/Leave')),
    privileges: [AllPrivilege.LeaveRead],
  },
  LeaveCreate: {
    path: '/leaves/create',
    title: '假勤新增',
    component: Loadable(() => import('../pages/leave/LeaveCreate')),
    privileges: [AllPrivilege.LeaveCreate],
  },
  LeaveCalendar: {
    path: '/leaves/calendar',
    title: '假勤行事曆',
    component: Loadable(() => import('../components/report/Leave')),
    privileges: [AllPrivilege.LeaveRead],
  },
  LeaveDetail: {
    path: '/leaves/:leaveId',
    title: '假勤詳情',
    component: Loadable(() => import('../pages/leave/LeaveDetail')),
    privileges: [],
  },
}

const accountRoutes = {
  AccountList: {
    path: '/accounts',
    title: '帳戶列表',
    component: Loadable(() => import('../pages/account/AccountList')),
    privileges: [],
  },
  AccountCreate: {
    path: '/accounts/create',
    title: '帳戶新增',
    component: Loadable(() => import('../pages/account/AccountCreate')),
    privileges: [],
  },
  AccountDetail: {
    path: '/accounts/:accountId',
    title: '帳戶資訊',
    component: Loadable(() => import('../pages/account/AccountDetail')),
    privileges: [],
  },
}

const conversationRoutes = {
  ConversationList: {
    path: '/conversations',
    title: '聊天室列表',
    component: Loadable(() => import('../pages/conversation/ConversationList')),
    privileges: [],
  },
}

const appointmentRoutes = {
  AppointmentList: {
    path: '/appointments',
    title: '約診列表',
    component: Loadable(() => import('../pages/appointment/AppointmentList')),
    privileges: [],
  },
  AppointmentCalendar: {
    path: '/appointments/calendar',
    title: '約診行事曆',
    component: Loadable(
      () => import('../pages/appointment/AppointmentCalendar'),
    ),
    privileges: [],
  },
}

export const authRoutes = [
  {
    path: '/selectRecoverPasswordMethod',
    component: Loadable(
      () => import('../components/auth/SelectRecoverPasswordMethod'),
    ),
  },
  {
    path: '/recoverPassword/:method',
    component: Loadable(() => import('../components/auth/RecoverPassword')),
  },
  {
    path: '/recoverPasswordSMSCheck/:phone',
    component: Loadable(
      () => import('../components/auth/RecoverPasswordSMSCheck'),
    ),
  },
  {
    path: '/resetPasswordEmailSent',
    component: Loadable(
      () => import('../components/auth/ResetPasswordEmailSent'),
    ),
  },
  {
    path: '/resetPassword/:accountId/:token',
    component: Loadable(() => import('../components/auth/ResetPassword')),
  },
  {
    path: '/invalidResetPasswordUrl',
    component: Loadable(
      () => import('../components/auth/InvalidResetPasswordUrl'),
    ),
  },
  {
    path: '/resetPasswordComplete',
    component: Loadable(
      () => import('../components/auth/ResetPasswordComplete'),
    ),
  },
  {
    path: '/error',
    component: Loadable(() => import('../pages/error/index')),
  },
]

export const systemRoutes = {
  SystemSetting: {
    path: '/systemSetting',
    title: '系統設定',
    component: Loadable(() => import('../pages/system/SystemSetting')),
    privileges: [AllPrivilege.StatisticRead],
  },
  PrivilegesConfig: {
    path: '/privilegesConfig',
    title: '權限設定',
    component: Loadable(() => import('../pages/system/PrivilegesConfig')),
    privileges: [AllPrivilege.SystemUpdate],
  },
  MinimumRequiredAppVersion: {
    path: '/minimumRequiredAppVersion',
    title: 'APP 最低需求版本',
    component: Loadable(
      () => import('../pages/system/MinimumRequiredAppVersion'),
    ),
    privileges: [AllPrivilege.SystemUpdate],
  },
}

// 產生 route mapping
export const routeMap: Route<RouteKey> = {
  Home: {
    path: '/',
    title: '首頁',
    component: () => null,
  },
  ...orderRoutes,
  ...clinicRoutes,
  ...doctorRoutes,
  ...patientWithMenuRoutes,
  ...patientWithoutMenuRoutes,
  ...stageRoutes,
  ...taskRoutes,
  ...employeeRoutes,
  ...productRoutes,
  ...invoiceRoutes,
  ...moldStageRoutes,
  ...feedbackRoutes,
  ...statisticRoutes,
  ...leaveRoutes,
  ...accountRoutes,
  ...conversationRoutes,
  ...appointmentRoutes,
  ...trackRoutes,
  ...diaryRoutes,
  ...notificationRoutes,
  ...gmpRoutes,
  ...systemRoutes,
  Error: {
    path: '/error',
    title: '問題頁',
    component: Loadable(() => import('../pages/error/index')),
  },
}
