import React from 'react'
import {
  Color,
  Geometry,
  LineBasicMaterial,
  Vector3,
  VertexColors,
} from 'three'

export interface AxesProps {
  isAxesVisible: boolean
  size?: number
}

/** 實作參考: https://github.com/mrdoob/three.js/blob/7a3d9d99dcc1dfd35ef9638fe51a91d9b7449043/src/helpers/AxesHelper.js */
function Axes(props: AxesProps) {
  const { isAxesVisible, size = 400 } = props

  if (!isAxesVisible)
    return null

  const geometryX = new Geometry()
  geometryX.vertices.push(new Vector3(0, 0, 0), new Vector3(size, 0, 0))
  geometryX.colors.push(new Color(1, 0, 0), new Color(1, 0.6, 0))

  const geometryY = new Geometry()
  geometryY.vertices.push(new Vector3(0, 0, 0), new Vector3(0, size, 0))
  geometryY.colors.push(new Color(0, 1, 0), new Color(0.6, 1, 0))

  const geometryZ = new Geometry()
  geometryZ.vertices.push(new Vector3(0, 0, 0), new Vector3(0, 0, size))
  geometryZ.colors.push(new Color(0, 0, 1), new Color(0, 0.6, 1))

  const material = new LineBasicMaterial({ vertexColors: VertexColors })

  return (
    <>
      <lineSegments geometry={geometryX} material={material} />
      <lineSegments geometry={geometryY} material={material} />
      <lineSegments geometry={geometryZ} material={material} />
    </>
  )
}

export default Axes
