import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Radio, Select, Tag, message } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import type { GraphQLError } from 'graphql'
import { always, cond, equals, map, values, without } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  AccountPrivilegesQueryQuery,
  AccountPrivilegesQueryVariables,
  UpdateAccountPrivilegesMutation,
  UpdateAccountPrivilegesVariables,
} from '../../../../../../graphql/types'
import {
  AllPrivilege,
  EntityType,
  Role,
} from '../../../../../../graphql/types'
import { OnceButton } from '../../../../../components/common/button'
import LoadingLayer from '../../../../../components/common/LoadingLayer'
import { EntitySelect } from '../../../../../components/form/Select'
import ClinicLink from '../../../../../components/link/clinic'
import DoctorLink from '../../../../../components/link/doctor'
import EmployeeLink from '../../../../../components/link/employee'
import PatientLink from '../../../../../components/link/patient'
import { authContext } from '../../../../../context'
import { useLoadingLayer } from '../../../../../helpers/hooks'
import { isInRoles } from '../../../../../utils'
import type { TabKey } from '../../components/TabConfig'
import TabTitle from '../../components/TabTitle'

const FormItem = Form.Item
const Option = Select.Option

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

const TitleContainer = styled.div`
  text-align: right;
  padding-right: 25px;
  margin-bottom: 24px;
  width: 25%;
  min-width: 100px;
`

const fragment = gql`
  fragment AccountInPrivilegesTab on Account {
    id
    privileges
    extraPrivileges
    entityType
    entity {
      ...EntitySelect
    }
  }
  ${EntitySelect.fragments.EntitySelect}
`

const accountPrivilegesQuery = gql`
  query AccountPrivilegesQuery($id: ID!) {
    account(id: $id) {
      ...AccountInPrivilegesTab
    }
  }
  ${fragment}
`

const updateAccountPrivilegesMutation = gql`
  mutation UpdateAccountPrivileges($id: ID!, $payload: UpdateAccountInput!) {
    updateAccount(id: $id, payload: $payload) {
      ...AccountInPrivilegesTab
    }
  }
  ${fragment}
`

function SaveButton(props: ButtonProps) {
  return <OnceButton {...props} label="儲存更新" />
}

function CancelButton(props: ButtonProps) {
  return <OnceButton {...props} label="取消" />
}

type BindingFormProps = FormComponentProps & {
  account?: AccountPrivilegesQueryQuery['account']
  disabled: boolean | undefined
  entityType: EntityType
}

function Binding({ form, account, entityType, disabled }: BindingFormProps) {
  const { getFieldDecorator } = form

  const EntityLink = cond([
    [equals(EntityType.Employee), always(EmployeeLink)],
    [equals(EntityType.Doctor), always(DoctorLink)],
    [equals(EntityType.Clinic), always(ClinicLink)],
    [equals(EntityType.Patient), always(PatientLink)],
  ])(entityType)

  return entityType
    ? (
      <FormItem {...formItemLayout} label="關聯使用者">
        {getFieldDecorator('entity', {
          initialValue: account?.entity?.id,
          rules: [
            {
              required: true,
              message: '必填欄位',
            },
          ],
        })(
          <EntitySelect
            entityType={entityType}
            showSearch
            disabled={disabled}
            initItem={account?.entity as any}
          />,
        )}
        {account && (
          <EntityLink item={account.entity}>
            <i className="fa fa-external-link" />
          </EntityLink>
        )}
      </FormItem>
      )
    : null
}

export type AccountPrivilegesFormField = Pick<
  NonNullable<AccountPrivilegesQueryQuery['account']>,
  'extraPrivileges'
> & {
  entity: string
}

interface Props extends FormComponentProps<AccountPrivilegesFormField> {
  accountId: string
  tabKey: TabKey
}
function AccountPrivilegesForm(props: Props) {
  const { form, accountId, tabKey } = props
  const { getFieldDecorator, getFieldsValue } = form
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const auth = useContext(authContext)
  const { t } = useTranslation()

  const { data, loading: queryLoading } = useQuery<
    AccountPrivilegesQueryQuery,
    AccountPrivilegesQueryVariables
  >(accountPrivilegesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onCompleted: (data) => {
      if (!data?.account) {
        toErrorPage({
          message: '不存在的帳戶',
          redirect: {
            name: '帳戶總覽',
            url: '/accounts',
          },
        })
      }
    },
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '帳戶總覽',
          url: '/accounts',
        },
      })
    },
    variables: {
      id: accountId,
    },
  })
  const [update] = useMutation<
    UpdateAccountPrivilegesMutation,
    UpdateAccountPrivilegesVariables
  >(updateAccountPrivilegesMutation)

  const handleSubmit = async () => {
    try {
      setLoadingLayer({ loading: true, tip: '更新中...' })
      // @TODO 這邊 type 沒有保護到，若是更改 schema 或者 form 欄位不容易察覺到問題
      const payload = getFieldsValue() as AccountPrivilegesFormField

      await update({
        variables: {
          id: accountId,
          payload: {
            extraPrivileges: payload.extraPrivileges,
          },
        },
        update: (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data)
            message.success('更新帳戶成功！')
        },
      })
    }
    catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`更新帳戶失敗: ${e.message}`)
    }
  }

  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  const account = data?.account

  if (!account)
    return null

  const isGodOrManager = isInRoles([Role.God, Role.Manager], auth)

  return (
    <LoadingLayer loading={loading} tip={tip}>
      <Form style={{ marginTop: 16 }}>
        <TitleContainer>
          <TabTitle tabKey={tabKey} />
        </TitleContainer>
        <FormItem {...formItemLayout} label="使用者類別">
          <Radio.Group disabled value={account.entityType}>
            <Radio key={EntityType.Employee} value={EntityType.Employee}>
              {t(`account.entityType.${EntityType.Employee}`)}
            </Radio>
            <Radio key={EntityType.Doctor} value={EntityType.Doctor}>
              {t(`account.entityType.${EntityType.Doctor}`)}
            </Radio>
            <Radio key={EntityType.Clinic} value={EntityType.Clinic}>
              {t(`account.entityType.${EntityType.Clinic}`)}
            </Radio>
            <Radio key={EntityType.Patient} value={EntityType.Patient}>
              {t(`account.entityType.${EntityType.Patient}`)}
            </Radio>
          </Radio.Group>
        </FormItem>
        <Binding
          entityType={account.entityType}
          form={form}
          account={account}
          disabled
        />
        <FormItem {...formItemLayout} label="角色預設權限">
          {map(
            (x: string) => (
              <Tag key={x}>{t(`account.privileges.${x}`)}</Tag>
            ),
            without(account?.extraPrivileges ?? [], account.privileges ?? []),
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="額外權限">
          {getFieldDecorator('extraPrivileges', {
            initialValue: account.extraPrivileges,
          })(
            <Select
              mode="multiple"
              placeholder="添加額外權限"
              disabled={!isGodOrManager}
            >
              {map(
                x => (
                  <Option key={x} value={x}>
                    {t(`account.privileges.${x}`)}
                  </Option>
                ),
                values(AllPrivilege),
              )}
            </Select>,
          )}
        </FormItem>
        <FormItem wrapperCol={{ span: 12, offset: 4 }}>
          <SaveButton onClick={handleSubmit} type="primary" />
          <CancelButton style={{ marginLeft: '16px' }} />
        </FormItem>
      </Form>
    </LoadingLayer>
  )
}

export default Form.create<Props>()(AccountPrivilegesForm)
