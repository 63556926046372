import { gql } from '@apollo/client'

import { ProductInfoFragment } from '../fragment'

export const removeProductMutation = gql`
  mutation RemoveProduct($id: ID!) {
    removeProduct(id: $id) {
      ...ProductInfo
    }
  }
  ${ProductInfoFragment}
`
