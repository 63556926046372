import { Modal } from 'antd'
import type { ModalProps } from 'antd/lib/modal'
import React from 'react'

interface Props extends ModalProps {
  src: string
}

function PreviewModal(props: Props) {
  const { src, ...restProps } = props
  return (
    <Modal title="預覽圖" {...restProps}>
      <img src={src} style={{ width: '100%', height: '100%' }} />
    </Modal>
  )
}

export default PreviewModal
