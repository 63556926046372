export type Maybe<T> = T | undefined;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};










export enum AccessoryCode {
  /** 重壓 */
  Recreation = 'RECREATION',
  /** 維持器 */
  Retainer = 'RETAINER',
  /** 4800-RT */
  Retainer_4800 = 'RETAINER_4800',
  /** A+0 */
  APlusZero = 'A_PLUS_ZERO',
  /** A */
  A = 'A',
  /** 0 */
  Zero = 'ZERO',
  /** 活動裝置 */
  Campaign = 'CAMPAIGN',
  /** 其他 */
  Others = 'OTHERS'
}

export type AccessoryStage = Stage & Node & {
  __typename?: 'AccessoryStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 配件編號 */
  accessoryCode: AccessoryCode;
  /** 指示卡 */
  instructionCard?: Maybe<InstructionCard>;
  /** 模型批次 ex: 0822SOV-1(G) */
  rpBatchNumber?: Maybe<Scalars['String']>;
  /** 模型代號 */
  rpModelNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
  /** 訂單 */
  order?: Maybe<Order>;
  /** 出貨單 */
  invoice?: Maybe<Invoice>;
};

export type Account = Node & {
  __typename?: 'Account';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 信箱(登入用) */
  email?: Maybe<Scalars['String']>;
  /** 電話(手機登入用) */
  phone?: Maybe<Scalars['String']>;
  /** 信箱是否驗證 */
  isEmailVerified: Scalars['Boolean'];
  /** 電話是否驗證 */
  isPhoneVerified: Scalars['Boolean'];
  /** 密碼 */
  password: Scalars['String'];
  /** 使用者暱稱 */
  nickname: Scalars['String'];
  /** 備用信箱(聯絡用) */
  backupEmail?: Maybe<Scalars['String']>;
  /** 頭像檔案 */
  avatar?: Maybe<File>;
  /** 社交相關綁定 */
  providers?: Maybe<Providers>;
  /** 帳戶是否開放使用 */
  isActive: Scalars['Boolean'];
  /** 最後登入時間 */
  lastLogin?: Maybe<Scalars['Date']>;
  /** 綁定的使用者種類(員工/醫生/診所/病患) */
  entityType: EntityType;
  /** 綁定的使用者(同上種類) */
  entity?: Maybe<Entity>;
  /** 權限 */
  privileges: Array<AllPrivilege>;
  /** 額外權限 */
  extraPrivileges?: Maybe<Array<AllPrivilege>>;
  /** 備註 */
  note: Scalars['String'];
  /** 通知設定 */
  notificationSetting: NotificationSetting;
  /** 使用者目前的 app 暫存 */
  currentAppStorage?: Maybe<Scalars['String']>;
  /** 是否強制清除 app 暫存 */
  shouldClearStorage: Scalars['Boolean'];
  /** 最後一次 app 暫存清除時間 */
  lastStorageClearDate?: Maybe<Scalars['Date']>;
  /** 最近一次登入客戶端資訊 */
  lastLoginClientInfo?: Maybe<Scalars['String']>;
};

export type AccountManagerStatistic = {
  __typename?: 'AccountManagerStatistic';
  patientStatusAndCountMapList: Array<PatientStatusAndCountMap>;
  patientCountCreatedDuringPreviousMonth: Scalars['Int'];
  patientCountCreatedDuringCurrentMonth: Scalars['Int'];
  patientCountCompletedDuringCurrentMonth: Scalars['Int'];
};

export type AccountManagerStatisticQuery = {
  date: Scalars['Date'];
};

export type AccountsQuery = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  entityType?: Maybe<Array<EntityType>>;
  isActive?: Maybe<Scalars['Boolean']>;
  entityName?: Maybe<Scalars['String']>;
};

export enum AccountStatus {
  /** 不存在 */
  NotFound = 'NOT_FOUND',
  /** 驗證中 */
  Verifying = 'VERIFYING',
  /** 授權中 */
  Authorizing = 'AUTHORIZING',
  /** 已授權 */
  Authorized = 'AUTHORIZED',
  /** 已停權 */
  Suspended = 'SUSPENDED'
}

export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum AllPrivilege {
  AccountCreate = 'ACCOUNT_CREATE',
  AccountRead = 'ACCOUNT_READ',
  AccountUpdate = 'ACCOUNT_UPDATE',
  AccountDelete = 'ACCOUNT_DELETE',
  ClinicCreate = 'CLINIC_CREATE',
  ClinicRead = 'CLINIC_READ',
  ClinicUpdate = 'CLINIC_UPDATE',
  ClinicDelete = 'CLINIC_DELETE',
  MessageCreate = 'MESSAGE_CREATE',
  MessageRead = 'MESSAGE_READ',
  MessageUpdate = 'MESSAGE_UPDATE',
  MessageDelete = 'MESSAGE_DELETE',
  ConversationCreate = 'CONVERSATION_CREATE',
  ConversationRead = 'CONVERSATION_READ',
  ConversationUpdate = 'CONVERSATION_UPDATE',
  ConversationDelete = 'CONVERSATION_DELETE',
  DoctorCreate = 'DOCTOR_CREATE',
  DoctorRead = 'DOCTOR_READ',
  DoctorUpdate = 'DOCTOR_UPDATE',
  DoctorDelete = 'DOCTOR_DELETE',
  PatientCreate = 'PATIENT_CREATE',
  PatientRead = 'PATIENT_READ',
  PatientUpdate = 'PATIENT_UPDATE',
  PatientDelete = 'PATIENT_DELETE',
  PatientClose = 'PATIENT_CLOSE',
  OrderCreate = 'ORDER_CREATE',
  OrderRead = 'ORDER_READ',
  OrderUpdate = 'ORDER_UPDATE',
  OrderDelete = 'ORDER_DELETE',
  StageCreate = 'STAGE_CREATE',
  StageRead = 'STAGE_READ',
  StageUpdate = 'STAGE_UPDATE',
  StageDelete = 'STAGE_DELETE',
  StageStart = 'STAGE_START',
  ScheduleCreate = 'SCHEDULE_CREATE',
  ScheduleRead = 'SCHEDULE_READ',
  ScheduleUpdate = 'SCHEDULE_UPDATE',
  ScheduleDelete = 'SCHEDULE_DELETE',
  TaskRead = 'TASK_READ',
  TaskUpdate = 'TASK_UPDATE',
  InvoiceCreate = 'INVOICE_CREATE',
  InvoiceRead = 'INVOICE_READ',
  InvoiceUpdate = 'INVOICE_UPDATE',
  InvoiceDelete = 'INVOICE_DELETE',
  ProductCreate = 'PRODUCT_CREATE',
  ProductRead = 'PRODUCT_READ',
  ProductUpdate = 'PRODUCT_UPDATE',
  ProductDelete = 'PRODUCT_DELETE',
  EmployeeCreate = 'EMPLOYEE_CREATE',
  EmployeeRead = 'EMPLOYEE_READ',
  EmployeeUpdate = 'EMPLOYEE_UPDATE',
  EmployeeDelete = 'EMPLOYEE_DELETE',
  NotificationRead = 'NOTIFICATION_READ',
  NotificationUpdate = 'NOTIFICATION_UPDATE',
  StatisticCreate = 'STATISTIC_CREATE',
  StatisticRead = 'STATISTIC_READ',
  StatisticUpdate = 'STATISTIC_UPDATE',
  StatisticDelete = 'STATISTIC_DELETE',
  LeaveCreate = 'LEAVE_CREATE',
  LeaveRead = 'LEAVE_READ',
  LeaveUpdate = 'LEAVE_UPDATE',
  LeaveDelete = 'LEAVE_DELETE',
  FeedbackCreate = 'FEEDBACK_CREATE',
  FeedbackRead = 'FEEDBACK_READ',
  FeedbackUpdate = 'FEEDBACK_UPDATE',
  FeedbackDelete = 'FEEDBACK_DELETE',
  UserCreate = 'USER_CREATE',
  UserRead = 'USER_READ',
  UserUpdate = 'USER_UPDATE',
  UserDelete = 'USER_DELETE',
  TrackCreate = 'TRACK_CREATE',
  TrackRead = 'TRACK_READ',
  TrackUpdate = 'TRACK_UPDATE',
  TrackDelete = 'TRACK_DELETE',
  SystemUpdate = 'SYSTEM_UPDATE'
}

export type Analysis = {
  __typename?: 'Analysis';
  /** 數位分析資料 */
  digiAnalysis?: Maybe<Scalars['String']>;
  /** 各療程計畫 */
  steps?: Maybe<Array<Step>>;
};

export enum AnglesClassification {
  Class1 = 'class1',
  Class2 = 'class2',
  Class3 = 'class3'
}

export type Appointment = Schedule & Node & {
  __typename?: 'Appointment';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: ScheduleType;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  description: Scalars['String'];
  isAttended: Scalars['Boolean'];
  creator: Entity;
  patient: Patient;
};

export type AppointmentNotification = Notification & Node & {
  __typename?: 'AppointmentNotification';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: NotificationType;
  receiver: Entity;
  read: Scalars['Boolean'];
  platform: Platform;
  content: Scalars['String'];
  appointment: Appointment;
};

export type AppointmentsQuery = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creator?: Maybe<Scalars['ID']>;
  patient?: Maybe<Scalars['ID']>;
  clinic?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['ID']>;
  isAttended?: Maybe<Scalars['Boolean']>;
  clinicName?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  startDateInterval?: Maybe<Array<Scalars['Date']>>;
};

export type Auxiliary = {
  __typename?: 'Auxiliary';
  type: AuxiliaryType;
  toothPosition: ToothPosition;
  auxiliaryPosition: AuxiliaryPosition;
  value?: Maybe<Scalars['Float']>;
};

export type AuxiliaryInput = {
  toothPosition: ToothPosition;
  auxiliaryPosition: AuxiliaryPosition;
  type: AuxiliaryType;
  value?: Maybe<Scalars['Float']>;
};

export enum AuxiliaryPosition {
  /** 近心 */
  Near = 'NEAR',
  /** 遠心 */
  Far = 'FAR',
  /** 一般/正上方 */
  Center = 'CENTER',
  /** 齒頸 */
  Cervical = 'CERVICAL',
  /** 切端 */
  Incisal = 'INCISAL'
}

export enum AuxiliaryType {
  Attachment = 'ATTACHMENT',
  Button = 'BUTTON',
  StrippingAll = 'STRIPPING_ALL',
  StrippingHalf = 'STRIPPING_HALF',
  Tad = 'TAD'
}

export type BatchUpdateOrderInput = {
  id: Scalars['ID'];
  stageOrder?: Maybe<Array<StageOrderContentInput>>;
  stageProvide?: Maybe<Array<StageProvideContentInput>>;
  type?: Maybe<OrderType>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  appointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  appointmentDate?: Maybe<Scalars['Date']>;
};

export type BindAccountEntityInput = {
  entity: Scalars['ID'];
};

export enum BraceMaterial {
  Petg = 'PETG',
  Benq = 'BENQ',
  Tpu = 'TPU',
  Choice = 'CHOICE'
}

export enum BraceThickness {
  T63 = 'T63',
  T75 = 'T75',
  T100 = 'T100'
}

export enum CephAnalysis {
  Normal = 'normal',
  Protrusion = 'protrusion',
  BoneLess = 'boneLess'
}

export type ChangeProsthesisToTemp = {
  __typename?: 'ChangeProsthesisToTemp';
  need: Need;
  instruction?: Maybe<Scalars['String']>;
};

export type ChangeProsthesisToTempInput = {
  need?: Maybe<Need>;
  instruction?: Maybe<Scalars['String']>;
};

export type CheckPoint = Node & {
  __typename?: 'CheckPoint';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 目標 step */
  goalStep: Scalars['Int'];
  /** 上顎目標 */
  upper: Scalars['String'];
  /** 下顎目標 */
  lower: Scalars['String'];
  /** 備註 */
  note: Scalars['String'];
  /** 臨床指示 */
  instruction: Array<AuxiliaryType>;
  /** viewer 模型檔 */
  files?: Maybe<EvalStageFiles>;
  /** 分階段設計 */
  designStages: Array<DesignStage>;
  /** 是否完成：已到達目標 step */
  isCompleted: Scalars['Boolean'];
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
  /** 是否為 Final */
  isFinal: Scalars['Boolean'];
};

export type Clinic = Entity & Node & {
  __typename?: 'Clinic';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  name: Scalars['String'];
  fullname: Scalars['String'];
  type: ClinicType;
  location: ClinicLocation;
  phone: Scalars['String'];
  doctors: Array<Doctor>;
  email: Scalars['String'];
  accountManagers: Array<Employee>;
  technicians: Array<Employee>;
  sales: Array<Employee>;
  customerService?: Maybe<Employee>;
  specialContract: Scalars['Boolean'];
  deliveryMethod: DeliveryMethod;
  invoices: PagedInvoice;
};


export type ClinicInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type ClinicLocation = {
  __typename?: 'ClinicLocation';
  address: Scalars['String'];
  country: Country;
  district: District;
};

export type ClinicLocationCreateInput = {
  address: Scalars['String'];
  country: Country;
  district: District;
};

export type ClinicLocationUpdateInput = {
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  district?: Maybe<District>;
};

export enum ClinicPaymentType {
  StepByStep = 'step_by_step',
  StepByStepSingleArch = 'step_by_step_single_arch',
  PayInFullHigh = 'pay_in_full_high',
  PayInFullLow = 'pay_in_full_low',
  PayInFullSingleArch = 'pay_in_full_single_arch',
  SimpleInstallment = 'simple_installment'
}

export type ClinicsQuery = {
  name?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['ID']>;
  isSpecialContract?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Array<Country>>;
  district?: Maybe<Array<District>>;
};

export enum ClinicType {
  Chain = 'CHAIN',
  Franchise = 'FRANCHISE',
  Normal = 'NORMAL'
}

export type Conversation = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: ConversationType;
  members: Array<Entity>;
  readMessageRecords: Array<ReadMessageRecord>;
  latestMessage?: Maybe<Message>;
};

export type ConversationMemberQuery = {
  name?: Maybe<Scalars['String']>;
};

export type ConversationMessagesQueryInput = {
  content?: Maybe<Scalars['String']>;
};

export enum ConversationSearchMethods {
  Conversation = 'CONVERSATION',
  Patient = 'PATIENT'
}

export type ConversationsWithLatestMessageConnection = {
  __typename?: 'ConversationsWithLatestMessageConnection';
  edges: Array<ConversationsWithLatestMessageEdge>;
  pageInfo: PageInfo;
};

export type ConversationsWithLatestMessageEdge = {
  __typename?: 'ConversationsWithLatestMessageEdge';
  node: ConversationsWithLatestMessageNode;
  cursor: Scalars['String'];
};

export type ConversationsWithLatestMessageNode = Node & {
  __typename?: 'ConversationsWithLatestMessageNode';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  latestMessage: Message;
  patient: Patient;
  numberOfUnreadMessages: Scalars['Int'];
};

export type ConversationsWithLatestMessageQuery = {
  patientName?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<Array<ConversationType>>;
};

export enum ConversationType {
  /** 病患聊天室 */
  Patient = 'PATIENT'
}

export enum Country {
  Tw = 'TW',
  Cn = 'CN'
}

export type CreateAccessoryStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  accessoryCode: AccessoryCode;
};

export type CreateAccountFromEmailInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  hostname: Scalars['String'];
  entityType: EntityType;
  entity?: Maybe<Scalars['ID']>;
};

export type CreateAccountInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  nickname: Scalars['String'];
  entityType: EntityType;
  entity?: Maybe<Scalars['ID']>;
  extraPrivileges?: Maybe<Array<AllPrivilege>>;
  note?: Maybe<Scalars['String']>;
};

export type CreateAppointmentInput = {
  creator: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  patient: Scalars['ID'];
  isAttended: Scalars['Boolean'];
};

export type CreateClinicInput = {
  type: ClinicType;
  name: Scalars['String'];
  fullname: Scalars['String'];
  location: ClinicLocationCreateInput;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  doctors: Array<Scalars['ID']>;
  accountManagers: Array<Scalars['ID']>;
  technicians: Array<Scalars['ID']>;
  sales: Array<Scalars['ID']>;
  customerService?: Maybe<Scalars['ID']>;
  specialContract?: Maybe<Scalars['Boolean']>;
  deliveryMethod: DeliveryMethod;
};

export type CreateDesignStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['Int']>;
  stepIndex?: Maybe<Scalars['Int']>;
};

export type CreateDiaryInput = {
  patient: Scalars['ID'];
  diaryDate: Scalars['Date'];
  photos?: Maybe<Array<Scalars['ID']>>;
  text?: Maybe<Scalars['String']>;
  subStage: Scalars['ID'];
  wearTime: Scalars['Int'];
};

export type CreateDoctorInput = {
  id?: Maybe<Scalars['ID']>;
  clinics?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  level: DoctorLevel;
};

export type CreateEmployeeInput = {
  gender?: Maybe<Gender>;
  role: Role;
  birthDate?: Maybe<Scalars['Date']>;
  isMarried?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lineId?: Maybe<Scalars['String']>;
  onBoardDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  taskOwner?: Maybe<Array<TaskType>>;
  approvers?: Maybe<Array<Scalars['ID']>>;
  resigned?: Maybe<Scalars['Boolean']>;
};

export type CreateEvalStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  serialNumber: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  moldStage?: Maybe<Scalars['ID']>;
  initialEvalStage?: Maybe<Scalars['ID']>;
};

export type CreateFeedbackInput = {
  id?: Maybe<Scalars['ID']>;
  category?: Maybe<FeedbackCategory>;
  screenshotList?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  entityType: EntityType;
  entity: Scalars['ID'];
};

export type CreateInvoiceWithoutStageInput = {
  middlemanFee: Scalars['Int'];
  discount: Scalars['Float'];
  tax: Scalars['Boolean'];
  payment: Payment;
  products?: Maybe<Array<InvoiceProductInput>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType: HomeDeliveryType;
  note?: Maybe<Scalars['String']>;
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  clinic: Scalars['ID'];
  sales: Scalars['ID'];
  doctor?: Maybe<Scalars['ID']>;
};

export type CreateInvoiceWithStageInput = {
  middlemanFee: Scalars['Int'];
  discount: Scalars['Float'];
  tax: Scalars['Boolean'];
  payment: Payment;
  products?: Maybe<Array<InvoiceProductInput>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType: HomeDeliveryType;
  note?: Maybe<Scalars['String']>;
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  patient: Scalars['ID'];
  stage: Scalars['ID'];
};

export type CreateLeaveInput = {
  employee: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  type: LeaveType;
  reason: Scalars['String'];
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type CreateMessage = {
  type: MessageType;
  creator: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
};

export type CreateMoldStageInput = {
  patient: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  moldType: MoldType;
};

export type CreateNotificationInput = {
  type: NotificationType;
  receiver: Scalars['ID'];
  platform: Platform;
  content: Scalars['String'];
};

export type CreateOrderInput = {
  patient: Scalars['ID'];
  stageOrder: Array<StageOrderContentInput>;
  type: OrderType;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  appointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  appointmentDate?: Maybe<Scalars['Date']>;
};

export type CreatePatientInput = {
  payment?: Maybe<PatientPaymentInput>;
  /** 負責診所 */
  clinic: Scalars['ID'];
  /** 負責醫師 */
  doctor: Scalars['ID'];
  /** 負責AM */
  accountManager?: Maybe<Scalars['ID']>;
  /** 負責業務 */
  sales: Scalars['ID'];
  /** 負責客服人員 */
  customerService?: Maybe<Scalars['ID']>;
  /** 負責技師 */
  technician?: Maybe<Scalars['ID']>;
  /** 下次約診日 */
  nextAppointmentDate?: Maybe<Scalars['Date']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 代號 (客戶模型編號) */
  patientCode?: Maybe<Scalars['String']>;
  /** 塔位 (在哪個櫃子，方便尋找用) */
  cabinetCode?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<Gender>;
  /** 主要治療牙弓 (上排, 下排) */
  treatArches?: Maybe<TreatArchesInput>;
  /** 醫師主要治療指示 */
  instruction?: Maybe<DoctorInstructionInput>;
  /** 照片（均儲存於 S3） */
  photos?: Maybe<PhotosInput>;
  /** 病患備註 */
  note?: Maybe<PatientNoteInput>;
  /** 主訴 */
  chiefComplaint?: Maybe<Scalars['String']>;
  /** 顏面觀 */
  profile?: Maybe<ProfileInput>;
  /** 口內觀 */
  intraOral?: Maybe<IntraOralInput>;
  /** 矯正症狀 */
  symptoms?: Maybe<SymptomsInput>;
  /** 醫師主要治療指示 */
  doctorInstruction?: Maybe<DoctorInstructionInput>;
  /** 初診日 */
  appointmentAt?: Maybe<Scalars['Date']>;
};

export type CreatePrintStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  serialNumber: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  currentEvalStage?: Maybe<Scalars['ID']>;
};

export type CreateProductInput = {
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  spec?: Maybe<Scalars['String']>;
  cost: Scalars['Int'];
};

export type CreateSubStageInput = {
  stage: Scalars['ID'];
  serialNumber: Scalars['Int'];
};

export type CreateTrackInput = {
  clinic: Scalars['ID'];
  patient: Scalars['ID'];
  stage: Scalars['ID'];
  created?: Maybe<Scalars['Date']>;
  photo?: Maybe<TrackPhotoInput>;
  mainIssues?: Maybe<Array<TrackIssue>>;
  extraIssue?: Maybe<Scalars['String']>;
};

export type CreateWearInput = {
  patient: Scalars['ID'];
  subStage: Scalars['ID'];
  date: Scalars['Date'];
  time: Scalars['Int'];
};


export type DatedStage = {
  __typename?: 'DatedStage';
  date: Scalars['Date'];
  total: Scalars['Int'];
  accessoryTotal: Scalars['Int'];
  printTotal: Scalars['Int'];
  evalTotal: Scalars['Int'];
  accessoryDelayTotal: Scalars['Int'];
  printDelayTotal: Scalars['Int'];
  evalDelayTotal: Scalars['Int'];
  accessoryStages: Array<AccessoryStage>;
  printStages: Array<PrintStage>;
  evalStages: Array<EvalStage>;
};

export type DateSegement = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export enum DelayedStatus {
  IsDelay = 'IS_DELAY',
  Running = 'RUNNING',
  Completed = 'COMPLETED'
}

export enum DeliveryMethod {
  Shipping = 'SHIPPING',
  Sales = 'SALES'
}

export type DesignStage = Stage & Node & {
  __typename?: 'DesignStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 療程計畫 */
  step: Step;
  /** 指示卡 */
  instructionCard?: Maybe<InstructionCard>;
  /** 模型代號 */
  rpModelNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
  /** 所屬檢查點 */
  checkPoint?: Maybe<CheckPoint>;
  /** 關聯的報告工單 */
  evalStage: EvalStage;
  /** 檔案 */
  files?: Maybe<DesignStageFiles>;
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
  /** 最新一筆關聯到此設計單的製造單 */
  latestPrintStage?: Maybe<PrintStage>;
};

export type DesignStageFiles = {
  __typename?: 'DesignStageFiles';
  /** 分牙檔 */
  teeth?: Maybe<Array<ToothFile>>;
  /** 上顎牙肉 */
  upperJawModel?: Maybe<File>;
  /** 下顎牙肉 */
  lowerJawModel?: Maybe<File>;
  /** 上顎 RP 模型 */
  upperRP?: Maybe<File>;
  /** 下顎 RP 模型 */
  lowerRP?: Maybe<File>;
};

export type DesignStageFilesInput = {
  upperRP?: Maybe<Scalars['ID']>;
  lowerRP?: Maybe<Scalars['ID']>;
};

export type DiariesQuery = {
  patientName?: Maybe<Scalars['String']>;
  clinicName?: Maybe<Scalars['String']>;
};

export type Diary = Node & {
  __typename?: 'Diary';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  patient: Patient;
  wear: Wear;
  diaryDate: Scalars['Date'];
  photos?: Maybe<Array<File>>;
  text?: Maybe<Scalars['String']>;
};

export type DiaryWithHint = {
  __typename?: 'DiaryWithHint';
  diary?: Maybe<Diary>;
  hasUpdatedCurrentWear: Scalars['Boolean'];
};

export enum District {
  Tpe = 'TPE',
  Ntpc = 'NTPC',
  Kel = 'KEL',
  Tyn = 'TYN',
  Hsz = 'HSZ',
  Zmi = 'ZMI',
  Txg = 'TXG',
  Chw = 'CHW',
  Ntc = 'NTC',
  Yun = 'YUN',
  Cyi = 'CYI',
  Tnn = 'TNN',
  Khh = 'KHH',
  Pif = 'PIF',
  Ila = 'ILA',
  Hun = 'HUN',
  Ttt = 'TTT',
  Peh = 'PEH',
  Ah = 'AH',
  Bj = 'BJ',
  Fj = 'FJ',
  Gs = 'GS',
  Gd = 'GD',
  Gx = 'GX',
  Gz = 'GZ',
  Hi = 'HI',
  He = 'HE',
  Ha = 'HA',
  Hl = 'HL',
  Hb = 'HB',
  Hn = 'HN',
  Jl = 'JL',
  Js = 'JS',
  Jx = 'JX',
  Ln = 'LN',
  Nm = 'NM',
  Nx = 'NX',
  Qh = 'QH',
  Sd = 'SD',
  Sx = 'SX',
  Sn = 'SN',
  Sh = 'SH',
  Sc = 'SC',
  Tj = 'TJ',
  Xz = 'XZ',
  Xj = 'XJ',
  Yn = 'YN',
  Zj = 'ZJ',
  Cq = 'CQ',
  Mo = 'MO',
  Hk = 'HK'
}

export type Doctor = Node & Entity & {
  __typename?: 'Doctor';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 姓名 */
  name: Scalars['String'];
  /** email */
  email: Scalars['String'];
  /** 電話 */
  phone?: Maybe<Scalars['String']>;
  /** 隸屬診所 */
  clinics: Array<Clinic>;
  /** 醫師備註 */
  note: Scalars['String'];
  /** 等級 */
  level: DoctorLevel;
  /** 關聯病患 */
  patients?: Maybe<PagedPatient>;
};


export type DoctorPatientsArgs = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type DoctorInstruction = {
  __typename?: 'DoctorInstruction';
  ipr: Need;
  tads: Need;
  otherOrthdontalTools: Need;
  functionalAppliance: Need;
  maintainSpace: MaintainSpace;
  changeProsthesisToTemp: ChangeProsthesisToTemp;
  extractToothWhenNoSpace: ExtractToothWhenNoSpace;
  note: Scalars['String'];
};

export type DoctorInstructionInput = {
  note?: Maybe<Scalars['String']>;
  ipr?: Maybe<Need>;
  tads?: Maybe<Need>;
  otherOrthdontalTools?: Maybe<Need>;
  functionalAppliance?: Maybe<Need>;
  maintainSpace?: Maybe<MaintainSpaceInput>;
  changeProsthesisToTemp?: Maybe<ChangeProsthesisToTempInput>;
  extractToothWhenNoSpace?: Maybe<ExtractToothWhenNoSpaceInput>;
};

export enum DoctorLevel {
  Unknown = 'UNKNOWN',
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  Level5 = 'level5'
}

export type DoctorsQuery = {
  name?: Maybe<Scalars['String']>;
  clinic?: Maybe<Scalars['String']>;
  clinicName?: Maybe<Scalars['String']>;
  level?: Maybe<Array<DoctorLevel>>;
};

export type Employee = Entity & Node & {
  __typename?: 'Employee';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  name: Scalars['String'];
  title: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  backupEmail?: Maybe<Scalars['String']>;
  lineId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  isMarried?: Maybe<Scalars['Boolean']>;
  onBoardDate: Scalars['Date'];
  role: Role;
  taskOwner: Array<TaskType>;
  approvers: Array<Employee>;
  resigned: Scalars['Boolean'];
  leaveStatus: LeaveStatusQuery;
  patients?: Maybe<PagedPatient>;
  orders?: Maybe<PagedOrder>;
  stages?: Maybe<PagedStage>;
  leaves?: Maybe<PagedLeave>;
};


export type EmployeePatientsArgs = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeStagesArgs = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeLeavesArgs = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type EmployeesQuery = {
  roles?: Maybe<Array<Role>>;
  resigned?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  taskOwner?: Maybe<Array<TaskType>>;
  technicianClinic?: Maybe<Scalars['ID']>;
  salesClinic?: Maybe<Scalars['ID']>;
};

export type EmployeeTaskStatistic = {
  __typename?: 'EmployeeTaskStatistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  employee: Employee;
  taskType: TaskType;
  count: Scalars['Int'];
};

export type EmployeeTaskStatisticQuery = {
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  taskTypes: Array<TaskType>;
};

export type Entity = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum EntityType {
  Employee = 'EMPLOYEE',
  Doctor = 'DOCTOR',
  Clinic = 'CLINIC',
  Patient = 'PATIENT'
}

export type EntityTypeToPrivilegesListSystem = System & Node & {
  __typename?: 'EntityTypeToPrivilegesListSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 實體權限表 */
  entityTypeToPrivilegesList: Array<SystemEntityTypeToPrivileges>;
};

export type EstheticLine = {
  __typename?: 'EstheticLine';
  lipsEstheticLineRelation: LipsEstheticLineRelation;
  instruction: Instruction;
};

export type EstheticLineInput = {
  lipsEstheticLineRelation?: Maybe<LipsEstheticLineRelation>;
  instruction?: Maybe<Instruction>;
};

export type EvalStage = Stage & Node & {
  __typename?: 'EvalStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 診斷資料 */
  analysis?: Maybe<Analysis>;
  /** 檢查點 */
  checkPoints: Array<CheckPoint>;
  /** 是否在製造前創建 */
  isBeforePrint: Scalars['Boolean'];
  /** 關聯的建模單 */
  moldStage?: Maybe<MoldStage>;
  /** 檔案 */
  files?: Maybe<EvalStageFiles>;
  /** 階段進度表（分階段 excel） */
  stepsOverview?: Maybe<File>;
  /** 從特定報告單派生出來 */
  initialEvalStage?: Maybe<EvalStage>;
  /** 訂單 */
  order?: Maybe<Order>;
  /** 出貨單 */
  invoice?: Maybe<Invoice>;
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
  /** 是否為 CP 版報告 */
  isCPMode: Scalars['Boolean'];
  /** 可供外部檢視 */
  externalVisible: Scalars['Boolean'];
  /** 是否在報告中顯示 CP */
  shouldShowCheckPoint: Scalars['Boolean'];
};

export type EvalStageFiles = {
  __typename?: 'EvalStageFiles';
  /** 分牙檔 */
  teeth?: Maybe<Array<ToothFile>>;
  /** 上顎牙肉 */
  upperJawModel?: Maybe<File>;
  /** 下顎牙肉 */
  lowerJawModel?: Maybe<File>;
};

export type EvalStagesQuery = {
  patient?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<StageStatus>>;
  isBeforePrint?: Maybe<Scalars['Boolean']>;
};

export type Event = Node & {
  __typename?: 'Event';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  creator: Entity;
  type: EventType;
  data: EventData;
};

export type EventData = {
  __typename?: 'EventData';
  patient?: Maybe<Patient>;
  order?: Maybe<Order>;
  stage?: Maybe<Stage>;
};

export type EventsQuery = {
  patient?: Maybe<Scalars['ID']>;
};

export enum EventType {
  CreatePatient = 'CREATE_PATIENT',
  CreateOrder = 'CREATE_ORDER',
  CreateStage = 'CREATE_STAGE',
  CompleteStage = 'COMPLETE_STAGE',
  UpdateCurrentStage = 'UPDATE_CURRENT_STAGE'
}

export type ExpectedShippingStagesQuery = {
  type?: Maybe<Array<StageType>>;
  status?: Maybe<Array<StageStatus>>;
};

export type ExportData = {
  __typename?: 'ExportData';
  data: Scalars['String'];
};

export type ExtractToothWhenNoSpace = {
  __typename?: 'ExtractToothWhenNoSpace';
  intention: Need;
  toothType?: Maybe<ToothType>;
};

export type ExtractToothWhenNoSpaceInput = {
  intention?: Maybe<Need>;
  toothType?: Maybe<ToothType>;
};

export enum FaceRatio {
  Normal = 'normal',
  Long = 'long',
  Short = 'short'
}

export enum FcmNotificationType {
  TomorrowAppointment = 'TOMORROW_APPOINTMENT'
}

export type Feedback = Node & {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  category: FeedbackCategory;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  screenshotList?: Maybe<Array<File>>;
  status: FeedbackStatus;
  entityType: EntityType;
  entity: Entity;
};

export enum FeedbackCategory {
  Bug = 'bug',
  Feature = 'feature'
}

export type FeedbackQuery = {
  createdDateInterval?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Array<FeedbackCategory>>;
  status?: Maybe<Array<FeedbackStatus>>;
  entityName?: Maybe<Scalars['String']>;
  entityType?: Maybe<Array<EntityType>>;
};

export enum FeedbackStatus {
  Pending = 'pending',
  Completed = 'completed'
}

export enum FieldAsForeignKeyReferToUser {
  Id = 'id',
  Sales = 'sales',
  Doctor = 'doctor',
  Clinic = 'clinic',
  Owner = 'owner',
  Approvers = 'approvers',
  Employee = 'employee',
  Patient = 'patient',
  AccountManager = 'accountManager'
}

export type File = Node & {
  __typename?: 'File';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  mimeType: Scalars['String'];
  filename: Scalars['String'];
  path: Scalars['String'];
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type FileInput = {
  mimeType: Scalars['String'];
  filename: Scalars['String'];
  path: Scalars['String'];
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type FileUploadOption = {
  prefix: Scalars['String'];
  needThumbnail?: Maybe<Scalars['Boolean']>;
};

export type ForwardTaskInput = {
  nextOwner?: Maybe<Scalars['ID']>;
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GroupedCreatedPatientCountMap = {
  __typename?: 'GroupedCreatedPatientCountMap';
  clinic: Clinic;
  source: PatientSource;
  createdPatientCount: Scalars['Int'];
};

export type GroupedCreatedPatientCountMapListQuery = {
  createdInterval?: Maybe<Array<Scalars['Date']>>;
};

export enum HomeDeliveryType {
  Self = 'self',
  TCat = 't_cat',
  Maple = 'maple',
  Post = 'post'
}

export type ImageMessage = Message & Node & {
  __typename?: 'ImageMessage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  conversation: Conversation;
  type: MessageType;
  creator: Entity;
  image: File;
};

export type ImpactedTooth = {
  __typename?: 'ImpactedTooth';
  hasImpactedTooth: Scalars['Boolean'];
  instruction?: Maybe<ImpactedToothInstruction>;
};

export type ImpactedToothInput = {
  hasImpactedTooth?: Maybe<Scalars['Boolean']>;
  instruction?: Maybe<ImpactedToothInstruction>;
};

export enum ImpactedToothInstruction {
  Extract = 'extract',
  DontMove = 'dontMove'
}

export enum Instruction {
  Maintain = 'maintain',
  Improve = 'improve',
  Idealize = 'idealize'
}

export type InstructionCard = {
  __typename?: 'InstructionCard';
  instruction?: Maybe<Scalars['String']>;
  auxiliaries: Array<Auxiliary>;
  /** 牙位狀態 */
  teethStatus: Array<ToothStatus>;
  retrieve: Scalars['Boolean'];
  stepCode?: Maybe<Scalars['String']>;
  stage: Stage;
  patient: Patient;
};

export type InstructionCardInput = {
  instruction?: Maybe<Scalars['String']>;
  auxiliaries?: Maybe<Array<AuxiliaryInput>>;
  teethStatus?: Maybe<Array<ToothStatusInput>>;
  retrieve?: Maybe<Scalars['Boolean']>;
};

export type IntraOral = {
  __typename?: 'IntraOral';
  missingTeeth: Array<Scalars['Int']>;
  teethDontMove: Array<Scalars['Int']>;
  teethWillExtract: Array<Scalars['Int']>;
  teethStatus: Array<ToothStatus>;
  temporomandibularJointDisordersTreatStatus: TreatStatus;
  periodontalDiseaseTreatStatus: TreatStatus;
  residualRoot: ResidualRoot;
  impactedTooth: ImpactedTooth;
  uneruptedWisdomTooth: UneruptedWisdomTooth;
  maxillarMidlineToFacialMidline: Midline;
  mandibularMidlineToMaxillarMidline: Midline;
};

export type IntraOralInput = {
  teethStatus?: Maybe<Array<ToothStatusInput>>;
  temporomandibularJointDisordersTreatStatus?: Maybe<TreatStatus>;
  periodontalDiseaseTreatStatus?: Maybe<TreatStatus>;
  residualRoot?: Maybe<ResidualRootInput>;
  impactedTooth?: Maybe<ImpactedToothInput>;
  uneruptedWisdomTooth?: Maybe<UneruptedWisdomToothInput>;
  maxillarMidlineToFacialMidline?: Maybe<MidlineInput>;
  mandibularMidlineToMaxillarMidline?: Maybe<MidlineInput>;
};

export type Invoice = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  clinic: Clinic;
  discount: Scalars['Float'];
  doctor: Doctor;
  homeDeliveryId: Scalars['String'];
  homeDeliveryType: HomeDeliveryType;
  /** 開立發票的號碼 */
  invoiceNumber: Scalars['String'];
  middlemanFee: Scalars['Int'];
  note: Scalars['String'];
  /** 入帳時間 */
  creditDate: Scalars['Date'];
  /** 出貨時間 */
  shippingDate?: Maybe<Scalars['Date']>;
  payment: Payment;
  products: Array<InvoiceProduct>;
  sales: Employee;
  tax: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  /** 出貨狀態 */
  shippingStatus: ShippingStatus;
  deferredRevenue: Scalars['Float'];
  /** 出貨單所屬診所的負責克服 */
  customerService?: Maybe<Employee>;
};

export type InvoiceMonth = {
  __typename?: 'InvoiceMonth';
  year: Scalars['Int'];
  month: Scalars['Int'];
  invoiceCount: Scalars['Int'];
  freeInvoiceCount: Scalars['Int'];
};

export type InvoiceMonthInput = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};

export type InvoiceProduct = {
  __typename?: 'InvoiceProduct';
  productId: Scalars['ID'];
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  spec: Scalars['String'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  count: Scalars['Int'];
};

export type InvoiceProductInput = {
  productId: Scalars['ID'];
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  spec?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  cost: Scalars['Int'];
  count: Scalars['Int'];
};

export type InvoiceQuery = {
  clinicId?: Maybe<Scalars['ID']>;
  doctorId?: Maybe<Scalars['ID']>;
  doctorIds?: Maybe<Array<Scalars['ID']>>;
  salesId?: Maybe<Scalars['ID']>;
  customerServiceId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  creditDateInterval?: Maybe<Array<Scalars['Date']>>;
  shippingDateInterval?: Maybe<Array<Scalars['Date']>>;
  invoiceMonths?: Maybe<Array<InvoiceMonthInput>>;
  clinicName?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  salesName?: Maybe<Scalars['String']>;
  customerServiceName?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientSources?: Maybe<Array<PatientSource>>;
  patientPaymentTypes?: Maybe<Array<PatientPaymentType>>;
  payment?: Maybe<Payment>;
  hasMiddlemanFee?: Maybe<Scalars['Boolean']>;
  shippingStatus?: Maybe<Array<ShippingStatus>>;
};

export type InvoiceStatistic = {
  __typename?: 'InvoiceStatistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segment: PatientSegment;
  indicators?: Maybe<Array<InvoiceStatisticIndicator>>;
};

export type InvoiceStatisticIndicator = {
  __typename?: 'InvoiceStatisticIndicator';
  type: InvoiceStatisticIndicatorType;
  value: Scalars['Int'];
};

export enum InvoiceStatisticIndicatorType {
  Revenue = 'REVENUE',
  TechnicianRevenue = 'TECHNICIAN_REVENUE',
  BrandRevenue = 'BRAND_REVENUE'
}

export type InvoiceStatisticQuery = {
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segments: Array<PatientSegmentInput>;
  indicatorType: InvoiceStatisticIndicatorType;
};

export type InvoiceWithoutStage = Invoice & Node & {
  __typename?: 'InvoiceWithoutStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  clinic: Clinic;
  discount: Scalars['Float'];
  doctor: Doctor;
  homeDeliveryId: Scalars['String'];
  homeDeliveryType: HomeDeliveryType;
  /** 開立發票的號碼 */
  invoiceNumber: Scalars['String'];
  middlemanFee: Scalars['Int'];
  note: Scalars['String'];
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  payment: Payment;
  products: Array<InvoiceProduct>;
  sales: Employee;
  tax: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  shippingStatus: ShippingStatus;
  deferredRevenue: Scalars['Float'];
  /** 出貨單所屬診所的負責克服 */
  customerService?: Maybe<Employee>;
};

export type InvoiceWithStage = Invoice & Node & {
  __typename?: 'InvoiceWithStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  clinic: Clinic;
  discount: Scalars['Float'];
  doctor: Doctor;
  homeDeliveryId: Scalars['String'];
  homeDeliveryType: HomeDeliveryType;
  /** 開立發票的號碼 */
  invoiceNumber: Scalars['String'];
  middlemanFee: Scalars['Int'];
  note: Scalars['String'];
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  payment: Payment;
  products: Array<InvoiceProduct>;
  sales: Employee;
  tax: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  shippingStatus: ShippingStatus;
  deferredRevenue: Scalars['Float'];
  /** 出貨單所屬診所的負責克服 */
  customerService?: Maybe<Employee>;
  patient: Patient;
  stage: Stage;
};

export enum JawPosition {
  /** 上顎 */
  Upper = 'UPPER',
  /** 下顎 */
  Lower = 'LOWER'
}

export type Leave = Node & {
  __typename?: 'Leave';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  employee: Employee;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  type: LeaveType;
  reason: Scalars['String'];
  isApproved: Scalars['Boolean'];
};

export type LeavesQuery = {
  employee?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<Array<LeaveType>>;
  reason?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type LeaveStatusQuery = {
  __typename?: 'LeaveStatusQuery';
  remainAnnual: Scalars['Float'];
  usedSickLeave: Scalars['Float'];
  usedPersonalLeave: Scalars['Float'];
};

export enum LeaveType {
  Sick = 'SICK',
  Personal = 'PERSONAL',
  Funeral = 'FUNERAL',
  Marriage = 'MARRIAGE',
  Annual = 'ANNUAL',
  Menstruation = 'MENSTRUATION',
  Maternity = 'MATERNITY',
  Other = 'OTHER',
  Birthday = 'BIRTHDAY'
}

export enum LipsEstheticLineRelation {
  Over = 'over',
  On = 'on',
  In = 'in'
}

export type MaintainSpace = {
  __typename?: 'MaintainSpace';
  isMaintainingSpace: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
};

export type MaintainSpaceInput = {
  isMaintainingSpace?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type MaxStageCapacity = {
  __typename?: 'MaxStageCapacity';
  capacityPerType: Array<MaxStageCapacityPerType>;
  maxPoints: Scalars['Float'];
};

export type MaxStageCapacityPerType = {
  __typename?: 'MaxStageCapacityPerType';
  stageType: StageType;
  pointsPerUnit: Scalars['Float'];
  maxPoints: Scalars['Float'];
};

export type Message = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  conversation: Conversation;
  type: MessageType;
  creator: Entity;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type MessagesQuery = {
  content?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
};

export enum MessageType {
  /** 一般留言 */
  Text = 'TEXT',
  /** 相片留言 */
  Image = 'IMAGE'
}

export type Midline = {
  __typename?: 'Midline';
  offset: Offset;
  instruction: Instruction;
};

export type MidlineInput = {
  offset?: Maybe<Offset>;
  instruction?: Maybe<Instruction>;
};

export type MinimumRequiredAppVersionSystem = System & Node & {
  __typename?: 'MinimumRequiredAppVersionSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** APP 最低需求版本 */
  minimumRequiredAppVersion: Scalars['String'];
};

export type MoldStage = Stage & Node & {
  __typename?: 'MoldStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 齒模類型 */
  moldType: MoldType;
  /** 檔案 */
  files?: Maybe<MoldStageFiles>;
  /** 最新一筆關聯到此建模單的報告單 */
  latestEvalStage?: Maybe<EvalStage>;
  /** 關聯的報告單列表 (只有在 moldStagesTrack resolver 回傳的 PagedMoldStage 中的 MoldStage 此欄位才能正確取到值) */
  evalStages?: Maybe<Array<EvalStage>>;
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
};

export type MoldStageFiles = {
  __typename?: 'MoldStageFiles';
  /** 分牙檔 */
  teeth?: Maybe<Array<ToothFile>>;
  /** 上顎牙肉 */
  upperJawModel?: Maybe<File>;
  /** 下顎牙肉 */
  lowerJawModel?: Maybe<File>;
};

export type MoldStagesTrackQuery = {
  patientName?: Maybe<Scalars['String']>;
  firstTime?: Maybe<Scalars['Boolean']>;
  hasEvalStage?: Maybe<Scalars['Boolean']>;
  startOfCreated?: Maybe<Scalars['String']>;
  endOfCreated?: Maybe<Scalars['String']>;
};

export enum MoldType {
  /** 石膏 */
  Plaster = 'PLASTER',
  /** 牙托 */
  Tray = 'TRAY',
  /** 口掃檔 */
  Digital = 'DIGITAL'
}

export type Mutation = {
  __typename?: 'Mutation';
  backwardTask: Task;
  backwardTaskBatch: Array<Task>;
  bindAccountUser: Account;
  /** 新增配件單 */
  createAccessoryStage: AccessoryStage;
  createAccount: Account;
  createAccountFromEmail: Account;
  createAppointment: Appointment;
  createAppointments: Array<Appointment>;
  /** 新增 check point */
  createCheckPoint: EvalStage;
  createClinic: Clinic;
  /** 新增設計單 */
  createDesignStage: DesignStage;
  createDiary: DiaryWithHint;
  createDoctor: Doctor;
  createEmployee: Employee;
  /** 新增報告單 */
  createEvalStage: EvalStage;
  createFeedback: Feedback;
  /** 創建一個空的指示卡 */
  createInstructionCard: Stage;
  createInvoiceWithStage: InvoiceWithStage;
  createInvoiceWithoutStage: InvoiceWithoutStage;
  createLeave: Leave;
  createMessage: Message;
  /** 新增建模單 */
  createMoldStage: MoldStage;
  createOrder: Order;
  createOrders?: Maybe<Array<Order>>;
  createPatient: Patient;
  /** 新增製造單 */
  createPrintStage: PrintStage;
  createProduct: Product;
  createTrack: Track;
  /** 刪除 check point */
  deleteCheckPoint: EvalStage;
  /** 廢棄工單 */
  dropStage: Stage;
  exportInvoices: ExportData;
  exportPatients: ExportData;
  forwardTask: Task;
  forwardTaskBatch: Array<Task>;
  loginByRoot: Account;
  readAllNotification: ReadAllNotificationResponse;
  readNotification: Notification;
  register: Account;
  removeAccount: Account;
  removeAppointment: Appointment;
  removeClinic: Clinic;
  removeConversation: SystemMessage;
  removeDiary: DiaryWithHint;
  removeDoctor: Doctor;
  removeEmployee: Employee;
  removeFeedback: Feedback;
  /** 移除一個空的指示卡 */
  removeInstructionCard: Stage;
  removeInvoice: RemoveSystemMessage;
  removeLeave: Leave;
  removeMessage: RemoveSystemMessage;
  removeOrder?: Maybe<RemoveSystemMessage>;
  removePatient: RemoveSystemMessage;
  removeProduct: Product;
  /** 移除工單 */
  removeStage: RemoveSystemMessage;
  removeTrack: Track;
  replyTrack: Track;
  selectPatientCurrentEvalStage: Patient;
  sendLineText: SystemMessage;
  transferPatients: Array<Patient>;
  /** 還原廢棄工單 */
  undropStage: Stage;
  /** 更新配件單 */
  updateAccessoryStage: AccessoryStage;
  updateAccount: Account;
  /** 更新診斷 */
  updateAnalysis: EvalStage;
  updateAppointment: Appointment;
  updateAppointments: Array<Appointment>;
  /** 更新 check point */
  updateCheckPoint: EvalStage;
  updateClinic: Clinic;
  updateConversationMembers: Conversation;
  updateConversationReadMessageRecordOfMember: Conversation;
  /** 更新設計單 */
  updateDesignStage: DesignStage;
  updateDiary: DiaryWithHint;
  updateDoctor: Doctor;
  updateEmployee: Employee;
  updateEmployeeBatch: Array<Employee>;
  /** 更新報告單 */
  updateEvalStage: EvalStage;
  updateFeedback: Feedback;
  /** 更新設計單的指示卡 */
  updateInstructionCard: Stage;
  updateInvoicePayment: Scalars['Int'];
  updateInvoiceWithStage: InvoiceWithStage;
  updateInvoiceWithoutStage: InvoiceWithoutStage;
  updateLeave: Leave;
  updateMessage: Message;
  updateMinimumRequiredAppVersionSystem: MinimumRequiredAppVersionSystem;
  /** 更新建模單 */
  updateMoldStage: MoldStage;
  updateOrder: Order;
  updateOrders?: Maybe<Array<Order>>;
  updatePatient: Patient;
  updatePatientCurrentSubStage: Patient;
  updatePatientStatus: Patient;
  /** 更新製造單 */
  updatePrintStage: PrintStage;
  updateProduct: Product;
  updateSystemEntityTypeToPrivilegesList: EntityTypeToPrivilegesListSystem;
  updateSystemMaxStageCapacity: StageCapacitySystem;
  updateSystemRoleToPrivilegesList: RoleToPrivilegesListSystem;
  updateSystemStageCost: StageCostSystem;
  updateTrack: Track;
  /** 更新 Viewer 檔案 */
  updateViewerFiles: Stage;
  uploadFile: File;
  verifyEmployeeAccount: Account;
};


export type MutationBackwardTaskArgs = {
  id: Scalars['ID'];
};


export type MutationBackwardTaskBatchArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationBindAccountUserArgs = {
  id: Scalars['ID'];
  payload: BindAccountEntityInput;
};


export type MutationCreateAccessoryStageArgs = {
  payload: CreateAccessoryStageInput;
};


export type MutationCreateAccountArgs = {
  payload: CreateAccountInput;
};


export type MutationCreateAccountFromEmailArgs = {
  payload: CreateAccountFromEmailInput;
};


export type MutationCreateAppointmentArgs = {
  payload: CreateAppointmentInput;
};


export type MutationCreateAppointmentsArgs = {
  payloads: Array<CreateAppointmentInput>;
};


export type MutationCreateCheckPointArgs = {
  id: Scalars['ID'];
};


export type MutationCreateClinicArgs = {
  payload: CreateClinicInput;
};


export type MutationCreateDesignStageArgs = {
  payload: CreateDesignStageInput;
};


export type MutationCreateDiaryArgs = {
  payload: CreateDiaryInput;
};


export type MutationCreateDoctorArgs = {
  payload: CreateDoctorInput;
};


export type MutationCreateEmployeeArgs = {
  payload: CreateEmployeeInput;
};


export type MutationCreateEvalStageArgs = {
  payload: CreateEvalStageInput;
};


export type MutationCreateFeedbackArgs = {
  payload: CreateFeedbackInput;
};


export type MutationCreateInstructionCardArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInvoiceWithStageArgs = {
  payload: CreateInvoiceWithStageInput;
};


export type MutationCreateInvoiceWithoutStageArgs = {
  payload: CreateInvoiceWithoutStageInput;
};


export type MutationCreateLeaveArgs = {
  payload: CreateLeaveInput;
};


export type MutationCreateMessageArgs = {
  conversationId: Scalars['ID'];
  payload: CreateMessage;
};


export type MutationCreateMoldStageArgs = {
  payload: CreateMoldStageInput;
};


export type MutationCreateOrderArgs = {
  payload: CreateOrderInput;
};


export type MutationCreateOrdersArgs = {
  payloads: Array<CreateOrderInput>;
};


export type MutationCreatePatientArgs = {
  payload: CreatePatientInput;
};


export type MutationCreatePrintStageArgs = {
  payload: CreatePrintStageInput;
};


export type MutationCreateProductArgs = {
  payload: CreateProductInput;
};


export type MutationCreateTrackArgs = {
  payload: CreateTrackInput;
};


export type MutationDeleteCheckPointArgs = {
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
};


export type MutationDropStageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationExportInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
};


export type MutationExportPatientsArgs = {
  query?: Maybe<PatientsQuery>;
};


export type MutationForwardTaskArgs = {
  id: Scalars['ID'];
  payload: ForwardTaskInput;
};


export type MutationForwardTaskBatchArgs = {
  ids: Array<Scalars['ID']>;
  payload: ForwardTaskInput;
};


export type MutationLoginByRootArgs = {
  id: Scalars['ID'];
};


export type MutationReadAllNotificationArgs = {
  receiver: Scalars['ID'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterArgs = {
  payload: RegisterAccountInput;
};


export type MutationRemoveAccountArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveClinicArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveConversationArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDiaryArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDoctorArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFeedbackArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveInstructionCardArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveLeaveArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMessageArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePatientArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveStageArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTrackArgs = {
  id: Scalars['ID'];
};


export type MutationReplyTrackArgs = {
  id: Scalars['ID'];
  payload: ReplyTrackInput;
};


export type MutationSelectPatientCurrentEvalStageArgs = {
  id: Scalars['ID'];
  stageId: Scalars['ID'];
};


export type MutationSendLineTextArgs = {
  providersLineId: Scalars['String'];
  text: Scalars['String'];
};


export type MutationTransferPatientsArgs = {
  ids: Array<Scalars['ID']>;
  payload: TransferPatientsInput;
};


export type MutationUndropStageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateAccessoryStageArgs = {
  id: Scalars['ID'];
  payload: UpdateAccessoryStageInput;
};


export type MutationUpdateAccountArgs = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type MutationUpdateAnalysisArgs = {
  id: Scalars['ID'];
  payload: UpdateAnalysisInput;
};


export type MutationUpdateAppointmentArgs = {
  id: Scalars['ID'];
  payload: UpdateAppointmentInput;
};


export type MutationUpdateAppointmentsArgs = {
  payloads: Array<UpdateAppointmentsInput>;
};


export type MutationUpdateCheckPointArgs = {
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
  payload: UpdateCheckPointInput;
};


export type MutationUpdateClinicArgs = {
  id: Scalars['ID'];
  payload: UpdateClinicInput;
};


export type MutationUpdateConversationMembersArgs = {
  id: Scalars['ID'];
  entities: Array<Scalars['ID']>;
};


export type MutationUpdateConversationReadMessageRecordOfMemberArgs = {
  id: Scalars['ID'];
  entity: Scalars['ID'];
};


export type MutationUpdateDesignStageArgs = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type MutationUpdateDiaryArgs = {
  id: Scalars['ID'];
  payload: UpdateDiaryInput;
};


export type MutationUpdateDoctorArgs = {
  id: Scalars['ID'];
  payload: UpdateDoctorInput;
};


export type MutationUpdateEmployeeArgs = {
  id: Scalars['ID'];
  payload: UpdateEmployeeInput;
};


export type MutationUpdateEmployeeBatchArgs = {
  payload: Array<UpdateEmployeeBatchInput>;
};


export type MutationUpdateEvalStageArgs = {
  id: Scalars['ID'];
  payload: UpdateEvalStageInput;
};


export type MutationUpdateFeedbackArgs = {
  id: Scalars['ID'];
  payload: UpdateFeedbackInput;
};


export type MutationUpdateInstructionCardArgs = {
  id: Scalars['ID'];
  payload: InstructionCardInput;
};


export type MutationUpdateInvoicePaymentArgs = {
  query: InvoiceQuery;
  payload: UpdateInvoicePaymentInput;
};


export type MutationUpdateInvoiceWithStageArgs = {
  id: Scalars['ID'];
  payload: UpdateInvoiceWithStageInput;
};


export type MutationUpdateInvoiceWithoutStageArgs = {
  id: Scalars['ID'];
  payload: UpdateInvoiceWithoutStageInput;
};


export type MutationUpdateLeaveArgs = {
  id: Scalars['ID'];
  payload: UpdateLeaveInput;
};


export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  payload: UpdateMessage;
};


export type MutationUpdateMinimumRequiredAppVersionSystemArgs = {
  version: Scalars['String'];
};


export type MutationUpdateMoldStageArgs = {
  id: Scalars['ID'];
  payload: UpdateMoldStageInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['ID'];
  payload: UpdateOrderInput;
};


export type MutationUpdateOrdersArgs = {
  payloads: Array<BatchUpdateOrderInput>;
};


export type MutationUpdatePatientArgs = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type MutationUpdatePatientCurrentSubStageArgs = {
  id: Scalars['ID'];
  subStageId: Scalars['ID'];
};


export type MutationUpdatePatientStatusArgs = {
  id: Scalars['ID'];
  status?: Maybe<PatientStatus>;
};


export type MutationUpdatePrintStageArgs = {
  id: Scalars['ID'];
  payload: UpdatePrintStageInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID'];
  payload: UpdateProductInput;
};


export type MutationUpdateSystemEntityTypeToPrivilegesListArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemEntityTypeToPrivilegesInput;
};


export type MutationUpdateSystemMaxStageCapacityArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemMaxStageCapacityInput;
};


export type MutationUpdateSystemRoleToPrivilegesListArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemRoleToPrivilegesInput;
};


export type MutationUpdateSystemStageCostArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemStageCostInput;
};


export type MutationUpdateTrackArgs = {
  id: Scalars['ID'];
  payload: UpdateTrackInput;
};


export type MutationUpdateViewerFilesArgs = {
  id: Scalars['ID'];
  payload: ViewerFilesInput;
  cpSerialNumber?: Maybe<Scalars['Int']>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  options: FileUploadOption;
};


export type MutationVerifyEmployeeAccountArgs = {
  id: Scalars['ID'];
  verificationType: VerificationType;
};

export enum Need {
  Yes = 'yes',
  No = 'no',
  OnlyIfNeeded = 'onlyIfNeeded'
}

export type Node = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type NormalNotification = Notification & Node & {
  __typename?: 'NormalNotification';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: NotificationType;
  receiver: Entity;
  read: Scalars['Boolean'];
  platform: Platform;
  content: Scalars['String'];
};

export type Notification = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 類別 */
  type: NotificationType;
  /** 接受者 */
  receiver: Entity;
  /** 是否已讀 */
  read: Scalars['Boolean'];
  /** 顯示的平台 */
  platform: Platform;
  /** 文字內容 (html) */
  content: Scalars['String'];
};

export enum NotificationCategory {
  Task = 'TASK',
  Patient = 'PATIENT',
  Order = 'ORDER',
  Report = 'REPORT',
  Track = 'TRACK',
  System = 'SYSTEM'
}

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  node: Notification;
  cursor: Scalars['String'];
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  site: Array<NotificationType>;
  mail: Array<NotificationType>;
  line: Array<NotificationType>;
};

export type NotificationSettingInput = {
  site: Array<NotificationType>;
  mail: Array<NotificationType>;
  line: Array<NotificationType>;
};

export type NotificationsQuery = {
  type?: Maybe<Array<NotificationType>>;
  receiver?: Maybe<Array<Scalars['ID']>>;
  read?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Platform>;
};

export enum NotificationType {
  TaskAssigned = 'TASK_ASSIGNED',
  TaskRejected = 'TASK_REJECTED',
  StageExpectedShippingDateUpdated = 'STAGE_EXPECTED_SHIPPING_DATE_UPDATED',
  PatientCreated = 'PATIENT_CREATED',
  PatientUpdated = 'PATIENT_UPDATED',
  AppointmentCreated = 'APPOINTMENT_CREATED',
  AppointmentUpdated = 'APPOINTMENT_UPDATED',
  OrderCreated = 'ORDER_CREATED',
  OrderInProgress = 'ORDER_IN_PROGRESS',
  OrderCompleted = 'ORDER_COMPLETED',
  OrderDeleted = 'ORDER_DELETED',
  CurrentEvalSelected = 'CURRENT_EVAL_SELECTED',
  CurrentEvalUpdated = 'CURRENT_EVAL_UPDATED',
  TrackCreated = 'TRACK_CREATED',
  TrackReplied = 'TRACK_REPLIED',
  RegistrationRequested = 'REGISTRATION_REQUESTED',
  RegistrationApproved = 'REGISTRATION_APPROVED',
  Appointment = 'appointment'
}

export enum OcculusalMuscle {
  Normal = 'normal',
  Strong = 'strong',
  Weak = 'weak'
}

export type OcculusalPlane = {
  __typename?: 'OcculusalPlane';
  occulusalPlaneStatus: OcculusalPlaneStatus;
  instruction: Instruction;
};

export type OcculusalPlaneInput = {
  occulusalPlaneStatus?: Maybe<OcculusalPlaneStatus>;
  instruction?: Maybe<Instruction>;
};

export enum OcculusalPlaneStatus {
  Level = 'level',
  Skew = 'skew'
}

export enum Offset {
  Centered = 'centered',
  ShiftedRight = 'shiftedRight',
  ShiftedLeft = 'shiftedLeft'
}

export type Order = Node & {
  __typename?: 'Order';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 訂單編號 */
  oid: Scalars['String'];
  /** 顯示用訂單編號（六碼） */
  displayId: Scalars['String'];
  /** 創建者 */
  creator: Entity;
  /** 已綁定工單中，最晚的預計出貨日 */
  dueDate?: Maybe<Scalars['Date']>;
  /** 病患 */
  patient: Patient;
  /** 訂單種類 */
  type: OrderType;
  /** 下訂內容 */
  stageOrder: Array<StageOrderContent>;
  /** 已綁定工單中 */
  stageProvide: Array<StageProvideContent>;
  /** 是否已完全綁定 */
  isFulfilled: Scalars['Boolean'];
  /** 狀態 */
  status: OrderStatus;
  /** 是否為該病患最新的訂單 */
  isLatest: Scalars['Boolean'];
  /** 備註 */
  description: Scalars['String'];
  /** 指示 */
  instruction: Scalars['String'];
  /** 關聯約診 */
  appointment?: Maybe<Appointment>;
};

export type OrdersQuery = {
  patientName?: Maybe<Scalars['String']>;
  patientStatus?: Maybe<Array<PatientStatus>>;
  appointmentInterval?: Maybe<Array<Scalars['Date']>>;
  clinicId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  accountManagerId?: Maybe<Scalars['ID']>;
  salesId?: Maybe<Scalars['ID']>;
  oid?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<OrderType>>;
  status?: Maybe<Array<OrderStatus>>;
  dueDateInterval?: Maybe<Array<Scalars['Date']>>;
  hasAppointment?: Maybe<Scalars['Boolean']>;
  isFulfilled?: Maybe<Scalars['Boolean']>;
};

export enum OrderStatus {
  /** 待處理：工單項目與數量尚未滿足 */
  Pending = 'PENDING',
  /** 進行中：工單項目與數量已滿足 */
  InProgress = 'IN_PROGRESS',
  /** 已完成：工單皆已完成 */
  Completed = 'COMPLETED'
}

export enum OrderType {
  /** 療程正常 */
  NormalPrint = 'NORMAL_PRINT',
  /** 重取模 */
  Remodel = 'REMODEL',
  /** 看報告 */
  Report = 'REPORT',
  /** 配件 */
  Accessory = 'ACCESSORY'
}

export type PagedAccount = {
  __typename?: 'PagedAccount';
  docs: Array<Account>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedAppointment = {
  __typename?: 'PagedAppointment';
  docs: Array<Appointment>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedClinic = {
  __typename?: 'PagedClinic';
  docs: Array<Clinic>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedDiary = {
  __typename?: 'PagedDiary';
  docs: Array<Diary>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedDoctor = {
  __typename?: 'PagedDoctor';
  docs: Array<Doctor>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedEmployee = {
  __typename?: 'PagedEmployee';
  docs: Array<Employee>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedEvalStage = {
  __typename?: 'PagedEvalStage';
  docs: Array<EvalStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedEvent = {
  __typename?: 'PagedEvent';
  docs: Array<Event>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedFeedback = {
  __typename?: 'PagedFeedback';
  docs: Array<Feedback>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedInvoice = {
  __typename?: 'PagedInvoice';
  docs: Array<Invoice>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedLeave = {
  __typename?: 'PagedLeave';
  docs: Array<Leave>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedMoldStage = {
  __typename?: 'PagedMoldStage';
  docs: Array<MoldStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedOrder = {
  __typename?: 'PagedOrder';
  docs: Array<Order>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedPatient = {
  __typename?: 'PagedPatient';
  docs: Array<Patient>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedPrintStage = {
  __typename?: 'PagedPrintStage';
  docs: Array<PrintStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedProduct = {
  __typename?: 'PagedProduct';
  docs: Array<Product>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedSchedule = {
  __typename?: 'PagedSchedule';
  docs: Array<Schedule>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedStage = {
  __typename?: 'PagedStage';
  docs: Array<Stage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedSubStage = {
  __typename?: 'PagedSubStage';
  docs: Array<SubStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedTask = {
  __typename?: 'PagedTask';
  docs: Array<Task>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedTrack = {
  __typename?: 'PagedTrack';
  docs: Array<Track>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedWear = {
  __typename?: 'PagedWear';
  docs?: Maybe<Array<Wear>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
};

export type Patient = Entity & Node & {
  __typename?: 'Patient';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  /** 姓名 */
  name: Scalars['String'];
  /** 付費方案 (新) */
  payment: PatientPayment;
  /** 病患狀況（草稿、評估中、療程中、封存 */
  status: PatientStatus;
  /** 創建者 */
  creator?: Maybe<Entity>;
  /** 負責診所 */
  clinic: Clinic;
  /** 負責醫師 */
  doctor: Doctor;
  /** 負責AM */
  accountManager?: Maybe<Employee>;
  /** 負責業務 */
  sales: Employee;
  /** 負責客服人員 */
  customerService?: Maybe<Employee>;
  /** 負責技師 */
  technician?: Maybe<Employee>;
  /** 下次約診日 */
  nextAppointmentDate?: Maybe<Scalars['Date']>;
  /** 醫師選定之主要報告 */
  currentEvalStage?: Maybe<EvalStage>;
  /** 代號 (客戶模型編號) */
  patientCode: Scalars['String'];
  /** 塔位 (在哪個櫃子，方便尋找用) */
  cabinetCode: Scalars['String'];
  /** 性別 */
  gender?: Maybe<Gender>;
  /** 主要治療牙弓 (上排, 下排) */
  treatArches: TreatArches;
  /** 照片（均儲存於 S3） */
  photos: Photos;
  /** 病患備註 */
  note: PatientNote;
  /** 主訴 */
  chiefComplaint: Scalars['String'];
  /** 顏面觀 */
  profile: Profile;
  /** 口內觀 */
  intraOral: IntraOral;
  /** 矯正症狀 */
  symptoms: Symptoms;
  /** 醫師主要治療指示 */
  doctorInstruction: DoctorInstruction;
  /** 目前哪幾個製造正進行中 */
  currentSteps: Array<Scalars['Int']>;
  /** 病患聊天室 */
  conversation: PatientConversation;
  /** 目前配戴子工單 */
  currentSubStage?: Maybe<SubStage>;
  /** 目前進度 */
  progress?: Maybe<Progress>;
  /** 可否下新訂單 */
  canCreateOrder: Scalars['Boolean'];
  /** 統計資料 */
  meta: PatientMeta;
  invoices: PagedInvoice;
  stages?: Maybe<PagedStage>;
  orders?: Maybe<PagedOrder>;
  events?: Maybe<PagedEvent>;
  diaries?: Maybe<PagedDiary>;
  tracks?: Maybe<PagedTrack>;
  subStages?: Maybe<PagedSubStage>;
  wears?: Maybe<PagedWear>;
  appointments?: Maybe<PagedAppointment>;
};


export type PatientInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientStagesArgs = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientEventsArgs = {
  query?: Maybe<EventsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientDiariesArgs = {
  query?: Maybe<PatientDiariesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientTracksArgs = {
  query?: Maybe<TracksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientSubStagesArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientWearsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientAppointmentsArgs = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export enum PatientBrand {
  Sov = 'SOV',
  Smilux = 'SMILUX',
  Retainer = 'RETAINER',
  Oem = 'OEM',
  Traditional = 'TRADITIONAL',
  Unknown = 'UNKNOWN'
}

export type PatientConversation = Conversation & Node & {
  __typename?: 'PatientConversation';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: ConversationType;
  members: Array<Entity>;
  readMessageRecords: Array<ReadMessageRecord>;
  latestMessage?: Maybe<Message>;
  patient: Patient;
};

export type PatientDiariesQuery = {
  diaryDateInterval?: Maybe<Array<Scalars['String']>>;
};

export type PatientMeta = {
  __typename?: 'PatientMeta';
  firstEvalStage?: Maybe<Stage>;
  firstEvalStageAt?: Maybe<Scalars['Date']>;
  firstPrintStage?: Maybe<Stage>;
  firstPrintStageAt?: Maybe<Scalars['Date']>;
  lastEvalStage?: Maybe<Stage>;
  lastEvalStageAt?: Maybe<Scalars['Date']>;
  lastPrintStage?: Maybe<Stage>;
  lastPrintStageAt?: Maybe<Scalars['Date']>;
  currentStepNumber: Scalars['Int'];
  advancedStepNumber: Scalars['Int'];
  finalStepNumber: Scalars['Int'];
  techRevenue: Scalars['Int'];
  techCost: Scalars['Int'];
  techProfit: Scalars['Int'];
  brandRevenue: Scalars['Int'];
  risk: Scalars['Float'];
};

export type PatientNote = {
  __typename?: 'PatientNote';
  sales: Scalars['String'];
  design: Scalars['String'];
  braces: Scalars['String'];
};

export type PatientNoteInput = {
  sales?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['String']>;
  braces?: Maybe<Scalars['String']>;
};

export type PatientPayment = {
  __typename?: 'PatientPayment';
  type: PatientPaymentType;
  brand: PatientBrand;
  source: PatientSource;
  patientPrice: Scalars['Int'];
  base: Scalars['Int'];
  referral: Scalars['Int'];
  extension: Scalars['Int'];
  discount: Scalars['Int'];
};

export type PatientPaymentInput = {
  brand?: Maybe<PatientBrand>;
  source?: Maybe<PatientSource>;
  type?: Maybe<PatientPaymentType>;
  patientPrice?: Maybe<Scalars['Int']>;
  base?: Maybe<Scalars['Int']>;
  referral?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
};

export enum PatientPaymentType {
  LumpSum = 'LUMP_SUM',
  Installment = 'INSTALLMENT',
  Advance = 'ADVANCE',
  Unknown = 'UNKNOWN'
}

export type PatientSegment = {
  __typename?: 'PatientSegment';
  title: Scalars['String'];
  clinic?: Maybe<Clinic>;
  brands?: Maybe<Array<PatientBrand>>;
  sources?: Maybe<Array<PatientSource>>;
};

export type PatientSegmentInput = {
  title: Scalars['String'];
  clinicId?: Maybe<Scalars['ID']>;
  brands?: Maybe<Array<PatientBrand>>;
  sources?: Maybe<Array<PatientSource>>;
};

export enum PatientSource {
  Sov = 'SOV',
  Clinic = 'CLINIC',
  Kol = 'KOL',
  Unknown = 'UNKNOWN'
}

export type PatientsQuery = {
  name?: Maybe<Scalars['String']>;
  clinicName?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  accountManagerName?: Maybe<Scalars['String']>;
  technicianName?: Maybe<Scalars['String']>;
  salesName?: Maybe<Scalars['String']>;
  customerServiceName?: Maybe<Scalars['String']>;
  clinic?: Maybe<Scalars['ID']>;
  accountManager?: Maybe<Scalars['ID']>;
  technician?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['ID']>;
  customerService?: Maybe<Scalars['ID']>;
  patientCode?: Maybe<Scalars['String']>;
  cabinetCode?: Maybe<Scalars['String']>;
  nextAppointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  lastEvalStageAtInterval?: Maybe<Array<Scalars['Date']>>;
  lastPrintStageAtInterval?: Maybe<Array<Scalars['Date']>>;
  status?: Maybe<Array<PatientStatus>>;
  paymentBrand?: Maybe<Array<PatientBrand>>;
  paymentSource?: Maybe<Array<PatientSource>>;
};

export type PatientStatistic = {
  __typename?: 'PatientStatistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segment: PatientSegment;
  indicators?: Maybe<Array<PatientStatisticIndicator>>;
};

export type PatientStatisticIndicator = {
  __typename?: 'PatientStatisticIndicator';
  type: PatientStatisticIndicatorType;
  value: Scalars['Int'];
};

export enum PatientStatisticIndicatorType {
  CreatedCount = 'CREATED_COUNT',
  StartEvalCount = 'START_EVAL_COUNT',
  StartPrintCount = 'START_PRINT_COUNT',
  FinishedCount = 'FINISHED_COUNT'
}

export type PatientStatisticQuery = {
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segments: Array<PatientSegmentInput>;
  indicatorType: PatientStatisticIndicatorType;
};

export enum PatientStatus {
  Initial = 'INITIAL',
  OnEval = 'ON_EVAL',
  OnPrint = 'ON_PRINT',
  Completed = 'COMPLETED',
  Inactive = 'INACTIVE'
}

export type PatientStatusAndCountMap = {
  __typename?: 'PatientStatusAndCountMap';
  status: PatientStatus;
  count: Scalars['Int'];
};

export enum PatientType {
  Normal = 'normal',
  Referral = 'referral'
}

export enum Payment {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export enum PaymentCategory {
  ClinicOrder = 'CLINIC_ORDER',
  ReferralOrder = 'REFERRAL_ORDER',
  Undecided = 'UNDECIDED'
}

export enum PaymentType {
  StepByStep = 'step_by_step',
  StepByStepSingleArch = 'step_by_step_single_arch',
  PayInFullHigh = 'pay_in_full_high',
  PayInFullLow = 'pay_in_full_low',
  PayInFullSingleArch = 'pay_in_full_single_arch',
  SimpleInstallment = 'simple_installment',
  StepByStepSelfGun = 'step_by_step_self_gun',
  StepByStepSelfGunSingleArch = 'step_by_step_self_gun_single_arch',
  SpecialSales = 'special_sales'
}

export type Photos = {
  __typename?: 'Photos';
  frontFace?: Maybe<File>;
  frontFaceWithTeeth?: Maybe<File>;
  sideFace45?: Maybe<File>;
  sideFace45WithTeeth?: Maybe<File>;
  sideFace?: Maybe<File>;
  sideFaceWithTeeth?: Maybe<File>;
  leftInside?: Maybe<File>;
  frontInside?: Maybe<File>;
  rightInside?: Maybe<File>;
  upperJawInside?: Maybe<File>;
  lowerJawInside?: Maybe<File>;
  pano?: Maybe<File>;
  ceph?: Maybe<File>;
};

export type PhotosInput = {
  frontFace?: Maybe<Scalars['String']>;
  frontFaceWithTeeth?: Maybe<Scalars['String']>;
  sideFace45?: Maybe<Scalars['String']>;
  sideFace45WithTeeth?: Maybe<Scalars['String']>;
  sideFace?: Maybe<Scalars['String']>;
  sideFaceWithTeeth?: Maybe<Scalars['String']>;
  leftInside?: Maybe<Scalars['String']>;
  frontInside?: Maybe<Scalars['String']>;
  rightInside?: Maybe<Scalars['String']>;
  upperJawInside?: Maybe<Scalars['String']>;
  lowerJawInside?: Maybe<Scalars['String']>;
  pano?: Maybe<Scalars['String']>;
  ceph?: Maybe<Scalars['String']>;
};

export enum Platform {
  Erp = 'ERP',
  Crm = 'CRM',
  App = 'APP'
}

export type PrintStage = Stage & Node & {
  __typename?: 'PrintStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 模型批次 ex: 0822SOV-1(G) */
  rpBatchNumber?: Maybe<Scalars['String']>;
  /** 關聯的設計單 */
  designStage?: Maybe<DesignStage>;
  /** 訂單 */
  order?: Maybe<Order>;
  /** 牙套 */
  subStage?: Maybe<Array<SubStage>>;
  instructionCard?: Maybe<InstructionCard>;
  /** 出貨單 */
  invoice?: Maybe<Invoice>;
};

export type Product = Node & {
  __typename?: 'Product';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  spec: Scalars['String'];
  cost: Scalars['Int'];
};

export type ProductsQuery = {
  name?: Maybe<Scalars['String']>;
};

export type ProductStat = {
  __typename?: 'ProductStat';
  product?: Maybe<Product>;
  count?: Maybe<Scalars['Int']>;
};

export type ProductStatQuery = {
  clinic?: Maybe<Scalars['String']>;
  creditDate?: Maybe<Scalars['Date']>;
};

export type Profile = {
  __typename?: 'Profile';
  mandibularMidlineToFacialMidline: Midline;
  occulusalPlane: OcculusalPlane;
  estheticLine: EstheticLine;
  cephAnalysis: CephAnalysis;
  faceRatio: FaceRatio;
  occulusalMuscle: OcculusalMuscle;
  hasGummySmile: Scalars['Boolean'];
  smileLine: SmileLine;
  hasBuccalCorridor: Scalars['Boolean'];
};

export type ProfileInput = {
  mandibularMidlineToFacialMidline?: Maybe<MidlineInput>;
  occulusalPlane?: Maybe<OcculusalPlaneInput>;
  estheticLine?: Maybe<EstheticLineInput>;
  cephAnalysis?: Maybe<CephAnalysis>;
  faceRatio?: Maybe<FaceRatio>;
  occulusalMuscle?: Maybe<OcculusalMuscle>;
  hasGummySmile?: Maybe<Scalars['Boolean']>;
  smileLine?: Maybe<SmileLine>;
  hasBuccalCorridor?: Maybe<Scalars['Boolean']>;
};

export type Progress = {
  __typename?: 'Progress';
  current: Scalars['Int'];
  total: Scalars['Int'];
};

export type Provider = {
  __typename?: 'Provider';
  id?: Maybe<Scalars['String']>;
};

export type Providers = {
  __typename?: 'Providers';
  facebook?: Maybe<Provider>;
  google?: Maybe<Provider>;
  line?: Maybe<Provider>;
  wechat?: Maybe<Provider>;
};

export type Query = {
  __typename?: 'Query';
  accessoryStage: AccessoryStage;
  account: Account;
  accountManagerStatistic: AccountManagerStatistic;
  accountStatus: AccountStatus;
  accounts: PagedAccount;
  appointment: Appointment;
  appointments: PagedAppointment;
  clinic: Clinic;
  clinics: PagedClinic;
  conversation: Conversation;
  conversationMemberOption: Array<Entity>;
  conversationMessages: MessageConnection;
  conversationsWithLatestMessage: ConversationsWithLatestMessageConnection;
  designStage: DesignStage;
  diaries: PagedDiary;
  diary: Diary;
  doctor: Doctor;
  doctors: PagedDoctor;
  employee: Employee;
  employeeTaskStatistic: Array<EmployeeTaskStatistic>;
  employees: PagedEmployee;
  evalStage: EvalStage;
  evalStages: PagedEvalStage;
  event: Event;
  events: PagedEvent;
  expectedShippingStages: Array<DatedStage>;
  feedback: Feedback;
  feedbacks: PagedFeedback;
  groupedCreatedPatientCountMapList: Array<GroupedCreatedPatientCountMap>;
  instructionCards: PagedPrintStage;
  invoice: Invoice;
  invoiceMonths: Array<InvoiceMonth>;
  invoiceStatistic: Array<InvoiceStatistic>;
  invoices: PagedInvoice;
  latestPatientAppointments: PagedAppointment;
  leave: Leave;
  leaves: PagedLeave;
  leavesCanApprove: PagedLeave;
  leavesForCalendar: Array<Leave>;
  message: Message;
  messages: MessageConnection;
  minimumRequiredAppVersion: Scalars['String'];
  /** 專屬收模單清單頁面的 query */
  moldStagesTrack: PagedMoldStage;
  node?: Maybe<Node>;
  nodes: Array<Node>;
  /** 通知列表 */
  notifications?: Maybe<NotificationConnection>;
  numberOfUnreadConversations: Scalars['Int'];
  numberOfUnreadMessages: Scalars['Int'];
  order?: Maybe<Order>;
  orders?: Maybe<PagedOrder>;
  patient?: Maybe<Patient>;
  patientStatistic: Array<PatientStatistic>;
  patients?: Maybe<PagedPatient>;
  printStage: PrintStage;
  product?: Maybe<Product>;
  productStat?: Maybe<Array<Maybe<ProductStat>>>;
  products?: Maybe<PagedProduct>;
  remainAnnual: Scalars['Float'];
  schedule?: Maybe<Schedule>;
  schedules?: Maybe<PagedSchedule>;
  stage: Stage;
  stageCapacity: Array<StageCapacityPerType>;
  stages: PagedStage;
  statistics: Array<Statistic>;
  system: System;
  task?: Maybe<Task>;
  tasks?: Maybe<PagedTask>;
  track?: Maybe<Track>;
  tracks?: Maybe<PagedTrack>;
  /** 未讀通知數 */
  unreadNotificationCount: Scalars['Int'];
  workingTaskStatistic: Array<WorkingTaskStatistic>;
};


export type QueryAccessoryStageArgs = {
  id: Scalars['ID'];
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountManagerStatisticArgs = {
  id: Scalars['ID'];
  query: AccountManagerStatisticQuery;
};


export type QueryAccountStatusArgs = {
  account: Scalars['String'];
};


export type QueryAccountsArgs = {
  query?: Maybe<AccountsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentsArgs = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryClinicArgs = {
  id: Scalars['ID'];
};


export type QueryClinicsArgs = {
  query?: Maybe<ClinicsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryConversationArgs = {
  id: Scalars['ID'];
};


export type QueryConversationMemberOptionArgs = {
  conversation: Scalars['ID'];
  query?: Maybe<ConversationMemberQuery>;
};


export type QueryConversationMessagesArgs = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<ConversationMessagesQueryInput>;
};


export type QueryConversationsWithLatestMessageArgs = {
  query?: Maybe<ConversationsWithLatestMessageQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryDesignStageArgs = {
  id: Scalars['ID'];
};


export type QueryDiariesArgs = {
  query?: Maybe<DiariesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryDiaryArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorsArgs = {
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeeTaskStatisticArgs = {
  query: EmployeeTaskStatisticQuery;
};


export type QueryEmployeesArgs = {
  query?: Maybe<EmployeesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryEvalStageArgs = {
  id: Scalars['ID'];
};


export type QueryEvalStagesArgs = {
  query?: Maybe<EvalStagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  query?: Maybe<EventsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryExpectedShippingStagesArgs = {
  query?: Maybe<ExpectedShippingStagesQuery>;
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};


export type QueryFeedbacksArgs = {
  query?: Maybe<FeedbackQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryGroupedCreatedPatientCountMapListArgs = {
  id: Scalars['ID'];
  query?: Maybe<GroupedCreatedPatientCountMapListQuery>;
};


export type QueryInstructionCardsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceMonthsArgs = {
  query?: Maybe<InvoiceQuery>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryInvoiceStatisticArgs = {
  query: InvoiceStatisticQuery;
};


export type QueryInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLatestPatientAppointmentsArgs = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLeaveArgs = {
  id: Scalars['ID'];
};


export type QueryLeavesArgs = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLeavesCanApproveArgs = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLeavesForCalendarArgs = {
  startDate: Scalars['Date'];
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  query?: Maybe<MessagesQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryMoldStagesTrackArgs = {
  query?: Maybe<MoldStagesTrackQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryNotificationsArgs = {
  query?: Maybe<NotificationsQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryNumberOfUnreadMessagesArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientStatisticArgs = {
  query: PatientStatisticQuery;
};


export type QueryPatientsArgs = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryPrintStageArgs = {
  id: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductStatArgs = {
  query?: Maybe<ProductStatQuery>;
};


export type QueryProductsArgs = {
  query?: Maybe<ProductsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryRemainAnnualArgs = {
  id: Scalars['ID'];
};


export type QueryScheduleArgs = {
  id: Scalars['ID'];
};


export type QuerySchedulesArgs = {
  query?: Maybe<SchedulesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryStageArgs = {
  id: Scalars['ID'];
};


export type QueryStageCapacityArgs = {
  query: StageCapacityQuery;
};


export type QueryStagesArgs = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryStatisticsArgs = {
  query: StatisticQuery;
};


export type QuerySystemArgs = {
  configType: SystemConfigType;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryTrackArgs = {
  id: Scalars['ID'];
};


export type QueryTracksArgs = {
  query?: Maybe<TracksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryUnreadNotificationCountArgs = {
  receiver: Scalars['ID'];
  type: Array<NotificationType>;
  platform: Platform;
};


export type QueryWorkingTaskStatisticArgs = {
  query: WorkingTaskStatisticQuery;
};

export type ReadAllNotificationResponse = {
  __typename?: 'ReadAllNotificationResponse';
  statusCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReadMessageRecord = {
  __typename?: 'ReadMessageRecord';
  member: Entity;
  lastReadMessageDate: Scalars['Date'];
};

export enum ReferralPaymentType {
  StepByStepSelfGun = 'step_by_step_self_gun',
  StepByStepSelfGunSingleArch = 'step_by_step_self_gun_single_arch',
  SpecialSales = 'special_sales'
}

export type RegisterAccountInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  nickname: Scalars['String'];
  entityType: EntityType;
  note: Scalars['String'];
  isPhoneVerified: Scalars['Boolean'];
};

export type RemoveSystemMessage = {
  __typename?: 'RemoveSystemMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type ReplyTrackInput = {
  reply: Scalars['String'];
  replyDate: Scalars['Date'];
};

export type ResidualRoot = {
  __typename?: 'ResidualRoot';
  hasResidualRoot: Scalars['Boolean'];
  instruction?: Maybe<ResidualRootInstruction>;
};

export type ResidualRootInput = {
  hasResidualRoot?: Maybe<Scalars['Boolean']>;
  instruction?: Maybe<ResidualRootInstruction>;
};

export enum ResidualRootInstruction {
  Extract = 'extract',
  Restore = 'restore',
  Maintain = 'maintain'
}

export type Response = {
  __typename?: 'Response';
  status: ResponseStatus;
  message: Scalars['String'];
};

export enum ResponseStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

/** 員工角色 */
export enum Role {
  /** 系統管理員 */
  God = 'god',
  /** 主管 */
  Manager = 'manager',
  /** 技師 */
  Technician = 'technician',
  /** 客經 */
  AccountManager = 'accountManager',
  /** 業務 */
  Sales = 'sales',
  /** 客服 */
  CustomerService = 'customerService',
  /** 秘書 */
  Secretary = 'secretary',
  /** 一般員工 */
  OfficeWorker = 'officeWorker'
}

export type RoleToPrivilegesListSystem = System & Node & {
  __typename?: 'RoleToPrivilegesListSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 員工角色權限表 */
  roleToPrivilegesList: Array<SystemRoleToPrivileges>;
};

export type Schedule = {
  id: Scalars['ID'];
  type: ScheduleType;
  creator: Entity;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  description: Scalars['String'];
};

export type SchedulesQuery = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creator?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<ScheduleType>>;
};

export enum ScheduleType {
  /** 病患的約診行程 */
  Appointment = 'APPOINTMENT'
}

export enum ShippingStatus {
  /** 等待出貨 */
  Pending = 'PENDING',
  /** 已出貨 */
  Shipped = 'SHIPPED'
}

export enum SmileLine {
  Great = 'great',
  Ugly = 'ugly'
}

export type Stage = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 種類 */
  type: StageType;
  /** 狀態 */
  status: StageStatus;
  /** 出貨時間 */
  shippingDate?: Maybe<Scalars['Date']>;
  /** 預計出貨時間 */
  expectedShippingDate?: Maybe<Scalars['Date']>;
  /** 工單注意事項 */
  note: Scalars['String'];
  /** 病患 */
  patient: Patient;
  /** 包含任務 */
  tasks: Array<Task>;
  /** 進行中的任務 */
  currentTask?: Maybe<Task>;
  /** 工單任務進行狀態, for calendar */
  delayedStatus?: Maybe<DelayedStatus>;
};

export type StageCapacityPerType = {
  __typename?: 'StageCapacityPerType';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  stageType: StageType;
  actualCount: Scalars['Int'];
  expectedCount: Scalars['Int'];
  forecastCount: Scalars['Int'];
  points: Scalars['Float'];
};

export type StageCapacityQuery = {
  stageTypes: Array<StageType>;
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  patientBrand?: Maybe<PatientBrand>;
};

export type StageCapacitySystem = System & Node & {
  __typename?: 'StageCapacitySystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 工單產能 */
  stageCapacity: MaxStageCapacity;
};

export type StageCostPerType = {
  __typename?: 'StageCostPerType';
  stageType: StageType;
  cost: Scalars['Float'];
};

export type StageCostPerTypeInput = {
  stageType: StageType;
  cost: Scalars['Float'];
};

export type StageCostSystem = System & Node & {
  __typename?: 'StageCostSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 工單產能 */
  stageCost: Array<StageCostPerType>;
};

export type StageOrderContent = {
  __typename?: 'StageOrderContent';
  stageType: StageType;
  number: Scalars['Int'];
  items?: Maybe<Array<AccessoryCode>>;
};

export type StageOrderContentInput = {
  stageType: StageType;
  number: Scalars['Int'];
  items?: Maybe<Array<Scalars['String']>>;
};

export type StageProvideContent = {
  __typename?: 'StageProvideContent';
  stageType: StageType;
  stages: Array<Stage>;
};

export type StageProvideContentInput = {
  stageType: StageType;
  stages: Array<Scalars['ID']>;
};

export type StagesQuery = {
  patient?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<StageType>>;
  status?: Maybe<Array<StageStatus>>;
  expectedShippingDateInterval?: Maybe<Array<Scalars['Date']>>;
  clinic?: Maybe<Scalars['ID']>;
  accountManagerId?: Maybe<Scalars['ID']>;
  patientIdList?: Maybe<Array<Scalars['ID']>>;
};

export enum StageStatus {
  /** 待啟動 */
  Pending = 'PENDING',
  /** 進行中 */
  Started = 'STARTED',
  /** 已完成 */
  Completed = 'COMPLETED',
  /** 已廢棄 */
  Dropped = 'DROPPED'
}

export enum StageType {
  /** 建模 */
  Mold = 'MOLD',
  /** 報告 */
  Eval = 'EVAL',
  /** 設計 */
  Design = 'DESIGN',
  /** 製造 */
  Print = 'PRINT',
  /** 配件 */
  Accessory = 'ACCESSORY'
}

export type Statistic = {
  __typename?: 'Statistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  indicators: Array<StatisticIndicator>;
};

export type StatisticIndicator = {
  __typename?: 'StatisticIndicator';
  type: StatisticIndicatorType;
  value: Scalars['Int'];
};

export enum StatisticIndicatorType {
  Revenue = 'REVENUE',
  Cost = 'COST',
  PatientCountStartPrint = 'PATIENT_COUNT_START_PRINT',
  PatientCountStartEval = 'PATIENT_COUNT_START_EVAL',
  PatientCountProcessing = 'PATIENT_COUNT_PROCESSING',
  PatientCountFinished = 'PATIENT_COUNT_FINISHED',
  PatientCountInactive = 'PATIENT_COUNT_INACTIVE',
  StageCountAdvancedDesign = 'STAGE_COUNT_ADVANCED_DESIGN',
  StageCountAccruedDesign = 'STAGE_COUNT_ACCRUED_DESIGN',
  StageCountDiscardedDesign = 'STAGE_COUNT_DISCARDED_DESIGN'
}

export enum StatisticInterval {
  AllTime = 'ALL_TIME',
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
  Week = 'WEEK',
  Day = 'DAY'
}

export type StatisticQuery = {
  dateSegments: Array<DateSegement>;
  indicatorTypes: Array<StatisticIndicatorType>;
};

export type Step = {
  __typename?: 'Step';
  /** 上顎預估計畫 */
  upperStep: Scalars['String'];
  /** 下顎預估計畫 */
  lowerStep: Scalars['String'];
  /** 是否需要 attachment */
  attachment: Scalars['Boolean'];
  /** 是否需要 ipr */
  ipr: Scalars['Boolean'];
  /** 是否需要 button */
  button: Scalars['Boolean'];
  /** 是否為重新取模 */
  retrieve: Scalars['Boolean'];
};

export type StepInput = {
  upperStep?: Maybe<Scalars['String']>;
  lowerStep?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Boolean']>;
  ipr?: Maybe<Scalars['Boolean']>;
  button?: Maybe<Scalars['Boolean']>;
  retrieve?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated?: Maybe<Notification>;
  onMessageAdded: MessageEdge;
};


export type SubscriptionNotificationCreatedArgs = {
  receiver: Scalars['ID'];
  type: Array<NotificationType>;
  platform: Platform;
};


export type SubscriptionOnMessageAddedArgs = {
  payload: SubscriptionPayload;
};

export enum SubscriptionEvents {
  AddMessage = 'ADD_MESSAGE',
  AddNotification = 'ADD_NOTIFICATION'
}

export type SubscriptionPayload = {
  subscriptionType: SubscriptionType;
  subscriber?: Maybe<Scalars['ID']>;
  conversation?: Maybe<Scalars['ID']>;
};

export enum SubscriptionType {
  /** 所有聊天室 */
  All = 'ALL',
  /** 自己是成員的聊天室 */
  Self = 'SELF',
  /** 特定聊天室 */
  Specific = 'SPECIFIC'
}

export type SubStage = Node & {
  __typename?: 'SubStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  stage: PrintStage;
  wears?: Maybe<PagedWear>;
  serialNumber: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
};


export type SubStageWearsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type Symptoms = {
  __typename?: 'Symptoms';
  leftMolarRelationship: AnglesClassification;
  rightMolarRelationship: AnglesClassification;
  anteriorCrossbite: Scalars['Boolean'];
  anteriorCrowding: Scalars['Boolean'];
  bimaxillaryProtrusion: Scalars['Boolean'];
  deepBite: Scalars['Boolean'];
  highCanine: Scalars['Boolean'];
  openBite: Scalars['Boolean'];
  posteriorCrossbite: Scalars['Boolean'];
  posteriorCrowding: Scalars['Boolean'];
  vShapeArch: Scalars['Boolean'];
};

export type SymptomsInput = {
  leftMolarRelationship?: Maybe<AnglesClassification>;
  rightMolarRelationship?: Maybe<AnglesClassification>;
  anteriorCrossbite?: Maybe<Scalars['Boolean']>;
  anteriorCrowding?: Maybe<Scalars['Boolean']>;
  bimaxillaryProtrusion?: Maybe<Scalars['Boolean']>;
  deepBite?: Maybe<Scalars['Boolean']>;
  highCanine?: Maybe<Scalars['Boolean']>;
  openBite?: Maybe<Scalars['Boolean']>;
  posteriorCrossbite?: Maybe<Scalars['Boolean']>;
  posteriorCrowding?: Maybe<Scalars['Boolean']>;
  vShapeArch?: Maybe<Scalars['Boolean']>;
};

export type System = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
};

export enum SystemConfigType {
  /** 工單產能 */
  MaxWorkCapacity = 'MAX_WORK_CAPACITY',
  /** 工單成本 */
  StageCost = 'STAGE_COST',
  /** 員工角色預設權限 */
  RoleToPrivileges = 'ROLE_TO_PRIVILEGES',
  /** 非員工實體預設權限 */
  EntityTypeToPrivileges = 'ENTITY_TYPE_TO_PRIVILEGES',
  /** APP 最低需求版本 */
  MinimumRequiredAppVersion = 'MINIMUM_REQUIRED_APP_VERSION'
}

export type SystemEntityTypeToPrivileges = {
  __typename?: 'SystemEntityTypeToPrivileges';
  entityType: EntityType;
  privileges: Array<AllPrivilege>;
};

export type SystemEntityTypeToPrivilegesInput = {
  entityType: EntityType;
  privileges: Array<AllPrivilege>;
};

export type SystemMaxStageCapacityInput = {
  capacityPerType: Array<SystemStageCapacityPerTypeInput>;
  maxPoints: Scalars['Float'];
};

export type SystemMessage = {
  __typename?: 'SystemMessage';
  message: Scalars['String'];
};

export type SystemRoleToPrivileges = {
  __typename?: 'SystemRoleToPrivileges';
  role: Role;
  privileges: Array<AllPrivilege>;
};

export type SystemRoleToPrivilegesInput = {
  role: Role;
  privileges: Array<AllPrivilege>;
};

export type SystemStageCapacityPerTypeInput = {
  stageType: StageType;
  pointsPerUnit: Scalars['Float'];
  maxPoints: Scalars['Float'];
};

export type Task = Node & {
  __typename?: 'Task';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: TaskType;
  status: TaskStatus;
  startedByEmployee?: Maybe<Scalars['Date']>;
  completedByOwner?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  /** 是否已延遲 */
  isDelayed: Scalars['Boolean'];
  patient: Patient;
  stage: Stage;
  owner?: Maybe<Entity>;
  previousTask?: Maybe<Task>;
  nextTask?: Maybe<Task>;
};

export type TasksQuery = {
  expectedShippingDateInterval?: Maybe<Array<Scalars['Date']>>;
  owner?: Maybe<Scalars['ID']>;
  patient?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['ID']>;
  startedByEmployeeInterval?: Maybe<Array<Scalars['Date']>>;
  completedByOwnerInterval?: Maybe<Array<Scalars['Date']>>;
  status?: Maybe<Array<TaskStatus>>;
  type?: Maybe<Array<TaskType>>;
};

export enum TaskStatus {
  Pending = 'pending',
  Working = 'working',
  Completed = 'completed',
  Dropped = 'dropped'
}

export enum TaskType {
  StartScan = 'start_scan',
  AssignScan = 'assign_scan',
  CompleteScan = 'complete_scan',
  CompleteBase = 'complete_base',
  CompleteSplit = 'complete_split',
  SubmitFile = 'submit_file',
  StartEval = 'start_eval',
  ConfirmMold = 'confirm_mold',
  AmCheck = 'am_check',
  AssignAlign = 'assign_align',
  CompleteAlign = 'complete_align',
  AssignPredesign = 'assign_predesign',
  CompletePredesign = 'complete_predesign',
  SubmitPredesign = 'submit_predesign',
  AssignViewer = 'assign_viewer',
  CompleteViewer = 'complete_viewer',
  SubmitViewer = 'submit_viewer',
  AssignDesign = 'assign_design',
  CompleteDesign = 'complete_design',
  SubmitDesign = 'submit_design',
  StartPrint = 'start_print',
  ConfirmDesign = 'confirm_design',
  CompleteRp = 'complete_rp',
  CompleteBraces = 'complete_braces',
  CreateInvoice = 'create_invoice',
  ConfirmShip = 'confirm_ship',
  AssignBraces = 'assign_braces',
  AssignQc = 'assign_qc',
  AssignRp = 'assign_rp',
  AssignSplit = 'assign_split',
  AssignUpload = 'assign_upload',
  CompleteQc = 'complete_qc',
  CompleteUpload = 'complete_upload',
  StartDesign = 'start_design',
  SubmitBraces = 'submit_braces',
  SubmitQc = 'submit_qc',
  SubmitRp = 'submit_rp',
  SubmitScan = 'submit_scan',
  SubmitSplit = 'submit_split'
}

export type TextMessage = Message & Node & {
  __typename?: 'TextMessage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  conversation: Conversation;
  type: MessageType;
  creator: Entity;
  content: Scalars['String'];
};

export type ToothFile = {
  __typename?: 'ToothFile';
  /** 牙位 */
  position: ToothPosition;
  /** 關聯檔案 */
  file: File;
};

export type ToothFileInput = {
  position: ToothPosition;
  file: Scalars['ID'];
};

export enum ToothPosition {
  Fdi_1_1 = 'FDI_1_1',
  Fdi_1_2 = 'FDI_1_2',
  Fdi_1_3 = 'FDI_1_3',
  Fdi_1_4 = 'FDI_1_4',
  Fdi_1_5 = 'FDI_1_5',
  Fdi_1_6 = 'FDI_1_6',
  Fdi_1_7 = 'FDI_1_7',
  Fdi_1_8 = 'FDI_1_8',
  Fdi_2_1 = 'FDI_2_1',
  Fdi_2_2 = 'FDI_2_2',
  Fdi_2_3 = 'FDI_2_3',
  Fdi_2_4 = 'FDI_2_4',
  Fdi_2_5 = 'FDI_2_5',
  Fdi_2_6 = 'FDI_2_6',
  Fdi_2_7 = 'FDI_2_7',
  Fdi_2_8 = 'FDI_2_8',
  Fdi_3_1 = 'FDI_3_1',
  Fdi_3_2 = 'FDI_3_2',
  Fdi_3_3 = 'FDI_3_3',
  Fdi_3_4 = 'FDI_3_4',
  Fdi_3_5 = 'FDI_3_5',
  Fdi_3_6 = 'FDI_3_6',
  Fdi_3_7 = 'FDI_3_7',
  Fdi_3_8 = 'FDI_3_8',
  Fdi_4_1 = 'FDI_4_1',
  Fdi_4_2 = 'FDI_4_2',
  Fdi_4_3 = 'FDI_4_3',
  Fdi_4_4 = 'FDI_4_4',
  Fdi_4_5 = 'FDI_4_5',
  Fdi_4_6 = 'FDI_4_6',
  Fdi_4_7 = 'FDI_4_7',
  Fdi_4_8 = 'FDI_4_8'
}

export type ToothStatus = {
  __typename?: 'ToothStatus';
  position: ToothPosition;
  type: ToothStatusType;
};

export type ToothStatusInput = {
  position?: Maybe<ToothPosition>;
  type?: Maybe<ToothStatusType>;
};

export enum ToothStatusType {
  Missing = 'MISSING',
  DontMove = 'DONT_MOVE',
  WillExtract = 'WILL_EXTRACT',
  DontPutAttachment = 'DONT_PUT_ATTACHMENT'
}

export enum ToothType {
  Wisdom = 'wisdom',
  Incisor = 'incisor',
  Premolar = 'premolar'
}

export type Track = Node & {
  __typename?: 'Track';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  clinic: Clinic;
  patient: Patient;
  stage: PrintStage;
  photo?: Maybe<TrackPhoto>;
  mainIssues: Array<TrackIssue>;
  extraIssue?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['Date']>;
};

export enum TrackIssue {
  ProtrudingEdge = 'PROTRUDING_EDGE',
  ToothPain = 'TOOTH_PAIN',
  JointPain = 'JOINT_PAIN',
  BraceBroken = 'BRACE_BROKEN'
}

export type TrackPhoto = {
  __typename?: 'TrackPhoto';
  withBrace?: Maybe<TrackPhotoBase>;
  withoutBrace?: Maybe<TrackPhotoBase>;
};

export type TrackPhotoBase = {
  __typename?: 'TrackPhotoBase';
  frontLoose?: Maybe<File>;
  frontTight?: Maybe<File>;
  leftTight?: Maybe<File>;
  rightTight?: Maybe<File>;
};

export type TrackPhotoBaseInput = {
  frontLoose?: Maybe<Scalars['ID']>;
  frontTight?: Maybe<Scalars['ID']>;
  leftTight?: Maybe<Scalars['ID']>;
  rightTight?: Maybe<Scalars['ID']>;
};

export type TrackPhotoInput = {
  withBrace?: Maybe<TrackPhotoBaseInput>;
  withoutBrace?: Maybe<TrackPhotoBaseInput>;
};

export type TracksQuery = {
  clinicName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['ID']>;
  patientName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  isReply?: Maybe<Scalars['Boolean']>;
};

export type TransferPatientsInput = {
  accountManager?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['ID']>;
  technician?: Maybe<Scalars['ID']>;
  customerService?: Maybe<Scalars['ID']>;
};

export type TreatArches = {
  __typename?: 'TreatArches';
  upper: Scalars['Boolean'];
  lower: Scalars['Boolean'];
};

export type TreatArchesInput = {
  upper?: Maybe<Scalars['Boolean']>;
  lower?: Maybe<Scalars['Boolean']>;
};

export enum TreatStatus {
  No = 'no',
  Treated = 'treated',
  Untreat = 'untreat'
}

export type UneruptedWisdomTooth = {
  __typename?: 'UneruptedWisdomTooth';
  hasUneruptedWisdomTooth: Scalars['Boolean'];
  instruction?: Maybe<UneruptedWisdomToothInstruction>;
};

export type UneruptedWisdomToothInput = {
  hasUneruptedWisdomTooth?: Maybe<Scalars['Boolean']>;
  instruction?: Maybe<UneruptedWisdomToothInstruction>;
};

export enum UneruptedWisdomToothInstruction {
  Extract = 'extract',
  DontDistalize = 'dontDistalize'
}

export type UpdateAccessoryStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  rpModelNumber?: Maybe<Scalars['String']>;
  rpBatchNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
};

export type UpdateAccountInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  backupEmail?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  extraPrivileges?: Maybe<Array<AllPrivilege>>;
  entity?: Maybe<Scalars['ID']>;
  notificationSetting?: Maybe<NotificationSettingInput>;
  currentAppStorage?: Maybe<Scalars['String']>;
  shouldClearStorage?: Maybe<Scalars['Boolean']>;
  lastStorageClearDate?: Maybe<Scalars['String']>;
  lastLoginClientInfo?: Maybe<Scalars['String']>;
};

export type UpdateAnalysisInput = {
  digiAnalysis?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<StepInput>>;
};

export type UpdateAppointmentInput = {
  creator?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['ID']>;
  isAttended?: Maybe<Scalars['Boolean']>;
};

export type UpdateAppointmentsInput = {
  creator?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['ID']>;
  isAttended?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdateCheckPointInput = {
  goalStep: Scalars['Int'];
  upper: Scalars['String'];
  lower: Scalars['String'];
  note: Scalars['String'];
  instruction?: Maybe<Array<AuxiliaryType>>;
};

export type UpdateClinicInput = {
  type?: Maybe<ClinicType>;
  name?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  location?: Maybe<ClinicLocationCreateInput>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  doctors?: Maybe<Array<Scalars['ID']>>;
  accountManagers?: Maybe<Array<Scalars['ID']>>;
  technicians?: Maybe<Array<Scalars['ID']>>;
  sales?: Maybe<Array<Scalars['ID']>>;
  customerService?: Maybe<Scalars['ID']>;
  specialContract?: Maybe<Scalars['Boolean']>;
  deliveryMethod?: Maybe<DeliveryMethod>;
};

export type UpdateDesignStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['Int']>;
  files?: Maybe<DesignStageFilesInput>;
  step?: Maybe<StepInput>;
  rpModelNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
};

export type UpdateDiaryInput = {
  diaryDate?: Maybe<Scalars['Date']>;
  photos?: Maybe<Array<Scalars['ID']>>;
  text?: Maybe<Scalars['String']>;
  subStage?: Maybe<Scalars['ID']>;
  wearTime?: Maybe<Scalars['Int']>;
};

export type UpdateDoctorInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  level?: Maybe<DoctorLevel>;
};

export type UpdateEmployeeBatchInput = {
  id: Scalars['ID'];
  taskOwner?: Maybe<Array<TaskType>>;
};

export type UpdateEmployeeInput = {
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  birthDate?: Maybe<Scalars['Date']>;
  isMarried?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  backupEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lineId?: Maybe<Scalars['String']>;
  onBoardDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  taskOwner?: Maybe<Array<TaskType>>;
  approvers?: Maybe<Array<Scalars['ID']>>;
  resigned?: Maybe<Scalars['Boolean']>;
};

export type UpdateEvalStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  stepsOverview?: Maybe<Scalars['ID']>;
  moldStage?: Maybe<Scalars['ID']>;
};

export type UpdateFeedbackInput = {
  category?: Maybe<FeedbackCategory>;
  screenshotList?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  status?: Maybe<FeedbackStatus>;
  entityType?: Maybe<EntityType>;
  entity?: Maybe<Scalars['ID']>;
};

export type UpdateInvoicePaymentInput = {
  payment?: Maybe<Payment>;
};

export type UpdateInvoiceWithoutStageInput = {
  discount?: Maybe<Scalars['Float']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType?: Maybe<HomeDeliveryType>;
  invoiceNumber?: Maybe<Scalars['String']>;
  middlemanFee?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  creditDate?: Maybe<Scalars['Date']>;
  shippingDate?: Maybe<Scalars['Date']>;
  payment?: Maybe<Payment>;
  products?: Maybe<Array<InvoiceProductInput>>;
  tax?: Maybe<Scalars['Boolean']>;
};

export type UpdateInvoiceWithStageInput = {
  discount?: Maybe<Scalars['Float']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType?: Maybe<HomeDeliveryType>;
  invoiceNumber?: Maybe<Scalars['String']>;
  middlemanFee?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  creditDate?: Maybe<Scalars['Date']>;
  shippingDate?: Maybe<Scalars['Date']>;
  payment?: Maybe<Payment>;
  products?: Maybe<Array<InvoiceProductInput>>;
  tax?: Maybe<Scalars['Boolean']>;
};

export type UpdateLeaveInput = {
  employee?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<LeaveType>;
  reason?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type UpdateMessage = {
  creator?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
};

export type UpdateMoldStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  moldType?: Maybe<MoldType>;
};

export type UpdateOrderInput = {
  stageOrder?: Maybe<Array<StageOrderContentInput>>;
  stageProvide?: Maybe<Array<StageProvideContentInput>>;
  type?: Maybe<OrderType>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  appointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  appointmentDate?: Maybe<Scalars['Date']>;
};

export type UpdatePatientInput = {
  payment?: Maybe<PatientPaymentInput>;
  /** 負責診所 */
  clinic?: Maybe<Scalars['ID']>;
  /** 負責醫師 */
  doctor?: Maybe<Scalars['ID']>;
  /** 負責AM */
  accountManager?: Maybe<Scalars['ID']>;
  /** 負責業務 */
  sales?: Maybe<Scalars['ID']>;
  /** 負責客服人員 */
  customerService?: Maybe<Scalars['ID']>;
  /** 負責技師 */
  technician?: Maybe<Scalars['ID']>;
  /** 下次約診日 */
  nextAppointmentDate?: Maybe<Scalars['Date']>;
  /** 姓名 */
  name?: Maybe<Scalars['String']>;
  /** 代號 (客戶模型編號) */
  patientCode?: Maybe<Scalars['String']>;
  /** 塔位 (在哪個櫃子，方便尋找用) */
  cabinetCode?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<Gender>;
  /** 主要治療牙弓 (上排, 下排) */
  treatArches?: Maybe<TreatArchesInput>;
  /** 醫師主要治療指示 */
  instruction?: Maybe<DoctorInstructionInput>;
  /** 照片（均儲存於 S3） */
  photos?: Maybe<PhotosInput>;
  /** 病患備註 */
  note?: Maybe<PatientNoteInput>;
  /** 主訴 */
  chiefComplaint?: Maybe<Scalars['String']>;
  /** 顏面觀 */
  profile?: Maybe<ProfileInput>;
  /** 口內觀 */
  intraOral?: Maybe<IntraOralInput>;
  /** 矯正症狀 */
  symptoms?: Maybe<SymptomsInput>;
  /** 醫師主要治療指示 */
  doctorInstruction?: Maybe<DoctorInstructionInput>;
};

export type UpdatePatientStatusInput = {
  status?: Maybe<Scalars['String']>;
};

export type UpdatePrintStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  serialNumber?: Maybe<Scalars['Int']>;
  rpBatchNumber?: Maybe<Scalars['String']>;
  designStage?: Maybe<Scalars['ID']>;
};

export type UpdateProductInput = {
  serialNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  spec?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
};

export type UpdateSystemEntityTypeToPrivilegesInput = {
  entityTypeToPrivilegesList?: Maybe<Array<SystemEntityTypeToPrivilegesInput>>;
};

export type UpdateSystemMaxStageCapacityInput = {
  stageCapacity: SystemMaxStageCapacityInput;
};

export type UpdateSystemRoleToPrivilegesInput = {
  roleToPrivilegesList?: Maybe<Array<SystemRoleToPrivilegesInput>>;
};

export type UpdateSystemStageCostInput = {
  stageCost: Array<StageCostPerTypeInput>;
};

export type UpdateTrackInput = {
  clinic?: Maybe<Scalars['ID']>;
  patient?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['ID']>;
  photo?: Maybe<TrackPhotoInput>;
  mainIssues?: Maybe<Array<TrackIssue>>;
  extraIssue?: Maybe<Scalars['String']>;
};

export type UpdateWearInput = {
  subStage: Scalars['ID'];
  date: Scalars['Date'];
  time: Scalars['Int'];
};


export enum VerificationType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type ViewerFilesInput = {
  teeth?: Maybe<Array<ToothFileInput>>;
  upperJawModel?: Maybe<Scalars['ID']>;
  lowerJawModel?: Maybe<Scalars['ID']>;
};

export type Wear = Node & {
  __typename?: 'Wear';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  patient: Patient;
  subStage: SubStage;
  date: Scalars['Date'];
  time: Scalars['Int'];
};

export type WorkingTaskStatistic = {
  __typename?: 'WorkingTaskStatistic';
  owner: Employee;
  taskType: TaskType;
  count: Scalars['Int'];
};

export type WorkingTaskStatisticQuery = {
  assignableEmployeeTaskType: TaskType;
  filteredTaskTypeList: Array<TaskType>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

type DiscriminateUnion<T, U> = T extends U ? T : never;

type RequireField<T, TNames extends string> = T & { [P in TNames]: (T & { [name: string]: never })[P] };

export type AnalysisPreviewAnalysisAnalysis = (NonNullable<AnalysisPreviewAnalysisFragment['analysis']>);
export type CheckPointListCheckPoints = (NonNullable<CheckPointListFragment['checkPoints'][0]>);
export type AnalysisPreviewPhotosPhotos = AnalysisPreviewPhotosFragment['photos'];
export type AnalysisPreviewPhotosFrontFace = (NonNullable<AnalysisPreviewPhotosFragment['photos']['frontFace']>);
export type AnalysisPreviewPhotosFrontFaceWithTeeth = (NonNullable<AnalysisPreviewPhotosFragment['photos']['frontFaceWithTeeth']>);
export type AnalysisPreviewPhotosSideFace45 = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFace45']>);
export type AnalysisPreviewPhotosSideFace45WithTeeth = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFace45WithTeeth']>);
export type AnalysisPreviewPhotosSideFace = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFace']>);
export type AnalysisPreviewPhotosSideFaceWithTeeth = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFaceWithTeeth']>);
export type AnalysisPreviewPhotosFrontInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['frontInside']>);
export type AnalysisPreviewPhotosLeftInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['leftInside']>);
export type AnalysisPreviewPhotosRightInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['rightInside']>);
export type AnalysisPreviewPhotosUpperJawInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['upperJawInside']>);
export type AnalysisPreviewPhotosLowerJawInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['lowerJawInside']>);
export type AnalysisPreviewPhotosPano = (NonNullable<AnalysisPreviewPhotosFragment['photos']['pano']>);
export type AnalysisPreviewPhotosCeph = (NonNullable<AnalysisPreviewPhotosFragment['photos']['ceph']>);
export type AnalysisPreviewProfilePatient = AnalysisPreviewProfileFragment['patient'];
export type AnalysisPreviewProfileClinic = AnalysisPreviewProfileFragment['patient']['clinic'];
export type AnalysisPreviewProfileDoctor = AnalysisPreviewProfileFragment['patient']['doctor'];
export type AnalysisPreviewProfilePhotos = AnalysisPreviewProfileFragment['patient']['photos'];
export type AnalysisPreviewProfileFrontFace = (NonNullable<AnalysisPreviewProfileFragment['patient']['photos']['frontFace']>);
export type StepListAnalysis = (NonNullable<StepListFragment['analysis']>);
export type StepListSteps = (NonNullable<(NonNullable<(NonNullable<StepListFragment['analysis']>)['steps']>)[0]>);
export type AnalysisPreviewPatient = AnalysisPreviewFragment['patient'];
export type ViewerHeaderCurrentEvalStage = (NonNullable<ViewerHeaderFragment['currentEvalStage']>);
export type ViewerHeaderEvalStages = (NonNullable<ViewerHeaderFragment['evalStages']>);
export type ViewerHeaderDocs = (NonNullable<(NonNullable<ViewerHeaderFragment['evalStages']>)['docs'][0]>);
export type ViewerHeaderEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ViewerHeaderFragment['evalStages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type SidebarStepsOverview = (NonNullable<SidebarFragment['stepsOverview']>);
export type DentalModelViewerCurrentEvalStage = (NonNullable<DentalModelViewerFragment['currentEvalStage']>);
export type DentalModelViewerStages = (NonNullable<DentalModelViewerFragment['stages']>);
export type DentalModelViewerDocs = (NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>);
export type DentalModelViewerMoldStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>);
export type DentalModelViewerFiles = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>);
export type DentalModelViewerTeeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['teeth']>)[0]>);
export type DentalModelViewerFile = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type DentalModelViewerUpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['upperJawModel']>);
export type DentalModelViewerLowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['lowerJawModel']>);
export type DentalModelViewerEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type DentalModelViewerCheckPoints = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>);
export type DentalModelViewer_Files = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>);
export type DentalModelViewer_Teeth = (NonNullable<(NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['teeth']>)[0]>);
export type DentalModelViewer_File = (NonNullable<(NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['teeth']>)[0]>)['file'];
export type DentalModelViewer_UpperJawModel = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['upperJawModel']>);
export type DentalModelViewer_LowerJawModel = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['lowerJawModel']>);
export type DentalModelViewerDesignStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>);
export type DentalModelViewerStep = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['step'];
export type DentalModelViewerInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['instructionCard']>);
export type DentalModelViewer__Files = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>);
export type DentalModelViewer__Teeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['teeth']>)[0]>);
export type DentalModelViewer__File = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type DentalModelViewer__UpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['upperJawModel']>);
export type DentalModelViewer__LowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['lowerJawModel']>);
export type UploadFileVariables = UploadFileMutationVariables;
export type UploadFileUploadFile = UploadFileMutation['uploadFile'];
export type EmptyInstructionCardPatient = EmptyInstructionCardFragment['patient'];
export type EmptyInstructionCardStage = EmptyInstructionCardFragment['stage'];
export type InstructionCardPreviewPatient = InstructionCardPreviewFragment['patient'];
export type InstructionPopoverStage = InstructionPopoverFragment['stage'];
export type InstructionTeethInfoTeethStatus = (NonNullable<InstructionTeethInfoFragment['teethStatus'][0]>);
export type InstructionTeethInfoAuxiliaries = (NonNullable<InstructionTeethInfoFragment['auxiliaries'][0]>);
export type PatientWithDoctorLinkDoctor = PatientWithDoctorLinkFragment['doctor'];
export type StageLinkPatient = StageLinkFragment['patient'];
export type MessageInboxItemLatestMessage = MessageInboxItemFragment['latestMessage'];
export type MessageInboxItemConversation = MessageInboxItemFragment['latestMessage']['conversation'];
export type MessageInboxItemCreator = MessageInboxItemFragment['latestMessage']['creator'];
export type MessageInboxItemEmployeeInlineFragment = (DiscriminateUnion<RequireField<MessageInboxItemFragment['latestMessage']['creator'], '__typename'>, { __typename: 'Employee' }>);
export type MessageInboxItemTextMessageInlineFragment = (DiscriminateUnion<RequireField<MessageInboxItemFragment['latestMessage'], '__typename'>, { __typename: 'TextMessage' }>);
export type MessageInboxItemPatient = MessageInboxItemFragment['patient'];
export type MessageInboxEdges = (NonNullable<MessageInboxFragment['edges'][0]>);
export type MessageInboxNode = (NonNullable<MessageInboxFragment['edges'][0]>)['node'];
export type StageNameMoldStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type StageNameEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type StageNameDesignStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type StageNamePrintStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type StageNameAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type TablePatientInfoPhotos = TablePatientInfoFragment['photos'];
export type TablePatientInfoFrontFace = (NonNullable<TablePatientInfoFragment['photos']['frontFace']>);
export type TablePatientInfoClinic = TablePatientInfoFragment['clinic'];
export type TablePatientInfoDoctor = TablePatientInfoFragment['doctor'];
export type AnalysisPreviewAnalysisFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'shippingDate'>
  & { analysis?: Maybe<(
    { __typename?: 'Analysis' }
    & Pick<Analysis, 'digiAnalysis'>
  )> }
);

export type CheckPointListFragment = (
  { __typename?: 'EvalStage' }
  & { checkPoints: Array<(
    { __typename?: 'CheckPoint' }
    & PreviewCardFragment
    & CheckPointProgressFragment
  )> }
);

export type AnalysisPreviewPhotosFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45WithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, leftInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, rightInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, upperJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, lowerJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, pano?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, ceph?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ) }
);

export type AnalysisPreviewProfileFragment = (
  { __typename?: 'EvalStage' }
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name' | 'chiefComplaint'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ), doctor: (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ), photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
      )> }
    ) }
  ) }
  & StageLink_EvalStage_Fragment
);

export type StepListFragment = (
  { __typename?: 'EvalStage' }
  & { analysis?: Maybe<(
    { __typename?: 'Analysis' }
    & { steps?: Maybe<Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'upperStep' | 'lowerStep' | 'attachment' | 'ipr' | 'button' | 'retrieve'>
    )>> }
  )> }
);

export type VersionListFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'isCPMode' | 'shippingDate'>
  & StageName_EvalStage_Fragment
);

export type AnalysisPreviewFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'isCPMode' | 'shouldShowCheckPoint'>
  & { patient: (
    { __typename?: 'Patient' }
    & AnalysisPreviewPhotosFragment
  ) }
  & AnalysisPreviewProfileFragment
  & AnalysisPreviewAnalysisFragment
  & StepListFragment
  & CheckPointListFragment
);

export type CheckPointProgressFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'serialNumber' | 'goalStep' | 'isCompleted' | 'isFinal'>
);

export type PreviewCardFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'serialNumber' | 'goalStep' | 'upper' | 'lower' | 'note' | 'isFinal' | 'instruction'>
);

export type ViewerHeaderFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status' | 'name'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  )>, evalStages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'shippingDate' | 'status'>
      & StageName_AccessoryStage_Fragment
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'hasFilesForViewer' | 'id' | 'shippingDate' | 'status'>
      & StageName_EvalStage_Fragment
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'shippingDate' | 'status'>
      & StageName_DesignStage_Fragment
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'shippingDate' | 'status'>
      & StageName_PrintStage_Fragment
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'shippingDate' | 'status'>
      & StageName_MoldStage_Fragment
    )> }
  )> }
  & TablePatientInfoFragment
);

export type SidebarFragment = (
  { __typename?: 'EvalStage' }
  & { stepsOverview?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'path'>
  )> }
);

export type DentalModelViewerFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  )>, stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'type' | 'status' | 'shippingDate'>
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'serialNumber' | 'id' | 'type' | 'status' | 'shippingDate'>
      & { checkPoints: Array<(
        { __typename?: 'CheckPoint' }
        & Pick<CheckPoint, 'goalStep' | 'serialNumber' | 'upper' | 'lower' | 'isFinal' | 'isCompleted'>
        & { files?: Maybe<(
          { __typename?: 'EvalStageFiles' }
          & { teeth?: Maybe<Array<(
            { __typename?: 'ToothFile' }
            & Pick<ToothFile, 'position'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'path'>
            ) }
          )>>, upperJawModel?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          )>, lowerJawModel?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          )> }
        )> }
      )> }
      & SidebarFragment
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'serialNumber' | 'id' | 'type' | 'status' | 'shippingDate'>
      & { step: (
        { __typename?: 'Step' }
        & Pick<Step, 'upperStep' | 'lowerStep'>
      ), instructionCard?: Maybe<(
        { __typename?: 'InstructionCard' }
        & InstructionCardPreviewFragment
      )>, files?: Maybe<(
        { __typename?: 'DesignStageFiles' }
        & { teeth?: Maybe<Array<(
          { __typename?: 'ToothFile' }
          & Pick<ToothFile, 'position'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          ) }
        )>>, upperJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )>, lowerJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )> }
      )> }
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'type' | 'status' | 'shippingDate'>
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'serialNumber' | 'id' | 'type' | 'status' | 'shippingDate'>
      & { files?: Maybe<(
        { __typename?: 'MoldStageFiles' }
        & { teeth?: Maybe<Array<(
          { __typename?: 'ToothFile' }
          & Pick<ToothFile, 'position'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          ) }
        )>>, upperJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )>, lowerJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )> }
      )> }
    )> }
  )> }
  & ViewerHeaderFragment
);

export type UploadFileMutationVariables = {
  file: Scalars['Upload'];
  options: FileUploadOption;
};


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
  ) }
);

export type FileInfoFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
);

export type EmptyInstructionCardFragment = (
  { __typename?: 'InstructionCard' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageLink_MoldStage_Fragment
  ) }
);

export type InstructionCardPreviewFragment = (
  { __typename?: 'InstructionCard' }
  & Pick<InstructionCard, 'stepCode' | 'retrieve' | 'instruction'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & EmptyInstructionCardFragment
  & InstructionTeethInfoFragment
);

export type InstructionPopoverFragment = (
  { __typename?: 'InstructionCard' }
  & Pick<InstructionCard, 'stepCode' | 'instruction'>
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & StageName_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageName_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageName_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & StageName_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageName_MoldStage_Fragment
  ) }
  & InstructionCardPreviewFragment
);

export type InstructionTeethInfoFragment = (
  { __typename?: 'InstructionCard' }
  & { teethStatus: Array<(
    { __typename?: 'ToothStatus' }
    & Pick<ToothStatus, 'position' | 'type'>
  )>, auxiliaries: Array<(
    { __typename?: 'Auxiliary' }
    & Pick<Auxiliary, 'value' | 'type' | 'toothPosition' | 'auxiliaryPosition'>
  )> }
);

export type ClinicLinkFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name'>
);

export type DoctorLinkFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name' | 'level'>
);

export type PatientLinkFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name'>
);

export type PatientWithDoctorLinkFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) }
  & PatientLinkFragment
);

type StageLink_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_AccessoryStage_Fragment
);

type StageLink_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_EvalStage_Fragment
);

type StageLink_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_DesignStage_Fragment
);

type StageLink_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_PrintStage_Fragment
);

type StageLink_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_MoldStage_Fragment
);

export type StageLinkFragment = StageLink_AccessoryStage_Fragment | StageLink_EvalStage_Fragment | StageLink_DesignStage_Fragment | StageLink_PrintStage_Fragment | StageLink_MoldStage_Fragment;

export type MessageInboxItemFragment = (
  { __typename?: 'ConversationsWithLatestMessageNode' }
  & Pick<ConversationsWithLatestMessageNode, 'id' | 'numberOfUnreadMessages'>
  & { latestMessage: (
    { __typename?: 'TextMessage' }
    & Pick<TextMessage, 'content' | 'id' | 'type' | 'created'>
    & { conversation: (
      { __typename?: 'PatientConversation' }
      & Pick<PatientConversation, 'id'>
    ), creator: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'role' | 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ImageMessage' }
    & Pick<ImageMessage, 'id' | 'type' | 'created'>
    & { conversation: (
      { __typename?: 'PatientConversation' }
      & Pick<PatientConversation, 'id'>
    ), creator: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'role' | 'id' | 'name'>
    ) }
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
);

export type MessageInboxFragment = (
  { __typename?: 'ConversationsWithLatestMessageConnection' }
  & { edges: Array<(
    { __typename?: 'ConversationsWithLatestMessageEdge' }
    & Pick<ConversationsWithLatestMessageEdge, 'cursor'>
    & { node: (
      { __typename?: 'ConversationsWithLatestMessageNode' }
      & MessageInboxItemFragment
    ) }
  )> }
);

type NotificationItem_AppointmentNotification_Fragment = (
  { __typename?: 'AppointmentNotification' }
  & Pick<AppointmentNotification, 'id' | 'type' | 'read' | 'created' | 'content'>
);

type NotificationItem_NormalNotification_Fragment = (
  { __typename?: 'NormalNotification' }
  & Pick<NormalNotification, 'id' | 'type' | 'read' | 'created' | 'content'>
);

export type NotificationItemFragment = NotificationItem_AppointmentNotification_Fragment | NotificationItem_NormalNotification_Fragment;

export type NotificationSettingFormFragment = (
  { __typename?: 'NotificationSetting' }
  & Pick<NotificationSetting, 'site' | 'mail' | 'line'>
);

type StageName_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'accessoryCode' | 'id' | 'type'>
);

type StageName_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'serialNumber' | 'isBeforePrint' | 'id' | 'type'>
);

type StageName_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'serialNumber' | 'id' | 'type'>
);

type StageName_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'serialNumber' | 'id' | 'type'>
);

type StageName_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'serialNumber' | 'id' | 'type'>
);

export type StageNameFragment = StageName_AccessoryStage_Fragment | StageName_EvalStage_Fragment | StageName_DesignStage_Fragment | StageName_PrintStage_Fragment | StageName_MoldStage_Fragment;

export type ImageCardFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'path' | 'thumbnailPath'>
);

export type TablePatientInfoFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'gender'>
  & { photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ), clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & DoctorLinkFragment
  ) }
  & PatientLinkFragment
);

import { ObjectId } from 'mongodb';
export type AccessoryStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  accessoryCode: AccessoryCode,
  instructionCard?: Maybe<InstructionCardDbObject>,
  rpBatchNumber?: Maybe<string>,
  rpModelNumber?: Maybe<string>,
  upperModelCount?: Maybe<number>,
  lowerModelCount?: Maybe<number>,
  upperAttachmentTemplateCount?: Maybe<number>,
  lowerAttachmentTemplateCount?: Maybe<number>,
  upperMouthGuardCount?: Maybe<number>,
  lowerMouthGuardCount?: Maybe<number>,
  upperBraceCount?: Maybe<number>,
  upperBraceMaterial?: Maybe<BraceMaterial>,
  upperBraceThickness?: Maybe<BraceThickness>,
  lowerBraceCount?: Maybe<number>,
  lowerBraceMaterial?: Maybe<BraceMaterial>,
  lowerBraceThickness?: Maybe<BraceThickness>,
  order?: Maybe<OrderDbObject['_id']>,
};

export type StageDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  stageType: string,
};

export type PatientDbObject = EntityDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  name: string,
  payment: PatientPaymentDbObject,
  status: PatientStatus,
  creator?: Maybe<EntityDbObject['_id']>,
  clinic: ClinicDbObject['_id'],
  doctor: DoctorDbObject['_id'],
  accountManager?: Maybe<EmployeeDbObject['_id']>,
  sales: EmployeeDbObject['_id'],
  customerService?: Maybe<EmployeeDbObject['_id']>,
  technician?: Maybe<EmployeeDbObject['_id']>,
  nextAppointmentDate?: Maybe<any>,
  currentEvalStage?: Maybe<EvalStageDbObject['_id']>,
  patientCode: string,
  cabinetCode: string,
  gender?: Maybe<Gender>,
  treatArches: TreatArchesDbObject,
  photos: PhotosDbObject,
  note: PatientNoteDbObject,
  chiefComplaint: string,
  profile: ProfileDbObject,
  intraOral: IntraOralDbObject,
  symptoms: SymptomsDbObject,
  doctorInstruction: DoctorInstructionDbObject,
  currentSubStage?: Maybe<SubStageDbObject['_id']>,
  meta: PatientMetaDbObject,
};

export type EntityDbObject = {
  _id: ObjectId,
  name: string,
  entityType: string,
};

export type NodeDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  nodeType: string,
};

export type PatientPaymentDbObject = {
  type: PatientPaymentType,
  brand: PatientBrand,
  source: PatientSource,
  patientPrice: number,
  base: number,
  referral: number,
  extension: number,
  discount: number,
};

export type ClinicDbObject = EntityDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  name: string,
  fullname: string,
  type: ClinicType,
  location: ClinicLocationDbObject,
  phone: string,
  doctors: Array<DoctorDbObject['_id']>,
  email: string,
  accountManagers: Array<EmployeeDbObject['_id']>,
  technicians: Array<EmployeeDbObject['_id']>,
  sales: Array<EmployeeDbObject['_id']>,
  customerService?: Maybe<EmployeeDbObject['_id']>,
  specialContract: boolean,
  deliveryMethod: DeliveryMethod,
};

export type ClinicLocationDbObject = {
  address: string,
  country: Country,
  district: District,
};

export type DoctorDbObject = NodeDbObject & EntityDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  name: string,
  email: string,
  phone?: Maybe<string>,
  clinics: Array<ClinicDbObject['_id']>,
  note: string,
  level: DoctorLevel,
};

export type EmployeeDbObject = EntityDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  name: string,
  title: string,
  email?: Maybe<string>,
  phone?: Maybe<string>,
  address?: Maybe<string>,
  backupEmail?: Maybe<string>,
  lineId?: Maybe<string>,
  birthDate?: Maybe<any>,
  gender?: Maybe<Gender>,
  isMarried?: Maybe<boolean>,
  onBoardDate: any,
  role: Role,
  taskOwner: Array<TaskType>,
  approvers: Array<EmployeeDbObject['_id']>,
  resigned: boolean,
};

export type OrderDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  oid: string,
  creator: EntityDbObject['_id'],
  dueDate?: Maybe<any>,
  patient: PatientDbObject['_id'],
  type: OrderType,
  stageOrder: Array<StageOrderContentDbObject>,
  stageProvide: Array<StageProvideContentDbObject>,
  isFulfilled: boolean,
  status: OrderStatus,
  isLatest: boolean,
  description: string,
  instruction: string,
  appointment?: ObjectId,
};

export type StageOrderContentDbObject = {
  stageType: StageType,
  number: number,
  items?: Maybe<Array<AccessoryCode>>,
};

export type StageProvideContentDbObject = {
  stageType: StageType,
  stages: Array<StageDbObject['_id']>,
};

export type AppointmentDbObject = ScheduleDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: ScheduleType,
  startDate: any,
  endDate: any,
  description: string,
  isAttended: boolean,
  creator: EntityDbObject['_id'],
  patient: PatientDbObject['_id'],
};

export type ScheduleDbObject = {
  _id: ObjectId,
  type: ScheduleType,
  creator: EntityDbObject['_id'],
  startDate: any,
  endDate: any,
  description: string,
  scheduleType: string,
};

export type LeaveDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  employee: EmployeeDbObject['_id'],
  startDate: any,
  endDate: any,
  type: LeaveType,
  reason: string,
  isApproved: boolean,
};

export type InvoiceDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  clinic: ClinicDbObject['_id'],
  discount: number,
  doctor: DoctorDbObject['_id'],
  homeDeliveryId: string,
  homeDeliveryType: HomeDeliveryType,
  invoiceNumber: string,
  middlemanFee: number,
  note: string,
  creditDate: any,
  shippingDate?: Maybe<any>,
  payment: Payment,
  products: Array<InvoiceProductDbObject>,
  sales: EmployeeDbObject['_id'],
  tax: boolean,
  totalPrice: number,
  shippingStatus: ShippingStatus,
  deferredRevenue: number,
  customerService?: Maybe<EmployeeDbObject['_id']>,
  invoiceType: string,
};

export type InvoiceProductDbObject = {
  productId: ObjectId,
  serialNumber: string,
  name: string,
  spec: string,
  price: number,
  cost: number,
  count: number,
};

export type EvalStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  analysis?: Maybe<AnalysisDbObject>,
  checkPoints: Array<CheckPointDbObject>,
  isBeforePrint: boolean,
  moldStage?: Maybe<MoldStageDbObject['_id']>,
  files?: Maybe<EvalStageFilesDbObject>,
  stepsOverview?: Maybe<FileDbObject['_id']>,
  initialEvalStage?: Maybe<EvalStageDbObject['_id']>,
  order?: Maybe<OrderDbObject['_id']>,
};

export type TaskDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: TaskType,
  status: TaskStatus,
  startedByEmployee?: Maybe<any>,
  completedByOwner?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  stage: StageDbObject['_id'],
  owner?: Maybe<EntityDbObject['_id']>,
  previousTask?: Maybe<TaskDbObject['_id']>,
  nextTask?: Maybe<TaskDbObject['_id']>,
};

export type AnalysisDbObject = {
  digiAnalysis?: Maybe<string>,
  steps?: Maybe<Array<StepDbObject>>,
};

export type StepDbObject = {
  upperStep: string,
  lowerStep: string,
  attachment: boolean,
  ipr: boolean,
  button: boolean,
  retrieve: boolean,
};

export type CheckPointDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  serialNumber: number,
  goalStep: number,
  upper: string,
  lower: string,
  note: string,
  instruction: Array<AuxiliaryType>,
  files?: Maybe<EvalStageFilesDbObject>,
};

export type EvalStageFilesDbObject = {
  teeth?: Maybe<Array<ToothFileDbObject>>,
  upperJawModel?: Maybe<FileDbObject['_id']>,
  lowerJawModel?: Maybe<FileDbObject['_id']>,
};

export type ToothFileDbObject = {
  position: ToothPosition,
  file: FileDbObject['_id'],
};

export type FileDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  mimeType: string,
  filename: string,
  path: string,
  thumbnailPath?: Maybe<string>,
};

export type DesignStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  step: StepDbObject,
  instructionCard?: Maybe<InstructionCardDbObject>,
  rpModelNumber?: Maybe<string>,
  upperModelCount?: Maybe<number>,
  lowerModelCount?: Maybe<number>,
  upperAttachmentTemplateCount?: Maybe<number>,
  lowerAttachmentTemplateCount?: Maybe<number>,
  upperMouthGuardCount?: Maybe<number>,
  lowerMouthGuardCount?: Maybe<number>,
  upperBraceCount?: Maybe<number>,
  upperBraceMaterial?: Maybe<BraceMaterial>,
  upperBraceThickness?: Maybe<BraceThickness>,
  lowerBraceCount?: Maybe<number>,
  lowerBraceMaterial?: Maybe<BraceMaterial>,
  lowerBraceThickness?: Maybe<BraceThickness>,
  evalStage: EvalStageDbObject['_id'],
  files?: Maybe<DesignStageFilesDbObject>,
};

export type InstructionCardDbObject = {
  instruction?: Maybe<string>,
  auxiliaries: Array<AuxiliaryDbObject>,
  teethStatus: Array<ToothStatusDbObject>,
  stage: StageDbObject['_id'],
  patient: PatientDbObject['_id'],
};

export type AuxiliaryDbObject = {
  type: AuxiliaryType,
  toothPosition: ToothPosition,
  auxiliaryPosition: AuxiliaryPosition,
  value?: Maybe<number>,
};

export type ToothStatusDbObject = {
  position: ToothPosition,
  type: ToothStatusType,
};

export type DesignStageFilesDbObject = {
  teeth?: Maybe<Array<ToothFileDbObject>>,
  upperJawModel?: Maybe<FileDbObject['_id']>,
  lowerJawModel?: Maybe<FileDbObject['_id']>,
  upperRP?: Maybe<FileDbObject['_id']>,
  lowerRP?: Maybe<FileDbObject['_id']>,
};

export type PrintStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  rpBatchNumber?: Maybe<string>,
  designStage?: Maybe<DesignStageDbObject['_id']>,
  order?: Maybe<OrderDbObject['_id']>,
};

export type SubStageDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  stage: PrintStageDbObject['_id'],
  serialNumber: number,
};

export type WearDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  patient: PatientDbObject['_id'],
  subStage: SubStageDbObject['_id'],
  date: any,
  time: number,
};

export type MoldStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  moldType: MoldType,
  files?: Maybe<MoldStageFilesDbObject>,
};

export type MoldStageFilesDbObject = {
  teeth?: Maybe<Array<ToothFileDbObject>>,
  upperJawModel?: Maybe<FileDbObject['_id']>,
  lowerJawModel?: Maybe<FileDbObject['_id']>,
};

export type TreatArchesDbObject = {
  upper: boolean,
  lower: boolean,
};

export type PhotosDbObject = {
  frontFace?: Maybe<FileDbObject['_id']>,
  frontFaceWithTeeth?: Maybe<FileDbObject['_id']>,
  sideFace45?: Maybe<FileDbObject['_id']>,
  sideFace45WithTeeth?: Maybe<FileDbObject['_id']>,
  sideFace?: Maybe<FileDbObject['_id']>,
  sideFaceWithTeeth?: Maybe<FileDbObject['_id']>,
  leftInside?: Maybe<FileDbObject['_id']>,
  frontInside?: Maybe<FileDbObject['_id']>,
  rightInside?: Maybe<FileDbObject['_id']>,
  upperJawInside?: Maybe<FileDbObject['_id']>,
  lowerJawInside?: Maybe<FileDbObject['_id']>,
  pano?: Maybe<FileDbObject['_id']>,
  ceph?: Maybe<FileDbObject['_id']>,
};

export type PatientNoteDbObject = {
  sales: string,
  design: string,
  braces: string,
};

export type ProfileDbObject = {
  mandibularMidlineToFacialMidline: MidlineDbObject,
  occulusalPlane: OcculusalPlaneDbObject,
  estheticLine: EstheticLineDbObject,
  cephAnalysis: CephAnalysis,
  faceRatio: FaceRatio,
  occulusalMuscle: OcculusalMuscle,
  hasGummySmile: boolean,
  smileLine: SmileLine,
  hasBuccalCorridor: boolean,
};

export type MidlineDbObject = {
  offset: Offset,
  instruction: Instruction,
};

export type OcculusalPlaneDbObject = {
  occulusalPlaneStatus: OcculusalPlaneStatus,
  instruction: Instruction,
};

export type EstheticLineDbObject = {
  lipsEstheticLineRelation: LipsEstheticLineRelation,
  instruction: Instruction,
};

export type IntraOralDbObject = {
  missingTeeth: Array<number>,
  teethDontMove: Array<number>,
  teethWillExtract: Array<number>,
  teethStatus: Array<ToothStatusDbObject>,
  temporomandibularJointDisordersTreatStatus: TreatStatus,
  periodontalDiseaseTreatStatus: TreatStatus,
  residualRoot: ResidualRootDbObject,
  impactedTooth: ImpactedToothDbObject,
  uneruptedWisdomTooth: UneruptedWisdomToothDbObject,
  maxillarMidlineToFacialMidline: MidlineDbObject,
  mandibularMidlineToMaxillarMidline: MidlineDbObject,
};

export type ResidualRootDbObject = {
  hasResidualRoot: boolean,
  instruction?: Maybe<ResidualRootInstruction>,
};

export type ImpactedToothDbObject = {
  hasImpactedTooth: boolean,
  instruction?: Maybe<ImpactedToothInstruction>,
};

export type UneruptedWisdomToothDbObject = {
  hasUneruptedWisdomTooth: boolean,
  instruction?: Maybe<UneruptedWisdomToothInstruction>,
};

export type SymptomsDbObject = {
  leftMolarRelationship: AnglesClassification,
  rightMolarRelationship: AnglesClassification,
  anteriorCrossbite: boolean,
  anteriorCrowding: boolean,
  bimaxillaryProtrusion: boolean,
  deepBite: boolean,
  highCanine: boolean,
  openBite: boolean,
  posteriorCrossbite: boolean,
  posteriorCrowding: boolean,
  vShapeArch: boolean,
};

export type DoctorInstructionDbObject = {
  ipr: Need,
  tads: Need,
  otherOrthdontalTools: Need,
  functionalAppliance: Need,
  maintainSpace: MaintainSpaceDbObject,
  changeProsthesisToTemp: ChangeProsthesisToTempDbObject,
  extractToothWhenNoSpace: ExtractToothWhenNoSpaceDbObject,
  note: string,
};

export type MaintainSpaceDbObject = {
  isMaintainingSpace: boolean,
  note?: Maybe<string>,
};

export type ChangeProsthesisToTempDbObject = {
  need: Need,
  instruction?: Maybe<string>,
};

export type ExtractToothWhenNoSpaceDbObject = {
  intention: Need,
  toothType?: Maybe<ToothType>,
};

export type PatientConversationDbObject = ConversationDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: ConversationType,
  members: Array<EntityDbObject['_id']>,
  readMessageRecords: Array<ReadMessageRecordDbObject>,
  latestMessage?: Maybe<MessageDbObject['_id']>,
  patient: PatientDbObject['_id'],
};

export type ConversationDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  type: ConversationType,
  members: Array<EntityDbObject['_id']>,
  readMessageRecords: Array<ReadMessageRecordDbObject>,
  latestMessage?: Maybe<MessageDbObject['_id']>,
  conversationType: string,
};

export type ReadMessageRecordDbObject = {
  member: EntityDbObject['_id'],
  lastReadMessageDate: any,
};

export type MessageDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  conversation: ConversationDbObject['_id'],
  type: MessageType,
  creator: EntityDbObject['_id'],
  messageType: string,
};

export type PatientMetaDbObject = {
  firstEvalStage?: Maybe<StageDbObject['_id']>,
  firstEvalStageAt?: Maybe<any>,
  firstPrintStage?: Maybe<StageDbObject['_id']>,
  firstPrintStageAt?: Maybe<any>,
  lastEvalStage?: Maybe<StageDbObject['_id']>,
  lastEvalStageAt?: Maybe<any>,
  lastPrintStage?: Maybe<StageDbObject['_id']>,
  lastPrintStageAt?: Maybe<any>,
  currentStepNumber: number,
  advancedStepNumber: number,
  finalStepNumber: number,
  techRevenue: number,
  techCost: number,
  techProfit: number,
  brandRevenue: number,
  risk: number,
};

export type EventDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  creator: EntityDbObject['_id'],
  type: EventType,
  data: EventDataDbObject,
};

export type EventDataDbObject = {
  patient?: Maybe<PatientDbObject['_id']>,
  order?: Maybe<OrderDbObject['_id']>,
  stage?: Maybe<StageDbObject['_id']>,
};

export type DiaryDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  patient: PatientDbObject['_id'],
  wear: WearDbObject['_id'],
  diaryDate: any,
  photos?: Maybe<Array<FileDbObject['_id']>>,
  text?: Maybe<string>,
};

export type TrackDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  clinic: ClinicDbObject['_id'],
  patient: PatientDbObject['_id'],
  stage: PrintStageDbObject['_id'],
  photo?: Maybe<TrackPhotoDbObject>,
  mainIssues: Array<TrackIssue>,
  extraIssue?: Maybe<string>,
  reply?: Maybe<string>,
  replyDate?: Maybe<any>,
};

export type TrackPhotoDbObject = {
  withBrace?: Maybe<TrackPhotoBase>,
  withoutBrace?: Maybe<TrackPhotoBase>,
};

export type TrackPhotoBaseDbObject = {
  frontLoose?: Maybe<FileDbObject['_id']>,
  frontTight?: Maybe<FileDbObject['_id']>,
  leftTight?: Maybe<FileDbObject['_id']>,
  rightTight?: Maybe<FileDbObject['_id']>,
};

export type AccountDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  email?: Maybe<string>,
  phone?: Maybe<string>,
  isEmailVerified: boolean,
  isPhoneVerified: boolean,
  password: string,
  nickname: string,
  backupEmail?: Maybe<string>,
  avatar?: Maybe<FileDbObject['_id']>,
  providers?: Maybe<ProvidersDbObject>,
  isActive: boolean,
  lastLogin?: Maybe<any>,
  entityType: EntityType,
  entity?: Maybe<EntityDbObject['_id']>,
  privileges: Array<AllPrivilege>,
  extraPrivileges?: Maybe<Array<AllPrivilege>>,
  note: string,
  notificationSetting: NotificationSetting,
  currentAppStorage?: Maybe<string>,
  shouldClearStorage: boolean,
  lastStorageClearDate?: Maybe<any>,
  lastLoginClientInfo?: Maybe<string>,
  onModel: string,
  deviceToken: {
    ios: string,
    android: string,
  },
};

export type ProvidersDbObject = {
  facebook?: Maybe<ProviderDbObject>,
  google?: Maybe<ProviderDbObject>,
  line?: Maybe<ProviderDbObject>,
  wechat?: Maybe<ProviderDbObject>,
};

export type ProviderDbObject = {
  id?: Maybe<string>,
};

export type NotificationSettingDbObject = {
  site: Array<NotificationType>,
  mail: Array<NotificationType>,
  line: Array<NotificationType>,
};

export type FeedbackDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  category: FeedbackCategory,
  title?: Maybe<string>,
  content?: Maybe<string>,
  screenshotList?: Maybe<Array<FileDbObject['_id']>>,
  status: FeedbackStatus,
  entityType: EntityType,
  entity: EntityDbObject['_id'],
};

export type NotificationDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  type: NotificationType,
  receiver: EntityDbObject['_id'],
  read: boolean,
  platform: Platform,
  content: string,
  notificationType: string,
};

export type ProductDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  serialNumber: string,
  name: string,
  price: number,
  spec: string,
  cost: number,
};

export type SystemDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  systemType: string,
};

export type InvoiceWithStageDbObject = InvoiceDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  clinic: ClinicDbObject['_id'],
  discount: number,
  doctor: DoctorDbObject['_id'],
  homeDeliveryId: string,
  homeDeliveryType: HomeDeliveryType,
  invoiceNumber: string,
  middlemanFee: number,
  note: string,
  creditDate: any,
  shippingDate?: Maybe<any>,
  payment: Payment,
  products: Array<InvoiceProductDbObject>,
  sales: EmployeeDbObject['_id'],
  tax: boolean,
  totalPrice: number,
  shippingStatus: ShippingStatus,
  deferredRevenue: number,
  customerService?: Maybe<EmployeeDbObject['_id']>,
  patient: PatientDbObject['_id'],
  stage: StageDbObject['_id'],
};

export type InvoiceWithoutStageDbObject = InvoiceDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  clinic: ClinicDbObject['_id'],
  discount: number,
  doctor: DoctorDbObject['_id'],
  homeDeliveryId: string,
  homeDeliveryType: HomeDeliveryType,
  invoiceNumber: string,
  middlemanFee: number,
  note: string,
  creditDate: any,
  shippingDate?: Maybe<any>,
  payment: Payment,
  products: Array<InvoiceProductDbObject>,
  sales: EmployeeDbObject['_id'],
  tax: boolean,
  totalPrice: number,
  shippingStatus: ShippingStatus,
  deferredRevenue: number,
  customerService?: Maybe<EmployeeDbObject['_id']>,
};

export type MinimumRequiredAppVersionSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  minimumRequiredAppVersion: string,
};

export type EntityTypeToPrivilegesListSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  entityTypeToPrivilegesList: Array<SystemEntityTypeToPrivileges>,
};

export type StageCapacitySystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  stageCapacity: MaxStageCapacity,
};

export type RoleToPrivilegesListSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  roleToPrivilegesList: Array<SystemRoleToPrivileges>,
};

export type StageCostSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  stageCost: Array<StageCostPerType>,
};

export type TextMessageDbObject = MessageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  conversation: ConversationDbObject['_id'],
  type: MessageType,
  creator: EntityDbObject['_id'],
  content: string,
};

export type ImageMessageDbObject = MessageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  conversation: ConversationDbObject['_id'],
  type: MessageType,
  creator: EntityDbObject['_id'],
  image: FileDbObject['_id'],
};

export type AppointmentNotificationDbObject = NotificationDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: NotificationType,
  receiver: EntityDbObject['_id'],
  read: boolean,
  platform: Platform,
  content: string,
  appointment: AppointmentDbObject['_id'],
};

export type NormalNotificationDbObject = NotificationDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: NotificationType,
  receiver: EntityDbObject['_id'],
  read: boolean,
  platform: Platform,
  content: string,
};
