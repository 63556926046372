import { useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import type { FormInstance } from 'antd/lib/form'
import type { Moment } from 'moment'
import { useEffect, useState } from 'react'

import { feedbacksQuery } from '../../../../graphql/feedback/query/list'
import type {
  FeedbackQuery,
  FeedbacksQueryFeedbacks,
  FeedbacksQueryQuery,
  FeedbacksQueryVariables,
} from '../../../../graphql/types'

export type FeedbackFilterType = Pick<
  FeedbackQuery,
  'category' | 'status' | 'entityType'
>

export type FeedbackSorterField = 'created'

export interface FeedbackFormInput {
  entityName?: string
  createdDateInterval?: [Moment, Moment]
}

const formInput = {
  entityName: MyStringParam,
  createdDateInterval: DateIntervalParam,
}

export function useFeedbackIndex() {
  const [source, setSource] = useState<FeedbacksQueryFeedbacks | undefined>(
    undefined,
  )

  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<FeedbackFilterType, FeedbackSorterField>({
      limit: 20,
    })
  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading } = useQuery<
    FeedbacksQueryQuery,
    FeedbacksQueryVariables
  >(feedbacksQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        entityName: formQuery.entityName,
        createdDateInterval: getDateIntervalQueryString(
          formQuery.createdDateInterval,
        ),
        ...tableQuery.filters,
      },
      ...paginateQuery,
    },
  })

  // 避免換頁或搜尋時，table 資料變為空
  useEffect(() => {
    if (data && data.feedbacks)
      setSource(data.feedbacks)
  }, [data])
  const handleSearch = (form: FormInstance<FeedbackFormInput>) => {
    handleFormChange(form.getFieldsValue())
  }

  const handleClear = (form: FormInstance<FeedbackFormInput>) => {
    form.resetFields()
    handleTableReset()
    handleFormReset()
  }

  return {
    loading,
    source,
    formQuery,
    tableFilterInfo: tableQuery.filters,
    tableSortInfo: tableQuery.sort,
    handleClear,
    handleSearch,
    handleTableChange,
  }
}

export default useFeedbackIndex
