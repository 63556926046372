import { gql } from '@apollo/client'

import { FeedbackInfoFragment } from '../fragment'

export const feedbackQuery = gql`
  query FeedbackQuery($id: ID!) {
    feedback(id: $id) {
      ...FeedbackInfo
    }
  }
  ${FeedbackInfoFragment}
`
