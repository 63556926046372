import { addIndex, map } from 'ramda'
import React from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface TabInfo {
  label: string
  url: string
}
export interface TabLinksProps {
  activeKey: number
  tabInfoList: TabInfo[]
}

export type TabLinkProps = {
  isSelected?: boolean
} & LinkProps

const mapIndexed = addIndex<TabInfo>(map)

const TabLink = styled(Link)<TabLinkProps>`
  border-bottom: ${props =>
    props.isSelected ? '2px solid #1890ff' : 'none'};
  display: inline-block;
  margin: 0 16px 0 0;
  padding: 12px 16px;
`

const Tab = styled.div<{ isSelected: boolean }>`
  border-bottom: ${props =>
    props.isSelected ? '2px solid #1890ff' : 'none'};
  color: ${props => (props.isSelected ? '#1890ff' : '')};
  display: inline-block;
  margin: 0 16px 0 0;
  padding: 12px 16px;
  cursor: pointer;
`

const TabLinksWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

function TabLinks({ activeKey, tabInfoList }: TabLinksProps) {
  return (
    <TabLinksWrapper>
      {mapIndexed(
        (tabInfo, index) => (
          <TabLink
            isSelected={activeKey === index + 1}
            key={`tab-${index + 1}`}
            to={tabInfo.url}
          >
            {tabInfo.label}
          </TabLink>
        ),
        tabInfoList,
      )}
    </TabLinksWrapper>
  )
}

interface TabListType {
  key: string
  label: string
}

function ControlledTab(props: {
  tab: string
  tabList: TabListType[]
  onChange: (key: string) => void
}) {
  const { tab, tabList, onChange } = props
  return (
    <TabLinksWrapper>
      {map(
        ({ key, label }) => (
          <Tab isSelected={key === tab} key={key} onClick={() => onChange(key)}>
            {label}
          </Tab>
        ),
        tabList,
      )}
    </TabLinksWrapper>
  )
}

export { TabLink, TabLinks, ControlledTab }
