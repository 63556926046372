import { useMutation } from '@apollo/client'
import { Button, Form, Space, Spin, message } from 'antd'
import { includes } from 'ramda'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { createDoctorMutation } from '../../../graphql/doctor/mutation/create'
import type {
  CreateDoctorMutation,
  CreateDoctorVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
} from '../../../graphql/types'
import { OnceButton } from '../../components/common/button'
import type { FormDoctorFields } from '../../components/form/doctor'
import FormDoctor from '../../components/form/doctor'
import BreadcrumbCreator from '../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../components/layout/Page'
import Title from '../../components/layout/Title'
import { useLoadingLayer } from '../../helpers/hooks'

function CreateDoctor() {
  const history = useHistory()
  const { loading, tip, setLoadingLayer } = useLoadingLayer()
  const [create] = useMutation<CreateDoctorMutation, CreateDoctorVariables>(
    createDoctorMutation,
  )
  const [form] = Form.useForm<FormDoctorFields>()

  const handleSubmit = async () => {
    try {
      const formValues = (await form.validateFields()) as FormDoctorFields
      await create({
        variables: {
          payload: formValues,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已新增醫師')
            history.push('/doctors')
          }
        },
      })
    }
    catch (error) {
      if (error?.message) {
        /** graphQL errors */
        const isDuplicatedDoctorNameError = includes(
          'duplicated doctor name',
          error.message,
        )
        if (isDuplicatedDoctorNameError)
          message.error(`醫師姓名重複，建議在醫師姓名之後加上(診所)做區隔`)
        else
          message.error(`新增醫師失敗: ${error.message}`)
      }
      else {
        /** form errors or other errors */
        console.log(error)
      }
    }
    finally {
      setLoadingLayer({ loading: false, tip: '' })
    }
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'DoctorCreate' }]}
          />
          <Title route={{ key: 'DoctorCreate' }} />
        </>
      )}
      loading={loading}
      loadingComponent={<Spin size="large" tip={tip} />}
    >
      <Section>
        <FormDoctor form={form} />
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Space>
            <OnceButton
              label="新增資料"
              onClick={handleSubmit}
              requiredPrivilege={AllPrivilege.DoctorCreate}
              type="primary"
            />
            <Button style={{ marginLeft: 24 }}>
              <Link to="/doctors">回醫師清單</Link>
            </Button>
          </Space>
        </Form.Item>
      </Section>
    </Page>
  )
}

export default CreateDoctor
