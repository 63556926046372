import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, getStageName } from '@sov/common'
import { StageName } from '@sov/ui'
import { Spin } from 'antd'
import moment from 'moment'
import {
  always,
  append,
  ascend,
  compose,
  map,
  path,
  prop,
  reduce,
  slice,
  sortWith,
  uniqBy,
  when,
} from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  ClinicReportInvoiceItemFragment,
  ReportInvoiceClinicQueryQuery,
  ReportInvoiceClinicQueryQueryVariables,
} from '../../../../graphql/types'

interface NoteTableCellProps {
  invoiceItem: ClinicReportInvoiceItemFragment
}

function SalesContact(salesList: ClinicReportInvoiceItemFragment['sales'][]) {
  return reduce(
    (str, sales) => {
      const phone = sales.phone ?? ''
      if (str === '')
        return `${sales.name}(${phone})`
      else
        return `${str}、${sales.name}(${phone})`
    },
    '',
    salesList,
  )
}
function NoteTableCell({ invoiceItem }: NoteTableCellProps) {
  const { t } = useTranslation()
  if (invoiceItem.__typename === 'InvoiceWithStage') {
    const [, affix, serialNumber] = getStageName(t, invoiceItem.stage)
    return (
      <div className="td">
        {`${slice(0, 3, invoiceItem.patient.name)
          } `
          + `${affix}${serialNumber}`}
      </div>
    )
  }
  else {
    return <div className="td" />
  }
}

const fragments = {
  ClinicReportClinicItem: gql`
    fragment ClinicReportClinicItem on Clinic {
      id
      name
      fullname
      phone
      location {
        address
      }
    }
  `,
  ClinicReportInvoiceItem: gql`
    fragment ClinicReportInvoiceItem on Invoice {
      id
      creditDate
      discount
      middlemanFee
      tax
      totalPrice
      doctor {
        id
        name
      }
      sales {
        id
        name
        phone
      }
      products {
        name
        spec
        count
        price
      }
      ... on InvoiceWithStage {
        patient {
          id
          name
        }
        stage {
          ...StageName
        }
      }
    }
    ${StageName.fragment}
  `,
}

const reportInvoiceClinicQuery = gql`
  query ReportInvoiceClinicQuery($id: ID!) {
    clinic(id: $id) {
      ...ClinicReportClinicItem
    }
  }
  ${fragments.ClinicReportClinicItem}
`

interface Props {
  clinicId: string
  creditDateInterval: string[]
  invoices: ClinicReportInvoiceItemFragment[]
}

function ClinicReport(props: Props) {
  const { clinicId, creditDateInterval, invoices } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data, loading } = useQuery<
    ReportInvoiceClinicQueryQuery,
    ReportInvoiceClinicQueryQueryVariables
  >(reportInvoiceClinicQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    fetchPolicy: 'no-cache',
    variables: {
      id: clinicId,
    },
  })

  if (loading)
    return <Spin />

  let currentDoctorId

  // 照時間排
  const invoiceItems = sortWith<ClinicReportInvoiceItemFragment>([
    ascend(path(['doctor', 'id'])),
    ascend(path(['creditDate'])),
  ])(invoices)
  const totalPrice = reduce<ClinicReportInvoiceItemFragment, number>(
    (total, item: ClinicReportInvoiceItemFragment) => total + item.totalPrice,
    0,
    invoiceItems,
  )
  const totalTax = reduce<ClinicReportInvoiceItemFragment, number>(
    (total, item: ClinicReportInvoiceItemFragment) =>
      total
      + (item.tax ? Math.round(item.totalPrice - item.totalPrice / 1.05) : 0),
    0,
    invoiceItems,
  )

  const invoiceUniqueSales = uniqBy(
    prop('id'),
    map(invoice => invoice.sales, invoices),
  )

  return (
    <div>
      <div className="title-container">
        <div>舒服美生技股份有限公司</div>
        <span className="title">應收帳款明細表</span>
      </div>
      <div className="info-container">
        <div className="clinic-container">
          <div className="clinic-name">{data?.clinic.fullname}</div>
          <div>
            電話：
            {data?.clinic.phone}
          </div>
          <div>
            地址：
            {data?.clinic.location.address}
          </div>
        </div>
        <div className="invoice-container">
          <div>
            交易區間：
            {`${moment(creditDateInterval[0]).format('YYYY-MM-DD')}~${moment(
              creditDateInterval[1],
            ).format('YYYY-MM-DD')}`}
          </div>
          <div>
            負責業務：
            {SalesContact(invoiceUniqueSales)}
          </div>
        </div>
      </div>
      <div className="profile-container">
        <div className="table">
          <div className="tr">
            <div className="td field">入帳日期</div>
            <div className="td field">貨品名稱</div>
            <div className="td field">貨品規格</div>
            <div className="td field">量</div>
            <div className="td field">銷售額</div>
            <div className="td field">小計</div>
            <div className="td field">營業稅</div>
            <div className="td field">醫生</div>
            <div className="td field">備註</div>
          </div>
          {invoiceItems
            .filter(invoiceItem => invoiceItem.totalPrice !== 0)
            .map((invoiceItem, index) => {
              // 在列表中醫生換了
              // 就要加上分隔線
              const addTopLine = currentDoctorId !== invoiceItem.doctor.id
              currentDoctorId = invoiceItem.doctor.id

              // 貨品列表中要額外
              // 加上轉介費跟折扣(這兩個是非貨品)
              const productItems = compose<
                ClinicReportInvoiceItemFragment['products'],
                ClinicReportInvoiceItemFragment['products'],
                ClinicReportInvoiceItemFragment['products']
              >(
                when(
                  always(invoiceItem.discount > 0),
                  append({ name: '折扣', price: invoiceItem.discount * -1 }),
                ),
                when(
                  always(invoiceItem.middlemanFee > 0),
                  append({ name: '轉介費', price: invoiceItem.middlemanFee }),
                ),
              )(invoiceItem.products)

              // 商品的總稅額 (不含轉介跟折扣)
              const invoiceItemTotalTax = invoiceItem.tax
                ? Math.round(
                  invoiceItem.totalPrice - invoiceItem.totalPrice / 1.05,
                )
                : 0

              return (
                <div
                  className={`tr${addTopLine ? ' addTop' : ''}`}
                  key={index}
                >
                  <div className="td">
                    {moment(invoiceItem.creditDate).format('YYYY-MM-DD')}
                  </div>
                  <div className="td">
                    {productItems.map((product, index) => (
                      <div key={index}>{product.name}</div>
                    ))}
                  </div>
                  <div className="td">
                    {productItems.map((product, index) => (
                      <div key={index}>{product.spec}</div>
                    ))}
                  </div>
                  <div className="td">
                    {productItems.map((product, index) => (
                      <div key={index}>{product.count}</div>
                    ))}
                  </div>
                  <div className="td">
                    {productItems.map((product, index) => (
                      <div key={index}>{product.price}</div>
                    ))}
                  </div>
                  <div className="td">
                    {invoiceItem.totalPrice - invoiceItemTotalTax}
                  </div>
                  <div className="td">{invoiceItemTotalTax}</div>
                  <div className="td">{invoiceItem.doctor.name}</div>
                  <NoteTableCell invoiceItem={invoiceItem} />
                </div>
              )
            })}
        </div>
      </div>
      <div className="pay-container">
        <div className="total">
          <div className="row">
            <span>本期銷售額合計：</span>
            <span>
              {totalPrice - totalTax}
              {' '}
              元整
            </span>
          </div>
          <div className="row">
            <span>本期營業稅：</span>
            <span>
              {totalTax}
              {' '}
              元整
            </span>
          </div>
          <div className="row">
            <span>本期應收帳款新台幣：</span>
            <span>
              {totalPrice}
              {' '}
              元整
            </span>
          </div>
        </div>
        <div className="info">
          <div>付款選項：</div>
          <ul>
            <li>現金支付：請電聯負責業務至診所收取款項。</li>
            <li>
              支票付款方式：抬頭「
              <b>舒服美生技股份有限公司</b>
              」，請註明禁止背書轉讓，並以回郵信封掛號寄回。
            </li>
            <li>
              匯款付款方式：匯款銀行 - 012台北富邦 /3501中山分行
              戶名：舒服美生技股份有限公司 帳號：
              <b>82120000053206</b>
              。
            </li>
          </ul>
          <div>
            <b>
              *匯款完後請將「診所、月份帳款、帳號末五碼」傳送簡訊至0953235191
            </b>
          </div>
        </div>
      </div>
      <div className="sov-container">
        <span>
          <b>舒服美生技股份有限公司</b>
        </span>
        <span>統一編號：90138076</span>
        <span>營業處：104台北市中山區民權西路19號8樓</span>
        <span>電話：02-25505042</span>
      </div>
    </div>
  )
}

ClinicReport.fragments = fragments

export { ClinicReport }
