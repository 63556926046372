import PlaceholderCeph from './assets/ceph.png'
import PlaceholderFrontFace from './assets/frontFace.png'
import PlaceholderFrontFaceWithTeeth from './assets/frontFaceWithTeeth.png'
import PlaceholderFrontInside from './assets/frontInside.png'
import PlaceholderLeftInside from './assets/leftInside.png'
import PlaceholderLowerJawInside from './assets/lowerJawInside.png'
import PlaceholderPano from './assets/pano.png'
import PlaceholderRightInside from './assets/rightInside.png'
import PlaceholderSideFace from './assets/sideFace.png'
import PlaceholderSideFace45 from './assets/sideFace45.png'
import PlaceholderSideFace45WithTeeth from './assets/sideFace45WithTeeth.png'
import PlaceholderSideFaceWithTeeth from './assets/sideFaceWithTeeth.png'
import PlaceholderUpperJawInside from './assets/upperJawInside.png'

export const extraOralPhotos = [
  {
    key: 'frontFace',
    title: '正面照',
    aspect: 1 / 1,
    placeholder: PlaceholderFrontFace,
  },
  {
    key: 'sideFace45',
    title: '45度側面照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFace45,
  },
  {
    key: 'sideFace',
    title: '側面照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFace,
  },
  {
    key: 'frontFaceWithTeeth',
    title: '正面露齒照',
    aspect: 1 / 1,
    placeholder: PlaceholderFrontFaceWithTeeth,
  },
  {
    key: 'sideFace45WithTeeth',
    title: '45度側面露齒照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFace45WithTeeth,
  },
  {
    key: 'sideFaceWithTeeth',
    title: '側面露齒照',
    aspect: 1 / 1,
    placeholder: PlaceholderSideFaceWithTeeth,
  },
]

export const intraOralPhotos = [
  {
    key: 'rightInside',
    title: '右面觀',
    aspect: 4 / 3,
    placeholder: PlaceholderRightInside,
  },
  {
    key: 'frontInside',
    title: '正面觀',
    aspect: 4 / 3,
    placeholder: PlaceholderFrontInside,
  },
  {
    key: 'leftInside',
    title: '左面觀',
    aspect: 4 / 3,
    placeholder: PlaceholderLeftInside,
  },
  {
    key: 'upperJawInside',
    title: '上顎咬合面',
    aspect: 4 / 3,
    placeholder: PlaceholderUpperJawInside,
  },
  {
    key: 'lowerJawInside',
    title: '下顎咬合面',
    aspect: 4 / 3,
    placeholder: PlaceholderLowerJawInside,
  },
]

export const xRayPhotos = [
  {
    key: 'ceph',
    title: 'Ceph 片',
    aspect: 4 / 3,
    placeholder: PlaceholderCeph,
  },
  {
    key: 'pano',
    title: 'Pano 片',
    aspect: 2 / 1,
    placeholder: PlaceholderPano,
  },
]
