import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"

        d="M4.16919 3.47156V2.24771C4.16919 2.14163 4.2986 2.08305 4.386 2.14796L11.9625 7.7226C12.2381 7.92526 12.2381 8.31632 11.9625 8.51898L4.386 14.0936C4.29692 14.1585 4.16919 14.0999 4.16919 13.9939V12.77C4.16919 12.6924 4.20784 12.618 4.27171 12.5705L10.3221 8.12158L4.27171 3.67105C4.20784 3.62355 4.16919 3.54914 4.16919 3.47156Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  )
}

const ArrowRightIcon = props => <Icon {...props} component={Svg} />

export default ArrowRightIcon
