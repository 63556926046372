/* eslint max-len: 'off' */
import React from 'react'

function Hourglass() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41104 16.7277L8.33928 11.6559L5.41106 6.58405C4.23479 4.54669 5.70513 2 8.05767 2H15.9424C18.295 2 19.7653 4.5467 18.589 6.58406L15.6608 11.6559L18.589 16.7277C19.7653 18.7651 18.295 21.3118 15.9424 21.3118H8.05765C5.70511 21.3118 4.23478 18.7651 5.41104 16.7277L7.13034 17.7204L5.41104 16.7277ZM13.3514 11.6559L16.857 5.58406C17.2635 4.88003 16.7554 4 15.9424 4H8.05767C7.24473 4 6.73664 4.88003 7.14311 5.58405L10.6487 11.6559L7.14309 17.7277C6.73663 18.4318 7.24471 19.3118 8.05765 19.3118H15.9424C16.7554 19.3118 17.2634 18.4318 16.857 17.7277L13.3514 11.6559ZM12.4333 11.25C12.2409 11.5833 11.7597 11.5833 11.5673 11.25L8.9692 6.75C8.77675 6.41667 9.01731 6 9.40221 6L14.5984 6C14.9833 6 15.2238 6.41667 15.0314 6.75L12.4333 11.25ZM15.4644 18H8.53619L9.11354 17H14.887L15.4644 18Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  )
}

export default Hourglass
