import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path

        d="M11.9224 6L11.1745 6L5.49214 6L4.74423 6C4.6794 6 4.6436 6.07451 4.68327 6.12647L8.09 10.5461C8.21384 10.7069 8.45282 10.7069 8.57667 10.5461L11.9834 6.12647C12.0231 6.07549 11.9873 6 11.9224 6Z"
        fill="#F5222D"
      />
    </svg>
  )
}

const ArrowDownIcon = props => <Icon {...props} component={Svg} />

export default ArrowDownIcon
