import { gql } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { Col, Row, Space } from 'antd'
import type { BadgeProps } from 'antd/lib/badge'
import Badge from 'antd/lib/badge'
import type { Moment } from 'moment'
import moment from 'moment'
import { cond, isEmpty } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  OrderDetailFragment,
  PrintStage,
} from '../../../../../graphql/types'
import {
  OrderStatus,
  StageType,
} from '../../../../../graphql/types'
import { Label, SectionTitle } from './components'
import CopyableText from './CopyableText'

function getFormattedDate(date?: Moment) {
  if (!date)
    return getEmptyText()

  return moment(date).format('YYYY-MM-DD')
}

function getFormattedDateTime(date?: Moment) {
  if (!date)
    return getEmptyText()

  return moment(date).format('YYYY-MM-DD HH:mm')
}

function getOrderItem(type: StageType) {
  if (type === StageType.Eval)
    return '報告'

  if (type === StageType.Print)
    return 'Step'

  return ''
}

function getOrderItemUnit(type: StageType) {
  if (type === StageType.Eval)
    return '份'

  if (type === StageType.Print)
    return '組'

  return ''
}

function getRenderedOrderItem(stageOrder: OrderDetailFragment['stageOrder']) {
  return stageOrder?.reduce((acc, cur) => {
    const orderItemUnit = getOrderItemUnit(cur.stageType)
    const orderItem = getOrderItem(cur.stageType)
    if (isEmpty(acc))
      return `${cur.number} ${orderItemUnit}${orderItem}`

    return `${acc} + ${cur.number} ${orderItemUnit}${orderItem}`
  }, '')
}

function getNewerDate(date1?: Moment, date2?: Moment) {
  if (!date1 && !date2)
    return undefined

  if (date1 && !date2)
    return date1

  if (!date1 && date2)
    return date2

  if (date1 && date2) {
    const isDate1AfterDate2 = moment(date1).isAfter(date2)
    return isDate1AfterDate2 ? moment(date1) : moment(date2)
  }
}

function getLatestShippingDate(stages: OrderDetailFragment['stageProvide'][number]['stages']) {
  const printStages = stages as PrintStage[]
  return printStages.reduce<Moment | undefined>(
    (acc, cur) => getNewerDate(acc, cur.shippingDate),
    undefined,
  )
}

interface OrderDetailProps {
  orderItem: OrderDetailFragment
}

function OrderDetail(props: OrderDetailProps) {
  const { orderItem } = props
  const {
    appointment,
    created,
    description,
    stageOrder,
    stageProvide,
    status,
  } = orderItem

  const appointmentStartDate = appointment?.startDate
  const stages = stageProvide
    .map(stageProvideInfo => stageProvideInfo.stages)
    .flat()
  const latestShippingDate = getLatestShippingDate(stages)
  const renderedDescription = isEmpty(description)
    ? getEmptyText()
    : description

  const { t } = useTranslation()
  const badgeStatus = cond<OrderStatus, BadgeProps['status']>([
    [status => status === OrderStatus.Completed, () => 'success'],
    [status => status === OrderStatus.InProgress, () => 'processing'],
    [status => status === OrderStatus.Pending, () => 'error'],
  ])(orderItem.status)

  return (
    <>
      <SectionTitle>訂單詳情</SectionTitle>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={4}>
            <Label>訂單編號:</Label>
          </Col>
          <Col span={6}>
            <CopyableText>{orderItem.displayId}</CopyableText>
          </Col>
          <Col span={6}>
            <Label>訂單建立日期:</Label>
          </Col>
          <Col span={8}>
            <div>{getFormattedDateTime(created)}</div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Label>訂單狀態:</Label>
          </Col>
          <Col span={6}>
            <Badge status={badgeStatus} />
            <span>{t(`order.status.${status}`)}</span>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Label>訂單項目:</Label>
          </Col>
          <Col span={6}>
            <div>{getRenderedOrderItem(stageOrder)}</div>
          </Col>
          <Col span={6}>
            <Label>預計約診日:</Label>
          </Col>
          <Col span={8}>
            <div>{getFormattedDateTime(appointmentStartDate)}</div>
          </Col>
        </Row>
        {status === OrderStatus.Completed && (
          <Row gutter={16}>
            <Col span={4}>
              <Label>出貨日:</Label>
            </Col>
            <Col span={8}>
              <div>{getFormattedDate(latestShippingDate)}</div>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={4}>
            <Label>備註:</Label>
          </Col>
          <Col span={20}>
            <div>{renderedDescription}</div>
          </Col>
        </Row>
      </Space>
    </>
  )
}

OrderDetail.fragments = {
  OrderDetail: gql`
    fragment OrderDetail on Order {
      id
      displayId
      status
      created
      stageOrder {
        stageType
        number
      }
      appointment {
        id
        startDate
      }
      stageProvide {
        stageType
        stages {
          id
          shippingDate
        }
      }
      description
    }
  `,
}

export default OrderDetail
