import moment from 'moment'
import type { FC } from 'react'
import React from 'react'

interface Props {
  className?: string
  patientCode: string
  serialNumber: number
  expectedShippingDate: moment.Moment
}

const GoodsCode: FC<Props> = (props) => {
  const { className, patientCode, serialNumber, expectedShippingDate } = props
  const twoDigitSerialNumber = `${serialNumber < 10 ? '0' : ''}${serialNumber}`
  return (
    <div className={className}>
      {patientCode}
      -
      {twoDigitSerialNumber}
      {' '}
      {moment(expectedShippingDate).format('YYYY-MM-DD')}
    </div>
  )
}

export default GoodsCode
