import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"

        d="M2.6665 7.83333C2.6665 7.55719 2.89036 7.33333 3.1665 7.33333H12.8332C13.1093 7.33333 13.3332 7.55719 13.3332 7.83333C13.3332 8.10948 13.1093 8.33333 12.8332 8.33333H3.1665C2.89036 8.33333 2.6665 8.10948 2.6665 7.83333Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  )
}

const HorizontalLineIcon = props => <Icon {...props} component={Svg} />

export default HorizontalLineIcon
