import { gql } from '@apollo/client'

import { DoctorInfoFragment } from '../fragment'

export const removeDoctorMutation = gql`
  mutation RemoveDoctor($id: ID!) {
    removeDoctor(id: $id) {
      ...DoctorInfo
    }
  }
  ${DoctorInfoFragment}
`
