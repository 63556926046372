import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import type { InstructionCardPreviewFragment } from '../../codegen/types'
import InstructionTeethInfo from '../InstructionTeethInfo'
import MultiLine from '../MultiLine'
import EmptyInstructionCard from './EmptyInstructionCard'

const blue = 'rgba(0, 0, 255, 0.945)'
const brown = 'rgb(165, 103, 22)'
const red = 'red'

interface StyledPreviewProps {
  baseFontSize: number
}

export const StyledPreview = styled.div<StyledPreviewProps>`
  .blue {
    color: ${blue};
    fill: ${blue};
  }
  .brown {
    color: ${brown};
    fill: ${brown};
  }
  .red {
    color: ${red};
    fill: ${red};
  }

  width: 105mm;
  height: 74.25mm;
  #instruction-image-container {
    font-family: 'Heiti TC', 'Microsoft JhengHei' !important;
    background: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .text-container {
      padding: 16px 16px 0 16px;
      flex: 1;
      .patient-info-container {
        margin: 0;
        color: purple;
        display: flex;
        div {
          line-height: 14px;
          font-size: ${props => `${props.baseFontSize + 2}px`};
          font-weight: bold;
          margin-right: 8px;
          &:nth-child(2) {
            flex: 1;
          }
        }
      }
      .info-container {
        margin: 8px 0 0 8px;
        font-size: ${props => `${props.baseFontSize}px`};
        color: black;
      }
    }
    svg.legend-container {
      max-height: 8mm;
      min-height: 8mm;
      text-align: center;
      font-size: ${props => `${props.baseFontSize}px`};
    }
    .footer {
      max-height: 8mm;
      min-height: 8mm;
    }
  }
`

/**
 * baseFontSize： 根據預覽的大小傳入字型大小的基數，再以此計算對應區塊的 font-size
 */
interface Props {
  baseFontSize?: number
  handleRemove?: () => void
  instructionItem: InstructionCardPreviewFragment
  style?: React.CSSProperties
}

// 預覽圖
function InstructionCardPreview(props: Props) {
  const { baseFontSize = 12, handleRemove, instructionItem, style } = props

  if (
    !instructionItem.instruction
    && instructionItem.auxiliaries.length === 0
  ) {
    return (
      <EmptyInstructionCard
        handleRemove={handleRemove}
        instructionItem={instructionItem}
      />
    )
  }

  const patientItem = instructionItem.patient
  const stepCode = instructionItem.stepCode
  const retrieve = instructionItem.retrieve
  const instruction = instructionItem.instruction
  return (
    <StyledPreview style={style} baseFontSize={baseFontSize}>
      <div id="instruction-image-container">
        <div className="text-container">
          <div className="patient-info-container">
            <div>
              姓名:
              {patientItem.name}
            </div>
            <div>
              Step:
              {stepCode}
            </div>
            <div className="red">
              {retrieve ? '佩戴至第三副時，請醫師重取模' : ''}
            </div>
          </div>
          <div className="info-container">
            <MultiLine text={instruction} />
          </div>
        </div>
        <InstructionTeethInfo instructionItem={instructionItem} />
        <svg
          className="legend-container"
          viewBox="-20 0 600 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <g transform="translate(0, -3)">
              <path stroke="#FFF" d="M12 1.654L3.261 23.5H20.74L12 1.654z" />
              <path fill="#1890FF" d="M12 3L20 23 4 23z" />
              <path stroke="#FFF" strokeLinecap="square" d="M12 2L12 25" />
            </g>
            <text x="25" y="15">
              Stripping
            </text>
          </g>
          <g>
            <path
              fill="#52C41A"
              fillRule="evenodd"
              stroke="#FFF"
              transform="translate(80, 0)"
              d="M11.762 1.505c-1.467.063-2.785.7-3.735 1.692C6.975 4.295 6.375 5.829 6.522
      7.5h3.215l.789 15h2.948l.791-15.031 3.23-.219c.073-1.676-.599-3.196-1.75-4.252-1.01-.925-2.39-1.496-3.983-1.493z"
            />
            <text x="105" y="15">
              TAD
            </text>
          </g>
          <g>
            <circle cx="155" cy="10" r="8" className="brown" />
            <text x="165" y="15">
              Button
            </text>
          </g>
          <g>
            <rect x="215" y="3" width="6" height="15" className="red" />
            <text x="225" y="15">
              Attachment
            </text>
          </g>
          <g>
            <path
              stroke="#000"
              strokeWidth="2"
              fill="#e5e5e5"
              d="M27.128.64C17.2-1.2 11.028.928 8.615 7.027 4.994 16.175 0 27.259 0 34.207
        0 41.153 5.558 47.72 13.564 47.72s11.586 3.563 19.551 3.563c7.966 0 22.201-8.512
        22.201-17.077 0-8.564-3.479-29.104-8.264-31.335-3.19-1.487-9.832-2.23-19.924-2.23z"
              transform="translate(315) scale(0.4)"
            />
            <text x="350" y="15">
              {`Don't Move`}
            </text>
          </g>
          <g>
            <path
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeOpacity=".25"
              d="M27.128.64C17.2-1.2 11.028.928 8.615 7.027 4.994 16.175 0 27.259 0 34.207
        0 41.153 5.558 47.72 13.564 47.72s11.586 3.563 19.551 3.563c7.966 0 22.201-8.512
        22.201-17.077 0-8.564-3.479-29.104-8.264-31.335-3.19-1.487-9.832-2.23-19.924-2.23z"
              transform="translate(430) scale(0.4)"
            />
            <text x="470" y="15" fontSize="14">
              Missing
            </text>
          </g>
        </svg>
        <div className="footer" />
      </div>
    </StyledPreview>
  )
}

InstructionCardPreview.fragments = {
  InstructionCardPreview: gql`
    fragment InstructionCardPreview on InstructionCard {
      stepCode
      retrieve
      instruction
      patient {
        id
        name
      }
      ...EmptyInstructionCard
      ...InstructionTeethInfo
    }
    ${EmptyInstructionCard.fragments.EmptyInstructionCard}
    ${InstructionTeethInfo.fragments.InstructionTeethInfo}
  `,
}

export default InstructionCardPreview
