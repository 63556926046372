export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

export const formItemWithInstructionLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  style: {
    marginBottom: '0',
  },
}

const lightGray = '#F5F5F5'
export const subFormItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
  style: {
    backgroundColor: lightGray,
  },
}

export const subFormItemWithoutBackgroundLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
  style: {
    backgroundColor: lightGray,
  },
}
