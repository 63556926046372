import { gql, useQuery } from '@apollo/client'
import {
  DateMomentParam,
  FormQuery,
  getDateIntervalQueryString,
} from '@sov/common'
import { StageName } from '@sov/ui'
import {
  Button,
  Col,
  DatePicker,
  Form,
  PageHeader,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import type { Moment } from 'moment'
import moment from 'moment'
import { includes } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import { withDefault } from 'use-query-params'

import type {
  DefectiveQueryDocs,
  DefectiveQueryQuery,
  DefectiveQueryQueryVariables,
} from '../../../graphql/types'
import {
  TaskStatus,
  TaskType,
} from '../../../graphql/types'
import PrinterModal from '../../components/common/PrinterModal'

const defectiveQuery = gql`
  query DefectiveQuery(
    $query: TasksQuery!
    $page: Int
    $limit: Int
    $sort: String
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        patient {
          id
          name
          clinic {
            id
            name
          }
        }
        stage {
          ...StageName
          id
          __typename
          note
          expectedShippingDate
          ... on AccessoryStage {
            accessoryCode
          }
        }
        startedByEmployee
        completedByOwner
      }
      total
      limit
      page
    }
  }
  ${StageName.fragment}
`

const formInput = {
  completedDate: withDefault(DateMomentParam, moment().subtract(1, 'd')),
}

const columns: ColumnProps<DefectiveQueryDocs>[] = [
  {
    title: '#',
    width: 10,
    align: 'center',
    render: (text, record, index: number) => <div>{index + 1}</div>,
  },
  {
    title: '病患',
    align: 'center',
    width: 40,
    render: (text, record) => <div>{record.patient.name}</div>,
  },
  {
    title: '診所',
    align: 'center',
    width: 60,
    render: (text, record) => <div>{record.patient.clinic.name}</div>,
  },
  {
    title: '工單',
    align: 'center',
    width: 60,
    render: (text, record) => <StageName item={record.stage} />,
  },
  {
    title: '備註',
    width: 120,
    render: (text, record) => <Note>{record.stage.note}</Note>,
  },
  {
    title: '任務開始時間',
    align: 'center',
    width: 100,
    dataIndex: 'startedByEmployee',
    render: text =>
      text && <div>{moment(text).format('YYYY-MM-DD HH:mm')}</div>,
  },
  {
    title: '任務完成時間',
    align: 'center',
    width: 100,
    dataIndex: 'completedByOwner',
    render: text =>
      text && <div>{moment(text).format('YYYY-MM-DD HH:mm')}</div>,
  },
]

const customColumnWidth = [
  { width: '5mm' },
  { width: '18mm' },
  { width: '24mm' },
  { width: '24mm' },
  { width: 'auto' },
  { width: '42mm' },
  { width: '42mm' },
]

const Content = styled(Col)`
  background-color: #fff;
  margin: 24px auto;
  padding: 24px;
`
const TitleRow = styled(Row)`
  margin-bottom: 16px;
`
const ReportName = styled.div`
  margin: 16px 0;
  font-weight: 500;
  font-size: 24px;
`
const DateRow = styled(Row)`
  margin: 14px 0 16px 24px;
`
const CompanyName = styled(Row)`
  padding-top: 24px;
`
const InfoRow = styled(Row)`
  margin-top: 24px;
  margin-right: 24px;
`
const TotalCount = styled.span`
  font-size: 16px;
  padding: 0 4px;
`
const Note = styled.div`
  word-break: break-word;
`

const limit = 500

function Defective() {
  const [visible, setVisible] = useState(false)
  const [selectedIds, setSelectIds] = useState<string[]>([])
  const { formQuery, handleFormChange } = FormQuery.useFormQuery(formInput)
  const [form] = Form.useForm()

  const completedDateInterval: [Moment, Moment] = [
    moment(formQuery.completedDate).startOf('month'),
    moment(formQuery.completedDate).endOf('month'),
  ]

  const { data, loading } = useQuery<
    DefectiveQueryQuery,
    DefectiveQueryQueryVariables
  >(defectiveQuery, {
    variables: {
      query: {
        type: [TaskType.ConfirmShip],
        status: [TaskStatus.Completed],
        completedByOwnerInterval: getDateIntervalQueryString(
          completedDateInterval,
        ),
      },
      limit,
    },
  })

  /** 僅篩選出配件單的任務 */
  const tasks
    = data?.tasks?.docs.filter(
      task => task.stage.__typename === 'AccessoryStage',
    ) ?? []
  const customColumn = columns.map((column, index) => ({
    ...column,
    ...customColumnWidth[index],
  }))
  const selectedTasks = tasks.filter(task => includes(task.id, selectedIds))

  const rowSelection = {
    columnWidth: 10,
    selectedRowKeys: selectedIds,
    onChange: val => setSelectIds(val),
  }

  const handleClick = () => setVisible(true)
  const handleCancel = () => setVisible(false)
  const handleClearSelection = () => setSelectIds([])
  const handleChangeCompletedDate = () => {
    handleFormChange(form.getFieldsValue())
  }

  return (
    <>
      <Row>
        <Content offset={2} span={20}>
          <TitleRow align="middle" justify="space-between">
            <PageHeader
              title="重壓品紀錄"
              subTitle="以月為單位做 [ 配件工單 ] 的紀錄留存"
              style={{ padding: 0 }}
            />
            <Space size="large">
              <Form.Item label="列印表號" style={{ margin: 0 }}>
                RP-023-004
              </Form.Item>
              <Button onClick={handleClearSelection}>清除選取</Button>
              <Button type="primary" onClick={handleClick}>
                預覽選取範圍
              </Button>
            </Space>
          </TitleRow>

          <Form form={form}>
            <Form.Item
              name="completedDate"
              label="篩選月份:"
              initialValue={formQuery.completedDate}
            >
              <DatePicker picker="month" onChange={handleChangeCompletedDate} />
            </Form.Item>
          </Form>

          <Table
            rowKey="id"
            size="small"
            loading={loading}
            columns={columns}
            dataSource={tasks}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Content>

        <PrinterModal visible={visible} onCancel={handleCancel}>
          <CompanyName justify="center">
            <Typography.Text strong>舒服美生技股份有限公司</Typography.Text>
          </CompanyName>
          <Row justify="center">
            <ReportName>重壓品紀錄</ReportName>
          </Row>
          <DateRow>
            篩選月份:
            {' '}
            {moment(formQuery.completedDate).format('YYYY-MM')}
          </DateRow>
          <Table
            rowKey="id"
            size="small"
            loading={loading}
            columns={customColumn}
            dataSource={selectedTasks}
            pagination={false}
          />
          <InfoRow justify="end">
            <Space size="large">
              <div>RP-023-004</div>
              <div>生效時間: 2021-01-04</div>
              <div>版次: 1</div>
            </Space>
          </InfoRow>
          <InfoRow justify="end" align="bottom">
            總共
            <TotalCount>{selectedTasks.length}</TotalCount>
            項結果
          </InfoRow>
        </PrinterModal>
      </Row>
    </>
  )
}

export default Defective
