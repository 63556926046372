import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path

        d="M4.74431 10.6667H5.49222H11.1746H11.9225C11.9873 10.6667 12.0231 10.5922 11.9835 10.5402L8.57675 6.12059C8.45291 5.95981 8.21392 5.95981 8.09008 6.12059L4.68335 10.5402C4.64368 10.5912 4.67948 10.6667 4.74431 10.6667Z"
        fill="#52C41A"
      />
    </svg>
  )
}

const ArrowUpIcon = props => <Icon {...props} component={Svg} />

export default ArrowUpIcon
