import type { AntSorterType } from '@sov/common'
import { TableQuery } from '@sov/common'
import { Button, Table } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { identity, includes, map, pathOr, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type {
  FeedbacksQueryDocs,
  FeedbacksQueryQuery,
} from '../../../graphql/types'
import {
  EntityType,
  FeedbackCategory,
  FeedbackStatus,
} from '../../../graphql/types'
import type {
  FeedbackFilterType,
  FeedbackSorterField,
} from '../index/feedback/useFeedbackIndex'

function EditButtonLink(props) {
  return (
    <Button size="small" type="primary">
      <Link to={`/feedbacks/${props.record.id}`}>編輯</Link>
    </Button>
  )
}

interface Props {
  filterInfo?: FeedbackFilterType
  handleChange?: TableProps<FeedbacksQueryDocs>['onChange']
  loading?: boolean
  sortInfo?: AntSorterType<FeedbackSorterField>
  source?: FeedbacksQueryQuery['feedbacks']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

export function FeedbackTable(props: Props) {
  const {
    filterInfo = {},
    handleChange = identity,
    loading,
    sortInfo,
    source = defaultSource,
  } = props
  const { t } = useTranslation()

  const columns: ColumnProps<FeedbacksQueryDocs>[] = [
    {
      title: '回報日',
      width: 60,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortOrder: sortInfo?.field === 'created' ? sortInfo.order : undefined,
      render: (text) => {
        return <div>{moment(text).format('YYYY-MM-DD')}</div>
      },
    },
    {
      title: '帳戶名',
      width: 40,
      dataIndex: 'entityName',
      key: 'entityName',
      render: (text, record) => {
        return <div>{record.entity?.name}</div>
      },
    },
    {
      title: '實體類型',
      width: 60,
      dataIndex: 'entityType',
      key: 'entityType',
      filters: map(
        x => ({ text: t(`account.entityType.${x}`), value: x }),
        values(EntityType),
      ),
      filterMultiple: false,
      filteredValue: filterInfo.entityType ?? [],
      onFilter: (value, record) =>
        filterInfo.entityType
          ? includes(record.entityType, filterInfo.entityType)
          : false,
      render: (text, record) => {
        const entityType = record.entityType
        return <div>{t(`account.entityType.${entityType}`)}</div>
      },
    },
    {
      title: '回饋種類',
      width: 30,
      dataIndex: 'category',
      key: 'category',
      filters: map(
        x => ({ text: t(`feedback.category.${x}`), value: x }),
        values(FeedbackCategory),
      ),
      filterMultiple: false,
      filteredValue: filterInfo.category ?? [],
      onFilter: (value, record) =>
        filterInfo.category
          ? includes(record.category, filterInfo.category)
          : false,
      render: text => <div>{t(`feedback.category.${text}`)}</div>,
    },
    {
      title: '標題',
      width: 80,
      dataIndex: 'title',
      key: 'title',
      render: (text) => {
        return <div>{text}</div>
      },
    },
    {
      title: '回饋內容',
      width: 200,
      dataIndex: 'content',
      key: 'content',
      render: (text) => {
        return (
          <div>
            {pathOr(80, ['length'], text) >= 100
              ? `${text.substr(0, 100)}...`
              : text}
          </div>
        )
      },
    },
    {
      title: '目前狀態',
      width: 60,
      dataIndex: 'status',
      key: 'status',
      filters: map(
        x => ({ text: t(`feedback.status.${x}`), value: x }),
        values(FeedbackStatus),
      ),
      filterMultiple: false,
      filteredValue: filterInfo.status ?? [],
      onFilter: (value, record) =>
        filterInfo.status ? includes(record.status, filterInfo.status) : false,
      render: text => <div>{t(`feedback.status.${text}`)}</div>,
    },
    {
      title: '行動',
      width: 40,
      key: 'actions',
      render: (text, record) => (
        <div>
          <EditButtonLink record={record} />
        </div>
      ),
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<FeedbacksQueryDocs>
      rowKey="id"
      size="small"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何回饋單' }}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
    />
  )
}

export default FeedbackTable
