import { ToothPosition } from '../../../../graphql/types'

/** 將美式牙位轉換成 FDI 牙位 */
export default (serialNumber: number): ToothPosition => {
  if (serialNumber > 32 || serialNumber < 1)
    throw new Error('無效的牙位編號')

  switch (serialNumber) {
    case 1:
      return ToothPosition.Fdi_1_8
    case 2:
      return ToothPosition.Fdi_1_7
    case 3:
      return ToothPosition.Fdi_1_6
    case 4:
      return ToothPosition.Fdi_1_5
    case 5:
      return ToothPosition.Fdi_1_4
    case 6:
      return ToothPosition.Fdi_1_3
    case 7:
      return ToothPosition.Fdi_1_2
    case 8:
      return ToothPosition.Fdi_1_1
    case 9:
      return ToothPosition.Fdi_2_1
    case 10:
      return ToothPosition.Fdi_2_2
    case 11:
      return ToothPosition.Fdi_2_3
    case 12:
      return ToothPosition.Fdi_2_4
    case 13:
      return ToothPosition.Fdi_2_5
    case 14:
      return ToothPosition.Fdi_2_6
    case 15:
      return ToothPosition.Fdi_2_7
    case 16:
      return ToothPosition.Fdi_2_8
    case 17:
      return ToothPosition.Fdi_3_8
    case 18:
      return ToothPosition.Fdi_3_7
    case 19:
      return ToothPosition.Fdi_3_6
    case 20:
      return ToothPosition.Fdi_3_5
    case 21:
      return ToothPosition.Fdi_3_4
    case 22:
      return ToothPosition.Fdi_3_3
    case 23:
      return ToothPosition.Fdi_3_2
    case 24:
      return ToothPosition.Fdi_3_1
    case 25:
      return ToothPosition.Fdi_4_1
    case 26:
      return ToothPosition.Fdi_4_2
    case 27:
      return ToothPosition.Fdi_4_3
    case 28:
      return ToothPosition.Fdi_4_4
    case 29:
      return ToothPosition.Fdi_4_5
    case 30:
      return ToothPosition.Fdi_4_6
    case 31:
      return ToothPosition.Fdi_4_7
    case 32:
      return ToothPosition.Fdi_4_8
  }

  throw new Error('找不到對應的牙位')
}
