import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useMutation } from '@apollo/client'
import { Button, Modal, message as antdMessage } from 'antd'
import {
  all,
  allPass,
  complement,
  eqProps,
  forEach,
  forEachObjIndexed,
  isEmpty,
  isNil,
  map,
  pickBy,
  prop,
} from 'ramda'
import React, { useState } from 'react'
import type { UseTranslationResponse } from 'react-i18next'

import type {
  BackwardTaskBatchMutation,
  BackwardTaskBatchVariables,
  ForwardTaskBatchMutation,
  ForwardTaskBatchVariables,
  ForwardTaskInput,
  TaskUpdateBatchFragment,
} from '../../../graphql/types'
import { EmployeeSelect } from '../form/Select'
import EmployeeLink from '../link/employee'

export const taskUpdateBatchFragment = gql`
  fragment TaskUpdateBatch on Task {
    id
    type
    status
    nextTask {
      id
      type
    }
    owner {
      id
      name
    }
  }
`

const forwardTaskBatchMutation = gql`
  mutation ForwardTaskBatch($ids: [ID!]!, $payload: ForwardTaskInput!) {
    forwardTaskBatch(ids: $ids, payload: $payload) {
      ...TaskUpdateBatch
    }
  }
  ${taskUpdateBatchFragment}
`

const backwardTaskBatchMutation = gql`
  mutation BackwardTaskBatch($ids: [ID!]!) {
    backwardTaskBatch(ids: $ids) {
      ...TaskUpdateBatch
    }
  }
  ${taskUpdateBatchFragment}
`

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

interface BaseProps {
  handleBatchButtonClick: () => void
  handleModalClose: () => void
  isModalVisible: boolean
  selectedTasks: TaskUpdateBatchFragment[]
  t: UseTranslationResponse['t']
}

export type TaskUpdateBatchProps = FormComponentProps & BaseProps

const ForwardButton = props => <Button {...props}> 批次下一步</Button>

const BackwardButton = props => <Button {...props}> 批次退回</Button>

/**
 * @todo: 改寫成 useTranslation
 * 這邊把 i18n 的 t 當作 props 傳進來，
 * 而不是直接用 useTranslation 的寫法是因為：
 * 直接使用 useTranslation 的話，
 * 在勾選表格 checkbox 時，會發生錯誤: `Rendered more hooks than during the previous render`
 * 目前還沒找到造成錯誤的原因與解決方法，所以在找到解決方法之前先暫時用這樣的寫法使其功能正常運作
 */

/** 批次更新任務的 Modal (目前僅 for RP大單， QC 相關任務) */
function TaskUpdateBatch(props: TaskUpdateBatchProps) {
  const {
    form,
    handleBatchButtonClick,
    handleModalClose,
    isModalVisible,
    selectedTasks,
    t,
  } = props
  // 選出第一個當基準，比較其他元素內容不一致則無法批次處理
  if (isEmpty(selectedTasks))
    return null

  const headTaskItem = selectedTasks[0]
  const validPredicate = allPass([
    eqProps('type', headTaskItem),
    eqProps('status', headTaskItem),
    eqProps('owner', headTaskItem),
  ])
  const isValid = all(validPredicate, selectedTasks)

  const ownerItem = headTaskItem.owner
  const nextTaskType = headTaskItem.nextTask ? headTaskItem.nextTask.type : ''

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const selectedTaskIds = map(prop('id'), selectedTasks)

  const [forwardTaskBatch] = useMutation<
    ForwardTaskBatchMutation,
    ForwardTaskBatchVariables
  >(forwardTaskBatchMutation)
  const [backwardTaskBatch] = useMutation<
    BackwardTaskBatchMutation,
    BackwardTaskBatchVariables
  >(backwardTaskBatchMutation)

  const handleForwardBatch = async () => {
    setIsButtonLoading(true)
    form.validateFieldsAndScroll(async (err) => {
      if (err) {
        let message = ''
        forEachObjIndexed(
          val =>
            forEach<any>((x) => {
              message = message + x.message
            }, val.error),
          err,
        )
        antdMessage.error(message)
      }
      else {
        const payload: ForwardTaskInput = pickBy(complement(isNil))(
          form.getFieldsValue(),
        )

        await forwardTaskBatch({
          variables: {
            ids: selectedTaskIds,
            payload,
          },
        })
        handleBatchButtonClick()
      }
      setIsButtonLoading(false)
    })
  }

  const handleBackwardBatch = async () => {
    setIsButtonLoading(true)
    await backwardTaskBatch({
      variables: {
        ids: selectedTaskIds,
      },
    })
    setIsButtonLoading(false)
    handleBatchButtonClick()
  }

  const Footer = () =>
    isValid
      ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackwardButton
            loading={isButtonLoading}
            onClick={handleBackwardBatch}
            danger
          />
          <ForwardButton
            loading={isButtonLoading}
            onClick={handleForwardBatch}
            type="primary"
          />
        </div>
        )
      : (
        <div style={{ color: 'red' }}>
          由於內容不同，故無法批次處理 (請檢查負責人，任務狀態)
        </div>
        )

  const { getFieldDecorator } = form
  const taskTypeTranslation = headTaskItem.type
    ? t(`task.type.${headTaskItem.type}`)
    : ''
  const taskStatusTranslation = headTaskItem.status
    ? t(`task.status.${headTaskItem.status}`)
    : ''

  return (
    <Modal
      footer={<Footer />}
      onCancel={handleModalClose}
      title={`已選擇: ${selectedTasks.length} 個任務`}
      visible={isModalVisible}
      width={760}
    >
      <Form>
        <FormItem {...formItemLayout} label="任務">
          {taskTypeTranslation}
          (
          {taskStatusTranslation}
          )
        </FormItem>
        {!isEmpty(ownerItem) && ownerItem?.__typename === 'Employee' && (
          <FormItem {...formItemLayout} label="負責人">
            <EmployeeLink item={ownerItem} />
          </FormItem>
        )}
        <FormItem {...formItemLayout} label="下一個任務">
          {nextTaskType !== '' ? t(`task.type.${nextTaskType}`) : '完成工單'}
        </FormItem>
        {nextTaskType !== '' && (
          <FormItem {...formItemLayout} label="指派負責人">
            {getFieldDecorator('nextOwner', {
              rules: [{ required: true, message: '請輸入負責人' }],
            })(
              <EmployeeSelect
                query={{
                  taskOwner: [nextTaskType],
                }}
                showSearch
              />,
            )}
          </FormItem>
        )}
      </Form>
    </Modal>
  )
}

export default Form.create<TaskUpdateBatchProps>()(TaskUpdateBatch)
