import { gql } from '@apollo/client'

import { ProductInfoFragment } from '../fragment'

export const productsQuery = gql`
  query ProductsQuery(
    $query: ProductsQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    products(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...ProductInfo
      }
      total
      limit
      page
    }
  }
  ${ProductInfoFragment}
`
