import { gql, useMutation } from '@apollo/client'
import { Button, Card, Form, Select, Space, message } from 'antd'
import type { SelectProps } from 'antd/lib/select'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  RoleToPrivilegesListSystem,
  UpdateSystemRoleToPrivilegesListMutation,
  UpdateSystemRoleToPrivilegesListMutationVariables,
} from '../../../../graphql/types'
import {
  AllPrivilege,
  SystemConfigType,
} from '../../../../graphql/types'

const updateSystemRoleToPrivilegesListMutation = gql`
  mutation UpdateSystemRoleToPrivilegesList(
    $configType: SystemConfigType!
    $payload: UpdateSystemRoleToPrivilegesInput!
  ) {
    updateSystemRoleToPrivilegesList(
      configType: $configType
      payload: $payload
    ) {
      id
    }
  }
`

interface SystemRoleToPrivilegesListFormCardProps {
  initialValues: RoleToPrivilegesListSystem
}

function SystemRoleToPrivilegesListFormCard(props: SystemRoleToPrivilegesListFormCardProps) {
  const { initialValues } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [updateSystemRoleToPrivilegesList] = useMutation<
    UpdateSystemRoleToPrivilegesListMutation,
    UpdateSystemRoleToPrivilegesListMutationVariables
  >(updateSystemRoleToPrivilegesListMutation)

  const handleUpdateSystemRoleToPrivilegesListClick = async () => {
    try {
      const fieldsValue
        = (await form.validateFields()) as UpdateSystemRoleToPrivilegesListMutationVariables['payload']
      await updateSystemRoleToPrivilegesList({
        variables: {
          configType: SystemConfigType.RoleToPrivileges,
          payload: fieldsValue,
        },
        update: async () => {
          await message.info('已更新系統設定中的員工角色預設權限')
        },
      })
    }
    catch (e) {
      if (e?.message) {
        /** graphQL errors */
        message.error(e?.message)
      }
      else {
        /** form errors or other errors */
        message.error(e.toString())
      }
    }
  }

  const selectFilterOption: SelectProps<AllPrivilege>['filterOption'] = (
    input,
    option,
  ) => {
    const label = option?.props.children
    return label.includes(input)
  }

  return (
    <Card
      title="員工角色預設權限"
      size="small"
      extra={(
        <Button danger onClick={handleUpdateSystemRoleToPrivilegesListClick}>
          更新
        </Button>
      )}
    >
      <Form form={form} initialValues={initialValues}>
        {initialValues.roleToPrivilegesList.map((roleToPrivileges, index) => (
          <div key={`system-config-roleToPrivileges-${index}`}>
            <Space size={8}>
              <Form.Item
                label="角色"
                name={['roleToPrivilegesList', index, 'role']}
              >
                <div>{t(`role.${roleToPrivileges.role}`)}</div>
              </Form.Item>
            </Space>
            <Form.Item
              label="權限"
              name={['roleToPrivilegesList', index, 'privileges']}
            >
              <Select
                filterOption={selectFilterOption}
                optionFilterProp="children"
                mode="multiple"
              >
                {Object.values(AllPrivilege).map(privilege => (
                  <Select.Option
                    key={`system-config-${roleToPrivileges.role}-selecting-${privilege}`}
                    value={privilege}
                  >
                    {t(`account.privileges.${privilege}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        ))}
      </Form>
    </Card>
  )
}

export default SystemRoleToPrivilegesListFormCard
