import CheckPointProgress from './CheckPointProgress'
import InstructionTag from './InstructionTag'
import PreviewCard from './PreviewCard'

const CheckPoint = {
  PreviewCard,
  InstructionTag,
  CheckPointProgress,
}

export default CheckPoint
