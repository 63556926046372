import { useEffect, useRef } from 'react'

export type Delay = number | null

/**
 * 這個 hook 實作參考 Dan Abramov 文章：Making setInterval Declarative with React Hooks
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
function useInterval(callback, delay: Delay) {
  const savedCallback = useRef()

  /** Remember the latest callback */
  useEffect(() => {
    savedCallback.current = callback
  })

  /** Set up the interval */
  useEffect(() => {
    function tick() {
      /**
       * @todo: useRef 標上正確的 type
       * useRef 預設的 type 可能是 undefined 無法對其取值
       * 在找到解法前暫時先跳過 type 檢查
       */
      // @ts-ignore
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return undefined
  }, [delay])
}

export { useInterval }
