import { gql } from '@apollo/client'

import { CommentInfoFragment } from '../fragment'

export const onMessageAddedSubscription = gql`
  subscription onMessageAddedSubscription($payload: SubscriptionPayload!) {
    onMessageAdded(payload: $payload) {
      cursor
      node {
        ...messageInfo
      }
    }
  }
  ${CommentInfoFragment}
`
