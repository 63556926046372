import { gql } from '@apollo/client'
import { StageName } from '@sov/ui'
import { holidayUtils } from '@sov/utils'
import { DatePicker, Form, Modal, message } from 'antd'
import type { Rule } from 'antd/lib/form'
import type { Moment } from 'moment'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'

import type {
  ExpectedShippingDateModalFragment,
  UpdateAccessoryStageInput,
  UpdateDesignStageInput,
  UpdateEvalStageInput,
  UpdateMoldStageInput,
  UpdatePrintStageInput,
} from '../../../../graphql/types'
import {
  StageType,
} from '../../../../graphql/types'
import StageCapacityChart from '../../chart/StageCapacityChart'
import { useUpdateStage } from './useUpdateStage'

type UpdateStageExpectedShippingDateInput =
  | Pick<UpdateAccessoryStageInput, 'expectedShippingDate'>
  | Pick<UpdateDesignStageInput, 'expectedShippingDate'>
  | Pick<UpdateEvalStageInput, 'expectedShippingDate'>
  | Pick<UpdateMoldStageInput, 'expectedShippingDate'>
  | Pick<UpdatePrintStageInput, 'expectedShippingDate'>

const CustomizedFormItem = styled(Form.Item)`
  margin: 0;
`

interface ExpectedShippingDateModalProps {
  handleExpectedShippingDateUpdated: () => void
  handleModalClose: () => void
  isVisible: boolean
  selectedStage: ExpectedShippingDateModalFragment
}

function ExpectedShippingDateModal(props: ExpectedShippingDateModalProps) {
  const {
    handleExpectedShippingDateUpdated,
    handleModalClose,
    isVisible,
    selectedStage,
  } = props

  const stageId = selectedStage.id
  const stageType = selectedStage.type
  const [form] = Form.useForm()
  const [updateStage] = useUpdateStage(stageType)

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [noCapacityDates, setNoCapacityDates] = useState<Moment[]>([])

  const defaultExpectedShippingDate = selectedStage.expectedShippingDate
    ? moment(selectedStage.expectedShippingDate)
    : null

  const initialValues = {
    expectedShippingDate: defaultExpectedShippingDate,
  }

  const handleOk = async () => {
    const messageKey = 'update-expected-shipping-date-loading'
    try {
      const fieldsValue = await form.validateFields()
      const payload = fieldsValue as UpdateStageExpectedShippingDateInput
      message.loading({ content: '更新中', key: messageKey })
      setIsButtonLoading(true)
      const variables = {
        id: stageId,
        payload,
      }

      await updateStage({
        variables,
        update: (_, response) => {
          if (response.data) {
            message.info(`成功更新工單的預計出貨`)
            handleModalClose()
            handleExpectedShippingDateUpdated()
          }
        },
      })
    }
    catch (error) {
      if (error?.message) {
        /** graphQL errors */
        message.error(error.message)
      }
      else {
        /** form errors or other errors */
        console.log(error)
      }
    }
    finally {
      message.destroy(messageKey)
      setIsButtonLoading(false)
    }
  }

  const requiredRule: Rule = {
    required: stageType !== StageType.Mold,
  }

  return (
    <Modal
      closable={false}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleModalClose}
      okButtonProps={{ disabled: isButtonLoading }}
      cancelButtonProps={{ disabled: isButtonLoading }}
      visible={isVisible}
    >
      {(stageType === StageType.Eval || stageType === StageType.Print) && (
        <StageCapacityChart
          stageType={stageType}
          setNoCapacityDates={setNoCapacityDates}
        />
      )}
      <Form form={form} initialValues={initialValues}>
        <CustomizedFormItem label="診所">
          <div>{selectedStage.patient.clinic.name}</div>
        </CustomizedFormItem>
        <CustomizedFormItem label="病患">
          <div>{selectedStage.patient.name}</div>
        </CustomizedFormItem>
        <CustomizedFormItem label="工單">
          <StageName item={selectedStage} />
        </CustomizedFormItem>
        <CustomizedFormItem
          name="expectedShippingDate"
          rules={[requiredRule]}
          label="預計出貨"
        >
          <DatePicker
            format="YYYY-MM-DD"
            allowClear={stageType === StageType.Mold}
            disabledDate={date =>
              noCapacityDates.filter(x => x.isSame(date, 'd')).length > 0
              || date.isBefore(moment(), 'd')
              || (holidayUtils.isHoliday(date)
              && !holidayUtils.isForceBusinessDay(date))}
          />
        </CustomizedFormItem>
      </Form>
    </Modal>
  )
}

ExpectedShippingDateModal.fragments = {
  ExpectedShippingDateModal: gql`
    fragment ExpectedShippingDateModal on Stage {
      id
      ...StageName
      expectedShippingDate
      type
      patient {
        id
        name
        clinic {
          id
          name
        }
      }
    }
    ${StageName.fragment}
  `,
}

export default ExpectedShippingDateModal
