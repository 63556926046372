import produce from 'immer'
import { all, equals, isNil } from 'ramda'
import { useEffect, useState } from 'react'

import type { DentalModelViewerDocs } from '../../codegen/types'
import type { MeshColor } from './constant/meshColor'
import { STLLoader } from './STLLoader'
import { getCenteredMeshes, initializeItems } from './utils'
import type { Mode, ViewerItem } from '.'

function getPercent(total: number, loaded: number) {
  return total > 0 ? Math.round((loaded / total) * 100) : 0
}

interface UseViewerItemsArgs {
  mode: Mode
  stages: DentalModelViewerDocs[]
  currentEvalStageId?: string
}

function useViewerItems(args: UseViewerItemsArgs) {
  const { mode, stages, currentEvalStageId } = args

  const initialValue = initializeItems(mode, stages, currentEvalStageId)
  const [viewerItems, setViewerItems] = useState<ViewerItem[]>(initialValue)

  useEffect(() => {
    viewerItems.map((item, itemIndex) => {
      const totalMeshCount = item.meshes.length

      /** 對 item 的每個 mesh 都新增 STLLoader instance 以載入模型檔 */
      item.meshes.map((mesh, meshIndex) => {
        const stlLoader = new STLLoader()
        /** mesh 完成載入後，將 geometry 寫入 state */
        stlLoader.load(mesh.url, (geometry) => {
          setViewerItems(viewerItems =>
            produce(viewerItems, (draft) => {
              const loadedMeshCount = draft[itemIndex].meshes.filter(
                mesh => !isNil(mesh.geometry),
              ).length
              draft[itemIndex].percent = getPercent(
                totalMeshCount,
                loadedMeshCount + 1,
              )
              draft[itemIndex].meshes[meshIndex].geometry = geometry
            }),
          )
        })
      })
    })
  }, [mode])

  const percentList = viewerItems.map(item => item.percent ?? 0)
  useEffect(() => {
    /** 所有 viewerItem 都完成載入後，進行置中 */
    if (all(equals(100), percentList)) {
      setViewerItems(viewerItems =>
        produce(viewerItems, (draft) => {
          draft.forEach((item, itemIndex) => {
            /** item 的 meshes 都替換為已置中的 meshes */
            item.meshes = getCenteredMeshes(draft[itemIndex].meshes)
          })
        }),
      )
    }
  }, percentList)

  const handleChangeColor = (id: ViewerItem['id'], color: MeshColor) => {
    setViewerItems(viewerItems =>
      produce(viewerItems, (draftItem) => {
        const itemIndex = viewerItems.findIndex(item => item.id === id)
        if (itemIndex >= 0)
          draftItem[itemIndex].color = color
      }),
    )
  }

  const handleUpdateViewerItems = (mode: Mode) => {
    setViewerItems(initializeItems(mode, stages, currentEvalStageId))
  }

  return {
    viewerItems,
    handleChangeColor,
    handleUpdateViewerItems,
  }
}

export default useViewerItems
