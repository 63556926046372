import type { ButtonProps } from 'antd/lib/button'
import React from 'react'

import { AllPrivilege } from '../../../../../graphql/types'
import type {
  ConfirmButtonProps,
} from '../../../../components/common/button'
import {
  ConfirmButtonDropdownMenu,
  OnceButton,
} from '../../../../components/common/button'

function UpdateButton(props: ButtonProps) {
  return <OnceButton {...props} label="更新資料" />
}

function RemoveButton({ handleRemove }) {
  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除工單',
      requiredPrivilege: AllPrivilege.StageDelete,
    },
  ]

  return (
    <ConfirmButtonDropdownMenu
      confirmButtonDropdownMenuItemsProps={confirmButtonDropdownMenuItemsProps}
    />
  )
}

export { RemoveButton, UpdateButton }
