import { gql, useQuery } from '@apollo/client'
import {
  DateIntervalParam,
  ErrorHandling,
  FormQuery,
  MyStringParam,
  TableQuery,
} from '@sov/common'
import { Button, Form, Row } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import type { Moment } from 'moment'
import { isNil } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import type {
  StageListQuery,
  StageListQueryVariables,
  StagesQuery,
} from '../../../../graphql/types'
import RangePicker from '../../../components/common/RangePicker'
import { ClinicSelect, PatientSelect } from '../../../components/form/Select'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import StageTable from '../../../components/table/Stage'
import { useInstructionRemove } from '../../../helpers/hooks/useInstruction'

const stageListQuery = gql`
  query StageList($query: StagesQuery, $page: Int, $limit: Int, $sort: String) {
    stages(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...StageTable
      }
      total
      limit
      page
    }
  }
  ${StageTable.fragments.StageTable}
`

const CustomizedForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
`

interface SearchFormQuery {
  patient?: string
  clinic?: string
  expectedShippingDateInterval?: [Moment, Moment]
}

type FormQueryType = SearchFormQuery

export type StageFilterType = Pick<StagesQuery, 'type' | 'status'>

export type StageSorterField = 'expectedShippingDate' | 'shippingDate'

interface StageFormProps {
  form: FormInstance<FormQueryType>
  formQuery: FormQueryType
  handleSearch: (formValues: FormQueryType) => void
  handleReset: () => void
}

function StageForm(props: StageFormProps) {
  const { form, formQuery, handleSearch, handleReset } = props

  const getChangeHandler = (field: keyof FormQueryType) => (value) => {
    handleSearch({
      ...formQuery,
      [field]: value,
    })
  }

  const handleClickReset = () => {
    handleReset()
    form.resetFields()
  }

  return (
    <CustomizedForm layout="inline" initialValues={formQuery}>
      <Row>
        <Form.Item label="診所" name="clinic">
          <ClinicSelect
            disabled={!isNil(formQuery.patient)}
            onChange={getChangeHandler('clinic')}
            allowClear
          />
        </Form.Item>
        <Form.Item label="姓名" name="patient">
          <PatientSelect
            query={{ clinic: formQuery.clinic }}
            onChange={getChangeHandler('patient')}
            allowClear
          />
        </Form.Item>
        <Form.Item label="預計出貨" name="expectedShippingDateInterval">
          <RangePicker
            onChange={getChangeHandler('expectedShippingDateInterval')}
          />
        </Form.Item>
      </Row>
      <div>
        <Form.Item>
          <Button onClick={handleClickReset}>重置</Button>
        </Form.Item>
      </div>
    </CustomizedForm>
  )
}

const formInput = {
  patient: MyStringParam,
  clinic: MyStringParam,
  expectedShippingDateInterval: DateIntervalParam,
}

export function StageIndex() {
  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const [form] = Form.useForm()

  const { formQuery, handleFormChange, handleFormReset }
    = FormQuery.useFormQuery(formInput)
  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<StageFilterType, StageSorterField>({
      limit: 60,
      sort: '-expectedShippingDate',
    })

  const { data, refetch, loading } = useQuery<
    StageListQuery,
    StageListQueryVariables
  >(stageListQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        type: tableQuery.filters?.type,
        status: tableQuery.filters?.status,
        patient: formQuery.patient,
        clinic: formQuery.clinic,
        expectedShippingDateInterval: formQuery.expectedShippingDateInterval,
      },
      ...paginateQuery,
    },
  })
  const { removeStageInstructionCard } = useInstructionRemove({
    onRemoved: refetch,
  })

  const stages = data?.stages

  const handleSearch = (formValues: FormQueryType) => {
    handleFormChange(formValues, 'pushIn')
  }

  const handleReset = () => {
    handleFormReset()
    handleTableReset()
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator routes={[{ key: 'Home' }, { key: 'StageList' }]} />
          <Title route={{ key: 'StageList' }} />
        </>
      )}
    >
      <Section>
        <StageForm
          form={form}
          formQuery={formQuery}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <StageTable
          source={stages}
          loading={loading}
          filterInfo={tableQuery.filters}
          sortInfo={tableQuery.sort}
          handleTableChange={handleTableChange}
          handleRemoveStageInstruction={removeStageInstructionCard}
          refetch={refetch}
        />
      </Section>
    </Page>
  )
}

export default StageIndex
