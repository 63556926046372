import React from 'react'

interface Props {
  value: number | string
}

function Currency(props: Props) {
  const { value } = props
  const parsedValue
    = typeof value === 'string' ? Number.parseFloat(value) : value

  return (
    <span style={{ color: parsedValue < 0 ? 'red' : 'initial' }}>
      {new Intl.NumberFormat().format(parsedValue)}
    </span>
  )
}

export { Currency }
