import { gql } from '@apollo/client'

import { EmployeeInfoFragment } from '../fragment'

export const employeesQuery = gql`
  query EmployeesQuery(
    $query: EmployeesQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    employees(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...EmployeeInfo
      }
      total
      limit
      page
    }
  }
  ${EmployeeInfoFragment}
`
