import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, Spin, message } from 'antd'
import type { GraphQLError } from 'graphql'
import { dissoc, includes } from 'ramda'
import React, { useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { removeProductMutation } from '../../../graphql/product/mutation/remove'
import { updateProductMutation } from '../../../graphql/product/mutation/update'
import { productQuery } from '../../../graphql/product/query/item'
import { productsQuery } from '../../../graphql/product/query/list'
import type {
  ProductQueryQuery,
  ProductQueryVariables,
  RemoveProductMutation,
  RemoveProductVariables,
  UpdateProductInput,
  UpdateProductMutation,
  UpdateProductVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
} from '../../../graphql/types'
import { useLoadingLayer } from '../../helpers/hooks'
import type {
  ConfirmButtonProps,
} from '../common/button'
import {
  ConfirmButtonDropdownMenu,
  OnceButton,
} from '../common/button'
import FormProduct from '../form/product'
import Page, { Section } from '../layout/Page'
import ProductMenu from '../pageHeader/product'

const FormItem = Form.Item

const BackLink = () => <Link to="/products">回出貨品項清單</Link>

type Props = FormComponentProps<UpdateProductInput>

interface RouteProps {
  productId: string
}

function ProductDetail(props: Props) {
  const { form } = props
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const productId = match.params.productId
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading: queryLoading } = useQuery<
    ProductQueryQuery,
    ProductQueryVariables
  >(productQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onCompleted: (data) => {
      if (!data?.product) {
        toErrorPage({
          message: '不存在的出貨品項',
          redirect: {
            name: '出貨品項總覽',
            url: '/products',
          },
        })
      }
    },
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '出貨品項總覽',
          url: '/products',
        },
      })
    },
    variables: {
      id: productId,
    },
  })
  const [update] = useMutation<UpdateProductMutation, UpdateProductVariables>(
    updateProductMutation,
  )
  const [remove] = useMutation<RemoveProductMutation, RemoveProductVariables>(
    removeProductMutation,
  )

  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  const handleSubmit = async () => {
    const formValue = form.getFieldsValue()
    setLoadingLayer({ loading: true, tip: '更新中...' })
    try {
      await update({
        variables: {
          id: productId,
          payload: dissoc('id', formValue),
        },
        update: (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data)
            message.info('已更新出貨品項')
        },
      })
    }
    catch (error) {
      setLoadingLayer({ loading: false, tip: '' })
      const e = error as GraphQLError
      const isDuplicatedProductSerialNumberError = includes(
        '已有相同編號的商品',
        e.message,
      )
      const hintMessage = isDuplicatedProductSerialNumberError
        ? '品項編號重複，請填寫其他數字'
        : `更新出貨品項失敗: ${e.message}`
      message.error(hintMessage)
    }
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })
    try {
      await remove({
        variables: {
          id: productId,
        },
        refetchQueries: [
          {
            query: productsQuery,
            variables: {
              query: {},
              page: 1,
              limit: 100,
              sort: 'serialNumber',
            },
          },
        ],
        update: async (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data) {
            message.info('已刪除出貨品項')
            history.push('/products')
          }
        },
      })
    }
    catch (error) {
      setLoadingLayer({ loading: false, tip: '' })
      const e = error as GraphQLError
      const hintMessage = `刪除出貨品項失敗: ${e.message}`
      message.error(hintMessage)
    }
  }

  if (queryLoading)
    return <Page loading />

  if (!data?.product)
    return null

  const product = data.product

  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除出貨品項',
      requiredPrivilege: AllPrivilege.ProductDelete,
    },
  ]

  return (
    <Page
      header={(
        <>
          <ProductMenu item={data.product.id} />
        </>
      )}
      loading={loading}
      loadingComponent={<Spin size="large" tip={tip} />}
    >
      <Section>
        {product && (
          <Form>
            <FormProduct form={form} item={product} />
            <FormItem
              wrapperCol={{ span: 16, offset: 6 }}
              style={{ marginTop: 24 }}
            >
              <OnceButton
                label="更新資料"
                onClick={handleSubmit}
                requiredPrivilege={AllPrivilege.ProductUpdate}
                style={{ marginRight: 24 }}
                type="primary"
              />
              <Button style={{ marginRight: 24 }}>
                <BackLink />
              </Button>
              <ConfirmButtonDropdownMenu
                confirmButtonDropdownMenuItemsProps={
                  confirmButtonDropdownMenuItemsProps
                }
              />
            </FormItem>
          </Form>
        )}
      </Section>
    </Page>
  )
}

export { ProductDetail }

export default Form.create<Props>()(ProductDetail)
