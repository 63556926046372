import { gql } from '@apollo/client'

import { LeaveInfoFragment } from '../fragment'

export const leavesForCalendarQuery = gql`
  query LeavesForCalendarQuery($startDate: Date!) {
    leavesForCalendar(startDate: $startDate) {
      ...LeaveInfo
    }
  }
  ${LeaveInfoFragment}
`
