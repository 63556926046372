import { exhaustiveCheck } from '@sov/common'

import { NotificationCategory } from '../../codegen/types'
import orderIcon from '../../static/images/notification_order.png'
import patientIcon from '../../static/images/notification_patient.png'
import reportIcon from '../../static/images/notification_report.png'
import systemIcon from '../../static/images/notification_system.png'
import taskIcon from '../../static/images/notification_task.png'
import trackIcon from '../../static/images/notification_track.png'

export function getAvatarIconByCategory(notificationCategory: NotificationCategory) {
  switch (notificationCategory) {
    case NotificationCategory.Patient:
      return patientIcon
    case NotificationCategory.Task:
      return taskIcon
    case NotificationCategory.Order:
      return orderIcon
    case NotificationCategory.Report:
      return reportIcon
    case NotificationCategory.Track:
      return trackIcon
    case NotificationCategory.System:
      return systemIcon
    default:
      exhaustiveCheck(notificationCategory)
  }
}
