import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Input, Modal } from 'antd'
import type { FC } from 'react'
import React from 'react'

interface Props extends FormComponentProps {
  visible: boolean
  formLabel: string
  initialValue: string
  handleClose: () => void
  handleUpdate: (note?: string) => Promise<void>
}

const EditModal: FC<Props> = (props) => {
  const { form, visible, formLabel, initialValue, handleClose, handleUpdate }
    = props

  return (
    <Modal
      title="病患備註編輯"
      visible={visible}
      onOk={() => handleUpdate(form.getFieldValue('note'))}
      onCancel={handleClose}
    >
      <Form>
        <Form.Item label={formLabel}>
          {form.getFieldDecorator('note', { initialValue })(
            <Input.TextArea autoSize />,
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Form.create<Props>()(EditModal)
