export type Maybe<T> = T | undefined;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};










export enum AccessoryCode {
  /** 重壓 */
  Recreation = 'RECREATION',
  /** 維持器 */
  Retainer = 'RETAINER',
  /** 4800-RT */
  Retainer_4800 = 'RETAINER_4800',
  /** A+0 */
  APlusZero = 'A_PLUS_ZERO',
  /** A */
  A = 'A',
  /** 0 */
  Zero = 'ZERO',
  /** 活動裝置 */
  Campaign = 'CAMPAIGN',
  /** 其他 */
  Others = 'OTHERS'
}

export type AccessoryStage = Stage & Node & {
  __typename?: 'AccessoryStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 配件編號 */
  accessoryCode: AccessoryCode;
  /** 指示卡 */
  instructionCard?: Maybe<InstructionCard>;
  /** 模型批次 ex: 0822SOV-1(G) */
  rpBatchNumber?: Maybe<Scalars['String']>;
  /** 模型代號 */
  rpModelNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
  /** 訂單 */
  order?: Maybe<Order>;
  /** 出貨單 */
  invoice?: Maybe<Invoice>;
};

export type Account = Node & {
  __typename?: 'Account';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 信箱(登入用) */
  email?: Maybe<Scalars['String']>;
  /** 電話(手機登入用) */
  phone?: Maybe<Scalars['String']>;
  /** 信箱是否驗證 */
  isEmailVerified: Scalars['Boolean'];
  /** 電話是否驗證 */
  isPhoneVerified: Scalars['Boolean'];
  /** 密碼 */
  password: Scalars['String'];
  /** 使用者暱稱 */
  nickname: Scalars['String'];
  /** 備用信箱(聯絡用) */
  backupEmail?: Maybe<Scalars['String']>;
  /** 頭像檔案 */
  avatar?: Maybe<File>;
  /** 社交相關綁定 */
  providers?: Maybe<Providers>;
  /** 帳戶是否開放使用 */
  isActive: Scalars['Boolean'];
  /** 最後登入時間 */
  lastLogin?: Maybe<Scalars['Date']>;
  /** 綁定的使用者種類(員工/醫生/診所/病患) */
  entityType: EntityType;
  /** 綁定的使用者(同上種類) */
  entity?: Maybe<Entity>;
  /** 權限 */
  privileges: Array<AllPrivilege>;
  /** 額外權限 */
  extraPrivileges?: Maybe<Array<AllPrivilege>>;
  /** 備註 */
  note: Scalars['String'];
  /** 通知設定 */
  notificationSetting: NotificationSetting;
  /** 使用者目前的 app 暫存 */
  currentAppStorage?: Maybe<Scalars['String']>;
  /** 是否強制清除 app 暫存 */
  shouldClearStorage: Scalars['Boolean'];
  /** 最後一次 app 暫存清除時間 */
  lastStorageClearDate?: Maybe<Scalars['Date']>;
  /** 最近一次登入客戶端資訊 */
  lastLoginClientInfo?: Maybe<Scalars['String']>;
};

export type AccountManagerStatistic = {
  __typename?: 'AccountManagerStatistic';
  patientStatusAndCountMapList: Array<PatientStatusAndCountMap>;
  patientCountCreatedDuringPreviousMonth: Scalars['Int'];
  patientCountCreatedDuringCurrentMonth: Scalars['Int'];
  patientCountCompletedDuringCurrentMonth: Scalars['Int'];
};

export type AccountManagerStatisticQuery = {
  date: Scalars['Date'];
};

export type AccountsQuery = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  entityType?: Maybe<Array<EntityType>>;
  isActive?: Maybe<Scalars['Boolean']>;
  entityName?: Maybe<Scalars['String']>;
};

export enum AccountStatus {
  /** 不存在 */
  NotFound = 'NOT_FOUND',
  /** 驗證中 */
  Verifying = 'VERIFYING',
  /** 授權中 */
  Authorizing = 'AUTHORIZING',
  /** 已授權 */
  Authorized = 'AUTHORIZED',
  /** 已停權 */
  Suspended = 'SUSPENDED'
}

export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum AllPrivilege {
  AccountCreate = 'ACCOUNT_CREATE',
  AccountRead = 'ACCOUNT_READ',
  AccountUpdate = 'ACCOUNT_UPDATE',
  AccountDelete = 'ACCOUNT_DELETE',
  ClinicCreate = 'CLINIC_CREATE',
  ClinicRead = 'CLINIC_READ',
  ClinicUpdate = 'CLINIC_UPDATE',
  ClinicDelete = 'CLINIC_DELETE',
  MessageCreate = 'MESSAGE_CREATE',
  MessageRead = 'MESSAGE_READ',
  MessageUpdate = 'MESSAGE_UPDATE',
  MessageDelete = 'MESSAGE_DELETE',
  ConversationCreate = 'CONVERSATION_CREATE',
  ConversationRead = 'CONVERSATION_READ',
  ConversationUpdate = 'CONVERSATION_UPDATE',
  ConversationDelete = 'CONVERSATION_DELETE',
  DoctorCreate = 'DOCTOR_CREATE',
  DoctorRead = 'DOCTOR_READ',
  DoctorUpdate = 'DOCTOR_UPDATE',
  DoctorDelete = 'DOCTOR_DELETE',
  PatientCreate = 'PATIENT_CREATE',
  PatientRead = 'PATIENT_READ',
  PatientUpdate = 'PATIENT_UPDATE',
  PatientDelete = 'PATIENT_DELETE',
  PatientClose = 'PATIENT_CLOSE',
  OrderCreate = 'ORDER_CREATE',
  OrderRead = 'ORDER_READ',
  OrderUpdate = 'ORDER_UPDATE',
  OrderDelete = 'ORDER_DELETE',
  StageCreate = 'STAGE_CREATE',
  StageRead = 'STAGE_READ',
  StageUpdate = 'STAGE_UPDATE',
  StageDelete = 'STAGE_DELETE',
  StageStart = 'STAGE_START',
  ScheduleCreate = 'SCHEDULE_CREATE',
  ScheduleRead = 'SCHEDULE_READ',
  ScheduleUpdate = 'SCHEDULE_UPDATE',
  ScheduleDelete = 'SCHEDULE_DELETE',
  TaskRead = 'TASK_READ',
  TaskUpdate = 'TASK_UPDATE',
  InvoiceCreate = 'INVOICE_CREATE',
  InvoiceRead = 'INVOICE_READ',
  InvoiceUpdate = 'INVOICE_UPDATE',
  InvoiceDelete = 'INVOICE_DELETE',
  ProductCreate = 'PRODUCT_CREATE',
  ProductRead = 'PRODUCT_READ',
  ProductUpdate = 'PRODUCT_UPDATE',
  ProductDelete = 'PRODUCT_DELETE',
  EmployeeCreate = 'EMPLOYEE_CREATE',
  EmployeeRead = 'EMPLOYEE_READ',
  EmployeeUpdate = 'EMPLOYEE_UPDATE',
  EmployeeDelete = 'EMPLOYEE_DELETE',
  NotificationRead = 'NOTIFICATION_READ',
  NotificationUpdate = 'NOTIFICATION_UPDATE',
  StatisticCreate = 'STATISTIC_CREATE',
  StatisticRead = 'STATISTIC_READ',
  StatisticUpdate = 'STATISTIC_UPDATE',
  StatisticDelete = 'STATISTIC_DELETE',
  LeaveCreate = 'LEAVE_CREATE',
  LeaveRead = 'LEAVE_READ',
  LeaveUpdate = 'LEAVE_UPDATE',
  LeaveDelete = 'LEAVE_DELETE',
  FeedbackCreate = 'FEEDBACK_CREATE',
  FeedbackRead = 'FEEDBACK_READ',
  FeedbackUpdate = 'FEEDBACK_UPDATE',
  FeedbackDelete = 'FEEDBACK_DELETE',
  UserCreate = 'USER_CREATE',
  UserRead = 'USER_READ',
  UserUpdate = 'USER_UPDATE',
  UserDelete = 'USER_DELETE',
  TrackCreate = 'TRACK_CREATE',
  TrackRead = 'TRACK_READ',
  TrackUpdate = 'TRACK_UPDATE',
  TrackDelete = 'TRACK_DELETE',
  SystemUpdate = 'SYSTEM_UPDATE'
}

export type Analysis = {
  __typename?: 'Analysis';
  /** 數位分析資料 */
  digiAnalysis?: Maybe<Scalars['String']>;
  /** 各療程計畫 */
  steps?: Maybe<Array<Step>>;
};

export enum AnglesClassification {
  Class1 = 'class1',
  Class2 = 'class2',
  Class3 = 'class3'
}

export type Appointment = Schedule & Node & {
  __typename?: 'Appointment';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: ScheduleType;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  description: Scalars['String'];
  isAttended: Scalars['Boolean'];
  creator: Entity;
  patient: Patient;
};

export type AppointmentNotification = Notification & Node & {
  __typename?: 'AppointmentNotification';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: NotificationType;
  receiver: Entity;
  read: Scalars['Boolean'];
  platform: Platform;
  content: Scalars['String'];
  appointment: Appointment;
};

export type AppointmentsQuery = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creator?: Maybe<Scalars['ID']>;
  patient?: Maybe<Scalars['ID']>;
  clinic?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['ID']>;
  isAttended?: Maybe<Scalars['Boolean']>;
  clinicName?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  startDateInterval?: Maybe<Array<Scalars['Date']>>;
};

export type Auxiliary = {
  __typename?: 'Auxiliary';
  type: AuxiliaryType;
  toothPosition: ToothPosition;
  auxiliaryPosition: AuxiliaryPosition;
  value?: Maybe<Scalars['Float']>;
};

export type AuxiliaryInput = {
  toothPosition: ToothPosition;
  auxiliaryPosition: AuxiliaryPosition;
  type: AuxiliaryType;
  value?: Maybe<Scalars['Float']>;
};

export enum AuxiliaryPosition {
  /** 近心 */
  Near = 'NEAR',
  /** 遠心 */
  Far = 'FAR',
  /** 一般/正上方 */
  Center = 'CENTER',
  /** 齒頸 */
  Cervical = 'CERVICAL',
  /** 切端 */
  Incisal = 'INCISAL'
}

export enum AuxiliaryType {
  Attachment = 'ATTACHMENT',
  Button = 'BUTTON',
  StrippingAll = 'STRIPPING_ALL',
  StrippingHalf = 'STRIPPING_HALF',
  Tad = 'TAD'
}

export type BatchUpdateOrderInput = {
  id: Scalars['ID'];
  stageOrder?: Maybe<Array<StageOrderContentInput>>;
  stageProvide?: Maybe<Array<StageProvideContentInput>>;
  type?: Maybe<OrderType>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  appointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  appointmentDate?: Maybe<Scalars['Date']>;
};

export type BindAccountEntityInput = {
  entity: Scalars['ID'];
};

export enum BraceMaterial {
  Petg = 'PETG',
  Benq = 'BENQ',
  Tpu = 'TPU',
  Choice = 'CHOICE'
}

export enum BraceThickness {
  T63 = 'T63',
  T75 = 'T75',
  T100 = 'T100'
}

export enum CephAnalysis {
  Normal = 'normal',
  Protrusion = 'protrusion',
  BoneLess = 'boneLess'
}

export type ChangeProsthesisToTemp = {
  __typename?: 'ChangeProsthesisToTemp';
  need: Need;
  instruction?: Maybe<Scalars['String']>;
};

export type ChangeProsthesisToTempInput = {
  need?: Maybe<Need>;
  instruction?: Maybe<Scalars['String']>;
};

export type CheckPoint = Node & {
  __typename?: 'CheckPoint';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 目標 step */
  goalStep: Scalars['Int'];
  /** 上顎目標 */
  upper: Scalars['String'];
  /** 下顎目標 */
  lower: Scalars['String'];
  /** 備註 */
  note: Scalars['String'];
  /** 臨床指示 */
  instruction: Array<AuxiliaryType>;
  /** viewer 模型檔 */
  files?: Maybe<EvalStageFiles>;
  /** 分階段設計 */
  designStages: Array<DesignStage>;
  /** 是否完成：已到達目標 step */
  isCompleted: Scalars['Boolean'];
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
  /** 是否為 Final */
  isFinal: Scalars['Boolean'];
};

export type Clinic = Entity & Node & {
  __typename?: 'Clinic';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  name: Scalars['String'];
  fullname: Scalars['String'];
  type: ClinicType;
  location: ClinicLocation;
  phone: Scalars['String'];
  doctors: Array<Doctor>;
  email: Scalars['String'];
  accountManagers: Array<Employee>;
  technicians: Array<Employee>;
  sales: Array<Employee>;
  customerService?: Maybe<Employee>;
  specialContract: Scalars['Boolean'];
  deliveryMethod: DeliveryMethod;
  invoices: PagedInvoice;
};


export type ClinicInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type ClinicLocation = {
  __typename?: 'ClinicLocation';
  address: Scalars['String'];
  country: Country;
  district: District;
};

export type ClinicLocationCreateInput = {
  address: Scalars['String'];
  country: Country;
  district: District;
};

export type ClinicLocationUpdateInput = {
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  district?: Maybe<District>;
};

export enum ClinicPaymentType {
  StepByStep = 'step_by_step',
  StepByStepSingleArch = 'step_by_step_single_arch',
  PayInFullHigh = 'pay_in_full_high',
  PayInFullLow = 'pay_in_full_low',
  PayInFullSingleArch = 'pay_in_full_single_arch',
  SimpleInstallment = 'simple_installment'
}

export type ClinicsQuery = {
  name?: Maybe<Scalars['String']>;
  doctor?: Maybe<Scalars['ID']>;
  isSpecialContract?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Array<Country>>;
  district?: Maybe<Array<District>>;
};

export enum ClinicType {
  Chain = 'CHAIN',
  Franchise = 'FRANCHISE',
  Normal = 'NORMAL'
}

export type Conversation = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: ConversationType;
  members: Array<Entity>;
  readMessageRecords: Array<ReadMessageRecord>;
  latestMessage?: Maybe<Message>;
};

export type ConversationMemberQuery = {
  name?: Maybe<Scalars['String']>;
};

export type ConversationMessagesQueryInput = {
  content?: Maybe<Scalars['String']>;
};

export enum ConversationSearchMethods {
  Conversation = 'CONVERSATION',
  Patient = 'PATIENT'
}

export type ConversationsWithLatestMessageConnection = {
  __typename?: 'ConversationsWithLatestMessageConnection';
  edges: Array<ConversationsWithLatestMessageEdge>;
  pageInfo: PageInfo;
};

export type ConversationsWithLatestMessageEdge = {
  __typename?: 'ConversationsWithLatestMessageEdge';
  node: ConversationsWithLatestMessageNode;
  cursor: Scalars['String'];
};

export type ConversationsWithLatestMessageNode = Node & {
  __typename?: 'ConversationsWithLatestMessageNode';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  latestMessage: Message;
  patient: Patient;
  numberOfUnreadMessages: Scalars['Int'];
};

export type ConversationsWithLatestMessageQuery = {
  patientName?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<Array<ConversationType>>;
};

export enum ConversationType {
  /** 病患聊天室 */
  Patient = 'PATIENT'
}

export enum Country {
  Tw = 'TW',
  Cn = 'CN'
}

export type CreateAccessoryStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  accessoryCode: AccessoryCode;
};

export type CreateAccountFromEmailInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  hostname: Scalars['String'];
  entityType: EntityType;
  entity?: Maybe<Scalars['ID']>;
};

export type CreateAccountInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  nickname: Scalars['String'];
  entityType: EntityType;
  entity?: Maybe<Scalars['ID']>;
  extraPrivileges?: Maybe<Array<AllPrivilege>>;
  note?: Maybe<Scalars['String']>;
};

export type CreateAppointmentInput = {
  creator: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  patient: Scalars['ID'];
  isAttended: Scalars['Boolean'];
};

export type CreateClinicInput = {
  type: ClinicType;
  name: Scalars['String'];
  fullname: Scalars['String'];
  location: ClinicLocationCreateInput;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  doctors: Array<Scalars['ID']>;
  accountManagers: Array<Scalars['ID']>;
  technicians: Array<Scalars['ID']>;
  sales: Array<Scalars['ID']>;
  customerService?: Maybe<Scalars['ID']>;
  specialContract?: Maybe<Scalars['Boolean']>;
  deliveryMethod: DeliveryMethod;
};

export type CreateDesignStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['Int']>;
  stepIndex?: Maybe<Scalars['Int']>;
};

export type CreateDiaryInput = {
  patient: Scalars['ID'];
  diaryDate: Scalars['Date'];
  photos?: Maybe<Array<Scalars['ID']>>;
  text?: Maybe<Scalars['String']>;
  subStage: Scalars['ID'];
  wearTime: Scalars['Int'];
};

export type CreateDoctorInput = {
  id?: Maybe<Scalars['ID']>;
  clinics?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  level: DoctorLevel;
};

export type CreateEmployeeInput = {
  gender?: Maybe<Gender>;
  role: Role;
  birthDate?: Maybe<Scalars['Date']>;
  isMarried?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lineId?: Maybe<Scalars['String']>;
  onBoardDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  taskOwner?: Maybe<Array<TaskType>>;
  approvers?: Maybe<Array<Scalars['ID']>>;
  resigned?: Maybe<Scalars['Boolean']>;
};

export type CreateEvalStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  serialNumber: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  moldStage?: Maybe<Scalars['ID']>;
  initialEvalStage?: Maybe<Scalars['ID']>;
};

export type CreateFeedbackInput = {
  id?: Maybe<Scalars['ID']>;
  category?: Maybe<FeedbackCategory>;
  screenshotList?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  entityType: EntityType;
  entity: Scalars['ID'];
};

export type CreateInvoiceWithoutStageInput = {
  middlemanFee: Scalars['Int'];
  discount: Scalars['Float'];
  tax: Scalars['Boolean'];
  payment: Payment;
  products?: Maybe<Array<InvoiceProductInput>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType: HomeDeliveryType;
  note?: Maybe<Scalars['String']>;
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  clinic: Scalars['ID'];
  sales: Scalars['ID'];
  doctor?: Maybe<Scalars['ID']>;
};

export type CreateInvoiceWithStageInput = {
  middlemanFee: Scalars['Int'];
  discount: Scalars['Float'];
  tax: Scalars['Boolean'];
  payment: Payment;
  products?: Maybe<Array<InvoiceProductInput>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType: HomeDeliveryType;
  note?: Maybe<Scalars['String']>;
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  patient: Scalars['ID'];
  stage: Scalars['ID'];
};

export type CreateLeaveInput = {
  employee: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  type: LeaveType;
  reason: Scalars['String'];
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type CreateMessage = {
  type: MessageType;
  creator: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
};

export type CreateMoldStageInput = {
  patient: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  moldType: MoldType;
};

export type CreateNotificationInput = {
  type: NotificationType;
  receiver: Scalars['ID'];
  platform: Platform;
  content: Scalars['String'];
};

export type CreateOrderInput = {
  patient: Scalars['ID'];
  stageOrder: Array<StageOrderContentInput>;
  type: OrderType;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  appointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  appointmentDate?: Maybe<Scalars['Date']>;
};

export type CreatePatientInput = {
  payment?: Maybe<PatientPaymentInput>;
  /** 負責診所 */
  clinic: Scalars['ID'];
  /** 負責醫師 */
  doctor: Scalars['ID'];
  /** 負責AM */
  accountManager?: Maybe<Scalars['ID']>;
  /** 負責業務 */
  sales: Scalars['ID'];
  /** 負責客服人員 */
  customerService?: Maybe<Scalars['ID']>;
  /** 負責技師 */
  technician?: Maybe<Scalars['ID']>;
  /** 下次約診日 */
  nextAppointmentDate?: Maybe<Scalars['Date']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 代號 (客戶模型編號) */
  patientCode?: Maybe<Scalars['String']>;
  /** 塔位 (在哪個櫃子，方便尋找用) */
  cabinetCode?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<Gender>;
  /** 主要治療牙弓 (上排, 下排) */
  treatArches?: Maybe<TreatArchesInput>;
  /** 醫師主要治療指示 */
  instruction?: Maybe<DoctorInstructionInput>;
  /** 照片（均儲存於 S3） */
  photos?: Maybe<PhotosInput>;
  /** 病患備註 */
  note?: Maybe<PatientNoteInput>;
  /** 主訴 */
  chiefComplaint?: Maybe<Scalars['String']>;
  /** 顏面觀 */
  profile?: Maybe<ProfileInput>;
  /** 口內觀 */
  intraOral?: Maybe<IntraOralInput>;
  /** 矯正症狀 */
  symptoms?: Maybe<SymptomsInput>;
  /** 醫師主要治療指示 */
  doctorInstruction?: Maybe<DoctorInstructionInput>;
  /** 初診日 */
  appointmentAt?: Maybe<Scalars['Date']>;
};

export type CreatePrintStageInput = {
  patient: Scalars['ID'];
  expectedShippingDate: Scalars['Date'];
  serialNumber: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  currentEvalStage?: Maybe<Scalars['ID']>;
};

export type CreateProductInput = {
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  spec?: Maybe<Scalars['String']>;
  cost: Scalars['Int'];
};

export type CreateSubStageInput = {
  stage: Scalars['ID'];
  serialNumber: Scalars['Int'];
};

export type CreateTrackInput = {
  clinic: Scalars['ID'];
  patient: Scalars['ID'];
  stage: Scalars['ID'];
  created?: Maybe<Scalars['Date']>;
  photo?: Maybe<TrackPhotoInput>;
  mainIssues?: Maybe<Array<TrackIssue>>;
  extraIssue?: Maybe<Scalars['String']>;
};

export type CreateWearInput = {
  patient: Scalars['ID'];
  subStage: Scalars['ID'];
  date: Scalars['Date'];
  time: Scalars['Int'];
};


export type DatedStage = {
  __typename?: 'DatedStage';
  date: Scalars['Date'];
  total: Scalars['Int'];
  accessoryTotal: Scalars['Int'];
  printTotal: Scalars['Int'];
  evalTotal: Scalars['Int'];
  accessoryDelayTotal: Scalars['Int'];
  printDelayTotal: Scalars['Int'];
  evalDelayTotal: Scalars['Int'];
  accessoryStages: Array<AccessoryStage>;
  printStages: Array<PrintStage>;
  evalStages: Array<EvalStage>;
};

export type DateSegement = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export enum DelayedStatus {
  IsDelay = 'IS_DELAY',
  Running = 'RUNNING',
  Completed = 'COMPLETED'
}

export enum DeliveryMethod {
  Shipping = 'SHIPPING',
  Sales = 'SALES'
}

export type DesignStage = Stage & Node & {
  __typename?: 'DesignStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 療程計畫 */
  step: Step;
  /** 指示卡 */
  instructionCard?: Maybe<InstructionCard>;
  /** 模型代號 */
  rpModelNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
  /** 所屬檢查點 */
  checkPoint?: Maybe<CheckPoint>;
  /** 關聯的報告工單 */
  evalStage: EvalStage;
  /** 檔案 */
  files?: Maybe<DesignStageFiles>;
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
  /** 最新一筆關聯到此設計單的製造單 */
  latestPrintStage?: Maybe<PrintStage>;
};

export type DesignStageFiles = {
  __typename?: 'DesignStageFiles';
  /** 分牙檔 */
  teeth?: Maybe<Array<ToothFile>>;
  /** 上顎牙肉 */
  upperJawModel?: Maybe<File>;
  /** 下顎牙肉 */
  lowerJawModel?: Maybe<File>;
  /** 上顎 RP 模型 */
  upperRP?: Maybe<File>;
  /** 下顎 RP 模型 */
  lowerRP?: Maybe<File>;
};

export type DesignStageFilesInput = {
  upperRP?: Maybe<Scalars['ID']>;
  lowerRP?: Maybe<Scalars['ID']>;
};

export type DiariesQuery = {
  patientName?: Maybe<Scalars['String']>;
  clinicName?: Maybe<Scalars['String']>;
};

export type Diary = Node & {
  __typename?: 'Diary';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  patient: Patient;
  wear: Wear;
  diaryDate: Scalars['Date'];
  photos?: Maybe<Array<File>>;
  text?: Maybe<Scalars['String']>;
};

export type DiaryWithHint = {
  __typename?: 'DiaryWithHint';
  diary?: Maybe<Diary>;
  hasUpdatedCurrentWear: Scalars['Boolean'];
};

export enum District {
  Tpe = 'TPE',
  Ntpc = 'NTPC',
  Kel = 'KEL',
  Tyn = 'TYN',
  Hsz = 'HSZ',
  Zmi = 'ZMI',
  Txg = 'TXG',
  Chw = 'CHW',
  Ntc = 'NTC',
  Yun = 'YUN',
  Cyi = 'CYI',
  Tnn = 'TNN',
  Khh = 'KHH',
  Pif = 'PIF',
  Ila = 'ILA',
  Hun = 'HUN',
  Ttt = 'TTT',
  Peh = 'PEH',
  Ah = 'AH',
  Bj = 'BJ',
  Fj = 'FJ',
  Gs = 'GS',
  Gd = 'GD',
  Gx = 'GX',
  Gz = 'GZ',
  Hi = 'HI',
  He = 'HE',
  Ha = 'HA',
  Hl = 'HL',
  Hb = 'HB',
  Hn = 'HN',
  Jl = 'JL',
  Js = 'JS',
  Jx = 'JX',
  Ln = 'LN',
  Nm = 'NM',
  Nx = 'NX',
  Qh = 'QH',
  Sd = 'SD',
  Sx = 'SX',
  Sn = 'SN',
  Sh = 'SH',
  Sc = 'SC',
  Tj = 'TJ',
  Xz = 'XZ',
  Xj = 'XJ',
  Yn = 'YN',
  Zj = 'ZJ',
  Cq = 'CQ',
  Mo = 'MO',
  Hk = 'HK'
}

export type Doctor = Node & Entity & {
  __typename?: 'Doctor';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 姓名 */
  name: Scalars['String'];
  /** email */
  email: Scalars['String'];
  /** 電話 */
  phone?: Maybe<Scalars['String']>;
  /** 隸屬診所 */
  clinics: Array<Clinic>;
  /** 醫師備註 */
  note: Scalars['String'];
  /** 等級 */
  level: DoctorLevel;
  /** 關聯病患 */
  patients?: Maybe<PagedPatient>;
};


export type DoctorPatientsArgs = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type DoctorInstruction = {
  __typename?: 'DoctorInstruction';
  ipr: Need;
  tads: Need;
  otherOrthdontalTools: Need;
  functionalAppliance: Need;
  maintainSpace: MaintainSpace;
  changeProsthesisToTemp: ChangeProsthesisToTemp;
  extractToothWhenNoSpace: ExtractToothWhenNoSpace;
  note: Scalars['String'];
};

export type DoctorInstructionInput = {
  note?: Maybe<Scalars['String']>;
  ipr?: Maybe<Need>;
  tads?: Maybe<Need>;
  otherOrthdontalTools?: Maybe<Need>;
  functionalAppliance?: Maybe<Need>;
  maintainSpace?: Maybe<MaintainSpaceInput>;
  changeProsthesisToTemp?: Maybe<ChangeProsthesisToTempInput>;
  extractToothWhenNoSpace?: Maybe<ExtractToothWhenNoSpaceInput>;
};

export enum DoctorLevel {
  Unknown = 'UNKNOWN',
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  Level5 = 'level5'
}

export type DoctorsQuery = {
  name?: Maybe<Scalars['String']>;
  clinic?: Maybe<Scalars['String']>;
  clinicName?: Maybe<Scalars['String']>;
  level?: Maybe<Array<DoctorLevel>>;
};

export type Employee = Entity & Node & {
  __typename?: 'Employee';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  name: Scalars['String'];
  title: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  backupEmail?: Maybe<Scalars['String']>;
  lineId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  isMarried?: Maybe<Scalars['Boolean']>;
  onBoardDate: Scalars['Date'];
  role: Role;
  taskOwner: Array<TaskType>;
  approvers: Array<Employee>;
  resigned: Scalars['Boolean'];
  leaveStatus: LeaveStatusQuery;
  patients?: Maybe<PagedPatient>;
  orders?: Maybe<PagedOrder>;
  stages?: Maybe<PagedStage>;
  leaves?: Maybe<PagedLeave>;
};


export type EmployeePatientsArgs = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeStagesArgs = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeLeavesArgs = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type EmployeesQuery = {
  roles?: Maybe<Array<Role>>;
  resigned?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  taskOwner?: Maybe<Array<TaskType>>;
  technicianClinic?: Maybe<Scalars['ID']>;
  salesClinic?: Maybe<Scalars['ID']>;
};

export type EmployeeTaskStatistic = {
  __typename?: 'EmployeeTaskStatistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  employee: Employee;
  taskType: TaskType;
  count: Scalars['Int'];
};

export type EmployeeTaskStatisticQuery = {
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  taskTypes: Array<TaskType>;
};

export type Entity = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum EntityType {
  Employee = 'EMPLOYEE',
  Doctor = 'DOCTOR',
  Clinic = 'CLINIC',
  Patient = 'PATIENT'
}

export type EntityTypeToPrivilegesListSystem = System & Node & {
  __typename?: 'EntityTypeToPrivilegesListSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 實體權限表 */
  entityTypeToPrivilegesList: Array<SystemEntityTypeToPrivileges>;
};

export type EstheticLine = {
  __typename?: 'EstheticLine';
  lipsEstheticLineRelation: LipsEstheticLineRelation;
  instruction: Instruction;
};

export type EstheticLineInput = {
  lipsEstheticLineRelation?: Maybe<LipsEstheticLineRelation>;
  instruction?: Maybe<Instruction>;
};

export type EvalStage = Stage & Node & {
  __typename?: 'EvalStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 診斷資料 */
  analysis?: Maybe<Analysis>;
  /** 檢查點 */
  checkPoints: Array<CheckPoint>;
  /** 是否在製造前創建 */
  isBeforePrint: Scalars['Boolean'];
  /** 關聯的建模單 */
  moldStage?: Maybe<MoldStage>;
  /** 檔案 */
  files?: Maybe<EvalStageFiles>;
  /** 階段進度表（分階段 excel） */
  stepsOverview?: Maybe<File>;
  /** 從特定報告單派生出來 */
  initialEvalStage?: Maybe<EvalStage>;
  /** 訂單 */
  order?: Maybe<Order>;
  /** 出貨單 */
  invoice?: Maybe<Invoice>;
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
  /** 是否為 CP 版報告 */
  isCPMode: Scalars['Boolean'];
  /** 可供外部檢視 */
  externalVisible: Scalars['Boolean'];
  /** 是否在報告中顯示 CP */
  shouldShowCheckPoint: Scalars['Boolean'];
};

export type EvalStageFiles = {
  __typename?: 'EvalStageFiles';
  /** 分牙檔 */
  teeth?: Maybe<Array<ToothFile>>;
  /** 上顎牙肉 */
  upperJawModel?: Maybe<File>;
  /** 下顎牙肉 */
  lowerJawModel?: Maybe<File>;
};

export type EvalStagesQuery = {
  patient?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<StageStatus>>;
  isBeforePrint?: Maybe<Scalars['Boolean']>;
};

export type Event = Node & {
  __typename?: 'Event';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  creator: Entity;
  type: EventType;
  data: EventData;
};

export type EventData = {
  __typename?: 'EventData';
  patient?: Maybe<Patient>;
  order?: Maybe<Order>;
  stage?: Maybe<Stage>;
};

export type EventsQuery = {
  patient?: Maybe<Scalars['ID']>;
};

export enum EventType {
  CreatePatient = 'CREATE_PATIENT',
  CreateOrder = 'CREATE_ORDER',
  CreateStage = 'CREATE_STAGE',
  CompleteStage = 'COMPLETE_STAGE',
  UpdateCurrentStage = 'UPDATE_CURRENT_STAGE'
}

export type ExpectedShippingStagesQuery = {
  type?: Maybe<Array<StageType>>;
  status?: Maybe<Array<StageStatus>>;
};

export type ExportData = {
  __typename?: 'ExportData';
  data: Scalars['String'];
};

export type ExtractToothWhenNoSpace = {
  __typename?: 'ExtractToothWhenNoSpace';
  intention: Need;
  toothType?: Maybe<ToothType>;
};

export type ExtractToothWhenNoSpaceInput = {
  intention?: Maybe<Need>;
  toothType?: Maybe<ToothType>;
};

export enum FaceRatio {
  Normal = 'normal',
  Long = 'long',
  Short = 'short'
}

export enum FcmNotificationType {
  TomorrowAppointment = 'TOMORROW_APPOINTMENT'
}

export type Feedback = Node & {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  category: FeedbackCategory;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  screenshotList?: Maybe<Array<File>>;
  status: FeedbackStatus;
  entityType: EntityType;
  entity: Entity;
};

export enum FeedbackCategory {
  Bug = 'bug',
  Feature = 'feature'
}

export type FeedbackQuery = {
  createdDateInterval?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Array<FeedbackCategory>>;
  status?: Maybe<Array<FeedbackStatus>>;
  entityName?: Maybe<Scalars['String']>;
  entityType?: Maybe<Array<EntityType>>;
};

export enum FeedbackStatus {
  Pending = 'pending',
  Completed = 'completed'
}

export enum FieldAsForeignKeyReferToUser {
  Id = 'id',
  Sales = 'sales',
  Doctor = 'doctor',
  Clinic = 'clinic',
  Owner = 'owner',
  Approvers = 'approvers',
  Employee = 'employee',
  Patient = 'patient',
  AccountManager = 'accountManager'
}

export type File = Node & {
  __typename?: 'File';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  mimeType: Scalars['String'];
  filename: Scalars['String'];
  path: Scalars['String'];
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type FileInput = {
  mimeType: Scalars['String'];
  filename: Scalars['String'];
  path: Scalars['String'];
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type FileUploadOption = {
  prefix: Scalars['String'];
  needThumbnail?: Maybe<Scalars['Boolean']>;
};

export type ForwardTaskInput = {
  nextOwner?: Maybe<Scalars['ID']>;
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GroupedCreatedPatientCountMap = {
  __typename?: 'GroupedCreatedPatientCountMap';
  clinic: Clinic;
  source: PatientSource;
  createdPatientCount: Scalars['Int'];
};

export type GroupedCreatedPatientCountMapListQuery = {
  createdInterval?: Maybe<Array<Scalars['Date']>>;
};

export enum HomeDeliveryType {
  Self = 'self',
  TCat = 't_cat',
  Maple = 'maple',
  Post = 'post'
}

export type ImageMessage = Message & Node & {
  __typename?: 'ImageMessage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  conversation: Conversation;
  type: MessageType;
  creator: Entity;
  image: File;
};

export type ImpactedTooth = {
  __typename?: 'ImpactedTooth';
  hasImpactedTooth: Scalars['Boolean'];
  instruction?: Maybe<ImpactedToothInstruction>;
};

export type ImpactedToothInput = {
  hasImpactedTooth?: Maybe<Scalars['Boolean']>;
  instruction?: Maybe<ImpactedToothInstruction>;
};

export enum ImpactedToothInstruction {
  Extract = 'extract',
  DontMove = 'dontMove'
}

export enum Instruction {
  Maintain = 'maintain',
  Improve = 'improve',
  Idealize = 'idealize'
}

export type InstructionCard = {
  __typename?: 'InstructionCard';
  instruction?: Maybe<Scalars['String']>;
  auxiliaries: Array<Auxiliary>;
  /** 牙位狀態 */
  teethStatus: Array<ToothStatus>;
  retrieve: Scalars['Boolean'];
  stepCode?: Maybe<Scalars['String']>;
  stage: Stage;
  patient: Patient;
};

export type InstructionCardInput = {
  instruction?: Maybe<Scalars['String']>;
  auxiliaries?: Maybe<Array<AuxiliaryInput>>;
  teethStatus?: Maybe<Array<ToothStatusInput>>;
  retrieve?: Maybe<Scalars['Boolean']>;
};

export type IntraOral = {
  __typename?: 'IntraOral';
  missingTeeth: Array<Scalars['Int']>;
  teethDontMove: Array<Scalars['Int']>;
  teethWillExtract: Array<Scalars['Int']>;
  teethStatus: Array<ToothStatus>;
  temporomandibularJointDisordersTreatStatus: TreatStatus;
  periodontalDiseaseTreatStatus: TreatStatus;
  residualRoot: ResidualRoot;
  impactedTooth: ImpactedTooth;
  uneruptedWisdomTooth: UneruptedWisdomTooth;
  maxillarMidlineToFacialMidline: Midline;
  mandibularMidlineToMaxillarMidline: Midline;
};

export type IntraOralInput = {
  teethStatus?: Maybe<Array<ToothStatusInput>>;
  temporomandibularJointDisordersTreatStatus?: Maybe<TreatStatus>;
  periodontalDiseaseTreatStatus?: Maybe<TreatStatus>;
  residualRoot?: Maybe<ResidualRootInput>;
  impactedTooth?: Maybe<ImpactedToothInput>;
  uneruptedWisdomTooth?: Maybe<UneruptedWisdomToothInput>;
  maxillarMidlineToFacialMidline?: Maybe<MidlineInput>;
  mandibularMidlineToMaxillarMidline?: Maybe<MidlineInput>;
};

export type Invoice = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  clinic: Clinic;
  discount: Scalars['Float'];
  doctor: Doctor;
  homeDeliveryId: Scalars['String'];
  homeDeliveryType: HomeDeliveryType;
  /** 開立發票的號碼 */
  invoiceNumber: Scalars['String'];
  middlemanFee: Scalars['Int'];
  note: Scalars['String'];
  /** 入帳時間 */
  creditDate: Scalars['Date'];
  /** 出貨時間 */
  shippingDate?: Maybe<Scalars['Date']>;
  payment: Payment;
  products: Array<InvoiceProduct>;
  sales: Employee;
  tax: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  /** 出貨狀態 */
  shippingStatus: ShippingStatus;
  deferredRevenue: Scalars['Float'];
  /** 出貨單所屬診所的負責克服 */
  customerService?: Maybe<Employee>;
};

export type InvoiceMonth = {
  __typename?: 'InvoiceMonth';
  year: Scalars['Int'];
  month: Scalars['Int'];
  invoiceCount: Scalars['Int'];
  freeInvoiceCount: Scalars['Int'];
};

export type InvoiceMonthInput = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};

export type InvoiceProduct = {
  __typename?: 'InvoiceProduct';
  productId: Scalars['ID'];
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  spec: Scalars['String'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  count: Scalars['Int'];
};

export type InvoiceProductInput = {
  productId: Scalars['ID'];
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  spec?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  cost: Scalars['Int'];
  count: Scalars['Int'];
};

export type InvoiceQuery = {
  clinicId?: Maybe<Scalars['ID']>;
  doctorId?: Maybe<Scalars['ID']>;
  doctorIds?: Maybe<Array<Scalars['ID']>>;
  salesId?: Maybe<Scalars['ID']>;
  customerServiceId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  creditDateInterval?: Maybe<Array<Scalars['Date']>>;
  shippingDateInterval?: Maybe<Array<Scalars['Date']>>;
  invoiceMonths?: Maybe<Array<InvoiceMonthInput>>;
  clinicName?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  salesName?: Maybe<Scalars['String']>;
  customerServiceName?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientSources?: Maybe<Array<PatientSource>>;
  patientPaymentTypes?: Maybe<Array<PatientPaymentType>>;
  payment?: Maybe<Payment>;
  hasMiddlemanFee?: Maybe<Scalars['Boolean']>;
  shippingStatus?: Maybe<Array<ShippingStatus>>;
};

export type InvoiceStatistic = {
  __typename?: 'InvoiceStatistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segment: PatientSegment;
  indicators?: Maybe<Array<InvoiceStatisticIndicator>>;
};

export type InvoiceStatisticIndicator = {
  __typename?: 'InvoiceStatisticIndicator';
  type: InvoiceStatisticIndicatorType;
  value: Scalars['Int'];
};

export enum InvoiceStatisticIndicatorType {
  Revenue = 'REVENUE',
  TechnicianRevenue = 'TECHNICIAN_REVENUE',
  BrandRevenue = 'BRAND_REVENUE'
}

export type InvoiceStatisticQuery = {
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segments: Array<PatientSegmentInput>;
  indicatorType: InvoiceStatisticIndicatorType;
};

export type InvoiceWithoutStage = Invoice & Node & {
  __typename?: 'InvoiceWithoutStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  clinic: Clinic;
  discount: Scalars['Float'];
  doctor: Doctor;
  homeDeliveryId: Scalars['String'];
  homeDeliveryType: HomeDeliveryType;
  /** 開立發票的號碼 */
  invoiceNumber: Scalars['String'];
  middlemanFee: Scalars['Int'];
  note: Scalars['String'];
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  payment: Payment;
  products: Array<InvoiceProduct>;
  sales: Employee;
  tax: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  shippingStatus: ShippingStatus;
  deferredRevenue: Scalars['Float'];
  /** 出貨單所屬診所的負責克服 */
  customerService?: Maybe<Employee>;
};

export type InvoiceWithStage = Invoice & Node & {
  __typename?: 'InvoiceWithStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  clinic: Clinic;
  discount: Scalars['Float'];
  doctor: Doctor;
  homeDeliveryId: Scalars['String'];
  homeDeliveryType: HomeDeliveryType;
  /** 開立發票的號碼 */
  invoiceNumber: Scalars['String'];
  middlemanFee: Scalars['Int'];
  note: Scalars['String'];
  creditDate: Scalars['Date'];
  shippingDate?: Maybe<Scalars['Date']>;
  payment: Payment;
  products: Array<InvoiceProduct>;
  sales: Employee;
  tax: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  shippingStatus: ShippingStatus;
  deferredRevenue: Scalars['Float'];
  /** 出貨單所屬診所的負責克服 */
  customerService?: Maybe<Employee>;
  patient: Patient;
  stage: Stage;
};

export enum JawPosition {
  /** 上顎 */
  Upper = 'UPPER',
  /** 下顎 */
  Lower = 'LOWER'
}

export type Leave = Node & {
  __typename?: 'Leave';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  employee: Employee;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  type: LeaveType;
  reason: Scalars['String'];
  isApproved: Scalars['Boolean'];
};

export type LeavesQuery = {
  employee?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<Array<LeaveType>>;
  reason?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type LeaveStatusQuery = {
  __typename?: 'LeaveStatusQuery';
  remainAnnual: Scalars['Float'];
  usedSickLeave: Scalars['Float'];
  usedPersonalLeave: Scalars['Float'];
};

export enum LeaveType {
  Sick = 'SICK',
  Personal = 'PERSONAL',
  Funeral = 'FUNERAL',
  Marriage = 'MARRIAGE',
  Annual = 'ANNUAL',
  Menstruation = 'MENSTRUATION',
  Maternity = 'MATERNITY',
  Other = 'OTHER',
  Birthday = 'BIRTHDAY'
}

export enum LipsEstheticLineRelation {
  Over = 'over',
  On = 'on',
  In = 'in'
}

export type MaintainSpace = {
  __typename?: 'MaintainSpace';
  isMaintainingSpace: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
};

export type MaintainSpaceInput = {
  isMaintainingSpace?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type MaxStageCapacity = {
  __typename?: 'MaxStageCapacity';
  capacityPerType: Array<MaxStageCapacityPerType>;
  maxPoints: Scalars['Float'];
};

export type MaxStageCapacityPerType = {
  __typename?: 'MaxStageCapacityPerType';
  stageType: StageType;
  pointsPerUnit: Scalars['Float'];
  maxPoints: Scalars['Float'];
};

export type Message = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  conversation: Conversation;
  type: MessageType;
  creator: Entity;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type MessagesQuery = {
  content?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
};

export enum MessageType {
  /** 一般留言 */
  Text = 'TEXT',
  /** 相片留言 */
  Image = 'IMAGE'
}

export type Midline = {
  __typename?: 'Midline';
  offset: Offset;
  instruction: Instruction;
};

export type MidlineInput = {
  offset?: Maybe<Offset>;
  instruction?: Maybe<Instruction>;
};

export type MinimumRequiredAppVersionSystem = System & Node & {
  __typename?: 'MinimumRequiredAppVersionSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** APP 最低需求版本 */
  minimumRequiredAppVersion: Scalars['String'];
};

export type MoldStage = Stage & Node & {
  __typename?: 'MoldStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 齒模類型 */
  moldType: MoldType;
  /** 檔案 */
  files?: Maybe<MoldStageFiles>;
  /** 最新一筆關聯到此建模單的報告單 */
  latestEvalStage?: Maybe<EvalStage>;
  /** 關聯的報告單列表 (只有在 moldStagesTrack resolver 回傳的 PagedMoldStage 中的 MoldStage 此欄位才能正確取到值) */
  evalStages?: Maybe<Array<EvalStage>>;
  /** 是否有 Viewer 檔案可檢閱 */
  hasFilesForViewer: Scalars['Boolean'];
};

export type MoldStageFiles = {
  __typename?: 'MoldStageFiles';
  /** 分牙檔 */
  teeth?: Maybe<Array<ToothFile>>;
  /** 上顎牙肉 */
  upperJawModel?: Maybe<File>;
  /** 下顎牙肉 */
  lowerJawModel?: Maybe<File>;
};

export type MoldStagesTrackQuery = {
  patientName?: Maybe<Scalars['String']>;
  firstTime?: Maybe<Scalars['Boolean']>;
  hasEvalStage?: Maybe<Scalars['Boolean']>;
  startOfCreated?: Maybe<Scalars['String']>;
  endOfCreated?: Maybe<Scalars['String']>;
};

export enum MoldType {
  /** 石膏 */
  Plaster = 'PLASTER',
  /** 牙托 */
  Tray = 'TRAY',
  /** 口掃檔 */
  Digital = 'DIGITAL'
}

export type Mutation = {
  __typename?: 'Mutation';
  backwardTask: Task;
  backwardTaskBatch: Array<Task>;
  bindAccountUser: Account;
  /** 新增配件單 */
  createAccessoryStage: AccessoryStage;
  createAccount: Account;
  createAccountFromEmail: Account;
  createAppointment: Appointment;
  createAppointments: Array<Appointment>;
  /** 新增 check point */
  createCheckPoint: EvalStage;
  createClinic: Clinic;
  /** 新增設計單 */
  createDesignStage: DesignStage;
  createDiary: DiaryWithHint;
  createDoctor: Doctor;
  createEmployee: Employee;
  /** 新增報告單 */
  createEvalStage: EvalStage;
  createFeedback: Feedback;
  /** 創建一個空的指示卡 */
  createInstructionCard: Stage;
  createInvoiceWithStage: InvoiceWithStage;
  createInvoiceWithoutStage: InvoiceWithoutStage;
  createLeave: Leave;
  createMessage: Message;
  /** 新增建模單 */
  createMoldStage: MoldStage;
  createOrder: Order;
  createOrders?: Maybe<Array<Order>>;
  createPatient: Patient;
  /** 新增製造單 */
  createPrintStage: PrintStage;
  createProduct: Product;
  createTrack: Track;
  /** 刪除 check point */
  deleteCheckPoint: EvalStage;
  /** 廢棄工單 */
  dropStage: Stage;
  exportInvoices: ExportData;
  exportPatients: ExportData;
  forwardTask: Task;
  forwardTaskBatch: Array<Task>;
  loginByRoot: Account;
  readAllNotification: ReadAllNotificationResponse;
  readNotification: Notification;
  register: Account;
  removeAccount: Account;
  removeAppointment: Appointment;
  removeClinic: Clinic;
  removeConversation: SystemMessage;
  removeDiary: DiaryWithHint;
  removeDoctor: Doctor;
  removeEmployee: Employee;
  removeFeedback: Feedback;
  /** 移除一個空的指示卡 */
  removeInstructionCard: Stage;
  removeInvoice: RemoveSystemMessage;
  removeLeave: Leave;
  removeMessage: RemoveSystemMessage;
  removeOrder?: Maybe<RemoveSystemMessage>;
  removePatient: RemoveSystemMessage;
  removeProduct: Product;
  /** 移除工單 */
  removeStage: RemoveSystemMessage;
  removeTrack: Track;
  replyTrack: Track;
  selectPatientCurrentEvalStage: Patient;
  sendLineText: SystemMessage;
  transferPatients: Array<Patient>;
  /** 還原廢棄工單 */
  undropStage: Stage;
  /** 更新配件單 */
  updateAccessoryStage: AccessoryStage;
  updateAccount: Account;
  /** 更新診斷 */
  updateAnalysis: EvalStage;
  updateAppointment: Appointment;
  updateAppointments: Array<Appointment>;
  /** 更新 check point */
  updateCheckPoint: EvalStage;
  updateClinic: Clinic;
  updateConversationMembers: Conversation;
  updateConversationReadMessageRecordOfMember: Conversation;
  /** 更新設計單 */
  updateDesignStage: DesignStage;
  updateDiary: DiaryWithHint;
  updateDoctor: Doctor;
  updateEmployee: Employee;
  updateEmployeeBatch: Array<Employee>;
  /** 更新報告單 */
  updateEvalStage: EvalStage;
  updateFeedback: Feedback;
  /** 更新設計單的指示卡 */
  updateInstructionCard: Stage;
  updateInvoicePayment: Scalars['Int'];
  updateInvoiceWithStage: InvoiceWithStage;
  updateInvoiceWithoutStage: InvoiceWithoutStage;
  updateLeave: Leave;
  updateMessage: Message;
  updateMinimumRequiredAppVersionSystem: MinimumRequiredAppVersionSystem;
  /** 更新建模單 */
  updateMoldStage: MoldStage;
  updateOrder: Order;
  updateOrders?: Maybe<Array<Order>>;
  updatePatient: Patient;
  updatePatientCurrentSubStage: Patient;
  updatePatientStatus: Patient;
  /** 更新製造單 */
  updatePrintStage: PrintStage;
  updateProduct: Product;
  updateSystemEntityTypeToPrivilegesList: EntityTypeToPrivilegesListSystem;
  updateSystemMaxStageCapacity: StageCapacitySystem;
  updateSystemRoleToPrivilegesList: RoleToPrivilegesListSystem;
  updateSystemStageCost: StageCostSystem;
  updateTrack: Track;
  /** 更新 Viewer 檔案 */
  updateViewerFiles: Stage;
  uploadFile: File;
  verifyEmployeeAccount: Account;
};


export type MutationBackwardTaskArgs = {
  id: Scalars['ID'];
};


export type MutationBackwardTaskBatchArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationBindAccountUserArgs = {
  id: Scalars['ID'];
  payload: BindAccountEntityInput;
};


export type MutationCreateAccessoryStageArgs = {
  payload: CreateAccessoryStageInput;
};


export type MutationCreateAccountArgs = {
  payload: CreateAccountInput;
};


export type MutationCreateAccountFromEmailArgs = {
  payload: CreateAccountFromEmailInput;
};


export type MutationCreateAppointmentArgs = {
  payload: CreateAppointmentInput;
};


export type MutationCreateAppointmentsArgs = {
  payloads: Array<CreateAppointmentInput>;
};


export type MutationCreateCheckPointArgs = {
  id: Scalars['ID'];
};


export type MutationCreateClinicArgs = {
  payload: CreateClinicInput;
};


export type MutationCreateDesignStageArgs = {
  payload: CreateDesignStageInput;
};


export type MutationCreateDiaryArgs = {
  payload: CreateDiaryInput;
};


export type MutationCreateDoctorArgs = {
  payload: CreateDoctorInput;
};


export type MutationCreateEmployeeArgs = {
  payload: CreateEmployeeInput;
};


export type MutationCreateEvalStageArgs = {
  payload: CreateEvalStageInput;
};


export type MutationCreateFeedbackArgs = {
  payload: CreateFeedbackInput;
};


export type MutationCreateInstructionCardArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInvoiceWithStageArgs = {
  payload: CreateInvoiceWithStageInput;
};


export type MutationCreateInvoiceWithoutStageArgs = {
  payload: CreateInvoiceWithoutStageInput;
};


export type MutationCreateLeaveArgs = {
  payload: CreateLeaveInput;
};


export type MutationCreateMessageArgs = {
  conversationId: Scalars['ID'];
  payload: CreateMessage;
};


export type MutationCreateMoldStageArgs = {
  payload: CreateMoldStageInput;
};


export type MutationCreateOrderArgs = {
  payload: CreateOrderInput;
};


export type MutationCreateOrdersArgs = {
  payloads: Array<CreateOrderInput>;
};


export type MutationCreatePatientArgs = {
  payload: CreatePatientInput;
};


export type MutationCreatePrintStageArgs = {
  payload: CreatePrintStageInput;
};


export type MutationCreateProductArgs = {
  payload: CreateProductInput;
};


export type MutationCreateTrackArgs = {
  payload: CreateTrackInput;
};


export type MutationDeleteCheckPointArgs = {
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
};


export type MutationDropStageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationExportInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
};


export type MutationExportPatientsArgs = {
  query?: Maybe<PatientsQuery>;
};


export type MutationForwardTaskArgs = {
  id: Scalars['ID'];
  payload: ForwardTaskInput;
};


export type MutationForwardTaskBatchArgs = {
  ids: Array<Scalars['ID']>;
  payload: ForwardTaskInput;
};


export type MutationLoginByRootArgs = {
  id: Scalars['ID'];
};


export type MutationReadAllNotificationArgs = {
  receiver: Scalars['ID'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationRegisterArgs = {
  payload: RegisterAccountInput;
};


export type MutationRemoveAccountArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveClinicArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveConversationArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDiaryArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDoctorArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFeedbackArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveInstructionCardArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveLeaveArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMessageArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePatientArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveStageArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTrackArgs = {
  id: Scalars['ID'];
};


export type MutationReplyTrackArgs = {
  id: Scalars['ID'];
  payload: ReplyTrackInput;
};


export type MutationSelectPatientCurrentEvalStageArgs = {
  id: Scalars['ID'];
  stageId: Scalars['ID'];
};


export type MutationSendLineTextArgs = {
  providersLineId: Scalars['String'];
  text: Scalars['String'];
};


export type MutationTransferPatientsArgs = {
  ids: Array<Scalars['ID']>;
  payload: TransferPatientsInput;
};


export type MutationUndropStageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateAccessoryStageArgs = {
  id: Scalars['ID'];
  payload: UpdateAccessoryStageInput;
};


export type MutationUpdateAccountArgs = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type MutationUpdateAnalysisArgs = {
  id: Scalars['ID'];
  payload: UpdateAnalysisInput;
};


export type MutationUpdateAppointmentArgs = {
  id: Scalars['ID'];
  payload: UpdateAppointmentInput;
};


export type MutationUpdateAppointmentsArgs = {
  payloads: Array<UpdateAppointmentsInput>;
};


export type MutationUpdateCheckPointArgs = {
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
  payload: UpdateCheckPointInput;
};


export type MutationUpdateClinicArgs = {
  id: Scalars['ID'];
  payload: UpdateClinicInput;
};


export type MutationUpdateConversationMembersArgs = {
  id: Scalars['ID'];
  entities: Array<Scalars['ID']>;
};


export type MutationUpdateConversationReadMessageRecordOfMemberArgs = {
  id: Scalars['ID'];
  entity: Scalars['ID'];
};


export type MutationUpdateDesignStageArgs = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type MutationUpdateDiaryArgs = {
  id: Scalars['ID'];
  payload: UpdateDiaryInput;
};


export type MutationUpdateDoctorArgs = {
  id: Scalars['ID'];
  payload: UpdateDoctorInput;
};


export type MutationUpdateEmployeeArgs = {
  id: Scalars['ID'];
  payload: UpdateEmployeeInput;
};


export type MutationUpdateEmployeeBatchArgs = {
  payload: Array<UpdateEmployeeBatchInput>;
};


export type MutationUpdateEvalStageArgs = {
  id: Scalars['ID'];
  payload: UpdateEvalStageInput;
};


export type MutationUpdateFeedbackArgs = {
  id: Scalars['ID'];
  payload: UpdateFeedbackInput;
};


export type MutationUpdateInstructionCardArgs = {
  id: Scalars['ID'];
  payload: InstructionCardInput;
};


export type MutationUpdateInvoicePaymentArgs = {
  query: InvoiceQuery;
  payload: UpdateInvoicePaymentInput;
};


export type MutationUpdateInvoiceWithStageArgs = {
  id: Scalars['ID'];
  payload: UpdateInvoiceWithStageInput;
};


export type MutationUpdateInvoiceWithoutStageArgs = {
  id: Scalars['ID'];
  payload: UpdateInvoiceWithoutStageInput;
};


export type MutationUpdateLeaveArgs = {
  id: Scalars['ID'];
  payload: UpdateLeaveInput;
};


export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  payload: UpdateMessage;
};


export type MutationUpdateMinimumRequiredAppVersionSystemArgs = {
  version: Scalars['String'];
};


export type MutationUpdateMoldStageArgs = {
  id: Scalars['ID'];
  payload: UpdateMoldStageInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['ID'];
  payload: UpdateOrderInput;
};


export type MutationUpdateOrdersArgs = {
  payloads: Array<BatchUpdateOrderInput>;
};


export type MutationUpdatePatientArgs = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type MutationUpdatePatientCurrentSubStageArgs = {
  id: Scalars['ID'];
  subStageId: Scalars['ID'];
};


export type MutationUpdatePatientStatusArgs = {
  id: Scalars['ID'];
  status?: Maybe<PatientStatus>;
};


export type MutationUpdatePrintStageArgs = {
  id: Scalars['ID'];
  payload: UpdatePrintStageInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID'];
  payload: UpdateProductInput;
};


export type MutationUpdateSystemEntityTypeToPrivilegesListArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemEntityTypeToPrivilegesInput;
};


export type MutationUpdateSystemMaxStageCapacityArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemMaxStageCapacityInput;
};


export type MutationUpdateSystemRoleToPrivilegesListArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemRoleToPrivilegesInput;
};


export type MutationUpdateSystemStageCostArgs = {
  configType: SystemConfigType;
  payload: UpdateSystemStageCostInput;
};


export type MutationUpdateTrackArgs = {
  id: Scalars['ID'];
  payload: UpdateTrackInput;
};


export type MutationUpdateViewerFilesArgs = {
  id: Scalars['ID'];
  payload: ViewerFilesInput;
  cpSerialNumber?: Maybe<Scalars['Int']>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  options: FileUploadOption;
};


export type MutationVerifyEmployeeAccountArgs = {
  id: Scalars['ID'];
  verificationType: VerificationType;
};

export enum Need {
  Yes = 'yes',
  No = 'no',
  OnlyIfNeeded = 'onlyIfNeeded'
}

export type Node = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type NormalNotification = Notification & Node & {
  __typename?: 'NormalNotification';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: NotificationType;
  receiver: Entity;
  read: Scalars['Boolean'];
  platform: Platform;
  content: Scalars['String'];
};

export type Notification = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 類別 */
  type: NotificationType;
  /** 接受者 */
  receiver: Entity;
  /** 是否已讀 */
  read: Scalars['Boolean'];
  /** 顯示的平台 */
  platform: Platform;
  /** 文字內容 (html) */
  content: Scalars['String'];
};

export enum NotificationCategory {
  Task = 'TASK',
  Patient = 'PATIENT',
  Order = 'ORDER',
  Report = 'REPORT',
  Track = 'TRACK',
  System = 'SYSTEM'
}

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  node: Notification;
  cursor: Scalars['String'];
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  site: Array<NotificationType>;
  mail: Array<NotificationType>;
  line: Array<NotificationType>;
};

export type NotificationSettingInput = {
  site: Array<NotificationType>;
  mail: Array<NotificationType>;
  line: Array<NotificationType>;
};

export type NotificationsQuery = {
  type?: Maybe<Array<NotificationType>>;
  receiver?: Maybe<Array<Scalars['ID']>>;
  read?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Platform>;
};

export enum NotificationType {
  TaskAssigned = 'TASK_ASSIGNED',
  TaskRejected = 'TASK_REJECTED',
  StageExpectedShippingDateUpdated = 'STAGE_EXPECTED_SHIPPING_DATE_UPDATED',
  PatientCreated = 'PATIENT_CREATED',
  PatientUpdated = 'PATIENT_UPDATED',
  AppointmentCreated = 'APPOINTMENT_CREATED',
  AppointmentUpdated = 'APPOINTMENT_UPDATED',
  OrderCreated = 'ORDER_CREATED',
  OrderInProgress = 'ORDER_IN_PROGRESS',
  OrderCompleted = 'ORDER_COMPLETED',
  OrderDeleted = 'ORDER_DELETED',
  CurrentEvalSelected = 'CURRENT_EVAL_SELECTED',
  CurrentEvalUpdated = 'CURRENT_EVAL_UPDATED',
  TrackCreated = 'TRACK_CREATED',
  TrackReplied = 'TRACK_REPLIED',
  RegistrationRequested = 'REGISTRATION_REQUESTED',
  RegistrationApproved = 'REGISTRATION_APPROVED',
  Appointment = 'appointment'
}

export enum OcculusalMuscle {
  Normal = 'normal',
  Strong = 'strong',
  Weak = 'weak'
}

export type OcculusalPlane = {
  __typename?: 'OcculusalPlane';
  occulusalPlaneStatus: OcculusalPlaneStatus;
  instruction: Instruction;
};

export type OcculusalPlaneInput = {
  occulusalPlaneStatus?: Maybe<OcculusalPlaneStatus>;
  instruction?: Maybe<Instruction>;
};

export enum OcculusalPlaneStatus {
  Level = 'level',
  Skew = 'skew'
}

export enum Offset {
  Centered = 'centered',
  ShiftedRight = 'shiftedRight',
  ShiftedLeft = 'shiftedLeft'
}

export type Order = Node & {
  __typename?: 'Order';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 訂單編號 */
  oid: Scalars['String'];
  /** 顯示用訂單編號（六碼） */
  displayId: Scalars['String'];
  /** 創建者 */
  creator: Entity;
  /** 已綁定工單中，最晚的預計出貨日 */
  dueDate?: Maybe<Scalars['Date']>;
  /** 病患 */
  patient: Patient;
  /** 訂單種類 */
  type: OrderType;
  /** 下訂內容 */
  stageOrder: Array<StageOrderContent>;
  /** 已綁定工單中 */
  stageProvide: Array<StageProvideContent>;
  /** 是否已完全綁定 */
  isFulfilled: Scalars['Boolean'];
  /** 狀態 */
  status: OrderStatus;
  /** 是否為該病患最新的訂單 */
  isLatest: Scalars['Boolean'];
  /** 備註 */
  description: Scalars['String'];
  /** 指示 */
  instruction: Scalars['String'];
  /** 關聯約診 */
  appointment?: Maybe<Appointment>;
};

export type OrdersQuery = {
  patientName?: Maybe<Scalars['String']>;
  patientStatus?: Maybe<Array<PatientStatus>>;
  appointmentInterval?: Maybe<Array<Scalars['Date']>>;
  clinicId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  accountManagerId?: Maybe<Scalars['ID']>;
  salesId?: Maybe<Scalars['ID']>;
  oid?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<OrderType>>;
  status?: Maybe<Array<OrderStatus>>;
  dueDateInterval?: Maybe<Array<Scalars['Date']>>;
  hasAppointment?: Maybe<Scalars['Boolean']>;
  isFulfilled?: Maybe<Scalars['Boolean']>;
};

export enum OrderStatus {
  /** 待處理：工單項目與數量尚未滿足 */
  Pending = 'PENDING',
  /** 進行中：工單項目與數量已滿足 */
  InProgress = 'IN_PROGRESS',
  /** 已完成：工單皆已完成 */
  Completed = 'COMPLETED'
}

export enum OrderType {
  /** 療程正常 */
  NormalPrint = 'NORMAL_PRINT',
  /** 重取模 */
  Remodel = 'REMODEL',
  /** 看報告 */
  Report = 'REPORT',
  /** 配件 */
  Accessory = 'ACCESSORY'
}

export type PagedAccount = {
  __typename?: 'PagedAccount';
  docs: Array<Account>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedAppointment = {
  __typename?: 'PagedAppointment';
  docs: Array<Appointment>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedClinic = {
  __typename?: 'PagedClinic';
  docs: Array<Clinic>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedDiary = {
  __typename?: 'PagedDiary';
  docs: Array<Diary>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedDoctor = {
  __typename?: 'PagedDoctor';
  docs: Array<Doctor>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedEmployee = {
  __typename?: 'PagedEmployee';
  docs: Array<Employee>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedEvalStage = {
  __typename?: 'PagedEvalStage';
  docs: Array<EvalStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedEvent = {
  __typename?: 'PagedEvent';
  docs: Array<Event>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedFeedback = {
  __typename?: 'PagedFeedback';
  docs: Array<Feedback>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedInvoice = {
  __typename?: 'PagedInvoice';
  docs: Array<Invoice>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedLeave = {
  __typename?: 'PagedLeave';
  docs: Array<Leave>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedMoldStage = {
  __typename?: 'PagedMoldStage';
  docs: Array<MoldStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedOrder = {
  __typename?: 'PagedOrder';
  docs: Array<Order>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedPatient = {
  __typename?: 'PagedPatient';
  docs: Array<Patient>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedPrintStage = {
  __typename?: 'PagedPrintStage';
  docs: Array<PrintStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedProduct = {
  __typename?: 'PagedProduct';
  docs: Array<Product>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedSchedule = {
  __typename?: 'PagedSchedule';
  docs: Array<Schedule>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedStage = {
  __typename?: 'PagedStage';
  docs: Array<Stage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedSubStage = {
  __typename?: 'PagedSubStage';
  docs: Array<SubStage>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedTask = {
  __typename?: 'PagedTask';
  docs: Array<Task>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedTrack = {
  __typename?: 'PagedTrack';
  docs: Array<Track>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PagedWear = {
  __typename?: 'PagedWear';
  docs?: Maybe<Array<Wear>>;
  total: Scalars['Int'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
};

export type Patient = Entity & Node & {
  __typename?: 'Patient';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  autoIncrement: Scalars['Int'];
  /** 姓名 */
  name: Scalars['String'];
  /** 付費方案 (新) */
  payment: PatientPayment;
  /** 病患狀況（草稿、評估中、療程中、封存 */
  status: PatientStatus;
  /** 創建者 */
  creator?: Maybe<Entity>;
  /** 負責診所 */
  clinic: Clinic;
  /** 負責醫師 */
  doctor: Doctor;
  /** 負責AM */
  accountManager?: Maybe<Employee>;
  /** 負責業務 */
  sales: Employee;
  /** 負責客服人員 */
  customerService?: Maybe<Employee>;
  /** 負責技師 */
  technician?: Maybe<Employee>;
  /** 下次約診日 */
  nextAppointmentDate?: Maybe<Scalars['Date']>;
  /** 醫師選定之主要報告 */
  currentEvalStage?: Maybe<EvalStage>;
  /** 代號 (客戶模型編號) */
  patientCode: Scalars['String'];
  /** 塔位 (在哪個櫃子，方便尋找用) */
  cabinetCode: Scalars['String'];
  /** 性別 */
  gender?: Maybe<Gender>;
  /** 主要治療牙弓 (上排, 下排) */
  treatArches: TreatArches;
  /** 照片（均儲存於 S3） */
  photos: Photos;
  /** 病患備註 */
  note: PatientNote;
  /** 主訴 */
  chiefComplaint: Scalars['String'];
  /** 顏面觀 */
  profile: Profile;
  /** 口內觀 */
  intraOral: IntraOral;
  /** 矯正症狀 */
  symptoms: Symptoms;
  /** 醫師主要治療指示 */
  doctorInstruction: DoctorInstruction;
  /** 目前哪幾個製造正進行中 */
  currentSteps: Array<Scalars['Int']>;
  /** 病患聊天室 */
  conversation: PatientConversation;
  /** 目前配戴子工單 */
  currentSubStage?: Maybe<SubStage>;
  /** 目前進度 */
  progress?: Maybe<Progress>;
  /** 可否下新訂單 */
  canCreateOrder: Scalars['Boolean'];
  /** 統計資料 */
  meta: PatientMeta;
  invoices: PagedInvoice;
  stages?: Maybe<PagedStage>;
  orders?: Maybe<PagedOrder>;
  events?: Maybe<PagedEvent>;
  diaries?: Maybe<PagedDiary>;
  tracks?: Maybe<PagedTrack>;
  subStages?: Maybe<PagedSubStage>;
  wears?: Maybe<PagedWear>;
  appointments?: Maybe<PagedAppointment>;
};


export type PatientInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientStagesArgs = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientEventsArgs = {
  query?: Maybe<EventsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientDiariesArgs = {
  query?: Maybe<PatientDiariesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientTracksArgs = {
  query?: Maybe<TracksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientSubStagesArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientWearsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientAppointmentsArgs = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export enum PatientBrand {
  Sov = 'SOV',
  Smilux = 'SMILUX',
  Retainer = 'RETAINER',
  Oem = 'OEM',
  Traditional = 'TRADITIONAL',
  Unknown = 'UNKNOWN'
}

export type PatientConversation = Conversation & Node & {
  __typename?: 'PatientConversation';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: ConversationType;
  members: Array<Entity>;
  readMessageRecords: Array<ReadMessageRecord>;
  latestMessage?: Maybe<Message>;
  patient: Patient;
};

export type PatientDiariesQuery = {
  diaryDateInterval?: Maybe<Array<Scalars['String']>>;
};

export type PatientMeta = {
  __typename?: 'PatientMeta';
  firstEvalStage?: Maybe<Stage>;
  firstEvalStageAt?: Maybe<Scalars['Date']>;
  firstPrintStage?: Maybe<Stage>;
  firstPrintStageAt?: Maybe<Scalars['Date']>;
  lastEvalStage?: Maybe<Stage>;
  lastEvalStageAt?: Maybe<Scalars['Date']>;
  lastPrintStage?: Maybe<Stage>;
  lastPrintStageAt?: Maybe<Scalars['Date']>;
  currentStepNumber: Scalars['Int'];
  advancedStepNumber: Scalars['Int'];
  finalStepNumber: Scalars['Int'];
  techRevenue: Scalars['Int'];
  techCost: Scalars['Int'];
  techProfit: Scalars['Int'];
  brandRevenue: Scalars['Int'];
  risk: Scalars['Float'];
};

export type PatientNote = {
  __typename?: 'PatientNote';
  sales: Scalars['String'];
  design: Scalars['String'];
  braces: Scalars['String'];
};

export type PatientNoteInput = {
  sales?: Maybe<Scalars['String']>;
  design?: Maybe<Scalars['String']>;
  braces?: Maybe<Scalars['String']>;
};

export type PatientPayment = {
  __typename?: 'PatientPayment';
  type: PatientPaymentType;
  brand: PatientBrand;
  source: PatientSource;
  patientPrice: Scalars['Int'];
  base: Scalars['Int'];
  referral: Scalars['Int'];
  extension: Scalars['Int'];
  discount: Scalars['Int'];
};

export type PatientPaymentInput = {
  brand?: Maybe<PatientBrand>;
  source?: Maybe<PatientSource>;
  type?: Maybe<PatientPaymentType>;
  patientPrice?: Maybe<Scalars['Int']>;
  base?: Maybe<Scalars['Int']>;
  referral?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
};

export enum PatientPaymentType {
  LumpSum = 'LUMP_SUM',
  Installment = 'INSTALLMENT',
  Advance = 'ADVANCE',
  Unknown = 'UNKNOWN'
}

export type PatientSegment = {
  __typename?: 'PatientSegment';
  title: Scalars['String'];
  clinic?: Maybe<Clinic>;
  brands?: Maybe<Array<PatientBrand>>;
  sources?: Maybe<Array<PatientSource>>;
};

export type PatientSegmentInput = {
  title: Scalars['String'];
  clinicId?: Maybe<Scalars['ID']>;
  brands?: Maybe<Array<PatientBrand>>;
  sources?: Maybe<Array<PatientSource>>;
};

export enum PatientSource {
  Sov = 'SOV',
  Clinic = 'CLINIC',
  Kol = 'KOL',
  Unknown = 'UNKNOWN'
}

export type PatientsQuery = {
  name?: Maybe<Scalars['String']>;
  clinicName?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  accountManagerName?: Maybe<Scalars['String']>;
  technicianName?: Maybe<Scalars['String']>;
  salesName?: Maybe<Scalars['String']>;
  customerServiceName?: Maybe<Scalars['String']>;
  clinic?: Maybe<Scalars['ID']>;
  accountManager?: Maybe<Scalars['ID']>;
  technician?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['ID']>;
  customerService?: Maybe<Scalars['ID']>;
  patientCode?: Maybe<Scalars['String']>;
  cabinetCode?: Maybe<Scalars['String']>;
  nextAppointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  lastEvalStageAtInterval?: Maybe<Array<Scalars['Date']>>;
  lastPrintStageAtInterval?: Maybe<Array<Scalars['Date']>>;
  status?: Maybe<Array<PatientStatus>>;
  paymentBrand?: Maybe<Array<PatientBrand>>;
  paymentSource?: Maybe<Array<PatientSource>>;
};

export type PatientStatistic = {
  __typename?: 'PatientStatistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segment: PatientSegment;
  indicators?: Maybe<Array<PatientStatisticIndicator>>;
};

export type PatientStatisticIndicator = {
  __typename?: 'PatientStatisticIndicator';
  type: PatientStatisticIndicatorType;
  value: Scalars['Int'];
};

export enum PatientStatisticIndicatorType {
  CreatedCount = 'CREATED_COUNT',
  StartEvalCount = 'START_EVAL_COUNT',
  StartPrintCount = 'START_PRINT_COUNT',
  FinishedCount = 'FINISHED_COUNT'
}

export type PatientStatisticQuery = {
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  segments: Array<PatientSegmentInput>;
  indicatorType: PatientStatisticIndicatorType;
};

export enum PatientStatus {
  Initial = 'INITIAL',
  OnEval = 'ON_EVAL',
  OnPrint = 'ON_PRINT',
  Completed = 'COMPLETED',
  Inactive = 'INACTIVE'
}

export type PatientStatusAndCountMap = {
  __typename?: 'PatientStatusAndCountMap';
  status: PatientStatus;
  count: Scalars['Int'];
};

export enum PatientType {
  Normal = 'normal',
  Referral = 'referral'
}

export enum Payment {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export enum PaymentCategory {
  ClinicOrder = 'CLINIC_ORDER',
  ReferralOrder = 'REFERRAL_ORDER',
  Undecided = 'UNDECIDED'
}

export enum PaymentType {
  StepByStep = 'step_by_step',
  StepByStepSingleArch = 'step_by_step_single_arch',
  PayInFullHigh = 'pay_in_full_high',
  PayInFullLow = 'pay_in_full_low',
  PayInFullSingleArch = 'pay_in_full_single_arch',
  SimpleInstallment = 'simple_installment',
  StepByStepSelfGun = 'step_by_step_self_gun',
  StepByStepSelfGunSingleArch = 'step_by_step_self_gun_single_arch',
  SpecialSales = 'special_sales'
}

export type Photos = {
  __typename?: 'Photos';
  frontFace?: Maybe<File>;
  frontFaceWithTeeth?: Maybe<File>;
  sideFace45?: Maybe<File>;
  sideFace45WithTeeth?: Maybe<File>;
  sideFace?: Maybe<File>;
  sideFaceWithTeeth?: Maybe<File>;
  leftInside?: Maybe<File>;
  frontInside?: Maybe<File>;
  rightInside?: Maybe<File>;
  upperJawInside?: Maybe<File>;
  lowerJawInside?: Maybe<File>;
  pano?: Maybe<File>;
  ceph?: Maybe<File>;
};

export type PhotosInput = {
  frontFace?: Maybe<Scalars['String']>;
  frontFaceWithTeeth?: Maybe<Scalars['String']>;
  sideFace45?: Maybe<Scalars['String']>;
  sideFace45WithTeeth?: Maybe<Scalars['String']>;
  sideFace?: Maybe<Scalars['String']>;
  sideFaceWithTeeth?: Maybe<Scalars['String']>;
  leftInside?: Maybe<Scalars['String']>;
  frontInside?: Maybe<Scalars['String']>;
  rightInside?: Maybe<Scalars['String']>;
  upperJawInside?: Maybe<Scalars['String']>;
  lowerJawInside?: Maybe<Scalars['String']>;
  pano?: Maybe<Scalars['String']>;
  ceph?: Maybe<Scalars['String']>;
};

export enum Platform {
  Erp = 'ERP',
  Crm = 'CRM',
  App = 'APP'
}

export type PrintStage = Stage & Node & {
  __typename?: 'PrintStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: StageType;
  status: StageStatus;
  shippingDate?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  patient: Patient;
  tasks: Array<Task>;
  currentTask?: Maybe<Task>;
  delayedStatus?: Maybe<DelayedStatus>;
  /** 編號 */
  serialNumber: Scalars['Int'];
  /** 模型批次 ex: 0822SOV-1(G) */
  rpBatchNumber?: Maybe<Scalars['String']>;
  /** 關聯的設計單 */
  designStage?: Maybe<DesignStage>;
  /** 訂單 */
  order?: Maybe<Order>;
  /** 牙套 */
  subStage?: Maybe<Array<SubStage>>;
  instructionCard?: Maybe<InstructionCard>;
  /** 出貨單 */
  invoice?: Maybe<Invoice>;
};

export type Product = Node & {
  __typename?: 'Product';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  serialNumber: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  spec: Scalars['String'];
  cost: Scalars['Int'];
};

export type ProductsQuery = {
  name?: Maybe<Scalars['String']>;
};

export type ProductStat = {
  __typename?: 'ProductStat';
  product?: Maybe<Product>;
  count?: Maybe<Scalars['Int']>;
};

export type ProductStatQuery = {
  clinic?: Maybe<Scalars['String']>;
  creditDate?: Maybe<Scalars['Date']>;
};

export type Profile = {
  __typename?: 'Profile';
  mandibularMidlineToFacialMidline: Midline;
  occulusalPlane: OcculusalPlane;
  estheticLine: EstheticLine;
  cephAnalysis: CephAnalysis;
  faceRatio: FaceRatio;
  occulusalMuscle: OcculusalMuscle;
  hasGummySmile: Scalars['Boolean'];
  smileLine: SmileLine;
  hasBuccalCorridor: Scalars['Boolean'];
};

export type ProfileInput = {
  mandibularMidlineToFacialMidline?: Maybe<MidlineInput>;
  occulusalPlane?: Maybe<OcculusalPlaneInput>;
  estheticLine?: Maybe<EstheticLineInput>;
  cephAnalysis?: Maybe<CephAnalysis>;
  faceRatio?: Maybe<FaceRatio>;
  occulusalMuscle?: Maybe<OcculusalMuscle>;
  hasGummySmile?: Maybe<Scalars['Boolean']>;
  smileLine?: Maybe<SmileLine>;
  hasBuccalCorridor?: Maybe<Scalars['Boolean']>;
};

export type Progress = {
  __typename?: 'Progress';
  current: Scalars['Int'];
  total: Scalars['Int'];
};

export type Provider = {
  __typename?: 'Provider';
  id?: Maybe<Scalars['String']>;
};

export type Providers = {
  __typename?: 'Providers';
  facebook?: Maybe<Provider>;
  google?: Maybe<Provider>;
  line?: Maybe<Provider>;
  wechat?: Maybe<Provider>;
};

export type Query = {
  __typename?: 'Query';
  accessoryStage: AccessoryStage;
  account: Account;
  accountManagerStatistic: AccountManagerStatistic;
  accountStatus: AccountStatus;
  accounts: PagedAccount;
  appointment: Appointment;
  appointments: PagedAppointment;
  clinic: Clinic;
  clinics: PagedClinic;
  conversation: Conversation;
  conversationMemberOption: Array<Entity>;
  conversationMessages: MessageConnection;
  conversationsWithLatestMessage: ConversationsWithLatestMessageConnection;
  designStage: DesignStage;
  diaries: PagedDiary;
  diary: Diary;
  doctor: Doctor;
  doctors: PagedDoctor;
  employee: Employee;
  employeeTaskStatistic: Array<EmployeeTaskStatistic>;
  employees: PagedEmployee;
  evalStage: EvalStage;
  evalStages: PagedEvalStage;
  event: Event;
  events: PagedEvent;
  expectedShippingStages: Array<DatedStage>;
  feedback: Feedback;
  feedbacks: PagedFeedback;
  groupedCreatedPatientCountMapList: Array<GroupedCreatedPatientCountMap>;
  instructionCards: PagedPrintStage;
  invoice: Invoice;
  invoiceMonths: Array<InvoiceMonth>;
  invoiceStatistic: Array<InvoiceStatistic>;
  invoices: PagedInvoice;
  latestPatientAppointments: PagedAppointment;
  leave: Leave;
  leaves: PagedLeave;
  leavesCanApprove: PagedLeave;
  leavesForCalendar: Array<Leave>;
  message: Message;
  messages: MessageConnection;
  minimumRequiredAppVersion: Scalars['String'];
  /** 專屬收模單清單頁面的 query */
  moldStagesTrack: PagedMoldStage;
  node?: Maybe<Node>;
  nodes: Array<Node>;
  /** 通知列表 */
  notifications?: Maybe<NotificationConnection>;
  numberOfUnreadConversations: Scalars['Int'];
  numberOfUnreadMessages: Scalars['Int'];
  order?: Maybe<Order>;
  orders?: Maybe<PagedOrder>;
  patient?: Maybe<Patient>;
  patientStatistic: Array<PatientStatistic>;
  patients?: Maybe<PagedPatient>;
  printStage: PrintStage;
  product?: Maybe<Product>;
  productStat?: Maybe<Array<Maybe<ProductStat>>>;
  products?: Maybe<PagedProduct>;
  remainAnnual: Scalars['Float'];
  schedule?: Maybe<Schedule>;
  schedules?: Maybe<PagedSchedule>;
  stage: Stage;
  stageCapacity: Array<StageCapacityPerType>;
  stages: PagedStage;
  statistics: Array<Statistic>;
  system: System;
  task?: Maybe<Task>;
  tasks?: Maybe<PagedTask>;
  track?: Maybe<Track>;
  tracks?: Maybe<PagedTrack>;
  /** 未讀通知數 */
  unreadNotificationCount: Scalars['Int'];
  workingTaskStatistic: Array<WorkingTaskStatistic>;
};


export type QueryAccessoryStageArgs = {
  id: Scalars['ID'];
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountManagerStatisticArgs = {
  id: Scalars['ID'];
  query: AccountManagerStatisticQuery;
};


export type QueryAccountStatusArgs = {
  account: Scalars['String'];
};


export type QueryAccountsArgs = {
  query?: Maybe<AccountsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentsArgs = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryClinicArgs = {
  id: Scalars['ID'];
};


export type QueryClinicsArgs = {
  query?: Maybe<ClinicsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryConversationArgs = {
  id: Scalars['ID'];
};


export type QueryConversationMemberOptionArgs = {
  conversation: Scalars['ID'];
  query?: Maybe<ConversationMemberQuery>;
};


export type QueryConversationMessagesArgs = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<ConversationMessagesQueryInput>;
};


export type QueryConversationsWithLatestMessageArgs = {
  query?: Maybe<ConversationsWithLatestMessageQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryDesignStageArgs = {
  id: Scalars['ID'];
};


export type QueryDiariesArgs = {
  query?: Maybe<DiariesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryDiaryArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorsArgs = {
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeeTaskStatisticArgs = {
  query: EmployeeTaskStatisticQuery;
};


export type QueryEmployeesArgs = {
  query?: Maybe<EmployeesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryEvalStageArgs = {
  id: Scalars['ID'];
};


export type QueryEvalStagesArgs = {
  query?: Maybe<EvalStagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  query?: Maybe<EventsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryExpectedShippingStagesArgs = {
  query?: Maybe<ExpectedShippingStagesQuery>;
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};


export type QueryFeedbacksArgs = {
  query?: Maybe<FeedbackQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryGroupedCreatedPatientCountMapListArgs = {
  id: Scalars['ID'];
  query?: Maybe<GroupedCreatedPatientCountMapListQuery>;
};


export type QueryInstructionCardsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceMonthsArgs = {
  query?: Maybe<InvoiceQuery>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryInvoiceStatisticArgs = {
  query: InvoiceStatisticQuery;
};


export type QueryInvoicesArgs = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLatestPatientAppointmentsArgs = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLeaveArgs = {
  id: Scalars['ID'];
};


export type QueryLeavesArgs = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLeavesCanApproveArgs = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryLeavesForCalendarArgs = {
  startDate: Scalars['Date'];
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  query?: Maybe<MessagesQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryMoldStagesTrackArgs = {
  query?: Maybe<MoldStagesTrackQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryNotificationsArgs = {
  query?: Maybe<NotificationsQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryNumberOfUnreadMessagesArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientStatisticArgs = {
  query: PatientStatisticQuery;
};


export type QueryPatientsArgs = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryPrintStageArgs = {
  id: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductStatArgs = {
  query?: Maybe<ProductStatQuery>;
};


export type QueryProductsArgs = {
  query?: Maybe<ProductsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryRemainAnnualArgs = {
  id: Scalars['ID'];
};


export type QueryScheduleArgs = {
  id: Scalars['ID'];
};


export type QuerySchedulesArgs = {
  query?: Maybe<SchedulesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryStageArgs = {
  id: Scalars['ID'];
};


export type QueryStageCapacityArgs = {
  query: StageCapacityQuery;
};


export type QueryStagesArgs = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryStatisticsArgs = {
  query: StatisticQuery;
};


export type QuerySystemArgs = {
  configType: SystemConfigType;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryTrackArgs = {
  id: Scalars['ID'];
};


export type QueryTracksArgs = {
  query?: Maybe<TracksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryUnreadNotificationCountArgs = {
  receiver: Scalars['ID'];
  type: Array<NotificationType>;
  platform: Platform;
};


export type QueryWorkingTaskStatisticArgs = {
  query: WorkingTaskStatisticQuery;
};

export type ReadAllNotificationResponse = {
  __typename?: 'ReadAllNotificationResponse';
  statusCode: Scalars['String'];
  message: Scalars['String'];
};

export type ReadMessageRecord = {
  __typename?: 'ReadMessageRecord';
  member: Entity;
  lastReadMessageDate: Scalars['Date'];
};

export enum ReferralPaymentType {
  StepByStepSelfGun = 'step_by_step_self_gun',
  StepByStepSelfGunSingleArch = 'step_by_step_self_gun_single_arch',
  SpecialSales = 'special_sales'
}

export type RegisterAccountInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  nickname: Scalars['String'];
  entityType: EntityType;
  note: Scalars['String'];
  isPhoneVerified: Scalars['Boolean'];
};

export type RemoveSystemMessage = {
  __typename?: 'RemoveSystemMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type ReplyTrackInput = {
  reply: Scalars['String'];
  replyDate: Scalars['Date'];
};

export type ResidualRoot = {
  __typename?: 'ResidualRoot';
  hasResidualRoot: Scalars['Boolean'];
  instruction?: Maybe<ResidualRootInstruction>;
};

export type ResidualRootInput = {
  hasResidualRoot?: Maybe<Scalars['Boolean']>;
  instruction?: Maybe<ResidualRootInstruction>;
};

export enum ResidualRootInstruction {
  Extract = 'extract',
  Restore = 'restore',
  Maintain = 'maintain'
}

export type Response = {
  __typename?: 'Response';
  status: ResponseStatus;
  message: Scalars['String'];
};

export enum ResponseStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

/** 員工角色 */
export enum Role {
  /** 系統管理員 */
  God = 'god',
  /** 主管 */
  Manager = 'manager',
  /** 技師 */
  Technician = 'technician',
  /** 客經 */
  AccountManager = 'accountManager',
  /** 業務 */
  Sales = 'sales',
  /** 客服 */
  CustomerService = 'customerService',
  /** 秘書 */
  Secretary = 'secretary',
  /** 一般員工 */
  OfficeWorker = 'officeWorker'
}

export type RoleToPrivilegesListSystem = System & Node & {
  __typename?: 'RoleToPrivilegesListSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 員工角色權限表 */
  roleToPrivilegesList: Array<SystemRoleToPrivileges>;
};

export type Schedule = {
  id: Scalars['ID'];
  type: ScheduleType;
  creator: Entity;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  description: Scalars['String'];
};

export type SchedulesQuery = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  creator?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<ScheduleType>>;
};

export enum ScheduleType {
  /** 病患的約診行程 */
  Appointment = 'APPOINTMENT'
}

export enum ShippingStatus {
  /** 等待出貨 */
  Pending = 'PENDING',
  /** 已出貨 */
  Shipped = 'SHIPPED'
}

export enum SmileLine {
  Great = 'great',
  Ugly = 'ugly'
}

export type Stage = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 種類 */
  type: StageType;
  /** 狀態 */
  status: StageStatus;
  /** 出貨時間 */
  shippingDate?: Maybe<Scalars['Date']>;
  /** 預計出貨時間 */
  expectedShippingDate?: Maybe<Scalars['Date']>;
  /** 工單注意事項 */
  note: Scalars['String'];
  /** 病患 */
  patient: Patient;
  /** 包含任務 */
  tasks: Array<Task>;
  /** 進行中的任務 */
  currentTask?: Maybe<Task>;
  /** 工單任務進行狀態, for calendar */
  delayedStatus?: Maybe<DelayedStatus>;
};

export type StageCapacityPerType = {
  __typename?: 'StageCapacityPerType';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  stageType: StageType;
  actualCount: Scalars['Int'];
  expectedCount: Scalars['Int'];
  forecastCount: Scalars['Int'];
  points: Scalars['Float'];
};

export type StageCapacityQuery = {
  stageTypes: Array<StageType>;
  interval: StatisticInterval;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  patientBrand?: Maybe<PatientBrand>;
};

export type StageCapacitySystem = System & Node & {
  __typename?: 'StageCapacitySystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 工單產能 */
  stageCapacity: MaxStageCapacity;
};

export type StageCostPerType = {
  __typename?: 'StageCostPerType';
  stageType: StageType;
  cost: Scalars['Float'];
};

export type StageCostPerTypeInput = {
  stageType: StageType;
  cost: Scalars['Float'];
};

export type StageCostSystem = System & Node & {
  __typename?: 'StageCostSystem';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
  /** 工單產能 */
  stageCost: Array<StageCostPerType>;
};

export type StageOrderContent = {
  __typename?: 'StageOrderContent';
  stageType: StageType;
  number: Scalars['Int'];
  items?: Maybe<Array<AccessoryCode>>;
};

export type StageOrderContentInput = {
  stageType: StageType;
  number: Scalars['Int'];
  items?: Maybe<Array<Scalars['String']>>;
};

export type StageProvideContent = {
  __typename?: 'StageProvideContent';
  stageType: StageType;
  stages: Array<Stage>;
};

export type StageProvideContentInput = {
  stageType: StageType;
  stages: Array<Scalars['ID']>;
};

export type StagesQuery = {
  patient?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<StageType>>;
  status?: Maybe<Array<StageStatus>>;
  expectedShippingDateInterval?: Maybe<Array<Scalars['Date']>>;
  clinic?: Maybe<Scalars['ID']>;
  accountManagerId?: Maybe<Scalars['ID']>;
  patientIdList?: Maybe<Array<Scalars['ID']>>;
};

export enum StageStatus {
  /** 待啟動 */
  Pending = 'PENDING',
  /** 進行中 */
  Started = 'STARTED',
  /** 已完成 */
  Completed = 'COMPLETED',
  /** 已廢棄 */
  Dropped = 'DROPPED'
}

export enum StageType {
  /** 建模 */
  Mold = 'MOLD',
  /** 報告 */
  Eval = 'EVAL',
  /** 設計 */
  Design = 'DESIGN',
  /** 製造 */
  Print = 'PRINT',
  /** 配件 */
  Accessory = 'ACCESSORY'
}

export type Statistic = {
  __typename?: 'Statistic';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  indicators: Array<StatisticIndicator>;
};

export type StatisticIndicator = {
  __typename?: 'StatisticIndicator';
  type: StatisticIndicatorType;
  value: Scalars['Int'];
};

export enum StatisticIndicatorType {
  Revenue = 'REVENUE',
  Cost = 'COST',
  PatientCountStartPrint = 'PATIENT_COUNT_START_PRINT',
  PatientCountStartEval = 'PATIENT_COUNT_START_EVAL',
  PatientCountProcessing = 'PATIENT_COUNT_PROCESSING',
  PatientCountFinished = 'PATIENT_COUNT_FINISHED',
  PatientCountInactive = 'PATIENT_COUNT_INACTIVE',
  StageCountAdvancedDesign = 'STAGE_COUNT_ADVANCED_DESIGN',
  StageCountAccruedDesign = 'STAGE_COUNT_ACCRUED_DESIGN',
  StageCountDiscardedDesign = 'STAGE_COUNT_DISCARDED_DESIGN'
}

export enum StatisticInterval {
  AllTime = 'ALL_TIME',
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
  Week = 'WEEK',
  Day = 'DAY'
}

export type StatisticQuery = {
  dateSegments: Array<DateSegement>;
  indicatorTypes: Array<StatisticIndicatorType>;
};

export type Step = {
  __typename?: 'Step';
  /** 上顎預估計畫 */
  upperStep: Scalars['String'];
  /** 下顎預估計畫 */
  lowerStep: Scalars['String'];
  /** 是否需要 attachment */
  attachment: Scalars['Boolean'];
  /** 是否需要 ipr */
  ipr: Scalars['Boolean'];
  /** 是否需要 button */
  button: Scalars['Boolean'];
  /** 是否為重新取模 */
  retrieve: Scalars['Boolean'];
};

export type StepInput = {
  upperStep?: Maybe<Scalars['String']>;
  lowerStep?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Boolean']>;
  ipr?: Maybe<Scalars['Boolean']>;
  button?: Maybe<Scalars['Boolean']>;
  retrieve?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated?: Maybe<Notification>;
  onMessageAdded: MessageEdge;
};


export type SubscriptionNotificationCreatedArgs = {
  receiver: Scalars['ID'];
  type: Array<NotificationType>;
  platform: Platform;
};


export type SubscriptionOnMessageAddedArgs = {
  payload: SubscriptionPayload;
};

export enum SubscriptionEvents {
  AddMessage = 'ADD_MESSAGE',
  AddNotification = 'ADD_NOTIFICATION'
}

export type SubscriptionPayload = {
  subscriptionType: SubscriptionType;
  subscriber?: Maybe<Scalars['ID']>;
  conversation?: Maybe<Scalars['ID']>;
};

export enum SubscriptionType {
  /** 所有聊天室 */
  All = 'ALL',
  /** 自己是成員的聊天室 */
  Self = 'SELF',
  /** 特定聊天室 */
  Specific = 'SPECIFIC'
}

export type SubStage = Node & {
  __typename?: 'SubStage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  stage: PrintStage;
  wears?: Maybe<PagedWear>;
  serialNumber: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
};


export type SubStageWearsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type Symptoms = {
  __typename?: 'Symptoms';
  leftMolarRelationship: AnglesClassification;
  rightMolarRelationship: AnglesClassification;
  anteriorCrossbite: Scalars['Boolean'];
  anteriorCrowding: Scalars['Boolean'];
  bimaxillaryProtrusion: Scalars['Boolean'];
  deepBite: Scalars['Boolean'];
  highCanine: Scalars['Boolean'];
  openBite: Scalars['Boolean'];
  posteriorCrossbite: Scalars['Boolean'];
  posteriorCrowding: Scalars['Boolean'];
  vShapeArch: Scalars['Boolean'];
};

export type SymptomsInput = {
  leftMolarRelationship?: Maybe<AnglesClassification>;
  rightMolarRelationship?: Maybe<AnglesClassification>;
  anteriorCrossbite?: Maybe<Scalars['Boolean']>;
  anteriorCrowding?: Maybe<Scalars['Boolean']>;
  bimaxillaryProtrusion?: Maybe<Scalars['Boolean']>;
  deepBite?: Maybe<Scalars['Boolean']>;
  highCanine?: Maybe<Scalars['Boolean']>;
  openBite?: Maybe<Scalars['Boolean']>;
  posteriorCrossbite?: Maybe<Scalars['Boolean']>;
  posteriorCrowding?: Maybe<Scalars['Boolean']>;
  vShapeArch?: Maybe<Scalars['Boolean']>;
};

export type System = {
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  /** 系統設定類別 */
  configType: SystemConfigType;
};

export enum SystemConfigType {
  /** 工單產能 */
  MaxWorkCapacity = 'MAX_WORK_CAPACITY',
  /** 工單成本 */
  StageCost = 'STAGE_COST',
  /** 員工角色預設權限 */
  RoleToPrivileges = 'ROLE_TO_PRIVILEGES',
  /** 非員工實體預設權限 */
  EntityTypeToPrivileges = 'ENTITY_TYPE_TO_PRIVILEGES',
  /** APP 最低需求版本 */
  MinimumRequiredAppVersion = 'MINIMUM_REQUIRED_APP_VERSION'
}

export type SystemEntityTypeToPrivileges = {
  __typename?: 'SystemEntityTypeToPrivileges';
  entityType: EntityType;
  privileges: Array<AllPrivilege>;
};

export type SystemEntityTypeToPrivilegesInput = {
  entityType: EntityType;
  privileges: Array<AllPrivilege>;
};

export type SystemMaxStageCapacityInput = {
  capacityPerType: Array<SystemStageCapacityPerTypeInput>;
  maxPoints: Scalars['Float'];
};

export type SystemMessage = {
  __typename?: 'SystemMessage';
  message: Scalars['String'];
};

export type SystemRoleToPrivileges = {
  __typename?: 'SystemRoleToPrivileges';
  role: Role;
  privileges: Array<AllPrivilege>;
};

export type SystemRoleToPrivilegesInput = {
  role: Role;
  privileges: Array<AllPrivilege>;
};

export type SystemStageCapacityPerTypeInput = {
  stageType: StageType;
  pointsPerUnit: Scalars['Float'];
  maxPoints: Scalars['Float'];
};

export type Task = Node & {
  __typename?: 'Task';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  type: TaskType;
  status: TaskStatus;
  startedByEmployee?: Maybe<Scalars['Date']>;
  completedByOwner?: Maybe<Scalars['Date']>;
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note: Scalars['String'];
  /** 是否已延遲 */
  isDelayed: Scalars['Boolean'];
  patient: Patient;
  stage: Stage;
  owner?: Maybe<Entity>;
  previousTask?: Maybe<Task>;
  nextTask?: Maybe<Task>;
};

export type TasksQuery = {
  expectedShippingDateInterval?: Maybe<Array<Scalars['Date']>>;
  owner?: Maybe<Scalars['ID']>;
  patient?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['ID']>;
  startedByEmployeeInterval?: Maybe<Array<Scalars['Date']>>;
  completedByOwnerInterval?: Maybe<Array<Scalars['Date']>>;
  status?: Maybe<Array<TaskStatus>>;
  type?: Maybe<Array<TaskType>>;
};

export enum TaskStatus {
  Pending = 'pending',
  Working = 'working',
  Completed = 'completed',
  Dropped = 'dropped'
}

export enum TaskType {
  StartScan = 'start_scan',
  AssignScan = 'assign_scan',
  CompleteScan = 'complete_scan',
  CompleteBase = 'complete_base',
  CompleteSplit = 'complete_split',
  SubmitFile = 'submit_file',
  StartEval = 'start_eval',
  ConfirmMold = 'confirm_mold',
  AmCheck = 'am_check',
  AssignAlign = 'assign_align',
  CompleteAlign = 'complete_align',
  AssignPredesign = 'assign_predesign',
  CompletePredesign = 'complete_predesign',
  SubmitPredesign = 'submit_predesign',
  AssignViewer = 'assign_viewer',
  CompleteViewer = 'complete_viewer',
  SubmitViewer = 'submit_viewer',
  AssignDesign = 'assign_design',
  CompleteDesign = 'complete_design',
  SubmitDesign = 'submit_design',
  StartPrint = 'start_print',
  ConfirmDesign = 'confirm_design',
  CompleteRp = 'complete_rp',
  CompleteBraces = 'complete_braces',
  CreateInvoice = 'create_invoice',
  ConfirmShip = 'confirm_ship',
  AssignBraces = 'assign_braces',
  AssignQc = 'assign_qc',
  AssignRp = 'assign_rp',
  AssignSplit = 'assign_split',
  AssignUpload = 'assign_upload',
  CompleteQc = 'complete_qc',
  CompleteUpload = 'complete_upload',
  StartDesign = 'start_design',
  SubmitBraces = 'submit_braces',
  SubmitQc = 'submit_qc',
  SubmitRp = 'submit_rp',
  SubmitScan = 'submit_scan',
  SubmitSplit = 'submit_split'
}

export type TextMessage = Message & Node & {
  __typename?: 'TextMessage';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  conversation: Conversation;
  type: MessageType;
  creator: Entity;
  content: Scalars['String'];
};

export type ToothFile = {
  __typename?: 'ToothFile';
  /** 牙位 */
  position: ToothPosition;
  /** 關聯檔案 */
  file: File;
};

export type ToothFileInput = {
  position: ToothPosition;
  file: Scalars['ID'];
};

export enum ToothPosition {
  Fdi_1_1 = 'FDI_1_1',
  Fdi_1_2 = 'FDI_1_2',
  Fdi_1_3 = 'FDI_1_3',
  Fdi_1_4 = 'FDI_1_4',
  Fdi_1_5 = 'FDI_1_5',
  Fdi_1_6 = 'FDI_1_6',
  Fdi_1_7 = 'FDI_1_7',
  Fdi_1_8 = 'FDI_1_8',
  Fdi_2_1 = 'FDI_2_1',
  Fdi_2_2 = 'FDI_2_2',
  Fdi_2_3 = 'FDI_2_3',
  Fdi_2_4 = 'FDI_2_4',
  Fdi_2_5 = 'FDI_2_5',
  Fdi_2_6 = 'FDI_2_6',
  Fdi_2_7 = 'FDI_2_7',
  Fdi_2_8 = 'FDI_2_8',
  Fdi_3_1 = 'FDI_3_1',
  Fdi_3_2 = 'FDI_3_2',
  Fdi_3_3 = 'FDI_3_3',
  Fdi_3_4 = 'FDI_3_4',
  Fdi_3_5 = 'FDI_3_5',
  Fdi_3_6 = 'FDI_3_6',
  Fdi_3_7 = 'FDI_3_7',
  Fdi_3_8 = 'FDI_3_8',
  Fdi_4_1 = 'FDI_4_1',
  Fdi_4_2 = 'FDI_4_2',
  Fdi_4_3 = 'FDI_4_3',
  Fdi_4_4 = 'FDI_4_4',
  Fdi_4_5 = 'FDI_4_5',
  Fdi_4_6 = 'FDI_4_6',
  Fdi_4_7 = 'FDI_4_7',
  Fdi_4_8 = 'FDI_4_8'
}

export type ToothStatus = {
  __typename?: 'ToothStatus';
  position: ToothPosition;
  type: ToothStatusType;
};

export type ToothStatusInput = {
  position?: Maybe<ToothPosition>;
  type?: Maybe<ToothStatusType>;
};

export enum ToothStatusType {
  Missing = 'MISSING',
  DontMove = 'DONT_MOVE',
  WillExtract = 'WILL_EXTRACT',
  DontPutAttachment = 'DONT_PUT_ATTACHMENT'
}

export enum ToothType {
  Wisdom = 'wisdom',
  Incisor = 'incisor',
  Premolar = 'premolar'
}

export type Track = Node & {
  __typename?: 'Track';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  clinic: Clinic;
  patient: Patient;
  stage: PrintStage;
  photo?: Maybe<TrackPhoto>;
  mainIssues: Array<TrackIssue>;
  extraIssue?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['Date']>;
};

export enum TrackIssue {
  ProtrudingEdge = 'PROTRUDING_EDGE',
  ToothPain = 'TOOTH_PAIN',
  JointPain = 'JOINT_PAIN',
  BraceBroken = 'BRACE_BROKEN'
}

export type TrackPhoto = {
  __typename?: 'TrackPhoto';
  withBrace?: Maybe<TrackPhotoBase>;
  withoutBrace?: Maybe<TrackPhotoBase>;
};

export type TrackPhotoBase = {
  __typename?: 'TrackPhotoBase';
  frontLoose?: Maybe<File>;
  frontTight?: Maybe<File>;
  leftTight?: Maybe<File>;
  rightTight?: Maybe<File>;
};

export type TrackPhotoBaseInput = {
  frontLoose?: Maybe<Scalars['ID']>;
  frontTight?: Maybe<Scalars['ID']>;
  leftTight?: Maybe<Scalars['ID']>;
  rightTight?: Maybe<Scalars['ID']>;
};

export type TrackPhotoInput = {
  withBrace?: Maybe<TrackPhotoBaseInput>;
  withoutBrace?: Maybe<TrackPhotoBaseInput>;
};

export type TracksQuery = {
  clinicName?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['ID']>;
  patientName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  isReply?: Maybe<Scalars['Boolean']>;
};

export type TransferPatientsInput = {
  accountManager?: Maybe<Scalars['ID']>;
  sales?: Maybe<Scalars['ID']>;
  technician?: Maybe<Scalars['ID']>;
  customerService?: Maybe<Scalars['ID']>;
};

export type TreatArches = {
  __typename?: 'TreatArches';
  upper: Scalars['Boolean'];
  lower: Scalars['Boolean'];
};

export type TreatArchesInput = {
  upper?: Maybe<Scalars['Boolean']>;
  lower?: Maybe<Scalars['Boolean']>;
};

export enum TreatStatus {
  No = 'no',
  Treated = 'treated',
  Untreat = 'untreat'
}

export type UneruptedWisdomTooth = {
  __typename?: 'UneruptedWisdomTooth';
  hasUneruptedWisdomTooth: Scalars['Boolean'];
  instruction?: Maybe<UneruptedWisdomToothInstruction>;
};

export type UneruptedWisdomToothInput = {
  hasUneruptedWisdomTooth?: Maybe<Scalars['Boolean']>;
  instruction?: Maybe<UneruptedWisdomToothInstruction>;
};

export enum UneruptedWisdomToothInstruction {
  Extract = 'extract',
  DontDistalize = 'dontDistalize'
}

export type UpdateAccessoryStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  rpModelNumber?: Maybe<Scalars['String']>;
  rpBatchNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
};

export type UpdateAccountInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  backupEmail?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  extraPrivileges?: Maybe<Array<AllPrivilege>>;
  entity?: Maybe<Scalars['ID']>;
  notificationSetting?: Maybe<NotificationSettingInput>;
  currentAppStorage?: Maybe<Scalars['String']>;
  shouldClearStorage?: Maybe<Scalars['Boolean']>;
  lastStorageClearDate?: Maybe<Scalars['String']>;
  lastLoginClientInfo?: Maybe<Scalars['String']>;
};

export type UpdateAnalysisInput = {
  digiAnalysis?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<StepInput>>;
};

export type UpdateAppointmentInput = {
  creator?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['ID']>;
  isAttended?: Maybe<Scalars['Boolean']>;
};

export type UpdateAppointmentsInput = {
  creator?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['ID']>;
  isAttended?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdateCheckPointInput = {
  goalStep: Scalars['Int'];
  upper: Scalars['String'];
  lower: Scalars['String'];
  note: Scalars['String'];
  instruction?: Maybe<Array<AuxiliaryType>>;
};

export type UpdateClinicInput = {
  type?: Maybe<ClinicType>;
  name?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  location?: Maybe<ClinicLocationCreateInput>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  doctors?: Maybe<Array<Scalars['ID']>>;
  accountManagers?: Maybe<Array<Scalars['ID']>>;
  technicians?: Maybe<Array<Scalars['ID']>>;
  sales?: Maybe<Array<Scalars['ID']>>;
  customerService?: Maybe<Scalars['ID']>;
  specialContract?: Maybe<Scalars['Boolean']>;
  deliveryMethod?: Maybe<DeliveryMethod>;
};

export type UpdateDesignStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['Int']>;
  files?: Maybe<DesignStageFilesInput>;
  step?: Maybe<StepInput>;
  rpModelNumber?: Maybe<Scalars['String']>;
  /** 模型數量 */
  upperModelCount?: Maybe<Scalars['Int']>;
  lowerModelCount?: Maybe<Scalars['Int']>;
  /** 附件模板 */
  upperAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  lowerAttachmentTemplateCount?: Maybe<Scalars['Int']>;
  /** 防磨牙套 */
  upperMouthGuardCount?: Maybe<Scalars['Int']>;
  lowerMouthGuardCount?: Maybe<Scalars['Int']>;
  /** 牙套/維持器 */
  upperBraceCount?: Maybe<Scalars['Int']>;
  upperBraceMaterial?: Maybe<BraceMaterial>;
  upperBraceThickness?: Maybe<BraceThickness>;
  lowerBraceCount?: Maybe<Scalars['Int']>;
  lowerBraceMaterial?: Maybe<BraceMaterial>;
  lowerBraceThickness?: Maybe<BraceThickness>;
};

export type UpdateDiaryInput = {
  diaryDate?: Maybe<Scalars['Date']>;
  photos?: Maybe<Array<Scalars['ID']>>;
  text?: Maybe<Scalars['String']>;
  subStage?: Maybe<Scalars['ID']>;
  wearTime?: Maybe<Scalars['Int']>;
};

export type UpdateDoctorInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  level?: Maybe<DoctorLevel>;
};

export type UpdateEmployeeBatchInput = {
  id: Scalars['ID'];
  taskOwner?: Maybe<Array<TaskType>>;
};

export type UpdateEmployeeInput = {
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  birthDate?: Maybe<Scalars['Date']>;
  isMarried?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  backupEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lineId?: Maybe<Scalars['String']>;
  onBoardDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  taskOwner?: Maybe<Array<TaskType>>;
  approvers?: Maybe<Array<Scalars['ID']>>;
  resigned?: Maybe<Scalars['Boolean']>;
};

export type UpdateEvalStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  stepsOverview?: Maybe<Scalars['ID']>;
  moldStage?: Maybe<Scalars['ID']>;
};

export type UpdateFeedbackInput = {
  category?: Maybe<FeedbackCategory>;
  screenshotList?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  status?: Maybe<FeedbackStatus>;
  entityType?: Maybe<EntityType>;
  entity?: Maybe<Scalars['ID']>;
};

export type UpdateInvoicePaymentInput = {
  payment?: Maybe<Payment>;
};

export type UpdateInvoiceWithoutStageInput = {
  discount?: Maybe<Scalars['Float']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType?: Maybe<HomeDeliveryType>;
  invoiceNumber?: Maybe<Scalars['String']>;
  middlemanFee?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  creditDate?: Maybe<Scalars['Date']>;
  shippingDate?: Maybe<Scalars['Date']>;
  payment?: Maybe<Payment>;
  products?: Maybe<Array<InvoiceProductInput>>;
  tax?: Maybe<Scalars['Boolean']>;
};

export type UpdateInvoiceWithStageInput = {
  discount?: Maybe<Scalars['Float']>;
  homeDeliveryId?: Maybe<Scalars['String']>;
  homeDeliveryType?: Maybe<HomeDeliveryType>;
  invoiceNumber?: Maybe<Scalars['String']>;
  middlemanFee?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  creditDate?: Maybe<Scalars['Date']>;
  shippingDate?: Maybe<Scalars['Date']>;
  payment?: Maybe<Payment>;
  products?: Maybe<Array<InvoiceProductInput>>;
  tax?: Maybe<Scalars['Boolean']>;
};

export type UpdateLeaveInput = {
  employee?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  type?: Maybe<LeaveType>;
  reason?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type UpdateMessage = {
  creator?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['ID']>;
};

export type UpdateMoldStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  moldType?: Maybe<MoldType>;
};

export type UpdateOrderInput = {
  stageOrder?: Maybe<Array<StageOrderContentInput>>;
  stageProvide?: Maybe<Array<StageProvideContentInput>>;
  type?: Maybe<OrderType>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  appointmentDateInterval?: Maybe<Array<Scalars['Date']>>;
  appointmentDate?: Maybe<Scalars['Date']>;
};

export type UpdatePatientInput = {
  payment?: Maybe<PatientPaymentInput>;
  /** 負責診所 */
  clinic?: Maybe<Scalars['ID']>;
  /** 負責醫師 */
  doctor?: Maybe<Scalars['ID']>;
  /** 負責AM */
  accountManager?: Maybe<Scalars['ID']>;
  /** 負責業務 */
  sales?: Maybe<Scalars['ID']>;
  /** 負責客服人員 */
  customerService?: Maybe<Scalars['ID']>;
  /** 負責技師 */
  technician?: Maybe<Scalars['ID']>;
  /** 下次約診日 */
  nextAppointmentDate?: Maybe<Scalars['Date']>;
  /** 姓名 */
  name?: Maybe<Scalars['String']>;
  /** 代號 (客戶模型編號) */
  patientCode?: Maybe<Scalars['String']>;
  /** 塔位 (在哪個櫃子，方便尋找用) */
  cabinetCode?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<Gender>;
  /** 主要治療牙弓 (上排, 下排) */
  treatArches?: Maybe<TreatArchesInput>;
  /** 醫師主要治療指示 */
  instruction?: Maybe<DoctorInstructionInput>;
  /** 照片（均儲存於 S3） */
  photos?: Maybe<PhotosInput>;
  /** 病患備註 */
  note?: Maybe<PatientNoteInput>;
  /** 主訴 */
  chiefComplaint?: Maybe<Scalars['String']>;
  /** 顏面觀 */
  profile?: Maybe<ProfileInput>;
  /** 口內觀 */
  intraOral?: Maybe<IntraOralInput>;
  /** 矯正症狀 */
  symptoms?: Maybe<SymptomsInput>;
  /** 醫師主要治療指示 */
  doctorInstruction?: Maybe<DoctorInstructionInput>;
};

export type UpdatePatientStatusInput = {
  status?: Maybe<Scalars['String']>;
};

export type UpdatePrintStageInput = {
  expectedShippingDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  serialNumber?: Maybe<Scalars['Int']>;
  rpBatchNumber?: Maybe<Scalars['String']>;
  designStage?: Maybe<Scalars['ID']>;
};

export type UpdateProductInput = {
  serialNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  spec?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
};

export type UpdateSystemEntityTypeToPrivilegesInput = {
  entityTypeToPrivilegesList?: Maybe<Array<SystemEntityTypeToPrivilegesInput>>;
};

export type UpdateSystemMaxStageCapacityInput = {
  stageCapacity: SystemMaxStageCapacityInput;
};

export type UpdateSystemRoleToPrivilegesInput = {
  roleToPrivilegesList?: Maybe<Array<SystemRoleToPrivilegesInput>>;
};

export type UpdateSystemStageCostInput = {
  stageCost: Array<StageCostPerTypeInput>;
};

export type UpdateTrackInput = {
  clinic?: Maybe<Scalars['ID']>;
  patient?: Maybe<Scalars['ID']>;
  stage?: Maybe<Scalars['ID']>;
  photo?: Maybe<TrackPhotoInput>;
  mainIssues?: Maybe<Array<TrackIssue>>;
  extraIssue?: Maybe<Scalars['String']>;
};

export type UpdateWearInput = {
  subStage: Scalars['ID'];
  date: Scalars['Date'];
  time: Scalars['Int'];
};


export enum VerificationType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type ViewerFilesInput = {
  teeth?: Maybe<Array<ToothFileInput>>;
  upperJawModel?: Maybe<Scalars['ID']>;
  lowerJawModel?: Maybe<Scalars['ID']>;
};

export type Wear = Node & {
  __typename?: 'Wear';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  patient: Patient;
  subStage: SubStage;
  date: Scalars['Date'];
  time: Scalars['Int'];
};

export type WorkingTaskStatistic = {
  __typename?: 'WorkingTaskStatistic';
  owner: Employee;
  taskType: TaskType;
  count: Scalars['Int'];
};

export type WorkingTaskStatisticQuery = {
  assignableEmployeeTaskType: TaskType;
  filteredTaskTypeList: Array<TaskType>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export type StageCapacityQueryQueryVariables = {
  query: StageCapacityQuery;
};


export type StageCapacityQueryQuery = (
  { __typename?: 'Query' }
  & { stageCapacity: Array<(
    { __typename?: 'StageCapacityPerType' }
    & Pick<StageCapacityPerType, 'stageType' | 'startDate' | 'endDate' | 'points'>
  )>, system: { __typename?: 'MinimumRequiredAppVersionSystem' } | { __typename?: 'EntityTypeToPrivilegesListSystem' } | (
    { __typename?: 'StageCapacitySystem' }
    & { stageCapacity: (
      { __typename?: 'MaxStageCapacity' }
      & Pick<MaxStageCapacity, 'maxPoints'>
      & { capacityPerType: Array<(
        { __typename?: 'MaxStageCapacityPerType' }
        & Pick<MaxStageCapacityPerType, 'stageType' | 'pointsPerUnit' | 'maxPoints'>
      )> }
    ) }
  ) | { __typename?: 'RoleToPrivilegesListSystem' } | { __typename?: 'StageCostSystem' } }
);

export type InstructionTagListFragment = (
  { __typename?: 'InstructionCard' }
  & Pick<InstructionCard, 'retrieve'>
  & { auxiliaries: Array<(
    { __typename?: 'Auxiliary' }
    & Pick<Auxiliary, 'type'>
  )> }
);

export type MessageInboxQueryQueryVariables = {
  query?: Maybe<ConversationsWithLatestMessageQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type MessageInboxQueryQuery = (
  { __typename?: 'Query' }
  & { conversationsWithLatestMessage: (
    { __typename?: 'ConversationsWithLatestMessageConnection' }
    & MessageInboxFragment
  ) }
);

export type NotificationIconQueryVariables = {
  query?: Maybe<NotificationsQuery>;
  first?: Maybe<Scalars['Int']>;
};


export type NotificationIconQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationConnection' }
    & { edges: Array<(
      { __typename?: 'NotificationEdge' }
      & { node: (
        { __typename?: 'AppointmentNotification' }
        & NotificationItem_AppointmentNotification_Fragment
      ) | (
        { __typename?: 'NormalNotification' }
        & NotificationItem_NormalNotification_Fragment
      ) }
    )> }
  )> }
);

export type UnreadNotificationCountQueryVariables = {
  receiver: Scalars['ID'];
  type: Array<NotificationType>;
  platform: Platform;
};


export type UnreadNotificationCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'unreadNotificationCount'>
);

export type ReadNotificationMutationMutationVariables = {
  id: Scalars['ID'];
};


export type ReadNotificationMutationMutation = (
  { __typename?: 'Mutation' }
  & { readNotification: (
    { __typename?: 'AppointmentNotification' }
    & Pick<AppointmentNotification, 'id'>
  ) | (
    { __typename?: 'NormalNotification' }
    & Pick<NormalNotification, 'id'>
  ) }
);

export type ReadAllNotificationMutationMutationVariables = {
  receiver: Scalars['ID'];
};


export type ReadAllNotificationMutationMutation = (
  { __typename?: 'Mutation' }
  & { readAllNotification: (
    { __typename?: 'ReadAllNotificationResponse' }
    & Pick<ReadAllNotificationResponse, 'statusCode' | 'message'>
  ) }
);

export type NotificationCreatedSubscriptionVariables = {
  receiver: Scalars['ID'];
  type: Array<NotificationType>;
  platform: Platform;
};


export type NotificationCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { notificationCreated?: Maybe<(
    { __typename?: 'AppointmentNotification' }
    & Pick<AppointmentNotification, 'id'>
  ) | (
    { __typename?: 'NormalNotification' }
    & Pick<NormalNotification, 'id'>
  )> }
);

export type UploadAnalysisImageMutationVariables = {
  file: Scalars['Upload'];
  options: FileUploadOption;
};


export type UploadAnalysisImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'path'>
  ) }
);

type ViewerFileIcon_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & ViewerFilesOverview_AccessoryStage_Fragment
);

type ViewerFileIcon_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & ViewerFilesOverview_EvalStage_Fragment
);

type ViewerFileIcon_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & ViewerFilesOverview_DesignStage_Fragment
);

type ViewerFileIcon_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & ViewerFilesOverview_PrintStage_Fragment
);

type ViewerFileIcon_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & ViewerFilesOverview_MoldStage_Fragment
);

export type ViewerFileIconFragment = ViewerFileIcon_AccessoryStage_Fragment | ViewerFileIcon_EvalStage_Fragment | ViewerFileIcon_DesignStage_Fragment | ViewerFileIcon_PrintStage_Fragment | ViewerFileIcon_MoldStage_Fragment;

type ViewerFilesOverview_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ) }
  & StageLink_AccessoryStage_Fragment
);

type ViewerFilesOverview_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & { moldStage?: Maybe<(
    { __typename?: 'MoldStage' }
    & StageLink_MoldStage_Fragment
  )>, files?: Maybe<(
    { __typename?: 'EvalStageFiles' }
    & { upperJawModel?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path'>
    )>, lowerJawModel?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path'>
    )>, teeth?: Maybe<Array<(
      { __typename?: 'ToothFile' }
      & Pick<ToothFile, 'position'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'path'>
      ) }
    )>> }
  )>, patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ) }
  & StageLink_EvalStage_Fragment
);

type ViewerFilesOverview_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & { evalStage: (
    { __typename?: 'EvalStage' }
    & StageLink_EvalStage_Fragment
  ), files?: Maybe<(
    { __typename?: 'DesignStageFiles' }
    & { upperJawModel?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path'>
    )>, lowerJawModel?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path'>
    )>, teeth?: Maybe<Array<(
      { __typename?: 'ToothFile' }
      & Pick<ToothFile, 'position'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'path'>
      ) }
    )>> }
  )>, patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ) }
  & StageLink_DesignStage_Fragment
);

type ViewerFilesOverview_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ) }
  & StageLink_PrintStage_Fragment
);

type ViewerFilesOverview_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & { files?: Maybe<(
    { __typename?: 'MoldStageFiles' }
    & { upperJawModel?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path'>
    )>, lowerJawModel?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'path'>
    )>, teeth?: Maybe<Array<(
      { __typename?: 'ToothFile' }
      & Pick<ToothFile, 'position'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'path'>
      ) }
    )>> }
  )>, patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ) }
  & StageLink_MoldStage_Fragment
);

export type ViewerFilesOverviewFragment = ViewerFilesOverview_AccessoryStage_Fragment | ViewerFilesOverview_EvalStage_Fragment | ViewerFilesOverview_DesignStage_Fragment | ViewerFilesOverview_PrintStage_Fragment | ViewerFilesOverview_MoldStage_Fragment;

export type CreateMessageMutationMutationVariables = {
  conversationId: Scalars['ID'];
  payload: CreateMessage;
};


export type CreateMessageMutationMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'TextMessage' }
    & MessageInfo_TextMessage_Fragment
  ) | (
    { __typename?: 'ImageMessage' }
    & MessageInfo_ImageMessage_Fragment
  ) }
);

export type RemoveMessageMutationMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveMessageMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeMessage: (
    { __typename?: 'RemoveSystemMessage' }
    & Pick<RemoveSystemMessage, 'id' | 'message'>
  ) }
);

export type PatientConversationFragment = (
  { __typename?: 'Patient' }
  & { conversation: (
    { __typename?: 'PatientConversation' }
    & Pick<PatientConversation, 'id'>
  ) }
);

export type MemberSelectQueryQueryVariables = {
  conversation: Scalars['ID'];
  query?: Maybe<ConversationMemberQuery>;
};


export type MemberSelectQueryQuery = (
  { __typename?: 'Query' }
  & { conversationMemberOption: Array<(
    { __typename?: 'Patient' }
    & ConversationMemberInfo_Patient_Fragment
  ) | (
    { __typename?: 'Clinic' }
    & ConversationMemberInfo_Clinic_Fragment
  ) | (
    { __typename?: 'Doctor' }
    & ConversationMemberInfo_Doctor_Fragment
  ) | (
    { __typename?: 'Employee' }
    & ConversationMemberInfo_Employee_Fragment
  )> }
);

export type UpdateMembersMutationMutationVariables = {
  id: Scalars['ID'];
  entities: Array<Scalars['ID']>;
};


export type UpdateMembersMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConversationMembers: (
    { __typename?: 'PatientConversation' }
    & ConversationInfoFragment
  ) }
);

type ConversationMemberInfo_Patient_Fragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name'>
);

type ConversationMemberInfo_Clinic_Fragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name'>
);

type ConversationMemberInfo_Doctor_Fragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name'>
);

type ConversationMemberInfo_Employee_Fragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'role' | 'id' | 'name'>
);

export type ConversationMemberInfoFragment = ConversationMemberInfo_Patient_Fragment | ConversationMemberInfo_Clinic_Fragment | ConversationMemberInfo_Doctor_Fragment | ConversationMemberInfo_Employee_Fragment;

export type ConversationInfoFragment = (
  { __typename?: 'PatientConversation' }
  & Pick<PatientConversation, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name' | 'chiefComplaint'>
  ), members: Array<(
    { __typename?: 'Patient' }
    & ConversationMemberInfo_Patient_Fragment
  ) | (
    { __typename?: 'Clinic' }
    & ConversationMemberInfo_Clinic_Fragment
  ) | (
    { __typename?: 'Doctor' }
    & ConversationMemberInfo_Doctor_Fragment
  ) | (
    { __typename?: 'Employee' }
    & ConversationMemberInfo_Employee_Fragment
  )> }
);

type MessageInfo_TextMessage_Fragment = (
  { __typename?: 'TextMessage' }
  & Pick<TextMessage, 'content' | 'id' | 'type' | 'created'>
  & { conversation: (
    { __typename?: 'PatientConversation' }
    & Pick<PatientConversation, 'id'>
  ), creator: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'role' | 'id' | 'name'>
  ) }
);

type MessageInfo_ImageMessage_Fragment = (
  { __typename?: 'ImageMessage' }
  & Pick<ImageMessage, 'id' | 'type' | 'created'>
  & { image: (
    { __typename?: 'File' }
    & ImageCardFragment
  ), conversation: (
    { __typename?: 'PatientConversation' }
    & Pick<PatientConversation, 'id'>
  ), creator: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'role' | 'id' | 'name'>
  ) }
);

export type MessageInfoFragment = MessageInfo_TextMessage_Fragment | MessageInfo_ImageMessage_Fragment;

export type InvoiceTimeLineQueryVariables = {
  id: Scalars['ID'];
};


export type InvoiceTimeLineQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { invoices: (
      { __typename?: 'PagedInvoice' }
      & { docs: Array<(
        { __typename: 'InvoiceWithStage' }
        & Pick<InvoiceWithStage, 'id' | 'creditDate' | 'totalPrice'>
        & { stage: (
          { __typename?: 'AccessoryStage' }
          & StageInvoiceLink_AccessoryStage_Fragment
        ) | (
          { __typename?: 'EvalStage' }
          & StageInvoiceLink_EvalStage_Fragment
        ) | (
          { __typename?: 'DesignStage' }
          & StageInvoiceLink_DesignStage_Fragment
        ) | (
          { __typename?: 'PrintStage' }
          & StageInvoiceLink_PrintStage_Fragment
        ) | (
          { __typename?: 'MoldStage' }
          & StageInvoiceLink_MoldStage_Fragment
        ) }
      ) | (
        { __typename: 'InvoiceWithoutStage' }
        & Pick<InvoiceWithoutStage, 'id' | 'creditDate' | 'totalPrice'>
      )> }
    ) }
  )> }
);

type StageInvoiceLink_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_AccessoryStage_Fragment
);

type StageInvoiceLink_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_EvalStage_Fragment
);

type StageInvoiceLink_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_DesignStage_Fragment
);

type StageInvoiceLink_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_PrintStage_Fragment
);

type StageInvoiceLink_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_MoldStage_Fragment
);

export type StageInvoiceLinkFragment = StageInvoiceLink_AccessoryStage_Fragment | StageInvoiceLink_EvalStage_Fragment | StageInvoiceLink_DesignStage_Fragment | StageInvoiceLink_PrintStage_Fragment | StageInvoiceLink_MoldStage_Fragment;

export type DoctorInClinicDoctorSelectFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name'>
);

export type ClinicDoctorSelectQueryVariables = {
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type ClinicDoctorSelectQuery = (
  { __typename?: 'Query' }
  & { doctors: (
    { __typename?: 'PagedDoctor' }
    & { docs: Array<(
      { __typename?: 'Doctor' }
      & DoctorInClinicDoctorSelectFragment
    )> }
  ) }
);

export type ClinicInClinicSelectFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'type' | 'name' | 'specialContract'>
  & { accountManagers: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
  )>, technicians: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
  )>, salesList: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
  )>, customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
  )> }
);

export type ClinicSelectQueryVariables = {
  query?: Maybe<ClinicsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type ClinicSelectQuery = (
  { __typename?: 'Query' }
  & { clinics: (
    { __typename?: 'PagedClinic' }
    & { docs: Array<(
      { __typename?: 'Clinic' }
      & ClinicInClinicSelectFragment
    )> }
  ) }
);

export type DoctorInDoctorSelectFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name'>
);

export type DoctorSelectQueryVariables = {
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type DoctorSelectQuery = (
  { __typename?: 'Query' }
  & { doctors: (
    { __typename?: 'PagedDoctor' }
    & { docs: Array<(
      { __typename?: 'Doctor' }
      & DoctorInDoctorSelectFragment
    )> }
  ) }
);

export type EmployeeInEmployeeSelectFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
);

export type EmployeeSelectQueryVariables = {
  query?: Maybe<EmployeesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeSelectQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'PagedEmployee' }
    & { docs: Array<(
      { __typename?: 'Employee' }
      & EmployeeInEmployeeSelectFragment
    )> }
  ) }
);

type EntitySelect_Patient_Fragment = (
  { __typename?: 'Patient' }
  & PatientInPatientSelectFragment
);

type EntitySelect_Clinic_Fragment = (
  { __typename?: 'Clinic' }
  & ClinicInClinicSelectFragment
);

type EntitySelect_Doctor_Fragment = (
  { __typename?: 'Doctor' }
  & DoctorInDoctorSelectFragment
);

type EntitySelect_Employee_Fragment = (
  { __typename?: 'Employee' }
  & EmployeeInEmployeeSelectFragment
);

export type EntitySelectFragment = EntitySelect_Patient_Fragment | EntitySelect_Clinic_Fragment | EntitySelect_Doctor_Fragment | EntitySelect_Employee_Fragment;

export type PatientInPatientSelectFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'phone'>
  ) }
);

export type PatientSelectQueryVariables = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientSelectQuery = (
  { __typename?: 'Query' }
  & { patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & { docs: Array<(
      { __typename?: 'Patient' }
      & PatientInPatientSelectFragment
    )> }
  )> }
);

type StageInPatientStageSelect_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & StageName_AccessoryStage_Fragment
);

type StageInPatientStageSelect_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & StageName_EvalStage_Fragment
);

type StageInPatientStageSelect_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & StageName_DesignStage_Fragment
);

type StageInPatientStageSelect_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & StageName_PrintStage_Fragment
);

type StageInPatientStageSelect_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & StageName_MoldStage_Fragment
);

export type StageInPatientStageSelectFragment = StageInPatientStageSelect_AccessoryStage_Fragment | StageInPatientStageSelect_EvalStage_Fragment | StageInPatientStageSelect_DesignStage_Fragment | StageInPatientStageSelect_PrintStage_Fragment | StageInPatientStageSelect_MoldStage_Fragment;

export type PatientStageSelectQueryVariables = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientStageSelectQuery = (
  { __typename?: 'Query' }
  & { stages: (
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & StageInPatientStageSelect_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageInPatientStageSelect_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageInPatientStageSelect_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageInPatientStageSelect_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageInPatientStageSelect_MoldStage_Fragment
    )> }
  ) }
);

type StageInStageSelect_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'status'>
  & StageName_AccessoryStage_Fragment
);

type StageInStageSelect_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'status'>
  & StageName_EvalStage_Fragment
);

type StageInStageSelect_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'status'>
  & StageName_DesignStage_Fragment
);

type StageInStageSelect_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'status'>
  & StageName_PrintStage_Fragment
);

type StageInStageSelect_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'status'>
  & StageName_MoldStage_Fragment
);

export type StageInStageSelectFragment = StageInStageSelect_AccessoryStage_Fragment | StageInStageSelect_EvalStage_Fragment | StageInStageSelect_DesignStage_Fragment | StageInStageSelect_PrintStage_Fragment | StageInStageSelect_MoldStage_Fragment;

export type StageSelectQueryVariables = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type StageSelectQuery = (
  { __typename?: 'Query' }
  & { stages: (
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & StageInStageSelect_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageInStageSelect_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageInStageSelect_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageInStageSelect_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageInStageSelect_MoldStage_Fragment
    )> }
  ) }
);

export type AccountFormInfoFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'email' | 'phone' | 'nickname' | 'backupEmail' | 'isActive' | 'privileges' | 'extraPrivileges' | 'entityType'>
  & { entity?: Maybe<(
    { __typename?: 'Patient' }
    & EntitySelect_Patient_Fragment
  ) | (
    { __typename?: 'Clinic' }
    & EntitySelect_Clinic_Fragment
  ) | (
    { __typename?: 'Doctor' }
    & EntitySelect_Doctor_Fragment
  ) | (
    { __typename?: 'Employee' }
    & EntitySelect_Employee_Fragment
  )>, providers?: Maybe<(
    { __typename?: 'Providers' }
    & { facebook?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, google?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, line?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, wechat?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )> }
  )> }
);

export type AppointmentFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'startDate' | 'endDate' | 'description' | 'isAttended'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ), doctor: (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) }
    & TablePatientInfoFragment
  ), creator: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ) }
);

export type ClinicFormFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'type' | 'name' | 'fullname' | 'phone' | 'email' | 'deliveryMethod' | 'specialContract'>
  & { location: (
    { __typename?: 'ClinicLocation' }
    & Pick<ClinicLocation, 'address' | 'country' | 'district'>
  ), doctors: Array<(
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id'>
  )>, accountManagers: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>, technicians: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>, sales: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>, customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type EmployeeFormInfoFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'email' | 'phone' | 'address' | 'backupEmail' | 'lineId' | 'birthDate' | 'gender' | 'isMarried' | 'onBoardDate' | 'role' | 'title' | 'taskOwner' | 'resigned'>
  & { approvers: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )> }
);

type InvoiceClientInfoStageItem_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & StageLink_AccessoryStage_Fragment
);

type InvoiceClientInfoStageItem_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & StageLink_EvalStage_Fragment
);

type InvoiceClientInfoStageItem_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & StageLink_DesignStage_Fragment
);

type InvoiceClientInfoStageItem_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & StageLink_PrintStage_Fragment
);

type InvoiceClientInfoStageItem_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & StageLink_MoldStage_Fragment
);

export type InvoiceClientInfoStageItemFragment = InvoiceClientInfoStageItem_AccessoryStage_Fragment | InvoiceClientInfoStageItem_EvalStage_Fragment | InvoiceClientInfoStageItem_DesignStage_Fragment | InvoiceClientInfoStageItem_PrintStage_Fragment | InvoiceClientInfoStageItem_MoldStage_Fragment;

export type InvoiceClientInfoPatientItemFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & PatientLinkFragment
);

type InvoiceClientInfoInvoiceItem_InvoiceWithStage_Fragment = (
  { __typename?: 'InvoiceWithStage' }
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & DoctorLinkFragment
  ), sales: (
    { __typename?: 'Employee' }
    & EmployeeLinkFragment
  ) }
);

type InvoiceClientInfoInvoiceItem_InvoiceWithoutStage_Fragment = (
  { __typename?: 'InvoiceWithoutStage' }
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & DoctorLinkFragment
  ), sales: (
    { __typename?: 'Employee' }
    & EmployeeLinkFragment
  ) }
);

export type InvoiceClientInfoInvoiceItemFragment = InvoiceClientInfoInvoiceItem_InvoiceWithStage_Fragment | InvoiceClientInfoInvoiceItem_InvoiceWithoutStage_Fragment;

type FormInvoiceStageItem_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & InvoiceClientInfoStageItem_AccessoryStage_Fragment
);

type FormInvoiceStageItem_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & InvoiceClientInfoStageItem_EvalStage_Fragment
);

type FormInvoiceStageItem_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & InvoiceClientInfoStageItem_DesignStage_Fragment
);

type FormInvoiceStageItem_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & InvoiceClientInfoStageItem_PrintStage_Fragment
);

type FormInvoiceStageItem_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & InvoiceClientInfoStageItem_MoldStage_Fragment
);

export type FormInvoiceStageItemFragment = FormInvoiceStageItem_AccessoryStage_Fragment | FormInvoiceStageItem_EvalStage_Fragment | FormInvoiceStageItem_DesignStage_Fragment | FormInvoiceStageItem_PrintStage_Fragment | FormInvoiceStageItem_MoldStage_Fragment;

export type FormInvoicePatientItemFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & InvoiceClientInfoPatientItemFragment
);

type FormInvoiceItem_InvoiceWithStage_Fragment = (
  { __typename: 'InvoiceWithStage' }
  & Pick<InvoiceWithStage, 'id' | 'shippingDate' | 'creditDate' | 'tax' | 'middlemanFee' | 'discount' | 'payment' | 'homeDeliveryId' | 'homeDeliveryType' | 'invoiceNumber' | 'note'>
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & InvoiceClientInfoStageItem_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & InvoiceClientInfoStageItem_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & InvoiceClientInfoStageItem_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & InvoiceClientInfoStageItem_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & InvoiceClientInfoStageItem_MoldStage_Fragment
  ), patient: (
    { __typename?: 'Patient' }
    & InvoiceClientInfoPatientItemFragment
  ), products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'productId' | 'serialNumber' | 'name' | 'spec' | 'price' | 'cost' | 'count'>
  )> }
  & InvoiceClientInfoInvoiceItem_InvoiceWithStage_Fragment
);

type FormInvoiceItem_InvoiceWithoutStage_Fragment = (
  { __typename: 'InvoiceWithoutStage' }
  & Pick<InvoiceWithoutStage, 'id' | 'shippingDate' | 'creditDate' | 'tax' | 'middlemanFee' | 'discount' | 'payment' | 'homeDeliveryId' | 'homeDeliveryType' | 'invoiceNumber' | 'note'>
  & { products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'productId' | 'serialNumber' | 'name' | 'spec' | 'price' | 'cost' | 'count'>
  )> }
  & InvoiceClientInfoInvoiceItem_InvoiceWithoutStage_Fragment
);

export type FormInvoiceItemFragment = FormInvoiceItem_InvoiceWithStage_Fragment | FormInvoiceItem_InvoiceWithoutStage_Fragment;

type InvoiceMailStageItem_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'accessoryCode' | 'id'>
);

type InvoiceMailStageItem_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'serialNumber' | 'id'>
);

type InvoiceMailStageItem_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'serialNumber' | 'id'>
);

type InvoiceMailStageItem_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'serialNumber' | 'id'>
);

type InvoiceMailStageItem_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'serialNumber' | 'id'>
);

export type InvoiceMailStageItemFragment = InvoiceMailStageItem_AccessoryStage_Fragment | InvoiceMailStageItem_EvalStage_Fragment | InvoiceMailStageItem_DesignStage_Fragment | InvoiceMailStageItem_PrintStage_Fragment | InvoiceMailStageItem_MoldStage_Fragment;

export type InvoiceMailPatientItemFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name'>
);

type InvoiceMailInvoiceItem_InvoiceWithStage_Fragment = (
  { __typename?: 'InvoiceWithStage' }
  & Pick<InvoiceWithStage, 'id' | 'homeDeliveryId' | 'homeDeliveryType'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name' | 'email'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'email'>
  ) }
);

type InvoiceMailInvoiceItem_InvoiceWithoutStage_Fragment = (
  { __typename?: 'InvoiceWithoutStage' }
  & Pick<InvoiceWithoutStage, 'id' | 'homeDeliveryId' | 'homeDeliveryType'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name' | 'email'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'email'>
  ) }
);

export type InvoiceMailInvoiceItemFragment = InvoiceMailInvoiceItem_InvoiceWithStage_Fragment | InvoiceMailInvoiceItem_InvoiceWithoutStage_Fragment;

export type EmployeeLeaveFormInfoFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & { leaveStatus: (
    { __typename?: 'LeaveStatusQuery' }
    & Pick<LeaveStatusQuery, 'remainAnnual' | 'usedSickLeave' | 'usedPersonalLeave'>
  ) }
);

export type LeaveFormInfoFragment = (
  { __typename?: 'Leave' }
  & Pick<Leave, 'id' | 'startDate' | 'endDate' | 'type' | 'reason' | 'isApproved'>
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ) }
);

export type FormPatientBasicFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name' | 'patientCode' | 'cabinetCode' | 'gender' | 'chiefComplaint'>
  & { creator?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name' | 'specialContract'>
    & { accountManagers: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
      & EmployeeLinkFragment
    )>, technicians: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
      & EmployeeLinkFragment
    )>, sales: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
      & EmployeeLinkFragment
    )>, customerService?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
      & EmployeeLinkFragment
    )> }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
    & DoctorLinkFragment
  ), accountManager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
    & EmployeeLinkFragment
  )>, technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
    & EmployeeLinkFragment
  )>, sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
    & EmployeeLinkFragment
  ), customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
    & EmployeeLinkFragment
  )>, treatArches: (
    { __typename?: 'TreatArches' }
    & Pick<TreatArches, 'upper' | 'lower'>
  ), note: (
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'design' | 'braces' | 'sales'>
  ) }
  & PaymentFragment
);

export type FormPatientDoctorInstructionFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { doctorInstruction: (
    { __typename?: 'DoctorInstruction' }
    & Pick<DoctorInstruction, 'note' | 'ipr' | 'tads' | 'otherOrthdontalTools' | 'functionalAppliance'>
    & { maintainSpace: (
      { __typename?: 'MaintainSpace' }
      & Pick<MaintainSpace, 'isMaintainingSpace' | 'note'>
    ), changeProsthesisToTemp: (
      { __typename?: 'ChangeProsthesisToTemp' }
      & Pick<ChangeProsthesisToTemp, 'need' | 'instruction'>
    ), extractToothWhenNoSpace: (
      { __typename?: 'ExtractToothWhenNoSpace' }
      & Pick<ExtractToothWhenNoSpace, 'intention' | 'toothType'>
    ) }
  ) }
);

export type FormPatientIntraOralFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { intraOral: (
    { __typename?: 'IntraOral' }
    & Pick<IntraOral, 'temporomandibularJointDisordersTreatStatus' | 'periodontalDiseaseTreatStatus'>
    & { teethStatus: Array<(
      { __typename?: 'ToothStatus' }
      & Pick<ToothStatus, 'position' | 'type'>
    )>, residualRoot: (
      { __typename?: 'ResidualRoot' }
      & Pick<ResidualRoot, 'hasResidualRoot' | 'instruction'>
    ), impactedTooth: (
      { __typename?: 'ImpactedTooth' }
      & Pick<ImpactedTooth, 'hasImpactedTooth' | 'instruction'>
    ), uneruptedWisdomTooth: (
      { __typename?: 'UneruptedWisdomTooth' }
      & Pick<UneruptedWisdomTooth, 'hasUneruptedWisdomTooth' | 'instruction'>
    ), maxillarMidlineToFacialMidline: (
      { __typename?: 'Midline' }
      & Pick<Midline, 'offset' | 'instruction'>
    ), mandibularMidlineToMaxillarMidline: (
      { __typename?: 'Midline' }
      & Pick<Midline, 'offset' | 'instruction'>
    ) }
  ) }
);

export type PaymentFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { payment: (
    { __typename?: 'PatientPayment' }
    & Pick<PatientPayment, 'brand' | 'source' | 'type' | 'patientPrice' | 'base' | 'referral' | 'extension' | 'discount'>
  ) }
);

export type PatientPhotoUploadFragment = (
  { __typename?: 'Patient' }
  & { photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45WithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, leftInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, rightInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, upperJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, lowerJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, pano?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, ceph?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ) }
);

export type FormPatientPhotoFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & PatientPhotoUploadFragment
);

export type FormPatientProfileFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'cephAnalysis' | 'faceRatio' | 'occulusalMuscle' | 'hasGummySmile' | 'smileLine' | 'hasBuccalCorridor'>
    & { mandibularMidlineToFacialMidline: (
      { __typename?: 'Midline' }
      & Pick<Midline, 'offset' | 'instruction'>
    ), occulusalPlane: (
      { __typename?: 'OcculusalPlane' }
      & Pick<OcculusalPlane, 'occulusalPlaneStatus' | 'instruction'>
    ), estheticLine: (
      { __typename?: 'EstheticLine' }
      & Pick<EstheticLine, 'lipsEstheticLineRelation' | 'instruction'>
    ) }
  ) }
);

export type FormPatientSymptomsFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { symptoms: (
    { __typename?: 'Symptoms' }
    & Pick<Symptoms, 'leftMolarRelationship' | 'rightMolarRelationship' | 'anteriorCrossbite' | 'anteriorCrowding' | 'bimaxillaryProtrusion' | 'deepBite' | 'highCanine' | 'openBite' | 'posteriorCrossbite' | 'posteriorCrowding' | 'vShapeArch'>
  ) }
);

export type PhotoContainerFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45WithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, leftInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, rightInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, upperJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, lowerJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, pano?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, ceph?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ) }
);

export type CollageModalFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & PhotoContainerFragment
);

export type InstructionCardFieldFragment = (
  { __typename?: 'InstructionCard' }
  & InstructionCardPreviewFragment
);

type InstructionCardStageLink_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & StageLink_AccessoryStage_Fragment
);

type InstructionCardStageLink_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & StageLink_EvalStage_Fragment
);

type InstructionCardStageLink_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & StageLink_DesignStage_Fragment
);

type InstructionCardStageLink_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & StageLink_PrintStage_Fragment
);

type InstructionCardStageLink_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & StageLink_MoldStage_Fragment
);

export type InstructionCardStageLinkFragment = InstructionCardStageLink_AccessoryStage_Fragment | InstructionCardStageLink_EvalStage_Fragment | InstructionCardStageLink_DesignStage_Fragment | InstructionCardStageLink_PrintStage_Fragment | InstructionCardStageLink_MoldStage_Fragment;

export type DesignCheckPointCardFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'serialNumber' | 'isFinal'>
  & PreviewCardFragment
);

type EvalStageName_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & StageName_AccessoryStage_Fragment
);

type EvalStageName_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & StageName_EvalStage_Fragment
);

type EvalStageName_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & StageName_DesignStage_Fragment
);

type EvalStageName_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & StageName_PrintStage_Fragment
);

type EvalStageName_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & StageName_MoldStage_Fragment
);

export type EvalStageNameFragment = EvalStageName_AccessoryStage_Fragment | EvalStageName_EvalStage_Fragment | EvalStageName_DesignStage_Fragment | EvalStageName_PrintStage_Fragment | EvalStageName_MoldStage_Fragment;

export type AnalysisSectionFormFragment = (
  { __typename?: 'DesignStage' }
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { currentEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & EvalStageName_EvalStage_Fragment
    )> }
  ), step: (
    { __typename?: 'Step' }
    & Pick<Step, 'upperStep' | 'lowerStep'>
  ), checkPoint?: Maybe<(
    { __typename?: 'CheckPoint' }
    & DesignCheckPointCardFragment
  )>, instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & InstructionCardFieldFragment
    & InstructionTagListFragment
  )> }
  & InstructionCardStageLink_DesignStage_Fragment
);

type FormAnalysis_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & InstructionCardPreviewFragment
  )> }
  & InstructionCardStageLink_AccessoryStage_Fragment
);

type FormAnalysis_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'id'>
);

type FormAnalysis_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & AnalysisSectionFormFragment
);

type FormAnalysis_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & { designStage?: Maybe<(
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'note'>
    & AnalysisSectionFormFragment
  )> }
);

type FormAnalysis_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'id'>
);

export type FormAnalysisFragment = FormAnalysis_AccessoryStage_Fragment | FormAnalysis_EvalStage_Fragment | FormAnalysis_DesignStage_Fragment | FormAnalysis_PrintStage_Fragment | FormAnalysis_MoldStage_Fragment;

type FormBasic_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'expectedShippingDate' | 'note'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & ExpectedShippingDateModal_AccessoryStage_Fragment
  & StageName_AccessoryStage_Fragment
);

type FormBasic_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'isCPMode' | 'id' | 'expectedShippingDate' | 'note'>
  & { moldStage?: Maybe<(
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & ExpectedShippingDateModal_EvalStage_Fragment
  & StageName_EvalStage_Fragment
);

type FormBasic_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'expectedShippingDate' | 'note'>
  & { evalStage: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'isCPMode'>
    & StageLink_EvalStage_Fragment
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & ExpectedShippingDateModal_DesignStage_Fragment
  & StageName_DesignStage_Fragment
);

type FormBasic_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'expectedShippingDate' | 'note'>
  & { designStage?: Maybe<(
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & ExpectedShippingDateModal_PrintStage_Fragment
  & StageName_PrintStage_Fragment
);

type FormBasic_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'expectedShippingDate' | 'note'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & ExpectedShippingDateModal_MoldStage_Fragment
  & StageName_MoldStage_Fragment
);

export type FormBasicFragment = FormBasic_AccessoryStage_Fragment | FormBasic_EvalStage_Fragment | FormBasic_DesignStage_Fragment | FormBasic_PrintStage_Fragment | FormBasic_MoldStage_Fragment;

export type FormRpFilesFragment = (
  { __typename?: 'DesignStageFiles' }
  & { upperRP?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )>, lowerRP?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )> }
);

type FormFile_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
);

type FormFile_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & { moldStage?: Maybe<(
    { __typename?: 'MoldStage' }
    & { files?: Maybe<(
      { __typename?: 'MoldStageFiles' }
      & MoldStageViewerFilesFragment
    )> }
    & ViewerStageInfo_MoldStage_Fragment
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & ViewerStageInfo_EvalStage_Fragment
  & FinalCheckPointFragment
);

type FormFile_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & { files?: Maybe<(
    { __typename?: 'DesignStageFiles' }
    & DesignStageViewerFilesFragment
    & FormRpFilesFragment
  )>, evalStage: (
    { __typename?: 'EvalStage' }
    & { moldStage?: Maybe<(
      { __typename?: 'MoldStage' }
      & { files?: Maybe<(
        { __typename?: 'MoldStageFiles' }
        & MoldStageViewerFilesFragment
      )> }
      & ViewerStageInfo_MoldStage_Fragment
    )> }
    & ViewerStageInfo_EvalStage_Fragment
    & FinalCheckPointFragment
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & ViewerStageInfo_DesignStage_Fragment
);

type FormFile_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & { designStage?: Maybe<(
    { __typename?: 'DesignStage' }
    & { files?: Maybe<(
      { __typename?: 'DesignStageFiles' }
      & DesignStageViewerFilesFragment
      & FormRpFilesFragment
    )>, evalStage: (
      { __typename?: 'EvalStage' }
      & { moldStage?: Maybe<(
        { __typename?: 'MoldStage' }
        & { files?: Maybe<(
          { __typename?: 'MoldStageFiles' }
          & MoldStageViewerFilesFragment
        )> }
        & ViewerStageInfo_MoldStage_Fragment
      )> }
      & ViewerStageInfo_EvalStage_Fragment
      & FinalCheckPointFragment
    ) }
    & ViewerStageInfo_DesignStage_Fragment
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & ViewerStageInfo_PrintStage_Fragment
);

type FormFile_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & { files?: Maybe<(
    { __typename?: 'MoldStageFiles' }
    & MoldStageViewerFilesFragment
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & ViewerStageInfo_MoldStage_Fragment
);

export type FormFileFragment = FormFile_AccessoryStage_Fragment | FormFile_EvalStage_Fragment | FormFile_DesignStage_Fragment | FormFile_PrintStage_Fragment | FormFile_MoldStage_Fragment;

export type FinalCheckPointFragment = (
  { __typename?: 'EvalStage' }
  & { checkPoints: Array<(
    { __typename?: 'CheckPoint' }
    & Pick<CheckPoint, 'isFinal'>
    & { files?: Maybe<(
      { __typename?: 'EvalStageFiles' }
      & EvalStageViewerFilesFragment
    )> }
  )> }
);

type ProductSectionForm_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
);

type ProductSectionForm_EvalStage_Fragment = { __typename?: 'EvalStage' };

type ProductSectionForm_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
);

type ProductSectionForm_PrintStage_Fragment = { __typename?: 'PrintStage' };

type ProductSectionForm_MoldStage_Fragment = { __typename?: 'MoldStage' };

export type ProductSectionFormFragment = ProductSectionForm_AccessoryStage_Fragment | ProductSectionForm_EvalStage_Fragment | ProductSectionForm_DesignStage_Fragment | ProductSectionForm_PrintStage_Fragment | ProductSectionForm_MoldStage_Fragment;

type FormProduct_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'rpBatchNumber' | 'id'>
  & ProductSectionForm_AccessoryStage_Fragment
);

type FormProduct_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'id'>
);

type FormProduct_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & ProductSectionForm_DesignStage_Fragment
);

type FormProduct_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'rpBatchNumber' | 'id'>
  & { designStage?: Maybe<(
    { __typename?: 'DesignStage' }
    & ProductSectionForm_DesignStage_Fragment
  )> }
);

type FormProduct_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'id'>
);

export type FormProductFragment = FormProduct_AccessoryStage_Fragment | FormProduct_EvalStage_Fragment | FormProduct_DesignStage_Fragment | FormProduct_PrintStage_Fragment | FormProduct_MoldStage_Fragment;

export type RemoveViewerFileMutationVariables = {
  id: Scalars['ID'];
  payload: ViewerFilesInput;
};


export type RemoveViewerFileMutation = (
  { __typename?: 'Mutation' }
  & { updateViewerFiles: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

export type MoldStageViewerFilesFragment = (
  { __typename?: 'MoldStageFiles' }
  & { teeth?: Maybe<Array<(
    { __typename?: 'ToothFile' }
    & Pick<ToothFile, 'position'>
    & { file: (
      { __typename?: 'File' }
      & FileInfoFragment
    ) }
  )>>, upperJawModel?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )>, lowerJawModel?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )> }
);

export type EvalStageViewerFilesFragment = (
  { __typename?: 'EvalStageFiles' }
  & { teeth?: Maybe<Array<(
    { __typename?: 'ToothFile' }
    & Pick<ToothFile, 'position'>
    & { file: (
      { __typename?: 'File' }
      & FileInfoFragment
    ) }
  )>>, upperJawModel?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )>, lowerJawModel?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )> }
);

export type DesignStageViewerFilesFragment = (
  { __typename?: 'DesignStageFiles' }
  & { teeth?: Maybe<Array<(
    { __typename?: 'ToothFile' }
    & Pick<ToothFile, 'position'>
    & { file: (
      { __typename?: 'File' }
      & FileInfoFragment
    ) }
  )>>, upperJawModel?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )>, lowerJawModel?: Maybe<(
    { __typename?: 'File' }
    & FileInfoFragment
  )> }
);

type ViewerStageInfo_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'status'>
);

type ViewerStageInfo_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'serialNumber' | 'hasFilesForViewer' | 'id' | 'status'>
);

type ViewerStageInfo_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'serialNumber' | 'hasFilesForViewer' | 'id' | 'status'>
);

type ViewerStageInfo_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'serialNumber' | 'id' | 'status'>
);

type ViewerStageInfo_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'serialNumber' | 'hasFilesForViewer' | 'id' | 'status'>
);

export type ViewerStageInfoFragment = ViewerStageInfo_AccessoryStage_Fragment | ViewerStageInfo_EvalStage_Fragment | ViewerStageInfo_DesignStage_Fragment | ViewerStageInfo_PrintStage_Fragment | ViewerStageInfo_MoldStage_Fragment;

export type PatientAnalysisQueryQueryVariables = {
  id: Scalars['ID'];
};


export type PatientAnalysisQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { currentEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id'>
      & StageLink_EvalStage_Fragment
    )>, stages?: Maybe<(
      { __typename?: 'PagedStage' }
      & { docs: Array<(
        { __typename: 'AccessoryStage' }
        & Pick<AccessoryStage, 'shippingDate'>
        & StageName_AccessoryStage_Fragment
      ) | (
        { __typename: 'EvalStage' }
        & Pick<EvalStage, 'isCPMode' | 'shouldShowCheckPoint' | 'shippingDate'>
        & VersionListFragment
        & CheckPointListFragment
        & StepListFragment
        & AnalysisPreviewAnalysisFragment
        & StageName_EvalStage_Fragment
      ) | (
        { __typename: 'DesignStage' }
        & Pick<DesignStage, 'shippingDate'>
        & StageName_DesignStage_Fragment
      ) | (
        { __typename: 'PrintStage' }
        & Pick<PrintStage, 'shippingDate'>
        & StageName_PrintStage_Fragment
      ) | (
        { __typename: 'MoldStage' }
        & Pick<MoldStage, 'shippingDate'>
        & StageName_MoldStage_Fragment
      )> }
    )> }
  )> }
);

export type PatientTasksQueryQueryVariables = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientTasksQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & TableTaskFragment
    )> }
  )> }
);

export type EmployeeLinkFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name'>
);

export type CreateAppointmentMutationVariables = {
  payload: CreateAppointmentInput;
};


export type CreateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { createAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentFragment
  ) }
);

export type UpdateAppointmentMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAppointmentInput;
};


export type UpdateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentFragment
  ) }
);

export type RemoveAppointmentMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { removeAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentFragment
  ) }
);

export type BasicFieldsFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & PatientLinkFragment
);

export type CreateStageGeneralFormFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & BasicFieldsFragment
);

type ForwardStageTaskForm_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'expectedShippingDate'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'status'>
    & { owner?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, nextTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { technician?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & EmployeeLinkFragment
    )> }
  ) }
  & StageName_AccessoryStage_Fragment
);

type ForwardStageTaskForm_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'expectedShippingDate'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'status'>
    & { owner?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, nextTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { technician?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & EmployeeLinkFragment
    )> }
  ) }
  & StageName_EvalStage_Fragment
);

type ForwardStageTaskForm_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'expectedShippingDate'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'status'>
    & { owner?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, nextTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { technician?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & EmployeeLinkFragment
    )> }
  ) }
  & StageName_DesignStage_Fragment
);

type ForwardStageTaskForm_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'expectedShippingDate'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'status'>
    & { owner?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, nextTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { technician?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & EmployeeLinkFragment
    )> }
  ) }
  & StageName_PrintStage_Fragment
);

type ForwardStageTaskForm_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'expectedShippingDate'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'status'>
    & { owner?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, nextTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { technician?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & EmployeeLinkFragment
    )> }
  ) }
  & StageName_MoldStage_Fragment
);

export type ForwardStageTaskFormFragment = ForwardStageTaskForm_AccessoryStage_Fragment | ForwardStageTaskForm_EvalStage_Fragment | ForwardStageTaskForm_DesignStage_Fragment | ForwardStageTaskForm_PrintStage_Fragment | ForwardStageTaskForm_MoldStage_Fragment;

type RecentStageTimeline_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'status' | 'expectedShippingDate'>
  & StageName_AccessoryStage_Fragment
);

type RecentStageTimeline_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'status' | 'expectedShippingDate'>
  & StageName_EvalStage_Fragment
);

type RecentStageTimeline_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'status' | 'expectedShippingDate'>
  & StageName_DesignStage_Fragment
);

type RecentStageTimeline_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'status' | 'expectedShippingDate'>
  & StageName_PrintStage_Fragment
);

type RecentStageTimeline_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'status' | 'expectedShippingDate'>
  & StageName_MoldStage_Fragment
);

export type RecentStageTimelineFragment = RecentStageTimeline_AccessoryStage_Fragment | RecentStageTimeline_EvalStage_Fragment | RecentStageTimeline_DesignStage_Fragment | RecentStageTimeline_PrintStage_Fragment | RecentStageTimeline_MoldStage_Fragment;

export type ForwardStageTaskMutationVariables = {
  id: Scalars['ID'];
  payload: ForwardTaskInput;
};


export type ForwardStageTaskMutation = (
  { __typename?: 'Mutation' }
  & { forwardTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type BaseModalFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id'>
      & RecentStageTimeline_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id'>
      & RecentStageTimeline_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id'>
      & RecentStageTimeline_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'id'>
      & RecentStageTimeline_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & Pick<MoldStage, 'id'>
      & RecentStageTimeline_MoldStage_Fragment
    )> }
  )> }
  & CreateStageGeneralFormFragment
);

export type CreateAccessoryStageMutationVariables = {
  payload: CreateAccessoryStageInput;
};


export type CreateAccessoryStageMutation = (
  { __typename?: 'Mutation' }
  & { createAccessoryStage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
    & ForwardStageTaskForm_AccessoryStage_Fragment
  ) }
);

export type CreateAccessoryStageQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CreateAccessoryStageQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & BaseModalFragment
  )> }
);

export type CreateDesignStageMutationVariables = {
  payload: CreateDesignStageInput;
};


export type CreateDesignStageMutation = (
  { __typename?: 'Mutation' }
  & { createDesignStage: (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
    & ForwardStageTaskForm_DesignStage_Fragment
  ) }
);

export type DesignFormFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'isCPMode'>
    & StageName_EvalStage_Fragment
  )>, stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'status'>
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'id' | 'status'>
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'serialNumber' | 'id' | 'status'>
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'status'>
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'status'>
    )> }
  )> }
);

export type CreateDesignStageQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CreateDesignStageQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & BaseModalFragment
    & DesignFormFragment
  )> }
);

export type CreateEvalStageMutationVariables = {
  payload: CreateEvalStageInput;
};


export type CreateEvalStageMutation = (
  { __typename?: 'Mutation' }
  & { createEvalStage: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
    & ForwardStageTaskForm_EvalStage_Fragment
  ) }
);

export type EvalFormFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
  & { stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'status'>
      & StageName_AccessoryStage_Fragment
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'isBeforePrint' | 'serialNumber' | 'id' | 'status'>
      & StageName_EvalStage_Fragment
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'status'>
      & StageName_DesignStage_Fragment
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'status'>
      & StageName_PrintStage_Fragment
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'status'>
      & StageName_MoldStage_Fragment
    )> }
  )> }
);

export type CreateEvalStageQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CreateEvalStageQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & BaseModalFragment
    & EvalFormFragment
  )> }
);

export type CreateMoldStageMutationVariables = {
  payload: CreateMoldStageInput;
};


export type CreateMoldStageMutation = (
  { __typename?: 'Mutation' }
  & { createMoldStage: (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
    & ForwardStageTaskForm_MoldStage_Fragment
  ) }
);

export type CreateMoldStageQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CreateMoldStageQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & BaseModalFragment
  )> }
);

export type CreatePrintStageMutationVariables = {
  payload: CreatePrintStageInput;
};


export type CreatePrintStageMutation = (
  { __typename?: 'Mutation' }
  & { createPrintStage: (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
    & ForwardStageTaskForm_PrintStage_Fragment
  ) }
);

export type PrintFormFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  )>, stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'status'>
      & StageName_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'isBeforePrint' | 'updated' | 'id' | 'status'>
      & StageName_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'status'>
      & StageName_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'serialNumber' | 'id' | 'status'>
      & StageName_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'status'>
      & StageName_MoldStage_Fragment
    )> }
  )> }
);

export type CreatePrintStageQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CreatePrintStageQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & PrintFormFragment
    & BaseModalFragment
  )> }
);

type ExpectedShippingDateModal_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'expectedShippingDate' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  ) }
  & StageName_AccessoryStage_Fragment
);

type ExpectedShippingDateModal_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'expectedShippingDate' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  ) }
  & StageName_EvalStage_Fragment
);

type ExpectedShippingDateModal_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'expectedShippingDate' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  ) }
  & StageName_DesignStage_Fragment
);

type ExpectedShippingDateModal_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'expectedShippingDate' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  ) }
  & StageName_PrintStage_Fragment
);

type ExpectedShippingDateModal_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'expectedShippingDate' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  ) }
  & StageName_MoldStage_Fragment
);

export type ExpectedShippingDateModalFragment = ExpectedShippingDateModal_AccessoryStage_Fragment | ExpectedShippingDateModal_EvalStage_Fragment | ExpectedShippingDateModal_DesignStage_Fragment | ExpectedShippingDateModal_PrintStage_Fragment | ExpectedShippingDateModal_MoldStage_Fragment;

export type UpdateExpectedShippingDateOfAccessoryStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccessoryStageInput;
};


export type UpdateExpectedShippingDateOfAccessoryStageMutation = (
  { __typename?: 'Mutation' }
  & { updateAccessoryStage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) }
);

export type UpdateExpectedShippingDateOfDesignStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type UpdateExpectedShippingDateOfDesignStageMutation = (
  { __typename?: 'Mutation' }
  & { updateDesignStage: (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) }
);

export type UpdateExpectedShippingDateOfEvalStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateEvalStageInput;
};


export type UpdateExpectedShippingDateOfEvalStageMutation = (
  { __typename?: 'Mutation' }
  & { updateEvalStage: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type UpdateExpectedShippingDateOfMoldStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateMoldStageInput;
};


export type UpdateExpectedShippingDateOfMoldStageMutation = (
  { __typename?: 'Mutation' }
  & { updateMoldStage: (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

export type UpdateExpectedShippingDateOfPrintStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePrintStageInput;
};


export type UpdateExpectedShippingDateOfPrintStageMutation = (
  { __typename?: 'Mutation' }
  & { updatePrintStage: (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) }
);

type InvoicePrintModal_InvoiceWithStage_Fragment = (
  { __typename: 'InvoiceWithStage' }
  & Pick<InvoiceWithStage, 'id' | 'discount' | 'middlemanFee' | 'shippingDate' | 'tax' | 'totalPrice'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'name' | 'patientCode'>
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & StageName_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageName_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageName_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'serialNumber' | 'expectedShippingDate'>
    & { designStage?: Maybe<(
      { __typename?: 'DesignStage' }
      & { evalStage: (
        { __typename?: 'EvalStage' }
        & StageName_EvalStage_Fragment
      ) }
    )> }
    & StageName_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageName_MoldStage_Fragment
  ), clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'name' | 'fullname' | 'phone'>
    & { location: (
      { __typename?: 'ClinicLocation' }
      & Pick<ClinicLocation, 'address'>
    ) }
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'name'>
  ), products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'count' | 'name' | 'price' | 'serialNumber' | 'spec'>
  )> }
);

type InvoicePrintModal_InvoiceWithoutStage_Fragment = (
  { __typename: 'InvoiceWithoutStage' }
  & Pick<InvoiceWithoutStage, 'id' | 'discount' | 'middlemanFee' | 'shippingDate' | 'tax' | 'totalPrice'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'name' | 'fullname' | 'phone'>
    & { location: (
      { __typename?: 'ClinicLocation' }
      & Pick<ClinicLocation, 'address'>
    ) }
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'name'>
  ), products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'count' | 'name' | 'price' | 'serialNumber' | 'spec'>
  )> }
);

export type InvoicePrintModalFragment = InvoicePrintModal_InvoiceWithStage_Fragment | InvoicePrintModal_InvoiceWithoutStage_Fragment;

export type UpdateOrderInstructionWithOptionsMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateOrderInput;
};


export type UpdateOrderInstructionWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type TaskUpdateBatchFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'type' | 'status'>
  & { nextTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
  )>, owner?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )> }
);

export type ForwardTaskBatchMutationVariables = {
  ids: Array<Scalars['ID']>;
  payload: ForwardTaskInput;
};


export type ForwardTaskBatchMutation = (
  { __typename?: 'Mutation' }
  & { forwardTaskBatch: Array<(
    { __typename?: 'Task' }
    & TaskUpdateBatchFragment
  )> }
);

export type BackwardTaskBatchMutationVariables = {
  ids: Array<Scalars['ID']>;
};


export type BackwardTaskBatchMutation = (
  { __typename?: 'Mutation' }
  & { backwardTaskBatch: Array<(
    { __typename?: 'Task' }
    & TaskUpdateBatchFragment
  )> }
);

export type CreateTrackMutationVariables = {
  payload: CreateTrackInput;
};


export type CreateTrackMutation = (
  { __typename?: 'Mutation' }
  & { createTrack: (
    { __typename?: 'Track' }
    & Pick<Track, 'id'>
  ) }
);

export type RemoveTrackMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveTrackMutation = (
  { __typename?: 'Mutation' }
  & { removeTrack: (
    { __typename?: 'Track' }
    & Pick<Track, 'id'>
  ) }
);

export type ReplyTrackMutationVariables = {
  id: Scalars['ID'];
  payload: ReplyTrackInput;
};


export type ReplyTrackMutation = (
  { __typename?: 'Mutation' }
  & { replyTrack: (
    { __typename?: 'Track' }
    & Pick<Track, 'id'>
  ) }
);

export type UpdateViewerFilesMutationVariables = {
  id: Scalars['ID'];
  payload: ViewerFilesInput;
  cpSerialNumber?: Maybe<Scalars['Int']>;
};


export type UpdateViewerFilesMutation = (
  { __typename?: 'Mutation' }
  & { updateViewerFiles: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

export type CreatePatientModalMutationVariables = {
  payload: CreatePatientInput;
};


export type CreatePatientModalMutation = (
  { __typename?: 'Mutation' }
  & { createPatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
);

export type ClinicMenuFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name'>
);

export type DoctorMenuFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name'>
);

export type EmployeeMenuFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name'>
);

export type PatientPageHeaderQueryQueryVariables = {
  id: Scalars['ID'];
};


export type PatientPageHeaderQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'status' | 'name' | 'patientCode' | 'cabinetCode' | 'gender' | 'nextAppointmentDate'>
    & { payment: (
      { __typename?: 'PatientPayment' }
      & Pick<PatientPayment, 'brand' | 'source' | 'type' | 'patientPrice' | 'base' | 'referral' | 'extension' | 'discount'>
    ), photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ), clinic: (
      { __typename?: 'Clinic' }
      & ClinicLinkFragment
    ), doctor: (
      { __typename?: 'Doctor' }
      & DoctorLinkFragment
    ), accountManager?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, sales: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    ), technician?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, customerService?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, currentSubStage?: Maybe<(
      { __typename?: 'SubStage' }
      & Pick<SubStage, 'id' | 'serialNumber'>
      & { stage: (
        { __typename?: 'PrintStage' }
        & Pick<PrintStage, 'id' | 'serialNumber'>
      ) }
    )>, meta: (
      { __typename?: 'PatientMeta' }
      & Pick<PatientMeta, 'currentStepNumber' | 'finalStepNumber' | 'risk'>
    ) }
  )> }
);

export type PatientPageHeaderStageItemQueryQueryVariables = {
  stageId: Scalars['ID'];
};


export type PatientPageHeaderStageItemQueryQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id' | 'type'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'type'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id' | 'type'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'type'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id' | 'type'>
  ) }
);

export type QcTasksQueryQueryVariables = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QcTasksQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'startedByEmployee'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'name' | 'patientCode' | 'cabinetCode'>
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'id' | 'name' | 'specialContract'>
        ), currentEvalStage?: Maybe<(
          { __typename?: 'EvalStage' }
          & Pick<EvalStage, 'id'>
          & { analysis?: Maybe<(
            { __typename?: 'Analysis' }
            & { steps?: Maybe<Array<(
              { __typename?: 'Step' }
              & Pick<Step, 'upperStep' | 'lowerStep'>
            )>> }
          )> }
        )> }
      ), stage: (
        { __typename?: 'AccessoryStage' }
        & Pick<AccessoryStage, 'id' | 'expectedShippingDate'>
        & { instructionCard?: Maybe<(
          { __typename?: 'InstructionCard' }
          & InstructionCardPreviewFragment
        )> }
      ) | (
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename?: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename?: 'PrintStage' }
        & Pick<PrintStage, 'serialNumber' | 'id' | 'expectedShippingDate'>
        & { instructionCard?: Maybe<(
          { __typename?: 'InstructionCard' }
          & InstructionCardPreviewFragment
        )>, designStage?: Maybe<(
          { __typename?: 'DesignStage' }
          & Pick<DesignStage, 'id' | 'serialNumber'>
          & { step: (
            { __typename?: 'Step' }
            & Pick<Step, 'upperStep' | 'lowerStep'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'expectedShippingDate'>
      ) }
      & QcListTableFragment
    )> }
  )> }
);

export type RpTasksQueryQueryVariables = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type RpTasksQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & RpListTableFragment
      & TaskUpdateBatchFragment
    )> }
  )> }
);

export type AppointmentTableFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'startDate' | 'endDate' | 'description' | 'isAttended'>
  & { patient: (
    { __typename?: 'Patient' }
    & TablePatientInfoFragment
  ), creator: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ) }
);

export type DiaryTableFragment = (
  { __typename?: 'Diary' }
  & Pick<Diary, 'id' | 'diaryDate' | 'created'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  ), wear: (
    { __typename?: 'Wear' }
    & Pick<Wear, 'id'>
    & { subStage: (
      { __typename?: 'SubStage' }
      & Pick<SubStage, 'id' | 'serialNumber' | 'startDate'>
      & { stage: (
        { __typename?: 'PrintStage' }
        & Pick<PrintStage, 'id' | 'serialNumber'>
      ) }
    ) }
  ) }
);

export type DoctorTableFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name' | 'email' | 'phone' | 'note' | 'level'>
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  )> }
);

export type UpdateDoctorLevelMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDoctorInput;
};


export type UpdateDoctorLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateDoctor: (
    { __typename?: 'Doctor' }
    & DoctorTableFragment
  ) }
);

type InvoiceTable_InvoiceWithStage_Fragment = (
  { __typename: 'InvoiceWithStage' }
  & Pick<InvoiceWithStage, 'id' | 'autoIncrement' | 'middlemanFee' | 'totalPrice' | 'shippingDate' | 'creditDate' | 'payment' | 'deferredRevenue'>
  & { patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
    & StageLink_MoldStage_Fragment
  ), clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'productId' | 'serialNumber' | 'name' | 'price' | 'count'>
  )> }
);

type InvoiceTable_InvoiceWithoutStage_Fragment = (
  { __typename: 'InvoiceWithoutStage' }
  & Pick<InvoiceWithoutStage, 'id' | 'autoIncrement' | 'middlemanFee' | 'totalPrice' | 'shippingDate' | 'creditDate' | 'payment' | 'deferredRevenue'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'productId' | 'serialNumber' | 'name' | 'price' | 'count'>
  )> }
);

export type InvoiceTableFragment = InvoiceTable_InvoiceWithStage_Fragment | InvoiceTable_InvoiceWithoutStage_Fragment;

export type TableMoldStageFragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'note' | 'created'>
  & { patient: (
    { __typename?: 'Patient' }
    & { accountManager?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, sales: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    ) }
    & TablePatientInfoFragment
  ), evalStages?: Maybe<Array<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'expectedShippingDate' | 'created'>
    & StageLink_EvalStage_Fragment
  )>> }
  & StageLink_MoldStage_Fragment
);

type BindingCell_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & BindingStageSelect_AccessoryStage_Fragment
  & BindingDisplayCell_AccessoryStage_Fragment
);

type BindingCell_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & BindingStageSelect_EvalStage_Fragment
  & BindingDisplayCell_EvalStage_Fragment
);

type BindingCell_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & BindingStageSelect_DesignStage_Fragment
  & BindingDisplayCell_DesignStage_Fragment
);

type BindingCell_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & BindingStageSelect_PrintStage_Fragment
  & BindingDisplayCell_PrintStage_Fragment
);

type BindingCell_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & BindingStageSelect_MoldStage_Fragment
  & BindingDisplayCell_MoldStage_Fragment
);

export type BindingCellFragment = BindingCell_AccessoryStage_Fragment | BindingCell_EvalStage_Fragment | BindingCell_DesignStage_Fragment | BindingCell_PrintStage_Fragment | BindingCell_MoldStage_Fragment;

type BindingDisplayCell_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & StageLink_AccessoryStage_Fragment
);

type BindingDisplayCell_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & StageLink_EvalStage_Fragment
);

type BindingDisplayCell_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & StageLink_DesignStage_Fragment
);

type BindingDisplayCell_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & StageLink_PrintStage_Fragment
);

type BindingDisplayCell_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & StageLink_MoldStage_Fragment
);

export type BindingDisplayCellFragment = BindingDisplayCell_AccessoryStage_Fragment | BindingDisplayCell_EvalStage_Fragment | BindingDisplayCell_DesignStage_Fragment | BindingDisplayCell_PrintStage_Fragment | BindingDisplayCell_MoldStage_Fragment;

type BindingStageSelect_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'type'>
  )> }
  & StageName_AccessoryStage_Fragment
);

type BindingStageSelect_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'type'>
  )> }
  & StageName_EvalStage_Fragment
);

type BindingStageSelect_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'type'>
  )> }
  & StageName_DesignStage_Fragment
);

type BindingStageSelect_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'type'>
  )> }
  & StageName_PrintStage_Fragment
);

type BindingStageSelect_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'type'>
  )> }
  & StageName_MoldStage_Fragment
);

export type BindingStageSelectFragment = BindingStageSelect_AccessoryStage_Fragment | BindingStageSelect_EvalStage_Fragment | BindingStageSelect_DesignStage_Fragment | BindingStageSelect_PrintStage_Fragment | BindingStageSelect_MoldStage_Fragment;

export type PatientTableFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'status' | 'patientCode' | 'cabinetCode' | 'nextAppointmentDate' | 'created'>
  & { note: (
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'sales' | 'design' | 'braces'>
  ), photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'thumbnailPath'>
    )> }
  ), clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'specialContract'>
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), accountManager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & StageLink_EvalStage_Fragment
  )>, payment: (
    { __typename?: 'PatientPayment' }
    & Pick<PatientPayment, 'brand' | 'source'>
  ), meta: (
    { __typename?: 'PatientMeta' }
    & Pick<PatientMeta, 'currentStepNumber' | 'finalStepNumber' | 'lastEvalStageAt' | 'lastPrintStageAt'>
    & { lastEvalStage?: Maybe<(
      { __typename?: 'AccessoryStage' }
      & StageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageLink_MoldStage_Fragment
    )>, lastPrintStage?: Maybe<(
      { __typename?: 'AccessoryStage' }
      & StageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageLink_MoldStage_Fragment
    )> }
  ), creator?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )> }
  & TablePatientInfoFragment
  & PatientLinkFragment
);

export type UpdatePatientTableMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type UpdatePatientTableMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & PatientTableFragment
  ) }
);

export type PatientTablePaymentFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'autoIncrement' | 'status' | 'patientCode' | 'cabinetCode'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), payment: (
    { __typename?: 'PatientPayment' }
    & Pick<PatientPayment, 'brand' | 'source' | 'type' | 'patientPrice' | 'base' | 'referral' | 'extension' | 'discount'>
  ), accountManager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, meta: (
    { __typename?: 'PatientMeta' }
    & Pick<PatientMeta, 'currentStepNumber' | 'finalStepNumber' | 'techRevenue' | 'techCost' | 'techProfit' | 'risk' | 'lastPrintStageAt'>
    & { lastPrintStage?: Maybe<(
      { __typename?: 'AccessoryStage' }
      & StageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageLink_MoldStage_Fragment
    )> }
  ) }
  & PatientLinkFragment
);

export type PatientStageTableDropStageMutationVariables = {
  id: Scalars['ID'];
};


export type PatientStageTableDropStageMutation = (
  { __typename?: 'Mutation' }
  & { dropStage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'status'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'status'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'status'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'status'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'status'>
  ) }
);

export type PatientStageTableUndropStageMutationVariables = {
  id: Scalars['ID'];
};


export type PatientStageTableUndropStageMutation = (
  { __typename?: 'Mutation' }
  & { undropStage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'status'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'status'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'status'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'status'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'status'>
  ) }
);

export type PatientStageTablePatientInfoFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  )> }
);

type PatientStageTableStageInfo_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'type' | 'note' | 'status'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & InstructionPopoverFragment
  )>, currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
    & { owner?: Maybe<{ __typename?: 'Patient' } | { __typename?: 'Clinic' } | { __typename?: 'Doctor' } | (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    )> }
  )> }
  & ExpectedShippingDateModal_AccessoryStage_Fragment
  & StageName_AccessoryStage_Fragment
  & StageLink_AccessoryStage_Fragment
  & DropPopover_AccessoryStage_Fragment
  & ViewerFileIcon_AccessoryStage_Fragment
);

type PatientStageTableStageInfo_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'hasFilesForViewer' | 'id' | 'type' | 'note' | 'status'>
  & { initialEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
    & StageLink_EvalStage_Fragment
  )>, currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
    & { owner?: Maybe<{ __typename?: 'Patient' } | { __typename?: 'Clinic' } | { __typename?: 'Doctor' } | (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    )> }
  )> }
  & ExpectedShippingDateModal_EvalStage_Fragment
  & StageName_EvalStage_Fragment
  & StageLink_EvalStage_Fragment
  & DropPopover_EvalStage_Fragment
  & ViewerFileIcon_EvalStage_Fragment
);

type PatientStageTableStageInfo_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'hasFilesForViewer' | 'id' | 'type' | 'note' | 'status'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & Pick<InstructionCard, 'retrieve'>
    & InstructionPopoverFragment
  )>, currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
    & { owner?: Maybe<{ __typename?: 'Patient' } | { __typename?: 'Clinic' } | { __typename?: 'Doctor' } | (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    )> }
  )> }
  & ExpectedShippingDateModal_DesignStage_Fragment
  & StageName_DesignStage_Fragment
  & StageLink_DesignStage_Fragment
  & DropPopover_DesignStage_Fragment
  & ViewerFileIcon_DesignStage_Fragment
);

type PatientStageTableStageInfo_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'type' | 'note' | 'status'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & Pick<InstructionCard, 'retrieve'>
    & InstructionPopoverFragment
  )>, currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
    & { owner?: Maybe<{ __typename?: 'Patient' } | { __typename?: 'Clinic' } | { __typename?: 'Doctor' } | (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    )> }
  )> }
  & ExpectedShippingDateModal_PrintStage_Fragment
  & StageName_PrintStage_Fragment
  & StageLink_PrintStage_Fragment
  & DropPopover_PrintStage_Fragment
  & ViewerFileIcon_PrintStage_Fragment
);

type PatientStageTableStageInfo_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'hasFilesForViewer' | 'id' | 'type' | 'note' | 'status'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
    & { owner?: Maybe<{ __typename?: 'Patient' } | { __typename?: 'Clinic' } | { __typename?: 'Doctor' } | (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    )> }
  )> }
  & ExpectedShippingDateModal_MoldStage_Fragment
  & StageName_MoldStage_Fragment
  & StageLink_MoldStage_Fragment
  & DropPopover_MoldStage_Fragment
  & ViewerFileIcon_MoldStage_Fragment
);

export type PatientStageTableStageInfoFragment = PatientStageTableStageInfo_AccessoryStage_Fragment | PatientStageTableStageInfo_EvalStage_Fragment | PatientStageTableStageInfo_DesignStage_Fragment | PatientStageTableStageInfo_PrintStage_Fragment | PatientStageTableStageInfo_MoldStage_Fragment;

export type UpdatePatientCabinetCodeMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type UpdatePatientCabinetCodeMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'cabinetCode'>
  ) }
);

export type QcListTableFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'startedByEmployee'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'patientCode' | 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'specialContract' | 'deliveryMethod'>
      & ClinicLinkFragment
    ), currentEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & StageLink_EvalStage_Fragment
    )>, payment: (
      { __typename?: 'PatientPayment' }
      & Pick<PatientPayment, 'brand'>
    ) }
    & PatientLinkFragment
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'expectedShippingDate'>
    & { instructionCard?: Maybe<(
      { __typename?: 'InstructionCard' }
      & InstructionPopoverFragment
    )> }
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'expectedShippingDate'>
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'expectedShippingDate'>
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'expectedShippingDate'>
    & { instructionCard?: Maybe<(
      { __typename?: 'InstructionCard' }
      & Pick<InstructionCard, 'instruction' | 'stepCode'>
      & { auxiliaries: Array<(
        { __typename?: 'Auxiliary' }
        & Pick<Auxiliary, 'type' | 'toothPosition'>
      )> }
      & InstructionPopoverFragment
    )>, designStage?: Maybe<(
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'upperModelCount' | 'lowerModelCount'>
    )> }
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'expectedShippingDate'>
    & StageLink_MoldStage_Fragment
  ) }
);

export type RpListTableFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'status' | 'type' | 'expectedShippingDate'>
  & { owner?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'rpBatchNumber' | 'note'>
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'note'>
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'note'>
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'rpBatchNumber' | 'note'>
    & { designStage?: Maybe<(
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'rpModelNumber' | 'lowerModelCount' | 'upperModelCount'>
    )> }
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'note'>
    & StageLink_MoldStage_Fragment
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'deliveryMethod'>
      & ClinicLinkFragment
    ) }
    & PatientLinkFragment
  ) }
);

export type OrderCellFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'displayId'>
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'startDate' | 'endDate'>
  )> }
);

type StageTable_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'status' | 'type' | 'note' | 'expectedShippingDate'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & InstructionPopoverFragment
  )>, order?: Maybe<(
    { __typename?: 'Order' }
    & OrderCellFragment
  )>, patient: (
    { __typename?: 'Patient' }
    & TablePatientInfoFragment
  ) }
  & ExpectedShippingDateModal_AccessoryStage_Fragment
  & StageLink_AccessoryStage_Fragment
  & ViewerFileIcon_AccessoryStage_Fragment
);

type StageTable_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'hasFilesForViewer' | 'id' | 'status' | 'type' | 'note' | 'expectedShippingDate'>
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderCellFragment
  )>, patient: (
    { __typename?: 'Patient' }
    & TablePatientInfoFragment
  ) }
  & ExpectedShippingDateModal_EvalStage_Fragment
  & StageLink_EvalStage_Fragment
  & ViewerFileIcon_EvalStage_Fragment
);

type StageTable_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'hasFilesForViewer' | 'id' | 'status' | 'type' | 'note' | 'expectedShippingDate'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & Pick<InstructionCard, 'retrieve'>
    & InstructionPopoverFragment
  )>, latestPrintStage?: Maybe<(
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderCellFragment
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & TablePatientInfoFragment
  ) }
  & ExpectedShippingDateModal_DesignStage_Fragment
  & StageLink_DesignStage_Fragment
  & ViewerFileIcon_DesignStage_Fragment
);

type StageTable_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'status' | 'type' | 'note' | 'expectedShippingDate'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & Pick<InstructionCard, 'retrieve'>
    & InstructionPopoverFragment
  )>, order?: Maybe<(
    { __typename?: 'Order' }
    & OrderCellFragment
  )>, patient: (
    { __typename?: 'Patient' }
    & TablePatientInfoFragment
  ) }
  & ExpectedShippingDateModal_PrintStage_Fragment
  & StageLink_PrintStage_Fragment
  & ViewerFileIcon_PrintStage_Fragment
);

type StageTable_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'hasFilesForViewer' | 'id' | 'status' | 'type' | 'note' | 'expectedShippingDate'>
  & { latestEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderCellFragment
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & TablePatientInfoFragment
  ) }
  & ExpectedShippingDateModal_MoldStage_Fragment
  & StageLink_MoldStage_Fragment
  & ViewerFileIcon_MoldStage_Fragment
);

export type StageTableFragment = StageTable_AccessoryStage_Fragment | StageTable_EvalStage_Fragment | StageTable_DesignStage_Fragment | StageTable_PrintStage_Fragment | StageTable_MoldStage_Fragment;

export type TableTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'type' | 'status' | 'startedByEmployee' | 'expectedShippingDate' | 'isDelayed'>
  & { owner?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'note'>
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'note'>
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'note'>
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'note'>
    & { designStage?: Maybe<(
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id'>
      & { instructionCard?: Maybe<(
        { __typename?: 'InstructionCard' }
        & Pick<InstructionCard, 'retrieve'>
      )> }
    )> }
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'note'>
    & StageLink_MoldStage_Fragment
  ), patient: (
    { __typename?: 'Patient' }
    & { payment: (
      { __typename?: 'PatientPayment' }
      & Pick<PatientPayment, 'brand'>
    ) }
    & TablePatientInfoFragment
  ) }
);

export type PhotoThumbnailListFragment = (
  { __typename?: 'TrackPhotoBase' }
  & { frontLoose?: Maybe<(
    { __typename?: 'File' }
    & TrackPhotoFragment
  )>, frontTight?: Maybe<(
    { __typename?: 'File' }
    & TrackPhotoFragment
  )>, leftTight?: Maybe<(
    { __typename?: 'File' }
    & TrackPhotoFragment
  )>, rightTight?: Maybe<(
    { __typename?: 'File' }
    & TrackPhotoFragment
  )> }
);

export type TrackPhotoFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'path' | 'thumbnailPath'>
);

export type TrackTableFragment = (
  { __typename?: 'Track' }
  & Pick<Track, 'id' | 'created' | 'mainIssues' | 'extraIssue' | 'reply' | 'replyDate'>
  & { stage: (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'serialNumber'>
    & { subStage?: Maybe<Array<(
      { __typename?: 'SubStage' }
      & Pick<SubStage, 'startDate'>
    )>> }
  ), patient: (
    { __typename?: 'Patient' }
    & { accountManager?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )> }
    & TablePatientInfoFragment
  ), photo?: Maybe<(
    { __typename?: 'TrackPhoto' }
    & { withBrace?: Maybe<(
      { __typename?: 'TrackPhotoBase' }
      & PhotoThumbnailListFragment
    )>, withoutBrace?: Maybe<(
      { __typename?: 'TrackPhotoBase' }
      & PhotoThumbnailListFragment
    )> }
  )> }
);

export type EventTableFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'type' | 'created'>
  & { creator: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), data: (
    { __typename?: 'EventData' }
    & { patient?: Maybe<(
      { __typename?: 'Patient' }
      & PatientLinkFragment
    )>, stage?: Maybe<(
      { __typename?: 'AccessoryStage' }
      & StageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageLink_MoldStage_Fragment
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )> }
  ) }
);

export type PatientAppointmentTableFragment = (
  { __typename?: 'Appointment' }
  & AppointmentFragment
);

export type PatientOrderTableFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'displayId' | 'created' | 'type' | 'description' | 'status'>
  & { stageOrder: Array<(
    { __typename?: 'StageOrderContent' }
    & Pick<StageOrderContent, 'stageType' | 'number' | 'items'>
  )>, appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'startDate' | 'endDate'>
  )> }
);

export type PatientTrackTableFragment = (
  { __typename?: 'Track' }
  & Pick<Track, 'id' | 'created' | 'mainIssues' | 'extraIssue' | 'reply'>
  & { stage: (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'serialNumber'>
  ), photo?: Maybe<(
    { __typename?: 'TrackPhoto' }
    & { withBrace?: Maybe<(
      { __typename?: 'TrackPhotoBase' }
      & { frontLoose?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )>, frontTight?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )>, leftTight?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )>, rightTight?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )> }
    )>, withoutBrace?: Maybe<(
      { __typename?: 'TrackPhotoBase' }
      & { frontLoose?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )>, frontTight?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )>, leftTight?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )>, rightTight?: Maybe<(
        { __typename?: 'File' }
        & PatientTrackTablePhotoFragment
      )> }
    )> }
  )> }
);

export type PatientTrackTablePhotoFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'path' | 'thumbnailPath'>
);

export type RemoveInstructionCardMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveInstructionCardMutation = (
  { __typename?: 'Mutation' }
  & { removeInstructionCard: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

export type CreateAccountMutationVariables = {
  payload: CreateAccountInput;
};


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'Account' }
    & AccountFormInfoFragment
  ) }
);

export type AccountMenuQueryQueryVariables = {
  id: Scalars['ID'];
};


export type AccountMenuQueryQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'nickname'>
  ) }
);

export type UpdateAccountShouldClearStorageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type UpdateAccountShouldClearStorageMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type AccountAppInfoQueryVariables = {
  id: Scalars['ID'];
};


export type AccountAppInfoQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'lastLogin' | 'currentAppStorage' | 'shouldClearStorage' | 'lastStorageClearDate' | 'lastLoginClientInfo'>
  ) }
);

export type EntityBindFormFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'note' | 'entityType'>
  & { entity?: Maybe<(
    { __typename?: 'Patient' }
    & EntitySelect_Patient_Fragment
  ) | (
    { __typename?: 'Clinic' }
    & EntitySelect_Clinic_Fragment
  ) | (
    { __typename?: 'Doctor' }
    & EntitySelect_Doctor_Fragment
  ) | (
    { __typename?: 'Employee' }
    & EntitySelect_Employee_Fragment
  )> }
  & RemoveEntityBindingFromAccountButtonFragment
);

export type BindAccountUserMutationVariables = {
  id: Scalars['ID'];
  payload: BindAccountEntityInput;
};


export type BindAccountUserMutation = (
  { __typename?: 'Mutation' }
  & { bindAccountUser: (
    { __typename?: 'Account' }
    & EntityBindFormFragment
  ) }
);

export type RemoveEntityBindingFromAccountButtonFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'privileges'>
  & { entity?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type RemoveEntityBindingFromAccountMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type RemoveEntityBindingFromAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & RemoveEntityBindingFromAccountButtonFragment
  ) }
);

export type AccountBindEntityQueryVariables = {
  id: Scalars['ID'];
};


export type AccountBindEntityQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & EntityBindFormFragment
  ) }
);

export type AccountDetailFormFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'email' | 'isEmailVerified' | 'phone' | 'isPhoneVerified' | 'isActive'>
  & { providers?: Maybe<(
    { __typename?: 'Providers' }
    & { facebook?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, google?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, line?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, wechat?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )> }
  )> }
);

export type AvatarWithNicknameFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'nickname' | 'entityType'>
  & { avatar?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'thumbnailPath'>
  )> }
);

export type AccountInfoQueryQueryVariables = {
  id: Scalars['ID'];
};


export type AccountInfoQueryQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & AccountDetailFormFragment
    & AvatarWithNicknameFragment
  ) }
);

export type UpdateAccountInfoMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type UpdateAccountInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & AccountDetailFormFragment
    & AvatarWithNicknameFragment
  ) }
);

export type VerifyEmployeeAccountMutationVariables = {
  id: Scalars['ID'];
  verificationType: VerificationType;
};


export type VerifyEmployeeAccountMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmployeeAccount: (
    { __typename?: 'Account' }
    & AccountDetailFormFragment
    & AvatarWithNicknameFragment
  ) }
);

export type AccountInPrivilegesTabFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'privileges' | 'extraPrivileges' | 'entityType'>
  & { entity?: Maybe<(
    { __typename?: 'Patient' }
    & EntitySelect_Patient_Fragment
  ) | (
    { __typename?: 'Clinic' }
    & EntitySelect_Clinic_Fragment
  ) | (
    { __typename?: 'Doctor' }
    & EntitySelect_Doctor_Fragment
  ) | (
    { __typename?: 'Employee' }
    & EntitySelect_Employee_Fragment
  )> }
);

export type AccountPrivilegesQueryQueryVariables = {
  id: Scalars['ID'];
};


export type AccountPrivilegesQueryQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & AccountInPrivilegesTabFragment
  ) }
);

export type UpdateAccountPrivilegesMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type UpdateAccountPrivilegesMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & AccountInPrivilegesTabFragment
  ) }
);

export type NotificationSettingQueryVariables = {
  id: Scalars['ID'];
};


export type NotificationSettingQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { notificationSetting: (
      { __typename?: 'NotificationSetting' }
      & NotificationSettingFormFragment
    ) }
  ) }
);

export type UpdateNotificationSettingMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccountInput;
};


export type UpdateNotificationSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & { notificationSetting: (
      { __typename?: 'NotificationSetting' }
      & NotificationSettingFormFragment
    ) }
  ) }
);

export type LoginByRootMutationMutationVariables = {
  id: Scalars['ID'];
};


export type LoginByRootMutationMutation = (
  { __typename?: 'Mutation' }
  & { loginByRoot: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type AccountTableInfoFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'nickname' | 'email' | 'isEmailVerified' | 'phone' | 'isPhoneVerified' | 'entityType' | 'lastLogin' | 'isActive' | 'created'>
  & { avatar?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'thumbnailPath'>
  )>, entity?: Maybe<(
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ) | (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ) | (
    { __typename?: 'Doctor' }
    & DoctorLinkFragment
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'role'>
    & EmployeeLinkFragment
  )>, providers?: Maybe<(
    { __typename?: 'Providers' }
    & { facebook?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, google?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, line?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )>, wechat?: Maybe<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id'>
    )> }
  )> }
);

export type AccountsQueryQueryVariables = {
  query?: Maybe<AccountsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type AccountsQueryQuery = (
  { __typename?: 'Query' }
  & { accounts: (
    { __typename?: 'PagedAccount' }
    & Pick<PagedAccount, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Account' }
      & AccountTableInfoFragment
    )> }
  ) }
);

export type AppointmentCalendarQueryQueryVariables = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type AppointmentCalendarQueryQuery = (
  { __typename?: 'Query' }
  & { appointments: (
    { __typename?: 'PagedAppointment' }
    & Pick<PagedAppointment, 'limit' | 'total' | 'page'>
    & { docs: Array<(
      { __typename?: 'Appointment' }
      & AppointmentFragment
    )> }
  ) }
);

export type AppointmentListQueryQueryVariables = {
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type AppointmentListQueryQuery = (
  { __typename?: 'Query' }
  & { appointments: (
    { __typename?: 'PagedAppointment' }
    & Pick<PagedAppointment, 'limit' | 'total' | 'page'>
    & { docs: Array<(
      { __typename?: 'Appointment' }
      & AppointmentTableFragment
    )> }
  ) }
);

export type ClinicDetailQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ClinicDetailQueryQuery = (
  { __typename?: 'Query' }
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
    & ClinicFormFragment
    & ClinicMenuFragment
  ) }
);

export type ClinicInvoiceQueryVariables = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type ClinicInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'PagedInvoice' }
    & Pick<PagedInvoice, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'InvoiceWithStage' }
      & InvoiceTable_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & InvoiceTable_InvoiceWithoutStage_Fragment
    )> }
  ) }
);

type PatientStagePreview_AccessoryStage_Fragment = { __typename: 'AccessoryStage' };

type PatientStagePreview_EvalStage_Fragment = { __typename: 'EvalStage' };

type PatientStagePreview_DesignStage_Fragment = { __typename: 'DesignStage' };

type PatientStagePreview_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'serialNumber' | 'note' | 'expectedShippingDate'>
  & StageName_PrintStage_Fragment
);

type PatientStagePreview_MoldStage_Fragment = { __typename: 'MoldStage' };

export type PatientStagePreviewFragment = PatientStagePreview_AccessoryStage_Fragment | PatientStagePreview_EvalStage_Fragment | PatientStagePreview_DesignStage_Fragment | PatientStagePreview_PrintStage_Fragment | PatientStagePreview_MoldStage_Fragment;

export type ConversationPatientInfoFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name'>
  & { payment: (
    { __typename?: 'PatientPayment' }
    & Pick<PatientPayment, 'brand' | 'source' | 'type'>
  ), photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'thumbnailPath'>
    )> }
  ), clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), accountManager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, note: (
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'design'>
  ), stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & PatientStagePreview_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & PatientStagePreview_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & PatientStagePreview_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & PatientStagePreview_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & PatientStagePreview_MoldStage_Fragment
    )> }
  )> }
);

export type UpdatePatientNoteMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type UpdatePatientNoteMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & ConversationPatientInfoFragment
  ) }
);

export type ConversationInfoQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ConversationInfoQueryQuery = (
  { __typename?: 'Query' }
  & { conversation: (
    { __typename?: 'PatientConversation' }
    & Pick<PatientConversation, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & ConversationPatientInfoFragment
    ), members: Array<(
      { __typename?: 'Patient' }
      & ConversationMemberInfo_Patient_Fragment
    ) | (
      { __typename?: 'Clinic' }
      & ConversationMemberInfo_Clinic_Fragment
    ) | (
      { __typename?: 'Doctor' }
      & ConversationMemberInfo_Doctor_Fragment
    ) | (
      { __typename?: 'Employee' }
      & ConversationMemberInfo_Employee_Fragment
    )> }
  ) }
);

export type DiariesQueryQueryVariables = {
  query?: Maybe<DiariesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type DiariesQueryQuery = (
  { __typename?: 'Query' }
  & { diaries: (
    { __typename?: 'PagedDiary' }
    & Pick<PagedDiary, 'limit' | 'total' | 'page'>
    & { docs: Array<(
      { __typename?: 'Diary' }
      & DiaryTableFragment
    )> }
  ) }
);

export type DoctorDetailQueryVariables = {
  id: Scalars['ID'];
};


export type DoctorDetailQuery = (
  { __typename?: 'Query' }
  & { doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name' | 'phone' | 'note' | 'email' | 'level'>
    & { clinics: Array<(
      { __typename?: 'Clinic' }
      & ClinicLinkFragment
    )> }
  ) }
);

export type DoctorInvoiceQueryVariables = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type DoctorInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'PagedInvoice' }
    & Pick<PagedInvoice, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'InvoiceWithStage' }
      & InvoiceTable_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & InvoiceTable_InvoiceWithoutStage_Fragment
    )> }
  ) }
);

export type DoctorsQueryQueryVariables = {
  query?: Maybe<DoctorsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type DoctorsQueryQuery = (
  { __typename?: 'Query' }
  & { doctors: (
    { __typename?: 'PagedDoctor' }
    & Pick<PagedDoctor, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id'>
      & DoctorTableFragment
    )> }
  ) }
);

export type BarChartDataQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<GroupedCreatedPatientCountMapListQuery>;
};


export type BarChartDataQuery = (
  { __typename?: 'Query' }
  & { groupedCreatedPatientCountMapList: Array<(
    { __typename?: 'GroupedCreatedPatientCountMap' }
    & Pick<GroupedCreatedPatientCountMap, 'source' | 'createdPatientCount'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
  )> }
);

export type AccountManagerOrderListRowFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'displayId' | 'description' | 'created'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ) }
  ) }
);

export type AccountManagerOrderFragment = (
  { __typename?: 'Order' }
  & AccountManagerOrderListRowFragment
);

type AccountManagerStageListRow_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'shippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & { photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ) }
    & PatientLinkFragment
  ) }
  & StageName_AccessoryStage_Fragment
);

type AccountManagerStageListRow_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'shippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & { photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ) }
    & PatientLinkFragment
  ) }
  & StageName_EvalStage_Fragment
);

type AccountManagerStageListRow_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'shippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & { photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ) }
    & PatientLinkFragment
  ) }
  & StageName_DesignStage_Fragment
);

type AccountManagerStageListRow_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'shippingDate'>
  & { designStage?: Maybe<(
    { __typename?: 'DesignStage' }
    & { instructionCard?: Maybe<(
      { __typename?: 'InstructionCard' }
      & InstructionPopoverFragment
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & { photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ) }
    & PatientLinkFragment
  ) }
  & StageName_PrintStage_Fragment
);

type AccountManagerStageListRow_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'shippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & { photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'thumbnailPath'>
      )> }
    ) }
    & PatientLinkFragment
  ) }
  & StageName_MoldStage_Fragment
);

export type AccountManagerStageListRowFragment = AccountManagerStageListRow_AccessoryStage_Fragment | AccountManagerStageListRow_EvalStage_Fragment | AccountManagerStageListRow_DesignStage_Fragment | AccountManagerStageListRow_PrintStage_Fragment | AccountManagerStageListRow_MoldStage_Fragment;

type AccountManagerStage_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & AccountManagerStageListRow_AccessoryStage_Fragment
);

type AccountManagerStage_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & AccountManagerStageListRow_EvalStage_Fragment
);

type AccountManagerStage_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & AccountManagerStageListRow_DesignStage_Fragment
);

type AccountManagerStage_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & AccountManagerStageListRow_PrintStage_Fragment
);

type AccountManagerStage_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & AccountManagerStageListRow_MoldStage_Fragment
);

export type AccountManagerStageFragment = AccountManagerStage_AccessoryStage_Fragment | AccountManagerStage_EvalStage_Fragment | AccountManagerStage_DesignStage_Fragment | AccountManagerStage_PrintStage_Fragment | AccountManagerStage_MoldStage_Fragment;

export type AccountManagerStatisticPageQueryVariables = {
  id: Scalars['ID'];
  query: AccountManagerStatisticQuery;
  ordersLimit?: Maybe<Scalars['Int']>;
  stagesLimit?: Maybe<Scalars['Int']>;
};


export type AccountManagerStatisticPageQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
    & { orders?: Maybe<(
      { __typename?: 'PagedOrder' }
      & { docs: Array<(
        { __typename?: 'Order' }
        & AccountManagerOrderFragment
      )> }
    )>, stages?: Maybe<(
      { __typename?: 'PagedStage' }
      & { docs: Array<(
        { __typename?: 'AccessoryStage' }
        & AccountManagerStage_AccessoryStage_Fragment
      ) | (
        { __typename?: 'EvalStage' }
        & AccountManagerStage_EvalStage_Fragment
      ) | (
        { __typename?: 'DesignStage' }
        & AccountManagerStage_DesignStage_Fragment
      ) | (
        { __typename?: 'PrintStage' }
        & AccountManagerStage_PrintStage_Fragment
      ) | (
        { __typename?: 'MoldStage' }
        & AccountManagerStage_MoldStage_Fragment
      )> }
    )> }
    & EmployeeMenuFragment
  ), accountManagerStatistic: (
    { __typename?: 'AccountManagerStatistic' }
    & Pick<AccountManagerStatistic, 'patientCountCreatedDuringPreviousMonth' | 'patientCountCreatedDuringCurrentMonth' | 'patientCountCompletedDuringCurrentMonth'>
    & { patientStatusAndCountMapList: Array<(
      { __typename?: 'PatientStatusAndCountMap' }
      & Pick<PatientStatusAndCountMap, 'status' | 'count'>
    )> }
  ) }
);

export type UpdateEmployeeBatchMutationVariables = {
  payload: Array<UpdateEmployeeBatchInput>;
};


export type UpdateEmployeeBatchMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployeeBatch: Array<(
    { __typename?: 'Employee' }
    & EmployeeInfoFragment
  )> }
);

export type EmployeesByTaskOwnerQueryQueryVariables = {
  query?: Maybe<EmployeesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeesByTaskOwnerQueryQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'PagedEmployee' }
    & { docs: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'role' | 'taskOwner'>
    )> }
  ) }
);

export type CreateEmployeeMutationVariables = {
  payload: CreateEmployeeInput;
};


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee: (
    { __typename?: 'Employee' }
    & EmployeeFormInfoFragment
  ) }
);

export type EmployeeQueryQueryVariables = {
  id: Scalars['ID'];
};


export type EmployeeQueryQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & EmployeeFormInfoFragment
  ) }
);

export type UpdateEmployeeMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateEmployeeInput;
};


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee: (
    { __typename?: 'Employee' }
    & EmployeeFormInfoFragment
  ) }
);

export type RemoveEmployeeMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { removeEmployee: (
    { __typename?: 'Employee' }
    & EmployeeFormInfoFragment
  ) }
);

export type UpdateOrderInstructionMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateOrderInput;
};


export type UpdateOrderInstructionMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type OrderTableFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'status' | 'isLatest' | 'type' | 'description' | 'instruction' | 'created'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { sales: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    ), clinic: (
      { __typename?: 'Clinic' }
      & ClinicLinkFragment
    ) }
    & PatientWithDoctorLinkFragment
  ), appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'startDate'>
  )>, stageOrder: Array<(
    { __typename?: 'StageOrderContent' }
    & Pick<StageOrderContent, 'stageType' | 'number' | 'items'>
  )>, stageProvide: Array<(
    { __typename?: 'StageProvideContent' }
    & Pick<StageProvideContent, 'stageType'>
    & { stages: Array<(
      { __typename?: 'AccessoryStage' }
      & StageName_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageName_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageName_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageName_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageName_MoldStage_Fragment
    )> }
  )> }
);

export type EmployeeOrdersQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeOrdersQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
    & { orders?: Maybe<(
      { __typename?: 'PagedOrder' }
      & Pick<PagedOrder, 'total' | 'limit' | 'page'>
      & { docs: Array<(
        { __typename?: 'Order' }
        & OrderTableFragment
      )> }
    )> }
  ) }
);

export type EmployeePatientListQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeePatientListQueryQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
    & { patients?: Maybe<(
      { __typename?: 'PagedPatient' }
      & Pick<PagedPatient, 'total' | 'limit' | 'page'>
      & { docs: Array<(
        { __typename?: 'Patient' }
        & PatientTableFragment
      )> }
    )> }
  ) }
);

export type EmployeeStageListQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeStageListQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
    & { stages?: Maybe<(
      { __typename?: 'PagedStage' }
      & Pick<PagedStage, 'total' | 'limit' | 'page'>
      & { docs: Array<(
        { __typename?: 'AccessoryStage' }
        & StageTable_AccessoryStage_Fragment
      ) | (
        { __typename?: 'EvalStage' }
        & StageTable_EvalStage_Fragment
      ) | (
        { __typename?: 'DesignStage' }
        & StageTable_DesignStage_Fragment
      ) | (
        { __typename?: 'PrintStage' }
        & StageTable_PrintStage_Fragment
      ) | (
        { __typename?: 'MoldStage' }
        & StageTable_MoldStage_Fragment
      )> }
    )> }
  ) }
);

export type EmployeeTasksQueryQueryVariables = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeTasksQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & TableTaskFragment
      & TaskUpdateBatchFragment
    )> }
  )> }
);

export type EmployeeTaskOwnerQueryQueryVariables = {
  id: Scalars['ID'];
};


export type EmployeeTaskOwnerQueryQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'taskOwner'>
  ) }
);

export type CompletedBracesQueryQueryVariables = {
  query: TasksQuery;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CompletedBracesQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'startedByEmployee' | 'completedByOwner'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'patientCode'>
      ), stage: (
        { __typename: 'AccessoryStage' }
        & Pick<AccessoryStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'PrintStage' }
        & Pick<PrintStage, 'serialNumber' | 'id' | 'expectedShippingDate'>
        & { designStage?: Maybe<(
          { __typename?: 'DesignStage' }
          & Pick<DesignStage, 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
        )> }
      ) | (
        { __typename: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'expectedShippingDate'>
      ), owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'id' | 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'id' | 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type CompletedRpQueryQueryVariables = {
  query: TasksQuery;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type CompletedRpQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'startedByEmployee' | 'completedByOwner'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'patientCode'>
      ), stage: (
        { __typename: 'AccessoryStage' }
        & Pick<AccessoryStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'PrintStage' }
        & Pick<PrintStage, 'serialNumber' | 'id' | 'expectedShippingDate'>
        & { designStage?: Maybe<(
          { __typename?: 'DesignStage' }
          & Pick<DesignStage, 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount'>
        )> }
      ) | (
        { __typename: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'expectedShippingDate'>
      ), owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'id' | 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'id' | 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type DefectiveQueryQueryVariables = {
  query: TasksQuery;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type DefectiveQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'startedByEmployee' | 'completedByOwner'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'name'>
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'id' | 'name'>
        ) }
      ), stage: (
        { __typename: 'AccessoryStage' }
        & Pick<AccessoryStage, 'accessoryCode' | 'id' | 'note' | 'expectedShippingDate'>
        & StageName_AccessoryStage_Fragment
      ) | (
        { __typename: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'note' | 'expectedShippingDate'>
        & StageName_EvalStage_Fragment
      ) | (
        { __typename: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'note' | 'expectedShippingDate'>
        & StageName_DesignStage_Fragment
      ) | (
        { __typename: 'PrintStage' }
        & Pick<PrintStage, 'id' | 'note' | 'expectedShippingDate'>
        & StageName_PrintStage_Fragment
      ) | (
        { __typename: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'note' | 'expectedShippingDate'>
        & StageName_MoldStage_Fragment
      ) }
    )> }
  )> }
);

export type FactoryOrderQueryQueryVariables = {
  query: TasksQuery;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type FactoryOrderQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'startedByEmployee' | 'completedByOwner'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'patientCode'>
      ), stage: (
        { __typename: 'AccessoryStage' }
        & Pick<AccessoryStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'PrintStage' }
        & Pick<PrintStage, 'serialNumber' | 'id' | 'expectedShippingDate'>
        & { designStage?: Maybe<(
          { __typename?: 'DesignStage' }
          & Pick<DesignStage, 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
        )>, invoice?: Maybe<(
          { __typename?: 'InvoiceWithStage' }
          & { products: Array<(
            { __typename?: 'InvoiceProduct' }
            & Pick<InvoiceProduct, 'serialNumber' | 'name' | 'count'>
          )> }
        ) | (
          { __typename?: 'InvoiceWithoutStage' }
          & { products: Array<(
            { __typename?: 'InvoiceProduct' }
            & Pick<InvoiceProduct, 'serialNumber' | 'name' | 'count'>
          )> }
        )> }
      ) | (
        { __typename: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'expectedShippingDate'>
      ), owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'id' | 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'id' | 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type FactoryShipmentQueryVariables = {
  query: TasksQuery;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type FactoryShipmentQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'patientCode'>
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'id' | 'name'>
        ) }
      ), stage: (
        { __typename: 'AccessoryStage' }
        & Pick<AccessoryStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'expectedShippingDate'>
      ) | (
        { __typename: 'PrintStage' }
        & Pick<PrintStage, 'serialNumber' | 'id' | 'expectedShippingDate'>
        & { designStage?: Maybe<(
          { __typename?: 'DesignStage' }
          & Pick<DesignStage, 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
        )> }
      ) | (
        { __typename: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'expectedShippingDate'>
      ) }
    )> }
  )> }
);

export type InvoiceDetailQueryQueryVariables = {
  id: Scalars['ID'];
};


export type InvoiceDetailQueryQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'InvoiceWithStage' }
    & Pick<InvoiceWithStage, 'id'>
    & FormInvoiceItem_InvoiceWithStage_Fragment
    & InvoicePrintModal_InvoiceWithStage_Fragment
    & InvoiceMailInvoiceItem_InvoiceWithStage_Fragment
  ) | (
    { __typename?: 'InvoiceWithoutStage' }
    & Pick<InvoiceWithoutStage, 'id'>
    & FormInvoiceItem_InvoiceWithoutStage_Fragment
    & InvoicePrintModal_InvoiceWithoutStage_Fragment
    & InvoiceMailInvoiceItem_InvoiceWithoutStage_Fragment
  ) }
);

export type InvoiceListQueryVariables = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type InvoiceListQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'PagedInvoice' }
    & Pick<PagedInvoice, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'InvoiceWithStage' }
      & InvoiceTable_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & InvoiceTable_InvoiceWithoutStage_Fragment
    )> }
  ) }
);

export type StageInvoiceQueryQueryVariables = {
  id: Scalars['ID'];
};


export type StageInvoiceQueryQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id' | 'status'>
    & { invoice?: Maybe<(
      { __typename?: 'InvoiceWithStage' }
      & FormInvoiceItem_InvoiceWithStage_Fragment
      & InvoicePrintModal_InvoiceWithStage_Fragment
      & InvoiceMailInvoiceItem_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & FormInvoiceItem_InvoiceWithoutStage_Fragment
      & InvoicePrintModal_InvoiceWithoutStage_Fragment
      & InvoiceMailInvoiceItem_InvoiceWithoutStage_Fragment
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & InvoiceMailPatientItemFragment
      & FormInvoicePatientItemFragment
    ) }
    & InvoiceMailStageItem_AccessoryStage_Fragment
    & FormInvoiceStageItem_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'status'>
    & { invoice?: Maybe<(
      { __typename?: 'InvoiceWithStage' }
      & FormInvoiceItem_InvoiceWithStage_Fragment
      & InvoicePrintModal_InvoiceWithStage_Fragment
      & InvoiceMailInvoiceItem_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & FormInvoiceItem_InvoiceWithoutStage_Fragment
      & InvoicePrintModal_InvoiceWithoutStage_Fragment
      & InvoiceMailInvoiceItem_InvoiceWithoutStage_Fragment
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & InvoiceMailPatientItemFragment
      & FormInvoicePatientItemFragment
    ) }
    & InvoiceMailStageItem_EvalStage_Fragment
    & FormInvoiceStageItem_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id' | 'status'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & InvoiceMailPatientItemFragment
      & FormInvoicePatientItemFragment
    ) }
    & InvoiceMailStageItem_DesignStage_Fragment
    & FormInvoiceStageItem_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'status'>
    & { invoice?: Maybe<(
      { __typename?: 'InvoiceWithStage' }
      & FormInvoiceItem_InvoiceWithStage_Fragment
      & InvoicePrintModal_InvoiceWithStage_Fragment
      & InvoiceMailInvoiceItem_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & FormInvoiceItem_InvoiceWithoutStage_Fragment
      & InvoicePrintModal_InvoiceWithoutStage_Fragment
      & InvoiceMailInvoiceItem_InvoiceWithoutStage_Fragment
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & InvoiceMailPatientItemFragment
      & FormInvoicePatientItemFragment
    ) }
    & InvoiceMailStageItem_PrintStage_Fragment
    & FormInvoiceStageItem_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id' | 'status'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & InvoiceMailPatientItemFragment
      & FormInvoicePatientItemFragment
    ) }
    & InvoiceMailStageItem_MoldStage_Fragment
    & FormInvoiceStageItem_MoldStage_Fragment
  ) }
);

export type PatientCostQueryVariables = {
  id: Scalars['ID'];
  query: StagesQuery;
};


export type PatientCostQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & ClinicLinkFragment
    ), doctor: (
      { __typename?: 'Doctor' }
      & DoctorLinkFragment
    ) }
    & PatientLinkFragment
  )>, stages: (
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'type'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'type' | 'startedByEmployee' | 'completedByOwner'>
      )> }
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'isBeforePrint' | 'id' | 'type'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'type' | 'startedByEmployee' | 'completedByOwner'>
      )> }
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'type'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'type' | 'startedByEmployee' | 'completedByOwner'>
      )> }
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'type'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'type' | 'startedByEmployee' | 'completedByOwner'>
      )> }
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'type'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'type' | 'startedByEmployee' | 'completedByOwner'>
      )> }
    )> }
  ) }
);

export type EmployeeLeaveQueryQueryVariables = {
  id: Scalars['ID'];
};


export type EmployeeLeaveQueryQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & EmployeeLeaveFormInfoFragment
  ) }
);

export type LeaveInfooFragment = (
  { __typename?: 'Leave' }
  & Pick<Leave, 'isApproved'>
);

export type NotificationListQueryVariables = {
  query?: Maybe<NotificationsQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type NotificationListQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationConnection' }
    & { edges: Array<(
      { __typename?: 'NotificationEdge' }
      & { node: (
        { __typename?: 'AppointmentNotification' }
        & NotificationItem_AppointmentNotification_Fragment
      ) | (
        { __typename?: 'NormalNotification' }
        & NotificationItem_NormalNotification_Fragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  )> }
);

export type DiliveryInfoFragment = (
  { __typename?: 'Order' }
  & { stageProvide: Array<(
    { __typename?: 'StageProvideContent' }
    & { stages: Array<(
      { __typename?: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'type'>
      & { invoice?: Maybe<(
        { __typename?: 'InvoiceWithStage' }
        & Pick<InvoiceWithStage, 'homeDeliveryType' | 'homeDeliveryId'>
      ) | (
        { __typename?: 'InvoiceWithoutStage' }
        & Pick<InvoiceWithoutStage, 'homeDeliveryType' | 'homeDeliveryId'>
      )> }
    ) | (
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id' | 'type'>
      & { invoice?: Maybe<(
        { __typename?: 'InvoiceWithStage' }
        & Pick<InvoiceWithStage, 'homeDeliveryType' | 'homeDeliveryId'>
      ) | (
        { __typename?: 'InvoiceWithoutStage' }
        & Pick<InvoiceWithoutStage, 'homeDeliveryType' | 'homeDeliveryId'>
      )> }
    ) | (
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'type'>
    ) | (
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'type'>
      & { invoice?: Maybe<(
        { __typename?: 'InvoiceWithStage' }
        & Pick<InvoiceWithStage, 'homeDeliveryType' | 'homeDeliveryId'>
      ) | (
        { __typename?: 'InvoiceWithoutStage' }
        & Pick<InvoiceWithoutStage, 'homeDeliveryType' | 'homeDeliveryId'>
      )> }
    ) | (
      { __typename?: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'type'>
    )> }
  )> }
);

export type ItemListFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'status'>
  & { stageProvide: Array<(
    { __typename?: 'StageProvideContent' }
    & Pick<StageProvideContent, 'stageType'>
    & { stages: Array<(
      { __typename?: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'type'>
      & OrderDetailStageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id' | 'type'>
      & OrderDetailStageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'type'>
      & OrderDetailStageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'serialNumber' | 'id' | 'type'>
      & { invoice?: Maybe<(
        { __typename?: 'InvoiceWithStage' }
        & Pick<InvoiceWithStage, 'id' | 'totalPrice'>
        & { products: Array<(
          { __typename?: 'InvoiceProduct' }
          & Pick<InvoiceProduct, 'name' | 'price' | 'count'>
        )> }
      ) | (
        { __typename?: 'InvoiceWithoutStage' }
        & Pick<InvoiceWithoutStage, 'id' | 'totalPrice'>
        & { products: Array<(
          { __typename?: 'InvoiceProduct' }
          & Pick<InvoiceProduct, 'name' | 'price' | 'count'>
        )> }
      )> }
      & OrderDetailStageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'type'>
      & OrderDetailStageLink_MoldStage_Fragment
    )> }
  )> }
);

export type OrderDetailFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'displayId' | 'status' | 'created' | 'description'>
  & { stageOrder: Array<(
    { __typename?: 'StageOrderContent' }
    & Pick<StageOrderContent, 'stageType' | 'number'>
  )>, appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'startDate'>
  )>, stageProvide: Array<(
    { __typename?: 'StageProvideContent' }
    & Pick<StageProvideContent, 'stageType'>
    & { stages: Array<(
      { __typename?: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'shippingDate'>
    ) | (
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id' | 'shippingDate'>
    ) | (
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'shippingDate'>
    ) | (
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'shippingDate'>
    ) | (
      { __typename?: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'shippingDate'>
    )> }
  )> }
);

export type PatientProfileFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id'>
  & { creator: (
    { __typename: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { doctor: (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) }
  ) }
);

export type OrderDetailCardFragment = (
  { __typename?: 'Order' }
  & PatientProfileFragment
  & OrderDetailFragment
  & ItemListFragment
  & DiliveryInfoFragment
);

type OrderDetailStageLink_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id'>
  & StageName_AccessoryStage_Fragment
);

type OrderDetailStageLink_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & StageName_EvalStage_Fragment
);

type OrderDetailStageLink_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'id'>
  & StageName_DesignStage_Fragment
);

type OrderDetailStageLink_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'id'>
  & StageName_PrintStage_Fragment
);

type OrderDetailStageLink_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'id'>
  & StageName_MoldStage_Fragment
);

export type OrderDetailStageLinkFragment = OrderDetailStageLink_AccessoryStage_Fragment | OrderDetailStageLink_EvalStage_Fragment | OrderDetailStageLink_DesignStage_Fragment | OrderDetailStageLink_PrintStage_Fragment | OrderDetailStageLink_MoldStage_Fragment;

export type OrderDetailPageFragment = (
  { __typename?: 'Order' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientConversationFragment
  ) }
  & OrderDetailCardFragment
);

export type OrderDetailQueryVariables = {
  id: Scalars['ID'];
};


export type OrderDetailQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderDetailPageFragment
  )> }
);

export type BindingStagesQueryVariables = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type BindingStagesQuery = (
  { __typename?: 'Query' }
  & { stages: (
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ) }
      & BindingCell_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ) }
      & BindingCell_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ) }
      & BindingCell_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ) }
      & BindingCell_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ) }
      & BindingCell_MoldStage_Fragment
    )> }
  ) }
);

export type PatientAppointmentsQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<AppointmentsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientAppointmentsQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { appointments?: Maybe<(
      { __typename?: 'PagedAppointment' }
      & Pick<PagedAppointment, 'limit' | 'total' | 'page'>
      & { docs: Array<(
        { __typename?: 'Appointment' }
        & PatientAppointmentTableFragment
      )> }
    )> }
  )> }
);

export type PatientDetailFormFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
  & FormPatientBasicFragment
  & FormPatientPhotoFragment
  & FormPatientProfileFragment
  & FormPatientIntraOralFragment
  & FormPatientSymptomsFragment
  & FormPatientDoctorInstructionFragment
);

export type PatientDetailQueryQueryVariables = {
  id: Scalars['ID'];
};


export type PatientDetailQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & PatientDetailFormFragment
    & CollageModalFragment
  )> }
);

export type UpdatePatientMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type UpdatePatientMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & PatientDetailFormFragment
  ) }
);

export type UpdatePatientStatusMutationVariables = {
  id: Scalars['ID'];
  status?: Maybe<PatientStatus>;
};


export type UpdatePatientStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientStatus: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'status'>
  ) }
);

export type RemovePatientMutationVariables = {
  id: Scalars['ID'];
};


export type RemovePatientMutation = (
  { __typename?: 'Mutation' }
  & { removePatient: (
    { __typename?: 'RemoveSystemMessage' }
    & Pick<RemoveSystemMessage, 'id' | 'message'>
  ) }
);

export type PatientEventsQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<EventsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientEventsQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { events?: Maybe<(
      { __typename?: 'PagedEvent' }
      & Pick<PagedEvent, 'total' | 'limit' | 'page'>
      & { docs: Array<(
        { __typename?: 'Event' }
        & EventTableFragment
      )> }
    )> }
  )> }
);

export type PatientInvoicesQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientInvoicesQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { payment: (
      { __typename?: 'PatientPayment' }
      & Pick<PatientPayment, 'type'>
    ), invoices: (
      { __typename?: 'PagedInvoice' }
      & Pick<PagedInvoice, 'limit' | 'page' | 'total'>
      & { docs: Array<(
        { __typename?: 'InvoiceWithStage' }
        & InvoiceTable_InvoiceWithStage_Fragment
      ) | (
        { __typename?: 'InvoiceWithoutStage' }
        & InvoiceTable_InvoiceWithoutStage_Fragment
      )> }
    ) }
  )> }
);

export type PatientListQueryQueryVariables = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientListQueryQuery = (
  { __typename?: 'Query' }
  & { patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & Pick<PagedPatient, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Patient' }
      & PatientTableFragment
    )> }
  )> }
);

export type PatientListPaymentQueryQueryVariables = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientListPaymentQueryQuery = (
  { __typename?: 'Query' }
  & { patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & Pick<PagedPatient, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Patient' }
      & PatientTablePaymentFragment
    )> }
  )> }
);

export type PatientOrdersQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientOrdersQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { orders?: Maybe<(
      { __typename?: 'PagedOrder' }
      & Pick<PagedOrder, 'limit' | 'total' | 'page'>
      & { docs: Array<(
        { __typename?: 'Order' }
        & PatientOrderTableFragment
      )> }
    )> }
  )> }
);

export type DeleteCheckPointMutationVariables = {
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
};


export type DeleteCheckPointMutation = (
  { __typename?: 'Mutation' }
  & { deleteCheckPoint: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type UpdateCheckPointMutationVariables = {
  id: Scalars['ID'];
  serialNumber: Scalars['Int'];
  payload: UpdateCheckPointInput;
};


export type UpdateCheckPointMutation = (
  { __typename?: 'Mutation' }
  & { updateCheckPoint: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type CheckPointCardFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'hasFilesForViewer' | 'serialNumber' | 'goalStep' | 'upper' | 'lower' | 'note' | 'instruction'>
  & { files?: Maybe<(
    { __typename?: 'EvalStageFiles' }
    & { teeth?: Maybe<Array<(
      { __typename?: 'ToothFile' }
      & Pick<ToothFile, 'position'>
      & { file: (
        { __typename?: 'File' }
        & FileInfoFragment
      ) }
    )>>, upperJawModel?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )>, lowerJawModel?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )> }
  )> }
);

export type CheckPointGroupFragment = (
  { __typename?: 'CheckPoint' }
  & { designStages: Array<(
    { __typename?: 'DesignStage' }
    & StepCardFragment
  )> }
  & CheckPointCardFragment
);

export type ClearStepsMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAnalysisInput;
};


export type ClearStepsMutation = (
  { __typename?: 'Mutation' }
  & { updateAnalysis: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type PredesignCollapseFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id'>
  & { analysis?: Maybe<(
    { __typename?: 'Analysis' }
    & { steps?: Maybe<Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'upperStep' | 'lowerStep' | 'attachment' | 'ipr' | 'button' | 'retrieve'>
    )>> }
  )> }
);

export type StepCardFragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'serialNumber'>
  & { step: (
    { __typename?: 'Step' }
    & Pick<Step, 'upperStep' | 'lowerStep'>
  ), instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & InstructionTagListFragment
  )> }
);

export type PatientReportEditQueryVariables = {
  id: Scalars['ID'];
};


export type PatientReportEditQuery = (
  { __typename?: 'Query' }
  & { evalStage: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'isCPMode'>
    & { analysis?: Maybe<(
      { __typename?: 'Analysis' }
      & Pick<Analysis, 'digiAnalysis'>
    )>, stepsOverview?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )>, checkPoints: Array<(
      { __typename?: 'CheckPoint' }
      & CheckPointGroupFragment
      & CheckPointProgressFragment
    )>, patient: (
      { __typename?: 'Patient' }
      & { stages?: Maybe<(
        { __typename?: 'PagedStage' }
        & { docs: Array<(
          { __typename?: 'AccessoryStage' }
          & Pick<AccessoryStage, 'id'>
        ) | (
          { __typename?: 'EvalStage' }
          & Pick<EvalStage, 'id'>
        ) | (
          { __typename?: 'DesignStage' }
          & Pick<DesignStage, 'id'>
        ) | (
          { __typename?: 'PrintStage' }
          & Pick<PrintStage, 'serialNumber' | 'id'>
        ) | (
          { __typename?: 'MoldStage' }
          & Pick<MoldStage, 'id'>
        )> }
      )> }
    ) }
    & StageName_EvalStage_Fragment
    & PredesignCollapseFragment
  ) }
);

export type CreateCheckPointMutationVariables = {
  id: Scalars['ID'];
};


export type CreateCheckPointMutation = (
  { __typename?: 'Mutation' }
  & { createCheckPoint: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type UpdateAnalysisMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAnalysisInput;
};


export type UpdateAnalysisMutation = (
  { __typename?: 'Mutation' }
  & { updateAnalysis: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type UploadStepsOverViewMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateEvalStageInput;
};


export type UploadStepsOverViewMutation = (
  { __typename?: 'Mutation' }
  & { updateEvalStage: (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) }
);

export type CreateStageButtonGroupFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
);

type DropPopover_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'type' | 'status'>
  & StageName_AccessoryStage_Fragment
);

type DropPopover_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'type' | 'status'>
  & StageName_EvalStage_Fragment
);

type DropPopover_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'type' | 'status'>
  & StageName_DesignStage_Fragment
);

type DropPopover_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'type' | 'status'>
  & StageName_PrintStage_Fragment
);

type DropPopover_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'type' | 'status'>
  & StageName_MoldStage_Fragment
);

export type DropPopoverFragment = DropPopover_AccessoryStage_Fragment | DropPopover_EvalStage_Fragment | DropPopover_DesignStage_Fragment | DropPopover_PrintStage_Fragment | DropPopover_MoldStage_Fragment;

export type PatientStageIndexQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientStageIndexQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & { stages?: Maybe<(
      { __typename?: 'PagedStage' }
      & { docs: Array<(
        { __typename?: 'AccessoryStage' }
        & Pick<AccessoryStage, 'id' | 'type' | 'expectedShippingDate'>
        & PatientStageTableStageInfo_AccessoryStage_Fragment
      ) | (
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id' | 'type' | 'expectedShippingDate'>
        & PatientStageTableStageInfo_EvalStage_Fragment
      ) | (
        { __typename?: 'DesignStage' }
        & Pick<DesignStage, 'id' | 'type' | 'expectedShippingDate'>
        & PatientStageTableStageInfo_DesignStage_Fragment
      ) | (
        { __typename?: 'PrintStage' }
        & Pick<PrintStage, 'id' | 'type' | 'expectedShippingDate'>
        & PatientStageTableStageInfo_PrintStage_Fragment
      ) | (
        { __typename?: 'MoldStage' }
        & Pick<MoldStage, 'id' | 'type' | 'expectedShippingDate'>
        & PatientStageTableStageInfo_MoldStage_Fragment
      )> }
    )> }
    & CreateStageButtonGroupFragment
    & PatientStageTablePatientInfoFragment
  )> }
);

export type PatientTracksQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<TracksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientTracksQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { tracks?: Maybe<(
      { __typename?: 'PagedTrack' }
      & Pick<PagedTrack, 'limit' | 'total' | 'page'>
      & { docs: Array<(
        { __typename?: 'Track' }
        & PatientTrackTableFragment
      )> }
    )> }
  )> }
);

export type PatientTransferTableFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id'>
    & DoctorLinkFragment
  ), accountManager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeLinkFragment
  )>, sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeLinkFragment
  ), technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeLinkFragment
  )>, customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeLinkFragment
  )> }
  & PatientLinkFragment
);

export type PatientTransferListQueryQueryVariables = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientTransferListQueryQuery = (
  { __typename?: 'Query' }
  & { patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & Pick<PagedPatient, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Patient' }
      & PatientTransferTableFragment
    )> }
  )> }
);

export type PatientTransferMutationVariables = {
  ids: Array<Scalars['ID']>;
  payload: TransferPatientsInput;
};


export type PatientTransferMutation = (
  { __typename?: 'Mutation' }
  & { transferPatients: Array<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & PatientTransferTableFragment
  )> }
);

export type CheckPointOverviewFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'isCPMode'>
  & { checkPoints: Array<(
    { __typename?: 'CheckPoint' }
    & CheckPointProgressFragment
  )> }
  & StageName_EvalStage_Fragment
);

export type CheckPointOverviewPatientItemFragment = (
  { __typename?: 'Patient' }
  & { stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id'>
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'id'>
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'id'>
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'serialNumber' | 'id'>
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id'>
    )> }
  )> }
);

export type ContentCheckPointFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'hasFilesForViewer' | 'serialNumber' | 'goalStep' | 'upper' | 'lower' | 'note' | 'isFinal' | 'instruction'>
  & { files?: Maybe<(
    { __typename?: 'EvalStageFiles' }
    & { teeth?: Maybe<Array<(
      { __typename?: 'ToothFile' }
      & Pick<ToothFile, 'position'>
      & { file: (
        { __typename?: 'File' }
        & FileInfoFragment
      ) }
    )>>, upperJawModel?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )>, lowerJawModel?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )> }
  )> }
);

export type ContentDesignStageDropStageMutationVariables = {
  id: Scalars['ID'];
};


export type ContentDesignStageDropStageMutation = (
  { __typename?: 'Mutation' }
  & { dropStage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'status'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'status'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'status'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'status'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'status'>
  ) }
);

export type CreateDesignInstructionCardMutationVariables = {
  id: Scalars['ID'];
};


export type CreateDesignInstructionCardMutation = (
  { __typename?: 'Mutation' }
  & { createInstructionCard: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

export type UpdateDesignStageInTreatmentMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type UpdateDesignStageInTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { updateDesignStage: (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) }
);

export type ContentDesignStageFragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'note' | 'status' | 'hasFilesForViewer' | 'rpModelNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
  & { instructionCard?: Maybe<(
    { __typename?: 'InstructionCard' }
    & InstructionCardPreviewFragment
    & InstructionTagListFragment
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'upperStep' | 'lowerStep'>
  ), files?: Maybe<(
    { __typename?: 'DesignStageFiles' }
    & { teeth?: Maybe<Array<(
      { __typename?: 'ToothFile' }
      & Pick<ToothFile, 'position'>
      & { file: (
        { __typename?: 'File' }
        & FileInfoFragment
      ) }
    )>>, upperJawModel?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )>, lowerJawModel?: Maybe<(
      { __typename?: 'File' }
      & FileInfoFragment
    )> }
  )> }
  & StageLink_DesignStage_Fragment
  & TagCurrentTaskStageItemFragment
  & TagExpectedShippingDateStageItemFragment
);

export type BatchCreateDesignStageMutationVariables = {
  payload: CreateDesignStageInput;
};


export type BatchCreateDesignStageMutation = (
  { __typename?: 'Mutation' }
  & { createDesignStage: (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) }
);

export type ModalBatchCreateDesignFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'isCPMode'>
    & { checkPoints: Array<(
      { __typename?: 'CheckPoint' }
      & CheckPointProgressFragment
    )> }
  )>, designStages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id'>
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'id'>
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'serialNumber' | 'id'>
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id'>
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id'>
    )> }
  )> }
);

export type BatchUpdateDesignStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type BatchUpdateDesignStageMutation = (
  { __typename?: 'Mutation' }
  & { updateDesignStage: (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) }
);

export type ModalBatchEditDesignFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { startedDesignStages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id'>
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'id'>
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'serialNumber' | 'id'>
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id'>
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id'>
    )> }
  )> }
);

export type PanelCheckPointFragment = (
  { __typename?: 'CheckPoint' }
  & { designStages: Array<(
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id' | 'serialNumber' | 'status'>
    & ContentDesignStageFragment
  )> }
  & ContentCheckPointFragment
);

export type PanelCheckPointPatientItemFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & ModalBatchCreateDesignFragment
  & ModalBatchEditDesignFragment
);

export type ForwardCurrentTaskMutationVariables = {
  id: Scalars['ID'];
  payload: ForwardTaskInput;
};


export type ForwardCurrentTaskMutation = (
  { __typename?: 'Mutation' }
  & { forwardTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type BackwardCurrentTaskMutationVariables = {
  id: Scalars['ID'];
};


export type BackwardCurrentTaskMutation = (
  { __typename?: 'Mutation' }
  & { backwardTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type TagCurrentTaskStageItemFragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'status'>
  & { currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
    & { owner?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'name'>
    )>, nextTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'type'>
    )> }
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type UpdateDesignExpectedShippingDateMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type UpdateDesignExpectedShippingDateMutation = (
  { __typename?: 'Mutation' }
  & { updateDesignStage: (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) }
);

export type TagExpectedShippingDateStageItemFragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'status' | 'expectedShippingDate'>
);

export type PatientTreatmentQueryVariables = {
  id: Scalars['ID'];
};


export type PatientTreatmentQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { currentEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & { analysis?: Maybe<(
        { __typename?: 'Analysis' }
        & Pick<Analysis, 'digiAnalysis'>
      )>, stepsOverview?: Maybe<(
        { __typename?: 'File' }
        & FileInfoFragment
      )>, checkPoints: Array<(
        { __typename?: 'CheckPoint' }
        & PanelCheckPointFragment
      )> }
      & CheckPointOverviewFragment
    )> }
    & CheckPointOverviewPatientItemFragment
    & PanelCheckPointPatientItemFragment
  )> }
);

export type ClinicReportClinicItemFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name' | 'fullname' | 'phone'>
  & { location: (
    { __typename?: 'ClinicLocation' }
    & Pick<ClinicLocation, 'address'>
  ) }
);

type ClinicReportInvoiceItem_InvoiceWithStage_Fragment = (
  { __typename?: 'InvoiceWithStage' }
  & Pick<InvoiceWithStage, 'id' | 'creditDate' | 'discount' | 'middlemanFee' | 'tax' | 'totalPrice'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & StageName_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageName_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageName_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & StageName_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageName_MoldStage_Fragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'phone'>
  ), products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'name' | 'spec' | 'count' | 'price'>
  )> }
);

type ClinicReportInvoiceItem_InvoiceWithoutStage_Fragment = (
  { __typename?: 'InvoiceWithoutStage' }
  & Pick<InvoiceWithoutStage, 'id' | 'creditDate' | 'discount' | 'middlemanFee' | 'tax' | 'totalPrice'>
  & { doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'phone'>
  ), products: Array<(
    { __typename?: 'InvoiceProduct' }
    & Pick<InvoiceProduct, 'name' | 'spec' | 'count' | 'price'>
  )> }
);

export type ClinicReportInvoiceItemFragment = ClinicReportInvoiceItem_InvoiceWithStage_Fragment | ClinicReportInvoiceItem_InvoiceWithoutStage_Fragment;

export type ReportInvoiceClinicQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ReportInvoiceClinicQueryQuery = (
  { __typename?: 'Query' }
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicReportClinicItemFragment
  ) }
);

export type CustomerServiceReportEmployeeItemFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name'>
);

type CustomerServiceReportInvoiceItem_InvoiceWithStage_Fragment = (
  { __typename?: 'InvoiceWithStage' }
  & Pick<InvoiceWithStage, 'id' | 'creditDate' | 'middlemanFee'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & StageName_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageName_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageName_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & StageName_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageName_MoldStage_Fragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) }
);

type CustomerServiceReportInvoiceItem_InvoiceWithoutStage_Fragment = (
  { __typename?: 'InvoiceWithoutStage' }
  & Pick<InvoiceWithoutStage, 'id' | 'creditDate' | 'middlemanFee'>
  & { doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) }
);

export type CustomerServiceReportInvoiceItemFragment = CustomerServiceReportInvoiceItem_InvoiceWithStage_Fragment | CustomerServiceReportInvoiceItem_InvoiceWithoutStage_Fragment;

export type ReportInvoiceCustomerServiceQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ReportInvoiceCustomerServiceQueryQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & CustomerServiceReportEmployeeItemFragment
  ) }
);

export type ReportInvoiceQueryQueryVariables = {
  query?: Maybe<InvoiceQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type ReportInvoiceQueryQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'PagedInvoice' }
    & Pick<PagedInvoice, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'InvoiceWithStage' }
      & Pick<InvoiceWithStage, 'id' | 'totalPrice' | 'tax'>
      & { patient: (
        { __typename?: 'Patient' }
        & { payment: (
          { __typename?: 'PatientPayment' }
          & Pick<PatientPayment, 'type'>
        ) }
      ) }
      & ClinicReportInvoiceItem_InvoiceWithStage_Fragment
      & CustomerServiceReportInvoiceItem_InvoiceWithStage_Fragment
    ) | (
      { __typename?: 'InvoiceWithoutStage' }
      & Pick<InvoiceWithoutStage, 'id' | 'totalPrice' | 'tax'>
      & ClinicReportInvoiceItem_InvoiceWithoutStage_Fragment
      & CustomerServiceReportInvoiceItem_InvoiceWithoutStage_Fragment
    )> }
  ) }
);

export type MoldStagesTrackQueryQueryVariables = {
  query?: Maybe<MoldStagesTrackQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type MoldStagesTrackQueryQuery = (
  { __typename?: 'Query' }
  & { moldStagesTrack: (
    { __typename?: 'PagedMoldStage' }
    & Pick<PagedMoldStage, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'MoldStage' }
      & TableMoldStageFragment
    )> }
  ) }
);

export type StageAnalysisPreviewQueryVariables = {
  id: Scalars['ID'];
};


export type StageAnalysisPreviewQuery = (
  { __typename?: 'Query' }
  & { evalStage: (
    { __typename?: 'EvalStage' }
    & { patient: (
      { __typename?: 'Patient' }
      & PatientConversationFragment
    ) }
    & AnalysisPreviewFragment
  ) }
);

export type StageCalendarQueryQueryVariables = {
  query?: Maybe<StagesQuery>;
  limit?: Maybe<Scalars['Int']>;
};


export type StageCalendarQueryQuery = (
  { __typename?: 'Query' }
  & { stages: (
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'expectedShippingDate' | 'shippingDate' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'deliveryMethod'>
          & ClinicLinkFragment
        ), technician?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )> }
        & PatientLinkFragment
      ), currentTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
      & StageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id' | 'expectedShippingDate' | 'shippingDate' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'deliveryMethod'>
          & ClinicLinkFragment
        ), technician?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )> }
        & PatientLinkFragment
      ), currentTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
      & StageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'expectedShippingDate' | 'shippingDate' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'deliveryMethod'>
          & ClinicLinkFragment
        ), technician?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )> }
        & PatientLinkFragment
      ), currentTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
      & StageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'expectedShippingDate' | 'shippingDate' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'deliveryMethod'>
          & ClinicLinkFragment
        ), technician?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )> }
        & PatientLinkFragment
      ), currentTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
      & StageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'expectedShippingDate' | 'shippingDate' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & { clinic: (
          { __typename?: 'Clinic' }
          & Pick<Clinic, 'deliveryMethod'>
          & ClinicLinkFragment
        ), technician?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'name'>
        )> }
        & PatientLinkFragment
      ), currentTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
      & StageLink_MoldStage_Fragment
    )> }
  ) }
);

export type CalendarStagesQueryQueryVariables = {
  query?: Maybe<ExpectedShippingStagesQuery>;
  start: Scalars['String'];
  end: Scalars['String'];
};


export type CalendarStagesQueryQuery = (
  { __typename?: 'Query' }
  & { expectedShippingStages: Array<(
    { __typename?: 'DatedStage' }
    & Pick<DatedStage, 'date' | 'accessoryTotal' | 'printTotal' | 'evalTotal' | 'accessoryDelayTotal' | 'printDelayTotal' | 'evalDelayTotal'>
    & { accessoryStages: Array<(
      { __typename?: 'AccessoryStage' }
      & CalendarStage_AccessoryStage_Fragment
    )>, printStages: Array<(
      { __typename?: 'PrintStage' }
      & CalendarStage_PrintStage_Fragment
    )>, evalStages: Array<(
      { __typename?: 'EvalStage' }
      & CalendarStage_EvalStage_Fragment
    )> }
  )> }
);

type CalendarStage_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'delayedStatus'>
  & { patient: (
    { __typename?: 'Patient' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'deliveryMethod'>
      & ClinicLinkFragment
    ), sales: (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    ) }
    & PatientLinkFragment
  ), currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
  )> }
  & StageLink_AccessoryStage_Fragment
);

type CalendarStage_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'delayedStatus'>
  & { patient: (
    { __typename?: 'Patient' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'deliveryMethod'>
      & ClinicLinkFragment
    ), sales: (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    ) }
    & PatientLinkFragment
  ), currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
  )> }
  & StageLink_EvalStage_Fragment
);

type CalendarStage_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'delayedStatus'>
  & { patient: (
    { __typename?: 'Patient' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'deliveryMethod'>
      & ClinicLinkFragment
    ), sales: (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    ) }
    & PatientLinkFragment
  ), currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
  )> }
  & StageLink_DesignStage_Fragment
);

type CalendarStage_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'delayedStatus'>
  & { patient: (
    { __typename?: 'Patient' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'deliveryMethod'>
      & ClinicLinkFragment
    ), sales: (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    ) }
    & PatientLinkFragment
  ), currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
  )> }
  & StageLink_PrintStage_Fragment
);

type CalendarStage_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'delayedStatus'>
  & { patient: (
    { __typename?: 'Patient' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'deliveryMethod'>
      & ClinicLinkFragment
    ), sales: (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    ) }
    & PatientLinkFragment
  ), currentTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type'>
  )> }
  & StageLink_MoldStage_Fragment
);

export type CalendarStageFragment = CalendarStage_AccessoryStage_Fragment | CalendarStage_EvalStage_Fragment | CalendarStage_DesignStage_Fragment | CalendarStage_PrintStage_Fragment | CalendarStage_MoldStage_Fragment;

export type InstructionCardPreviewModalFragment = (
  { __typename?: 'InstructionCard' }
  & InstructionCardPreviewFragment
);

export type StageCardQueryQueryVariables = {
  id: Scalars['ID'];
};


export type StageCardQueryQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename: 'AccessoryStage' }
    & Pick<AccessoryStage, 'type' | 'id'>
    & { instructionCard?: Maybe<(
      { __typename?: 'InstructionCard' }
      & Pick<InstructionCard, 'retrieve' | 'instruction'>
      & InstructionTeethInfoFragment
      & InstructionCardPreviewModalFragment
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { intraOral: (
        { __typename?: 'IntraOral' }
        & { teethStatus: Array<(
          { __typename?: 'ToothStatus' }
          & Pick<ToothStatus, 'position' | 'type'>
        )> }
      ) }
      & PatientLinkFragment
    ) }
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename: 'EvalStage' }
    & Pick<EvalStage, 'type' | 'id'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { intraOral: (
        { __typename?: 'IntraOral' }
        & { teethStatus: Array<(
          { __typename?: 'ToothStatus' }
          & Pick<ToothStatus, 'position' | 'type'>
        )> }
      ) }
      & PatientLinkFragment
    ) }
  ) | (
    { __typename: 'DesignStage' }
    & Pick<DesignStage, 'type' | 'id'>
    & { instructionCard?: Maybe<(
      { __typename?: 'InstructionCard' }
      & Pick<InstructionCard, 'retrieve' | 'instruction'>
      & InstructionTeethInfoFragment
      & InstructionCardPreviewModalFragment
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { intraOral: (
        { __typename?: 'IntraOral' }
        & { teethStatus: Array<(
          { __typename?: 'ToothStatus' }
          & Pick<ToothStatus, 'position' | 'type'>
        )> }
      ) }
      & PatientLinkFragment
    ) }
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename: 'PrintStage' }
    & Pick<PrintStage, 'type' | 'id'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { intraOral: (
        { __typename?: 'IntraOral' }
        & { teethStatus: Array<(
          { __typename?: 'ToothStatus' }
          & Pick<ToothStatus, 'position' | 'type'>
        )> }
      ) }
      & PatientLinkFragment
    ) }
  ) | (
    { __typename: 'MoldStage' }
    & Pick<MoldStage, 'type' | 'id'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { intraOral: (
        { __typename?: 'IntraOral' }
        & { teethStatus: Array<(
          { __typename?: 'ToothStatus' }
          & Pick<ToothStatus, 'position' | 'type'>
        )> }
      ) }
      & PatientLinkFragment
    ) }
  ) }
);

export type UpdateStageInstructionMutationVariables = {
  id: Scalars['ID'];
  payload: InstructionCardInput;
};


export type UpdateStageInstructionMutation = (
  { __typename?: 'Mutation' }
  & { updateInstructionCard: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

type BraceAudit_AccessoryStage_Fragment = (
  { __typename: 'AccessoryStage' }
  & Pick<AccessoryStage, 'accessoryCode' | 'rpBatchNumber' | 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness' | 'id' | 'expectedShippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'name' | 'patientCode' | 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name' | 'deliveryMethod'>
    ), note: (
      { __typename?: 'PatientNote' }
      & Pick<PatientNote, 'braces'>
    ), intraOral: (
      { __typename?: 'IntraOral' }
      & { teethStatus: Array<(
        { __typename?: 'ToothStatus' }
        & Pick<ToothStatus, 'type' | 'position'>
      )> }
    ) }
  ) }
);

type BraceAudit_EvalStage_Fragment = (
  { __typename: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'expectedShippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'name' | 'patientCode' | 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name' | 'deliveryMethod'>
    ), note: (
      { __typename?: 'PatientNote' }
      & Pick<PatientNote, 'braces'>
    ), intraOral: (
      { __typename?: 'IntraOral' }
      & { teethStatus: Array<(
        { __typename?: 'ToothStatus' }
        & Pick<ToothStatus, 'type' | 'position'>
      )> }
    ) }
  ) }
);

type BraceAudit_DesignStage_Fragment = (
  { __typename: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'expectedShippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'name' | 'patientCode' | 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name' | 'deliveryMethod'>
    ), note: (
      { __typename?: 'PatientNote' }
      & Pick<PatientNote, 'braces'>
    ), intraOral: (
      { __typename?: 'IntraOral' }
      & { teethStatus: Array<(
        { __typename?: 'ToothStatus' }
        & Pick<ToothStatus, 'type' | 'position'>
      )> }
    ) }
  ) }
);

type BraceAudit_PrintStage_Fragment = (
  { __typename: 'PrintStage' }
  & Pick<PrintStage, 'serialNumber' | 'rpBatchNumber' | 'id' | 'expectedShippingDate'>
  & { designStage?: Maybe<(
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'upperModelCount' | 'lowerModelCount' | 'upperAttachmentTemplateCount' | 'lowerAttachmentTemplateCount' | 'upperMouthGuardCount' | 'lowerMouthGuardCount' | 'upperBraceCount' | 'upperBraceMaterial' | 'upperBraceThickness' | 'lowerBraceCount' | 'lowerBraceMaterial' | 'lowerBraceThickness'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'type'>
      & { owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'name'>
      )> }
    )>, step: (
      { __typename?: 'Step' }
      & Pick<Step, 'ipr' | 'attachment' | 'upperStep' | 'lowerStep'>
    ), instructionCard?: Maybe<(
      { __typename?: 'InstructionCard' }
      & Pick<InstructionCard, 'instruction'>
    )> }
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'name' | 'patientCode' | 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name' | 'deliveryMethod'>
    ), note: (
      { __typename?: 'PatientNote' }
      & Pick<PatientNote, 'braces'>
    ), intraOral: (
      { __typename?: 'IntraOral' }
      & { teethStatus: Array<(
        { __typename?: 'ToothStatus' }
        & Pick<ToothStatus, 'type' | 'position'>
      )> }
    ) }
  ) }
);

type BraceAudit_MoldStage_Fragment = (
  { __typename: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'expectedShippingDate'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'name' | 'patientCode' | 'cabinetCode'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'name' | 'deliveryMethod'>
    ), note: (
      { __typename?: 'PatientNote' }
      & Pick<PatientNote, 'braces'>
    ), intraOral: (
      { __typename?: 'IntraOral' }
      & { teethStatus: Array<(
        { __typename?: 'ToothStatus' }
        & Pick<ToothStatus, 'type' | 'position'>
      )> }
    ) }
  ) }
);

export type BraceAuditFragment = BraceAudit_AccessoryStage_Fragment | BraceAudit_EvalStage_Fragment | BraceAudit_DesignStage_Fragment | BraceAudit_PrintStage_Fragment | BraceAudit_MoldStage_Fragment;

export type OrderInfoFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'displayId' | 'type' | 'instruction'>
  & { stageOrder: Array<(
    { __typename?: 'StageOrderContent' }
    & Pick<StageOrderContent, 'stageType' | 'number' | 'items'>
  )>, creator: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'startDate'>
  )> }
);

export type StageOrderInfoQueryVariables = {
  stageId: Scalars['ID'];
};


export type StageOrderInfoQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInfoFragment
    )> }
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInfoFragment
    )> }
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & { latestPrintStage?: Maybe<(
      { __typename?: 'PrintStage' }
      & { order?: Maybe<(
        { __typename?: 'Order' }
        & OrderInfoFragment
      )> }
      & StageLink_PrintStage_Fragment
    )> }
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInfoFragment
    )> }
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & { latestEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & { order?: Maybe<(
        { __typename?: 'Order' }
        & OrderInfoFragment
      )> }
      & StageLink_EvalStage_Fragment
    )> }
    & StageLink_MoldStage_Fragment
  ) }
);

export type OrderInstructionFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'type' | 'instruction'>
);

export type StageOrderInstructionQueryVariables = {
  stageId: Scalars['ID'];
};


export type StageOrderInstructionQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInstructionFragment
    )> }
  ) | (
    { __typename?: 'EvalStage' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInstructionFragment
    )> }
  ) | (
    { __typename?: 'DesignStage' }
    & { latestPrintStage?: Maybe<(
      { __typename?: 'PrintStage' }
      & Pick<PrintStage, 'id'>
      & { order?: Maybe<(
        { __typename?: 'Order' }
        & OrderInstructionFragment
      )> }
    )> }
  ) | (
    { __typename?: 'PrintStage' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInstructionFragment
    )> }
  ) | (
    { __typename?: 'MoldStage' }
    & { latestEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id'>
      & { order?: Maybe<(
        { __typename?: 'Order' }
        & OrderInstructionFragment
      )> }
    )> }
  ) }
);

export type StagePatientInfoQueryVariables = {
  id: Scalars['ID'];
};


export type StagePatientInfoQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { doctor: (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'note'>
    ), doctorInstruction: (
      { __typename?: 'DoctorInstruction' }
      & Pick<DoctorInstruction, 'note' | 'ipr' | 'tads' | 'otherOrthdontalTools' | 'functionalAppliance'>
      & { maintainSpace: (
        { __typename?: 'MaintainSpace' }
        & Pick<MaintainSpace, 'isMaintainingSpace' | 'note'>
      ), changeProsthesisToTemp: (
        { __typename?: 'ChangeProsthesisToTemp' }
        & Pick<ChangeProsthesisToTemp, 'need' | 'instruction'>
      ), extractToothWhenNoSpace: (
        { __typename?: 'ExtractToothWhenNoSpace' }
        & Pick<ExtractToothWhenNoSpace, 'intention' | 'toothType'>
      ) }
    ), note: (
      { __typename?: 'PatientNote' }
      & Pick<PatientNote, 'design' | 'braces'>
    ) }
  )> }
);

export type UpdateDoctorNoteMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDoctorInput;
};


export type UpdateDoctorNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateDoctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id'>
  ) }
);

export type UpdatePatientInfoMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePatientInput;
};


export type UpdatePatientInfoMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
);

export type StartedEvalStagesQueryVariables = {
  patientId: Scalars['ID'];
};


export type StartedEvalStagesQuery = (
  { __typename?: 'Query' }
  & { evalStages: (
    { __typename?: 'PagedEvalStage' }
    & { docs: Array<(
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id'>
      & StageLink_EvalStage_Fragment
    )> }
  ) }
);

export type NextTaskAssigneeWorkingTaskStatisticQueryVariables = {
  workingTaskQuery: WorkingTaskStatisticQuery;
};


export type NextTaskAssigneeWorkingTaskStatisticQuery = (
  { __typename?: 'Query' }
  & { workingTaskStatistic: Array<(
    { __typename?: 'WorkingTaskStatistic' }
    & Pick<WorkingTaskStatistic, 'taskType' | 'count'>
    & { owner: (
      { __typename?: 'Employee' }
      & EmployeeLinkFragment
    ) }
  )> }
);

export type StageTasksQueryQueryVariables = {
  id: Scalars['ID'];
};


export type StageTasksQueryQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id' | 'type'>
    & { currentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ), nextTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status' | 'type' | 'completedByOwner'>
      & { owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'type'>
    & { currentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ), nextTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status' | 'type' | 'completedByOwner'>
      & { owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id' | 'type'>
    & { currentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ), nextTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status' | 'type' | 'completedByOwner'>
      & { owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'type'>
    & { currentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ), nextTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status' | 'type' | 'completedByOwner'>
      & { owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id' | 'type'>
    & { currentTask?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
      & { patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id'>
      ), nextTask?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'type'>
      )> }
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status' | 'type' | 'completedByOwner'>
      & { owner?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'name'>
      ) | (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'name'>
      ) | (
        { __typename?: 'Doctor' }
        & Pick<Doctor, 'name'>
      ) | (
        { __typename?: 'Employee' }
        & Pick<Employee, 'name'>
      )> }
    )> }
  ) }
);

export type ForwardTaskMutationVariables = {
  id: Scalars['ID'];
  payload: ForwardTaskInput;
};


export type ForwardTaskMutation = (
  { __typename?: 'Mutation' }
  & { forwardTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type BackwardTaskMutationVariables = {
  id: Scalars['ID'];
};


export type BackwardTaskMutation = (
  { __typename?: 'Mutation' }
  & { backwardTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type StageQueryQueryVariables = {
  id: Scalars['ID'];
};


export type StageQueryQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & BraceAudit_AccessoryStage_Fragment
    & FormBasic_AccessoryStage_Fragment
    & FormAnalysis_AccessoryStage_Fragment
    & FormProduct_AccessoryStage_Fragment
    & FormFile_AccessoryStage_Fragment
  ) | (
    { __typename: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'type'>
    & { initialEvalStage?: Maybe<(
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id'>
    )>, patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & BraceAudit_EvalStage_Fragment
    & FormBasic_EvalStage_Fragment
    & FormAnalysis_EvalStage_Fragment
    & FormProduct_EvalStage_Fragment
    & FormFile_EvalStage_Fragment
  ) | (
    { __typename: 'DesignStage' }
    & Pick<DesignStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & BraceAudit_DesignStage_Fragment
    & FormBasic_DesignStage_Fragment
    & FormAnalysis_DesignStage_Fragment
    & FormProduct_DesignStage_Fragment
    & FormFile_DesignStage_Fragment
  ) | (
    { __typename: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & BraceAudit_PrintStage_Fragment
    & FormBasic_PrintStage_Fragment
    & FormAnalysis_PrintStage_Fragment
    & FormProduct_PrintStage_Fragment
    & FormFile_PrintStage_Fragment
  ) | (
    { __typename: 'MoldStage' }
    & Pick<MoldStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & BraceAudit_MoldStage_Fragment
    & FormBasic_MoldStage_Fragment
    & FormAnalysis_MoldStage_Fragment
    & FormProduct_MoldStage_Fragment
    & FormFile_MoldStage_Fragment
  ) }
);

export type CreateInstructionCardMutationVariables = {
  id: Scalars['ID'];
};


export type CreateInstructionCardMutation = (
  { __typename?: 'Mutation' }
  & { createInstructionCard: (
    { __typename?: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id'>
  ) | (
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  ) | (
    { __typename?: 'DesignStage' }
    & Pick<DesignStage, 'id'>
  ) | (
    { __typename?: 'PrintStage' }
    & Pick<PrintStage, 'id'>
  ) | (
    { __typename?: 'MoldStage' }
    & Pick<MoldStage, 'id'>
  ) }
);

export type UpdateMoldStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateMoldStageInput;
};


export type UpdateMoldStageMutation = (
  { __typename?: 'Mutation' }
  & { updateMoldStage: (
    { __typename: 'MoldStage' }
    & Pick<MoldStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & FormBasic_MoldStage_Fragment
    & FormAnalysis_MoldStage_Fragment
    & FormProduct_MoldStage_Fragment
    & FormFile_MoldStage_Fragment
  ) }
);

export type UpdateEvalStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateEvalStageInput;
};


export type UpdateEvalStageMutation = (
  { __typename?: 'Mutation' }
  & { updateEvalStage: (
    { __typename: 'EvalStage' }
    & Pick<EvalStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & FormBasic_EvalStage_Fragment
    & FormAnalysis_EvalStage_Fragment
    & FormProduct_EvalStage_Fragment
    & FormFile_EvalStage_Fragment
  ) }
);

export type UpdateDesignStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDesignStageInput;
};


export type UpdateDesignStageMutation = (
  { __typename?: 'Mutation' }
  & { updateDesignStage: (
    { __typename: 'DesignStage' }
    & Pick<DesignStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & FormBasic_DesignStage_Fragment
    & FormAnalysis_DesignStage_Fragment
    & FormProduct_DesignStage_Fragment
    & FormFile_DesignStage_Fragment
  ) }
);

export type UpdatePrintStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdatePrintStageInput;
};


export type UpdatePrintStageMutation = (
  { __typename?: 'Mutation' }
  & { updatePrintStage: (
    { __typename: 'PrintStage' }
    & Pick<PrintStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & FormBasic_PrintStage_Fragment
    & FormAnalysis_PrintStage_Fragment
    & FormProduct_PrintStage_Fragment
    & FormFile_PrintStage_Fragment
  ) }
);

export type UpdateAccessoryStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateAccessoryStageInput;
};


export type UpdateAccessoryStageMutation = (
  { __typename?: 'Mutation' }
  & { updateAccessoryStage: (
    { __typename: 'AccessoryStage' }
    & Pick<AccessoryStage, 'id' | 'type'>
    & { patient: (
      { __typename?: 'Patient' }
      & { currentEvalStage?: Maybe<(
        { __typename?: 'EvalStage' }
        & Pick<EvalStage, 'id'>
      )> }
    ) }
    & FormBasic_AccessoryStage_Fragment
    & FormAnalysis_AccessoryStage_Fragment
    & FormProduct_AccessoryStage_Fragment
    & FormFile_AccessoryStage_Fragment
  ) }
);

export type StageListQueryVariables = {
  query?: Maybe<StagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type StageListQuery = (
  { __typename?: 'Query' }
  & { stages: (
    { __typename?: 'PagedStage' }
    & Pick<PagedStage, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'AccessoryStage' }
      & StageTable_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageTable_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageTable_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageTable_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageTable_MoldStage_Fragment
    )> }
  ) }
);

export type StatisticEmployeeTaskQueryQueryVariables = {
  query: EmployeeTaskStatisticQuery;
  sort?: Maybe<Scalars['String']>;
};


export type StatisticEmployeeTaskQueryQuery = (
  { __typename?: 'Query' }
  & { employeeTaskStatistic: Array<(
    { __typename?: 'EmployeeTaskStatistic' }
    & Pick<EmployeeTaskStatistic, 'startDate' | 'endDate' | 'taskType' | 'count'>
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    ) }
  )>, employees: (
    { __typename?: 'PagedEmployee' }
    & { docs: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'role'>
    )> }
  ) }
);

export type StatisticInvoiceQueryQueryVariables = {
  query: InvoiceStatisticQuery;
};


export type StatisticInvoiceQueryQuery = (
  { __typename?: 'Query' }
  & { invoiceStatistic: Array<(
    { __typename?: 'InvoiceStatistic' }
    & Pick<InvoiceStatistic, 'startDate' | 'endDate'>
    & { segment: (
      { __typename?: 'PatientSegment' }
      & Pick<PatientSegment, 'title' | 'brands' | 'sources'>
    ), indicators?: Maybe<Array<(
      { __typename?: 'InvoiceStatisticIndicator' }
      & Pick<InvoiceStatisticIndicator, 'type' | 'value'>
    )>> }
  )> }
);

export type FinishedPatientListQueryQueryVariables = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type FinishedPatientListQueryQuery = (
  { __typename?: 'Query' }
  & { patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & Pick<PagedPatient, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { treatArches: (
        { __typename?: 'TreatArches' }
        & Pick<TreatArches, 'upper' | 'lower'>
      ), accountManager?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      )>, technician?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      )>, payment: (
        { __typename?: 'PatientPayment' }
        & Pick<PatientPayment, 'base' | 'brand'>
      ), meta: (
        { __typename?: 'PatientMeta' }
        & Pick<PatientMeta, 'currentStepNumber' | 'finalStepNumber' | 'techRevenue' | 'techCost' | 'techProfit' | 'firstEvalStageAt' | 'firstPrintStageAt' | 'lastPrintStageAt'>
      ) }
      & TablePatientInfoFragment
    )> }
  )> }
);

export type StatisticPatientQueryQueryVariables = {
  query: PatientStatisticQuery;
};


export type StatisticPatientQueryQuery = (
  { __typename?: 'Query' }
  & { patientStatistic: Array<(
    { __typename?: 'PatientStatistic' }
    & Pick<PatientStatistic, 'startDate' | 'endDate'>
    & { segment: (
      { __typename?: 'PatientSegment' }
      & Pick<PatientSegment, 'title' | 'brands' | 'sources'>
    ), indicators?: Maybe<Array<(
      { __typename?: 'PatientStatisticIndicator' }
      & Pick<PatientStatisticIndicator, 'type' | 'value'>
    )>> }
  )> }
);

export type StatisticStageQueryQueryVariables = {
  query: StageCapacityQuery;
};


export type StatisticStageQueryQuery = (
  { __typename?: 'Query' }
  & { stageCapacity: Array<(
    { __typename?: 'StageCapacityPerType' }
    & Pick<StageCapacityPerType, 'stageType' | 'startDate' | 'endDate' | 'actualCount' | 'expectedCount' | 'forecastCount'>
  )> }
);

export type StatisticQueryQueryVariables = {
  query: StatisticQuery;
};


export type StatisticQueryQuery = (
  { __typename?: 'Query' }
  & { statistics: Array<(
    { __typename?: 'Statistic' }
    & Pick<Statistic, 'startDate' | 'endDate'>
    & { indicators: Array<(
      { __typename?: 'StatisticIndicator' }
      & Pick<StatisticIndicator, 'type' | 'value'>
    )> }
  )> }
);

export type MinimumRequiredAppVersionQueryVariables = {};


export type MinimumRequiredAppVersionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'minimumRequiredAppVersion'>
);

export type UpdateMinimumRequiredAppVersionSystemMutationVariables = {
  version: Scalars['String'];
};


export type UpdateMinimumRequiredAppVersionSystemMutation = (
  { __typename?: 'Mutation' }
  & { updateMinimumRequiredAppVersionSystem: (
    { __typename?: 'MinimumRequiredAppVersionSystem' }
    & Pick<MinimumRequiredAppVersionSystem, 'minimumRequiredAppVersion'>
  ) }
);

export type UpdateSystemEntityTypeToPrivilegesListMutationVariables = {
  configType: SystemConfigType;
  payload: UpdateSystemEntityTypeToPrivilegesInput;
};


export type UpdateSystemEntityTypeToPrivilegesListMutation = (
  { __typename?: 'Mutation' }
  & { updateSystemEntityTypeToPrivilegesList: (
    { __typename?: 'EntityTypeToPrivilegesListSystem' }
    & Pick<EntityTypeToPrivilegesListSystem, 'id'>
  ) }
);

export type UpdateSystemRoleToPrivilegesListMutationVariables = {
  configType: SystemConfigType;
  payload: UpdateSystemRoleToPrivilegesInput;
};


export type UpdateSystemRoleToPrivilegesListMutation = (
  { __typename?: 'Mutation' }
  & { updateSystemRoleToPrivilegesList: (
    { __typename?: 'RoleToPrivilegesListSystem' }
    & Pick<RoleToPrivilegesListSystem, 'id'>
  ) }
);

export type MultipleSystemQueryVariables = {};


export type MultipleSystemQuery = (
  { __typename?: 'Query' }
  & { roleToPrivilegesListSystem: (
    { __typename?: 'MinimumRequiredAppVersionSystem' }
    & Pick<MinimumRequiredAppVersionSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'EntityTypeToPrivilegesListSystem' }
    & Pick<EntityTypeToPrivilegesListSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'StageCapacitySystem' }
    & Pick<StageCapacitySystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'RoleToPrivilegesListSystem' }
    & Pick<RoleToPrivilegesListSystem, 'id' | 'configType'>
    & { roleToPrivilegesList: Array<(
      { __typename?: 'SystemRoleToPrivileges' }
      & Pick<SystemRoleToPrivileges, 'role' | 'privileges'>
    )> }
  ) | (
    { __typename?: 'StageCostSystem' }
    & Pick<StageCostSystem, 'id' | 'configType'>
  ), entityTypeToPrivilegesListSystem: (
    { __typename?: 'MinimumRequiredAppVersionSystem' }
    & Pick<MinimumRequiredAppVersionSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'EntityTypeToPrivilegesListSystem' }
    & Pick<EntityTypeToPrivilegesListSystem, 'id' | 'configType'>
    & { entityTypeToPrivilegesList: Array<(
      { __typename?: 'SystemEntityTypeToPrivileges' }
      & Pick<SystemEntityTypeToPrivileges, 'entityType' | 'privileges'>
    )> }
  ) | (
    { __typename?: 'StageCapacitySystem' }
    & Pick<StageCapacitySystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'RoleToPrivilegesListSystem' }
    & Pick<RoleToPrivilegesListSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'StageCostSystem' }
    & Pick<StageCostSystem, 'id' | 'configType'>
  ) }
);

export type ExportPatientsMutationVariables = {
  query: PatientsQuery;
};


export type ExportPatientsMutation = (
  { __typename?: 'Mutation' }
  & { exportPatients: (
    { __typename?: 'ExportData' }
    & Pick<ExportData, 'data'>
  ) }
);

export type ExportInvoicesMutationVariables = {
  query: InvoiceQuery;
};


export type ExportInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { exportInvoices: (
    { __typename?: 'ExportData' }
    & Pick<ExportData, 'data'>
  ) }
);

export type SystemStageCapacityQueryQueryVariables = {};


export type SystemStageCapacityQueryQuery = (
  { __typename?: 'Query' }
  & { system: (
    { __typename?: 'MinimumRequiredAppVersionSystem' }
    & Pick<MinimumRequiredAppVersionSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'EntityTypeToPrivilegesListSystem' }
    & Pick<EntityTypeToPrivilegesListSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'StageCapacitySystem' }
    & Pick<StageCapacitySystem, 'id' | 'configType'>
    & { stageCapacity: (
      { __typename?: 'MaxStageCapacity' }
      & Pick<MaxStageCapacity, 'maxPoints'>
      & { capacityPerType: Array<(
        { __typename?: 'MaxStageCapacityPerType' }
        & Pick<MaxStageCapacityPerType, 'stageType' | 'pointsPerUnit' | 'maxPoints'>
      )> }
    ) }
  ) | (
    { __typename?: 'RoleToPrivilegesListSystem' }
    & Pick<RoleToPrivilegesListSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'StageCostSystem' }
    & Pick<StageCostSystem, 'id' | 'configType'>
  ) }
);

export type UpdateSystemMaxStageCapacityMutationVariables = {
  configType: SystemConfigType;
  payload: UpdateSystemMaxStageCapacityInput;
};


export type UpdateSystemMaxStageCapacityMutation = (
  { __typename?: 'Mutation' }
  & { updateSystemMaxStageCapacity: (
    { __typename?: 'StageCapacitySystem' }
    & Pick<StageCapacitySystem, 'id'>
  ) }
);

export type SystemStageCostQueryQueryVariables = {};


export type SystemStageCostQueryQuery = (
  { __typename?: 'Query' }
  & { system: (
    { __typename?: 'MinimumRequiredAppVersionSystem' }
    & Pick<MinimumRequiredAppVersionSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'EntityTypeToPrivilegesListSystem' }
    & Pick<EntityTypeToPrivilegesListSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'StageCapacitySystem' }
    & Pick<StageCapacitySystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'RoleToPrivilegesListSystem' }
    & Pick<RoleToPrivilegesListSystem, 'id' | 'configType'>
  ) | (
    { __typename?: 'StageCostSystem' }
    & Pick<StageCostSystem, 'id' | 'configType'>
    & { stageCost: Array<(
      { __typename?: 'StageCostPerType' }
      & Pick<StageCostPerType, 'stageType' | 'cost'>
    )> }
  ) }
);

export type UpdateSystemStageCostMutationVariables = {
  configType: SystemConfigType;
  payload: UpdateSystemStageCostInput;
};


export type UpdateSystemStageCostMutation = (
  { __typename?: 'Mutation' }
  & { updateSystemStageCost: (
    { __typename?: 'StageCostSystem' }
    & Pick<StageCostSystem, 'id'>
  ) }
);

export type TasksQueryQueryVariables = {
  query?: Maybe<TasksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type TasksQueryQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'PagedTask' }
    & Pick<PagedTask, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Task' }
      & TableTaskFragment
    )> }
  )> }
);

export type TracksQueryQueryVariables = {
  query?: Maybe<TracksQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type TracksQueryQuery = (
  { __typename?: 'Query' }
  & { tracks?: Maybe<(
    { __typename?: 'PagedTrack' }
    & Pick<PagedTrack, 'limit' | 'total' | 'page'>
    & { docs: Array<(
      { __typename?: 'Track' }
      & TrackTableFragment
    )> }
  )> }
);

export type StageModelQueryQueryVariables = {
  id: Scalars['ID'];
};


export type StageModelQueryQuery = (
  { __typename?: 'Query' }
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & { patient: (
      { __typename?: 'Patient' }
      & DentalModelViewerFragment
    ) }
  ) | (
    { __typename?: 'EvalStage' }
    & { patient: (
      { __typename?: 'Patient' }
      & DentalModelViewerFragment
    ) }
  ) | (
    { __typename?: 'DesignStage' }
    & { patient: (
      { __typename?: 'Patient' }
      & DentalModelViewerFragment
    ) }
  ) | (
    { __typename?: 'PrintStage' }
    & { patient: (
      { __typename?: 'Patient' }
      & DentalModelViewerFragment
    ) }
  ) | (
    { __typename?: 'MoldStage' }
    & { patient: (
      { __typename?: 'Patient' }
      & DentalModelViewerFragment
    ) }
  ) }
);

export type PatientConversationQueryQueryVariables = {
  id: Scalars['ID'];
};


export type PatientConversationQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { conversation: (
      { __typename?: 'PatientConversation' }
      & Pick<PatientConversation, 'id'>
    ) }
  )> }
);

export type ClinicInfoFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'type' | 'name' | 'fullname' | 'phone' | 'email' | 'specialContract'>
  & { location: (
    { __typename?: 'ClinicLocation' }
    & Pick<ClinicLocation, 'address' | 'country' | 'district'>
  ), doctors: Array<(
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  )>, accountManagers: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, technicians: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, sales: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'phone'>
  )>, customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )> }
);

export type CreateClinicMutationVariables = {
  payload: CreateClinicInput;
};


export type CreateClinicMutation = (
  { __typename?: 'Mutation' }
  & { createClinic: (
    { __typename?: 'Clinic' }
    & ClinicInfoFragment
  ) }
);

export type RemoveClinicMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveClinicMutation = (
  { __typename?: 'Mutation' }
  & { removeClinic: (
    { __typename?: 'Clinic' }
    & ClinicInfoFragment
  ) }
);

export type UpdateClinicMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateClinicInput;
};


export type UpdateClinicMutation = (
  { __typename?: 'Mutation' }
  & { updateClinic: (
    { __typename?: 'Clinic' }
    & ClinicInfoFragment
  ) }
);

export type ClinicsQueryQueryVariables = {
  query?: Maybe<ClinicsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type ClinicsQueryQuery = (
  { __typename?: 'Query' }
  & { clinics: (
    { __typename?: 'PagedClinic' }
    & Pick<PagedClinic, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Clinic' }
      & ClinicInfoFragment
    )> }
  ) }
);

export type UpdateConversationReadMessageRecordOfMemberMutationMutationVariables = {
  id: Scalars['ID'];
  entity: Scalars['ID'];
};


export type UpdateConversationReadMessageRecordOfMemberMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConversationReadMessageRecordOfMember: (
    { __typename?: 'PatientConversation' }
    & ConversationInfoFragment
  ) }
);

export type ConversationQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ConversationQueryQuery = (
  { __typename?: 'Query' }
  & { conversation: (
    { __typename?: 'PatientConversation' }
    & ConversationInfoFragment
  ) }
);

export type ConversationMessagesQueryQueryVariables = {
  query?: Maybe<ConversationMessagesQueryInput>;
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type ConversationMessagesQueryQuery = (
  { __typename?: 'Query' }
  & { conversationMessages: (
    { __typename?: 'MessageConnection' }
    & { edges: Array<(
      { __typename?: 'MessageEdge' }
      & Pick<MessageEdge, 'cursor'>
      & { node: (
        { __typename?: 'TextMessage' }
        & MessageInfo_TextMessage_Fragment
      ) | (
        { __typename?: 'ImageMessage' }
        & MessageInfo_ImageMessage_Fragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type NumberOfUnreadMessagesQueryQueryVariables = {
  id: Scalars['ID'];
};


export type NumberOfUnreadMessagesQueryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'numberOfUnreadMessages'>
);

export type ConversationsWithLatestMessageQueryQueryVariables = {
  query?: Maybe<ConversationsWithLatestMessageQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type ConversationsWithLatestMessageQueryQuery = (
  { __typename?: 'Query' }
  & { conversationsWithLatestMessage: (
    { __typename?: 'ConversationsWithLatestMessageConnection' }
    & { edges: Array<(
      { __typename?: 'ConversationsWithLatestMessageEdge' }
      & Pick<ConversationsWithLatestMessageEdge, 'cursor'>
      & { node: (
        { __typename?: 'ConversationsWithLatestMessageNode' }
        & Pick<ConversationsWithLatestMessageNode, 'id' | 'numberOfUnreadMessages'>
        & { latestMessage: (
          { __typename?: 'TextMessage' }
          & MessageInfo_TextMessage_Fragment
        ) | (
          { __typename?: 'ImageMessage' }
          & MessageInfo_ImageMessage_Fragment
        ), patient: (
          { __typename?: 'Patient' }
          & Pick<Patient, 'id' | 'name'>
        ) }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type DoctorInfoFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'created' | 'updated' | 'name' | 'phone' | 'note' | 'email'>
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
    & { sales: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'phone'>
    )> }
  )>, patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & Pick<PagedPatient, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    )> }
  )> }
);

export type CreateDoctorMutationVariables = {
  payload: CreateDoctorInput;
};


export type CreateDoctorMutation = (
  { __typename?: 'Mutation' }
  & { createDoctor: (
    { __typename?: 'Doctor' }
    & DoctorInfoFragment
  ) }
);

export type RemoveDoctorMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveDoctorMutation = (
  { __typename?: 'Mutation' }
  & { removeDoctor: (
    { __typename?: 'Doctor' }
    & DoctorInfoFragment
  ) }
);

export type UpdateDoctorMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateDoctorInput;
};


export type UpdateDoctorMutation = (
  { __typename?: 'Mutation' }
  & { updateDoctor: (
    { __typename?: 'Doctor' }
    & DoctorInfoFragment
  ) }
);

export type EmployeeInfoFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'onBoardDate' | 'title' | 'taskOwner' | 'resigned' | 'role'>
  & { approvers: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )> }
  & EmployeeLinkFragment
);

export type EmployeeLeavesQueryQueryVariables = {
  id: Scalars['ID'];
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeeLeavesQueryQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & { leaves?: Maybe<(
      { __typename?: 'PagedLeave' }
      & Pick<PagedLeave, 'limit' | 'page' | 'total'>
      & { docs: Array<(
        { __typename?: 'Leave' }
        & LeaveInfoFragment
      )> }
    )>, leaveStatus: (
      { __typename?: 'LeaveStatusQuery' }
      & Pick<LeaveStatusQuery, 'remainAnnual' | 'usedSickLeave' | 'usedPersonalLeave'>
    ) }
    & EmployeeInfoFragment
  ) }
);

export type EmployeesQueryQueryVariables = {
  query?: Maybe<EmployeesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type EmployeesQueryQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'PagedEmployee' }
    & Pick<PagedEmployee, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Employee' }
      & EmployeeInfoFragment
    )> }
  ) }
);

export type FeedbackInfoFragment = (
  { __typename?: 'Feedback' }
  & Pick<Feedback, 'id' | 'created' | 'entityType' | 'category' | 'title' | 'content' | 'status'>
  & { entity: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) | (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ) | (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) | (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), screenshotList?: Maybe<Array<(
    { __typename?: 'File' }
    & FileInfoFragment
  )>> }
);

export type CreateFeedbackMutationVariables = {
  payload: CreateFeedbackInput;
};


export type CreateFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { createFeedback: (
    { __typename?: 'Feedback' }
    & FeedbackInfoFragment
  ) }
);

export type RemoveFeedbackMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { removeFeedback: (
    { __typename?: 'Feedback' }
    & FeedbackInfoFragment
  ) }
);

export type UpdateFeedbackMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateFeedbackInput;
};


export type UpdateFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { updateFeedback: (
    { __typename?: 'Feedback' }
    & FeedbackInfoFragment
  ) }
);

export type FeedbackQueryQueryVariables = {
  id: Scalars['ID'];
};


export type FeedbackQueryQuery = (
  { __typename?: 'Query' }
  & { feedback: (
    { __typename?: 'Feedback' }
    & FeedbackInfoFragment
  ) }
);

export type FeedbacksQueryQueryVariables = {
  query?: Maybe<FeedbackQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type FeedbacksQueryQuery = (
  { __typename?: 'Query' }
  & { feedbacks: (
    { __typename?: 'PagedFeedback' }
    & Pick<PagedFeedback, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Feedback' }
      & FeedbackInfoFragment
    )> }
  ) }
);

export type CreateInvoiceWithStageMutationVariables = {
  payload: CreateInvoiceWithStageInput;
};


export type CreateInvoiceWithStageMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceWithStage: (
    { __typename?: 'InvoiceWithStage' }
    & Pick<InvoiceWithStage, 'id'>
  ) }
);

export type CreateInvoiceWithoutStageMutationVariables = {
  payload: CreateInvoiceWithoutStageInput;
};


export type CreateInvoiceWithoutStageMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceWithoutStage: (
    { __typename?: 'InvoiceWithoutStage' }
    & Pick<InvoiceWithoutStage, 'id'>
  ) }
);

export type UpdateInvoiceWithStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateInvoiceWithStageInput;
};


export type UpdateInvoiceWithStageMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceWithStage: (
    { __typename?: 'InvoiceWithStage' }
    & Pick<InvoiceWithStage, 'id'>
  ) }
);

export type UpdateInvoiceWithoutStageMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateInvoiceWithoutStageInput;
};


export type UpdateInvoiceWithoutStageMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceWithoutStage: (
    { __typename?: 'InvoiceWithoutStage' }
    & Pick<InvoiceWithoutStage, 'id'>
  ) }
);

export type UpdateInvoicePaymentMutationVariables = {
  query: InvoiceQuery;
  payload: UpdateInvoicePaymentInput;
};


export type UpdateInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateInvoicePayment'>
);

export type LeaveInfoFragment = (
  { __typename?: 'Leave' }
  & Pick<Leave, 'id' | 'created' | 'updated' | 'startDate' | 'endDate' | 'type' | 'reason' | 'isApproved'>
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'role'>
    & { approvers: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )>, leaveStatus: (
      { __typename?: 'LeaveStatusQuery' }
      & Pick<LeaveStatusQuery, 'remainAnnual' | 'usedSickLeave' | 'usedPersonalLeave'>
    ) }
  ) }
);

export type CreateLeaveMutationVariables = {
  payload: CreateLeaveInput;
};


export type CreateLeaveMutation = (
  { __typename?: 'Mutation' }
  & { createLeave: (
    { __typename?: 'Leave' }
    & LeaveInfoFragment
  ) }
);

export type RemoveLeaveMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveLeaveMutation = (
  { __typename?: 'Mutation' }
  & { removeLeave: (
    { __typename?: 'Leave' }
    & LeaveInfoFragment
  ) }
);

export type UpdateLeaveMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateLeaveInput;
};


export type UpdateLeaveMutation = (
  { __typename?: 'Mutation' }
  & { updateLeave: (
    { __typename?: 'Leave' }
    & LeaveInfoFragment
  ) }
);

export type LeavesCanApproveQueryVariables = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type LeavesCanApproveQuery = (
  { __typename?: 'Query' }
  & { leavesCanApprove: (
    { __typename?: 'PagedLeave' }
    & Pick<PagedLeave, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Leave' }
      & LeaveInfoFragment
    )> }
  ) }
);

export type LeavesForCalendarQueryQueryVariables = {
  startDate: Scalars['Date'];
};


export type LeavesForCalendarQueryQuery = (
  { __typename?: 'Query' }
  & { leavesForCalendar: Array<(
    { __typename?: 'Leave' }
    & LeaveInfoFragment
  )> }
);

export type LeaveQueryQueryVariables = {
  id: Scalars['ID'];
};


export type LeaveQueryQuery = (
  { __typename?: 'Query' }
  & { leave: (
    { __typename?: 'Leave' }
    & LeaveInfoFragment
  ) }
);

export type LeavesQueryQueryVariables = {
  query?: Maybe<LeavesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type LeavesQueryQuery = (
  { __typename?: 'Query' }
  & { leaves: (
    { __typename?: 'PagedLeave' }
    & Pick<PagedLeave, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Leave' }
      & LeaveInfoFragment
    )> }
  ) }
);

export type OnMessageAddedSubscriptionSubscriptionVariables = {
  payload: SubscriptionPayload;
};


export type OnMessageAddedSubscriptionSubscription = (
  { __typename?: 'Subscription' }
  & { onMessageAdded: (
    { __typename?: 'MessageEdge' }
    & Pick<MessageEdge, 'cursor'>
    & { node: (
      { __typename?: 'TextMessage' }
      & MessageInfo_TextMessage_Fragment
    ) | (
      { __typename?: 'ImageMessage' }
      & MessageInfo_ImageMessage_Fragment
    ) }
  ) }
);

export type OrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'dueDate' | 'isLatest' | 'type' | 'status' | 'description'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ), sales: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    ) }
  ), stageOrder: Array<(
    { __typename?: 'StageOrderContent' }
    & Pick<StageOrderContent, 'stageType' | 'number' | 'items'>
  )>, stageProvide: Array<(
    { __typename?: 'StageProvideContent' }
    & Pick<StageProvideContent, 'stageType'>
    & { stages: Array<(
      { __typename?: 'AccessoryStage' }
      & StageLink_AccessoryStage_Fragment
    ) | (
      { __typename?: 'EvalStage' }
      & StageLink_EvalStage_Fragment
    ) | (
      { __typename?: 'DesignStage' }
      & StageLink_DesignStage_Fragment
    ) | (
      { __typename?: 'PrintStage' }
      & StageLink_PrintStage_Fragment
    ) | (
      { __typename?: 'MoldStage' }
      & StageLink_MoldStage_Fragment
    )> }
  )> }
);

export type CreateOrdersMutationVariables = {
  payloads: Array<CreateOrderInput>;
};


export type CreateOrdersMutation = (
  { __typename?: 'Mutation' }
  & { createOrders?: Maybe<Array<(
    { __typename?: 'Order' }
    & { appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'startDate'>
    )> }
    & OrderFragment
  )>> }
);

export type RemoveOrderMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveOrderMutation = (
  { __typename?: 'Mutation' }
  & { removeOrder?: Maybe<(
    { __typename?: 'RemoveSystemMessage' }
    & Pick<RemoveSystemMessage, 'id' | 'message'>
  )> }
);

export type UpdateOrdersMutationVariables = {
  payloads: Array<BatchUpdateOrderInput>;
};


export type UpdateOrdersMutation = (
  { __typename?: 'Mutation' }
  & { updateOrders?: Maybe<Array<(
    { __typename?: 'Order' }
    & { appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'startDate'>
    )> }
    & OrderFragment
  )>> }
);

export type OrdersQueryQueryVariables = {
  query?: Maybe<OrdersQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type OrdersQueryQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<(
    { __typename?: 'PagedOrder' }
    & Pick<PagedOrder, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Order' }
      & { appointment?: Maybe<(
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id' | 'startDate'>
      )> }
      & OrderFragment
    )> }
  )> }
);

export type PatientInfoFullFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'status' | 'id' | 'name' | 'patientCode' | 'cabinetCode' | 'gender' | 'nextAppointmentDate' | 'chiefComplaint'>
  & { conversation: (
    { __typename?: 'PatientConversation' }
    & Pick<PatientConversation, 'id'>
  ), clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name' | 'specialContract'>
    & { technicians: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    )> }
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'phone'>
  ), customerService?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, treatArches: (
    { __typename?: 'TreatArches' }
    & Pick<TreatArches, 'upper' | 'lower'>
  ), note: (
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'design' | 'braces' | 'sales'>
  ), photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45WithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, leftInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, rightInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, upperJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, lowerJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, pano?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, ceph?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ), profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'cephAnalysis' | 'faceRatio' | 'occulusalMuscle' | 'hasGummySmile' | 'smileLine' | 'hasBuccalCorridor'>
    & { mandibularMidlineToFacialMidline: (
      { __typename?: 'Midline' }
      & Pick<Midline, 'offset' | 'instruction'>
    ), occulusalPlane: (
      { __typename?: 'OcculusalPlane' }
      & Pick<OcculusalPlane, 'occulusalPlaneStatus' | 'instruction'>
    ), estheticLine: (
      { __typename?: 'EstheticLine' }
      & Pick<EstheticLine, 'lipsEstheticLineRelation' | 'instruction'>
    ) }
  ), intraOral: (
    { __typename?: 'IntraOral' }
    & Pick<IntraOral, 'missingTeeth' | 'teethDontMove' | 'teethWillExtract' | 'temporomandibularJointDisordersTreatStatus' | 'periodontalDiseaseTreatStatus'>
    & { residualRoot: (
      { __typename?: 'ResidualRoot' }
      & Pick<ResidualRoot, 'hasResidualRoot' | 'instruction'>
    ), impactedTooth: (
      { __typename?: 'ImpactedTooth' }
      & Pick<ImpactedTooth, 'hasImpactedTooth' | 'instruction'>
    ), uneruptedWisdomTooth: (
      { __typename?: 'UneruptedWisdomTooth' }
      & Pick<UneruptedWisdomTooth, 'hasUneruptedWisdomTooth' | 'instruction'>
    ), maxillarMidlineToFacialMidline: (
      { __typename?: 'Midline' }
      & Pick<Midline, 'offset' | 'instruction'>
    ), mandibularMidlineToMaxillarMidline: (
      { __typename?: 'Midline' }
      & Pick<Midline, 'offset' | 'instruction'>
    ) }
  ), symptoms: (
    { __typename?: 'Symptoms' }
    & Pick<Symptoms, 'leftMolarRelationship' | 'rightMolarRelationship' | 'anteriorCrossbite' | 'anteriorCrowding' | 'bimaxillaryProtrusion' | 'deepBite' | 'highCanine' | 'openBite' | 'posteriorCrossbite' | 'posteriorCrowding' | 'vShapeArch'>
  ), doctorInstruction: (
    { __typename?: 'DoctorInstruction' }
    & Pick<DoctorInstruction, 'note' | 'ipr' | 'tads' | 'otherOrthdontalTools' | 'functionalAppliance'>
    & { maintainSpace: (
      { __typename?: 'MaintainSpace' }
      & Pick<MaintainSpace, 'isMaintainingSpace' | 'note'>
    ), changeProsthesisToTemp: (
      { __typename?: 'ChangeProsthesisToTemp' }
      & Pick<ChangeProsthesisToTemp, 'need' | 'instruction'>
    ), extractToothWhenNoSpace: (
      { __typename?: 'ExtractToothWhenNoSpace' }
      & Pick<ExtractToothWhenNoSpace, 'intention' | 'toothType'>
    ) }
  ) }
);

export type PatientInfoShortFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name' | 'nextAppointmentDate'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name' | 'specialContract'>
  ), doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ), technician?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  )>, sales: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name'>
  ), note: (
    { __typename?: 'PatientNote' }
    & Pick<PatientNote, 'sales'>
  ), photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ) }
);

export type CreatePatientMutationVariables = {
  payload: CreatePatientInput;
};


export type CreatePatientMutation = (
  { __typename?: 'Mutation' }
  & { createPatient: (
    { __typename?: 'Patient' }
    & PatientInfoFullFragment
  ) }
);

export type PatientQueryQueryVariables = {
  id: Scalars['ID'];
};


export type PatientQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & PatientInfoFullFragment
  )> }
);

export type PatientsQueryQueryVariables = {
  query?: Maybe<PatientsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type PatientsQueryQuery = (
  { __typename?: 'Query' }
  & { patients?: Maybe<(
    { __typename?: 'PagedPatient' }
    & Pick<PagedPatient, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Patient' }
      & PatientInfoShortFragment
    )> }
  )> }
);

export type ProductInfoFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'created' | 'updated' | 'serialNumber' | 'name' | 'price' | 'spec' | 'cost'>
);

export type CreateProductMutationVariables = {
  payload: CreateProductInput;
};


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & ProductInfoFragment
  ) }
);

export type RemoveProductMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveProductMutation = (
  { __typename?: 'Mutation' }
  & { removeProduct: (
    { __typename?: 'Product' }
    & ProductInfoFragment
  ) }
);

export type UpdateProductMutationVariables = {
  id: Scalars['ID'];
  payload: UpdateProductInput;
};


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & ProductInfoFragment
  ) }
);

export type ProductQueryQueryVariables = {
  id: Scalars['ID'];
};


export type ProductQueryQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & ProductInfoFragment
  )> }
);

export type ProductsQueryQueryVariables = {
  query?: Maybe<ProductsQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type ProductsQueryQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'PagedProduct' }
    & Pick<PagedProduct, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Product' }
      & ProductInfoFragment
    )> }
  )> }
);

export type ProductStatQueryQueryVariables = {
  query?: Maybe<ProductStatQuery>;
};


export type ProductStatQueryQuery = (
  { __typename?: 'Query' }
  & { productStat?: Maybe<Array<Maybe<(
    { __typename?: 'ProductStat' }
    & Pick<ProductStat, 'count'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & ProductInfoFragment
    )> }
  )>>> }
);

export type SchedulesQueryQueryVariables = {
  query?: Maybe<SchedulesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type SchedulesQueryQuery = (
  { __typename?: 'Query' }
  & { schedules?: Maybe<(
    { __typename?: 'PagedSchedule' }
    & Pick<PagedSchedule, 'total' | 'limit' | 'page'>
    & { docs: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'type' | 'startDate' | 'endDate' | 'description'>
    )> }
  )> }
);

export type RemoveStageMutationVariables = {
  id: Scalars['ID'];
};


export type RemoveStageMutation = (
  { __typename?: 'Mutation' }
  & { removeStage: (
    { __typename?: 'RemoveSystemMessage' }
    & Pick<RemoveSystemMessage, 'id' | 'message'>
  ) }
);

export type AnalysisSelectQueryQueryVariables = {
  query?: Maybe<EvalStagesQuery>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type AnalysisSelectQueryQuery = (
  { __typename?: 'Query' }
  & { evalStages: (
    { __typename?: 'PagedEvalStage' }
    & { docs: Array<(
      { __typename?: 'EvalStage' }
      & Pick<EvalStage, 'id' | 'serialNumber' | 'isBeforePrint'>
      & { analysis?: Maybe<(
        { __typename?: 'Analysis' }
        & Pick<Analysis, 'digiAnalysis'>
        & { steps?: Maybe<Array<(
          { __typename?: 'Step' }
          & Pick<Step, 'upperStep' | 'lowerStep' | 'attachment' | 'ipr' | 'button' | 'retrieve'>
        )>> }
      )> }
    )> }
  ) }
);

export type AnalysisPreviewAnalysisFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'shippingDate'>
  & { analysis?: Maybe<(
    { __typename?: 'Analysis' }
    & Pick<Analysis, 'digiAnalysis'>
  )> }
);

export type CheckPointListFragment = (
  { __typename?: 'EvalStage' }
  & { checkPoints: Array<(
    { __typename?: 'CheckPoint' }
    & PreviewCardFragment
    & CheckPointProgressFragment
  )> }
);

export type AnalysisPreviewPhotosFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace45WithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, sideFaceWithTeeth?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, frontInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, leftInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, rightInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, upperJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, lowerJawInside?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, pano?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )>, ceph?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ) }
);

export type AnalysisPreviewProfileFragment = (
  { __typename?: 'EvalStage' }
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name' | 'chiefComplaint'>
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ), doctor: (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ), photos: (
      { __typename?: 'Photos' }
      & { frontFace?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
      )> }
    ) }
  ) }
  & StageLink_EvalStage_Fragment
);

export type StepListFragment = (
  { __typename?: 'EvalStage' }
  & { analysis?: Maybe<(
    { __typename?: 'Analysis' }
    & { steps?: Maybe<Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'upperStep' | 'lowerStep' | 'attachment' | 'ipr' | 'button' | 'retrieve'>
    )>> }
  )> }
);

export type VersionListFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'isCPMode' | 'shippingDate'>
  & StageName_EvalStage_Fragment
);

export type AnalysisPreviewFragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'isCPMode' | 'shouldShowCheckPoint'>
  & { patient: (
    { __typename?: 'Patient' }
    & AnalysisPreviewPhotosFragment
  ) }
  & AnalysisPreviewProfileFragment
  & AnalysisPreviewAnalysisFragment
  & StepListFragment
  & CheckPointListFragment
);

export type CheckPointProgressFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'serialNumber' | 'goalStep' | 'isCompleted' | 'isFinal'>
);

export type PreviewCardFragment = (
  { __typename?: 'CheckPoint' }
  & Pick<CheckPoint, 'serialNumber' | 'goalStep' | 'upper' | 'lower' | 'note' | 'isFinal' | 'instruction'>
);

export type ViewerHeaderFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status' | 'name'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  )>, evalStages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'shippingDate' | 'status'>
      & StageName_AccessoryStage_Fragment
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'hasFilesForViewer' | 'id' | 'shippingDate' | 'status'>
      & StageName_EvalStage_Fragment
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'id' | 'shippingDate' | 'status'>
      & StageName_DesignStage_Fragment
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'shippingDate' | 'status'>
      & StageName_PrintStage_Fragment
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'id' | 'shippingDate' | 'status'>
      & StageName_MoldStage_Fragment
    )> }
  )> }
  & TablePatientInfoFragment
);

export type SidebarFragment = (
  { __typename?: 'EvalStage' }
  & { stepsOverview?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'path'>
  )> }
);

export type DentalModelViewerFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'status'>
  & { currentEvalStage?: Maybe<(
    { __typename?: 'EvalStage' }
    & Pick<EvalStage, 'id'>
  )>, stages?: Maybe<(
    { __typename?: 'PagedStage' }
    & { docs: Array<(
      { __typename: 'AccessoryStage' }
      & Pick<AccessoryStage, 'id' | 'type' | 'status' | 'shippingDate'>
    ) | (
      { __typename: 'EvalStage' }
      & Pick<EvalStage, 'serialNumber' | 'id' | 'type' | 'status' | 'shippingDate'>
      & { checkPoints: Array<(
        { __typename?: 'CheckPoint' }
        & Pick<CheckPoint, 'goalStep' | 'serialNumber' | 'upper' | 'lower' | 'isFinal' | 'isCompleted'>
        & { files?: Maybe<(
          { __typename?: 'EvalStageFiles' }
          & { teeth?: Maybe<Array<(
            { __typename?: 'ToothFile' }
            & Pick<ToothFile, 'position'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'path'>
            ) }
          )>>, upperJawModel?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          )>, lowerJawModel?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          )> }
        )> }
      )> }
      & SidebarFragment
    ) | (
      { __typename: 'DesignStage' }
      & Pick<DesignStage, 'serialNumber' | 'id' | 'type' | 'status' | 'shippingDate'>
      & { step: (
        { __typename?: 'Step' }
        & Pick<Step, 'upperStep' | 'lowerStep'>
      ), instructionCard?: Maybe<(
        { __typename?: 'InstructionCard' }
        & InstructionCardPreviewFragment
      )>, files?: Maybe<(
        { __typename?: 'DesignStageFiles' }
        & { teeth?: Maybe<Array<(
          { __typename?: 'ToothFile' }
          & Pick<ToothFile, 'position'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          ) }
        )>>, upperJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )>, lowerJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )> }
      )> }
    ) | (
      { __typename: 'PrintStage' }
      & Pick<PrintStage, 'id' | 'type' | 'status' | 'shippingDate'>
    ) | (
      { __typename: 'MoldStage' }
      & Pick<MoldStage, 'serialNumber' | 'id' | 'type' | 'status' | 'shippingDate'>
      & { files?: Maybe<(
        { __typename?: 'MoldStageFiles' }
        & { teeth?: Maybe<Array<(
          { __typename?: 'ToothFile' }
          & Pick<ToothFile, 'position'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'path'>
          ) }
        )>>, upperJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )>, lowerJawModel?: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'path'>
        )> }
      )> }
    )> }
  )> }
  & ViewerHeaderFragment
);

export type UploadFileMutationVariables = {
  file: Scalars['Upload'];
  options: FileUploadOption;
};


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
  ) }
);

export type FileInfoFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
);

export type EmptyInstructionCardFragment = (
  { __typename?: 'InstructionCard' }
  & { patient: (
    { __typename?: 'Patient' }
    & PatientLinkFragment
  ), stage: (
    { __typename?: 'AccessoryStage' }
    & StageLink_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageLink_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageLink_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & StageLink_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageLink_MoldStage_Fragment
  ) }
);

export type InstructionCardPreviewFragment = (
  { __typename?: 'InstructionCard' }
  & Pick<InstructionCard, 'stepCode' | 'retrieve' | 'instruction'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
  & EmptyInstructionCardFragment
  & InstructionTeethInfoFragment
);

export type InstructionPopoverFragment = (
  { __typename?: 'InstructionCard' }
  & Pick<InstructionCard, 'stepCode' | 'instruction'>
  & { stage: (
    { __typename?: 'AccessoryStage' }
    & StageName_AccessoryStage_Fragment
  ) | (
    { __typename?: 'EvalStage' }
    & StageName_EvalStage_Fragment
  ) | (
    { __typename?: 'DesignStage' }
    & StageName_DesignStage_Fragment
  ) | (
    { __typename?: 'PrintStage' }
    & StageName_PrintStage_Fragment
  ) | (
    { __typename?: 'MoldStage' }
    & StageName_MoldStage_Fragment
  ) }
  & InstructionCardPreviewFragment
);

export type InstructionTeethInfoFragment = (
  { __typename?: 'InstructionCard' }
  & { teethStatus: Array<(
    { __typename?: 'ToothStatus' }
    & Pick<ToothStatus, 'position' | 'type'>
  )>, auxiliaries: Array<(
    { __typename?: 'Auxiliary' }
    & Pick<Auxiliary, 'value' | 'type' | 'toothPosition' | 'auxiliaryPosition'>
  )> }
);

export type ClinicLinkFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name'>
);

export type DoctorLinkFragment = (
  { __typename?: 'Doctor' }
  & Pick<Doctor, 'id' | 'name' | 'level'>
);

export type PatientLinkFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name'>
);

export type PatientWithDoctorLinkFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id'>
  & { doctor: (
    { __typename?: 'Doctor' }
    & Pick<Doctor, 'id' | 'name'>
  ) }
  & PatientLinkFragment
);

type StageLink_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_AccessoryStage_Fragment
);

type StageLink_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_EvalStage_Fragment
);

type StageLink_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_DesignStage_Fragment
);

type StageLink_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_PrintStage_Fragment
);

type StageLink_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'id' | 'type'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
  & StageName_MoldStage_Fragment
);

export type StageLinkFragment = StageLink_AccessoryStage_Fragment | StageLink_EvalStage_Fragment | StageLink_DesignStage_Fragment | StageLink_PrintStage_Fragment | StageLink_MoldStage_Fragment;

export type MessageInboxItemFragment = (
  { __typename?: 'ConversationsWithLatestMessageNode' }
  & Pick<ConversationsWithLatestMessageNode, 'id' | 'numberOfUnreadMessages'>
  & { latestMessage: (
    { __typename?: 'TextMessage' }
    & Pick<TextMessage, 'content' | 'id' | 'type' | 'created'>
    & { conversation: (
      { __typename?: 'PatientConversation' }
      & Pick<PatientConversation, 'id'>
    ), creator: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'role' | 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ImageMessage' }
    & Pick<ImageMessage, 'id' | 'type' | 'created'>
    & { conversation: (
      { __typename?: 'PatientConversation' }
      & Pick<PatientConversation, 'id'>
    ), creator: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'name'>
    ) | (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) | (
      { __typename?: 'Doctor' }
      & Pick<Doctor, 'id' | 'name'>
    ) | (
      { __typename?: 'Employee' }
      & Pick<Employee, 'role' | 'id' | 'name'>
    ) }
  ), patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name'>
  ) }
);

export type MessageInboxFragment = (
  { __typename?: 'ConversationsWithLatestMessageConnection' }
  & { edges: Array<(
    { __typename?: 'ConversationsWithLatestMessageEdge' }
    & Pick<ConversationsWithLatestMessageEdge, 'cursor'>
    & { node: (
      { __typename?: 'ConversationsWithLatestMessageNode' }
      & MessageInboxItemFragment
    ) }
  )> }
);

type NotificationItem_AppointmentNotification_Fragment = (
  { __typename?: 'AppointmentNotification' }
  & Pick<AppointmentNotification, 'id' | 'type' | 'read' | 'created' | 'content'>
);

type NotificationItem_NormalNotification_Fragment = (
  { __typename?: 'NormalNotification' }
  & Pick<NormalNotification, 'id' | 'type' | 'read' | 'created' | 'content'>
);

export type NotificationItemFragment = NotificationItem_AppointmentNotification_Fragment | NotificationItem_NormalNotification_Fragment;

export type NotificationSettingFormFragment = (
  { __typename?: 'NotificationSetting' }
  & Pick<NotificationSetting, 'site' | 'mail' | 'line'>
);

type StageName_AccessoryStage_Fragment = (
  { __typename?: 'AccessoryStage' }
  & Pick<AccessoryStage, 'accessoryCode' | 'id' | 'type'>
);

type StageName_EvalStage_Fragment = (
  { __typename?: 'EvalStage' }
  & Pick<EvalStage, 'serialNumber' | 'isBeforePrint' | 'id' | 'type'>
);

type StageName_DesignStage_Fragment = (
  { __typename?: 'DesignStage' }
  & Pick<DesignStage, 'serialNumber' | 'id' | 'type'>
);

type StageName_PrintStage_Fragment = (
  { __typename?: 'PrintStage' }
  & Pick<PrintStage, 'serialNumber' | 'id' | 'type'>
);

type StageName_MoldStage_Fragment = (
  { __typename?: 'MoldStage' }
  & Pick<MoldStage, 'serialNumber' | 'id' | 'type'>
);

export type StageNameFragment = StageName_AccessoryStage_Fragment | StageName_EvalStage_Fragment | StageName_DesignStage_Fragment | StageName_PrintStage_Fragment | StageName_MoldStage_Fragment;

export type ImageCardFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'path' | 'thumbnailPath'>
);

export type TablePatientInfoFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'gender'>
  & { photos: (
    { __typename?: 'Photos' }
    & { frontFace?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'filename' | 'path' | 'thumbnailPath'>
    )> }
  ), clinic: (
    { __typename?: 'Clinic' }
    & ClinicLinkFragment
  ), doctor: (
    { __typename?: 'Doctor' }
    & DoctorLinkFragment
  ) }
  & PatientLinkFragment
);

type DiscriminateUnion<T, U> = T extends U ? T : never;

type RequireField<T, TNames extends string> = T & { [P in TNames]: (T & { [name: string]: never })[P] };

export type StageCapacityQueryVariables = StageCapacityQueryQueryVariables;
export type StageCapacityQueryStageCapacity = (NonNullable<StageCapacityQueryQuery['stageCapacity'][0]>);
export type StageCapacityQuerySystem = StageCapacityQueryQuery['system'];
export type StageCapacityQueryStageCapacitySystemInlineFragment = (DiscriminateUnion<RequireField<StageCapacityQueryQuery['system'], '__typename'>, { __typename: 'StageCapacitySystem' }>);
export type StageCapacityQuery_StageCapacity = (DiscriminateUnion<RequireField<StageCapacityQueryQuery['system'], '__typename'>, { __typename: 'StageCapacitySystem' }>)['stageCapacity'];
export type StageCapacityQueryCapacityPerType = (NonNullable<(DiscriminateUnion<RequireField<StageCapacityQueryQuery['system'], '__typename'>, { __typename: 'StageCapacitySystem' }>)['stageCapacity']['capacityPerType'][0]>);
export type InstructionTagListAuxiliaries = (NonNullable<InstructionTagListFragment['auxiliaries'][0]>);
export type MessageInboxQueryVariables = MessageInboxQueryQueryVariables;
export type MessageInboxQueryConversationsWithLatestMessage = MessageInboxQueryQuery['conversationsWithLatestMessage'];
export type NotificationIconVariables = NotificationIconQueryVariables;
export type NotificationIconNotifications = (NonNullable<NotificationIconQuery['notifications']>);
export type NotificationIconEdges = (NonNullable<(NonNullable<NotificationIconQuery['notifications']>)['edges'][0]>);
export type NotificationIconNode = (NonNullable<(NonNullable<NotificationIconQuery['notifications']>)['edges'][0]>)['node'];
export type UnreadNotificationCountVariables = UnreadNotificationCountQueryVariables;
export type ReadNotificationMutationVariables = ReadNotificationMutationMutationVariables;
export type ReadNotificationMutationReadNotification = ReadNotificationMutationMutation['readNotification'];
export type ReadAllNotificationMutationVariables = ReadAllNotificationMutationMutationVariables;
export type ReadAllNotificationMutationReadAllNotification = ReadAllNotificationMutationMutation['readAllNotification'];
export type NotificationCreatedVariables = NotificationCreatedSubscriptionVariables;
export type NotificationCreatedNotificationCreated = (NonNullable<NotificationCreatedSubscription['notificationCreated']>);
export type UploadAnalysisImageVariables = UploadAnalysisImageMutationVariables;
export type UploadAnalysisImageUploadFile = UploadAnalysisImageMutation['uploadFile'];
export type ViewerFilesOverviewPatient = ViewerFilesOverviewFragment['patient'];
export type ViewerFilesOverviewMoldStageInlineFragment = (DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type ViewerFilesOverviewFiles = (NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'MoldStage' }>)['files']>);
export type ViewerFilesOverviewUpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'MoldStage' }>)['files']>)['upperJawModel']>);
export type ViewerFilesOverviewLowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'MoldStage' }>)['files']>)['lowerJawModel']>);
export type ViewerFilesOverviewTeeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'MoldStage' }>)['files']>)['teeth']>)[0]>);
export type ViewerFilesOverviewFile = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'MoldStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type ViewerFilesOverviewEvalStageInlineFragment = (DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type ViewerFilesOverviewMoldStage = (NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>)['moldStage']>);
export type ViewerFilesOverview_Files = (NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>)['files']>);
export type ViewerFilesOverview_UpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>)['files']>)['upperJawModel']>);
export type ViewerFilesOverview_LowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>)['files']>)['lowerJawModel']>);
export type ViewerFilesOverview_Teeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>)['files']>)['teeth']>)[0]>);
export type ViewerFilesOverview_File = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'EvalStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type ViewerFilesOverviewDesignStageInlineFragment = (DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type ViewerFilesOverviewEvalStage = (DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>)['evalStage'];
export type ViewerFilesOverview__Files = (NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>)['files']>);
export type ViewerFilesOverview__UpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>)['files']>)['upperJawModel']>);
export type ViewerFilesOverview__LowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>)['files']>)['lowerJawModel']>);
export type ViewerFilesOverview__Teeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>)['files']>)['teeth']>)[0]>);
export type ViewerFilesOverview__File = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<ViewerFilesOverviewFragment, '__typename'>, { __typename: 'DesignStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type CreateMessageMutationVariables = CreateMessageMutationMutationVariables;
export type CreateMessageMutationCreateMessage = CreateMessageMutationMutation['createMessage'];
export type RemoveMessageMutationVariables = RemoveMessageMutationMutationVariables;
export type RemoveMessageMutationRemoveMessage = RemoveMessageMutationMutation['removeMessage'];
export type PatientConversationConversation = PatientConversationFragment['conversation'];
export type MemberSelectQueryVariables = MemberSelectQueryQueryVariables;
export type MemberSelectQueryConversationMemberOption = (NonNullable<MemberSelectQueryQuery['conversationMemberOption'][0]>);
export type UpdateMembersMutationVariables = UpdateMembersMutationMutationVariables;
export type UpdateMembersMutationUpdateConversationMembers = UpdateMembersMutationMutation['updateConversationMembers'];
export type ConversationMemberInfoEmployeeInlineFragment = (DiscriminateUnion<RequireField<ConversationMemberInfoFragment, '__typename'>, { __typename: 'Employee' }>);
export type ConversationInfoMembers = (NonNullable<ConversationInfoFragment['members'][0]>);
export type ConversationInfoPatientConversationInlineFragment = (DiscriminateUnion<RequireField<ConversationInfoFragment, '__typename'>, { __typename: 'PatientConversation' }>);
export type ConversationInfoPatient = (DiscriminateUnion<RequireField<ConversationInfoFragment, '__typename'>, { __typename: 'PatientConversation' }>)['patient'];
export type MessageInfoConversation = MessageInfoFragment['conversation'];
export type MessageInfoCreator = MessageInfoFragment['creator'];
export type MessageInfoEmployeeInlineFragment = (DiscriminateUnion<RequireField<MessageInfoFragment['creator'], '__typename'>, { __typename: 'Employee' }>);
export type MessageInfoTextMessageInlineFragment = (DiscriminateUnion<RequireField<MessageInfoFragment, '__typename'>, { __typename: 'TextMessage' }>);
export type MessageInfoImageMessageInlineFragment = (DiscriminateUnion<RequireField<MessageInfoFragment, '__typename'>, { __typename: 'ImageMessage' }>);
export type MessageInfoImage = (DiscriminateUnion<RequireField<MessageInfoFragment, '__typename'>, { __typename: 'ImageMessage' }>)['image'];
export type InvoiceTimeLineVariables = InvoiceTimeLineQueryVariables;
export type InvoiceTimeLinePatient = (NonNullable<InvoiceTimeLineQuery['patient']>);
export type InvoiceTimeLineInvoices = (NonNullable<InvoiceTimeLineQuery['patient']>)['invoices'];
export type InvoiceTimeLineDocs = (NonNullable<(NonNullable<InvoiceTimeLineQuery['patient']>)['invoices']['docs'][0]>);
export type InvoiceTimeLineInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<InvoiceTimeLineQuery['patient']>)['invoices']['docs'][0]>), '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type InvoiceTimeLineStage = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<InvoiceTimeLineQuery['patient']>)['invoices']['docs'][0]>), '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'];
export type StageInvoiceLinkPatient = StageInvoiceLinkFragment['patient'];
export type ClinicDoctorSelectVariables = ClinicDoctorSelectQueryVariables;
export type ClinicDoctorSelectDoctors = ClinicDoctorSelectQuery['doctors'];
export type ClinicDoctorSelectDocs = (NonNullable<ClinicDoctorSelectQuery['doctors']['docs'][0]>);
export type ClinicInClinicSelectAccountManagers = (NonNullable<ClinicInClinicSelectFragment['accountManagers'][0]>);
export type ClinicInClinicSelectTechnicians = (NonNullable<ClinicInClinicSelectFragment['technicians'][0]>);
export type ClinicInClinicSelectSalesList = (NonNullable<ClinicInClinicSelectFragment['salesList'][0]>);
export type ClinicInClinicSelectCustomerService = (NonNullable<ClinicInClinicSelectFragment['customerService']>);
export type ClinicSelectVariables = ClinicSelectQueryVariables;
export type ClinicSelectClinics = ClinicSelectQuery['clinics'];
export type ClinicSelectDocs = (NonNullable<ClinicSelectQuery['clinics']['docs'][0]>);
export type DoctorSelectVariables = DoctorSelectQueryVariables;
export type DoctorSelectDoctors = DoctorSelectQuery['doctors'];
export type DoctorSelectDocs = (NonNullable<DoctorSelectQuery['doctors']['docs'][0]>);
export type EmployeeSelectVariables = EmployeeSelectQueryVariables;
export type EmployeeSelectEmployees = EmployeeSelectQuery['employees'];
export type EmployeeSelectDocs = (NonNullable<EmployeeSelectQuery['employees']['docs'][0]>);
export type EntitySelectClinicInlineFragment = (DiscriminateUnion<RequireField<EntitySelectFragment, '__typename'>, { __typename: 'Clinic' }>);
export type EntitySelectDoctorInlineFragment = (DiscriminateUnion<RequireField<EntitySelectFragment, '__typename'>, { __typename: 'Doctor' }>);
export type EntitySelectEmployeeInlineFragment = (DiscriminateUnion<RequireField<EntitySelectFragment, '__typename'>, { __typename: 'Employee' }>);
export type EntitySelectPatientInlineFragment = (DiscriminateUnion<RequireField<EntitySelectFragment, '__typename'>, { __typename: 'Patient' }>);
export type PatientInPatientSelectClinic = PatientInPatientSelectFragment['clinic'];
export type PatientInPatientSelectSales = PatientInPatientSelectFragment['sales'];
export type PatientSelectVariables = PatientSelectQueryVariables;
export type PatientSelectPatients = (NonNullable<PatientSelectQuery['patients']>);
export type PatientSelectDocs = (NonNullable<(NonNullable<PatientSelectQuery['patients']>)['docs'][0]>);
export type PatientStageSelectVariables = PatientStageSelectQueryVariables;
export type PatientStageSelectStages = PatientStageSelectQuery['stages'];
export type PatientStageSelectDocs = (NonNullable<PatientStageSelectQuery['stages']['docs'][0]>);
export type StageSelectVariables = StageSelectQueryVariables;
export type StageSelectStages = StageSelectQuery['stages'];
export type StageSelectDocs = (NonNullable<StageSelectQuery['stages']['docs'][0]>);
export type AccountFormInfoEntity = (NonNullable<AccountFormInfoFragment['entity']>);
export type AccountFormInfoProviders = (NonNullable<AccountFormInfoFragment['providers']>);
export type AccountFormInfoFacebook = (NonNullable<(NonNullable<AccountFormInfoFragment['providers']>)['facebook']>);
export type AccountFormInfoGoogle = (NonNullable<(NonNullable<AccountFormInfoFragment['providers']>)['google']>);
export type AccountFormInfoLine = (NonNullable<(NonNullable<AccountFormInfoFragment['providers']>)['line']>);
export type AccountFormInfoWechat = (NonNullable<(NonNullable<AccountFormInfoFragment['providers']>)['wechat']>);
export type AppointmentPatient = AppointmentFragment['patient'];
export type AppointmentClinic = AppointmentFragment['patient']['clinic'];
export type AppointmentDoctor = AppointmentFragment['patient']['doctor'];
export type AppointmentCreator = AppointmentFragment['creator'];
export type ClinicFormLocation = ClinicFormFragment['location'];
export type ClinicFormDoctors = (NonNullable<ClinicFormFragment['doctors'][0]>);
export type ClinicFormAccountManagers = (NonNullable<ClinicFormFragment['accountManagers'][0]>);
export type ClinicFormTechnicians = (NonNullable<ClinicFormFragment['technicians'][0]>);
export type ClinicFormSales = (NonNullable<ClinicFormFragment['sales'][0]>);
export type ClinicFormCustomerService = (NonNullable<ClinicFormFragment['customerService']>);
export type EmployeeFormInfoApprovers = (NonNullable<EmployeeFormInfoFragment['approvers'][0]>);
export type InvoiceClientInfoInvoiceItemClinic = InvoiceClientInfoInvoiceItemFragment['clinic'];
export type InvoiceClientInfoInvoiceItemDoctor = InvoiceClientInfoInvoiceItemFragment['doctor'];
export type InvoiceClientInfoInvoiceItemSales = InvoiceClientInfoInvoiceItemFragment['sales'];
export type FormInvoiceItemProducts = (NonNullable<FormInvoiceItemFragment['products'][0]>);
export type FormInvoiceItemInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<FormInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type FormInvoiceItemStage = (DiscriminateUnion<RequireField<FormInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'];
export type FormInvoiceItemPatient = (DiscriminateUnion<RequireField<FormInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient'];
export type InvoiceMailStageItemMoldStageInlineFragment = (DiscriminateUnion<RequireField<InvoiceMailStageItemFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type InvoiceMailStageItemEvalStageInlineFragment = (DiscriminateUnion<RequireField<InvoiceMailStageItemFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type InvoiceMailStageItemDesignStageInlineFragment = (DiscriminateUnion<RequireField<InvoiceMailStageItemFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type InvoiceMailStageItemPrintStageInlineFragment = (DiscriminateUnion<RequireField<InvoiceMailStageItemFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type InvoiceMailStageItemAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<InvoiceMailStageItemFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type InvoiceMailInvoiceItemClinic = InvoiceMailInvoiceItemFragment['clinic'];
export type InvoiceMailInvoiceItemDoctor = InvoiceMailInvoiceItemFragment['doctor'];
export type InvoiceMailInvoiceItemSales = InvoiceMailInvoiceItemFragment['sales'];
export type EmployeeLeaveFormInfoLeaveStatus = EmployeeLeaveFormInfoFragment['leaveStatus'];
export type LeaveFormInfoEmployee = LeaveFormInfoFragment['employee'];
export type FormPatientBasicCreator = (NonNullable<FormPatientBasicFragment['creator']>);
export type FormPatientBasicClinic = FormPatientBasicFragment['clinic'];
export type FormPatientBasicAccountManagers = (NonNullable<FormPatientBasicFragment['clinic']['accountManagers'][0]>);
export type FormPatientBasicTechnicians = (NonNullable<FormPatientBasicFragment['clinic']['technicians'][0]>);
export type FormPatientBasicSales = (NonNullable<FormPatientBasicFragment['clinic']['sales'][0]>);
export type FormPatientBasicCustomerService = (NonNullable<FormPatientBasicFragment['clinic']['customerService']>);
export type FormPatientBasicDoctor = FormPatientBasicFragment['doctor'];
export type FormPatientBasicAccountManager = (NonNullable<FormPatientBasicFragment['accountManager']>);
export type FormPatientBasicTechnician = (NonNullable<FormPatientBasicFragment['technician']>);
export type FormPatientBasic_Sales = FormPatientBasicFragment['sales'];
export type FormPatientBasic_CustomerService = (NonNullable<FormPatientBasicFragment['customerService']>);
export type FormPatientBasicTreatArches = FormPatientBasicFragment['treatArches'];
export type FormPatientBasicNote = FormPatientBasicFragment['note'];
export type FormPatientDoctorInstructionDoctorInstruction = FormPatientDoctorInstructionFragment['doctorInstruction'];
export type FormPatientDoctorInstructionMaintainSpace = FormPatientDoctorInstructionFragment['doctorInstruction']['maintainSpace'];
export type FormPatientDoctorInstructionChangeProsthesisToTemp = FormPatientDoctorInstructionFragment['doctorInstruction']['changeProsthesisToTemp'];
export type FormPatientDoctorInstructionExtractToothWhenNoSpace = FormPatientDoctorInstructionFragment['doctorInstruction']['extractToothWhenNoSpace'];
export type FormPatientIntraOralIntraOral = FormPatientIntraOralFragment['intraOral'];
export type FormPatientIntraOralTeethStatus = (NonNullable<FormPatientIntraOralFragment['intraOral']['teethStatus'][0]>);
export type FormPatientIntraOralResidualRoot = FormPatientIntraOralFragment['intraOral']['residualRoot'];
export type FormPatientIntraOralImpactedTooth = FormPatientIntraOralFragment['intraOral']['impactedTooth'];
export type FormPatientIntraOralUneruptedWisdomTooth = FormPatientIntraOralFragment['intraOral']['uneruptedWisdomTooth'];
export type FormPatientIntraOralMaxillarMidlineToFacialMidline = FormPatientIntraOralFragment['intraOral']['maxillarMidlineToFacialMidline'];
export type FormPatientIntraOralMandibularMidlineToMaxillarMidline = FormPatientIntraOralFragment['intraOral']['mandibularMidlineToMaxillarMidline'];
export type PaymentPayment = PaymentFragment['payment'];
export type PatientPhotoUploadPhotos = PatientPhotoUploadFragment['photos'];
export type PatientPhotoUploadFrontFace = (NonNullable<PatientPhotoUploadFragment['photos']['frontFace']>);
export type PatientPhotoUploadFrontFaceWithTeeth = (NonNullable<PatientPhotoUploadFragment['photos']['frontFaceWithTeeth']>);
export type PatientPhotoUploadSideFace45 = (NonNullable<PatientPhotoUploadFragment['photos']['sideFace45']>);
export type PatientPhotoUploadSideFace45WithTeeth = (NonNullable<PatientPhotoUploadFragment['photos']['sideFace45WithTeeth']>);
export type PatientPhotoUploadSideFace = (NonNullable<PatientPhotoUploadFragment['photos']['sideFace']>);
export type PatientPhotoUploadSideFaceWithTeeth = (NonNullable<PatientPhotoUploadFragment['photos']['sideFaceWithTeeth']>);
export type PatientPhotoUploadFrontInside = (NonNullable<PatientPhotoUploadFragment['photos']['frontInside']>);
export type PatientPhotoUploadLeftInside = (NonNullable<PatientPhotoUploadFragment['photos']['leftInside']>);
export type PatientPhotoUploadRightInside = (NonNullable<PatientPhotoUploadFragment['photos']['rightInside']>);
export type PatientPhotoUploadUpperJawInside = (NonNullable<PatientPhotoUploadFragment['photos']['upperJawInside']>);
export type PatientPhotoUploadLowerJawInside = (NonNullable<PatientPhotoUploadFragment['photos']['lowerJawInside']>);
export type PatientPhotoUploadPano = (NonNullable<PatientPhotoUploadFragment['photos']['pano']>);
export type PatientPhotoUploadCeph = (NonNullable<PatientPhotoUploadFragment['photos']['ceph']>);
export type FormPatientProfileProfile = FormPatientProfileFragment['profile'];
export type FormPatientProfileMandibularMidlineToFacialMidline = FormPatientProfileFragment['profile']['mandibularMidlineToFacialMidline'];
export type FormPatientProfileOcculusalPlane = FormPatientProfileFragment['profile']['occulusalPlane'];
export type FormPatientProfileEstheticLine = FormPatientProfileFragment['profile']['estheticLine'];
export type FormPatientSymptomsSymptoms = FormPatientSymptomsFragment['symptoms'];
export type PhotoContainerPhotos = PhotoContainerFragment['photos'];
export type PhotoContainerFrontFace = (NonNullable<PhotoContainerFragment['photos']['frontFace']>);
export type PhotoContainerFrontFaceWithTeeth = (NonNullable<PhotoContainerFragment['photos']['frontFaceWithTeeth']>);
export type PhotoContainerSideFace45 = (NonNullable<PhotoContainerFragment['photos']['sideFace45']>);
export type PhotoContainerSideFace45WithTeeth = (NonNullable<PhotoContainerFragment['photos']['sideFace45WithTeeth']>);
export type PhotoContainerSideFace = (NonNullable<PhotoContainerFragment['photos']['sideFace']>);
export type PhotoContainerSideFaceWithTeeth = (NonNullable<PhotoContainerFragment['photos']['sideFaceWithTeeth']>);
export type PhotoContainerFrontInside = (NonNullable<PhotoContainerFragment['photos']['frontInside']>);
export type PhotoContainerLeftInside = (NonNullable<PhotoContainerFragment['photos']['leftInside']>);
export type PhotoContainerRightInside = (NonNullable<PhotoContainerFragment['photos']['rightInside']>);
export type PhotoContainerUpperJawInside = (NonNullable<PhotoContainerFragment['photos']['upperJawInside']>);
export type PhotoContainerLowerJawInside = (NonNullable<PhotoContainerFragment['photos']['lowerJawInside']>);
export type PhotoContainerPano = (NonNullable<PhotoContainerFragment['photos']['pano']>);
export type PhotoContainerCeph = (NonNullable<PhotoContainerFragment['photos']['ceph']>);
export type AnalysisSectionFormPatient = AnalysisSectionFormFragment['patient'];
export type AnalysisSectionFormCurrentEvalStage = (NonNullable<AnalysisSectionFormFragment['patient']['currentEvalStage']>);
export type AnalysisSectionFormStep = AnalysisSectionFormFragment['step'];
export type AnalysisSectionFormCheckPoint = (NonNullable<AnalysisSectionFormFragment['checkPoint']>);
export type AnalysisSectionFormInstructionCard = (NonNullable<AnalysisSectionFormFragment['instructionCard']>);
export type FormAnalysisDesignStageInlineFragment = (DiscriminateUnion<RequireField<FormAnalysisFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type FormAnalysisPrintStageInlineFragment = (DiscriminateUnion<RequireField<FormAnalysisFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type FormAnalysisDesignStage = (NonNullable<(DiscriminateUnion<RequireField<FormAnalysisFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type FormAnalysisAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<FormAnalysisFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type FormAnalysisInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<FormAnalysisFragment, '__typename'>, { __typename: 'AccessoryStage' }>)['instructionCard']>);
export type FormBasicPatient = FormBasicFragment['patient'];
export type FormBasicEvalStageInlineFragment = (DiscriminateUnion<RequireField<FormBasicFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type FormBasicMoldStage = (NonNullable<(DiscriminateUnion<RequireField<FormBasicFragment, '__typename'>, { __typename: 'EvalStage' }>)['moldStage']>);
export type FormBasicDesignStageInlineFragment = (DiscriminateUnion<RequireField<FormBasicFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type FormBasicEvalStage = (DiscriminateUnion<RequireField<FormBasicFragment, '__typename'>, { __typename: 'DesignStage' }>)['evalStage'];
export type FormBasicPrintStageInlineFragment = (DiscriminateUnion<RequireField<FormBasicFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type FormBasicDesignStage = (NonNullable<(DiscriminateUnion<RequireField<FormBasicFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type FormRpFilesUpperRp = (NonNullable<FormRpFilesFragment['upperRP']>);
export type FormRpFilesLowerRp = (NonNullable<FormRpFilesFragment['lowerRP']>);
export type FormFilePatient = FormFileFragment['patient'];
export type FormFileMoldStageInlineFragment = (DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type FormFileFiles = (NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'MoldStage' }>)['files']>);
export type FormFileEvalStageInlineFragment = (DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type FormFileMoldStage = (NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'EvalStage' }>)['moldStage']>);
export type FormFile_Files = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'EvalStage' }>)['moldStage']>)['files']>);
export type FormFileDesignStageInlineFragment = (DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type FormFile__Files = (NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'DesignStage' }>)['files']>);
export type FormFileEvalStage = (DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'DesignStage' }>)['evalStage'];
export type FormFile_MoldStage = (NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'DesignStage' }>)['evalStage']['moldStage']>);
export type FormFile___Files = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'DesignStage' }>)['evalStage']['moldStage']>)['files']>);
export type FormFilePrintStageInlineFragment = (DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type FormFileDesignStage = (NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type FormFile____Files = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['files']>);
export type FormFile_EvalStage = (NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['evalStage'];
export type FormFile__MoldStage = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['evalStage']['moldStage']>);
export type FormFile_____Files = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<FormFileFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['evalStage']['moldStage']>)['files']>);
export type FinalCheckPointCheckPoints = (NonNullable<FinalCheckPointFragment['checkPoints'][0]>);
export type FinalCheckPointFiles = (NonNullable<(NonNullable<FinalCheckPointFragment['checkPoints'][0]>)['files']>);
export type ProductSectionFormDesignStageInlineFragment = (DiscriminateUnion<RequireField<ProductSectionFormFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type ProductSectionFormAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<ProductSectionFormFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type FormProductDesignStageInlineFragment = (DiscriminateUnion<RequireField<FormProductFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type FormProductPrintStageInlineFragment = (DiscriminateUnion<RequireField<FormProductFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type FormProductDesignStage = (NonNullable<(DiscriminateUnion<RequireField<FormProductFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type FormProductAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<FormProductFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type RemoveViewerFileVariables = RemoveViewerFileMutationVariables;
export type RemoveViewerFileUpdateViewerFiles = RemoveViewerFileMutation['updateViewerFiles'];
export type MoldStageViewerFilesTeeth = (NonNullable<(NonNullable<MoldStageViewerFilesFragment['teeth']>)[0]>);
export type MoldStageViewerFilesFile = (NonNullable<(NonNullable<MoldStageViewerFilesFragment['teeth']>)[0]>)['file'];
export type MoldStageViewerFilesUpperJawModel = (NonNullable<MoldStageViewerFilesFragment['upperJawModel']>);
export type MoldStageViewerFilesLowerJawModel = (NonNullable<MoldStageViewerFilesFragment['lowerJawModel']>);
export type EvalStageViewerFilesTeeth = (NonNullable<(NonNullable<EvalStageViewerFilesFragment['teeth']>)[0]>);
export type EvalStageViewerFilesFile = (NonNullable<(NonNullable<EvalStageViewerFilesFragment['teeth']>)[0]>)['file'];
export type EvalStageViewerFilesUpperJawModel = (NonNullable<EvalStageViewerFilesFragment['upperJawModel']>);
export type EvalStageViewerFilesLowerJawModel = (NonNullable<EvalStageViewerFilesFragment['lowerJawModel']>);
export type DesignStageViewerFilesTeeth = (NonNullable<(NonNullable<DesignStageViewerFilesFragment['teeth']>)[0]>);
export type DesignStageViewerFilesFile = (NonNullable<(NonNullable<DesignStageViewerFilesFragment['teeth']>)[0]>)['file'];
export type DesignStageViewerFilesUpperJawModel = (NonNullable<DesignStageViewerFilesFragment['upperJawModel']>);
export type DesignStageViewerFilesLowerJawModel = (NonNullable<DesignStageViewerFilesFragment['lowerJawModel']>);
export type ViewerStageInfoMoldStageInlineFragment = (DiscriminateUnion<RequireField<ViewerStageInfoFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type ViewerStageInfoEvalStageInlineFragment = (DiscriminateUnion<RequireField<ViewerStageInfoFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type ViewerStageInfoDesignStageInlineFragment = (DiscriminateUnion<RequireField<ViewerStageInfoFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type ViewerStageInfoPrintStageInlineFragment = (DiscriminateUnion<RequireField<ViewerStageInfoFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type PatientAnalysisQueryVariables = PatientAnalysisQueryQueryVariables;
export type PatientAnalysisQueryPatient = (NonNullable<PatientAnalysisQueryQuery['patient']>);
export type PatientAnalysisQueryCurrentEvalStage = (NonNullable<(NonNullable<PatientAnalysisQueryQuery['patient']>)['currentEvalStage']>);
export type PatientAnalysisQueryStages = (NonNullable<(NonNullable<PatientAnalysisQueryQuery['patient']>)['stages']>);
export type PatientAnalysisQueryDocs = (NonNullable<(NonNullable<(NonNullable<PatientAnalysisQueryQuery['patient']>)['stages']>)['docs'][0]>);
export type PatientAnalysisQueryEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<(NonNullable<PatientAnalysisQueryQuery['patient']>)['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type PatientTasksQueryVariables = PatientTasksQueryQueryVariables;
export type PatientTasksQueryTasks = (NonNullable<PatientTasksQueryQuery['tasks']>);
export type PatientTasksQueryDocs = (NonNullable<(NonNullable<PatientTasksQueryQuery['tasks']>)['docs'][0]>);
export type CreateAppointmentVariables = CreateAppointmentMutationVariables;
export type CreateAppointmentCreateAppointment = CreateAppointmentMutation['createAppointment'];
export type UpdateAppointmentVariables = UpdateAppointmentMutationVariables;
export type UpdateAppointmentUpdateAppointment = UpdateAppointmentMutation['updateAppointment'];
export type RemoveAppointmentVariables = RemoveAppointmentMutationVariables;
export type RemoveAppointmentRemoveAppointment = RemoveAppointmentMutation['removeAppointment'];
export type ForwardStageTaskFormCurrentTask = (NonNullable<ForwardStageTaskFormFragment['currentTask']>);
export type ForwardStageTaskFormOwner = (NonNullable<(NonNullable<ForwardStageTaskFormFragment['currentTask']>)['owner']>);
export type ForwardStageTaskFormNextTask = (NonNullable<(NonNullable<ForwardStageTaskFormFragment['currentTask']>)['nextTask']>);
export type ForwardStageTaskFormPatient = ForwardStageTaskFormFragment['patient'];
export type ForwardStageTaskFormTechnician = (NonNullable<ForwardStageTaskFormFragment['patient']['technician']>);
export type ForwardStageTaskVariables = ForwardStageTaskMutationVariables;
export type ForwardStageTaskForwardTask = ForwardStageTaskMutation['forwardTask'];
export type BaseModalStages = (NonNullable<BaseModalFragment['stages']>);
export type BaseModalDocs = (NonNullable<(NonNullable<BaseModalFragment['stages']>)['docs'][0]>);
export type CreateAccessoryStageVariables = CreateAccessoryStageMutationVariables;
export type CreateAccessoryStageCreateAccessoryStage = CreateAccessoryStageMutation['createAccessoryStage'];
export type CreateAccessoryStageQueryVariables = CreateAccessoryStageQueryQueryVariables;
export type CreateAccessoryStageQueryPatient = (NonNullable<CreateAccessoryStageQueryQuery['patient']>);
export type CreateDesignStageVariables = CreateDesignStageMutationVariables;
export type CreateDesignStageCreateDesignStage = CreateDesignStageMutation['createDesignStage'];
export type DesignFormCurrentEvalStage = (NonNullable<DesignFormFragment['currentEvalStage']>);
export type DesignFormStages = (NonNullable<DesignFormFragment['stages']>);
export type DesignFormDocs = (NonNullable<(NonNullable<DesignFormFragment['stages']>)['docs'][0]>);
export type DesignFormDesignStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DesignFormFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>);
export type CreateDesignStageQueryVariables = CreateDesignStageQueryQueryVariables;
export type CreateDesignStageQueryPatient = (NonNullable<CreateDesignStageQueryQuery['patient']>);
export type CreateEvalStageVariables = CreateEvalStageMutationVariables;
export type CreateEvalStageCreateEvalStage = CreateEvalStageMutation['createEvalStage'];
export type EvalFormStages = (NonNullable<EvalFormFragment['stages']>);
export type EvalFormDocs = (NonNullable<(NonNullable<EvalFormFragment['stages']>)['docs'][0]>);
export type EvalFormEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<EvalFormFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type CreateEvalStageQueryVariables = CreateEvalStageQueryQueryVariables;
export type CreateEvalStageQueryPatient = (NonNullable<CreateEvalStageQueryQuery['patient']>);
export type CreateMoldStageVariables = CreateMoldStageMutationVariables;
export type CreateMoldStageCreateMoldStage = CreateMoldStageMutation['createMoldStage'];
export type CreateMoldStageQueryVariables = CreateMoldStageQueryQueryVariables;
export type CreateMoldStageQueryPatient = (NonNullable<CreateMoldStageQueryQuery['patient']>);
export type CreatePrintStageVariables = CreatePrintStageMutationVariables;
export type CreatePrintStageCreatePrintStage = CreatePrintStageMutation['createPrintStage'];
export type PrintFormCurrentEvalStage = (NonNullable<PrintFormFragment['currentEvalStage']>);
export type PrintFormStages = (NonNullable<PrintFormFragment['stages']>);
export type PrintFormDocs = (NonNullable<(NonNullable<PrintFormFragment['stages']>)['docs'][0]>);
export type PrintFormEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<PrintFormFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type PrintFormPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<PrintFormFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'PrintStage' }>);
export type CreatePrintStageQueryVariables = CreatePrintStageQueryQueryVariables;
export type CreatePrintStageQueryPatient = (NonNullable<CreatePrintStageQueryQuery['patient']>);
export type ExpectedShippingDateModalPatient = ExpectedShippingDateModalFragment['patient'];
export type ExpectedShippingDateModalClinic = ExpectedShippingDateModalFragment['patient']['clinic'];
export type UpdateExpectedShippingDateOfAccessoryStageVariables = UpdateExpectedShippingDateOfAccessoryStageMutationVariables;
export type UpdateExpectedShippingDateOfAccessoryStageUpdateAccessoryStage = UpdateExpectedShippingDateOfAccessoryStageMutation['updateAccessoryStage'];
export type UpdateExpectedShippingDateOfDesignStageVariables = UpdateExpectedShippingDateOfDesignStageMutationVariables;
export type UpdateExpectedShippingDateOfDesignStageUpdateDesignStage = UpdateExpectedShippingDateOfDesignStageMutation['updateDesignStage'];
export type UpdateExpectedShippingDateOfEvalStageVariables = UpdateExpectedShippingDateOfEvalStageMutationVariables;
export type UpdateExpectedShippingDateOfEvalStageUpdateEvalStage = UpdateExpectedShippingDateOfEvalStageMutation['updateEvalStage'];
export type UpdateExpectedShippingDateOfMoldStageVariables = UpdateExpectedShippingDateOfMoldStageMutationVariables;
export type UpdateExpectedShippingDateOfMoldStageUpdateMoldStage = UpdateExpectedShippingDateOfMoldStageMutation['updateMoldStage'];
export type UpdateExpectedShippingDateOfPrintStageVariables = UpdateExpectedShippingDateOfPrintStageMutationVariables;
export type UpdateExpectedShippingDateOfPrintStageUpdatePrintStage = UpdateExpectedShippingDateOfPrintStageMutation['updatePrintStage'];
export type InvoicePrintModalClinic = InvoicePrintModalFragment['clinic'];
export type InvoicePrintModalLocation = InvoicePrintModalFragment['clinic']['location'];
export type InvoicePrintModalDoctor = InvoicePrintModalFragment['doctor'];
export type InvoicePrintModalProducts = (NonNullable<InvoicePrintModalFragment['products'][0]>);
export type InvoicePrintModalInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<InvoicePrintModalFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type InvoicePrintModalPatient = (DiscriminateUnion<RequireField<InvoicePrintModalFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient'];
export type InvoicePrintModalStage = (DiscriminateUnion<RequireField<InvoicePrintModalFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'];
export type InvoicePrintModalPrintStageInlineFragment = (DiscriminateUnion<RequireField<(DiscriminateUnion<RequireField<InvoicePrintModalFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type InvoicePrintModalDesignStage = (NonNullable<(DiscriminateUnion<RequireField<(DiscriminateUnion<RequireField<InvoicePrintModalFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type InvoicePrintModalEvalStage = (NonNullable<(DiscriminateUnion<RequireField<(DiscriminateUnion<RequireField<InvoicePrintModalFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['evalStage'];
export type UpdateOrderInstructionWithOptionsVariables = UpdateOrderInstructionWithOptionsMutationVariables;
export type UpdateOrderInstructionWithOptionsUpdateOrder = UpdateOrderInstructionWithOptionsMutation['updateOrder'];
export type TaskUpdateBatchNextTask = (NonNullable<TaskUpdateBatchFragment['nextTask']>);
export type TaskUpdateBatchOwner = (NonNullable<TaskUpdateBatchFragment['owner']>);
export type ForwardTaskBatchVariables = ForwardTaskBatchMutationVariables;
export type ForwardTaskBatchForwardTaskBatch = (NonNullable<ForwardTaskBatchMutation['forwardTaskBatch'][0]>);
export type BackwardTaskBatchVariables = BackwardTaskBatchMutationVariables;
export type BackwardTaskBatchBackwardTaskBatch = (NonNullable<BackwardTaskBatchMutation['backwardTaskBatch'][0]>);
export type CreateTrackVariables = CreateTrackMutationVariables;
export type CreateTrackCreateTrack = CreateTrackMutation['createTrack'];
export type RemoveTrackVariables = RemoveTrackMutationVariables;
export type RemoveTrackRemoveTrack = RemoveTrackMutation['removeTrack'];
export type ReplyTrackVariables = ReplyTrackMutationVariables;
export type ReplyTrackReplyTrack = ReplyTrackMutation['replyTrack'];
export type UpdateViewerFilesVariables = UpdateViewerFilesMutationVariables;
export type UpdateViewerFilesUpdateViewerFiles = UpdateViewerFilesMutation['updateViewerFiles'];
export type CreatePatientModalVariables = CreatePatientModalMutationVariables;
export type CreatePatientModalCreatePatient = CreatePatientModalMutation['createPatient'];
export type PatientPageHeaderQueryVariables = PatientPageHeaderQueryQueryVariables;
export type PatientPageHeaderQueryPatient = (NonNullable<PatientPageHeaderQueryQuery['patient']>);
export type PatientPageHeaderQueryPayment = (NonNullable<PatientPageHeaderQueryQuery['patient']>)['payment'];
export type PatientPageHeaderQueryPhotos = (NonNullable<PatientPageHeaderQueryQuery['patient']>)['photos'];
export type PatientPageHeaderQueryFrontFace = (NonNullable<(NonNullable<PatientPageHeaderQueryQuery['patient']>)['photos']['frontFace']>);
export type PatientPageHeaderQueryClinic = (NonNullable<PatientPageHeaderQueryQuery['patient']>)['clinic'];
export type PatientPageHeaderQueryDoctor = (NonNullable<PatientPageHeaderQueryQuery['patient']>)['doctor'];
export type PatientPageHeaderQueryAccountManager = (NonNullable<(NonNullable<PatientPageHeaderQueryQuery['patient']>)['accountManager']>);
export type PatientPageHeaderQuerySales = (NonNullable<PatientPageHeaderQueryQuery['patient']>)['sales'];
export type PatientPageHeaderQueryTechnician = (NonNullable<(NonNullable<PatientPageHeaderQueryQuery['patient']>)['technician']>);
export type PatientPageHeaderQueryCustomerService = (NonNullable<(NonNullable<PatientPageHeaderQueryQuery['patient']>)['customerService']>);
export type PatientPageHeaderQueryCurrentSubStage = (NonNullable<(NonNullable<PatientPageHeaderQueryQuery['patient']>)['currentSubStage']>);
export type PatientPageHeaderQueryStage = (NonNullable<(NonNullable<PatientPageHeaderQueryQuery['patient']>)['currentSubStage']>)['stage'];
export type PatientPageHeaderQueryMeta = (NonNullable<PatientPageHeaderQueryQuery['patient']>)['meta'];
export type PatientPageHeaderStageItemQueryVariables = PatientPageHeaderStageItemQueryQueryVariables;
export type PatientPageHeaderStageItemQueryStage = PatientPageHeaderStageItemQueryQuery['stage'];
export type QcTasksQueryVariables = QcTasksQueryQueryVariables;
export type QcTasksQueryTasks = (NonNullable<QcTasksQueryQuery['tasks']>);
export type QcTasksQueryDocs = (NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>);
export type QcTasksQueryPatient = (NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['patient'];
export type QcTasksQueryClinic = (NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['patient']['clinic'];
export type QcTasksQueryCurrentEvalStage = (NonNullable<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['patient']['currentEvalStage']>);
export type QcTasksQueryAnalysis = (NonNullable<(NonNullable<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['patient']['currentEvalStage']>)['analysis']>);
export type QcTasksQuerySteps = (NonNullable<(NonNullable<(NonNullable<(NonNullable<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['patient']['currentEvalStage']>)['analysis']>)['steps']>)[0]>);
export type QcTasksQueryStage = (NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'];
export type QcTasksQueryPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type QcTasksQueryInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['instructionCard']>);
export type QcTasksQueryDesignStage = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type QcTasksQueryStep = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['step'];
export type QcTasksQueryAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type QcTasksQuery_InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<QcTasksQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'AccessoryStage' }>)['instructionCard']>);
export type RpTasksQueryVariables = RpTasksQueryQueryVariables;
export type RpTasksQueryTasks = (NonNullable<RpTasksQueryQuery['tasks']>);
export type RpTasksQueryDocs = (NonNullable<(NonNullable<RpTasksQueryQuery['tasks']>)['docs'][0]>);
export type AppointmentTablePatient = AppointmentTableFragment['patient'];
export type AppointmentTableCreator = AppointmentTableFragment['creator'];
export type DiaryTablePatient = DiaryTableFragment['patient'];
export type DiaryTableClinic = DiaryTableFragment['patient']['clinic'];
export type DiaryTableWear = DiaryTableFragment['wear'];
export type DiaryTableSubStage = DiaryTableFragment['wear']['subStage'];
export type DiaryTableStage = DiaryTableFragment['wear']['subStage']['stage'];
export type DoctorTableClinics = (NonNullable<DoctorTableFragment['clinics'][0]>);
export type UpdateDoctorLevelVariables = UpdateDoctorLevelMutationVariables;
export type UpdateDoctorLevelUpdateDoctor = UpdateDoctorLevelMutation['updateDoctor'];
export type InvoiceTableClinic = InvoiceTableFragment['clinic'];
export type InvoiceTableDoctor = InvoiceTableFragment['doctor'];
export type InvoiceTableSales = InvoiceTableFragment['sales'];
export type InvoiceTableCustomerService = (NonNullable<InvoiceTableFragment['customerService']>);
export type InvoiceTableProducts = (NonNullable<InvoiceTableFragment['products'][0]>);
export type InvoiceTableInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<InvoiceTableFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type InvoiceTablePatient = (DiscriminateUnion<RequireField<InvoiceTableFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient'];
export type InvoiceTableStage = (DiscriminateUnion<RequireField<InvoiceTableFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'];
export type TableMoldStagePatient = TableMoldStageFragment['patient'];
export type TableMoldStageAccountManager = (NonNullable<TableMoldStageFragment['patient']['accountManager']>);
export type TableMoldStageSales = TableMoldStageFragment['patient']['sales'];
export type TableMoldStageEvalStages = (NonNullable<(NonNullable<TableMoldStageFragment['evalStages']>)[0]>);
export type BindingStageSelectCurrentTask = (NonNullable<BindingStageSelectFragment['currentTask']>);
export type PatientTableNote = PatientTableFragment['note'];
export type PatientTablePhotos = PatientTableFragment['photos'];
export type PatientTableFrontFace = (NonNullable<PatientTableFragment['photos']['frontFace']>);
export type PatientTableClinic = PatientTableFragment['clinic'];
export type PatientTableDoctor = PatientTableFragment['doctor'];
export type PatientTableAccountManager = (NonNullable<PatientTableFragment['accountManager']>);
export type PatientTableTechnician = (NonNullable<PatientTableFragment['technician']>);
export type PatientTableSales = PatientTableFragment['sales'];
export type PatientTableCustomerService = (NonNullable<PatientTableFragment['customerService']>);
export type PatientTableCurrentEvalStage = (NonNullable<PatientTableFragment['currentEvalStage']>);
export type PatientTablePayment = PatientTableFragment['payment'];
export type PatientTableMeta = PatientTableFragment['meta'];
export type PatientTableLastEvalStage = (NonNullable<PatientTableFragment['meta']['lastEvalStage']>);
export type PatientTableLastPrintStage = (NonNullable<PatientTableFragment['meta']['lastPrintStage']>);
export type PatientTableCreator = (NonNullable<PatientTableFragment['creator']>);
export type UpdatePatientTableVariables = UpdatePatientTableMutationVariables;
export type UpdatePatientTableUpdatePatient = UpdatePatientTableMutation['updatePatient'];
export type PatientTablePaymentClinic = PatientTablePaymentFragment['clinic'];
export type PatientTablePaymentPayment = PatientTablePaymentFragment['payment'];
export type PatientTablePaymentAccountManager = (NonNullable<PatientTablePaymentFragment['accountManager']>);
export type PatientTablePaymentTechnician = (NonNullable<PatientTablePaymentFragment['technician']>);
export type PatientTablePaymentSales = PatientTablePaymentFragment['sales'];
export type PatientTablePaymentCustomerService = (NonNullable<PatientTablePaymentFragment['customerService']>);
export type PatientTablePaymentMeta = PatientTablePaymentFragment['meta'];
export type PatientTablePaymentLastPrintStage = (NonNullable<PatientTablePaymentFragment['meta']['lastPrintStage']>);
export type PatientStageTableDropStageVariables = PatientStageTableDropStageMutationVariables;
export type PatientStageTableDropStageDropStage = PatientStageTableDropStageMutation['dropStage'];
export type PatientStageTableUndropStageVariables = PatientStageTableUndropStageMutationVariables;
export type PatientStageTableUndropStageUndropStage = PatientStageTableUndropStageMutation['undropStage'];
export type PatientStageTablePatientInfoCurrentEvalStage = (NonNullable<PatientStageTablePatientInfoFragment['currentEvalStage']>);
export type PatientStageTableStageInfoCurrentTask = (NonNullable<PatientStageTableStageInfoFragment['currentTask']>);
export type PatientStageTableStageInfoOwner = (NonNullable<(NonNullable<PatientStageTableStageInfoFragment['currentTask']>)['owner']>);
export type PatientStageTableStageInfoMoldStageInlineFragment = (DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type PatientStageTableStageInfoEvalStageInlineFragment = (DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type PatientStageTableStageInfoInitialEvalStage = (NonNullable<(DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'EvalStage' }>)['initialEvalStage']>);
export type PatientStageTableStageInfoDesignStageInlineFragment = (DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type PatientStageTableStageInfoInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'DesignStage' }>)['instructionCard']>);
export type PatientStageTableStageInfoPrintStageInlineFragment = (DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type PatientStageTableStageInfo_InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'PrintStage' }>)['instructionCard']>);
export type PatientStageTableStageInfoAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type PatientStageTableStageInfo__InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<PatientStageTableStageInfoFragment, '__typename'>, { __typename: 'AccessoryStage' }>)['instructionCard']>);
export type UpdatePatientCabinetCodeVariables = UpdatePatientCabinetCodeMutationVariables;
export type UpdatePatientCabinetCodeUpdatePatient = UpdatePatientCabinetCodeMutation['updatePatient'];
export type QcListTablePatient = QcListTableFragment['patient'];
export type QcListTableClinic = QcListTableFragment['patient']['clinic'];
export type QcListTableCurrentEvalStage = (NonNullable<QcListTableFragment['patient']['currentEvalStage']>);
export type QcListTablePayment = QcListTableFragment['patient']['payment'];
export type QcListTableStage = QcListTableFragment['stage'];
export type QcListTablePrintStageInlineFragment = (DiscriminateUnion<RequireField<QcListTableFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type QcListTableInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<QcListTableFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>)['instructionCard']>);
export type QcListTableAuxiliaries = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<QcListTableFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>)['instructionCard']>)['auxiliaries'][0]>);
export type QcListTableDesignStage = (NonNullable<(DiscriminateUnion<RequireField<QcListTableFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type QcListTableAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<QcListTableFragment['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type QcListTable_InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<QcListTableFragment['stage'], '__typename'>, { __typename: 'AccessoryStage' }>)['instructionCard']>);
export type RpListTableOwner = (NonNullable<RpListTableFragment['owner']>);
export type RpListTableStage = RpListTableFragment['stage'];
export type RpListTablePrintStageInlineFragment = (DiscriminateUnion<RequireField<RpListTableFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type RpListTableDesignStage = (NonNullable<(DiscriminateUnion<RequireField<RpListTableFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type RpListTableAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<RpListTableFragment['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type RpListTablePatient = RpListTableFragment['patient'];
export type RpListTableClinic = RpListTableFragment['patient']['clinic'];
export type OrderCellAppointment = (NonNullable<OrderCellFragment['appointment']>);
export type StageTablePatient = StageTableFragment['patient'];
export type StageTableMoldStageInlineFragment = (DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type StageTableLatestEvalStage = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'MoldStage' }>)['latestEvalStage']>);
export type StageTableOrder = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'MoldStage' }>)['latestEvalStage']>)['order']>);
export type StageTableEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type StageTable_Order = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'EvalStage' }>)['order']>);
export type StageTableDesignStageInlineFragment = (DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type StageTableInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'DesignStage' }>)['instructionCard']>);
export type StageTableLatestPrintStage = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'DesignStage' }>)['latestPrintStage']>);
export type StageTable__Order = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'DesignStage' }>)['latestPrintStage']>)['order']>);
export type StageTablePrintStageInlineFragment = (DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type StageTable_InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'PrintStage' }>)['instructionCard']>);
export type StageTable___Order = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'PrintStage' }>)['order']>);
export type StageTableAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type StageTable__InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'AccessoryStage' }>)['instructionCard']>);
export type StageTable____Order = (NonNullable<(DiscriminateUnion<RequireField<StageTableFragment, '__typename'>, { __typename: 'AccessoryStage' }>)['order']>);
export type TableTaskOwner = (NonNullable<TableTaskFragment['owner']>);
export type TableTaskStage = TableTaskFragment['stage'];
export type TableTaskPrintStageInlineFragment = (DiscriminateUnion<RequireField<TableTaskFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type TableTaskDesignStage = (NonNullable<(DiscriminateUnion<RequireField<TableTaskFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type TableTaskInstructionCard = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<TableTaskFragment['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['instructionCard']>);
export type TableTaskPatient = TableTaskFragment['patient'];
export type TableTaskPayment = TableTaskFragment['patient']['payment'];
export type PhotoThumbnailListFrontLoose = (NonNullable<PhotoThumbnailListFragment['frontLoose']>);
export type PhotoThumbnailListFrontTight = (NonNullable<PhotoThumbnailListFragment['frontTight']>);
export type PhotoThumbnailListLeftTight = (NonNullable<PhotoThumbnailListFragment['leftTight']>);
export type PhotoThumbnailListRightTight = (NonNullable<PhotoThumbnailListFragment['rightTight']>);
export type TrackTableStage = TrackTableFragment['stage'];
export type TrackTableSubStage = (NonNullable<(NonNullable<TrackTableFragment['stage']['subStage']>)[0]>);
export type TrackTablePatient = TrackTableFragment['patient'];
export type TrackTableAccountManager = (NonNullable<TrackTableFragment['patient']['accountManager']>);
export type TrackTablePhoto = (NonNullable<TrackTableFragment['photo']>);
export type TrackTableWithBrace = (NonNullable<(NonNullable<TrackTableFragment['photo']>)['withBrace']>);
export type TrackTableWithoutBrace = (NonNullable<(NonNullable<TrackTableFragment['photo']>)['withoutBrace']>);
export type EventTableCreator = EventTableFragment['creator'];
export type EventTableData = EventTableFragment['data'];
export type EventTablePatient = (NonNullable<EventTableFragment['data']['patient']>);
export type EventTableStage = (NonNullable<EventTableFragment['data']['stage']>);
export type EventTableOrder = (NonNullable<EventTableFragment['data']['order']>);
export type PatientOrderTableStageOrder = (NonNullable<PatientOrderTableFragment['stageOrder'][0]>);
export type PatientOrderTableAppointment = (NonNullable<PatientOrderTableFragment['appointment']>);
export type PatientTrackTableStage = PatientTrackTableFragment['stage'];
export type PatientTrackTablePhoto = (NonNullable<PatientTrackTableFragment['photo']>);
export type PatientTrackTableWithBrace = (NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withBrace']>);
export type PatientTrackTableFrontLoose = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withBrace']>)['frontLoose']>);
export type PatientTrackTableFrontTight = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withBrace']>)['frontTight']>);
export type PatientTrackTableLeftTight = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withBrace']>)['leftTight']>);
export type PatientTrackTableRightTight = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withBrace']>)['rightTight']>);
export type PatientTrackTableWithoutBrace = (NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withoutBrace']>);
export type PatientTrackTable_FrontLoose = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withoutBrace']>)['frontLoose']>);
export type PatientTrackTable_FrontTight = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withoutBrace']>)['frontTight']>);
export type PatientTrackTable_LeftTight = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withoutBrace']>)['leftTight']>);
export type PatientTrackTable_RightTight = (NonNullable<(NonNullable<(NonNullable<PatientTrackTableFragment['photo']>)['withoutBrace']>)['rightTight']>);
export type RemoveInstructionCardVariables = RemoveInstructionCardMutationVariables;
export type RemoveInstructionCardRemoveInstructionCard = RemoveInstructionCardMutation['removeInstructionCard'];
export type CreateAccountVariables = CreateAccountMutationVariables;
export type CreateAccountCreateAccount = CreateAccountMutation['createAccount'];
export type AccountMenuQueryVariables = AccountMenuQueryQueryVariables;
export type AccountMenuQueryAccount = AccountMenuQueryQuery['account'];
export type UpdateAccountShouldClearStorageVariables = UpdateAccountShouldClearStorageMutationVariables;
export type UpdateAccountShouldClearStorageUpdateAccount = UpdateAccountShouldClearStorageMutation['updateAccount'];
export type AccountAppInfoVariables = AccountAppInfoQueryVariables;
export type AccountAppInfoAccount = AccountAppInfoQuery['account'];
export type EntityBindFormEntity = (NonNullable<EntityBindFormFragment['entity']>);
export type BindAccountUserVariables = BindAccountUserMutationVariables;
export type BindAccountUserBindAccountUser = BindAccountUserMutation['bindAccountUser'];
export type RemoveEntityBindingFromAccountButtonEntity = (NonNullable<RemoveEntityBindingFromAccountButtonFragment['entity']>);
export type RemoveEntityBindingFromAccountVariables = RemoveEntityBindingFromAccountMutationVariables;
export type RemoveEntityBindingFromAccountUpdateAccount = RemoveEntityBindingFromAccountMutation['updateAccount'];
export type AccountBindEntityVariables = AccountBindEntityQueryVariables;
export type AccountBindEntityAccount = AccountBindEntityQuery['account'];
export type AccountDetailFormProviders = (NonNullable<AccountDetailFormFragment['providers']>);
export type AccountDetailFormFacebook = (NonNullable<(NonNullable<AccountDetailFormFragment['providers']>)['facebook']>);
export type AccountDetailFormGoogle = (NonNullable<(NonNullable<AccountDetailFormFragment['providers']>)['google']>);
export type AccountDetailFormLine = (NonNullable<(NonNullable<AccountDetailFormFragment['providers']>)['line']>);
export type AccountDetailFormWechat = (NonNullable<(NonNullable<AccountDetailFormFragment['providers']>)['wechat']>);
export type AvatarWithNicknameAvatar = (NonNullable<AvatarWithNicknameFragment['avatar']>);
export type AccountInfoQueryVariables = AccountInfoQueryQueryVariables;
export type AccountInfoQueryAccount = AccountInfoQueryQuery['account'];
export type UpdateAccountInfoVariables = UpdateAccountInfoMutationVariables;
export type UpdateAccountInfoUpdateAccount = UpdateAccountInfoMutation['updateAccount'];
export type VerifyEmployeeAccountVariables = VerifyEmployeeAccountMutationVariables;
export type VerifyEmployeeAccountVerifyEmployeeAccount = VerifyEmployeeAccountMutation['verifyEmployeeAccount'];
export type AccountInPrivilegesTabEntity = (NonNullable<AccountInPrivilegesTabFragment['entity']>);
export type AccountPrivilegesQueryVariables = AccountPrivilegesQueryQueryVariables;
export type AccountPrivilegesQueryAccount = AccountPrivilegesQueryQuery['account'];
export type UpdateAccountPrivilegesVariables = UpdateAccountPrivilegesMutationVariables;
export type UpdateAccountPrivilegesUpdateAccount = UpdateAccountPrivilegesMutation['updateAccount'];
export type NotificationSettingVariables = NotificationSettingQueryVariables;
export type NotificationSettingAccount = NotificationSettingQuery['account'];
export type NotificationSettingNotificationSetting = NotificationSettingQuery['account']['notificationSetting'];
export type UpdateNotificationSettingVariables = UpdateNotificationSettingMutationVariables;
export type UpdateNotificationSettingUpdateAccount = UpdateNotificationSettingMutation['updateAccount'];
export type UpdateNotificationSettingNotificationSetting = UpdateNotificationSettingMutation['updateAccount']['notificationSetting'];
export type LoginByRootMutationVariables = LoginByRootMutationMutationVariables;
export type LoginByRootMutationLoginByRoot = LoginByRootMutationMutation['loginByRoot'];
export type AccountTableInfoAvatar = (NonNullable<AccountTableInfoFragment['avatar']>);
export type AccountTableInfoEntity = (NonNullable<AccountTableInfoFragment['entity']>);
export type AccountTableInfoEmployeeInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<AccountTableInfoFragment['entity']>), '__typename'>, { __typename: 'Employee' }>);
export type AccountTableInfoClinicInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<AccountTableInfoFragment['entity']>), '__typename'>, { __typename: 'Clinic' }>);
export type AccountTableInfoDoctorInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<AccountTableInfoFragment['entity']>), '__typename'>, { __typename: 'Doctor' }>);
export type AccountTableInfoPatientInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<AccountTableInfoFragment['entity']>), '__typename'>, { __typename: 'Patient' }>);
export type AccountTableInfoProviders = (NonNullable<AccountTableInfoFragment['providers']>);
export type AccountTableInfoFacebook = (NonNullable<(NonNullable<AccountTableInfoFragment['providers']>)['facebook']>);
export type AccountTableInfoGoogle = (NonNullable<(NonNullable<AccountTableInfoFragment['providers']>)['google']>);
export type AccountTableInfoLine = (NonNullable<(NonNullable<AccountTableInfoFragment['providers']>)['line']>);
export type AccountTableInfoWechat = (NonNullable<(NonNullable<AccountTableInfoFragment['providers']>)['wechat']>);
export type AccountsQueryVariables = AccountsQueryQueryVariables;
export type AccountsQueryAccounts = AccountsQueryQuery['accounts'];
export type AccountsQueryDocs = (NonNullable<AccountsQueryQuery['accounts']['docs'][0]>);
export type AppointmentCalendarQueryVariables = AppointmentCalendarQueryQueryVariables;
export type AppointmentCalendarQueryAppointments = AppointmentCalendarQueryQuery['appointments'];
export type AppointmentCalendarQueryDocs = (NonNullable<AppointmentCalendarQueryQuery['appointments']['docs'][0]>);
export type AppointmentListQueryVariables = AppointmentListQueryQueryVariables;
export type AppointmentListQueryAppointments = AppointmentListQueryQuery['appointments'];
export type AppointmentListQueryDocs = (NonNullable<AppointmentListQueryQuery['appointments']['docs'][0]>);
export type ClinicDetailQueryVariables = ClinicDetailQueryQueryVariables;
export type ClinicDetailQueryClinic = ClinicDetailQueryQuery['clinic'];
export type ClinicInvoiceVariables = ClinicInvoiceQueryVariables;
export type ClinicInvoiceInvoices = ClinicInvoiceQuery['invoices'];
export type ClinicInvoiceDocs = (NonNullable<ClinicInvoiceQuery['invoices']['docs'][0]>);
export type PatientStagePreviewPrintStageInlineFragment = (DiscriminateUnion<RequireField<PatientStagePreviewFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type ConversationPatientInfoPayment = ConversationPatientInfoFragment['payment'];
export type ConversationPatientInfoPhotos = ConversationPatientInfoFragment['photos'];
export type ConversationPatientInfoFrontFace = (NonNullable<ConversationPatientInfoFragment['photos']['frontFace']>);
export type ConversationPatientInfoClinic = ConversationPatientInfoFragment['clinic'];
export type ConversationPatientInfoDoctor = ConversationPatientInfoFragment['doctor'];
export type ConversationPatientInfoAccountManager = (NonNullable<ConversationPatientInfoFragment['accountManager']>);
export type ConversationPatientInfoTechnician = (NonNullable<ConversationPatientInfoFragment['technician']>);
export type ConversationPatientInfoNote = ConversationPatientInfoFragment['note'];
export type ConversationPatientInfoStages = (NonNullable<ConversationPatientInfoFragment['stages']>);
export type ConversationPatientInfoDocs = (NonNullable<(NonNullable<ConversationPatientInfoFragment['stages']>)['docs'][0]>);
export type UpdatePatientNoteVariables = UpdatePatientNoteMutationVariables;
export type UpdatePatientNoteUpdatePatient = UpdatePatientNoteMutation['updatePatient'];
export type ConversationInfoQueryVariables = ConversationInfoQueryQueryVariables;
export type ConversationInfoQueryConversation = ConversationInfoQueryQuery['conversation'];
export type ConversationInfoQueryMembers = (NonNullable<ConversationInfoQueryQuery['conversation']['members'][0]>);
export type ConversationInfoQueryPatientConversationInlineFragment = (DiscriminateUnion<RequireField<ConversationInfoQueryQuery['conversation'], '__typename'>, { __typename: 'PatientConversation' }>);
export type ConversationInfoQueryPatient = (DiscriminateUnion<RequireField<ConversationInfoQueryQuery['conversation'], '__typename'>, { __typename: 'PatientConversation' }>)['patient'];
export type DiariesQueryVariables = DiariesQueryQueryVariables;
export type DiariesQueryDiaries = DiariesQueryQuery['diaries'];
export type DiariesQueryDocs = (NonNullable<DiariesQueryQuery['diaries']['docs'][0]>);
export type DoctorDetailVariables = DoctorDetailQueryVariables;
export type DoctorDetailDoctor = DoctorDetailQuery['doctor'];
export type DoctorDetailClinics = (NonNullable<DoctorDetailQuery['doctor']['clinics'][0]>);
export type DoctorInvoiceVariables = DoctorInvoiceQueryVariables;
export type DoctorInvoiceInvoices = DoctorInvoiceQuery['invoices'];
export type DoctorInvoiceDocs = (NonNullable<DoctorInvoiceQuery['invoices']['docs'][0]>);
export type DoctorsQueryVariables = DoctorsQueryQueryVariables;
export type DoctorsQueryDoctors = DoctorsQueryQuery['doctors'];
export type DoctorsQueryDocs = (NonNullable<DoctorsQueryQuery['doctors']['docs'][0]>);
export type BarChartDataVariables = BarChartDataQueryVariables;
export type BarChartDataGroupedCreatedPatientCountMapList = (NonNullable<BarChartDataQuery['groupedCreatedPatientCountMapList'][0]>);
export type BarChartDataClinic = (NonNullable<BarChartDataQuery['groupedCreatedPatientCountMapList'][0]>)['clinic'];
export type AccountManagerOrderListRowPatient = AccountManagerOrderListRowFragment['patient'];
export type AccountManagerOrderListRowPhotos = AccountManagerOrderListRowFragment['patient']['photos'];
export type AccountManagerOrderListRowFrontFace = (NonNullable<AccountManagerOrderListRowFragment['patient']['photos']['frontFace']>);
export type AccountManagerStageListRowPatient = AccountManagerStageListRowFragment['patient'];
export type AccountManagerStageListRowPhotos = AccountManagerStageListRowFragment['patient']['photos'];
export type AccountManagerStageListRowFrontFace = (NonNullable<AccountManagerStageListRowFragment['patient']['photos']['frontFace']>);
export type AccountManagerStageListRowPrintStageInlineFragment = (DiscriminateUnion<RequireField<AccountManagerStageListRowFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type AccountManagerStageListRowDesignStage = (NonNullable<(DiscriminateUnion<RequireField<AccountManagerStageListRowFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type AccountManagerStageListRowDesignStageInlineFragment = ({ __typename: 'DesignStage' } & Pick<(NonNullable<(DiscriminateUnion<RequireField<AccountManagerStageListRowFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>), 'instructionCard'>);
export type AccountManagerStageListRowInstructionCard = (NonNullable<({ __typename: 'DesignStage' } & Pick<(NonNullable<(DiscriminateUnion<RequireField<AccountManagerStageListRowFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>), 'instructionCard'>)['instructionCard']>);
export type AccountManagerStatisticPageVariables = AccountManagerStatisticPageQueryVariables;
export type AccountManagerStatisticPageEmployee = AccountManagerStatisticPageQuery['employee'];
export type AccountManagerStatisticPageOrders = (NonNullable<AccountManagerStatisticPageQuery['employee']['orders']>);
export type AccountManagerStatisticPageDocs = (NonNullable<(NonNullable<AccountManagerStatisticPageQuery['employee']['orders']>)['docs'][0]>);
export type AccountManagerStatisticPageStages = (NonNullable<AccountManagerStatisticPageQuery['employee']['stages']>);
export type AccountManagerStatisticPage_Docs = (NonNullable<(NonNullable<AccountManagerStatisticPageQuery['employee']['stages']>)['docs'][0]>);
export type AccountManagerStatisticPageAccountManagerStatistic = AccountManagerStatisticPageQuery['accountManagerStatistic'];
export type AccountManagerStatisticPagePatientStatusAndCountMapList = (NonNullable<AccountManagerStatisticPageQuery['accountManagerStatistic']['patientStatusAndCountMapList'][0]>);
export type UpdateEmployeeBatchVariables = UpdateEmployeeBatchMutationVariables;
export type UpdateEmployeeBatchUpdateEmployeeBatch = (NonNullable<UpdateEmployeeBatchMutation['updateEmployeeBatch'][0]>);
export type EmployeesByTaskOwnerQueryVariables = EmployeesByTaskOwnerQueryQueryVariables;
export type EmployeesByTaskOwnerQueryEmployees = EmployeesByTaskOwnerQueryQuery['employees'];
export type EmployeesByTaskOwnerQueryDocs = (NonNullable<EmployeesByTaskOwnerQueryQuery['employees']['docs'][0]>);
export type CreateEmployeeVariables = CreateEmployeeMutationVariables;
export type CreateEmployeeCreateEmployee = CreateEmployeeMutation['createEmployee'];
export type EmployeeQueryVariables = EmployeeQueryQueryVariables;
export type EmployeeQueryEmployee = EmployeeQueryQuery['employee'];
export type UpdateEmployeeVariables = UpdateEmployeeMutationVariables;
export type UpdateEmployeeUpdateEmployee = UpdateEmployeeMutation['updateEmployee'];
export type RemoveEmployeeVariables = RemoveEmployeeMutationVariables;
export type RemoveEmployeeRemoveEmployee = RemoveEmployeeMutation['removeEmployee'];
export type UpdateOrderInstructionVariables = UpdateOrderInstructionMutationVariables;
export type UpdateOrderInstructionUpdateOrder = UpdateOrderInstructionMutation['updateOrder'];
export type OrderTablePatient = OrderTableFragment['patient'];
export type OrderTableSales = OrderTableFragment['patient']['sales'];
export type OrderTableClinic = OrderTableFragment['patient']['clinic'];
export type OrderTableAppointment = (NonNullable<OrderTableFragment['appointment']>);
export type OrderTableStageOrder = (NonNullable<OrderTableFragment['stageOrder'][0]>);
export type OrderTableStageProvide = (NonNullable<OrderTableFragment['stageProvide'][0]>);
export type OrderTableStages = (NonNullable<(NonNullable<OrderTableFragment['stageProvide'][0]>)['stages'][0]>);
export type EmployeeOrdersVariables = EmployeeOrdersQueryVariables;
export type EmployeeOrdersEmployee = EmployeeOrdersQuery['employee'];
export type EmployeeOrdersOrders = (NonNullable<EmployeeOrdersQuery['employee']['orders']>);
export type EmployeeOrdersDocs = (NonNullable<(NonNullable<EmployeeOrdersQuery['employee']['orders']>)['docs'][0]>);
export type EmployeePatientListQueryVariables = EmployeePatientListQueryQueryVariables;
export type EmployeePatientListQueryEmployee = EmployeePatientListQueryQuery['employee'];
export type EmployeePatientListQueryPatients = (NonNullable<EmployeePatientListQueryQuery['employee']['patients']>);
export type EmployeePatientListQueryDocs = (NonNullable<(NonNullable<EmployeePatientListQueryQuery['employee']['patients']>)['docs'][0]>);
export type EmployeeStageListVariables = EmployeeStageListQueryVariables;
export type EmployeeStageListEmployee = EmployeeStageListQuery['employee'];
export type EmployeeStageListStages = (NonNullable<EmployeeStageListQuery['employee']['stages']>);
export type EmployeeStageListDocs = (NonNullable<(NonNullable<EmployeeStageListQuery['employee']['stages']>)['docs'][0]>);
export type EmployeeTasksQueryVariables = EmployeeTasksQueryQueryVariables;
export type EmployeeTasksQueryTasks = (NonNullable<EmployeeTasksQueryQuery['tasks']>);
export type EmployeeTasksQueryDocs = (NonNullable<(NonNullable<EmployeeTasksQueryQuery['tasks']>)['docs'][0]>);
export type EmployeeTaskOwnerQueryVariables = EmployeeTaskOwnerQueryQueryVariables;
export type EmployeeTaskOwnerQueryEmployee = EmployeeTaskOwnerQueryQuery['employee'];
export type CompletedBracesQueryVariables = CompletedBracesQueryQueryVariables;
export type CompletedBracesQueryTasks = (NonNullable<CompletedBracesQueryQuery['tasks']>);
export type CompletedBracesQueryDocs = (NonNullable<(NonNullable<CompletedBracesQueryQuery['tasks']>)['docs'][0]>);
export type CompletedBracesQueryPatient = (NonNullable<(NonNullable<CompletedBracesQueryQuery['tasks']>)['docs'][0]>)['patient'];
export type CompletedBracesQueryStage = (NonNullable<(NonNullable<CompletedBracesQueryQuery['tasks']>)['docs'][0]>)['stage'];
export type CompletedBracesQueryPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<CompletedBracesQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type CompletedBracesQueryDesignStage = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<CompletedBracesQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type CompletedBracesQueryOwner = (NonNullable<(NonNullable<(NonNullable<CompletedBracesQueryQuery['tasks']>)['docs'][0]>)['owner']>);
export type CompletedRpQueryVariables = CompletedRpQueryQueryVariables;
export type CompletedRpQueryTasks = (NonNullable<CompletedRpQueryQuery['tasks']>);
export type CompletedRpQueryDocs = (NonNullable<(NonNullable<CompletedRpQueryQuery['tasks']>)['docs'][0]>);
export type CompletedRpQueryPatient = (NonNullable<(NonNullable<CompletedRpQueryQuery['tasks']>)['docs'][0]>)['patient'];
export type CompletedRpQueryStage = (NonNullable<(NonNullable<CompletedRpQueryQuery['tasks']>)['docs'][0]>)['stage'];
export type CompletedRpQueryPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<CompletedRpQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type CompletedRpQueryDesignStage = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<CompletedRpQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type CompletedRpQueryOwner = (NonNullable<(NonNullable<(NonNullable<CompletedRpQueryQuery['tasks']>)['docs'][0]>)['owner']>);
export type DefectiveQueryVariables = DefectiveQueryQueryVariables;
export type DefectiveQueryTasks = (NonNullable<DefectiveQueryQuery['tasks']>);
export type DefectiveQueryDocs = (NonNullable<(NonNullable<DefectiveQueryQuery['tasks']>)['docs'][0]>);
export type DefectiveQueryPatient = (NonNullable<(NonNullable<DefectiveQueryQuery['tasks']>)['docs'][0]>)['patient'];
export type DefectiveQueryClinic = (NonNullable<(NonNullable<DefectiveQueryQuery['tasks']>)['docs'][0]>)['patient']['clinic'];
export type DefectiveQueryStage = (NonNullable<(NonNullable<DefectiveQueryQuery['tasks']>)['docs'][0]>)['stage'];
export type DefectiveQueryAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DefectiveQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type FactoryOrderQueryVariables = FactoryOrderQueryQueryVariables;
export type FactoryOrderQueryTasks = (NonNullable<FactoryOrderQueryQuery['tasks']>);
export type FactoryOrderQueryDocs = (NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>);
export type FactoryOrderQueryPatient = (NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['patient'];
export type FactoryOrderQueryStage = (NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['stage'];
export type FactoryOrderQueryPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type FactoryOrderQueryDesignStage = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type FactoryOrderQueryInvoice = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['invoice']>);
export type FactoryOrderQueryProducts = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['invoice']>)['products'][0]>);
export type FactoryOrderQueryOwner = (NonNullable<(NonNullable<(NonNullable<FactoryOrderQueryQuery['tasks']>)['docs'][0]>)['owner']>);
export type FactoryShipmentVariables = FactoryShipmentQueryVariables;
export type FactoryShipmentTasks = (NonNullable<FactoryShipmentQuery['tasks']>);
export type FactoryShipmentDocs = (NonNullable<(NonNullable<FactoryShipmentQuery['tasks']>)['docs'][0]>);
export type FactoryShipmentPatient = (NonNullable<(NonNullable<FactoryShipmentQuery['tasks']>)['docs'][0]>)['patient'];
export type FactoryShipmentClinic = (NonNullable<(NonNullable<FactoryShipmentQuery['tasks']>)['docs'][0]>)['patient']['clinic'];
export type FactoryShipmentStage = (NonNullable<(NonNullable<FactoryShipmentQuery['tasks']>)['docs'][0]>)['stage'];
export type FactoryShipmentPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<FactoryShipmentQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type FactoryShipmentDesignStage = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<FactoryShipmentQuery['tasks']>)['docs'][0]>)['stage'], '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type InvoiceDetailQueryVariables = InvoiceDetailQueryQueryVariables;
export type InvoiceDetailQueryInvoice = InvoiceDetailQueryQuery['invoice'];
export type InvoiceListVariables = InvoiceListQueryVariables;
export type InvoiceListInvoices = InvoiceListQuery['invoices'];
export type InvoiceListDocs = (NonNullable<InvoiceListQuery['invoices']['docs'][0]>);
export type StageInvoiceQueryVariables = StageInvoiceQueryQueryVariables;
export type StageInvoiceQueryStage = StageInvoiceQueryQuery['stage'];
export type StageInvoiceQueryPatient = StageInvoiceQueryQuery['stage']['patient'];
export type StageInvoiceQueryEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageInvoiceQueryQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>);
export type StageInvoiceQueryInvoice = (NonNullable<(DiscriminateUnion<RequireField<StageInvoiceQueryQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>)['invoice']>);
export type StageInvoiceQueryPrintStageInlineFragment = (DiscriminateUnion<RequireField<StageInvoiceQueryQuery['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type StageInvoiceQuery_Invoice = (NonNullable<(DiscriminateUnion<RequireField<StageInvoiceQueryQuery['stage'], '__typename'>, { __typename: 'PrintStage' }>)['invoice']>);
export type StageInvoiceQueryAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageInvoiceQueryQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type StageInvoiceQuery__Invoice = (NonNullable<(DiscriminateUnion<RequireField<StageInvoiceQueryQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>)['invoice']>);
export type PatientCostVariables = PatientCostQueryVariables;
export type PatientCostPatient = (NonNullable<PatientCostQuery['patient']>);
export type PatientCostClinic = (NonNullable<PatientCostQuery['patient']>)['clinic'];
export type PatientCostDoctor = (NonNullable<PatientCostQuery['patient']>)['doctor'];
export type PatientCostStages = PatientCostQuery['stages'];
export type PatientCostDocs = (NonNullable<PatientCostQuery['stages']['docs'][0]>);
export type PatientCostEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<PatientCostQuery['stages']['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type PatientCostTasks = (NonNullable<(NonNullable<PatientCostQuery['stages']['docs'][0]>)['tasks'][0]>);
export type EmployeeLeaveQueryVariables = EmployeeLeaveQueryQueryVariables;
export type EmployeeLeaveQueryEmployee = EmployeeLeaveQueryQuery['employee'];
export type NotificationListVariables = NotificationListQueryVariables;
export type NotificationListNotifications = (NonNullable<NotificationListQuery['notifications']>);
export type NotificationListEdges = (NonNullable<(NonNullable<NotificationListQuery['notifications']>)['edges'][0]>);
export type NotificationListNode = (NonNullable<(NonNullable<NotificationListQuery['notifications']>)['edges'][0]>)['node'];
export type NotificationListPageInfo = (NonNullable<NotificationListQuery['notifications']>)['pageInfo'];
export type DiliveryInfoStageProvide = (NonNullable<DiliveryInfoFragment['stageProvide'][0]>);
export type DiliveryInfoStages = (NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>);
export type DiliveryInfoPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'PrintStage' }>);
export type DiliveryInfoInvoice = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'PrintStage' }>)['invoice']>);
export type DiliveryInfoEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type DiliveryInfo_Invoice = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['invoice']>);
export type DiliveryInfoAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'AccessoryStage' }>);
export type DiliveryInfo__Invoice = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DiliveryInfoFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'AccessoryStage' }>)['invoice']>);
export type ItemListStageProvide = (NonNullable<ItemListFragment['stageProvide'][0]>);
export type ItemListStages = (NonNullable<(NonNullable<ItemListFragment['stageProvide'][0]>)['stages'][0]>);
export type ItemListPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ItemListFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'PrintStage' }>);
export type ItemListInvoice = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ItemListFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'PrintStage' }>)['invoice']>);
export type ItemListProducts = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ItemListFragment['stageProvide'][0]>)['stages'][0]>), '__typename'>, { __typename: 'PrintStage' }>)['invoice']>)['products'][0]>);
export type OrderDetailStageOrder = (NonNullable<OrderDetailFragment['stageOrder'][0]>);
export type OrderDetailAppointment = (NonNullable<OrderDetailFragment['appointment']>);
export type OrderDetailStageProvide = (NonNullable<OrderDetailFragment['stageProvide'][0]>);
export type OrderDetailStages = (NonNullable<(NonNullable<OrderDetailFragment['stageProvide'][0]>)['stages'][0]>);
export type PatientProfileCreator = PatientProfileFragment['creator'];
export type PatientProfilePatient = PatientProfileFragment['patient'];
export type PatientProfileDoctor = PatientProfileFragment['patient']['doctor'];
export type OrderDetailPagePatient = OrderDetailPageFragment['patient'];
export type OrderDetailVariables = OrderDetailQueryVariables;
export type OrderDetailOrder = (NonNullable<OrderDetailQuery['order']>);
export type BindingStagesVariables = BindingStagesQueryVariables;
export type BindingStagesStages = BindingStagesQuery['stages'];
export type BindingStagesDocs = (NonNullable<BindingStagesQuery['stages']['docs'][0]>);
export type BindingStagesPatient = (NonNullable<BindingStagesQuery['stages']['docs'][0]>)['patient'];
export type PatientAppointmentsQueryVariables = PatientAppointmentsQueryQueryVariables;
export type PatientAppointmentsQueryPatient = (NonNullable<PatientAppointmentsQueryQuery['patient']>);
export type PatientAppointmentsQueryAppointments = (NonNullable<(NonNullable<PatientAppointmentsQueryQuery['patient']>)['appointments']>);
export type PatientAppointmentsQueryDocs = (NonNullable<(NonNullable<(NonNullable<PatientAppointmentsQueryQuery['patient']>)['appointments']>)['docs'][0]>);
export type PatientDetailQueryVariables = PatientDetailQueryQueryVariables;
export type PatientDetailQueryPatient = (NonNullable<PatientDetailQueryQuery['patient']>);
export type UpdatePatientVariables = UpdatePatientMutationVariables;
export type UpdatePatientUpdatePatient = UpdatePatientMutation['updatePatient'];
export type UpdatePatientStatusVariables = UpdatePatientStatusMutationVariables;
export type UpdatePatientStatusUpdatePatientStatus = UpdatePatientStatusMutation['updatePatientStatus'];
export type RemovePatientVariables = RemovePatientMutationVariables;
export type RemovePatientRemovePatient = RemovePatientMutation['removePatient'];
export type PatientEventsQueryVariables = PatientEventsQueryQueryVariables;
export type PatientEventsQueryPatient = (NonNullable<PatientEventsQueryQuery['patient']>);
export type PatientEventsQueryEvents = (NonNullable<(NonNullable<PatientEventsQueryQuery['patient']>)['events']>);
export type PatientEventsQueryDocs = (NonNullable<(NonNullable<(NonNullable<PatientEventsQueryQuery['patient']>)['events']>)['docs'][0]>);
export type PatientInvoicesQueryVariables = PatientInvoicesQueryQueryVariables;
export type PatientInvoicesQueryPatient = (NonNullable<PatientInvoicesQueryQuery['patient']>);
export type PatientInvoicesQueryPayment = (NonNullable<PatientInvoicesQueryQuery['patient']>)['payment'];
export type PatientInvoicesQueryInvoices = (NonNullable<PatientInvoicesQueryQuery['patient']>)['invoices'];
export type PatientInvoicesQueryDocs = (NonNullable<(NonNullable<PatientInvoicesQueryQuery['patient']>)['invoices']['docs'][0]>);
export type PatientListQueryVariables = PatientListQueryQueryVariables;
export type PatientListQueryPatients = (NonNullable<PatientListQueryQuery['patients']>);
export type PatientListQueryDocs = (NonNullable<(NonNullable<PatientListQueryQuery['patients']>)['docs'][0]>);
export type PatientListPaymentQueryVariables = PatientListPaymentQueryQueryVariables;
export type PatientListPaymentQueryPatients = (NonNullable<PatientListPaymentQueryQuery['patients']>);
export type PatientListPaymentQueryDocs = (NonNullable<(NonNullable<PatientListPaymentQueryQuery['patients']>)['docs'][0]>);
export type PatientOrdersQueryVariables = PatientOrdersQueryQueryVariables;
export type PatientOrdersQueryPatient = (NonNullable<PatientOrdersQueryQuery['patient']>);
export type PatientOrdersQueryOrders = (NonNullable<(NonNullable<PatientOrdersQueryQuery['patient']>)['orders']>);
export type PatientOrdersQueryDocs = (NonNullable<(NonNullable<(NonNullable<PatientOrdersQueryQuery['patient']>)['orders']>)['docs'][0]>);
export type DeleteCheckPointVariables = DeleteCheckPointMutationVariables;
export type DeleteCheckPointDeleteCheckPoint = DeleteCheckPointMutation['deleteCheckPoint'];
export type UpdateCheckPointVariables = UpdateCheckPointMutationVariables;
export type UpdateCheckPointUpdateCheckPoint = UpdateCheckPointMutation['updateCheckPoint'];
export type CheckPointCardFiles = (NonNullable<CheckPointCardFragment['files']>);
export type CheckPointCardTeeth = (NonNullable<(NonNullable<(NonNullable<CheckPointCardFragment['files']>)['teeth']>)[0]>);
export type CheckPointCardFile = (NonNullable<(NonNullable<(NonNullable<CheckPointCardFragment['files']>)['teeth']>)[0]>)['file'];
export type CheckPointCardUpperJawModel = (NonNullable<(NonNullable<CheckPointCardFragment['files']>)['upperJawModel']>);
export type CheckPointCardLowerJawModel = (NonNullable<(NonNullable<CheckPointCardFragment['files']>)['lowerJawModel']>);
export type CheckPointGroupDesignStages = (NonNullable<CheckPointGroupFragment['designStages'][0]>);
export type ClearStepsVariables = ClearStepsMutationVariables;
export type ClearStepsUpdateAnalysis = ClearStepsMutation['updateAnalysis'];
export type PredesignCollapseAnalysis = (NonNullable<PredesignCollapseFragment['analysis']>);
export type PredesignCollapseSteps = (NonNullable<(NonNullable<(NonNullable<PredesignCollapseFragment['analysis']>)['steps']>)[0]>);
export type StepCardStep = StepCardFragment['step'];
export type StepCardInstructionCard = (NonNullable<StepCardFragment['instructionCard']>);
export type PatientReportEditVariables = PatientReportEditQueryVariables;
export type PatientReportEditEvalStage = PatientReportEditQuery['evalStage'];
export type PatientReportEditAnalysis = (NonNullable<PatientReportEditQuery['evalStage']['analysis']>);
export type PatientReportEditStepsOverview = (NonNullable<PatientReportEditQuery['evalStage']['stepsOverview']>);
export type PatientReportEditCheckPoints = (NonNullable<PatientReportEditQuery['evalStage']['checkPoints'][0]>);
export type PatientReportEditPatient = PatientReportEditQuery['evalStage']['patient'];
export type PatientReportEditStages = (NonNullable<PatientReportEditQuery['evalStage']['patient']['stages']>);
export type PatientReportEditDocs = (NonNullable<(NonNullable<PatientReportEditQuery['evalStage']['patient']['stages']>)['docs'][0]>);
export type PatientReportEditPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<PatientReportEditQuery['evalStage']['patient']['stages']>)['docs'][0]>), '__typename'>, { __typename: 'PrintStage' }>);
export type CreateCheckPointVariables = CreateCheckPointMutationVariables;
export type CreateCheckPointCreateCheckPoint = CreateCheckPointMutation['createCheckPoint'];
export type UpdateAnalysisVariables = UpdateAnalysisMutationVariables;
export type UpdateAnalysisUpdateAnalysis = UpdateAnalysisMutation['updateAnalysis'];
export type UploadStepsOverViewVariables = UploadStepsOverViewMutationVariables;
export type UploadStepsOverViewUpdateEvalStage = UploadStepsOverViewMutation['updateEvalStage'];
export type PatientStageIndexQueryVariables = PatientStageIndexQueryQueryVariables;
export type PatientStageIndexQueryPatient = (NonNullable<PatientStageIndexQueryQuery['patient']>);
export type PatientStageIndexQueryStages = (NonNullable<(NonNullable<PatientStageIndexQueryQuery['patient']>)['stages']>);
export type PatientStageIndexQueryDocs = (NonNullable<(NonNullable<(NonNullable<PatientStageIndexQueryQuery['patient']>)['stages']>)['docs'][0]>);
export type PatientTracksQueryVariables = PatientTracksQueryQueryVariables;
export type PatientTracksQueryPatient = (NonNullable<PatientTracksQueryQuery['patient']>);
export type PatientTracksQueryTracks = (NonNullable<(NonNullable<PatientTracksQueryQuery['patient']>)['tracks']>);
export type PatientTracksQueryDocs = (NonNullable<(NonNullable<(NonNullable<PatientTracksQueryQuery['patient']>)['tracks']>)['docs'][0]>);
export type PatientTransferTableClinic = PatientTransferTableFragment['clinic'];
export type PatientTransferTableDoctor = PatientTransferTableFragment['doctor'];
export type PatientTransferTableAccountManager = (NonNullable<PatientTransferTableFragment['accountManager']>);
export type PatientTransferTableSales = PatientTransferTableFragment['sales'];
export type PatientTransferTableTechnician = (NonNullable<PatientTransferTableFragment['technician']>);
export type PatientTransferTableCustomerService = (NonNullable<PatientTransferTableFragment['customerService']>);
export type PatientTransferListQueryVariables = PatientTransferListQueryQueryVariables;
export type PatientTransferListQueryPatients = (NonNullable<PatientTransferListQueryQuery['patients']>);
export type PatientTransferListQueryDocs = (NonNullable<(NonNullable<PatientTransferListQueryQuery['patients']>)['docs'][0]>);
export type PatientTransferVariables = PatientTransferMutationVariables;
export type PatientTransferTransferPatients = (NonNullable<PatientTransferMutation['transferPatients'][0]>);
export type CheckPointOverviewCheckPoints = (NonNullable<CheckPointOverviewFragment['checkPoints'][0]>);
export type CheckPointOverviewPatientItemStages = (NonNullable<CheckPointOverviewPatientItemFragment['stages']>);
export type CheckPointOverviewPatientItemDocs = (NonNullable<(NonNullable<CheckPointOverviewPatientItemFragment['stages']>)['docs'][0]>);
export type CheckPointOverviewPatientItemPrintStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<CheckPointOverviewPatientItemFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'PrintStage' }>);
export type ContentCheckPointFiles = (NonNullable<ContentCheckPointFragment['files']>);
export type ContentCheckPointTeeth = (NonNullable<(NonNullable<(NonNullable<ContentCheckPointFragment['files']>)['teeth']>)[0]>);
export type ContentCheckPointFile = (NonNullable<(NonNullable<(NonNullable<ContentCheckPointFragment['files']>)['teeth']>)[0]>)['file'];
export type ContentCheckPointUpperJawModel = (NonNullable<(NonNullable<ContentCheckPointFragment['files']>)['upperJawModel']>);
export type ContentCheckPointLowerJawModel = (NonNullable<(NonNullable<ContentCheckPointFragment['files']>)['lowerJawModel']>);
export type ContentDesignStageDropStageVariables = ContentDesignStageDropStageMutationVariables;
export type ContentDesignStageDropStageDropStage = ContentDesignStageDropStageMutation['dropStage'];
export type CreateDesignInstructionCardVariables = CreateDesignInstructionCardMutationVariables;
export type CreateDesignInstructionCardCreateInstructionCard = CreateDesignInstructionCardMutation['createInstructionCard'];
export type UpdateDesignStageInTreatmentVariables = UpdateDesignStageInTreatmentMutationVariables;
export type UpdateDesignStageInTreatmentUpdateDesignStage = UpdateDesignStageInTreatmentMutation['updateDesignStage'];
export type ContentDesignStageInstructionCard = (NonNullable<ContentDesignStageFragment['instructionCard']>);
export type ContentDesignStageStep = ContentDesignStageFragment['step'];
export type ContentDesignStageFiles = (NonNullable<ContentDesignStageFragment['files']>);
export type ContentDesignStageTeeth = (NonNullable<(NonNullable<(NonNullable<ContentDesignStageFragment['files']>)['teeth']>)[0]>);
export type ContentDesignStageFile = (NonNullable<(NonNullable<(NonNullable<ContentDesignStageFragment['files']>)['teeth']>)[0]>)['file'];
export type ContentDesignStageUpperJawModel = (NonNullable<(NonNullable<ContentDesignStageFragment['files']>)['upperJawModel']>);
export type ContentDesignStageLowerJawModel = (NonNullable<(NonNullable<ContentDesignStageFragment['files']>)['lowerJawModel']>);
export type BatchCreateDesignStageVariables = BatchCreateDesignStageMutationVariables;
export type BatchCreateDesignStageCreateDesignStage = BatchCreateDesignStageMutation['createDesignStage'];
export type ModalBatchCreateDesignCurrentEvalStage = (NonNullable<ModalBatchCreateDesignFragment['currentEvalStage']>);
export type ModalBatchCreateDesignCheckPoints = (NonNullable<(NonNullable<ModalBatchCreateDesignFragment['currentEvalStage']>)['checkPoints'][0]>);
export type ModalBatchCreateDesignDesignStages = (NonNullable<ModalBatchCreateDesignFragment['designStages']>);
export type ModalBatchCreateDesignDocs = (NonNullable<(NonNullable<ModalBatchCreateDesignFragment['designStages']>)['docs'][0]>);
export type ModalBatchCreateDesignDesignStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ModalBatchCreateDesignFragment['designStages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>);
export type BatchUpdateDesignStageVariables = BatchUpdateDesignStageMutationVariables;
export type BatchUpdateDesignStageUpdateDesignStage = BatchUpdateDesignStageMutation['updateDesignStage'];
export type ModalBatchEditDesignStartedDesignStages = (NonNullable<ModalBatchEditDesignFragment['startedDesignStages']>);
export type ModalBatchEditDesignDocs = (NonNullable<(NonNullable<ModalBatchEditDesignFragment['startedDesignStages']>)['docs'][0]>);
export type ModalBatchEditDesignDesignStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ModalBatchEditDesignFragment['startedDesignStages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>);
export type PanelCheckPointDesignStages = (NonNullable<PanelCheckPointFragment['designStages'][0]>);
export type ForwardCurrentTaskVariables = ForwardCurrentTaskMutationVariables;
export type ForwardCurrentTaskForwardTask = ForwardCurrentTaskMutation['forwardTask'];
export type BackwardCurrentTaskVariables = BackwardCurrentTaskMutationVariables;
export type BackwardCurrentTaskBackwardTask = BackwardCurrentTaskMutation['backwardTask'];
export type TagCurrentTaskStageItemCurrentTask = (NonNullable<TagCurrentTaskStageItemFragment['currentTask']>);
export type TagCurrentTaskStageItemOwner = (NonNullable<(NonNullable<TagCurrentTaskStageItemFragment['currentTask']>)['owner']>);
export type TagCurrentTaskStageItemNextTask = (NonNullable<(NonNullable<TagCurrentTaskStageItemFragment['currentTask']>)['nextTask']>);
export type TagCurrentTaskStageItemTasks = (NonNullable<TagCurrentTaskStageItemFragment['tasks'][0]>);
export type UpdateDesignExpectedShippingDateVariables = UpdateDesignExpectedShippingDateMutationVariables;
export type UpdateDesignExpectedShippingDateUpdateDesignStage = UpdateDesignExpectedShippingDateMutation['updateDesignStage'];
export type PatientTreatmentVariables = PatientTreatmentQueryVariables;
export type PatientTreatmentPatient = (NonNullable<PatientTreatmentQuery['patient']>);
export type PatientTreatmentCurrentEvalStage = (NonNullable<(NonNullable<PatientTreatmentQuery['patient']>)['currentEvalStage']>);
export type PatientTreatmentAnalysis = (NonNullable<(NonNullable<(NonNullable<PatientTreatmentQuery['patient']>)['currentEvalStage']>)['analysis']>);
export type PatientTreatmentStepsOverview = (NonNullable<(NonNullable<(NonNullable<PatientTreatmentQuery['patient']>)['currentEvalStage']>)['stepsOverview']>);
export type PatientTreatmentCheckPoints = (NonNullable<(NonNullable<(NonNullable<PatientTreatmentQuery['patient']>)['currentEvalStage']>)['checkPoints'][0]>);
export type ClinicReportClinicItemLocation = ClinicReportClinicItemFragment['location'];
export type ClinicReportInvoiceItemDoctor = ClinicReportInvoiceItemFragment['doctor'];
export type ClinicReportInvoiceItemSales = ClinicReportInvoiceItemFragment['sales'];
export type ClinicReportInvoiceItemProducts = (NonNullable<ClinicReportInvoiceItemFragment['products'][0]>);
export type ClinicReportInvoiceItemInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<ClinicReportInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type ClinicReportInvoiceItemPatient = (DiscriminateUnion<RequireField<ClinicReportInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient'];
export type ClinicReportInvoiceItemStage = (DiscriminateUnion<RequireField<ClinicReportInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'];
export type ReportInvoiceClinicQueryVariables = ReportInvoiceClinicQueryQueryVariables;
export type ReportInvoiceClinicQueryClinic = ReportInvoiceClinicQueryQuery['clinic'];
export type CustomerServiceReportInvoiceItemDoctor = CustomerServiceReportInvoiceItemFragment['doctor'];
export type CustomerServiceReportInvoiceItemClinic = CustomerServiceReportInvoiceItemFragment['clinic'];
export type CustomerServiceReportInvoiceItemInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<CustomerServiceReportInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type CustomerServiceReportInvoiceItemPatient = (DiscriminateUnion<RequireField<CustomerServiceReportInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient'];
export type CustomerServiceReportInvoiceItemStage = (DiscriminateUnion<RequireField<CustomerServiceReportInvoiceItemFragment, '__typename'>, { __typename: 'InvoiceWithStage' }>)['stage'];
export type ReportInvoiceCustomerServiceQueryVariables = ReportInvoiceCustomerServiceQueryQueryVariables;
export type ReportInvoiceCustomerServiceQueryEmployee = ReportInvoiceCustomerServiceQueryQuery['employee'];
export type ReportInvoiceQueryVariables = ReportInvoiceQueryQueryVariables;
export type ReportInvoiceQueryInvoices = ReportInvoiceQueryQuery['invoices'];
export type ReportInvoiceQueryDocs = (NonNullable<ReportInvoiceQueryQuery['invoices']['docs'][0]>);
export type ReportInvoiceQueryInvoiceWithStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<ReportInvoiceQueryQuery['invoices']['docs'][0]>), '__typename'>, { __typename: 'InvoiceWithStage' }>);
export type ReportInvoiceQueryPatient = (DiscriminateUnion<RequireField<(NonNullable<ReportInvoiceQueryQuery['invoices']['docs'][0]>), '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient'];
export type ReportInvoiceQueryPayment = (DiscriminateUnion<RequireField<(NonNullable<ReportInvoiceQueryQuery['invoices']['docs'][0]>), '__typename'>, { __typename: 'InvoiceWithStage' }>)['patient']['payment'];
export type MoldStagesTrackQueryVariables = MoldStagesTrackQueryQueryVariables;
export type MoldStagesTrackQueryMoldStagesTrack = MoldStagesTrackQueryQuery['moldStagesTrack'];
export type MoldStagesTrackQueryDocs = (NonNullable<MoldStagesTrackQueryQuery['moldStagesTrack']['docs'][0]>);
export type StageAnalysisPreviewVariables = StageAnalysisPreviewQueryVariables;
export type StageAnalysisPreviewEvalStage = StageAnalysisPreviewQuery['evalStage'];
export type StageAnalysisPreviewPatient = StageAnalysisPreviewQuery['evalStage']['patient'];
export type StageCalendarQueryVariables = StageCalendarQueryQueryVariables;
export type StageCalendarQueryStages = StageCalendarQueryQuery['stages'];
export type StageCalendarQueryDocs = (NonNullable<StageCalendarQueryQuery['stages']['docs'][0]>);
export type StageCalendarQueryPatient = (NonNullable<StageCalendarQueryQuery['stages']['docs'][0]>)['patient'];
export type StageCalendarQueryClinic = (NonNullable<StageCalendarQueryQuery['stages']['docs'][0]>)['patient']['clinic'];
export type StageCalendarQueryTechnician = (NonNullable<(NonNullable<StageCalendarQueryQuery['stages']['docs'][0]>)['patient']['technician']>);
export type StageCalendarQueryCurrentTask = (NonNullable<(NonNullable<StageCalendarQueryQuery['stages']['docs'][0]>)['currentTask']>);
export type CalendarStagesQueryVariables = CalendarStagesQueryQueryVariables;
export type CalendarStagesQueryExpectedShippingStages = (NonNullable<CalendarStagesQueryQuery['expectedShippingStages'][0]>);
export type CalendarStagesQueryAccessoryStages = (NonNullable<(NonNullable<CalendarStagesQueryQuery['expectedShippingStages'][0]>)['accessoryStages'][0]>);
export type CalendarStagesQueryPrintStages = (NonNullable<(NonNullable<CalendarStagesQueryQuery['expectedShippingStages'][0]>)['printStages'][0]>);
export type CalendarStagesQueryEvalStages = (NonNullable<(NonNullable<CalendarStagesQueryQuery['expectedShippingStages'][0]>)['evalStages'][0]>);
export type CalendarStagePatient = CalendarStageFragment['patient'];
export type CalendarStageClinic = CalendarStageFragment['patient']['clinic'];
export type CalendarStageSales = CalendarStageFragment['patient']['sales'];
export type CalendarStageCurrentTask = (NonNullable<CalendarStageFragment['currentTask']>);
export type StageCardQueryVariables = StageCardQueryQueryVariables;
export type StageCardQueryStage = StageCardQueryQuery['stage'];
export type StageCardQueryPatient = StageCardQueryQuery['stage']['patient'];
export type StageCardQueryIntraOral = StageCardQueryQuery['stage']['patient']['intraOral'];
export type StageCardQueryTeethStatus = (NonNullable<StageCardQueryQuery['stage']['patient']['intraOral']['teethStatus'][0]>);
export type StageCardQueryDesignStageInlineFragment = (DiscriminateUnion<RequireField<StageCardQueryQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>);
export type StageCardQueryInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<StageCardQueryQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>)['instructionCard']>);
export type StageCardQueryAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageCardQueryQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type StageCardQuery_InstructionCard = (NonNullable<(DiscriminateUnion<RequireField<StageCardQueryQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>)['instructionCard']>);
export type UpdateStageInstructionVariables = UpdateStageInstructionMutationVariables;
export type UpdateStageInstructionUpdateInstructionCard = UpdateStageInstructionMutation['updateInstructionCard'];
export type BraceAuditPatient = BraceAuditFragment['patient'];
export type BraceAuditClinic = BraceAuditFragment['patient']['clinic'];
export type BraceAuditNote = BraceAuditFragment['patient']['note'];
export type BraceAuditIntraOral = BraceAuditFragment['patient']['intraOral'];
export type BraceAuditTeethStatus = (NonNullable<BraceAuditFragment['patient']['intraOral']['teethStatus'][0]>);
export type BraceAuditAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type BraceAuditPrintStageInlineFragment = (DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type BraceAuditDesignStage = (NonNullable<(DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>);
export type BraceAuditTasks = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['tasks'][0]>);
export type BraceAuditOwner = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['tasks'][0]>)['owner']>);
export type BraceAuditStep = (NonNullable<(DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['step'];
export type BraceAuditInstructionCard = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<BraceAuditFragment, '__typename'>, { __typename: 'PrintStage' }>)['designStage']>)['instructionCard']>);
export type OrderInfoStageOrder = (NonNullable<OrderInfoFragment['stageOrder'][0]>);
export type OrderInfoCreator = OrderInfoFragment['creator'];
export type OrderInfoAppointment = (NonNullable<OrderInfoFragment['appointment']>);
export type StageOrderInfoVariables = StageOrderInfoQueryVariables;
export type StageOrderInfoStage = StageOrderInfoQuery['stage'];
export type StageOrderInfoEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>);
export type StageOrderInfoOrder = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>)['order']>);
export type StageOrderInfoPrintStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type StageOrderInfo_Order = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'PrintStage' }>)['order']>);
export type StageOrderInfoAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type StageOrderInfo__Order = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>)['order']>);
export type StageOrderInfoDesignStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>);
export type StageOrderInfoLatestPrintStage = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>)['latestPrintStage']>);
export type StageOrderInfo___Order = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>)['latestPrintStage']>)['order']>);
export type StageOrderInfoMoldStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'MoldStage' }>);
export type StageOrderInfoLatestEvalStage = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'MoldStage' }>)['latestEvalStage']>);
export type StageOrderInfo____Order = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<StageOrderInfoQuery['stage'], '__typename'>, { __typename: 'MoldStage' }>)['latestEvalStage']>)['order']>);
export type StageOrderInstructionVariables = StageOrderInstructionQueryVariables;
export type StageOrderInstructionStage = StageOrderInstructionQuery['stage'];
export type StageOrderInstructionEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>);
export type StageOrderInstructionOrder = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>)['order']>);
export type StageOrderInstructionPrintStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'PrintStage' }>);
export type StageOrderInstruction_Order = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'PrintStage' }>)['order']>);
export type StageOrderInstructionAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>);
export type StageOrderInstruction__Order = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'AccessoryStage' }>)['order']>);
export type StageOrderInstructionDesignStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>);
export type StageOrderInstructionLatestPrintStage = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>)['latestPrintStage']>);
export type StageOrderInstruction___Order = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'DesignStage' }>)['latestPrintStage']>)['order']>);
export type StageOrderInstructionMoldStageInlineFragment = (DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'MoldStage' }>);
export type StageOrderInstructionLatestEvalStage = (NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'MoldStage' }>)['latestEvalStage']>);
export type StageOrderInstruction____Order = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<StageOrderInstructionQuery['stage'], '__typename'>, { __typename: 'MoldStage' }>)['latestEvalStage']>)['order']>);
export type StagePatientInfoVariables = StagePatientInfoQueryVariables;
export type StagePatientInfoPatient = (NonNullable<StagePatientInfoQuery['patient']>);
export type StagePatientInfoDoctor = (NonNullable<StagePatientInfoQuery['patient']>)['doctor'];
export type StagePatientInfoDoctorInstruction = (NonNullable<StagePatientInfoQuery['patient']>)['doctorInstruction'];
export type StagePatientInfoMaintainSpace = (NonNullable<StagePatientInfoQuery['patient']>)['doctorInstruction']['maintainSpace'];
export type StagePatientInfoChangeProsthesisToTemp = (NonNullable<StagePatientInfoQuery['patient']>)['doctorInstruction']['changeProsthesisToTemp'];
export type StagePatientInfoExtractToothWhenNoSpace = (NonNullable<StagePatientInfoQuery['patient']>)['doctorInstruction']['extractToothWhenNoSpace'];
export type StagePatientInfoNote = (NonNullable<StagePatientInfoQuery['patient']>)['note'];
export type UpdateDoctorNoteVariables = UpdateDoctorNoteMutationVariables;
export type UpdateDoctorNoteUpdateDoctor = UpdateDoctorNoteMutation['updateDoctor'];
export type UpdatePatientInfoVariables = UpdatePatientInfoMutationVariables;
export type UpdatePatientInfoUpdatePatient = UpdatePatientInfoMutation['updatePatient'];
export type StartedEvalStagesVariables = StartedEvalStagesQueryVariables;
export type StartedEvalStagesEvalStages = StartedEvalStagesQuery['evalStages'];
export type StartedEvalStagesDocs = (NonNullable<StartedEvalStagesQuery['evalStages']['docs'][0]>);
export type NextTaskAssigneeWorkingTaskStatisticVariables = NextTaskAssigneeWorkingTaskStatisticQueryVariables;
export type NextTaskAssigneeWorkingTaskStatisticWorkingTaskStatistic = (NonNullable<NextTaskAssigneeWorkingTaskStatisticQuery['workingTaskStatistic'][0]>);
export type NextTaskAssigneeWorkingTaskStatisticOwner = (NonNullable<NextTaskAssigneeWorkingTaskStatisticQuery['workingTaskStatistic'][0]>)['owner'];
export type StageTasksQueryVariables = StageTasksQueryQueryVariables;
export type StageTasksQueryStage = StageTasksQueryQuery['stage'];
export type StageTasksQueryCurrentTask = (NonNullable<StageTasksQueryQuery['stage']['currentTask']>);
export type StageTasksQueryPatient = (NonNullable<StageTasksQueryQuery['stage']['currentTask']>)['patient'];
export type StageTasksQueryNextTask = (NonNullable<(NonNullable<StageTasksQueryQuery['stage']['currentTask']>)['nextTask']>);
export type StageTasksQueryTasks = (NonNullable<StageTasksQueryQuery['stage']['tasks'][0]>);
export type StageTasksQueryOwner = (NonNullable<(NonNullable<StageTasksQueryQuery['stage']['tasks'][0]>)['owner']>);
export type ForwardTaskVariables = ForwardTaskMutationVariables;
export type ForwardTaskForwardTask = ForwardTaskMutation['forwardTask'];
export type BackwardTaskVariables = BackwardTaskMutationVariables;
export type BackwardTaskBackwardTask = BackwardTaskMutation['backwardTask'];
export type StageQueryVariables = StageQueryQueryVariables;
export type StageQueryStage = StageQueryQuery['stage'];
export type StageQueryEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageQueryQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>);
export type StageQueryInitialEvalStage = (NonNullable<(DiscriminateUnion<RequireField<StageQueryQuery['stage'], '__typename'>, { __typename: 'EvalStage' }>)['initialEvalStage']>);
export type StageQueryPatient = StageQueryQuery['stage']['patient'];
export type StageQueryCurrentEvalStage = (NonNullable<StageQueryQuery['stage']['patient']['currentEvalStage']>);
export type CreateInstructionCardVariables = CreateInstructionCardMutationVariables;
export type CreateInstructionCardCreateInstructionCard = CreateInstructionCardMutation['createInstructionCard'];
export type UpdateMoldStageVariables = UpdateMoldStageMutationVariables;
export type UpdateMoldStageUpdateMoldStage = UpdateMoldStageMutation['updateMoldStage'];
export type UpdateMoldStagePatient = UpdateMoldStageMutation['updateMoldStage']['patient'];
export type UpdateMoldStageCurrentEvalStage = (NonNullable<UpdateMoldStageMutation['updateMoldStage']['patient']['currentEvalStage']>);
export type UpdateEvalStageVariables = UpdateEvalStageMutationVariables;
export type UpdateEvalStageUpdateEvalStage = UpdateEvalStageMutation['updateEvalStage'];
export type UpdateEvalStagePatient = UpdateEvalStageMutation['updateEvalStage']['patient'];
export type UpdateEvalStageCurrentEvalStage = (NonNullable<UpdateEvalStageMutation['updateEvalStage']['patient']['currentEvalStage']>);
export type UpdateDesignStageVariables = UpdateDesignStageMutationVariables;
export type UpdateDesignStageUpdateDesignStage = UpdateDesignStageMutation['updateDesignStage'];
export type UpdateDesignStagePatient = UpdateDesignStageMutation['updateDesignStage']['patient'];
export type UpdateDesignStageCurrentEvalStage = (NonNullable<UpdateDesignStageMutation['updateDesignStage']['patient']['currentEvalStage']>);
export type UpdatePrintStageVariables = UpdatePrintStageMutationVariables;
export type UpdatePrintStageUpdatePrintStage = UpdatePrintStageMutation['updatePrintStage'];
export type UpdatePrintStagePatient = UpdatePrintStageMutation['updatePrintStage']['patient'];
export type UpdatePrintStageCurrentEvalStage = (NonNullable<UpdatePrintStageMutation['updatePrintStage']['patient']['currentEvalStage']>);
export type UpdateAccessoryStageVariables = UpdateAccessoryStageMutationVariables;
export type UpdateAccessoryStageUpdateAccessoryStage = UpdateAccessoryStageMutation['updateAccessoryStage'];
export type UpdateAccessoryStagePatient = UpdateAccessoryStageMutation['updateAccessoryStage']['patient'];
export type UpdateAccessoryStageCurrentEvalStage = (NonNullable<UpdateAccessoryStageMutation['updateAccessoryStage']['patient']['currentEvalStage']>);
export type StageListVariables = StageListQueryVariables;
export type StageListStages = StageListQuery['stages'];
export type StageListDocs = (NonNullable<StageListQuery['stages']['docs'][0]>);
export type StatisticEmployeeTaskQueryVariables = StatisticEmployeeTaskQueryQueryVariables;
export type StatisticEmployeeTaskQueryEmployeeTaskStatistic = (NonNullable<StatisticEmployeeTaskQueryQuery['employeeTaskStatistic'][0]>);
export type StatisticEmployeeTaskQueryEmployee = (NonNullable<StatisticEmployeeTaskQueryQuery['employeeTaskStatistic'][0]>)['employee'];
export type StatisticEmployeeTaskQueryEmployees = StatisticEmployeeTaskQueryQuery['employees'];
export type StatisticEmployeeTaskQueryDocs = (NonNullable<StatisticEmployeeTaskQueryQuery['employees']['docs'][0]>);
export type StatisticInvoiceQueryVariables = StatisticInvoiceQueryQueryVariables;
export type StatisticInvoiceQueryInvoiceStatistic = (NonNullable<StatisticInvoiceQueryQuery['invoiceStatistic'][0]>);
export type StatisticInvoiceQuerySegment = (NonNullable<StatisticInvoiceQueryQuery['invoiceStatistic'][0]>)['segment'];
export type StatisticInvoiceQueryIndicators = (NonNullable<(NonNullable<(NonNullable<StatisticInvoiceQueryQuery['invoiceStatistic'][0]>)['indicators']>)[0]>);
export type FinishedPatientListQueryVariables = FinishedPatientListQueryQueryVariables;
export type FinishedPatientListQueryPatients = (NonNullable<FinishedPatientListQueryQuery['patients']>);
export type FinishedPatientListQueryDocs = (NonNullable<(NonNullable<FinishedPatientListQueryQuery['patients']>)['docs'][0]>);
export type FinishedPatientListQueryTreatArches = (NonNullable<(NonNullable<FinishedPatientListQueryQuery['patients']>)['docs'][0]>)['treatArches'];
export type FinishedPatientListQueryAccountManager = (NonNullable<(NonNullable<(NonNullable<FinishedPatientListQueryQuery['patients']>)['docs'][0]>)['accountManager']>);
export type FinishedPatientListQueryTechnician = (NonNullable<(NonNullable<(NonNullable<FinishedPatientListQueryQuery['patients']>)['docs'][0]>)['technician']>);
export type FinishedPatientListQueryPayment = (NonNullable<(NonNullable<FinishedPatientListQueryQuery['patients']>)['docs'][0]>)['payment'];
export type FinishedPatientListQueryMeta = (NonNullable<(NonNullable<FinishedPatientListQueryQuery['patients']>)['docs'][0]>)['meta'];
export type StatisticPatientQueryVariables = StatisticPatientQueryQueryVariables;
export type StatisticPatientQueryPatientStatistic = (NonNullable<StatisticPatientQueryQuery['patientStatistic'][0]>);
export type StatisticPatientQuerySegment = (NonNullable<StatisticPatientQueryQuery['patientStatistic'][0]>)['segment'];
export type StatisticPatientQueryIndicators = (NonNullable<(NonNullable<(NonNullable<StatisticPatientQueryQuery['patientStatistic'][0]>)['indicators']>)[0]>);
export type StatisticStageQueryVariables = StatisticStageQueryQueryVariables;
export type StatisticStageQueryStageCapacity = (NonNullable<StatisticStageQueryQuery['stageCapacity'][0]>);
export type StatisticQueryVariables = StatisticQueryQueryVariables;
export type StatisticQueryStatistics = (NonNullable<StatisticQueryQuery['statistics'][0]>);
export type StatisticQueryIndicators = (NonNullable<(NonNullable<StatisticQueryQuery['statistics'][0]>)['indicators'][0]>);
export type MinimumRequiredAppVersionVariables = MinimumRequiredAppVersionQueryVariables;
export type UpdateMinimumRequiredAppVersionSystemVariables = UpdateMinimumRequiredAppVersionSystemMutationVariables;
export type UpdateMinimumRequiredAppVersionSystemUpdateMinimumRequiredAppVersionSystem = UpdateMinimumRequiredAppVersionSystemMutation['updateMinimumRequiredAppVersionSystem'];
export type UpdateSystemEntityTypeToPrivilegesListVariables = UpdateSystemEntityTypeToPrivilegesListMutationVariables;
export type UpdateSystemEntityTypeToPrivilegesListUpdateSystemEntityTypeToPrivilegesList = UpdateSystemEntityTypeToPrivilegesListMutation['updateSystemEntityTypeToPrivilegesList'];
export type UpdateSystemRoleToPrivilegesListVariables = UpdateSystemRoleToPrivilegesListMutationVariables;
export type UpdateSystemRoleToPrivilegesListUpdateSystemRoleToPrivilegesList = UpdateSystemRoleToPrivilegesListMutation['updateSystemRoleToPrivilegesList'];
export type MultipleSystemVariables = MultipleSystemQueryVariables;
export type MultipleSystemRoleToPrivilegesListSystem = MultipleSystemQuery['roleToPrivilegesListSystem'];
export type MultipleSystemRoleToPrivilegesListSystemInlineFragment = (DiscriminateUnion<RequireField<MultipleSystemQuery['roleToPrivilegesListSystem'], '__typename'>, { __typename: 'RoleToPrivilegesListSystem' }>);
export type MultipleSystemRoleToPrivilegesList = (NonNullable<(DiscriminateUnion<RequireField<MultipleSystemQuery['roleToPrivilegesListSystem'], '__typename'>, { __typename: 'RoleToPrivilegesListSystem' }>)['roleToPrivilegesList'][0]>);
export type MultipleSystemEntityTypeToPrivilegesListSystem = MultipleSystemQuery['entityTypeToPrivilegesListSystem'];
export type MultipleSystemEntityTypeToPrivilegesListSystemInlineFragment = (DiscriminateUnion<RequireField<MultipleSystemQuery['entityTypeToPrivilegesListSystem'], '__typename'>, { __typename: 'EntityTypeToPrivilegesListSystem' }>);
export type MultipleSystemEntityTypeToPrivilegesList = (NonNullable<(DiscriminateUnion<RequireField<MultipleSystemQuery['entityTypeToPrivilegesListSystem'], '__typename'>, { __typename: 'EntityTypeToPrivilegesListSystem' }>)['entityTypeToPrivilegesList'][0]>);
export type ExportPatientsVariables = ExportPatientsMutationVariables;
export type ExportPatientsExportPatients = ExportPatientsMutation['exportPatients'];
export type ExportInvoicesVariables = ExportInvoicesMutationVariables;
export type ExportInvoicesExportInvoices = ExportInvoicesMutation['exportInvoices'];
export type SystemStageCapacityQueryVariables = SystemStageCapacityQueryQueryVariables;
export type SystemStageCapacityQuerySystem = SystemStageCapacityQueryQuery['system'];
export type SystemStageCapacityQueryStageCapacitySystemInlineFragment = (DiscriminateUnion<RequireField<SystemStageCapacityQueryQuery['system'], '__typename'>, { __typename: 'StageCapacitySystem' }>);
export type SystemStageCapacityQueryStageCapacity = (DiscriminateUnion<RequireField<SystemStageCapacityQueryQuery['system'], '__typename'>, { __typename: 'StageCapacitySystem' }>)['stageCapacity'];
export type SystemStageCapacityQueryCapacityPerType = (NonNullable<(DiscriminateUnion<RequireField<SystemStageCapacityQueryQuery['system'], '__typename'>, { __typename: 'StageCapacitySystem' }>)['stageCapacity']['capacityPerType'][0]>);
export type UpdateSystemMaxStageCapacityVariables = UpdateSystemMaxStageCapacityMutationVariables;
export type UpdateSystemMaxStageCapacityUpdateSystemMaxStageCapacity = UpdateSystemMaxStageCapacityMutation['updateSystemMaxStageCapacity'];
export type SystemStageCostQueryVariables = SystemStageCostQueryQueryVariables;
export type SystemStageCostQuerySystem = SystemStageCostQueryQuery['system'];
export type SystemStageCostQueryStageCostSystemInlineFragment = (DiscriminateUnion<RequireField<SystemStageCostQueryQuery['system'], '__typename'>, { __typename: 'StageCostSystem' }>);
export type SystemStageCostQueryStageCost = (NonNullable<(DiscriminateUnion<RequireField<SystemStageCostQueryQuery['system'], '__typename'>, { __typename: 'StageCostSystem' }>)['stageCost'][0]>);
export type UpdateSystemStageCostVariables = UpdateSystemStageCostMutationVariables;
export type UpdateSystemStageCostUpdateSystemStageCost = UpdateSystemStageCostMutation['updateSystemStageCost'];
export type TasksQueryVariables = TasksQueryQueryVariables;
export type TasksQueryTasks = (NonNullable<TasksQueryQuery['tasks']>);
export type TasksQueryDocs = (NonNullable<(NonNullable<TasksQueryQuery['tasks']>)['docs'][0]>);
export type TracksQueryVariables = TracksQueryQueryVariables;
export type TracksQueryTracks = (NonNullable<TracksQueryQuery['tracks']>);
export type TracksQueryDocs = (NonNullable<(NonNullable<TracksQueryQuery['tracks']>)['docs'][0]>);
export type StageModelQueryVariables = StageModelQueryQueryVariables;
export type StageModelQueryStage = StageModelQueryQuery['stage'];
export type StageModelQueryPatient = StageModelQueryQuery['stage']['patient'];
export type PatientConversationQueryVariables = PatientConversationQueryQueryVariables;
export type PatientConversationQueryPatient = (NonNullable<PatientConversationQueryQuery['patient']>);
export type PatientConversationQueryConversation = (NonNullable<PatientConversationQueryQuery['patient']>)['conversation'];
export type ClinicInfoLocation = ClinicInfoFragment['location'];
export type ClinicInfoDoctors = (NonNullable<ClinicInfoFragment['doctors'][0]>);
export type ClinicInfoAccountManagers = (NonNullable<ClinicInfoFragment['accountManagers'][0]>);
export type ClinicInfoTechnicians = (NonNullable<ClinicInfoFragment['technicians'][0]>);
export type ClinicInfoSales = (NonNullable<ClinicInfoFragment['sales'][0]>);
export type ClinicInfoCustomerService = (NonNullable<ClinicInfoFragment['customerService']>);
export type CreateClinicVariables = CreateClinicMutationVariables;
export type CreateClinicCreateClinic = CreateClinicMutation['createClinic'];
export type RemoveClinicVariables = RemoveClinicMutationVariables;
export type RemoveClinicRemoveClinic = RemoveClinicMutation['removeClinic'];
export type UpdateClinicVariables = UpdateClinicMutationVariables;
export type UpdateClinicUpdateClinic = UpdateClinicMutation['updateClinic'];
export type ClinicsQueryVariables = ClinicsQueryQueryVariables;
export type ClinicsQueryClinics = ClinicsQueryQuery['clinics'];
export type ClinicsQueryDocs = (NonNullable<ClinicsQueryQuery['clinics']['docs'][0]>);
export type UpdateConversationReadMessageRecordOfMemberMutationVariables = UpdateConversationReadMessageRecordOfMemberMutationMutationVariables;
export type UpdateConversationReadMessageRecordOfMemberMutationUpdateConversationReadMessageRecordOfMember = UpdateConversationReadMessageRecordOfMemberMutationMutation['updateConversationReadMessageRecordOfMember'];
export type ConversationQueryVariables = ConversationQueryQueryVariables;
export type ConversationQueryConversation = ConversationQueryQuery['conversation'];
export type ConversationMessagesQueryVariables = ConversationMessagesQueryQueryVariables;
export type ConversationMessagesQueryConversationMessages = ConversationMessagesQueryQuery['conversationMessages'];
export type ConversationMessagesQueryEdges = (NonNullable<ConversationMessagesQueryQuery['conversationMessages']['edges'][0]>);
export type ConversationMessagesQueryNode = (NonNullable<ConversationMessagesQueryQuery['conversationMessages']['edges'][0]>)['node'];
export type ConversationMessagesQueryPageInfo = ConversationMessagesQueryQuery['conversationMessages']['pageInfo'];
export type NumberOfUnreadMessagesQueryVariables = NumberOfUnreadMessagesQueryQueryVariables;
export type ConversationsWithLatestMessageQueryVariables = ConversationsWithLatestMessageQueryQueryVariables;
export type ConversationsWithLatestMessageQueryConversationsWithLatestMessage = ConversationsWithLatestMessageQueryQuery['conversationsWithLatestMessage'];
export type ConversationsWithLatestMessageQueryEdges = (NonNullable<ConversationsWithLatestMessageQueryQuery['conversationsWithLatestMessage']['edges'][0]>);
export type ConversationsWithLatestMessageQueryNode = (NonNullable<ConversationsWithLatestMessageQueryQuery['conversationsWithLatestMessage']['edges'][0]>)['node'];
export type ConversationsWithLatestMessageQueryLatestMessage = (NonNullable<ConversationsWithLatestMessageQueryQuery['conversationsWithLatestMessage']['edges'][0]>)['node']['latestMessage'];
export type ConversationsWithLatestMessageQueryPatient = (NonNullable<ConversationsWithLatestMessageQueryQuery['conversationsWithLatestMessage']['edges'][0]>)['node']['patient'];
export type ConversationsWithLatestMessageQueryPageInfo = ConversationsWithLatestMessageQueryQuery['conversationsWithLatestMessage']['pageInfo'];
export type DoctorInfoClinics = (NonNullable<DoctorInfoFragment['clinics'][0]>);
export type DoctorInfoSales = (NonNullable<(NonNullable<DoctorInfoFragment['clinics'][0]>)['sales'][0]>);
export type DoctorInfoPatients = (NonNullable<DoctorInfoFragment['patients']>);
export type DoctorInfoDocs = (NonNullable<(NonNullable<DoctorInfoFragment['patients']>)['docs'][0]>);
export type CreateDoctorVariables = CreateDoctorMutationVariables;
export type CreateDoctorCreateDoctor = CreateDoctorMutation['createDoctor'];
export type RemoveDoctorVariables = RemoveDoctorMutationVariables;
export type RemoveDoctorRemoveDoctor = RemoveDoctorMutation['removeDoctor'];
export type UpdateDoctorVariables = UpdateDoctorMutationVariables;
export type UpdateDoctorUpdateDoctor = UpdateDoctorMutation['updateDoctor'];
export type EmployeeInfoApprovers = (NonNullable<EmployeeInfoFragment['approvers'][0]>);
export type EmployeeLeavesQueryVariables = EmployeeLeavesQueryQueryVariables;
export type EmployeeLeavesQueryEmployee = EmployeeLeavesQueryQuery['employee'];
export type EmployeeLeavesQueryLeaves = (NonNullable<EmployeeLeavesQueryQuery['employee']['leaves']>);
export type EmployeeLeavesQueryDocs = (NonNullable<(NonNullable<EmployeeLeavesQueryQuery['employee']['leaves']>)['docs'][0]>);
export type EmployeeLeavesQueryLeaveStatus = EmployeeLeavesQueryQuery['employee']['leaveStatus'];
export type EmployeesQueryVariables = EmployeesQueryQueryVariables;
export type EmployeesQueryEmployees = EmployeesQueryQuery['employees'];
export type EmployeesQueryDocs = (NonNullable<EmployeesQueryQuery['employees']['docs'][0]>);
export type FeedbackInfoEntity = FeedbackInfoFragment['entity'];
export type FeedbackInfoScreenshotList = (NonNullable<(NonNullable<FeedbackInfoFragment['screenshotList']>)[0]>);
export type CreateFeedbackVariables = CreateFeedbackMutationVariables;
export type CreateFeedbackCreateFeedback = CreateFeedbackMutation['createFeedback'];
export type RemoveFeedbackVariables = RemoveFeedbackMutationVariables;
export type RemoveFeedbackRemoveFeedback = RemoveFeedbackMutation['removeFeedback'];
export type UpdateFeedbackVariables = UpdateFeedbackMutationVariables;
export type UpdateFeedbackUpdateFeedback = UpdateFeedbackMutation['updateFeedback'];
export type FeedbackQueryVariables = FeedbackQueryQueryVariables;
export type FeedbackQueryFeedback = FeedbackQueryQuery['feedback'];
export type FeedbacksQueryVariables = FeedbacksQueryQueryVariables;
export type FeedbacksQueryFeedbacks = FeedbacksQueryQuery['feedbacks'];
export type FeedbacksQueryDocs = (NonNullable<FeedbacksQueryQuery['feedbacks']['docs'][0]>);
export type CreateInvoiceWithStageVariables = CreateInvoiceWithStageMutationVariables;
export type CreateInvoiceWithStageCreateInvoiceWithStage = CreateInvoiceWithStageMutation['createInvoiceWithStage'];
export type CreateInvoiceWithoutStageVariables = CreateInvoiceWithoutStageMutationVariables;
export type CreateInvoiceWithoutStageCreateInvoiceWithoutStage = CreateInvoiceWithoutStageMutation['createInvoiceWithoutStage'];
export type UpdateInvoiceWithStageVariables = UpdateInvoiceWithStageMutationVariables;
export type UpdateInvoiceWithStageUpdateInvoiceWithStage = UpdateInvoiceWithStageMutation['updateInvoiceWithStage'];
export type UpdateInvoiceWithoutStageVariables = UpdateInvoiceWithoutStageMutationVariables;
export type UpdateInvoiceWithoutStageUpdateInvoiceWithoutStage = UpdateInvoiceWithoutStageMutation['updateInvoiceWithoutStage'];
export type UpdateInvoicePaymentVariables = UpdateInvoicePaymentMutationVariables;
export type LeaveInfoEmployee = LeaveInfoFragment['employee'];
export type LeaveInfoApprovers = (NonNullable<LeaveInfoFragment['employee']['approvers'][0]>);
export type LeaveInfoLeaveStatus = LeaveInfoFragment['employee']['leaveStatus'];
export type CreateLeaveVariables = CreateLeaveMutationVariables;
export type CreateLeaveCreateLeave = CreateLeaveMutation['createLeave'];
export type RemoveLeaveVariables = RemoveLeaveMutationVariables;
export type RemoveLeaveRemoveLeave = RemoveLeaveMutation['removeLeave'];
export type UpdateLeaveVariables = UpdateLeaveMutationVariables;
export type UpdateLeaveUpdateLeave = UpdateLeaveMutation['updateLeave'];
export type LeavesCanApproveVariables = LeavesCanApproveQueryVariables;
export type LeavesCanApproveLeavesCanApprove = LeavesCanApproveQuery['leavesCanApprove'];
export type LeavesCanApproveDocs = (NonNullable<LeavesCanApproveQuery['leavesCanApprove']['docs'][0]>);
export type LeavesForCalendarQueryVariables = LeavesForCalendarQueryQueryVariables;
export type LeavesForCalendarQueryLeavesForCalendar = (NonNullable<LeavesForCalendarQueryQuery['leavesForCalendar'][0]>);
export type LeaveQueryVariables = LeaveQueryQueryVariables;
export type LeaveQueryLeave = LeaveQueryQuery['leave'];
export type LeavesQueryVariables = LeavesQueryQueryVariables;
export type LeavesQueryLeaves = LeavesQueryQuery['leaves'];
export type LeavesQueryDocs = (NonNullable<LeavesQueryQuery['leaves']['docs'][0]>);
export type OnMessageAddedSubscriptionVariables = OnMessageAddedSubscriptionSubscriptionVariables;
export type OnMessageAddedSubscriptionOnMessageAdded = OnMessageAddedSubscriptionSubscription['onMessageAdded'];
export type OnMessageAddedSubscriptionNode = OnMessageAddedSubscriptionSubscription['onMessageAdded']['node'];
export type OrderPatient = OrderFragment['patient'];
export type OrderClinic = OrderFragment['patient']['clinic'];
export type OrderSales = OrderFragment['patient']['sales'];
export type OrderStageOrder = (NonNullable<OrderFragment['stageOrder'][0]>);
export type OrderStageProvide = (NonNullable<OrderFragment['stageProvide'][0]>);
export type OrderStages = (NonNullable<(NonNullable<OrderFragment['stageProvide'][0]>)['stages'][0]>);
export type CreateOrdersVariables = CreateOrdersMutationVariables;
export type CreateOrdersCreateOrders = (NonNullable<(NonNullable<CreateOrdersMutation['createOrders']>)[0]>);
export type CreateOrdersAppointment = (NonNullable<(NonNullable<(NonNullable<CreateOrdersMutation['createOrders']>)[0]>)['appointment']>);
export type RemoveOrderVariables = RemoveOrderMutationVariables;
export type RemoveOrderRemoveOrder = (NonNullable<RemoveOrderMutation['removeOrder']>);
export type UpdateOrdersVariables = UpdateOrdersMutationVariables;
export type UpdateOrdersUpdateOrders = (NonNullable<(NonNullable<UpdateOrdersMutation['updateOrders']>)[0]>);
export type UpdateOrdersAppointment = (NonNullable<(NonNullable<(NonNullable<UpdateOrdersMutation['updateOrders']>)[0]>)['appointment']>);
export type OrdersQueryVariables = OrdersQueryQueryVariables;
export type OrdersQueryOrders = (NonNullable<OrdersQueryQuery['orders']>);
export type OrdersQueryDocs = (NonNullable<(NonNullable<OrdersQueryQuery['orders']>)['docs'][0]>);
export type OrdersQueryAppointment = (NonNullable<(NonNullable<(NonNullable<OrdersQueryQuery['orders']>)['docs'][0]>)['appointment']>);
export type PatientInfoFullConversation = PatientInfoFullFragment['conversation'];
export type PatientInfoFullClinic = PatientInfoFullFragment['clinic'];
export type PatientInfoFullTechnicians = (NonNullable<PatientInfoFullFragment['clinic']['technicians'][0]>);
export type PatientInfoFullDoctor = PatientInfoFullFragment['doctor'];
export type PatientInfoFullSales = PatientInfoFullFragment['sales'];
export type PatientInfoFullCustomerService = (NonNullable<PatientInfoFullFragment['customerService']>);
export type PatientInfoFullTechnician = (NonNullable<PatientInfoFullFragment['technician']>);
export type PatientInfoFullTreatArches = PatientInfoFullFragment['treatArches'];
export type PatientInfoFullNote = PatientInfoFullFragment['note'];
export type PatientInfoFullPhotos = PatientInfoFullFragment['photos'];
export type PatientInfoFullFrontFace = (NonNullable<PatientInfoFullFragment['photos']['frontFace']>);
export type PatientInfoFullFrontFaceWithTeeth = (NonNullable<PatientInfoFullFragment['photos']['frontFaceWithTeeth']>);
export type PatientInfoFullSideFace45 = (NonNullable<PatientInfoFullFragment['photos']['sideFace45']>);
export type PatientInfoFullSideFace45WithTeeth = (NonNullable<PatientInfoFullFragment['photos']['sideFace45WithTeeth']>);
export type PatientInfoFullSideFace = (NonNullable<PatientInfoFullFragment['photos']['sideFace']>);
export type PatientInfoFullSideFaceWithTeeth = (NonNullable<PatientInfoFullFragment['photos']['sideFaceWithTeeth']>);
export type PatientInfoFullFrontInside = (NonNullable<PatientInfoFullFragment['photos']['frontInside']>);
export type PatientInfoFullLeftInside = (NonNullable<PatientInfoFullFragment['photos']['leftInside']>);
export type PatientInfoFullRightInside = (NonNullable<PatientInfoFullFragment['photos']['rightInside']>);
export type PatientInfoFullUpperJawInside = (NonNullable<PatientInfoFullFragment['photos']['upperJawInside']>);
export type PatientInfoFullLowerJawInside = (NonNullable<PatientInfoFullFragment['photos']['lowerJawInside']>);
export type PatientInfoFullPano = (NonNullable<PatientInfoFullFragment['photos']['pano']>);
export type PatientInfoFullCeph = (NonNullable<PatientInfoFullFragment['photos']['ceph']>);
export type PatientInfoFullProfile = PatientInfoFullFragment['profile'];
export type PatientInfoFullMandibularMidlineToFacialMidline = PatientInfoFullFragment['profile']['mandibularMidlineToFacialMidline'];
export type PatientInfoFullOcculusalPlane = PatientInfoFullFragment['profile']['occulusalPlane'];
export type PatientInfoFullEstheticLine = PatientInfoFullFragment['profile']['estheticLine'];
export type PatientInfoFullIntraOral = PatientInfoFullFragment['intraOral'];
export type PatientInfoFullResidualRoot = PatientInfoFullFragment['intraOral']['residualRoot'];
export type PatientInfoFullImpactedTooth = PatientInfoFullFragment['intraOral']['impactedTooth'];
export type PatientInfoFullUneruptedWisdomTooth = PatientInfoFullFragment['intraOral']['uneruptedWisdomTooth'];
export type PatientInfoFullMaxillarMidlineToFacialMidline = PatientInfoFullFragment['intraOral']['maxillarMidlineToFacialMidline'];
export type PatientInfoFullMandibularMidlineToMaxillarMidline = PatientInfoFullFragment['intraOral']['mandibularMidlineToMaxillarMidline'];
export type PatientInfoFullSymptoms = PatientInfoFullFragment['symptoms'];
export type PatientInfoFullDoctorInstruction = PatientInfoFullFragment['doctorInstruction'];
export type PatientInfoFullMaintainSpace = PatientInfoFullFragment['doctorInstruction']['maintainSpace'];
export type PatientInfoFullChangeProsthesisToTemp = PatientInfoFullFragment['doctorInstruction']['changeProsthesisToTemp'];
export type PatientInfoFullExtractToothWhenNoSpace = PatientInfoFullFragment['doctorInstruction']['extractToothWhenNoSpace'];
export type PatientInfoShortClinic = PatientInfoShortFragment['clinic'];
export type PatientInfoShortDoctor = PatientInfoShortFragment['doctor'];
export type PatientInfoShortTechnician = (NonNullable<PatientInfoShortFragment['technician']>);
export type PatientInfoShortSales = PatientInfoShortFragment['sales'];
export type PatientInfoShortNote = PatientInfoShortFragment['note'];
export type PatientInfoShortPhotos = PatientInfoShortFragment['photos'];
export type PatientInfoShortFrontFace = (NonNullable<PatientInfoShortFragment['photos']['frontFace']>);
export type CreatePatientVariables = CreatePatientMutationVariables;
export type CreatePatientCreatePatient = CreatePatientMutation['createPatient'];
export type PatientQueryVariables = PatientQueryQueryVariables;
export type PatientQueryPatient = (NonNullable<PatientQueryQuery['patient']>);
export type PatientsQueryVariables = PatientsQueryQueryVariables;
export type PatientsQueryPatients = (NonNullable<PatientsQueryQuery['patients']>);
export type PatientsQueryDocs = (NonNullable<(NonNullable<PatientsQueryQuery['patients']>)['docs'][0]>);
export type CreateProductVariables = CreateProductMutationVariables;
export type CreateProductCreateProduct = CreateProductMutation['createProduct'];
export type RemoveProductVariables = RemoveProductMutationVariables;
export type RemoveProductRemoveProduct = RemoveProductMutation['removeProduct'];
export type UpdateProductVariables = UpdateProductMutationVariables;
export type UpdateProductUpdateProduct = UpdateProductMutation['updateProduct'];
export type ProductQueryVariables = ProductQueryQueryVariables;
export type ProductQueryProduct = (NonNullable<ProductQueryQuery['product']>);
export type ProductsQueryVariables = ProductsQueryQueryVariables;
export type ProductsQueryProducts = (NonNullable<ProductsQueryQuery['products']>);
export type ProductsQueryDocs = (NonNullable<(NonNullable<ProductsQueryQuery['products']>)['docs'][0]>);
export type ProductStatQueryVariables = ProductStatQueryQueryVariables;
export type ProductStatQueryProductStat = (NonNullable<(NonNullable<ProductStatQueryQuery['productStat']>)[0]>);
export type ProductStatQueryProduct = (NonNullable<(NonNullable<(NonNullable<ProductStatQueryQuery['productStat']>)[0]>)['product']>);
export type SchedulesQueryVariables = SchedulesQueryQueryVariables;
export type SchedulesQuerySchedules = (NonNullable<SchedulesQueryQuery['schedules']>);
export type SchedulesQueryDocs = (NonNullable<(NonNullable<SchedulesQueryQuery['schedules']>)['docs'][0]>);
export type RemoveStageVariables = RemoveStageMutationVariables;
export type RemoveStageRemoveStage = RemoveStageMutation['removeStage'];
export type AnalysisSelectQueryVariables = AnalysisSelectQueryQueryVariables;
export type AnalysisSelectQueryEvalStages = AnalysisSelectQueryQuery['evalStages'];
export type AnalysisSelectQueryDocs = (NonNullable<AnalysisSelectQueryQuery['evalStages']['docs'][0]>);
export type AnalysisSelectQueryAnalysis = (NonNullable<(NonNullable<AnalysisSelectQueryQuery['evalStages']['docs'][0]>)['analysis']>);
export type AnalysisSelectQuerySteps = (NonNullable<(NonNullable<(NonNullable<(NonNullable<AnalysisSelectQueryQuery['evalStages']['docs'][0]>)['analysis']>)['steps']>)[0]>);
export type AnalysisPreviewAnalysisAnalysis = (NonNullable<AnalysisPreviewAnalysisFragment['analysis']>);
export type CheckPointListCheckPoints = (NonNullable<CheckPointListFragment['checkPoints'][0]>);
export type AnalysisPreviewPhotosPhotos = AnalysisPreviewPhotosFragment['photos'];
export type AnalysisPreviewPhotosFrontFace = (NonNullable<AnalysisPreviewPhotosFragment['photos']['frontFace']>);
export type AnalysisPreviewPhotosFrontFaceWithTeeth = (NonNullable<AnalysisPreviewPhotosFragment['photos']['frontFaceWithTeeth']>);
export type AnalysisPreviewPhotosSideFace45 = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFace45']>);
export type AnalysisPreviewPhotosSideFace45WithTeeth = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFace45WithTeeth']>);
export type AnalysisPreviewPhotosSideFace = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFace']>);
export type AnalysisPreviewPhotosSideFaceWithTeeth = (NonNullable<AnalysisPreviewPhotosFragment['photos']['sideFaceWithTeeth']>);
export type AnalysisPreviewPhotosFrontInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['frontInside']>);
export type AnalysisPreviewPhotosLeftInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['leftInside']>);
export type AnalysisPreviewPhotosRightInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['rightInside']>);
export type AnalysisPreviewPhotosUpperJawInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['upperJawInside']>);
export type AnalysisPreviewPhotosLowerJawInside = (NonNullable<AnalysisPreviewPhotosFragment['photos']['lowerJawInside']>);
export type AnalysisPreviewPhotosPano = (NonNullable<AnalysisPreviewPhotosFragment['photos']['pano']>);
export type AnalysisPreviewPhotosCeph = (NonNullable<AnalysisPreviewPhotosFragment['photos']['ceph']>);
export type AnalysisPreviewProfilePatient = AnalysisPreviewProfileFragment['patient'];
export type AnalysisPreviewProfileClinic = AnalysisPreviewProfileFragment['patient']['clinic'];
export type AnalysisPreviewProfileDoctor = AnalysisPreviewProfileFragment['patient']['doctor'];
export type AnalysisPreviewProfilePhotos = AnalysisPreviewProfileFragment['patient']['photos'];
export type AnalysisPreviewProfileFrontFace = (NonNullable<AnalysisPreviewProfileFragment['patient']['photos']['frontFace']>);
export type StepListAnalysis = (NonNullable<StepListFragment['analysis']>);
export type StepListSteps = (NonNullable<(NonNullable<(NonNullable<StepListFragment['analysis']>)['steps']>)[0]>);
export type AnalysisPreviewPatient = AnalysisPreviewFragment['patient'];
export type ViewerHeaderCurrentEvalStage = (NonNullable<ViewerHeaderFragment['currentEvalStage']>);
export type ViewerHeaderEvalStages = (NonNullable<ViewerHeaderFragment['evalStages']>);
export type ViewerHeaderDocs = (NonNullable<(NonNullable<ViewerHeaderFragment['evalStages']>)['docs'][0]>);
export type ViewerHeaderEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<ViewerHeaderFragment['evalStages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type SidebarStepsOverview = (NonNullable<SidebarFragment['stepsOverview']>);
export type DentalModelViewerCurrentEvalStage = (NonNullable<DentalModelViewerFragment['currentEvalStage']>);
export type DentalModelViewerStages = (NonNullable<DentalModelViewerFragment['stages']>);
export type DentalModelViewerDocs = (NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>);
export type DentalModelViewerMoldStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>);
export type DentalModelViewerFiles = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>);
export type DentalModelViewerTeeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['teeth']>)[0]>);
export type DentalModelViewerFile = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type DentalModelViewerUpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['upperJawModel']>);
export type DentalModelViewerLowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'MoldStage' }>)['files']>)['lowerJawModel']>);
export type DentalModelViewerEvalStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>);
export type DentalModelViewerCheckPoints = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>);
export type DentalModelViewer_Files = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>);
export type DentalModelViewer_Teeth = (NonNullable<(NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['teeth']>)[0]>);
export type DentalModelViewer_File = (NonNullable<(NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['teeth']>)[0]>)['file'];
export type DentalModelViewer_UpperJawModel = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['upperJawModel']>);
export type DentalModelViewer_LowerJawModel = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'EvalStage' }>)['checkPoints'][0]>)['files']>)['lowerJawModel']>);
export type DentalModelViewerDesignStageInlineFragment = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>);
export type DentalModelViewerStep = (DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['step'];
export type DentalModelViewerInstructionCard = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['instructionCard']>);
export type DentalModelViewer__Files = (NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>);
export type DentalModelViewer__Teeth = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['teeth']>)[0]>);
export type DentalModelViewer__File = (NonNullable<(NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['teeth']>)[0]>)['file'];
export type DentalModelViewer__UpperJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['upperJawModel']>);
export type DentalModelViewer__LowerJawModel = (NonNullable<(NonNullable<(DiscriminateUnion<RequireField<(NonNullable<(NonNullable<DentalModelViewerFragment['stages']>)['docs'][0]>), '__typename'>, { __typename: 'DesignStage' }>)['files']>)['lowerJawModel']>);
export type UploadFileVariables = UploadFileMutationVariables;
export type UploadFileUploadFile = UploadFileMutation['uploadFile'];
export type EmptyInstructionCardPatient = EmptyInstructionCardFragment['patient'];
export type EmptyInstructionCardStage = EmptyInstructionCardFragment['stage'];
export type InstructionCardPreviewPatient = InstructionCardPreviewFragment['patient'];
export type InstructionPopoverStage = InstructionPopoverFragment['stage'];
export type InstructionTeethInfoTeethStatus = (NonNullable<InstructionTeethInfoFragment['teethStatus'][0]>);
export type InstructionTeethInfoAuxiliaries = (NonNullable<InstructionTeethInfoFragment['auxiliaries'][0]>);
export type PatientWithDoctorLinkDoctor = PatientWithDoctorLinkFragment['doctor'];
export type StageLinkPatient = StageLinkFragment['patient'];
export type MessageInboxItemLatestMessage = MessageInboxItemFragment['latestMessage'];
export type MessageInboxItemConversation = MessageInboxItemFragment['latestMessage']['conversation'];
export type MessageInboxItemCreator = MessageInboxItemFragment['latestMessage']['creator'];
export type MessageInboxItemEmployeeInlineFragment = (DiscriminateUnion<RequireField<MessageInboxItemFragment['latestMessage']['creator'], '__typename'>, { __typename: 'Employee' }>);
export type MessageInboxItemTextMessageInlineFragment = (DiscriminateUnion<RequireField<MessageInboxItemFragment['latestMessage'], '__typename'>, { __typename: 'TextMessage' }>);
export type MessageInboxItemPatient = MessageInboxItemFragment['patient'];
export type MessageInboxEdges = (NonNullable<MessageInboxFragment['edges'][0]>);
export type MessageInboxNode = (NonNullable<MessageInboxFragment['edges'][0]>)['node'];
export type StageNameMoldStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'MoldStage' }>);
export type StageNameEvalStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'EvalStage' }>);
export type StageNameDesignStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'DesignStage' }>);
export type StageNamePrintStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'PrintStage' }>);
export type StageNameAccessoryStageInlineFragment = (DiscriminateUnion<RequireField<StageNameFragment, '__typename'>, { __typename: 'AccessoryStage' }>);
export type TablePatientInfoPhotos = TablePatientInfoFragment['photos'];
export type TablePatientInfoFrontFace = (NonNullable<TablePatientInfoFragment['photos']['frontFace']>);
export type TablePatientInfoClinic = TablePatientInfoFragment['clinic'];
export type TablePatientInfoDoctor = TablePatientInfoFragment['doctor'];
import { ObjectId } from 'mongodb';
export type AccessoryStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  accessoryCode: AccessoryCode,
  instructionCard?: Maybe<InstructionCardDbObject>,
  rpBatchNumber?: Maybe<string>,
  rpModelNumber?: Maybe<string>,
  upperModelCount?: Maybe<number>,
  lowerModelCount?: Maybe<number>,
  upperAttachmentTemplateCount?: Maybe<number>,
  lowerAttachmentTemplateCount?: Maybe<number>,
  upperMouthGuardCount?: Maybe<number>,
  lowerMouthGuardCount?: Maybe<number>,
  upperBraceCount?: Maybe<number>,
  upperBraceMaterial?: Maybe<BraceMaterial>,
  upperBraceThickness?: Maybe<BraceThickness>,
  lowerBraceCount?: Maybe<number>,
  lowerBraceMaterial?: Maybe<BraceMaterial>,
  lowerBraceThickness?: Maybe<BraceThickness>,
  order?: Maybe<OrderDbObject['_id']>,
};

export type StageDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  stageType: string,
};

export type PatientDbObject = EntityDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  name: string,
  payment: PatientPaymentDbObject,
  status: PatientStatus,
  creator?: Maybe<EntityDbObject['_id']>,
  clinic: ClinicDbObject['_id'],
  doctor: DoctorDbObject['_id'],
  accountManager?: Maybe<EmployeeDbObject['_id']>,
  sales: EmployeeDbObject['_id'],
  customerService?: Maybe<EmployeeDbObject['_id']>,
  technician?: Maybe<EmployeeDbObject['_id']>,
  nextAppointmentDate?: Maybe<any>,
  currentEvalStage?: Maybe<EvalStageDbObject['_id']>,
  patientCode: string,
  cabinetCode: string,
  gender?: Maybe<Gender>,
  treatArches: TreatArchesDbObject,
  photos: PhotosDbObject,
  note: PatientNoteDbObject,
  chiefComplaint: string,
  profile: ProfileDbObject,
  intraOral: IntraOralDbObject,
  symptoms: SymptomsDbObject,
  doctorInstruction: DoctorInstructionDbObject,
  currentSubStage?: Maybe<SubStageDbObject['_id']>,
  meta: PatientMetaDbObject,
};

export type EntityDbObject = {
  _id: ObjectId,
  name: string,
  entityType: string,
};

export type NodeDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  nodeType: string,
};

export type PatientPaymentDbObject = {
  type: PatientPaymentType,
  brand: PatientBrand,
  source: PatientSource,
  patientPrice: number,
  base: number,
  referral: number,
  extension: number,
  discount: number,
};

export type ClinicDbObject = EntityDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  name: string,
  fullname: string,
  type: ClinicType,
  location: ClinicLocationDbObject,
  phone: string,
  doctors: Array<DoctorDbObject['_id']>,
  email: string,
  accountManagers: Array<EmployeeDbObject['_id']>,
  technicians: Array<EmployeeDbObject['_id']>,
  sales: Array<EmployeeDbObject['_id']>,
  customerService?: Maybe<EmployeeDbObject['_id']>,
  specialContract: boolean,
  deliveryMethod: DeliveryMethod,
};

export type ClinicLocationDbObject = {
  address: string,
  country: Country,
  district: District,
};

export type DoctorDbObject = NodeDbObject & EntityDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  name: string,
  email: string,
  phone?: Maybe<string>,
  clinics: Array<ClinicDbObject['_id']>,
  note: string,
  level: DoctorLevel,
};

export type EmployeeDbObject = EntityDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  name: string,
  title: string,
  email?: Maybe<string>,
  phone?: Maybe<string>,
  address?: Maybe<string>,
  backupEmail?: Maybe<string>,
  lineId?: Maybe<string>,
  birthDate?: Maybe<any>,
  gender?: Maybe<Gender>,
  isMarried?: Maybe<boolean>,
  onBoardDate: any,
  role: Role,
  taskOwner: Array<TaskType>,
  approvers: Array<EmployeeDbObject['_id']>,
  resigned: boolean,
};

export type OrderDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  oid: string,
  creator: EntityDbObject['_id'],
  dueDate?: Maybe<any>,
  patient: PatientDbObject['_id'],
  type: OrderType,
  stageOrder: Array<StageOrderContentDbObject>,
  stageProvide: Array<StageProvideContentDbObject>,
  isFulfilled: boolean,
  status: OrderStatus,
  isLatest: boolean,
  description: string,
  instruction: string,
  appointment?: ObjectId,
};

export type StageOrderContentDbObject = {
  stageType: StageType,
  number: number,
  items?: Maybe<Array<AccessoryCode>>,
};

export type StageProvideContentDbObject = {
  stageType: StageType,
  stages: Array<StageDbObject['_id']>,
};

export type AppointmentDbObject = ScheduleDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: ScheduleType,
  startDate: any,
  endDate: any,
  description: string,
  isAttended: boolean,
  creator: EntityDbObject['_id'],
  patient: PatientDbObject['_id'],
};

export type ScheduleDbObject = {
  _id: ObjectId,
  type: ScheduleType,
  creator: EntityDbObject['_id'],
  startDate: any,
  endDate: any,
  description: string,
  scheduleType: string,
};

export type LeaveDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  employee: EmployeeDbObject['_id'],
  startDate: any,
  endDate: any,
  type: LeaveType,
  reason: string,
  isApproved: boolean,
};

export type InvoiceDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  clinic: ClinicDbObject['_id'],
  discount: number,
  doctor: DoctorDbObject['_id'],
  homeDeliveryId: string,
  homeDeliveryType: HomeDeliveryType,
  invoiceNumber: string,
  middlemanFee: number,
  note: string,
  creditDate: any,
  shippingDate?: Maybe<any>,
  payment: Payment,
  products: Array<InvoiceProductDbObject>,
  sales: EmployeeDbObject['_id'],
  tax: boolean,
  totalPrice: number,
  shippingStatus: ShippingStatus,
  deferredRevenue: number,
  customerService?: Maybe<EmployeeDbObject['_id']>,
  invoiceType: string,
};

export type InvoiceProductDbObject = {
  productId: ObjectId,
  serialNumber: string,
  name: string,
  spec: string,
  price: number,
  cost: number,
  count: number,
};

export type EvalStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  analysis?: Maybe<AnalysisDbObject>,
  checkPoints: Array<CheckPointDbObject>,
  isBeforePrint: boolean,
  moldStage?: Maybe<MoldStageDbObject['_id']>,
  files?: Maybe<EvalStageFilesDbObject>,
  stepsOverview?: Maybe<FileDbObject['_id']>,
  initialEvalStage?: Maybe<EvalStageDbObject['_id']>,
  order?: Maybe<OrderDbObject['_id']>,
};

export type TaskDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: TaskType,
  status: TaskStatus,
  startedByEmployee?: Maybe<any>,
  completedByOwner?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  stage: StageDbObject['_id'],
  owner?: Maybe<EntityDbObject['_id']>,
  previousTask?: Maybe<TaskDbObject['_id']>,
  nextTask?: Maybe<TaskDbObject['_id']>,
};

export type AnalysisDbObject = {
  digiAnalysis?: Maybe<string>,
  steps?: Maybe<Array<StepDbObject>>,
};

export type StepDbObject = {
  upperStep: string,
  lowerStep: string,
  attachment: boolean,
  ipr: boolean,
  button: boolean,
  retrieve: boolean,
};

export type CheckPointDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  serialNumber: number,
  goalStep: number,
  upper: string,
  lower: string,
  note: string,
  instruction: Array<AuxiliaryType>,
  files?: Maybe<EvalStageFilesDbObject>,
};

export type EvalStageFilesDbObject = {
  teeth?: Maybe<Array<ToothFileDbObject>>,
  upperJawModel?: Maybe<FileDbObject['_id']>,
  lowerJawModel?: Maybe<FileDbObject['_id']>,
};

export type ToothFileDbObject = {
  position: ToothPosition,
  file: FileDbObject['_id'],
};

export type FileDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  mimeType: string,
  filename: string,
  path: string,
  thumbnailPath?: Maybe<string>,
};

export type DesignStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  step: StepDbObject,
  instructionCard?: Maybe<InstructionCardDbObject>,
  rpModelNumber?: Maybe<string>,
  upperModelCount?: Maybe<number>,
  lowerModelCount?: Maybe<number>,
  upperAttachmentTemplateCount?: Maybe<number>,
  lowerAttachmentTemplateCount?: Maybe<number>,
  upperMouthGuardCount?: Maybe<number>,
  lowerMouthGuardCount?: Maybe<number>,
  upperBraceCount?: Maybe<number>,
  upperBraceMaterial?: Maybe<BraceMaterial>,
  upperBraceThickness?: Maybe<BraceThickness>,
  lowerBraceCount?: Maybe<number>,
  lowerBraceMaterial?: Maybe<BraceMaterial>,
  lowerBraceThickness?: Maybe<BraceThickness>,
  evalStage: EvalStageDbObject['_id'],
  files?: Maybe<DesignStageFilesDbObject>,
};

export type InstructionCardDbObject = {
  instruction?: Maybe<string>,
  auxiliaries: Array<AuxiliaryDbObject>,
  teethStatus: Array<ToothStatusDbObject>,
  stage: StageDbObject['_id'],
  patient: PatientDbObject['_id'],
};

export type AuxiliaryDbObject = {
  type: AuxiliaryType,
  toothPosition: ToothPosition,
  auxiliaryPosition: AuxiliaryPosition,
  value?: Maybe<number>,
};

export type ToothStatusDbObject = {
  position: ToothPosition,
  type: ToothStatusType,
};

export type DesignStageFilesDbObject = {
  teeth?: Maybe<Array<ToothFileDbObject>>,
  upperJawModel?: Maybe<FileDbObject['_id']>,
  lowerJawModel?: Maybe<FileDbObject['_id']>,
  upperRP?: Maybe<FileDbObject['_id']>,
  lowerRP?: Maybe<FileDbObject['_id']>,
};

export type PrintStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  rpBatchNumber?: Maybe<string>,
  designStage?: Maybe<DesignStageDbObject['_id']>,
  order?: Maybe<OrderDbObject['_id']>,
};

export type SubStageDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  stage: PrintStageDbObject['_id'],
  serialNumber: number,
};

export type WearDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  patient: PatientDbObject['_id'],
  subStage: SubStageDbObject['_id'],
  date: any,
  time: number,
};

export type MoldStageDbObject = StageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: StageType,
  status: StageStatus,
  shippingDate?: Maybe<any>,
  expectedShippingDate?: Maybe<any>,
  note: string,
  patient: PatientDbObject['_id'],
  tasks: Array<TaskDbObject['_id']>,
  serialNumber: number,
  moldType: MoldType,
  files?: Maybe<MoldStageFilesDbObject>,
};

export type MoldStageFilesDbObject = {
  teeth?: Maybe<Array<ToothFileDbObject>>,
  upperJawModel?: Maybe<FileDbObject['_id']>,
  lowerJawModel?: Maybe<FileDbObject['_id']>,
};

export type TreatArchesDbObject = {
  upper: boolean,
  lower: boolean,
};

export type PhotosDbObject = {
  frontFace?: Maybe<FileDbObject['_id']>,
  frontFaceWithTeeth?: Maybe<FileDbObject['_id']>,
  sideFace45?: Maybe<FileDbObject['_id']>,
  sideFace45WithTeeth?: Maybe<FileDbObject['_id']>,
  sideFace?: Maybe<FileDbObject['_id']>,
  sideFaceWithTeeth?: Maybe<FileDbObject['_id']>,
  leftInside?: Maybe<FileDbObject['_id']>,
  frontInside?: Maybe<FileDbObject['_id']>,
  rightInside?: Maybe<FileDbObject['_id']>,
  upperJawInside?: Maybe<FileDbObject['_id']>,
  lowerJawInside?: Maybe<FileDbObject['_id']>,
  pano?: Maybe<FileDbObject['_id']>,
  ceph?: Maybe<FileDbObject['_id']>,
};

export type PatientNoteDbObject = {
  sales: string,
  design: string,
  braces: string,
};

export type ProfileDbObject = {
  mandibularMidlineToFacialMidline: MidlineDbObject,
  occulusalPlane: OcculusalPlaneDbObject,
  estheticLine: EstheticLineDbObject,
  cephAnalysis: CephAnalysis,
  faceRatio: FaceRatio,
  occulusalMuscle: OcculusalMuscle,
  hasGummySmile: boolean,
  smileLine: SmileLine,
  hasBuccalCorridor: boolean,
};

export type MidlineDbObject = {
  offset: Offset,
  instruction: Instruction,
};

export type OcculusalPlaneDbObject = {
  occulusalPlaneStatus: OcculusalPlaneStatus,
  instruction: Instruction,
};

export type EstheticLineDbObject = {
  lipsEstheticLineRelation: LipsEstheticLineRelation,
  instruction: Instruction,
};

export type IntraOralDbObject = {
  missingTeeth: Array<number>,
  teethDontMove: Array<number>,
  teethWillExtract: Array<number>,
  teethStatus: Array<ToothStatusDbObject>,
  temporomandibularJointDisordersTreatStatus: TreatStatus,
  periodontalDiseaseTreatStatus: TreatStatus,
  residualRoot: ResidualRootDbObject,
  impactedTooth: ImpactedToothDbObject,
  uneruptedWisdomTooth: UneruptedWisdomToothDbObject,
  maxillarMidlineToFacialMidline: MidlineDbObject,
  mandibularMidlineToMaxillarMidline: MidlineDbObject,
};

export type ResidualRootDbObject = {
  hasResidualRoot: boolean,
  instruction?: Maybe<ResidualRootInstruction>,
};

export type ImpactedToothDbObject = {
  hasImpactedTooth: boolean,
  instruction?: Maybe<ImpactedToothInstruction>,
};

export type UneruptedWisdomToothDbObject = {
  hasUneruptedWisdomTooth: boolean,
  instruction?: Maybe<UneruptedWisdomToothInstruction>,
};

export type SymptomsDbObject = {
  leftMolarRelationship: AnglesClassification,
  rightMolarRelationship: AnglesClassification,
  anteriorCrossbite: boolean,
  anteriorCrowding: boolean,
  bimaxillaryProtrusion: boolean,
  deepBite: boolean,
  highCanine: boolean,
  openBite: boolean,
  posteriorCrossbite: boolean,
  posteriorCrowding: boolean,
  vShapeArch: boolean,
};

export type DoctorInstructionDbObject = {
  ipr: Need,
  tads: Need,
  otherOrthdontalTools: Need,
  functionalAppliance: Need,
  maintainSpace: MaintainSpaceDbObject,
  changeProsthesisToTemp: ChangeProsthesisToTempDbObject,
  extractToothWhenNoSpace: ExtractToothWhenNoSpaceDbObject,
  note: string,
};

export type MaintainSpaceDbObject = {
  isMaintainingSpace: boolean,
  note?: Maybe<string>,
};

export type ChangeProsthesisToTempDbObject = {
  need: Need,
  instruction?: Maybe<string>,
};

export type ExtractToothWhenNoSpaceDbObject = {
  intention: Need,
  toothType?: Maybe<ToothType>,
};

export type PatientConversationDbObject = ConversationDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: ConversationType,
  members: Array<EntityDbObject['_id']>,
  readMessageRecords: Array<ReadMessageRecordDbObject>,
  latestMessage?: Maybe<MessageDbObject['_id']>,
  patient: PatientDbObject['_id'],
};

export type ConversationDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  type: ConversationType,
  members: Array<EntityDbObject['_id']>,
  readMessageRecords: Array<ReadMessageRecordDbObject>,
  latestMessage?: Maybe<MessageDbObject['_id']>,
  conversationType: string,
};

export type ReadMessageRecordDbObject = {
  member: EntityDbObject['_id'],
  lastReadMessageDate: any,
};

export type MessageDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  conversation: ConversationDbObject['_id'],
  type: MessageType,
  creator: EntityDbObject['_id'],
  messageType: string,
};

export type PatientMetaDbObject = {
  firstEvalStage?: Maybe<StageDbObject['_id']>,
  firstEvalStageAt?: Maybe<any>,
  firstPrintStage?: Maybe<StageDbObject['_id']>,
  firstPrintStageAt?: Maybe<any>,
  lastEvalStage?: Maybe<StageDbObject['_id']>,
  lastEvalStageAt?: Maybe<any>,
  lastPrintStage?: Maybe<StageDbObject['_id']>,
  lastPrintStageAt?: Maybe<any>,
  currentStepNumber: number,
  advancedStepNumber: number,
  finalStepNumber: number,
  techRevenue: number,
  techCost: number,
  techProfit: number,
  brandRevenue: number,
  risk: number,
};

export type EventDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  creator: EntityDbObject['_id'],
  type: EventType,
  data: EventDataDbObject,
};

export type EventDataDbObject = {
  patient?: Maybe<PatientDbObject['_id']>,
  order?: Maybe<OrderDbObject['_id']>,
  stage?: Maybe<StageDbObject['_id']>,
};

export type DiaryDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  patient: PatientDbObject['_id'],
  wear: WearDbObject['_id'],
  diaryDate: any,
  photos?: Maybe<Array<FileDbObject['_id']>>,
  text?: Maybe<string>,
};

export type TrackDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  clinic: ClinicDbObject['_id'],
  patient: PatientDbObject['_id'],
  stage: PrintStageDbObject['_id'],
  photo?: Maybe<TrackPhotoDbObject>,
  mainIssues: Array<TrackIssue>,
  extraIssue?: Maybe<string>,
  reply?: Maybe<string>,
  replyDate?: Maybe<any>,
};

export type TrackPhotoDbObject = {
  withBrace?: Maybe<TrackPhotoBase>,
  withoutBrace?: Maybe<TrackPhotoBase>,
};

export type TrackPhotoBaseDbObject = {
  frontLoose?: Maybe<FileDbObject['_id']>,
  frontTight?: Maybe<FileDbObject['_id']>,
  leftTight?: Maybe<FileDbObject['_id']>,
  rightTight?: Maybe<FileDbObject['_id']>,
};

export type AccountDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  email?: Maybe<string>,
  phone?: Maybe<string>,
  isEmailVerified: boolean,
  isPhoneVerified: boolean,
  password: string,
  nickname: string,
  backupEmail?: Maybe<string>,
  avatar?: Maybe<FileDbObject['_id']>,
  providers?: Maybe<ProvidersDbObject>,
  isActive: boolean,
  lastLogin?: Maybe<any>,
  entityType: EntityType,
  entity?: Maybe<EntityDbObject['_id']>,
  privileges: Array<AllPrivilege>,
  extraPrivileges?: Maybe<Array<AllPrivilege>>,
  note: string,
  notificationSetting: NotificationSetting,
  currentAppStorage?: Maybe<string>,
  shouldClearStorage: boolean,
  lastStorageClearDate?: Maybe<any>,
  lastLoginClientInfo?: Maybe<string>,
  onModel: string,
  deviceToken: {
    ios: string,
    android: string,
  },
};

export type ProvidersDbObject = {
  facebook?: Maybe<ProviderDbObject>,
  google?: Maybe<ProviderDbObject>,
  line?: Maybe<ProviderDbObject>,
  wechat?: Maybe<ProviderDbObject>,
};

export type ProviderDbObject = {
  id?: Maybe<string>,
};

export type NotificationSettingDbObject = {
  site: Array<NotificationType>,
  mail: Array<NotificationType>,
  line: Array<NotificationType>,
};

export type FeedbackDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  category: FeedbackCategory,
  title?: Maybe<string>,
  content?: Maybe<string>,
  screenshotList?: Maybe<Array<FileDbObject['_id']>>,
  status: FeedbackStatus,
  entityType: EntityType,
  entity: EntityDbObject['_id'],
};

export type NotificationDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  type: NotificationType,
  receiver: EntityDbObject['_id'],
  read: boolean,
  platform: Platform,
  content: string,
  notificationType: string,
};

export type ProductDbObject = NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  serialNumber: string,
  name: string,
  price: number,
  spec: string,
  cost: number,
};

export type SystemDbObject = {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  systemType: string,
};

export type InvoiceWithStageDbObject = InvoiceDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  clinic: ClinicDbObject['_id'],
  discount: number,
  doctor: DoctorDbObject['_id'],
  homeDeliveryId: string,
  homeDeliveryType: HomeDeliveryType,
  invoiceNumber: string,
  middlemanFee: number,
  note: string,
  creditDate: any,
  shippingDate?: Maybe<any>,
  payment: Payment,
  products: Array<InvoiceProductDbObject>,
  sales: EmployeeDbObject['_id'],
  tax: boolean,
  totalPrice: number,
  shippingStatus: ShippingStatus,
  deferredRevenue: number,
  customerService?: Maybe<EmployeeDbObject['_id']>,
  patient: PatientDbObject['_id'],
  stage: StageDbObject['_id'],
};

export type InvoiceWithoutStageDbObject = InvoiceDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  autoIncrement: number,
  clinic: ClinicDbObject['_id'],
  discount: number,
  doctor: DoctorDbObject['_id'],
  homeDeliveryId: string,
  homeDeliveryType: HomeDeliveryType,
  invoiceNumber: string,
  middlemanFee: number,
  note: string,
  creditDate: any,
  shippingDate?: Maybe<any>,
  payment: Payment,
  products: Array<InvoiceProductDbObject>,
  sales: EmployeeDbObject['_id'],
  tax: boolean,
  totalPrice: number,
  shippingStatus: ShippingStatus,
  deferredRevenue: number,
  customerService?: Maybe<EmployeeDbObject['_id']>,
};

export type MinimumRequiredAppVersionSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  minimumRequiredAppVersion: string,
};

export type EntityTypeToPrivilegesListSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  entityTypeToPrivilegesList: Array<SystemEntityTypeToPrivileges>,
};

export type StageCapacitySystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  stageCapacity: MaxStageCapacity,
};

export type RoleToPrivilegesListSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  roleToPrivilegesList: Array<SystemRoleToPrivileges>,
};

export type StageCostSystemDbObject = SystemDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  configType: SystemConfigType,
  stageCost: Array<StageCostPerType>,
};

export type TextMessageDbObject = MessageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  conversation: ConversationDbObject['_id'],
  type: MessageType,
  creator: EntityDbObject['_id'],
  content: string,
};

export type ImageMessageDbObject = MessageDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  conversation: ConversationDbObject['_id'],
  type: MessageType,
  creator: EntityDbObject['_id'],
  image: FileDbObject['_id'],
};

export type AppointmentNotificationDbObject = NotificationDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: NotificationType,
  receiver: EntityDbObject['_id'],
  read: boolean,
  platform: Platform,
  content: string,
  appointment: AppointmentDbObject['_id'],
};

export type NormalNotificationDbObject = NotificationDbObject & NodeDbObject & {
  _id: ObjectId,
  created: any,
  updated: any,
  type: NotificationType,
  receiver: EntityDbObject['_id'],
  read: boolean,
  platform: Platform,
  content: string,
};
