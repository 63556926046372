import { StatisticInterval } from '@sov/common/src/types'
import moment from 'moment'

export const defaultInterval: (
  interval: StatisticInterval
) => [moment.Moment, moment.Moment] = (interval: StatisticInterval) => {
  switch (interval) {
    case StatisticInterval.Year:
      return [
        moment().startOf('year').subtract(4, 'year'),
        moment().startOf('year'),
      ]
    case StatisticInterval.Quarter:
      return [
        moment().startOf('quarter').subtract(5, 'quarter'),
        moment().startOf('quarter'),
      ]
    case StatisticInterval.Month:
      return [
        moment().startOf('month').subtract(12, 'month'),
        moment().startOf('month'),
      ]
    case StatisticInterval.Day:
      return [moment().startOf('month'), moment().endOf('month')]
    default:
      return [moment().startOf('month'), moment().endOf('month')]
  }
}

export const dateText: (interval: StatisticInterval, x: any) => string = (
  interval: StatisticInterval,
  x: any,
) => {
  switch (interval) {
    case StatisticInterval.Year:
      return moment(x.startDate).format('YYYY')
    case StatisticInterval.Quarter:
      return moment(x.startDate).format('YYYY-[Q]Q')
    case StatisticInterval.Month:
      return moment(x.startDate).format('YYYY-MM')
    case StatisticInterval.Day:
      return moment(x.startDate).format('MM-DD')
    default:
      return '不分時段'
  }
}
