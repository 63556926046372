import PageBottom from './PageBottom'
import PageBreak from './PageBreak'
import PageContainerHorizontalMargin from './PageContainerHorizontalMargin'
import PageTitle from './PageTitle'
import PageTop from './PageTop'

export {
  PageBottom,
  PageBreak,
  PageContainerHorizontalMargin,
  PageTitle,
  PageTop,
}
