import Loadable from '@loadable/component'
import { map, values } from 'ramda'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Layout from '../components/layout'
import Page from '../components/layout/Page'
import GMPMenu from '../components/pageHeader/GMPMenu'
import type { Route as RouteMap } from './routes'

export const gmpRouteKeys = [
  'FactoryOrders',
  'Defective',
  'CompletedRP',
  'FactoryShipment',
  'CompletedBraces',
] as const
export type GMPRouteKey = typeof gmpRouteKeys[number]

export const gmpRoutes: RouteMap<GMPRouteKey> = {
  FactoryOrders: {
    path: '/gmp/factory-orders',
    title: '訂單記錄',
    component: Loadable(() => import('../pages/gmp/FactoryOrders')),
    privileges: [],
  },
  CompletedRP: {
    path: '/gmp/completed-rp',
    title: '模具排程紀錄',
    component: Loadable(() => import('../pages/gmp/CompletedRP')),
    privileges: [],
  },
  CompletedBraces: {
    path: '/gmp/completed-braces',
    title: '牙套生產排程',
    component: Loadable(() => import('../pages/gmp/CompletedBraces')),
    privileges: [],
  },
  FactoryShipment: {
    path: '/gmp/factory-shipment',
    title: '每日出貨統計表',
    component: Loadable(() => import('../pages/gmp/FactoryShipment')),
    privileges: [],
  },
  Defective: {
    path: '/gmp/defective',
    title: '重壓品紀錄',
    component: Loadable(() => import('../pages/gmp/Defective')),
    privileges: [],
  },
}

function GMPRoutes() {
  return (
    <Switch>
      <Layout>
        <Page header={<GMPMenu />}>
          <Switch>
            {map(
              ({ path, component: Component }) => (
                <Route exact key={path} path={path}>
                  {(props: any) => <Component {...props} />}
                </Route>
              ),
              values(gmpRoutes),
            )}
          </Switch>
        </Page>
      </Layout>
    </Switch>
  )
}

export default GMPRoutes
