import '@ant-design/compatible/assets/index.css'

import { Form } from '@ant-design/compatible'
import type { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { Input, Modal } from 'antd'
import React from 'react'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

interface Props {
  onSubmit: (value: string) => void
  onCancel: () => void
  visible: boolean
  form: WrappedFormUtils
}
export const ChangePasswordModal = Form.create<Props>()((props: Props) => {
  const { form, visible, onSubmit, onCancel } = props
  const { getFieldDecorator } = form

  const onOk = () => {
    form.validateFields(async (err, formValues) => {
      if (err) {
        console.error(err, formValues)
        return
      }
      await onSubmit(formValues.password)
    })
  }
  return (
    <Modal title="更改密碼" visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form>
        <FormItem {...formItemLayout} label="請輸入密碼">
          {getFieldDecorator('password', {
            rules: [
              {
                min: 4,
                max: 16,
                pattern: new RegExp('^[a-z0-9]', 'i'),
                message: '需輸入英文或數字 4 到 16 位',
              },
              {
                required: true,
                message: '必填欄位',
              },
            ],
          })(<Input.Password />)}
        </FormItem>
        <FormItem {...formItemLayout} label="確認密碼">
          {getFieldDecorator('confirm', {
            rules: [
              {
                min: 4,
                max: 16,
                pattern: new RegExp('^[a-z0-9]', 'i'),
                message: '需輸入英文或數字 4 到 16 位',
              },
              {
                required: true,
                message: '必填欄位',
              },
              {
                validator: (rule, value, cb) => {
                  if (value && value !== form.getFieldValue('password'))
                    cb('與密碼不同')
                  else
                    cb()
                },
              },
            ],
          })(<Input.Password />)}
        </FormItem>
      </Form>
    </Modal>
  )
})
