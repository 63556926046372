import { gql } from '@apollo/client'
import { InstructionCardPreview } from '@sov/ui'
import { Modal } from 'antd'
import React from 'react'

import type { InstructionCardPreviewModalFragment } from '../../../../graphql/types'

interface Props {
  instructionItem?: InstructionCardPreviewModalFragment
  visible: boolean
  onCancel: () => void
}
function InstructionCardPreviewModal(props: Props) {
  const { instructionItem, visible, onCancel } = props
  return instructionItem
    ? (
      <Modal
        className="instruction-card-preview-modal"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        closable={false}
      >
        <InstructionCardPreview instructionItem={instructionItem} />
      </Modal>
      )
    : null
}

InstructionCardPreviewModal.fragments = {
  InstructionCardPreviewModal: gql`
    fragment InstructionCardPreviewModal on InstructionCard {
      ...InstructionCardPreview
    }
    ${InstructionCardPreview.fragments.InstructionCardPreview}
  `,
}
export default InstructionCardPreviewModal
