import { gql } from '@apollo/client'

import { OrderFragment } from '../fragment'

export const ordersQuery = gql`
  query OrdersQuery(
    $query: OrdersQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    orders(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...Order
        appointment {
          id
          startDate
        }
      }
      total
      limit
      page
    }
  }
  ${OrderFragment}
`
