import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Input, InputNumber } from 'antd'
import React from 'react'

import type {
  CreateProductInput,
  Product,
  UpdateProductInput,
} from '../../../../graphql/types'

const { TextArea } = Input
const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

type ProductInput = CreateProductInput | UpdateProductInput
type ProductFormProps = ProductInput & {
  id?: string
}

type Props = FormComponentProps<ProductFormProps> & {
  item?: Product
}

function FormProduct(props: Props) {
  const { form, item } = props
  const { getFieldDecorator } = form

  return (
    <>
      {item?.id && (
        <FormItem {...formItemLayout} label="出貨品項 ID">
          {getFieldDecorator('id', { initialValue: item.id })(
            <div>{item.id}</div>,
          )}
        </FormItem>
      )}
      <FormItem {...formItemLayout} label="出貨品項編號 (四個數字)">
        {getFieldDecorator('serialNumber', {
          initialValue: item?.serialNumber ?? '',
          rules: [{ required: true, message: '請輸入' }],
        })(<Input />)}
      </FormItem>
      <FormItem {...formItemLayout} label="出貨品項名稱">
        {getFieldDecorator('name', {
          initialValue: item?.name ?? '',
          rules: [{ required: true, message: '請輸入' }],
        })(<Input />)}
      </FormItem>
      <FormItem {...formItemLayout} label="定價">
        {getFieldDecorator('price', {
          initialValue: item?.price ?? 0,
          rules: [{ required: true, message: '請輸入' }],
        })(<InputNumber />)}
      </FormItem>
      <FormItem {...formItemLayout} label="規格">
        {getFieldDecorator('spec', {
          initialValue: item?.spec ?? '',
        })(<TextArea autoSize={{ minRows: 3 }} />)}
      </FormItem>
      <FormItem {...formItemLayout} label="成本">
        {getFieldDecorator('cost', {
          initialValue: item?.cost ?? 0,
          rules: [{ required: true, message: '請輸入' }],
        })(<InputNumber />)}
      </FormItem>
    </>
  )
}

export default FormProduct
