import { gql } from '@apollo/client'
import { Radio } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import Form from 'antd/lib/form'
import { keys, map } from 'ramda'
import React from 'react'

import type {
  CreatePatientInput,
} from '../../../../graphql/types'
import {
  AnglesClassification,
} from '../../../../graphql/types'
import { formItemLayout } from './utils/layouts'

export type FormSymptomFields = Pick<CreatePatientInput, 'symptoms'>
export type FormSymptomInitialValues = Partial<FormSymptomFields>

interface Props {
  form: FormInstance<FormSymptomFields>
  initialValues?: FormSymptomInitialValues
}

export const symptomsMapping = {
  anteriorCrowding: 'Anterior Crowding',
  posteriorCrowding: 'Posterior Crowding',
  openBite: 'Open Bite',
  deepBite: 'Deep Bite',
  highCanine: 'High Canine',
  anteriorCrossbite: 'Anterior Crossbite',
  posteriorCrossbite: 'Posterior Crossbite',
  bimaxillaryProtrusion: 'Bimaxillary Protrusion',
  vShapeArch: 'V Shape Arch',
}

function AnglesClassificationRadioGroup(props) {
  return (
    <Radio.Group {...props}>
      <Radio value={AnglesClassification.Class1}>class1</Radio>
      <Radio value={AnglesClassification.Class2}>class2</Radio>
      <Radio value={AnglesClassification.Class3}>class3</Radio>
    </Radio.Group>
  )
}

function FormPatientSymptoms(props: Props) {
  const { form, initialValues } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item
        name={['symptoms', 'leftMolarRelationship']}
        label="左側臼齒安格斯分類(正常, 暴牙, 戽斗)"
      >
        <AnglesClassificationRadioGroup />
      </Form.Item>

      <Form.Item
        name={['symptoms', 'rightMolarRelationship']}
        label="右側臼齒安格斯分類(正常, 暴牙, 戽斗)"
      >
        <AnglesClassificationRadioGroup />
      </Form.Item>

      <h3 style={{ marginLeft: '40px' }}>症狀</h3>
      {map<keyof typeof symptomsMapping, JSX.Element>(key => (
        <Form.Item
          key={key}
          name={['symptoms', key]}
          label={symptomsMapping[key]}
        >
          <Radio.Group>
            <Radio value={false}>No</Radio>
            <Radio value>Yes</Radio>
          </Radio.Group>
        </Form.Item>
      ))(keys(symptomsMapping))}
    </Form>
  )
}

FormPatientSymptoms.fragments = {
  FormPatientSymptoms: gql`
    fragment FormPatientSymptoms on Patient {
      id
      symptoms {
        # 左臼齒安格斯分類
        leftMolarRelationship
        # 右臼齒安格斯分類
        rightMolarRelationship
        anteriorCrossbite
        anteriorCrowding
        bimaxillaryProtrusion
        deepBite
        highCanine
        openBite
        posteriorCrossbite
        posteriorCrowding
        vShapeArch
      }
    }
  `,
}

export { FormPatientSymptoms }

export default FormPatientSymptoms
