import { gql } from '@apollo/client'
import PatientLink from '@sov/ui/src/components/Link/Patient'
import { holidayUtils } from '@sov/utils'
import { DatePicker, Form, Input } from 'antd'
import type { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  BasicFieldsFragment,
  CreateStageGeneralFormFragment,
} from '../../../../../graphql/types'
import {
  StageType,
} from '../../../../../graphql/types'

const FormItem = Form.Item
const TextArea = Input.TextArea

const FormTitle = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
`

export const formItemLayout = {
  labelCol: { span: 8 },
  labelAlign: 'left' as const,
  wrapperCol: { span: 16 },
}

export interface BasicFieldsProps {
  patientItem?: BasicFieldsFragment
}

function BasicFields(props: BasicFieldsProps) {
  const { patientItem } = props

  if (!patientItem)
    return null

  return (
    <>
      <FormItem label="病患" {...formItemLayout}>
        <PatientLink item={patientItem} style={{ color: 'black' }} />
      </FormItem>
    </>
  )
}

BasicFields.fragment = gql`
  fragment BasicFields on Patient {
    id
    ...PatientLink
  }
  ${PatientLink.fragment}
`

export interface BasicFormFields {
  expectedShippingDate: moment.Moment
  note: string
}

interface BasicFormProps {
  form: FormInstance<BasicFormFields>
  stageType: StageType
  noCapacityDates: moment.Moment[]
}

function BasicForm(props: BasicFormProps) {
  const { form, stageType, noCapacityDates } = props
  const initialValues = {
    // @ts-ignore
    expectedShippingDate: holidayUtils.addWeekDaysWithHoliday(moment(), 7),
    note: '',
  }

  return (
    <Form form={form} initialValues={initialValues} requiredMark={false}>
      {/* 建模單預計出貨，是目前進行中報告預計出貨 - 7 工作日 */}
      {stageType !== StageType.Mold && (
        <FormItem
          name="expectedShippingDate"
          rules={[
            {
              required: true,
            },
          ]}
          label="預計出貨"
          {...formItemLayout}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            allowClear={false}
            disabledDate={date =>
              noCapacityDates.filter(x => x.isSame(date, 'd')).length > 0
              || date.isBefore(moment(), 'd')
              || (holidayUtils.isHoliday(date)
              && !holidayUtils.isForceBusinessDay(date))}
          />
        </FormItem>
      )}
      <FormItem name="note" label="本工單注意事項" {...formItemLayout}>
        <TextArea autoSize={{ minRows: 3 }} style={{ width: '300px' }} />
      </FormItem>
    </Form>
  )
}

interface Props {
  form: FormInstance<any>
  stageType: StageType
  children: React.ReactElement | ((props: any) => JSX.Element)
  patientItem?: CreateStageGeneralFormFragment
  noCapacityDates: moment.Moment[]
}

export function CreateStageGeneralForm(props: Props) {
  const {
    form: stageForm,
    stageType,
    patientItem,
    children,
    noCapacityDates,
  } = props
  const { t } = useTranslation()
  return (
    <>
      <FormTitle>{`新增${t(`stage.type.${stageType}`)}工單`}</FormTitle>
      <BasicFields patientItem={patientItem} />
      {typeof children === 'function'
        ? children({ form: stageForm })
        : React.cloneElement(children, { form: stageForm })}
      <BasicForm
        form={stageForm}
        stageType={stageType}
        noCapacityDates={noCapacityDates}
      />
    </>
  )
}

CreateStageGeneralForm.fragment = gql`
  fragment CreateStageGeneralForm on Patient {
    id
    ...BasicFields
  }
  ${BasicFields.fragment}
`
