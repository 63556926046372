import { gql } from '@apollo/client'
import type {
  StageNameConfig,
} from '@sov/common'
import {
  getStageAffix,
  getStageSerialNumber,
  getStageTypeLabel,
} from '@sov/common'
import { isEmpty } from 'ramda'
import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { StageNameFragment } from '../codegen/types'

interface StageNameElementProps extends StageNameConfig {
  item: StageNameFragment
}

const StageTypeLabel: FC<StageNameElementProps> = (props) => {
  const { item, isExternal } = props
  const { t } = useTranslation()

  return (
    <span className="type">{getStageTypeLabel(t, item, { isExternal })}</span>
  )
}

const StageAffix: FC<StageNameElementProps> = (props) => {
  const { item } = props
  const affix = getStageAffix(item)

  return isEmpty(affix) ? null : <span className="affix">{affix}</span>
}

const StageSerialNumber: FC<StageNameElementProps> = (props) => {
  const { t } = useTranslation()
  return (
    <span className="serialNumber">{getStageSerialNumber(t, props.item)}</span>
  )
}

interface StageNameProps extends StageNameElementProps {
  className?: string
  showAffix?: boolean
  showType?: boolean
  showSerialNumber?: boolean
}

function StageName(props: StageNameProps) {
  const {
    className,
    showAffix = true,
    showSerialNumber = true,
    showType = true,
    ...restProps
  } = props

  return (
    <span className={className}>
      {showType && <StageTypeLabel {...restProps} />}
      {' '}
      {showAffix && <StageAffix {...restProps} />}
      {showSerialNumber && <StageSerialNumber {...restProps} />}
    </span>
  )
}

StageName.fragment = gql`
  fragment StageName on Stage {
    id
    type
    ... on MoldStage {
      serialNumber
    }
    ... on EvalStage {
      serialNumber
      isBeforePrint
    }
    ... on DesignStage {
      serialNumber
    }
    ... on PrintStage {
      serialNumber
    }
    ... on AccessoryStage {
      accessoryCode
    }
  }
`

export default StageName
