import { CalendarOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Button, DatePicker, Row, Tag, Typography, message } from 'antd'
import type { DatePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import type {
  TagExpectedShippingDateStageItemFragment,
  UpdateDesignExpectedShippingDateMutation,
  UpdateDesignExpectedShippingDateMutationVariables,
} from '../../../../graphql/types'
import {
  StageStatus,
} from '../../../../graphql/types'

const ClickableTag = styled(Tag)<{ couldUpdateExpectedShippingDate: boolean }>`
  cursor: ${props =>
    props.couldUpdateExpectedShippingDate ? 'pointer' : 'auto'};
  border: none;
  border-radius: 4px;
  background-color: #fafafa;
  &:hover {
    ${props =>
      props.couldUpdateExpectedShippingDate && 'background-color: #f2f2f2;'}
  }
`

const HiddenDatePicker = styled(DatePicker)`
  visibility: hidden;
  width: 0;
  padding: 0;
`

const updateDesignExpectedShippingDate = gql`
  mutation UpdateDesignExpectedShippingDate(
    $id: ID!
    $payload: UpdateDesignStageInput!
  ) {
    updateDesignStage(id: $id, payload: $payload) {
      id
    }
  }
`

interface TagExpectedShippingDateProps {
  stageItem: TagExpectedShippingDateStageItemFragment
  handleRefetch: () => Promise<void>
}

function TagExpectedShippingDate(props: TagExpectedShippingDateProps) {
  const { stageItem, handleRefetch } = props
  const { id, expectedShippingDate, status } = stageItem

  const couldUpdateExpectedShippingDate = status === StageStatus.Started

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState<moment.Moment>()
  const [update] = useMutation<
    UpdateDesignExpectedShippingDateMutation,
    UpdateDesignExpectedShippingDateMutationVariables
  >(updateDesignExpectedShippingDate)

  const shouldCloseCalendar = useRef(true)

  const handleOpenChange = (open: boolean) => {
    if (open === false && shouldCloseCalendar.current)
      setOpen(false)
    else
      setOpen(true)
  }
  const handleChange: DatePickerProps['onChange'] = (date) => {
    shouldCloseCalendar.current = false
    date && setSelectedDate(date)
  }
  const handleUpdate = async () => {
    if (!selectedDate)
      return

    try {
      setLoading(true)
      await update({
        variables: {
          id,
          payload: {
            expectedShippingDate: selectedDate.toDate(),
          },
        },
        update: async (cache, { data }) => {
          if (data?.updateDesignStage) {
            await handleRefetch()
            setLoading(false)
            setOpen(false)
            shouldCloseCalendar.current = true
            message.success('預計出貨已更新')
          }
        },
      })
    }
    catch (error) {
      message.error(error)
      setLoading(false)
    }
  }

  const renderExtraFooter: DatePickerProps['renderExtraFooter'] = () => (
    <Row justify="space-between" align="middle">
      <Typography.Link onClick={() => setSelectedDate(moment())}>
        今天
      </Typography.Link>
      <Button
        type="primary"
        size="small"
        loading={loading}
        onClick={handleUpdate}
      >
        確定
      </Button>
    </Row>
  )

  const disabledDate: DatePickerProps['disabledDate'] = date =>
    date.startOf('day').isBefore(moment().startOf('day'))

  return (
    <>
      <HiddenDatePicker
        value={selectedDate}
        open={open}
        // @ts-ignore
        showToday={false}
        renderExtraFooter={renderExtraFooter}
        disabledDate={disabledDate}
        onOpenChange={handleOpenChange}
        onChange={handleChange}
      />
      <ClickableTag
        icon={<CalendarOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />}
        couldUpdateExpectedShippingDate={couldUpdateExpectedShippingDate}
        onClick={() =>
          couldUpdateExpectedShippingDate && handleOpenChange(true)}
      >
        <Typography.Text type="secondary">
          預計出貨：
          {expectedShippingDate
          && moment(expectedShippingDate).format('YYYY-MM-DD')}
        </Typography.Text>
      </ClickableTag>
    </>
  )
}

TagExpectedShippingDate.fragments = {
  TagExpectedShippingDateStageItem: gql`
    fragment TagExpectedShippingDateStageItem on DesignStage {
      id
      status
      expectedShippingDate
    }
  `,
}

export default TagExpectedShippingDate
