import { TaskType } from './types'

export const filteredWorkingTaskTypeList = [
  TaskType.CompleteDesign,
  TaskType.CompleteAlign,
  TaskType.CompletePredesign,
  TaskType.CompleteScan,
  TaskType.CompleteSplit,
  TaskType.ConfirmDesign,
]
