import { Button } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import { includes } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

import type { AllPrivilege } from '../../../../graphql/types'
import { authContext } from '../../../context'
import { getAuthPrivileges } from '../../../utils'

type SubjectType = React.MouseEvent<HTMLElement, MouseEvent>
function useTakeOnce({ handleClick }) {
  const subject = new Subject<SubjectType>()

  useEffect(() => {
    /** component unmount 要 unsubscribe subject */
    return () => {
      subject.unsubscribe()
    }
  }, [])

  subject.pipe(take(1)).subscribe(handleClick)

  return {
    handleTakeOnceClick: () => subject.next(),
  }
}

/** UI 更新前只會觸發一次點擊事件 */
export type OnceButtonProps = ButtonProps & {
  label: string
  requiredPrivilege?: AllPrivilege
}

function OnceButton(props: OnceButtonProps) {
  const { label, requiredPrivilege, ...buttonProps } = props
  const auth = useContext(authContext)
  const privilegesOfUser = getAuthPrivileges(auth)
  const hasRequiredPrivilege = requiredPrivilege
    ? includes(requiredPrivilege, privilegesOfUser)
    : true

  if (!hasRequiredPrivilege)
    return null

  const { handleTakeOnceClick } = useTakeOnce({
    handleClick: buttonProps.onClick,
  })

  return (
    <Button {...buttonProps} onClick={handleTakeOnceClick}>
      {label}
    </Button>
  )
}

interface UpdateButtonProps {
  isVisible: boolean
  onClick: ButtonProps['onClick']
}

function UpdateButton({ isVisible, onClick }: UpdateButtonProps) {
  if (!isVisible)
    return null

  return <OnceButton label="更新資料" onClick={onClick} type="primary" />
}

export { OnceButton, UpdateButton }
