import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { Button, Form, Row, Spin } from 'antd'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import type {
  PatientInvoicesQueryQuery,
  PatientInvoicesQueryVariables,
} from '../../../graphql/types'
import { Section } from '../../components/layout/Page'
import type { InvoiceSortType } from '../../components/table/Invoice'
import InvoiceTable from '../../components/table/Invoice'

const patientInvoicesQuery = gql`
  query PatientInvoicesQuery(
    $id: ID!
    $query: InvoiceQuery
    $page: Int
    $limit: Int
    $sort: String
  ) {
    patient(id: $id) {
      id
      payment {
        type
      }
      invoices(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...InvoiceTable
        }
        limit
        page
        total
      }
    }
  }
  ${InvoiceTable.fragment}
`

interface RouteProps {
  patientId: string
}

function PatientInvoice() {
  const match = useRouteMatch<RouteProps>()
  const patientId = match.params.patientId

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<any, InvoiceSortType>({
      limit: 200,
      sort: '-creditDate',
    })
  const { data, loading } = useQuery<
    PatientInvoicesQueryQuery,
    PatientInvoicesQueryVariables
  >(patientInvoicesQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      id: patientId,
      query: {},
      ...tableQuery,
      ...paginateQuery,
    },
  })

  const patient = data?.patient

  if (loading)
    return <Spin />

  if (!patient)
    return null

  const invoices = patient.invoices
  const currentTotalPrice = invoices.docs.reduce(
    (acc, cur) => acc + cur.totalPrice,
    0,
  )

  return (
    <Section>
      <Row justify="space-between" gutter={8}>
        <Form.Item label="本頁合計金額">{currentTotalPrice}</Form.Item>
        <Button onClick={handleTableReset}>刷新</Button>
      </Row>
      <InvoiceTable
        handleChange={handleTableChange}
        loading={loading}
        sortInfo={tableQuery.sort}
        source={invoices}
      />
    </Section>
  )
}

export default PatientInvoice
