import 'moment/locale/zh-tw'

import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { LoadingOutlined } from '@ant-design/icons'
import { useQuery, useSubscription } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Badge, Col, Input, List, Row, Space, Spin } from 'antd'
import moment from 'moment'
import { adjust, assocPath, filter, findIndex, head, map, prepend } from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import styled from 'styled-components'

import { conversationsWithLatestMessageQuery } from '../../../../graphql/conversation/query/conversationQuerys'
import { onMessageAddedSubscription } from '../../../../graphql/message/subscription/onMessageAdded'
import type {
  ConversationsWithLatestMessageQueryEdges,
  ConversationsWithLatestMessageQueryQuery,
  ConversationsWithLatestMessageQueryQueryVariables,
  ImageMessage,
  OnMessageAddedSubscriptionSubscription,
  OnMessageAddedSubscriptionVariables,
  TextMessage,
} from '../../../../graphql/types'
import {
  MessageType,
  SubscriptionType,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { isItemOwner } from '../../../utils'

const { Search } = Input

interface LatestMessageListProps {
  selectedConversation: string
  handleBlockClick: (conversation: string) => void
}

interface LatestMessageBlockProps {
  node: ConversationsWithLatestMessageQueryEdges['node']
  handleClick: (patient: string) => void
  handelIsSelected: (id: string) => void
}

interface ContentContainerProps {
  hasUreadMessages: boolean
}

interface LatestMessageFormInput {
  patientName: string
}

type LatestMessageFormProps = {
  handleChange: (form: FormComponentProps['form']) => void
  subject: Subject<any>
} & FormComponentProps<LatestMessageFormInput>

const LatestMessageListContainer = styled.div`
  width: 260px;
  height: calc(100vh - 200px);
  overflow: scroll;
  overscroll-behavior: none;
  border-right: 1px solid #e8e8e8;
`

const PatientNameContainer = styled.div`
  font-size: 17px;
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  color: black;
`

const ContentContainer = styled.div<ContentContainerProps>`
  width: 220px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  color: grey;
`

const PatientSearchForm = Form.create<LatestMessageFormProps>()(
  ({ form, handleChange, subject }: LatestMessageFormProps) => {
    const { getFieldDecorator } = form

    useEffect(() => subject.unsubscribe(), [])

    subject.pipe(debounceTime(500)).subscribe(() => handleChange(form))

    return (
      <Form>
        {getFieldDecorator('patientName')(
          <Search
            placeholder="搜尋病患"
            onChange={value => subject.next(value)}
            style={{ width: 200, margin: '10px 0', marginLeft: '25px' }}
          />,
        )}
      </Form>
    )
  },
)

function LatestMessageBlock({ node, handleClick }: LatestMessageBlockProps) {
  const auth = useContext(authContext)
  if (!auth)
    return null

  const {
    id,
    latestMessage,
    numberOfUnreadMessages,
    patient: { name: patientName },
  } = node

  const { type, content, created, creator } = latestMessage as TextMessage &
    ImageMessage

  const { id: creatorId, name: creatorName } = creator
  const hasUreadMessages = numberOfUnreadMessages !== 0
  const renderCreator = isItemOwner(creatorId, auth) ? '你' : `${creatorName}`

  return (
    <div onClick={() => handleClick(id)}>
      <Row justify="space-between">
        <Col>
          <Space size={0}>
            <PatientNameContainer>{patientName}</PatientNameContainer>
            <Badge count={numberOfUnreadMessages} />
          </Space>
        </Col>
        <Col>{moment(created).fromNow()}</Col>
      </Row>
      <ContentContainer
        hasUreadMessages={hasUreadMessages}
      >
        {`${renderCreator} : ${
        type === MessageType.Image ? '傳送了一張圖片' : content
      }`}
      </ContentContainer>
    </div>
  )
}

function LatestMessageList({
  selectedConversation,
  handleBlockClick,
}: LatestMessageListProps) {
  const $Sub = new Subject()
  const auth = useContext(authContext)
  if (!auth)
    return null

  const [patientSearch, setPatientSearch] = useState('')
  const [realTimeEdges, setRealTimeEdges] = useState<
    ConversationsWithLatestMessageQueryEdges[]
  >([])

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const {
    data: conversationsWithLatestMessageData,
    loading: conversationsWithLatestMessageLoading,
    fetchMore: fetchMoreConversationsWithLatestMessage,
    loading,
  } = useQuery<
    ConversationsWithLatestMessageQueryQuery,
    ConversationsWithLatestMessageQueryQueryVariables
  >(conversationsWithLatestMessageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      first: 10,
      query: {
        patientName: patientSearch,
      },
    },
    skip: !auth,
    onCompleted: (data) => {
      if (
        data?.conversationsWithLatestMessage
        && !conversationsWithLatestMessageLoading
      ) {
        const edges = data.conversationsWithLatestMessage.edges
        const realTimeEdges = map((edge) => {
          const isSelected = selectedConversation === edge.node.id
          return isSelected
            ? assocPath(['node', 'numberOfUnreadMessages'], 0, edge)
            : edge
        }, edges)
        setRealTimeEdges(realTimeEdges)
        if (!selectedConversation)
          handleBlockClick(realTimeEdges[0]?.node.id)
      }
    },
  })

  const {
    loading: conversationWithLatestMessageLoading,
    refetch: refetchLatestMessage,
  } = useQuery<
    ConversationsWithLatestMessageQueryQuery,
    ConversationsWithLatestMessageQueryQueryVariables
  >(conversationsWithLatestMessageQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      first: 1,
      query: {
        patientName: patientSearch,
      },
    },
    skip: !auth,
    onCompleted: (data) => {
      if (
        data?.conversationsWithLatestMessage
        && !conversationWithLatestMessageLoading
      ) {
        const newEdges = data.conversationsWithLatestMessage.edges
        if (head(newEdges)) {
          const conversationWithLatestMessageEdge
            = head<ConversationsWithLatestMessageQueryEdges>(newEdges)
          const conversationWithLatestMessageNode
            = conversationWithLatestMessageEdge!.node
          const isSelected
            = selectedConversation === conversationWithLatestMessageNode.id
          const realTimeNewLatestMessageEdge = isSelected
            ? assocPath(
              ['node', 'numberOfUnreadMessages'],
              0,
              conversationWithLatestMessageEdge,
            )
            : conversationWithLatestMessageEdge
          const filterOldLatestCommetsEdge
            = filter<ConversationsWithLatestMessageQueryEdges>(
              edge => conversationWithLatestMessageNode.id !== edge.node.id,
              realTimeEdges,
            )
          const newLatestEdges = prepend(
            realTimeNewLatestMessageEdge,
            filterOldLatestCommetsEdge,
          )
          setRealTimeEdges(
            newLatestEdges as ConversationsWithLatestMessageQueryEdges[],
          )
        }
      }
    },
  })

  useSubscription<
    OnMessageAddedSubscriptionSubscription,
    OnMessageAddedSubscriptionVariables
  >(onMessageAddedSubscription, {
    variables: {
      payload: {
        subscriptionType: SubscriptionType.All,
      },
    },
    fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.onMessageAdded)
        refetchLatestMessage()
    },
  })

  useEffect(() => {
    const selectedEdgeIndex = findIndex(
      edge => edge.node.id === selectedConversation,
      realTimeEdges,
    )
    const newRealTimeEdge = adjust(
      selectedEdgeIndex,
      edge => assocPath(['node', 'numberOfUnreadMessages'], 0, edge),
      realTimeEdges,
    )
    setRealTimeEdges(newRealTimeEdge)
  }, [selectedConversation])

  const handleScroll = (e) => {
    const list = e.target
    const isAtBottom = list.scrollHeight - list.clientHeight === list.scrollTop
    if (
      isAtBottom
      && conversationsWithLatestMessageData
      && conversationsWithLatestMessageData.conversationsWithLatestMessage
      && conversationsWithLatestMessageData.conversationsWithLatestMessage.pageInfo
        .hasNextPage
    ) {
      fetchMoreConversationsWithLatestMessage({
        variables: {
          after:
            conversationsWithLatestMessageData.conversationsWithLatestMessage
              .pageInfo.endCursor,
          first: 10,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (
            fetchMoreResult
            && fetchMoreResult.conversationsWithLatestMessage
            && previousResult.conversationsWithLatestMessage
          ) {
            const { pageInfo, edges: moreEdges }
              = fetchMoreResult.conversationsWithLatestMessage
            const newEdges = [...realTimeEdges, ...moreEdges]
            const result = {
              conversationsWithLatestMessage: {
                __typename:
                  previousResult.conversationsWithLatestMessage.__typename,
                edges: newEdges,
                pageInfo,
              },
            }
            setRealTimeEdges(newEdges)
            return result
          }
          return previousResult
        },
      })
    }
  }

  const handelIsSelected = (id: string) => {
    const selectedEdgeIndex = findIndex(
      edge => edge.node.id === id,
      realTimeEdges,
    )
    const newRealTimeEdge = adjust(
      selectedEdgeIndex,
      edge => assocPath(['node', 'numberOfUnreadMessages'], 0, edge),
      realTimeEdges,
    )
    setRealTimeEdges(newRealTimeEdge)
  }

  const handleChange = (form: FormComponentProps['form']) => {
    const { getFieldValue } = form
    const patientName = getFieldValue('patientName')
    setPatientSearch(patientName)
  }

  return (
    <LatestMessageListContainer onScroll={handleScroll}>
      <PatientSearchForm handleChange={handleChange} subject={$Sub} />
      {conversationsWithLatestMessageData?.conversationsWithLatestMessage && (
        <>
          {loading && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              style={{ display: 'block', marginBottom: '10px' }}
            />
          )}
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={realTimeEdges.map(edge => ({
              node: edge.node,
            }))}
            renderItem={(item: any, index) => {
              const isSelected = item.node.id === selectedConversation
              return (
                <List.Item
                  style={{
                    backgroundColor: isSelected ? '#e6f7ff' : '',
                  }}
                >
                  <LatestMessageBlock
                    node={item.node}
                    key={index}
                    handelIsSelected={handelIsSelected}
                    handleClick={handleBlockClick}
                  />
                </List.Item>
              )
            }}
          />
        </>
      )}
    </LatestMessageListContainer>
  )
}
export default LatestMessageList
