import { TableQuery } from '@sov/common'
import { Button, Table, Tag } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type { EmployeeInfoFragment } from '../../../graphql/types'
import EmployeeLink from '../link/employee'

interface Props<T> {
  source?: {
    docs: T[]
    page?: number
    total: number
    limit: number
  }
  loading?: boolean
  handleChange: TableProps<T>['onChange']
}

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

// approver 只取 id, name
type TableFields = EmployeeInfoFragment

function EditButtonLink({ record }: { record: TableFields }) {
  return (
    <Button size="small" type="primary">
      <Link to={`/employees/${record.id}`}>編輯</Link>
    </Button>
  )
}

function EmployeeTable<T extends TableFields>({
  source = defaultSource,
  loading,
  handleChange,
}: Props<T>) {
  const { t } = useTranslation()
  const columns: ColumnProps<T>[] = [
    {
      title: '姓名',
      align: 'center',
      width: 60,
      dataIndex: 'name',
      key: 'name',
      render: (_text, record) => <EmployeeLink item={record} />,
    },
    {
      title: '角色',
      align: 'center',
      width: 60,
      dataIndex: 'role',
      key: 'role',
      render: text => `${t(`employee.role.${text}`)}`,
    },
    {
      title: '到職日',
      align: 'center',
      width: 120,
      dataIndex: 'onBoardDate',
      key: 'onBoardDate',
      render: (_value, record) =>
        moment(record.onBoardDate).format('YYYY-MM-DD'),
    },
    {
      title: '可進行的任務',
      align: 'left',
      width: 150,
      dataIndex: 'taskOwner',
      key: 'taskOwner',
      render: taskOwner => (
        <>
          {taskOwner.map(taskType => (
            <Tag key={taskType}>{t(`task.type.${taskType}`)}</Tag>
          ))}
        </>
      ),
    },
    {
      title: '核假人員',
      align: 'left',
      width: 150,
      dataIndex: 'approvers',
      key: 'approvers',
      render: (_value, record) => (
        <>
          {record.approvers.map((approver, index) => (
            <Tag key={index}>{approver.name}</Tag>
          ))}
        </>
      ),
    },
    {
      title: '狀態',
      align: 'center',
      width: 60,
      dataIndex: 'resigned',
      key: 'resigned',
      render: (text) => {
        return text ? '已離職' : '在職中'
      },
    },
    {
      title: '動作',
      align: 'center',
      width: 60,
      key: 'actions',
      render: (_text, record) => <EditButtonLink record={record} />,
    },
  ]

  const { docs, page, total, limit } = source

  return (
    <Table<T>
      rowKey="id"
      size="small"
      columns={columns as any}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何員工' }}
      onChange={handleChange}
      pagination={TableQuery.getAntdPagination({
        page: page || 1,
        total,
        limit,
      })}
    />
  )
}

export default EmployeeTable
