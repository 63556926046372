import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'

import type {
  TasksQuery,
  TasksQueryQuery,
  TasksQueryVariables,
} from '../../../../graphql/types'
import TableTask from '../../../components/table/Task'

const tasksQuery = gql`
  query TasksQuery(
    $query: TasksQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...TableTask
      }
      total
      limit
      page
    }
  }
  ${TableTask.fragment}
`

export type TaskFilterType = Pick<TasksQuery, 'type' | 'status'>

export type TaskSorterField = 'startedByEmployee' | 'expectedShippingDate'

export function useTaskList({ isMobile }: { isMobile?: boolean }) {
  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<TaskFilterType, TaskSorterField>({
      sort: '-completedByOwner',
      limit: isMobile ? 20 : 100,
    })

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, loading, refetch } = useQuery<
    TasksQueryQuery,
    TasksQueryVariables
  >(tasksQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        ...tableQuery.filters,
      },
      ...paginateQuery,
    },
  })

  const tasks = data && data.tasks ? data.tasks : undefined

  return {
    tasks,
    loading,
    refetch,
    sortInfo: tableQuery.sort,
    filterInfo: tableQuery.filters,
    handleTableChange,
    handleClear: handleTableReset,
  }
}
