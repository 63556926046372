import { Avatar, Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import type { EntityType } from '../../../../graphql/types'

interface Props {
  entityType: EntityType
  loading?: boolean
  size?: number
  src?: string
}

function AccountAvatar(props: Props) {
  const avatars = import('./images')
  const [defaultImages, setDefaultImages] = useState({})

  useEffect(() => {
    avatars.then(res => setDefaultImages(res.default))
  }, [])

  const { entityType, size, src, loading = false, ...restProps } = props
  const imgSrc = loading || !src ? defaultImages[entityType] : src
  return (
    <Spin spinning={loading}>
      <Avatar size={size} src={imgSrc} {...restProps} />
    </Spin>
  )
}

export default AccountAvatar
