import { gql } from '@apollo/client'

import { LeaveInfoFragment } from '../fragment'

export const removeLeaveMutation = gql`
  mutation RemoveLeave($id: ID!) {
    removeLeave(id: $id) {
      ...LeaveInfo
    }
  }
  ${LeaveInfoFragment}
`
