import { Column } from '@ant-design/charts'
import { gql, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import type { Moment } from 'moment'
import rawMoment from 'moment'
import { extendMoment } from 'moment-range'
import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  StageCapacityQueryQuery,
  StageCapacityQueryQueryVariables,
  StageCapacitySystem,
} from '../../../graphql/types'
import {
  StageType,
  StatisticInterval,
} from '../../../graphql/types'

// @ts-ignore
const moment = extendMoment(rawMoment)

const stageCapacityQuery = gql`
  query StageCapacityQuery($query: StageCapacityQuery!) {
    stageCapacity(query: $query) {
      stageType
      startDate
      endDate
      points
    }
    system(configType: MAX_WORK_CAPACITY) {
      ... on StageCapacitySystem {
        stageCapacity {
          capacityPerType {
            stageType
            pointsPerUnit
            maxPoints
          }
          maxPoints
        }
      }
    }
  }
`

interface StageCapacityChartProps {
  stageType: StageType.Eval | StageType.Print
  setNoCapacityDates: (noCapacityDates: Moment[]) => void
}

const StageCapacityChart: FC<StageCapacityChartProps> = (props) => {
  const { stageType, setNoCapacityDates } = props
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { t } = useTranslation()
  const startDate = moment().startOf('day').subtract(0, 'day')
  const endDate = moment().startOf('day').add(21, 'day')

  const { data, loading } = useQuery<
    StageCapacityQueryQuery,
    StageCapacityQueryQueryVariables
  >(stageCapacityQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    variables: {
      query: {
        interval: StatisticInterval.Day,
        stageTypes: [StageType.Eval, StageType.Print],
        startDate,
        endDate,
      },
    },
    onCompleted: (data) => {
      const stageCapacity = data?.stageCapacity ?? []
      const stageCapacitySystem = data?.system as StageCapacitySystem
      const stageCapacityCeiling = stageCapacitySystem.stageCapacity
      const noCapacityDates = Array.from(
        moment.range(startDate, endDate).by('days'),
      ).filter((date) => {
        let overload = false

        /** 找出當天資料 */
        const data = stageCapacity.filter(x =>
          moment(x.startDate).isSame(date),
        )

        /** 已超過總上限 */
        const evalPoints
          = data.find(x => x.stageType === StageType.Eval)?.points ?? 0
        const printPoints
          = data.find(x => x.stageType === StageType.Print)?.points ?? 0
        if (
          evalPoints + printPoints
          >= (stageCapacityCeiling?.maxPoints ?? 0)
        )
          overload = true

        /** 已超過本單類型上限 */
        const points = data.find(x => x.stageType === stageType)?.points ?? 0
        if (
          points
          >= (stageCapacityCeiling?.capacityPerType.find(
            x => x.stageType === stageType,
          )?.maxPoints ?? 0)
        )
          overload = true

        return overload
      })

      setNoCapacityDates(noCapacityDates)
    },
    onError: (error) => {
      toErrorPage(error.message)
    },
  })

  if (loading)
    return <div style={{ padding: 12 }}>產能資料讀取中</div>

  const stageCapacity = data?.stageCapacity ?? []
  const stageCapacitySystem = data?.system as StageCapacitySystem
  const stageCapacityCeiling = stageCapacitySystem.stageCapacity
  const getMaxPoints = (type: StageType) =>
    stageCapacityCeiling?.capacityPerType.find(x => x.stageType === type)
      ?.maxPoints ?? 0

  const chartData = stageCapacity.map(x => ({
    date: moment(x.startDate).format('MM-DD'),
    value: x.points,
    type: t(`stage.type.${x.stageType}`),
  }))

  return (
    <>
      <Column
        style={{ padding: '6px 12px 6px 12px' }}
        width={760 - 12 * 2}
        height={100}
        data={chartData}
        xField="date"
        yField="value"
        seriesField="type"
        isStack
        annotations={[
          {
            type: 'line',
            start: { date: 'min', value: stageCapacityCeiling?.maxPoints ?? 0 },
            end: { date: 'max', value: stageCapacityCeiling?.maxPoints ?? 0 },
            style: {
              stroke: '#F4664A',
            },
          },
        ]}
        meta={{
          type: { alias: '類別' },
          date: { alias: '日期' },
          value: { alias: '日產能' },
        }}
      />
      <div style={{ padding: '0 12px 6px 12px', color: 'red' }}>
        <div>
          (1) 總點數上限：
          {stageCapacityCeiling?.maxPoints ?? 0}
        </div>
        <div>
          (2)
          {' '}
          {t(`stage.type.${stageType}`)}
          單個別點數上限：
          {getMaxPoints(stageType)}
          {' '}
          (
          {
            stageCapacityCeiling?.capacityPerType.find(
              x => x.stageType === stageType,
            )?.pointsPerUnit
          }
          {' '}
          / 單)
        </div>
      </div>
    </>
  )
}

export default StageCapacityChart
