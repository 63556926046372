import { gql } from '@apollo/client'

export default {
  full: gql`
    fragment PatientInfoFull on Patient {
      status
      # 病患 id
      id
      # 病患姓名
      name
      # 代號 (客戶模型編號)
      patientCode
      # 塔位 (在哪個櫃子，方便尋找用)
      cabinetCode
      # 性別
      gender
      # 聊天室
      conversation {
        id
      }
      # 負責診所
      clinic {
        id
        name
        specialContract
        technicians {
          id
          name
        }
      }
      # 負責醫師
      doctor {
        id
        name
      }
      # 負責業務
      sales {
        id
        name
        phone
      }
      # 負責客服
      customerService {
        id
        name
      }
      # 負責技師
      technician {
        id
        name
      }
      # 下次約診日
      nextAppointmentDate
      # 主要治療牙弓 (上排, 下排)
      treatArches {
        upper
        lower
      }
      note {
        # 設計組注意事項
        design
        # 牙套組注意事項
        braces
        # 業務組注意事項
        sales
      }
      # 照片（均儲存於 S3）
      photos {
        # 口外照(外部照片)
        # 正面照
        frontFace {
          id
          filename
          path
          thumbnailPath
        }
        # 露齒照
        frontFaceWithTeeth {
          id
          filename
          path
          thumbnailPath
        }
        # 45度側面照
        sideFace45 {
          id
          filename
          path
          thumbnailPath
        }
        sideFace45WithTeeth {
          id
          filename
          path
          thumbnailPath
        }
        # 側面照
        sideFace {
          id
          filename
          path
          thumbnailPath
        }
        sideFaceWithTeeth {
          id
          filename
          path
          thumbnailPath
        }

        # 口內照(口腔內部)
        # 正面觀
        frontInside {
          id
          filename
          path
          thumbnailPath
        }
        # 左面觀
        leftInside {
          id
          filename
          path
          thumbnailPath
        }
        # 右面觀
        rightInside {
          id
          filename
          path
          thumbnailPath
        }
        # 上顎咬合面
        upperJawInside {
          id
          filename
          path
          thumbnailPath
        }
        # 下顎咬合面
        lowerJawInside {
          id
          filename
          path
          thumbnailPath
        }

        # X光照
        # Pano X光片
        pano {
          id
          filename
          path
          thumbnailPath
        }
        # Ceph X光片
        ceph {
          id
          filename
          path
          thumbnailPath
        }
      }
      # 主訴
      chiefComplaint
      # 顏面觀
      profile {
        # 下顎位置是否對齊顏面中線 & 相關指示
        mandibularMidlineToFacialMidline {
          offset
          instruction
        }
        # 咬合平面是否歪斜 & 相關指示
        occulusalPlane {
          occulusalPlaneStatus
          instruction
        }
        # 上下唇是否超出 E-line & 相關指示
        estheticLine {
          lipsEstheticLineRelation
          instruction
        }
        # Ceph 分析
        cephAnalysis
        # 下臉部 1/3 比例
        faceRatio
        # 咬肌狀況
        occulusalMuscle
        # 笑齦狀況
        hasGummySmile
        # 微笑曲線
        smileLine
        # 齒頰間隙
        hasBuccalCorridor
      }
      # 口內觀
      intraOral {
        # 缺牙
        missingTeeth
        # 不移動的牙齒
        teethDontMove
        # 治療前要拔除的牙齒
        teethWillExtract
        # TMJ
        temporomandibularJointDisordersTreatStatus
        # 牙周問題
        periodontalDiseaseTreatStatus
        # 蛀牙(殘根)
        residualRoot {
          hasResidualRoot
          instruction
        }
        # 阻生齒
        impactedTooth {
          hasImpactedTooth
          instruction
        }
        # 未萌發智齒
        uneruptedWisdomTooth {
          hasUneruptedWisdomTooth
          instruction
        }
        # 上顎門齒是否對齊顏面中線 & 相關指示
        maxillarMidlineToFacialMidline {
          offset
          instruction
        }
        # 下顎門齒是否對齊上顎中線 & 相關指示
        mandibularMidlineToMaxillarMidline {
          offset
          instruction
        }
      }
      # 症狀
      symptoms {
        # 左臼齒安格斯分類
        leftMolarRelationship
        # 右臼齒安格斯分類
        rightMolarRelationship
        anteriorCrossbite
        anteriorCrowding
        bimaxillaryProtrusion
        deepBite
        highCanine
        openBite
        posteriorCrossbite
        posteriorCrowding
        vShapeArch
      }
      # 醫師主要治療指示
      doctorInstruction {
        # 醫師備註
        note
        # 是否 IPR
        ipr
        # 是否可配合骨釘
        tads
        # 是否可搭配其他矯正裝置 ex: Hook, Button
        otherOrthdontalTools
        # 是否可搭配活動裝置
        functionalAppliance
        # 是否保留空間 & 備註
        maintainSpace {
          isMaintainingSpace
          note
        }
        # 是否可將假牙換成臨時假牙 & 備註
        changeProsthesisToTemp {
          need
          instruction
        }
        # 空間不足時，是否可拔牙
        extractToothWhenNoSpace {
          intention
          toothType
        }
      }
    }
  `,
  short: gql`
    fragment PatientInfoShort on Patient {
      # 病患 id
      id
      # 診所 id 與名稱
      clinic {
        id
        name
        specialContract
      }
      doctor {
        id
        name
      }
      technician {
        id
        name
      }
      sales {
        id
        name
      }
      # 病患姓名
      name
      # 下次約診日
      nextAppointmentDate
      note {
        # 業務組事項
        sales
      }
      photos {
        # 口外照(外部照片)
        # 正面照
        frontFace {
          id
          filename
          path
          thumbnailPath
        }
      }
    }
  `,
}
