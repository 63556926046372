import { gql, useMutation } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery } from '@sov/common'
import { DisplayPatientBrand, InstructionPopover, Link } from '@sov/ui'
import { Table, Typography } from 'antd'
import type { ColumnProps, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { filter } from 'ramda'
import React from 'react'

import type {
  QcListTableFragment,
  UpdatePatientCabinetCodeMutation,
  UpdatePatientCabinetCodeVariables,
} from '../../../graphql/types'
import {
  AuxiliaryType,
} from '../../../graphql/types'
import { getIsClinicNearCompany } from '../../utils'

const { Paragraph } = Typography
const { StageLink, ClinicLink, PatientLink } = Link
type ColumnItem = QcListTableFragment

const updatePatientCabinetCodeMutation = /* GraphQL */ gql`
  mutation updatePatientCabinetCode($id: ID!, $payload: UpdatePatientInput!) {
    updatePatient(id: $id, payload: $payload) {
      id
      cabinetCode
    }
  }
`

type Props<T> = TableProps<T> & {
  cursor: any
  data: ColumnItem[]
  handleChange: TableProps<T>['onChange']
  removeStageInstructionCard: (stageId: string) => void
  sortInfo?: AntSorterType<'cabinetCode'>
  hiddenColumnKeys?: ColumnProps<ColumnItem>['key'][]
}

function QCListTable(props: Props<ColumnItem>) {
  const {
    title,
    cursor,
    data,
    handleChange,
    removeStageInstructionCard,
    locale,
    loading,
    rowSelection,
    sortInfo,
    hiddenColumnKeys,
  } = props
  const [update] = useMutation<
    UpdatePatientCabinetCodeMutation,
    UpdatePatientCabinetCodeVariables
  >(updatePatientCabinetCodeMutation)
  const handleUpdateCabinetCode = async (patientId: string, value: string) => {
    await update({
      variables: {
        id: patientId,
        payload: {
          cabinetCode: value,
        },
      },
    })
  }

  const columns: ColumnProps<ColumnItem>[] = [
    {
      align: 'center',
      title: '#',
      width: 30,
      key: '#',
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      align: 'center',
      title: '開始時間',
      width: 60,
      dataIndex: 'startedByEmployee',
      key: 'startedByEmployee',
      render: (text, record) => {
        const startedByEmployee = record.startedByEmployee
        return <div>{moment(startedByEmployee).format('YYYY-MM-DD HH:mm')}</div>
      },
    },
    {
      align: 'center',
      title: '特約',
      width: 30,
      dataIndex: 'specialContract',
      key: 'specialContract',
      render: (text, record) => {
        if (!record)
          return

        const specialContract = record.patient.clinic.specialContract
        return <div>{specialContract ? 'V' : ''}</div>
      },
    },
    {
      align: 'center',
      title: '品牌',
      width: 60,
      dataIndex: ['patient', 'payment', 'brand'],
      key: 'brand',
      render: (text, record) => {
        return <DisplayPatientBrand value={record.patient.payment.brand} />
      },
    },
    {
      align: 'center',
      title: '診所',
      width: 60,
      dataIndex: 'clinic',
      key: 'clinic',
      render: (text, record) => {
        if (!record)
          return

        const clinicItem = record.patient.clinic
        const isClinicNearCompany = getIsClinicNearCompany(
          clinicItem.deliveryMethod,
        )
        return (
          <ClinicLink
            item={clinicItem}
            style={{
              color: 'black',
              backgroundColor: isClinicNearCompany ? 'unset' : 'yellow',
            }}
          >
            {!isClinicNearCompany && `${clinicItem.name} (送)`}
          </ClinicLink>
        )
      },
    },
    {
      align: 'center',
      title: '病患',
      width: 60,
      dataIndex: 'patient',
      key: 'patient',
      render: (text, record) => {
        if (!record)
          return

        const patientItem = record.patient
        return <PatientLink item={patientItem} style={{ color: 'black' }} />
      },
    },
    {
      align: 'center',
      title: '上下牙套數',
      width: 60,
      key: 'stage.designStage',
      render: (text, record) => {
        if (
          record.stage.__typename === 'PrintStage'
          && record.stage.designStage
        ) {
          const designStage = record.stage.designStage
          return (
            <div>
              {designStage.upperModelCount}
              {' '}
              /
              {designStage.lowerModelCount}
            </div>
          )
        }
      },
    },
    {
      align: 'center',
      title: 'Step',
      width: 60,
      dataIndex: 'stage',
      key: 'stage',
      render: (text, record) => {
        if (!record)
          return

        const stageItem = record.stage
        /** 預計出貨在未來三天以內的，都特別標記 */
        const isExpectedShippingDateComing = record.stage.expectedShippingDate
          ? moment(record.stage.expectedShippingDate).isSameOrBefore(
            moment().add(3, 'days'),
          )
          : false
        return (
          <StageLink
            item={stageItem}
            style={
              isExpectedShippingDateComing ? { backgroundColor: 'yellow' } : {}
            }
          />
        )
      },
    },
    {
      align: 'center',
      title: '代號',
      width: 100,
      dataIndex: 'patientCode',
      key: 'patientCode',
      render: (text, record) => {
        if (!record)
          return

        const patientCode = record.patient.patientCode
        return <div>{patientCode}</div>
      },
    },
    {
      align: 'center',
      title: '塔位',
      width: 100,
      dataIndex: 'cabinetCode',
      key: 'cabinetCode',
      /** 已在 page component 針對此欄位做排序，此處不再實作排序相關邏輯 */
      sorter: true,
      sortOrder: sortInfo?.field === 'cabinetCode' ? sortInfo.order : undefined,
      render: (text, record) => {
        if (!record)
          return

        const cabinetCode = record.patient.cabinetCode || '無塔位'

        return (
          <Paragraph
            editable={{
              onChange: (value) => {
                handleUpdateCabinetCode(record.patient.id, value)
              },
            }}
          >
            {cabinetCode}
          </Paragraph>
        )
      },
    },
    {
      align: 'center',
      title: '小卡',
      width: 30,
      dataIndex: 'card',
      key: 'card',
      render: (text, record) => {
        if (
          (record.stage.__typename === 'PrintStage'
          || record.stage.__typename === 'AccessoryStage')
          && record.stage.instructionCard
        )
          return 'V'
        else
          return <div />
      },
    },
    {
      align: 'center',
      title: 'A袋',
      width: 30,
      dataIndex: 'aBag',
      key: 'aBag',
      render: (text, record) => {
        const stageItem = record.stage
        if (
          stageItem.__typename === 'PrintStage'
          && stageItem.instructionCard
        ) {
          const attachments = filter(
            auxiliary => auxiliary.type === AuxiliaryType.Attachment,
            stageItem.instructionCard?.auxiliaries ?? [],
          )
          if (attachments.length >= 6)
            return 'V'
          else
            return <div />
        }
        else {
          return <div />
        }
      },
    },
    {
      align: 'center',
      title: '指示卡',
      width: 60,
      dataIndex: 'instructionCard',
      key: 'instructionCard',
      render: (text, record) => {
        if (
          record.stage.__typename === 'PrintStage'
          || record.stage.__typename === 'AccessoryStage'
        ) {
          return (
            <InstructionPopover
              instructionItem={record.stage.instructionCard}
              handleRemove={() => removeStageInstructionCard(record.stage.id)}
              withEditLink
            />
          )
        }
        else {
          return null
        }
      },
    },
  ]

  return (
    <Table
      title={title}
      columns={columns.filter(x => !hiddenColumnKeys?.includes(x.key))}
      dataSource={data}
      expandIconColumnIndex={1}
      loading={loading}
      locale={locale}
      onChange={handleChange}
      pagination={cursor ? TableQuery.getAntdPagination(cursor) : false}
      rowKey="id"
      rowSelection={rowSelection}
      size="small"
    />
  )
}

QCListTable.fragment = gql`
  fragment QCListTable on Task {
    id
    startedByEmployee
    patient {
      ...PatientLink
      clinic {
        ...ClinicLink
        specialContract
        deliveryMethod
      }
      currentEvalStage {
        ...StageLink
      }
      patientCode
      cabinetCode
      payment {
        brand
      }
    }
    stage {
      expectedShippingDate
      ...StageLink
      ... on PrintStage {
        instructionCard {
          instruction
          stepCode
          auxiliaries {
            type
            toothPosition
          }
          ...InstructionPopover
        }
        designStage {
          id
          upperModelCount
          lowerModelCount
        }
      }
      ... on AccessoryStage {
        instructionCard {
          ...InstructionPopover
        }
      }
    }
  }
  ${InstructionPopover.fragments.InstructionPopover}
  ${StageLink.fragment}
  ${ClinicLink.fragment}
  ${PatientLink.fragment}
`

export default QCListTable
