import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import type { PatientWithDoctorLinkFragment } from '../../codegen/types'
import type { PatientLinkProps } from './Patient'
import PatientLink from './Patient'

const Wrapper = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`
const DoctorName = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

interface Props extends PatientLinkProps {
  className?: string
  item: PatientWithDoctorLinkFragment
}

function PatientWithDoctorLink(props: Props) {
  const { item, className, ...restProps } = props
  return (
    <Wrapper className={className}>
      <PatientLink {...restProps} item={item} />
      <DoctorName>{item.doctor.name}</DoctorName>
    </Wrapper>
  )
}

PatientWithDoctorLink.fragment = gql`
  fragment PatientWithDoctorLink on Patient {
    id
    ...PatientLink
    doctor {
      id
      name
    }
  }
  ${PatientLink.fragment}
`

export default PatientWithDoctorLink
