import { gql } from '@apollo/client'

import PatientInfoFragment from '../fragment'

export const createPatientMutation = gql`
  mutation CreatePatient($payload: CreatePatientInput!) {
    createPatient(payload: $payload) {
      ...PatientInfoFull
    }
  }
  ${PatientInfoFragment.full}
`
