import { gql } from '@apollo/client'

export const ClinicInfoFragment = gql`
  fragment ClinicInfo on Clinic {
    id
    type
    name
    fullname
    location {
      address
      country
      district
    }
    phone
    email
    specialContract
    doctors {
      id
      name
    }
    accountManagers {
      id
      name
    }
    technicians {
      id
      name
    }
    sales {
      id
      name
      phone
    }
    customerService {
      id
      name
    }
  }
`
