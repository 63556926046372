import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'

const OrderRowLabel = styled.span`
  margin-right: 16px;
`

interface OrderRowProps {
  label: string
}

const OrderRow: FC<OrderRowProps> = (props) => {
  const { children, label } = props

  return (
    <div>
      <OrderRowLabel>
        {label}
        :
      </OrderRowLabel>
      {children}
    </div>
  )
}

export default OrderRow
