/* eslint max-len: 'off' */
import React from 'react'

function BottomJaw() {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>下顎</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="下顎" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="002-teeth-copy-2"
          transform="translate(2.000000, 12.000000)"
          fillRule="nonzero"
        >
          <path
            d="M23.7191724,18.7586207 C19.3131034,18.7586207 18.2102069,18.5853793 17.0554483,14.9451034 C16.214069,12.2918621 16.0242759,8.96827586 16,6.17158621 C15.9884138,4.82868966 17.1657931,2.15724138 18.7453793,1.1062069 C21.3633103,-0.635586207 24.937931,-0.0435862069 27.9144828,0.990344828 C29.8228966,1.65296552 31.6,3.52827586 31.4377931,6.17158621 C31.2584828,9.09627586 30.5550345,12.7652414 29.7395862,15.5862069 C28.9401379,18.3525517 26.2427586,18.7586207 23.7191724,18.7586207 Z"
            id="Shape"
            fill="#E9F0F2"
          />
          <path
            d="M8.28082759,18.7586207 C12.6868966,18.7586207 13.7897931,18.5853793 14.9445517,14.9451034 C15.785931,12.2924138 15.9751724,8.96882759 16,6.17213793 C16.0115862,4.82924138 14.8342069,2.1577931 13.2546207,1.10675862 C10.6366897,-0.635586207 7.06206897,-0.0435862069 4.08551724,0.990344828 C2.17710345,1.65296552 0.4,3.52827586 0.562206897,6.17158621 C0.741517241,9.09627586 1.44496552,12.7652414 2.26041379,15.5862069 C3.05986207,18.3525517 5.75724138,18.7586207 8.28082759,18.7586207 Z"
            id="Shape"
            fill="#E9F0F2"
          />
          <path
            d="M4.99917241,23.1724138 L27.0008276,23.1724138 C29.7616552,23.1724138 32,20.934069 32,18.1732414 L32,12.7966897 C32,12.128 31.4582069,11.5862069 30.7895172,11.5862069 L30.6968276,11.5862069 C30.4198621,12.9914483 30.0921379,14.3685517 29.7401379,15.5862069 C28.9401379,18.3525517 26.2427586,18.7586207 23.7191724,18.7586207 C19.3131034,18.7586207 18.2102069,18.5853793 17.0554483,14.9451034 C16.7255172,13.9051034 16.4982069,12.7608276 16.3393103,11.5862069 L15.6606897,11.5862069 C15.5017931,12.7602759 15.2744828,13.9045517 14.9445517,14.9451034 C13.7897931,18.5853793 12.6868966,18.7586207 8.28082759,18.7586207 C5.75724138,18.7586207 3.05931034,18.3525517 2.25986207,15.5862069 C1.90786207,14.3685517 1.58068966,12.9914483 1.30317241,11.5862069 L1.21048276,11.5862069 C0.541793103,11.5862069 0,12.128 0,12.7966897 L0,18.1732414 C0,20.934069 2.23834483,23.1724138 4.99917241,23.1724138 Z"
            id="Shape"
            fill="#ED9893"
          />
        </g>
      </g>
    </svg>
  )
}

export default BottomJaw
