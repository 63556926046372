import React from 'react'

import type { Auxiliary } from '../../../codegen/types'
import { AuxiliaryType } from '../../../codegen/types'
import { getIndex, getOffsetX, isTopTeeth } from '../../InstructionUtils'
import config from '../../InstructionUtils/config'

interface Props {
  item: Auxiliary
}

function Tad(props: Props) {
  const { item } = props
  const { type, toothPosition, auxiliaryPosition } = item

  // type 不對或者資料有問題則回傳 null
  if (type !== AuxiliaryType.Tad)
    return null

  // 微調數值
  const xAdjustment = isTopTeeth(toothPosition) ? -5 : 5
  const yAdjustment = isTopTeeth(toothPosition) ? -29 : 29
  const width = getIndex(toothPosition) > 5 ? 10 : 6
  // 根據 auxiliary position(近心遠心) 調整 x 軸
  const xOffset
    = getOffsetX(toothPosition, auxiliaryPosition, width * 2) + xAdjustment
  const yOffset = 0 + yAdjustment
  const cx = config.tooth[toothPosition].centerＸ + xOffset
  const cy = isTopTeeth(toothPosition)
    ? config.top.cy + yOffset
    : config.bottom.cy + yOffset
  const translate = `translate(${cx}, ${cy})`
  const scale = `scale(0.5)`
  const rotate = isTopTeeth(toothPosition)
    ? `rotate(0, ${cx}, ${cy})`
    : `rotate(180, ${cx}, ${cy})`
  return (
    <path
      fill="#52C41A"
      fillRule="evenodd"
      stroke="#FFF"
      transform={rotate + translate + scale}
      d="M11.762 1.505c-1.467.063-2.785.7-3.735 1.692C6.975 4.295 6.375 5.829 6.522
  7.5h3.215l.789 15h2.948l.791-15.031 3.23-.219c.073-1.676-.599-3.196-1.75-4.252-1.01-.925-2.39-1.496-3.983-1.493z"
    />
  )
}

export default Tad
