import React from 'react'

export default () => (
  <svg
    width="235"
    height="468"
    viewBox="0 0 235 468"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="235"
      height="468"
    >
      <rect width="235" height="468" fill="#FAFAFA" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="235" height="468" fill="#FAFAFA" />
      <g opacity="0.3">
        <rect x="-66" y="180" width="258" height="96" rx="48" fill="#1890FF" />
        <rect
          x="-66"
          y="180"
          width="258"
          height="96"
          rx="48"
          fill="url(#paint0_linear)"
          fillOpacity="0.8"
        />
      </g>
      <g opacity="0.5">
        <rect x="-51" y="190" width="228" height="76" rx="38" fill="#1890FF" />
        <rect
          x="-51"
          y="190"
          width="228"
          height="76"
          rx="38"
          fill="url(#paint1_linear)"
          fillOpacity="0.8"
        />
      </g>
      <rect x="-39" y="200" width="204" height="56" rx="28" fill="#1890FF" />
      <rect
        x="-39"
        y="200"
        width="204"
        height="56"
        rx="28"
        fill="url(#paint2_linear)"
        fillOpacity="0.8"
      />
      <path
        d="M167 78L177.032 78C194.135 78 208 91.8647 208 108.968L208 174"
        stroke="#EDEDED"
        strokeWidth="4.12903"
      />
      <path
        d="M195.903 163.484L208.29 175.871L220.678 163.484"
        stroke="#EDEDED"
        strokeWidth="4.12903"
        strokeLinecap="round"
      />
      <path
        d="M85 298L74.9677 298C57.8647 298 44 311.865 44 328.968L44 394"
        stroke="#EDEDED"
        strokeWidth="4.12903"
      />
      <path
        d="M56.0967 383.484L43.7096 395.871L31.3225 383.484"
        stroke="#EDEDED"
        strokeWidth="4.12903"
        strokeLinecap="round"
      />
      <circle
        cx="211.5"
        cy="243.5"
        r="10"
        fill="#FFD37D"
        stroke="#FFE7B9"
        strokeWidth="3"
      />
      <circle
        cx="43"
        cy="291"
        r="6.5"
        fill="#FFD37D"
        stroke="#FFE7B9"
        strokeWidth="3"
      />
      <circle
        cx="144"
        cy="148"
        r="6.5"
        fill="#FFD37D"
        stroke="#FFE7B9"
        strokeWidth="3"
      />
      <rect
        x="-35"
        y="43"
        width="186"
        height="68"
        rx="16"
        fill="#F2F2F2"
        fillOpacity="0.5"
        style={{ mixBlendMode: 'multiply' }}
      />
      <rect
        x="101"
        y="338"
        width="186"
        height="68"
        rx="34"
        fill="#F2F2F2"
        style={{ mixBlendMode: 'multiply' }}
      />
      <rect
        x="285"
        y="35"
        width="97"
        height="30"
        rx="15"
        transform="rotate(-180 285 35)"
        fill="#F2F2F2"
        style={{ mixBlendMode: 'multiply' }}
      />
      <rect
        x="64"
        y="126"
        width="40"
        height="30"
        rx="15"
        fill="#F2F2F2"
        fillOpacity="0.5"
        style={{ mixBlendMode: 'multiply' }}
      />
      <rect
        x="-49"
        y="126"
        width="97"
        height="30"
        rx="15"
        fill="#F2F2F2"
        style={{ mixBlendMode: 'multiply' }}
      />
      <rect
        x="154"
        y="288"
        width="97"
        height="30"
        rx="15"
        fill="#F2F2F2"
        fillOpacity="0.5"
        style={{ mixBlendMode: 'multiply' }}
      />
      <rect
        x="57"
        y="422"
        width="194"
        height="30"
        rx="15"
        fill="#F2F2F2"
        fillOpacity="0.5"
        style={{ mixBlendMode: 'multiply' }}
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-32.9634"
        y1="276"
        x2="-10.8551"
        y2="159.302"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.571675" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-21.8049"
        y1="266"
        x2="63.3063"
        y2="44.5229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-12.8781"
        y1="256"
        x2="40.9993"
        y2="85.757"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)
