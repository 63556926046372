import { QuestionCircleFilled } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Col, Row, Tooltip, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import type { CheckPointGroupFragment } from '../../../../graphql/types'
import type { CheckPointCardProps } from './CheckPointCard'
import CheckPointCard from './CheckPointCard'
import { LIGHT_GREY } from './common'
import StepCard from './StepCard'

const StepCardWrapper = styled.div`
  padding: 24px;
  background-color: ${LIGHT_GREY};
`

interface CheckPointGroupProps extends CheckPointCardProps {
  checkPoint: CheckPointGroupFragment
}

function CheckPointGroup(props: CheckPointGroupProps) {
  const { isEditing, checkPoint, isFinal, handleFocus, handleRefetch } = props

  return (
    <Row>
      <Col span={12}>
        <CheckPointCard
          checkPoint={checkPoint}
          isEditing={isEditing}
          isFinal={isFinal}
          handleFocus={handleFocus}
          handleRefetch={handleRefetch}
        />
      </Col>
      {isEditing && (
        <Col span={12}>
          <StepCardWrapper>
            <Typography.Text type="secondary">
              對應設計工單列表
              <Tooltip
                placement="right"
                title="顯示目前已完成的，設計工單列表中的治療內容"
              >
                <QuestionCircleFilled
                  style={{
                    marginLeft: '8px',
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Tooltip>
            </Typography.Text>
            {checkPoint.designStages.map(stage => (
              <StepCard key={stage.id} stage={stage} />
            ))}
          </StepCardWrapper>
        </Col>
      )}
    </Row>
  )
}

CheckPointGroup.fragments = {
  CheckPointGroup: gql`
    fragment CheckPointGroup on CheckPoint {
      ...CheckPointCard
      designStages {
        ...StepCard
      }
    }
    ${CheckPointCard.fragments.CheckPointCard}
    ${StepCard.fragments.StepCard}
  `,
}

export default CheckPointGroup
