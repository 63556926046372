import { gql } from '@apollo/client'
import type { AntSorterType } from '@sov/common'
import { TableQuery } from '@sov/common'
import { Currency, DisplayPatientBrand, DisplayPatientStatus } from '@sov/ui'
import ClinicLink from '@sov/ui/src/components/Link/Clinic'
import PatientLink from '@sov/ui/src/components/Link/Patient'
import StageLink from '@sov/ui/src/components/Link/Stage'
import { Table } from 'antd'
import type { ColumnProps, TablePaginationConfig, TableProps } from 'antd/lib/table'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  PatientTablePaymentFragment,
  PatientsQuery,
} from '../../../graphql/types'
import {
  PatientBrand,
  PatientSource,
  PatientStatus,
} from '../../../graphql/types'
import BlockPatientRisk from '../display/PatientRisk'

export type PatientFilterType = Pick<
  PatientsQuery,
  'status' | 'paymentBrand' | 'paymentSource'
>

export type PatientSorterField =
  | 'autoIncrement'
  | 'patientPrice'
  | 'base'
  | 'referral'
  | 'extension'
  | 'discount'

const defaultSource = {
  docs: [],
  page: 1,
  total: 1,
  limit: 10,
}

interface Props extends TableProps<PatientTablePaymentFragment> {
  handleSearch?: () => void
  handleReset?: () => void
  filterInfo?: PatientFilterType
  handleChange: TableProps<PatientTablePaymentFragment>['onChange']
  sortInfo?: AntSorterType<PatientSorterField>
  source?: {
    docs: PatientTablePaymentFragment[]
    page?: number
    total: number
    limit: number
  }
}

// 病患付費方案表格
export function PatientTablePayment(props: Props) {
  const {
    filterInfo = {},
    handleChange,
    source = defaultSource,
    scroll,
    loading = false,
    sortInfo,
  } = props
  const { t } = useTranslation()

  const columns: ColumnProps<PatientTablePaymentFragment>[] = [
    {
      title: '流水號',
      width: 60,
      align: 'center',
      key: 'autoIncrement',
      dataIndex: 'autoIncrement',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'autoIncrement' ? sortInfo.order : undefined,
      render: (_, record) => {
        return record.autoIncrement
      },
    },
    {
      title: '病患',
      width: 60,
      align: 'center',
      dataIndex: 'patient',
      key: 'patient',
      render: (_value, record) => {
        return <PatientLink item={record} />
      },
    },
    {
      title: '診所',
      width: 60,
      align: 'center',
      dataIndex: 'clinic',
      key: 'clinic',
      render: (_value, record) => {
        return <ClinicLink item={record.clinic} />
      },
    },
    {
      title: '品牌',
      width: 60,
      align: 'center',
      dataIndex: 'paymentBrand',
      key: 'paymentBrand',
      filters: Object.values(PatientBrand).map(x => ({
        text: <DisplayPatientBrand value={x} />,
        value: x,
      })),
      filteredValue: filterInfo.paymentBrand ?? [],
      filterMultiple: true,
      render: (_value, record) => {
        return <DisplayPatientBrand value={record.payment.brand} />
      },
    },
    {
      title: '客源',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'source'],
      key: 'paymentSource',
      filters: Object.values(PatientSource).map(x => ({
        text: t(`patient.source.${x}`),
        value: x,
      })),
      filteredValue: filterInfo.paymentSource ?? [],
      filterMultiple: true,
      render: (_value, record) => {
        return <div>{t(`patient.source.${record.payment.source}`)}</div>
      },
    },
    {
      title: '模式',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'type'],
      key: 'type',
      render: (_value, record) => {
        return <div>{t(`patient.type.${record.payment.type}`)}</div>
      },
    },
    {
      title: '終價',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'patientPrice'],
      key: 'patientPrice',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'payment.patientPrice' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.payment.patientPrice} />
      },
    },
    {
      title: '技工',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'base'],
      key: 'base',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'payment.base' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.payment.base} />
      },
    },
    {
      title: '轉介',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'referral'],
      key: 'referral',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'payment.referral' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.payment.referral} />
      },
    },
    {
      title: '延長',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'extension'],
      key: 'extension',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'payment.extension' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.payment.extension} />
      },
    },
    {
      title: '折扣',
      width: 60,
      align: 'center',
      dataIndex: ['payment', 'discount'],
      key: 'discount',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'payment.discount' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.payment.discount} />
      },
    },
    {
      title: '技工收入',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'techRevenue'],
      key: 'techRevenue',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'meta.techRevenue' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.meta.techRevenue} />
      },
    },
    {
      title: '技工成本',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'techCost'],
      key: 'techCost',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'meta.techCost' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.meta.techCost} />
      },
    },
    {
      title: '技工毛利',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'techProfit'],
      key: 'techProfit',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'meta.techProfit' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <Currency value={record.meta.techProfit} />
      },
    },
    {
      title: 'Step',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'finalStepNumber'],
      key: 'finalStepNumber',
      sorter: true,
      sortOrder:
        sortInfo?.field === 'meta.finalStepNumber' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return (
          <>
            {`${record.meta.currentStepNumber} / ${record.meta.finalStepNumber}`}
          </>
        )
      },
    },
    {
      title: '風險值',
      width: 60,
      align: 'center',
      dataIndex: ['meta', 'risk'],
      key: 'risk',
      sorter: true,
      sortOrder: sortInfo?.field === 'meta.risk' ? sortInfo.order : undefined,
      render: (_value, record) => {
        return <BlockPatientRisk value={record.meta.risk} />
      },
    },
    {
      title: '狀態',
      width: 80,
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      filters: Object.values(PatientStatus).map(status => ({
        text: <DisplayPatientStatus value={status} />,
        value: status,
      })),
      filteredValue: filterInfo.status ?? [],
      filterMultiple: true,
      render: (_value, record) => {
        return <DisplayPatientStatus value={record.status} />
      },
    },
  ]

  const { docs, page, total, limit } = source

  const paginationConfig: TablePaginationConfig = scroll
    ? {
        current: page || 1,
        total: total || 1,
        pageSize: limit || 1,
        size: 'small',
      }
    : TableQuery.getAntdPagination({ page: page || 1, total, limit })

  return (
    <Table<PatientTablePaymentFragment>
      scroll={scroll}
      rowKey="id"
      size="small"
      columns={columns}
      dataSource={docs}
      loading={loading}
      locale={{ emptyText: '系統中無任何病患' }}
      pagination={paginationConfig}
      onChange={handleChange}
    />
  )
}

PatientTablePayment.fragment = gql`
  fragment PatientTablePayment on Patient {
    autoIncrement
    clinic {
      ...ClinicLink
    }
    payment {
      brand
      source
      type
      patientPrice
      base
      referral
      extension
      discount
    }
    status
    patientCode
    cabinetCode
    ...PatientLink
    accountManager {
      id
      name
    }
    technician {
      id
      name
    }
    sales {
      id
      name
    }
    customerService {
      id
      name
    }
    meta {
      # 財務
      currentStepNumber
      finalStepNumber
      techRevenue
      techCost
      techProfit
      risk
      # 療程
      lastPrintStage {
        ...StageLink
      }
      lastPrintStageAt
    }
  }
  ${PatientLink.fragment}
  ${ClinicLink.fragment}
  ${StageLink.fragment}
`

export default PatientTablePayment
