import type { AuthContextType } from '@sov/common/src/auth/context'
import { message } from 'antd'
import useFetch from 'use-http'

export interface Credential {
  username: string
  password: string
}

function useLogin() {
  const redirect = window.location.origin
  const url = `${process.env.API_HOST}/auth/login?redirect=${redirect}`
  const options = {
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  const request = useFetch(url, options as any)

  const login = async (credential: Credential) => {
    /**
     * 成功的話會回傳 auth:
     * auth {
     *  // account 的欄位
     *  id
     *  username
     *  providers
     *    ...
     *  // populate 後 entity 的欄位
     *  entity: {
     *    id
     *    name
     *    role
     *    privileges
     *    ...
     *  }
     * }
     */
    const res: AuthContextType & { message?: string, status?: number }
      = await request.post(credential as any)
    // @TODO account migration

    if (!res) {
      await message.error(
        `無法連線伺服器，請稍後再試，如持續無法連線，請聯繫舒服美資訊部門`,
        3,
      )
    }
    else if (res.id && res.entity) {
      await message.success(`歡迎你回來!`)

      if (window.location.pathname === '/login')
        window.location.replace('/')
      else
        window.location.reload()
    }
    else if (res.status === 401) {
      // 登入未被驗證
      window.location.replace('/emailVerification')
    }
    else {
      await message.error(`登入失敗：${res?.message}`)
    }
  }
  return login
}

export { useLogin }
