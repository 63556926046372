import { gql } from '@apollo/client'
import { Form, Input, Radio, Select, Switch } from 'antd'
import type { FormInstance, FormProps } from 'antd/lib/form/Form'
import { map } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  CreateClinicInput,
} from '../../../../graphql/types'
import {
  ClinicType,
  Country,
  DeliveryMethod,
  Role,
} from '../../../../graphql/types'
import { ChinaDistricts, TaiwanDistricts } from '../../../customType'
import { DoctorSelect, EmployeeSelect } from '../Select'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}
// 不需轉換的fields
export const formPickedFields = [
  'type',
  'name',
  'fullname',
  'phone',
  'email',
  'location',
  'specialContract',
  'deliveryMethod',
] as const

// 確保 formfields 都是 CreateClinicInput 的 payload
const formFieldsNames: (keyof CreateClinicInput)[] = [
  ...formPickedFields,
  ...(['doctors', 'technicians', 'sales', 'customerService'] as const),
]
type FormFieldsNames = typeof formFieldsNames
type FormFieldsName = FormFieldsNames[number]

export type FormGeneralFields = Pick<CreateClinicInput, FormFieldsName>
export type FormPickedFields = Pick<
  CreateClinicInput,
  typeof formPickedFields[number]
>

export type FormInitialValues = Partial<FormGeneralFields>

const Option = Select.Option

function TypeRadio(props) {
  const { t } = useTranslation()
  return (
    <Radio.Group {...props}>
      {Object.values(ClinicType).map(method => (
        <Radio.Button key={method} value={method}>
          {t(`clinic.type.${method}`)}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

interface RegionSelectProps {
  form: FormInstance<FormGeneralFields>
}
function RegionSelect(props: RegionSelectProps) {
  const { form } = props
  const { t } = useTranslation()

  return (
    <Input.Group compact>
      <FormItem
        name={['location', 'country']}
        rules={[{ required: true, message: '請輸入' }]}
        style={{ marginBottom: 0 }}
        noStyle
      >
        <Select<Country>
          onChange={(value) => {
            const location = form.getFieldValue(['location'])
            if (value !== form.getFieldValue(location.country)) {
              form.setFieldsValue({
                location: {
                  district:
                    value === Country.Tw
                      ? TaiwanDistricts.TPE
                      : ChinaDistricts.SH,
                },
              })
            }
          }}
        >
          {map(
            country => (
              <Option key={country} value={country}>
                {t(`country.${country}`)}
              </Option>
            ),
            Object.values(Country),
          )}
        </Select>
      </FormItem>
      <FormItem dependencies={[['location', 'country']]} noStyle>
        {form => (
          <FormItem
            name={['location', 'district']}
            rules={[{ required: true, message: '請輸入' }]}
            noStyle
          >
            <Select dropdownMatchSelectWidth={false}>
              {map(
                district => (
                  <Option key={district} value={district}>
                    {t(`district.${district}`)}
                  </Option>
                ),
                Object.values(
                  form.getFieldValue(['location', 'country']) === Country.Tw
                    ? TaiwanDistricts
                    : ChinaDistricts,
                ),
              )}
            </Select>
          </FormItem>
        )}
      </FormItem>
    </Input.Group>
  )
}

function DeliveryMethodRadio(props) {
  const { t } = useTranslation()
  return (
    <Radio.Group {...props}>
      {Object.values(DeliveryMethod).map(method => (
        <Radio.Button key={method} value={method}>
          {t(`clinic.deliveryMethod.${method}`)}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

type ClinicFormProps = FormProps & {
  form: FormInstance<FormGeneralFields>
  initialValues: FormInitialValues
}
function ClinicForm(props: ClinicFormProps) {
  const { initialValues, form } = props

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <FormItem
        name="type"
        label="診所類型"
        rules={[{ required: true, message: '請選擇' }]}
      >
        <TypeRadio />
      </FormItem>
      <FormItem
        name="deliveryMethod"
        label="寄送方式"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <DeliveryMethodRadio />
      </FormItem>
      <FormItem
        name="name"
        label="診所簡稱"
        rules={[{ required: true, message: '請輸入', max: 5 }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="fullname"
        label="診所全名"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="診所地址"
        name={['location', 'address']}
        rules={[{ required: true, message: '請輸入' }]}
      >
        <Input addonBefore={<RegionSelect form={form} />} />
      </FormItem>
      <FormItem
        name="phone"
        label="診所電話"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <Input />
      </FormItem>
      <FormItem name="email" label="診所 email">
        <Input />
      </FormItem>
      <FormItem name="specialContract" label="特約" valuePropName="checked">
        <Switch />
      </FormItem>
      <FormItem
        name="doctors"
        label="旗下醫師"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <DoctorSelect style={{ width: '100%' }} mode="multiple" />
      </FormItem>
      <FormItem
        name="accountManagers"
        label="預設AM"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <EmployeeSelect
          showSearch
          mode="multiple"
          query={{
            roles: [Role.AccountManager, Role.Manager],
          }}
        />
      </FormItem>
      <FormItem
        name="technicians"
        label="預設技師"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <EmployeeSelect style={{ width: '100%' }} mode="multiple" />
      </FormItem>
      <FormItem
        name="sales"
        label="預設業務"
        rules={[{ required: true, message: '請輸入' }]}
      >
        <EmployeeSelect
          showSearch
          mode="multiple"
          query={{
            roles: [Role.Sales],
          }}
        />
      </FormItem>
      <FormItem
        name="customerService"
        label="預設客服"
        rules={[{ message: '請輸入' }]}
      >
        <EmployeeSelect
          showSearch
          query={{
            roles: [Role.CustomerService],
          }}
        />
      </FormItem>
    </Form>
  )
}

ClinicForm.fragments = {
  ClinicForm: gql`
    fragment ClinicForm on Clinic {
      id
      type
      name
      fullname
      location {
        address
        country
        district
      }
      phone
      email
      deliveryMethod
      specialContract
      doctors {
        id
      }
      accountManagers {
        id
      }
      technicians {
        id
      }
      sales {
        id
      }
      customerService {
        id
      }
    }
  `,
}

export default ClinicForm
