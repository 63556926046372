import { gql } from '@apollo/client'

export const updateInvoiceWithStageMutation = gql`
  mutation UpdateInvoiceWithStage(
    $id: ID!
    $payload: UpdateInvoiceWithStageInput!
  ) {
    updateInvoiceWithStage(id: $id, payload: $payload) {
      id
    }
  }
`

export const updateInvoiceWithoutStageMutation = gql`
  mutation UpdateInvoiceWithoutStage(
    $id: ID!
    $payload: UpdateInvoiceWithoutStageInput!
  ) {
    updateInvoiceWithoutStage(id: $id, payload: $payload) {
      id
    }
  }
`

export const updateInvoicePaymentMutation = gql`
  mutation UpdateInvoicePayment(
    $query: InvoiceQuery!
    $payload: UpdateInvoicePaymentInput!
  ) {
    updateInvoicePayment(query: $query, payload: $payload)
  }
`
