import { gql } from '@apollo/client'
import { Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import type { DoctorMenuFragment } from '../../../../graphql/types'
import BreadcrumbCreator from '../../layout/BreadcrumbCreator'
import Title from '../../layout/Title'

export const doctorMenuFragment = gql`
  fragment DoctorMenu on Doctor {
    id
    name
  }
`

interface Props {
  item: DoctorMenuFragment
  selectedKeys: string[]
}

function DoctorMenu(props: Props) {
  const { item, selectedKeys } = props

  return (
    <div style={{ marginBottom: -16 }}>
      <BreadcrumbCreator
        routes={[
          { key: 'Home' },
          { key: 'DoctorList' },
          {
            key: 'DoctorDetail',
            render: () => <Link to={`/doctors/${item.id}`}>{item.name}</Link>,
          },
        ]}
      />
      <Title route={{ key: 'DoctorDetail', render: () => item.name }} />
      <Menu mode="horizontal" selectedKeys={selectedKeys}>
        <Menu.Item key="doctor-detail">
          <Link to={`/doctors/${item.id}`}>醫師詳情</Link>
        </Menu.Item>
        <Menu.Item key="doctor-invoice-index">
          <Link to={`/doctors/${item.id}/invoices`}>醫師出貨單</Link>
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default DoctorMenu
