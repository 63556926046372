import { LeftOutlined } from '@ant-design/icons'
import { Button, Link as CustomLink } from '@sov/ui'
import { Switch } from 'antd'
import type { SwitchChangeEventHandler } from 'antd/lib/switch'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import type { EvalStage, StageLinkFragment } from '../../codegen/types'

const customIconStyle = {
  color: 'rgba(0, 0, 0, 0.65)',
}

const Container = styled.div`
  background-color: white;
  height: 64px;
  padding: 0 24px;
  display: flex;
  align-items: center;

  > * {
    flex: 1;
  }

  @media print {
    display: none;
  }
`

const IconContainer = styled.div`
  padding-left: 12px;
`

const CustomStyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 32px;
`

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`

const SwitchLabel = styled.div`
  margin-right: 8px;
`

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface Props {
  handleSwitchChange?: SwitchChangeEventHandler
  isCRM: boolean
  linkOfStage: string
  stage: StageLinkFragment
}

function AnalysisPreviewHeader(props: Props) {
  const { handleSwitchChange, isCRM, linkOfStage, stage } = props

  const evalStage = stage as EvalStage
  const title = `${evalStage.isBeforePrint ? '評估' : '比對'}報告 ${
    evalStage.isBeforePrint ? 'V' : 'E'
  }${evalStage.serialNumber}`

  return (
    <Container>
      {isCRM
        ? (
          <IconContainer>
            <CustomStyledLink to={linkOfStage}>
              <LeftOutlined style={customIconStyle} />
            </CustomStyledLink>
          </IconContainer>
          )
        : (
          <CustomStyledLink to={linkOfStage}>
            <Button buttonType="text">返回編輯</Button>
          </CustomStyledLink>
          )}
      <Title>
        <CustomLink.StageLink item={stage}>
          {' '}
          {title}
        </CustomLink.StageLink>
      </Title>
      <FlexEnd>
        {!isCRM && (
          <SwitchContainer>
            <SwitchLabel>顯示病患照片：</SwitchLabel>
            <Switch defaultChecked onChange={handleSwitchChange} />
          </SwitchContainer>
        )}
        <Button buttonType="primary" onClick={() => window.print()}>
          列印
        </Button>
      </FlexEnd>
    </Container>
  )
}

export default AnalysisPreviewHeader
