import { CloseOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import { AutoComplete, Avatar, Input, Modal, Popconfirm, message } from 'antd'
import type { SelectValue } from 'antd/lib/select'
import { find, groupBy, includes, map, pluck, propEq, reject } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import styled from 'styled-components'

import type {
  ConversationMemberInfoFragment,
  MemberSelectQueryQuery,
  MemberSelectQueryVariables,
  UpdateMembersMutationMutation,
  UpdateMembersMutationVariables,
} from '../../../../graphql/types'
import { getEntityTitle } from '../../../helpers/entity/getEntityTitle'
import { ConversationInfo, ConversationMemberInfo } from './fragments'

const MemberListContainer = styled.div`
  margin-top: 16px;
`

const MemberListTitle = styled.div`
  height: 32px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 2px solid #e8e8e8;
`

const MemberListContent = styled.div`
  padding: 16px;
  height: 320px;
  overflow: scroll;
`

function renderTitle(title: string) {
  return <span>{title === 'Doctor' ? '外部人員' : '內部員工'}</span>
}

function getGroupedMemberOptions(members: ConversationMemberInfoFragment[]) {
  const byTypename = groupBy<ConversationMemberInfoFragment>(member =>
    member.__typename === 'Doctor' ? 'Doctor' : 'Employee',
  )
  const groupedMembers = byTypename(members)
  return map(
    groupName => ({
      label: renderTitle(groupName),
      options: map(
        member => ({
          value: member.id,
          label: member.name,
        }),
        groupedMembers[groupName],
      ),
    }),
    Object.keys(groupedMembers),
  )
}

const memberSelectQuery = gql`
  query MemberSelectQuery($conversation: ID!, $query: ConversationMemberQuery) {
    conversationMemberOption(conversation: $conversation, query: $query) {
      ...conversationMemberInfo
    }
  }
  ${ConversationMemberInfo}
`

interface MemberAutocompleteSearchProps {
  conversation: MemberSelectQueryVariables['conversation']
  editingMembers: ConversationMemberInfoFragment[]
  onSelect: (selected: ConversationMemberInfoFragment) => void
}

function MemberAutocompleteSearch(props: MemberAutocompleteSearchProps) {
  const { conversation, editingMembers, onSelect } = props

  const [searchUser, setSearchUser] = useState('')
  // @TODO 這邊不確定要打的 query，因為理論上負責醫生跟診所還有主牙技師會內建在成員名單中，需要額外加入的應該只有其他 employee
  const { data } = useQuery<MemberSelectQueryQuery, MemberSelectQueryVariables>(
    memberSelectQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      variables: {
        conversation,
        query: {
          name: searchUser,
        },
      },
    },
  )
  const $Sub = new Subject<string>()
  const members = (data && data.conversationMemberOption) || []
  const memberUnselected = reject(
    member => includes(member, editingMembers),
    members,
  )
  useEffect(() => $Sub.unsubscribe.bind($Sub), [])

  const handleSearch = (value: string) => {
    setSearchUser(value)
  }

  const getMemberData = (id: string) => find(propEq('id', id), memberUnselected)

  const handelSelect = (value: SelectValue) => {
    if (typeof value === 'string') {
      const selectedMember = getMemberData(value)
      if (selectedMember)
        onSelect(selectedMember)

      setSearchUser('')
    }
  }

  $Sub.pipe(debounceTime(500)).subscribe(handleSearch)
  return (
    <div style={{ width: '400px' }}>
      <AutoComplete
        size="large"
        style={{ width: '100%' }}
        options={getGroupedMemberOptions(memberUnselected)}
        onSelect={handelSelect}
        onSearch={value => $Sub.next(value)}
        placeholder="請輸入成員名稱"
      >
        <Input suffix={<SearchOutlined />} />
      </AutoComplete>
    </div>
  )
}

interface MemberRowProps {
  name: string
  entityTitle: string
  onRemove: () => void
}
function MemberRow(props: MemberRowProps) {
  const { name, entityTitle, onRemove } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '38px',
        fontSize: '14px',
        marginBottom: '4px',
      }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Avatar icon={<UserOutlined />} style={{ marginRight: '12px' }} />
        <div style={{ width: '52px', color: 'rgba(0, 0, 0, 0.45)' }}>
          {entityTitle}
        </div>
        <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{name}</div>
      </div>
      <div
        style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: '16px' }}
      >
        <Popconfirm
          title="確定刪除此成員？"
          okText="刪除"
          cancelText="取消"
          okType="danger"
          onConfirm={onRemove}
        >
          <CloseOutlined />
        </Popconfirm>
      </div>
    </div>
  )
}
export const updateMembersMutation = gql`
  mutation updateMembersMutation($id: ID!, $entities: [ID!]!) {
    updateConversationMembers(id: $id, entities: $entities) {
      ...conversationInfo
    }
  }
  ${ConversationInfo}
`

interface AddMemberModalProps {
  visible: boolean
  handleCloseModal: () => void
  conversationId: string
  members: ConversationMemberInfoFragment[] | undefined
}

export default (props: AddMemberModalProps) => {
  const { visible, handleCloseModal, conversationId, members = [] } = props
  const [editingMembers, setEditingMembers] = useState(members)
  const [updateMembers] = useMutation<
    UpdateMembersMutationMutation,
    UpdateMembersMutationVariables
  >(updateMembersMutation)
  const { t } = useTranslation()

  // 如果 modal 關掉，清空編輯成員的狀態以免產生資料不一致的誤會。
  useEffect(() => {
    if (!visible)
      setEditingMembers(members)
  }, [visible])

  // @TODO 目前這邊的寫法是更新 state 強制去 re-render，可能有更好的寫法
  useEffect(() => {
    if (members)
      setEditingMembers(members)
  }, [members])

  const handleAddMember = (member: ConversationMemberInfoFragment) => {
    setEditingMembers(prevMembers => [...prevMembers, member])
  }
  const handleRemoveMember = (id: string) => {
    setEditingMembers(prevMembers => reject(propEq('id', id), prevMembers))
  }
  const handelSubmit = async () => {
    const editingMemberIds = pluck('id', editingMembers)
    await updateMembers({
      variables: {
        id: conversationId,
        entities: editingMemberIds,
      },
      update: async (cache, { data }) => {
        if (data) {
          message.success('更新成員成功')
          handleCloseModal()
        }
      },
    })
  }
  return (
    <div>
      <Modal
        visible={visible}
        title="管理成員"
        onOk={handelSubmit}
        onCancel={handleCloseModal}
        okText="儲存"
        cancelText="取消"
        maskClosable={false}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MemberAutocompleteSearch
              conversation={conversationId}
              editingMembers={editingMembers}
              onSelect={handleAddMember}
            />
          </div>
          <MemberListContainer>
            <MemberListTitle>
              群組成員 (
              {editingMembers.length}
              )
            </MemberListTitle>
            <MemberListContent>
              {map(
                member => (
                  <MemberRow
                    key={member.id}
                    name={member.name}
                    entityTitle={getEntityTitle(member, t)}
                    onRemove={() => handleRemoveMember(member.id)}
                  />
                ),
                editingMembers,
              )}
            </MemberListContent>
          </MemberListContainer>
        </div>
      </Modal>
    </div>
  )
}
