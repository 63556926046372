import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Checkbox from 'antd/lib/checkbox'
import {
  any,
  find,
  groupBy,
  identity,
  map,
  reverse,
  slice,
  take,
  takeLast,
  uniq,
  without,
} from 'ramda'
import type { CSSProperties } from 'react'
import React from 'react'
import styled from 'styled-components'

import type {
  ToothStatus,
} from '../../codegen/types'
import {
  ToothPosition,
  ToothStatusType,
} from '../../codegen/types'
import { getIndex, getQuadrant } from '../InstructionUtils'

const defaultToothStatus = {
  [ToothStatusType.DontPutAttachment]: false,
  [ToothStatusType.DontMove]: false,
  [ToothStatusType.Missing]: false,
  [ToothStatusType.WillExtract]: false,
}

const Container = styled.div`
  min-width: 770px;
`

const UpperTeethStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const LowerTeethStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const ToothStatusTitleContainer = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isReverse ? 'column-reverse' : 'column')};
  text-align: right;
  flex-shrink: 0;
`

const ToothStatusTitleRow = styled.div`
  margin: 4px 0px;
`

const ToothStatusCheckboxColumn = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isReverse ? 'column-reverse' : 'column')};
  align-items: center;

  > :not(:last-child) {
    border-bottom: ${props =>
      props.isReverse ? 'none' : '1px solid rgba(0,0,0,0.12)'};
    border-top: ${props =>
      props.isReverse ? '1px solid rgba(0,0,0,0.12)' : 'none'};
  }
`

const ToothStatusCheckboxContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 4px 0px;
`

interface ToothStatusTypeConfig {
  type: ToothStatusType
  label: React.ReactChild
  disabledBy?: ToothStatusType[]
  activeIconStyle?: React.CSSProperties
}

type TeethStatusTypeConfig = ToothStatusTypeConfig[]

interface ToothStatusTitleColumnProps {
  isReverse?: boolean
  teethStatusTypeConfig: TeethStatusTypeConfig
}

function ToothStatusTitleColumn(props: ToothStatusTitleColumnProps) {
  const { isReverse = false, teethStatusTypeConfig } = props
  return (
    <ToothStatusTitleContainer isReverse={isReverse}>
      {teethStatusTypeConfig.map((toothStatusTypeConfig, index) => (
        <ToothStatusTitleRow key={index}>
          {toothStatusTypeConfig.label}
        </ToothStatusTitleRow>
      ))}
    </ToothStatusTitleContainer>
  )
}

interface ToothSVGProps {
  position: ToothPosition
  style?: CSSProperties
}

function ToothSVG(props: ToothSVGProps) {
  const { position, style } = props
  return (
    <div
      style={{
        border: '1px solid gray',
        padding: '5px',
        margin: '4px',
        ...style,
      }}
    >
      {getQuadrant(position)}
      {getIndex(position)}
    </div>
  )
}

interface OnToothStatusChangeArgs {
  isChecked: boolean
  toothStatus: ToothStatus
}

type OnToothStatusChange = (args: OnToothStatusChangeArgs) => void

interface ToothStatusCheckboxGroupProps {
  toothPosition: ToothPosition
  teethStatus: ToothStatus[]
  isReverse?: boolean
  disabled?: boolean
  teethStatusTypeConfig: TeethStatusTypeConfig
  onToothStatusChange: OnToothStatusChange
}
function ToothStatusCheckboxGroup(props: ToothStatusCheckboxGroupProps) {
  const {
    toothPosition,
    teethStatus,
    isReverse = false,
    disabled = false,
    teethStatusTypeConfig,
    onToothStatusChange,
  } = props

  const grouped = groupBy(
    toothStatusInfo => toothStatusInfo.position,
    teethStatus,
  )
  const toothStatus
    = grouped[toothPosition]?.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.type]: true,
      }
    }, defaultToothStatus) ?? defaultToothStatus

  const getOnChangeHandler
    = (toothStatusType: ToothStatusType) => (e: CheckboxChangeEvent) => {
      const isChecked = e.target.checked
      onToothStatusChange({
        toothStatus: {
          position: toothPosition,
          type: toothStatusType,
        },
        isChecked,
      })
    }

  const firstActiveStatusType = find(
    toothStatusTypeConfig => toothStatus[toothStatusTypeConfig.type],
    teethStatusTypeConfig,
  )
  const iconStyle = firstActiveStatusType?.activeIconStyle ?? {}

  return (
    <div>
      {isReverse && <ToothSVG position={toothPosition} style={iconStyle} />}
      <ToothStatusCheckboxColumn isReverse={isReverse}>
        {map(
          toothStatusConfig => (
            <ToothStatusCheckboxContainer
              key={`check-box-${toothPosition}-${toothStatusConfig.type}`}
            >
              <Checkbox
                checked={toothStatus[toothStatusConfig.type]}
                onChange={getOnChangeHandler(toothStatusConfig.type)}
                disabled={
                  disabled
                  || any(
                    type => toothStatus[type],
                    toothStatusConfig.disabledBy ?? [],
                  )
                }
              />
            </ToothStatusCheckboxContainer>
          ),
          teethStatusTypeConfig,
        )}
      </ToothStatusCheckboxColumn>
      {!isReverse && <ToothSVG position={toothPosition} style={iconStyle} />}
    </div>
  )
}

type HandleToothStatusChange = OnToothStatusChange

interface TeethStatusInputProps {
  teethStatus?: ToothStatus[]
  onChange?: (value: ToothStatus[]) => void
  disabled?: boolean
  teethStatusTypeConfig?: TeethStatusTypeConfig
}

export function TeethStatusInput(props: TeethStatusInputProps) {
  const {
    teethStatus = [],
    teethStatusTypeConfig = [],
    onChange = identity,
    disabled = false,
  } = props

  const handleToothStatusChange: HandleToothStatusChange = (args) => {
    const { isChecked, toothStatus } = args

    const updatedTeethStatus = isChecked
      ? uniq([...teethStatus, toothStatus])
      : without([toothStatus], teethStatus)

    onChange(updatedTeethStatus)
  }

  const upperTeethStatus = [
    ...reverse(take(8, Object.values(ToothPosition))),
    ...slice(8, 16, Object.values(ToothPosition)),
  ]
  const lowerTeethStatus = [
    ...reverse(takeLast(8, Object.values(ToothPosition))),
    ...slice(16, 24, Object.values(ToothPosition)),
  ]
  return (
    <Container>
      <UpperTeethStatusContainer>
        <ToothStatusTitleColumn teethStatusTypeConfig={teethStatusTypeConfig} />
        {map(
          position => (
            <ToothStatusCheckboxGroup
              key={`tooth-status-${position}`}
              toothPosition={position}
              teethStatus={teethStatus}
              teethStatusTypeConfig={teethStatusTypeConfig}
              onToothStatusChange={handleToothStatusChange}
              disabled={disabled}
            />
          ),
          upperTeethStatus,
        )}
      </UpperTeethStatusContainer>
      <LowerTeethStatusContainer>
        <ToothStatusTitleColumn
          teethStatusTypeConfig={teethStatusTypeConfig}
          isReverse
        />
        {map(
          position => (
            <ToothStatusCheckboxGroup
              key={`tooth-status-${position}`}
              toothPosition={position}
              teethStatus={teethStatus}
              teethStatusTypeConfig={teethStatusTypeConfig}
              onToothStatusChange={handleToothStatusChange}
              disabled={disabled}
              isReverse
            />
          ),
          lowerTeethStatus,
        )}
      </LowerTeethStatusContainer>
    </Container>
  )
}
