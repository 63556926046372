import { QuestionCircleFilled } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Notification } from '@sov/ui'
import { Avatar, Button, Checkbox, Col, Form, Row, Tooltip } from 'antd'
import type { FormInstance, FormProps } from 'antd/lib/form'
import { append, equals, includes, keys, map, omit, reject } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  NotificationCategory,
  NotificationSettingFormFragment,
  NotificationSettingInput,
  NotificationType,
} from '../../codegen/types'
import lineIcon from '../../static/images/notification_line.svg'
import mailIcon from '../../static/images/notification_mail.svg'
import monitorIcon from '../../static/images/notification_monitor.svg'

const FormContainer = styled.div`
  background-color: white;
  margin-bottom: 16px;
  padding: 0 24px;
`

const PlatformRowWrapper = styled(Row)`
  padding-left: 48px;
  padding-top: 24px;
  height: 24px;
  box-sizing: content-box;
`

const QuestionIcon = styled(QuestionCircleFilled)`
  color: rgba(0, 0, 0, 0.2);
  margin-left: 4px;
`
const PlatformItem = styled.div`
  font-family: ' Noto Sans CJK TC';
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledAvatar = styled(Avatar)`
  margin-right: 4px;
`

const NotificationRowWrapper = styled(Row)`
  padding-left: 48px;
  height: 24px;
  margin-bottom: 8px;
`

const FooterWrapper = styled(Row)`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 16px;
  margin-top: 28px;
`
const UpdateButton = styled(Button)`
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
`
const CancelButton = styled(Button)`
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.45);
`

type NotificationSettingFormInitialValues = NotificationSettingInput
type DisplayNotificationSettingListByCategory = Partial<
  Record<NotificationCategory, NotificationType[]>
>

interface NotificationSettingFormProps extends FormProps {
  notificationSetting: NotificationSettingFormFragment
  form: FormInstance
  handleUpdate: (
    notificationSetting: NotificationSettingFormFragment
  ) => Promise<void>
  displayNotificationSettingListByCategory: DisplayNotificationSettingListByCategory
}

function NotificationSettingForm(props: NotificationSettingFormProps) {
  const { t } = useTranslation()

  const {
    notificationSetting,
    form,
    handleUpdate,
    displayNotificationSettingListByCategory,
  } = props

  const initialNotificationSettingFormFieldsValues = omit(
    ['__typename'],
    notificationSetting,
  )
  const initialValues: NotificationSettingFormInitialValues = {
    ...initialNotificationSettingFormFieldsValues,
  }

  const onSubmitClick = () => {
    const formFieldsValue = form.getFieldsValue()
    handleUpdate(formFieldsValue)
  }

  return (
    <>
      <FormContainer>
        <PlatformRowWrapper justify="space-between">
          <Col span={12} offset={10}>
            <Row>
              <Col flex={1}>
                <PlatformItem>
                  <StyledAvatar shape="square" size={24} src={monitorIcon} />
                  網站
                </PlatformItem>
              </Col>

              <Col flex={1}>
                <PlatformItem>
                  <StyledAvatar shape="square" size={24} src={mailIcon} />
                  電子郵件
                  <Tooltip
                    placement="right"
                    title="需完成信箱帳號認證後才能使用。"
                  >
                    <QuestionIcon />
                  </Tooltip>
                </PlatformItem>
              </Col>

              <Col flex={1}>
                <PlatformItem>
                  <StyledAvatar shape="square" size={24} src={lineIcon} />
                  Line
                  <Tooltip
                    placement="right"
                    title="需完成Line帳號認證後才能使用。"
                  >
                    <QuestionIcon />
                  </Tooltip>
                </PlatformItem>
              </Col>
            </Row>
          </Col>
        </PlatformRowWrapper>
        <Form form={form} initialValues={initialValues}>
          {map(
            notificationCategory => (
              <>
                <Row key={notificationCategory}>
                  <Notification.category
                    notificationCategory={notificationCategory}
                  />
                </Row>

                {displayNotificationSettingListByCategory[
                  notificationCategory
                ]?.map((notificationType: NotificationType) => (
                  <NotificationRowWrapper key={notificationType}>
                    <Col span={6}>
                      {t(`notification.type.${notificationType}`)}
                    </Col>
                    <Col span={12} offset={6}>
                      <Row justify="space-between">
                        {keys(omit(['__typename'], notificationSetting)).map(
                          platform => (
                            <Col key={platform} flex={1}>
                              <Form.Item
                                name={platform}
                                style={{ width: '100%' }}
                                getValueFromEvent={() => {
                                  const fieldValue: NotificationType[]
                                    = form.getFieldValue(platform) ?? []
                                  return includes(notificationType, fieldValue)
                                    ? reject(
                                      equals(notificationType),
                                      fieldValue,
                                    )
                                    : append(notificationType, fieldValue)
                                }}
                              >
                                <Checkbox.Group>
                                  <Checkbox
                                    value={notificationType}
                                    disabled={platform === 'line'}
                                  />
                                </Checkbox.Group>
                              </Form.Item>
                            </Col>
                          ),
                        )}
                      </Row>
                    </Col>
                  </NotificationRowWrapper>
                ))}
              </>
            ),
            keys(displayNotificationSettingListByCategory),
          )}
        </Form>
      </FormContainer>
      <FooterWrapper>
        <CancelButton
          onClick={() => {
            form.resetFields()
          }}
        >
          取消
        </CancelButton>
        <UpdateButton type="primary" onClick={onSubmitClick}>
          儲存更新
        </UpdateButton>
      </FooterWrapper>
    </>
  )
}

NotificationSettingForm.fragment = gql`
  fragment NotificationSettingForm on NotificationSetting {
    site
    mail
    line
  }
`

export default NotificationSettingForm
