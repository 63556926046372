import type { AuthContextType } from '@sov/common/src/auth/context'
import {
  authMeResponseKey,
  localStorageKey,
} from '@sov/common/src/keyOfGitHeadCommitHash'
import { equals } from 'ramda'
import { useEffect, useState } from 'react'
import useFetch from 'use-http'

import { useLocalStorage } from './useLocalStorage'

function useAuth() {
  // 設定 user
  const [init, setInit] = useState(false)
  const [user, setUser] = useState<AuthContextType>(undefined)

  // 設定 provider
  const [provider, setProvider, removeProvider] = useLocalStorage('provider')

  // 設定打 auth/me
  const url = `${process.env.API_HOST}/auth/me`
  const options = {
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
    },
  }
  const request = useFetch(url, options as any)

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        /**
         * 成功的話會回傳 auth:
         * auth {
         *  // account 的欄位
         *  id
         *  username
         *  providers
         *    ...
         *  // populate 後 entity 的欄位
         *  entity: {
         *    id
         *    name
         *    role
         *    privileges
         *    ...
         *  }
         * }
         */
        const res: AuthContextType = await request.post()
        setInit(true)
        // @TODO account migration
        // 如果未登入或有錯誤會回傳其他格式，所以要確認是正確的回傳 auth 格式
        if (res && res.id && res.entity) {
          setUser(res)
          const serverGitHeadCommitHash = res[authMeResponseKey]
          window.localStorage.setItem(localStorageKey, serverGitHeadCommitHash)
          if (!res.providers)
            removeProvider()
          else if (!equals(provider, res.providers))
            setProvider(res.providers)
        }
      }
    }
    fetchData()
  }, [])

  return { user, init }
}

export { useAuth }
