import { InboxOutlined, UploadOutlined } from '@ant-design/icons'
import type { ApolloClient } from '@apollo/client'
import { gql } from '@apollo/client'
import { Button, Upload } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import type {
  UploadFile,
  UploadFileStatus,
  UploadProps,
} from 'antd/lib/upload/interface'
import type { Lens } from 'ramda'
import { isEmpty, isNil, map, over } from 'ramda'
import React, { cloneElement } from 'react'

import ImgCrop from '../../../ui/src/components/ImageCrop'
import type {
  FileInfoFragment,
  FileUploadOption,
  UploadFileMutation,
  UploadFileMutationVariables,
} from '../codegen/types'

const uploadFileMutation = gql`
  mutation UploadFile($file: Upload!, $options: FileUploadOption!) {
    uploadFile(file: $file, options: $options) {
      id
      filename
      path
      thumbnailPath
    }
  }
`

/** 從 formValues 中的單一檔案欄位，取出 File document id */
function fileFieldTransformer<Payload extends any>(lens: Lens, formValues: any): Payload {
  return over(lens, (val?: UploadFile[]) => val?.[0]?.response?.id ?? null, formValues)
}

/** 從 formValues 中的複數檔案欄位，取出 File document ids */
function filesFieldTransformer<Payload extends any>(lens: Lens, formValues: any): Payload {
  return over(
    lens,
    (val?: UploadFile[]) => {
      if (isNil(val) || isEmpty(val))
        return null

      return map<UploadFile, string>(
        screenshot => screenshot?.response?.id,
        val,
      )
    },
    formValues,
  )
}

/** 從 file document 或 file documents 建立 mock antd file 物件 */
function initialValueTransformer(initialValue?: FileInfoFragment | FileInfoFragment[]): UploadFile<FileInfoFragment>[] {
  /// initialValue: undefined
  if (isNil(initialValue))
    return []

  const transformer = (file: FileInfoFragment) => ({
    uid: file.id,
    name: file.filename,
    url: file.path,
    thumbUrl: file.thumbnailPath ? file.thumbnailPath : undefined,
    response: file,
    size: 0,
    status: 'done' as UploadFileStatus,
    type: '',
  })

  return Array.isArray(initialValue)
    ? map(transformer, initialValue)
    : [transformer(initialValue)]
}

function DefaultDraggerContent() {
  return (
    <>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">點擊或拖曳到此區域以上傳</p>
    </>
  )
}
function DefaultUploadButton(props: ButtonProps) {
  return (
    <Button {...props}>
      <UploadOutlined style={{ marginRight: 8 }} />
      {props.children ?? '上傳'}
    </Button>
  )
}

interface GqlUploadProps extends UploadProps {
  // 傳入 apollo client
  client: ApolloClient<any>
  // 檔案類型
  fileType: 'image' | 'model' | 'any'
  buttonProps?: ButtonProps
  dragger?: boolean
  children?: React.ReactElement
  cropOption?: {
    aspect: number
    shape: 'rect' | 'round'
    modalTitle: string
  }
  uploadOptions: FileUploadOption
  handleLoading?: (loading: boolean) => void
  handleProgress?: (percent: number) => void
  isWithImageCrop?: boolean
}

function GqlUpload(props: GqlUploadProps) {
  const {
    fileType,
    client,
    dragger,
    children,
    disabled,
    buttonProps,
    cropOption,
    uploadOptions,
    handleLoading,
    handleProgress,
    isWithImageCrop = true,
    ...restProps
  } = props
  const uploaderProps: UploadProps = {
    customRequest: async (customRequestOptions) => {
      const { onError, onSuccess, onProgress, file } = customRequestOptions
      try {
        await client.mutate<UploadFileMutation, UploadFileMutationVariables>({
          mutation: uploadFileMutation,
          variables: {
            file,
            options: uploadOptions,
          },
          context: {
            fetchOptions: {
              useUpload: true,
              onStart: () => {
                if (handleLoading)
                  handleLoading(true)
              },
              onProgress: (ev: ProgressEvent) => {
                const percent = Number(
                  Math.round((ev.loaded / ev.total) * 100).toFixed(2),
                )
                onProgress?.({ ...ev, percent })
                if (handleProgress)
                  handleProgress(percent)
              },
            },
          },
          update: async (_cache, { data }) => {
            if (data) {
              onSuccess?.(data.uploadFile, new XMLHttpRequest())
              if (handleLoading)
                handleLoading(false)
            }
          },
        })
      }
      catch (error) {
        console.log('error', error)
        onError?.(error)
      }
    },
    ...restProps,
  }

  if (fileType === 'image' && isWithImageCrop) {
    return (
      <ImgCrop
        grid
        rotate
        modalOk="確認並上傳"
        modalCancel="取消"
        {...cropOption}
      >
        {dragger
          ? (
            <Upload.Dragger {...uploaderProps}>
              {children
                ? (
                    cloneElement(children, { disabled })
                  )
                : (
                  <DefaultDraggerContent />
                  )}
            </Upload.Dragger>
            )
          : (
            <Upload {...uploaderProps}>
              {children
                ? (
                    cloneElement(children, { disabled })
                  )
                : (
                  <DefaultUploadButton disabled={disabled} {...buttonProps} />
                  )}
            </Upload>
            )}
      </ImgCrop>
    )
  }
  else {
    return (
      <>
        {dragger
          ? (
            <Upload.Dragger {...uploaderProps}>
              {children
                ? (
                    cloneElement(children, { disabled })
                  )
                : (
                  <DefaultDraggerContent />
                  )}
            </Upload.Dragger>
            )
          : (
            <Upload {...uploaderProps}>
              {children
                ? (
                    cloneElement(children, { disabled })
                  )
                : (
                  <DefaultUploadButton disabled={disabled} {...buttonProps} />
                  )}
            </Upload>
            )}
      </>
    )
  }
}

GqlUpload.fragment = gql`
  fragment fileInfo on File {
    id
    filename
    path
    thumbnailPath
  }
`

export default {
  Upload: GqlUpload,
  fileFieldTransformer,
  filesFieldTransformer,
  initialValueTransformer,
}
