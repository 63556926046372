import { gql, useMutation } from '@apollo/client'
import { message } from 'antd'
import { includes, isNil } from 'ramda'
import React, { useContext } from 'react'

import type {
  RemoveEntityBindingFromAccountButtonFragment,
  RemoveEntityBindingFromAccountMutation,
  RemoveEntityBindingFromAccountMutationVariables,
} from '../../../../../../graphql/types'
import {
  AllPrivilege,
  Role,
} from '../../../../../../graphql/types'
import { ConfirmButton } from '../../../../../components/common/button'
import { authContext } from '../../../../../context'
import { isRole } from '../../../../../utils'

const fragment = gql`
  fragment RemoveEntityBindingFromAccountButton on Account {
    id
    entity {
      id
    }
    privileges
  }
`

const removeEntityBindingFromAccountMutation = gql`
  mutation RemoveEntityBindingFromAccount(
    $id: ID!
    $payload: UpdateAccountInput!
  ) {
    updateAccount(id: $id, payload: $payload) {
      ...RemoveEntityBindingFromAccountButton
    }
  }
  ${fragment}
`

interface FormFields {
  entity: null
}

interface RemoveEntityBindingFromAccountButtonProps {
  account: RemoveEntityBindingFromAccountButtonFragment
  onRemoveEntityBindingFromAccountCompleted: () => void
}

function RemoveEntityBindingFromAccountButton(props: RemoveEntityBindingFromAccountButtonProps) {
  const { account, onRemoveEntityBindingFromAccountCompleted } = props

  const [update] = useMutation<
    RemoveEntityBindingFromAccountMutation,
    RemoveEntityBindingFromAccountMutationVariables
  >(removeEntityBindingFromAccountMutation)
  const handleRemoveEntityBindingFromAccount = async () => {
    try {
      const formValues: FormFields = {
        entity: null,
      }

      /**
       * graphQL Maybe type 應該要包含 null，但是在我們客製化的 codegen type 定義將 null 移除了
       * 這邊先暫時跳過 type check
       */
      await update({
        variables: {
          id: account.id,
          // @ts-ignore
          payload: formValues,
        },
        update: (cache, { data }) => {
          onRemoveEntityBindingFromAccountCompleted()
          if (data)
            message.success('成功將實體從帳戶解除綁定！')
        },
      })
    }
    catch (error) {
      if (error?.message) {
        /** graphQL errors */
        message.error(`將實體從帳戶解除綁定時發生錯誤: ${error.message}`)
      }
      else {
        /** other errors */
        console.error(error)
      }
    }
  }

  const auth = useContext(authContext)

  if (!auth)
    return null

  const isGod = isRole(Role.God, auth)
  const isEntityBound = !isNil(account.entity)
  const accountItemHasPrivilegesOfAccountDelete = includes(
    AllPrivilege.AccountDelete,
    account.privileges,
  )
  const accountItemIsGod = accountItemHasPrivilegesOfAccountDelete
  const canUserCancelAccountBound = !accountItemIsGod && isGod && isEntityBound

  if (!canUserCancelAccountBound)
    return null

  return (
    <ConfirmButton
      confirmButtonType="update"
      label="解除綁定目前對應實體"
      modalProps={{ onOk: handleRemoveEntityBindingFromAccount }}
      requiredInputText="解除綁定目前對應實體"
    />
  )
}

RemoveEntityBindingFromAccountButton.fragments = {
  RemoveEntityBindingFromAccountButton: fragment,
}

export default RemoveEntityBindingFromAccountButton
