import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { Button, Form, Spin, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import type { GraphQLError } from 'graphql'
import moment from 'moment'
import { compose, dissoc, evolve, includes } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import type {
  EmployeeFormInfoFragment,
  EmployeeQueryQuery,
  EmployeeQueryVariables,
  RemoveEmployeeMutation,
  RemoveEmployeeVariables,
  UpdateEmployeeMutation,
  UpdateEmployeeVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
} from '../../../graphql/types'
import type {
  ConfirmButtonProps,
} from '../../components/common/button'
import {
  ConfirmButtonDropdownMenu,
  UpdateButton,
} from '../../components/common/button'
import EmployeeForm from '../../components/form/employee'
import Page, { Section } from '../../components/layout/Page'
import EmployeeMenu, { EmployeeMenuKey } from '../../components/pageHeader/employee'
import { authContext } from '../../context'
import { useLoadingLayer } from '../../helpers/hooks'
import { getAuthPrivileges, isItemOwner } from '../../utils'

const FormItem = Form.Item

const employeeQuery = gql`
  query EmployeeQuery($id: ID!) {
    employee(id: $id) {
      ...EmployeeFormInfo
    }
  }
  ${EmployeeForm.fragments.EmployeeFormInfo}
`

const updateEmployeeMutation = gql`
  mutation UpdateEmployee($id: ID!, $payload: UpdateEmployeeInput!) {
    updateEmployee(id: $id, payload: $payload) {
      ...EmployeeFormInfo
    }
  }
  ${EmployeeForm.fragments.EmployeeFormInfo}
`

const removeEmployeeMutation = gql`
  mutation RemoveEmployee($id: ID!) {
    removeEmployee(id: $id) {
      ...EmployeeFormInfo
    }
  }
  ${EmployeeForm.fragments.EmployeeFormInfo}
`

interface RouteProps {
  employeeId: string
}

export function EmployeeDetail() {
  const [form] = useForm<EmployeeFormInfoFragment>()
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const employeeId = match.params.employeeId
  const auth = useContext(authContext)
  if (!auth)
    return null

  const isMyself = isItemOwner(employeeId, auth)
  const canUpdate
    = isMyself || includes(AllPrivilege.EmployeeUpdate, getAuthPrivileges(auth))
  const { loading, tip, setLoadingLayer } = useLoadingLayer({
    loading: true,
    tip: '載入中...',
  })
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery<EmployeeQueryQuery, EmployeeQueryVariables>(employeeQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '員工總覽',
          url: '/employees',
        },
      })
    },
    variables: {
      id: employeeId,
    },
  })
  const [update] = useMutation<UpdateEmployeeMutation, UpdateEmployeeVariables>(
    updateEmployeeMutation,
  )
  const [remove] = useMutation<RemoveEmployeeMutation, RemoveEmployeeVariables>(
    removeEmployeeMutation,
  )

  useEffect(() => {
    refetch({ id: employeeId })
  }, [employeeId])

  useEffect(() => {
    setLoadingLayer({ loading: false, tip: '' })
  }, [queryLoading])

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      setLoadingLayer({ loading: true, tip: '更新中...' })
      await update({
        variables: {
          id: employeeId,
          payload: {
            ...compose(
              evolve({
                birthDate: x => x.toISOString(),
                onBoardDate: x => x.toISOString(),
              }),
              dissoc('rolePrivileges'),
            )(values),
          },
        },
        update: async (cache, { data }) => {
          setLoadingLayer({ loading: false, tip: '' })
          if (data)
            message.info('已更新員工')
        },
      })
    }
    catch (e) {
      setLoadingLayer({ loading: false, tip: '' })
      if (e.message) {
        const isDuplicatedEmployeeNameError = includes(
          'duplicated employee name',
          e.message,
        )
        if (isDuplicatedEmployeeNameError)
          message.error(`員工姓名重複，建議在員工姓名之後加上(英文名)做區隔`)
        else
          message.error(`更新員工失敗: ${e.message}`)
      }
    }
  }

  const handleRemove = async () => {
    setLoadingLayer({ loading: true, tip: '刪除中...' })
    try {
      await remove({
        variables: {
          id: employeeId,
        },
        update: (cache, { data }) => {
          if (data) {
            message.info('已刪除員工')
            history.push('/employees')
          }
        },
      })
    }
    catch (error) {
      const e = error as GraphQLError
      setLoadingLayer({ loading: false, tip: '' })
      message.error(`刪除員工失敗: ${e.message}`)
    }
  }

  if (queryLoading)
    return <Page loading />

  const employee = data?.employee

  const confirmButtonDropdownMenuItemsProps: ConfirmButtonProps[] = [
    {
      label: '刪除',
      modalProps: {
        onOk: handleRemove,
      },
      requiredInputText: '刪除員工',
      requiredPrivilege: AllPrivilege.EmployeeDelete,
    },
  ]

  const initialValues = {
    ...employee,
    birthDate: moment(employee?.birthDate),
    onBoardDate: moment(employee?.onBoardDate),
    approvers: employee?.approvers.map(x => x.id),
    taskOwner: employee?.taskOwner,
  }

  return (
    <div>
      <Page
        header={(
          <EmployeeMenu
            item={employee}
            selectedKeys={[EmployeeMenuKey.EMPLOYEE_DETAIL]}
          />
        )}
        loading={loading}
        loadingComponent={<Spin size="large" tip={tip} />}
      >
        <Section>
          {employee && (
            <Form>
              <EmployeeForm form={form} initialValues={initialValues} />
              <FormItem
                wrapperCol={{ span: 16, offset: 6 }}
                style={{ marginTop: 24 }}
              >
                <UpdateButton isVisible={canUpdate} onClick={handleSubmit} />
                <Button style={{ marginLeft: 16 }}>
                  <Link to="/employees">返回列表</Link>
                </Button>
                <ConfirmButtonDropdownMenu
                  confirmButtonDropdownMenuItemsProps={
                    confirmButtonDropdownMenuItemsProps
                  }
                />
              </FormItem>
            </Form>
          )}
        </Section>
      </Page>
    </div>
  )
}

export default EmployeeDetail
