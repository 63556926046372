import { Link } from '@sov/ui'
import { Checkbox, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import type { StageLinkFragment } from '../../../../graphql/types'

const TitleLink = styled(Typography.Title)`
  margin: 0 !important;
  &:hover {
    text-decoration: underline;
  }
`
const StyledCheckbox = styled(Checkbox)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface SelectableStageLinkProps {
  stageItem: StageLinkFragment
  isSelected: boolean
  disabled?: boolean
  handleSelect: () => void
}

function SelectableStageLink(props: SelectableStageLinkProps) {
  const { isSelected, stageItem, disabled = false, handleSelect } = props

  const [isActive, setIsActive] = useState(false)

  const handleActive = () => setIsActive(true)
  const handleInActive = () => setIsActive(false)
  const handleLeave = () => !isSelected && handleInActive()

  useEffect(() => {
    setIsActive(isSelected)
  }, [isSelected])

  return (
    <div onMouseOver={handleActive} onMouseLeave={handleLeave}>
      {isActive
        ? (
          <SelectWrapper>
            <StyledCheckbox
              checked={isSelected}
              onChange={handleSelect}
              disabled={disabled}
            />
            <TitleLink level={4}>
              <Link.StageLink item={stageItem} target="_blank" />
            </TitleLink>
          </SelectWrapper>
          )
        : (
          <SelectWrapper>
            <TitleLink level={4}>
              <Link.StageLink
                item={stageItem}
                target="_blank"
                showAvatar
                style={{ display: 'flex' }}
              />
            </TitleLink>
          </SelectWrapper>
          )}
    </div>
  )
}

export default SelectableStageLink
