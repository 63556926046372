import {
  DeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { GqlUpload } from '@sov/ui'
import {
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from 'antd'
import type { FormProps } from 'antd/lib/form'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import type {
  CheckPointCardFragment,
  DeleteCheckPointMutation,
  DeleteCheckPointMutationVariables,
  UpdateCheckPointMutation,
  UpdateCheckPointMutationVariables,
} from '../../../../graphql/types'
import {
  AuxiliaryType,
  JawPosition,
} from '../../../../graphql/types'
import { ActivableIcon } from '../../../components/common/ViewerFileIcon'
import {
  getInitialJawFile,
  getInitialToothFiles,
} from '../../../components/form/stage/FormViewerFiles'
import ViewerFilesUpload from '../../../components/modal/ViewerFilesUpload'
import { CPIcon, LIGHT_GREY, ORANGE } from './common'
import useDebounceSave from './useDebounceSave'

const Wrapper = styled.div<{ isEditing: boolean }>`
  border-left: solid 7px ${props => (props.isEditing ? ORANGE : 'white')};
  padding: 16px;
  background-color: ${props => (props.isEditing ? LIGHT_GREY : 'white')};
`
const TitleRow = styled(Row)`
  * {
    font-size: 24px;
  }
`
const Editor = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  background-color: #fff;
  padding: 8px;
  margin: 8px 0;
`

export const deleteCheckPoint = gql`
  mutation deleteCheckPoint($id: ID!, $serialNumber: Int!) {
    deleteCheckPoint(id: $id, serialNumber: $serialNumber) {
      id
    }
  }
`

export const updateCheckPoint = gql`
  mutation updateCheckPoint(
    $id: ID!
    $serialNumber: Int!
    $payload: UpdateCheckPointInput!
  ) {
    updateCheckPoint(id: $id, serialNumber: $serialNumber, payload: $payload) {
      id
    }
  }
`

interface RouteProps {
  stageId: string
  patientId: string
}

interface CheckPointForm {
  upper: string
  lower: string
  note: string
}

export interface CheckPointCardProps {
  checkPoint: CheckPointCardFragment
  isEditing: boolean
  isFinal: boolean
  handleFocus: (serialNumber: number) => void
  handleRefetch: () => Promise<void>
}

function CheckPointCard(props: CheckPointCardProps) {
  const { checkPoint, isEditing, isFinal, handleFocus, handleRefetch } = props
  const { hasFilesForViewer, files, serialNumber, goalStep } = checkPoint

  const match = useRouteMatch<RouteProps>()
  const { stageId, patientId } = match.params

  const [update] = useMutation<
    UpdateCheckPointMutation,
    UpdateCheckPointMutationVariables
  >(updateCheckPoint)
  const [deleteCP] = useMutation<
    DeleteCheckPointMutation,
    DeleteCheckPointMutationVariables
  >(deleteCheckPoint)
  const [form] = Form.useForm<CheckPointForm>()
  const [visible, setVisible] = useState(false)

  const defaultFiles = [
    ...getInitialToothFiles(files?.teeth),
    ...getInitialJawFile(JawPosition.Upper, files?.upperJawModel),
    ...getInitialJawFile(JawPosition.Lower, files?.lowerJawModel),
  ]

  const handleHoverEditor = () => {
    handleFocus(serialNumber)
  }

  const handleOpen = () => setVisible(true)
  const handleClose = () => {
    setVisible(false)

    const messageKey = 'upload-loading'
    message.loading({ content: '更新中', key: messageKey })
    handleRefetch().then(() => {
      message.destroy(messageKey)
    })
  }

  const handleUpdate: FormProps['onValuesChange'] = async () => {
    const value = (await form.validateFields()) as CheckPointForm
    await update({
      variables: {
        id: stageId,
        serialNumber,
        payload: {
          goalStep,
          ...value,
        },
      },
      update: async (_cache, { errors }) => {
        if (errors) {
          message.error(errors[0].message)
        }
        else {
          message.info(`已儲存 CP${serialNumber} 內容`)
          await handleRefetch()
        }
      },
    })
  }
  const { handleChange } = useDebounceSave({ onSave: handleUpdate })

  const handleDeleteCP = async () => {
    await deleteCP({
      variables: {
        id: stageId,
        serialNumber,
      },
      update: async (_cache, { data }) => {
        if (data?.deleteCheckPoint) {
          await handleRefetch()
          message.info('已刪除 check point')
        }
      },
    })
  }

  const goalStepValidator = (rule, value) => {
    const isValid = Number.isInteger(value) && value > 0
    return isValid ? Promise.resolve() : Promise.reject(new Error('需輸入整數'))
  }

  const DeleteConfirmTitle = (
    <div>
      <div>確定要刪除這個 Check Point?</div>
      <div style={{ color: 'red' }}>模型檔案資料將一併刪除</div>
    </div>
  )

  return (
    <Wrapper isEditing={isEditing} onMouseOver={handleHoverEditor}>
      <TitleRow justify="space-between">
        <Space>
          <CPIcon />
          <div style={{ color: ORANGE }}>
            {isFinal ? 'Final' : `CP ${serialNumber}`}
          </div>
        </Space>
        <Space size="large">
          <ActivableIcon active={hasFilesForViewer} onClick={handleOpen}>
            {hasFilesForViewer
              ? (
                <FolderOutlined />
                )
              : (
                <Tooltip title="尚未上傳模型檔案">
                  <FolderOpenOutlined />
                </Tooltip>
                )}
          </ActivableIcon>
          {!isFinal && (
            <Popconfirm title={DeleteConfirmTitle} onConfirm={handleDeleteCP}>
              <DeleteOutlined style={{ color: '#F5222D' }} />
            </Popconfirm>
          )}
        </Space>
      </TitleRow>
      <ViewerFilesUpload
        patientId={patientId}
        stageId={stageId}
        visible={visible}
        defaultFiles={defaultFiles}
        hasFilesForViewer={hasFilesForViewer}
        cpSerialNumber={serialNumber}
        handleClose={handleClose}
      />
      <Form
        form={form}
        initialValues={checkPoint}
        onValuesChange={handleChange}
      >
        <Editor>
          <Typography.Text type="secondary">上顎</Typography.Text>
          <Form.Item name="upper" noStyle>
            <Input.TextArea
              autoSize={{ minRows: 2 }}
              bordered={false}
              placeholder="填寫 Check point 預計達成目標"
              style={{ padding: 0, resize: 'none' }}
            />
          </Form.Item>
          <Divider style={{ margin: '4px 0' }} />
          <Typography.Text type="secondary">下顎</Typography.Text>
          <Form.Item name="lower" noStyle>
            <Input.TextArea
              autoSize={{ minRows: 2 }}
              bordered={false}
              placeholder="填寫 Check point 預計達成目標"
              style={{ padding: 0, resize: 'none' }}
            />
          </Form.Item>
          <Divider style={{ margin: '4px 0' }} />
          <Typography.Text type="secondary">內部備註</Typography.Text>
          <Form.Item name="note" noStyle>
            <Input.TextArea
              autoSize={{ minRows: 2 }}
              bordered={false}
              placeholder="此區域內容作為內部進行設計時的提醒，醫生並不會看到"
              style={{ padding: 0, resize: 'none' }}
            />
          </Form.Item>
        </Editor>
        <Row align="middle">
          此 Checkpoint，預計在 Step
          <Form.Item
            name="goalStep"
            style={{ margin: 0 }}
            rules={[
              { required: true, message: '不可空白' },
              { validator: goalStepValidator, message: '需輸入整數' },
            ]}
          >
            <InputNumber
              size="small"
              defaultValue={goalStep}
              style={{ width: '60px', margin: '0 8px' }}
            />
          </Form.Item>
          達成
          <Tooltip
            placement="right"
            title="請 Senior 評估預計達成 CheckPoint 數量。此數量僅供預估，不會讓醫生看到，可以在療程進行中隨臨床狀況增減。"
          >
            <QuestionCircleFilled
              style={{
                marginLeft: '8px',
                fontSize: '16px',
                color: 'rgba(0, 0, 0, 0.2)',
              }}
            />
          </Tooltip>
        </Row>
        <Form.Item name="instruction" label="臨床指示" style={{ margin: 0 }}>
          <Checkbox.Group>
            <Checkbox value={AuxiliaryType.StrippingAll}>IPR</Checkbox>
            <Checkbox value={AuxiliaryType.Button}>Button</Checkbox>
            <Checkbox value={AuxiliaryType.Attachment}>Attachment</Checkbox>
            <Checkbox value={AuxiliaryType.Tad}>TAD</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Wrapper>
  )
}

CheckPointCard.fragments = {
  CheckPointCard: gql`
    fragment CheckPointCard on CheckPoint {
      hasFilesForViewer
      serialNumber
      goalStep
      upper
      lower
      note
      instruction
      files {
        teeth {
          position
          file {
            ...fileInfo
          }
        }
        upperJawModel {
          ...fileInfo
        }
        lowerJawModel {
          ...fileInfo
        }
      }
    }
    ${GqlUpload.Upload.fragment}
  `,
}

export default CheckPointCard
