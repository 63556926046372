import { gql } from '@apollo/client'

import { ProductInfoFragment } from '../fragment'

export const productQuery = gql`
  query ProductQuery($id: ID!) {
    product(id: $id) {
      ...ProductInfo
    }
  }
  ${ProductInfoFragment}
`
