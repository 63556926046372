import { Form } from '@ant-design/compatible'
import type { FormComponentProps } from '@ant-design/compatible/lib/form'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Typography,
  message,
} from 'antd'
import React, { useContext, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { removeStageMutation } from '../../../../graphql/stage/mutation/remove'
import type {
  BraceMaterial,
  BraceThickness,
  CreateInstructionCardMutation,
  CreateInstructionCardMutationVariables,
  RemoveStageMutation,
  RemoveStageVariables,
  StageQueryQuery,
  StageQueryVariables,
  UpdateAccessoryStageMutation,
  UpdateAccessoryStageMutationVariables,
  UpdateDesignStageMutation,
  UpdateDesignStageMutationVariables,
  UpdateEvalStageMutation,
  UpdateEvalStageMutationVariables,
  UpdateMoldStageMutation,
  UpdateMoldStageMutationVariables,
  UpdatePrintStageMutation,
  UpdatePrintStageMutationVariables,
} from '../../../../graphql/types'
import {
  Role,
  StageType,
} from '../../../../graphql/types'
import PrinterModal from '../../../components/common/PrinterModal'
import type {
  StageUpdateAnalysisFields,
} from '../../../components/form/stage/FormAnalysis'
import FormAnalysis from '../../../components/form/stage/FormAnalysis'
import type {
  StageUpdateBasicFields,
} from '../../../components/form/stage/FormBasic'
import FormBasic from '../../../components/form/stage/FormBasic'
import type {
  StageUpdateFileFields,
} from '../../../components/form/stage/FormFile'
import FormFile from '../../../components/form/stage/FormFile'
import type {
  StageUpdateProductFields,
} from '../../../components/form/stage/FormProduct'
import FormProduct from '../../../components/form/stage/FormProduct'
import Page, { Section } from '../../../components/layout/Page'
import { authContext } from '../../../context'
import { useInstructionRemove } from '../../../helpers/hooks/useInstruction'
import { isInRoles } from '../../../utils'
import BraceAudit, { braceAuditFragment } from './components/BraceAudit'
import { RemoveButton, UpdateButton } from './components/common'
import OrderInfo from './components/OrderInfo'
import OrderInstruction from './components/OrderInstruction'
import StagePatientInfo from './components/StagePatientInfo'
import TaskSteps from './components/TaskSteps'
import { RefetchContext } from './utils'

const stageQuery = gql`
  query StageQuery($id: ID!) {
    stage(id: $id) {
      id
      type
      __typename
      ...BraceAudit
      ... on EvalStage {
        initialEvalStage {
          id
        }
      }
      patient {
        currentEvalStage {
          id
        }
      }
      ...FormBasic
      ...FormAnalysis
      ...FormProduct
      ...FormFile
    }
  }
  ${FormBasic.fragment}
  ${FormAnalysis.fragment}
  ${FormProduct.fragment}
  ${FormFile.fragment}
  ${braceAuditFragment}
`

const createInstructionCardMutation = gql`
  mutation CreateInstructionCard($id: ID!) {
    createInstructionCard(id: $id) {
      id
    }
  }
`

const updateMoldStage = gql`
  mutation UpdateMoldStage($id: ID!, $payload: UpdateMoldStageInput!) {
    updateMoldStage(id: $id, payload: $payload) {
      id
      type
      __typename
      patient {
        currentEvalStage {
          id
        }
      }
      ...FormBasic
      ...FormAnalysis
      ...FormProduct
      ...FormFile
    }
  }
  ${FormBasic.fragment}
  ${FormAnalysis.fragment}
  ${FormProduct.fragment}
  ${FormFile.fragment}
`
const updateEvalStage = gql`
  mutation UpdateEvalStage($id: ID!, $payload: UpdateEvalStageInput!) {
    updateEvalStage(id: $id, payload: $payload) {
      id
      type
      __typename
      patient {
        currentEvalStage {
          id
        }
      }
      ...FormBasic
      ...FormAnalysis
      ...FormProduct
      ...FormFile
    }
  }
  ${FormBasic.fragment}
  ${FormAnalysis.fragment}
  ${FormProduct.fragment}
  ${FormFile.fragment}
`
const updateDesignStage = gql`
  mutation UpdateDesignStage($id: ID!, $payload: UpdateDesignStageInput!) {
    updateDesignStage(id: $id, payload: $payload) {
      id
      type
      __typename
      patient {
        currentEvalStage {
          id
        }
      }
      ...FormBasic
      ...FormAnalysis
      ...FormProduct
      ...FormFile
    }
  }
  ${FormBasic.fragment}
  ${FormAnalysis.fragment}
  ${FormProduct.fragment}
  ${FormFile.fragment}
`
const updatePrintStage = gql`
  mutation UpdatePrintStage($id: ID!, $payload: UpdatePrintStageInput!) {
    updatePrintStage(id: $id, payload: $payload) {
      id
      type
      __typename
      patient {
        currentEvalStage {
          id
        }
      }
      ...FormBasic
      ...FormAnalysis
      ...FormProduct
      ...FormFile
    }
  }
  ${FormBasic.fragment}
  ${FormAnalysis.fragment}
  ${FormProduct.fragment}
  ${FormFile.fragment}
`
const updateAccessoryStage = gql`
  mutation UpdateAccessoryStage(
    $id: ID!
    $payload: UpdateAccessoryStageInput!
  ) {
    updateAccessoryStage(id: $id, payload: $payload) {
      id
      type
      __typename
      patient {
        currentEvalStage {
          id
        }
      }
      ...FormBasic
      ...FormAnalysis
      ...FormProduct
      ...FormFile
    }
  }
  ${FormBasic.fragment}
  ${FormAnalysis.fragment}
  ${FormProduct.fragment}
  ${FormFile.fragment}
`

const TaskStepsSection = styled(Section as any)`
  padding: 0;
  margin-bottom: 0;
`

const PatientInfoSection = styled(Section as any)`
  background-color: initial;
  margin-left: 0;
  padding: 0;
  flex: 0;
  align-self: flex-start;
`

const SectionItemDivider = styled(Divider)`
  margin: 8px 0;
`

const FormSectionItem = styled.div`
  padding: 8px 0;
`

const FormSection = styled(Section as any)`
  padding: 0;
  align-self: flex-start;
  ${FormSectionItem} + ${FormSectionItem} {
    margin-top: 16px;
  }
`

interface RouteProps {
  stageId: string
}

type StageDetailProps = FormComponentProps<
  StageUpdateBasicFields &
  StageUpdateAnalysisFields &
  StageUpdateProductFields &
  StageUpdateFileFields
>

function StageDetail({ form }: StageDetailProps) {
  const history = useHistory()
  const match = useRouteMatch<RouteProps>()
  const auth = useContext(authContext)
  const stageId = match.params.stageId
  const { validateFieldsAndScroll } = form

  const [visible, setVisible] = useState(false)

  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const { data, refetch, loading } = useQuery<
    StageQueryQuery,
    StageQueryVariables
  >(stageQuery, {
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage({
        message: error.message,
        redirect: {
          name: '工單總覽',
          url: '/stages',
        },
      })
    },
    variables: {
      id: stageId,
    },
  })
  const [updateMold] = useMutation<
    UpdateMoldStageMutation,
    UpdateMoldStageMutationVariables
  >(updateMoldStage)
  const [updateEval] = useMutation<
    UpdateEvalStageMutation,
    UpdateEvalStageMutationVariables
  >(updateEvalStage)
  const [updateDesign] = useMutation<
    UpdateDesignStageMutation,
    UpdateDesignStageMutationVariables
  >(updateDesignStage)
  const [updatePrint] = useMutation<
    UpdatePrintStageMutation,
    UpdatePrintStageMutationVariables
  >(updatePrintStage)
  const [updateAccessory] = useMutation<
    UpdateAccessoryStageMutation,
    UpdateAccessoryStageMutationVariables
  >(updateAccessoryStage)
  const [remove] = useMutation<RemoveStageMutation, RemoveStageVariables>(
    removeStageMutation,
  )
  const [createInstructionCard] = useMutation<
    CreateInstructionCardMutation,
    CreateInstructionCardMutationVariables
  >(createInstructionCardMutation)
  const { removeStageInstructionCard } = useInstructionRemove({
    onRemoved: refetch,
  })

  if (loading)
    return <Page loading />

  if (!data?.stage) {
    toErrorPage({
      message: '不存在的工單',
      redirect: {
        name: '工單總覽',
        url: '/stages',
      },
    })
    return null
  }

  const stage = data.stage

  const handleCreateInstructionCard = async () => {
    await createInstructionCard({
      variables: {
        id: stageId,
      },
      update: async (cache, { data }) => {
        if (data) {
          message.info('已新增指示卡')
          history.push(`/stages/${stageId}/instruction`)
        }
      },
    })
  }

  const handleUpdate = async () => {
    validateFieldsAndScroll(async (err, values) => {
      if (err) {
        console.error(err, values)
        message.error('有欄位輸入錯誤')
        return
      }
      try {
        if (stage.type === StageType.Mold) {
          await updateMold({
            variables: {
              id: stageId,
              payload: {
                expectedShippingDate: values.expectedShippingDate,
                note: values.note,
              },
            },
            update: async (cache, { data }) => {
              if (data?.updateMoldStage) {
                message.info('已更新工單')
                await refetch()
              }
            },
          })
        }

        if (stage.type === StageType.Eval) {
          await updateEval({
            variables: {
              id: stageId,
              payload: {
                expectedShippingDate: values.expectedShippingDate,
                note: values.note,
                moldStage: values.moldStage ?? null,
              },
            },
            update: async (cache, { data }) => {
              if (data?.updateEvalStage) {
                message.info('已更新工單')
                await refetch()
              }
            },
          })
        }

        if (stage.type === StageType.Design) {
          await updateDesign({
            variables: {
              id: stageId,
              payload: {
                expectedShippingDate: values.expectedShippingDate,
                note: values.note,
                serialNumber: values.serialNumber,
                step: values.step,
                files: {
                  upperRP: values.files.upperRP[0]?.response?.id,
                  lowerRP: values.files.lowerRP[0]?.response?.id,
                },
                rpModelNumber: values.rpModelNumber,
                upperModelCount: values.upperModelCount,
                lowerModelCount: values.lowerModelCount,
                upperAttachmentTemplateCount:
                  values.upperAttachmentTemplateCount,
                lowerAttachmentTemplateCount:
                  values.lowerAttachmentTemplateCount,
                upperMouthGuardCount: values.upperMouthGuardCount,
                lowerMouthGuardCount: values.lowerMouthGuardCount,
                upperBraceCount: values.upperBraceCount,
                upperBraceMaterial: values.upperBraceMaterial as BraceMaterial,
                upperBraceThickness:
                  values.upperBraceThickness as BraceThickness,
                lowerBraceCount: values.lowerBraceCount,
                lowerBraceMaterial: values.lowerBraceMaterial as BraceMaterial,
                lowerBraceThickness:
                  values.lowerBraceThickness as BraceThickness,
              },
            },
            update: async (cache, { data }) => {
              if (data?.updateDesignStage) {
                message.info('已更新工單')
                await refetch()
              }
            },
          })
        }

        if (stage.type === StageType.Print) {
          await updatePrint({
            variables: {
              id: stageId,
              payload: {
                expectedShippingDate: values.expectedShippingDate,
                note: values.note,
                serialNumber: values.serialNumber,
                designStage: values.designStage ?? null,
                rpBatchNumber: values.rpBatchNumber,
              },
            },
            update: async (cache, { data }) => {
              if (data?.updatePrintStage) {
                message.info('已更新工單')
                await refetch()
              }
            },
          })
        }

        if (stage.type === StageType.Accessory) {
          await updateAccessory({
            variables: {
              id: stageId,
              payload: {
                expectedShippingDate: values.expectedShippingDate,
                note: values.note,
                rpModelNumber: values.rpModelNumber,
                rpBatchNumber: values.rpBatchNumber,
                upperModelCount: values.upperModelCount,
                lowerModelCount: values.lowerModelCount,
                upperAttachmentTemplateCount:
                  values.upperAttachmentTemplateCount,
                lowerAttachmentTemplateCount:
                  values.lowerAttachmentTemplateCount,
                upperMouthGuardCount: values.upperMouthGuardCount,
                lowerMouthGuardCount: values.lowerMouthGuardCount,
                upperBraceCount: values.upperBraceCount,
                upperBraceMaterial: values.upperBraceMaterial as BraceMaterial,
                upperBraceThickness:
                  values.upperBraceThickness as BraceThickness,
                lowerBraceCount: values.lowerBraceCount,
                lowerBraceMaterial: values.lowerBraceMaterial as BraceMaterial,
                lowerBraceThickness:
                  values.lowerBraceThickness as BraceThickness,
              },
            },
            update: async (cache, { data }) => {
              if (data?.updateAccessoryStage) {
                message.info('已更新工單')
                await refetch()
              }
            },
          })
        }
      }
      catch (e) {
        if (e?.message) {
          /** graphQL errors */
          message.error(e.message)
        }
        else {
          /** form errors or other errors */
          message.error(e.toString())
        }
      }
    })
  }

  const handleRemove = async () => {
    try {
      await remove({
        variables: {
          id: stageId,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已刪除工單')
            history.push('/')
          }
        },
      })
    }
    catch (e) {
      if (e?.message) {
        /** graphQL errors */
        message.error(e.message)
      }
      else {
        /** form errors or other errors */
        message.error(e.toString())
      }
    }
  }

  const handleClick = () => setVisible(true)
  const handleCancel = () => setVisible(false)

  // 製造跟配件才有牙套工單
  const isWhiteButtonVisible
    = stage.type === StageType.Print || stage.type === StageType.Accessory
  // 只有天神跟主管帳號可以砍工單
  const canRoleRemoveStage = isInRoles([Role.God, Role.Manager], auth)
  const isPatientCurrentEvalStage
    = stage.patient.currentEvalStage?.id === stageId
  const hasInitialStage
    = stage.__typename === 'EvalStage' && Boolean(stage.initialEvalStage?.id)
  // 不是病患主要報告，或者雖然是主要報告，但有 initialEvalStage 則有辦法推斷前一個主要報告，因此可以刪除並更新病患主要報告
  const isRemovableStage = !isPatientCurrentEvalStage || hasInitialStage
  const isRemoveButtonVisible = canRoleRemoveStage && isRemovableStage

  const Buttons = () => (
    <>
      {isWhiteButtonVisible && (
        <Button type="link" onClick={handleClick}>
          牙套工單
        </Button>
      )}
      {isWhiteButtonVisible && (
        <PrinterModal
          visible={visible}
          onCancel={handleCancel}
          width="80mm"
          height="1127px"
          printOnHead
        >
          <BraceAudit item={stage} />
        </PrinterModal>
      )}
      <UpdateButton type="primary" onClick={handleUpdate} />
      {isRemoveButtonVisible && <RemoveButton handleRemove={handleRemove} />}
    </>
  )

  return (
    <>
      <TaskStepsSection>
        <TaskSteps stageId={stageId} />
      </TaskStepsSection>
      <OrderInstruction stageId={stageId} />
      <Row>
        <FormSection>
          <Card size="small" title="工單詳情" extra={<Buttons />}>
            <Row>
              <Col span={21}>
                <FormSectionItem id="detail-stage-profile">
                  <Typography.Title level={5} type="secondary">
                    基本資料
                  </Typography.Title>
                  <FormBasic form={form} item={stage} refetch={refetch} />
                </FormSectionItem>
                <SectionItemDivider />
                <FormSectionItem id="detail-stage-info-design">
                  <Typography.Title level={5} type="secondary">
                    治療相關
                  </Typography.Title>
                  <FormAnalysis
                    form={form}
                    item={stage}
                    handleCreateInstructionCard={handleCreateInstructionCard}
                    handleRemoveInstructionCard={() =>
                      removeStageInstructionCard(stageId)}
                  />
                </FormSectionItem>
                <SectionItemDivider />
                <FormSectionItem id="detail-stage-info-rp">
                  <Typography.Title level={5} type="secondary">
                    出貨相關
                  </Typography.Title>
                  {(stage.__typename === 'PrintStage'
                  || stage.__typename === 'DesignStage'
                  || stage.__typename === 'AccessoryStage') && (
                    <FormProduct form={form} item={stage} />
                  )}
                </FormSectionItem>
                <SectionItemDivider />
                <FormSectionItem id="detail-stage-info-file">
                  <Typography.Title level={5} type="secondary">
                    檔案相關
                  </Typography.Title>
                  {stage.type !== StageType.Accessory && (
                    <RefetchContext.Provider value={refetch}>
                      <FormFile form={form} item={stage} />
                    </RefetchContext.Provider>
                  )}
                </FormSectionItem>
              </Col>
            </Row>
          </Card>
        </FormSection>
        <PatientInfoSection>
          <Space direction="vertical" size={16}>
            <OrderInfo stageId={stageId} />
            <StagePatientInfo patientId={stage.patient.id} />
          </Space>
        </PatientInfoSection>
      </Row>
    </>
  )
}

export default Form.create<StageDetailProps>()(StageDetail)
