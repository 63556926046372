import { gql } from '@apollo/client'

import { ConversationInfoFragment } from '../fragment'

export const updateConversationReadMessageRecordOfMemberMutation = gql`
  mutation UpdateConversationReadMessageRecordOfMemberMutation(
    $id: ID!
    $entity: ID!
  ) {
    updateConversationReadMessageRecordOfMember(id: $id, entity: $entity) {
      ...conversationInfo
    }
  }
  ${ConversationInfoFragment}
`
