import { gql, useMutation } from '@apollo/client'
import { isEmptyOrNil } from '@sov/common'
import { Button, Input, Modal, message } from 'antd'
import type { TextAreaProps } from 'antd/lib/input'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import type {
  OrderType,
  UpdateOrderInstructionWithOptionsMutation,
  UpdateOrderInstructionWithOptionsMutationVariables,
} from '../../../../graphql/types'
import CloseIcon from './CloseIcon'
import type { RadioButtonGroupProps } from './RadioButtonGroup'
import RadioButtonGroup from './RadioButtonGroup'

const CustomizedTextArea = styled(Input.TextArea)`
  padding: 8px;
`

export enum ModalType {
  Create = 'CREATE',
  Edit = 'EDIT',
}

interface GetModalTitleArgs {
  modalType: ModalType
}

function getModalTitle(args: GetModalTitleArgs) {
  const { modalType } = args
  return modalType === ModalType.Create ? '訂單指示' : '訂單指示編輯'
}

export interface SelectedOrder {
  id: string
  type: OrderType
  instruction: string
}

const CustomizedModal = styled(Modal)`
  .ant-modal-title {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 24px 40px;

    textarea {
      align-self: flex-end;
    }
  }
`

const updateOrderInstructionWithOptionsMutation = gql`
  mutation UpdateOrderInstructionWithOptions(
    $id: ID!
    $payload: UpdateOrderInput!
  ) {
    updateOrder(id: $id, payload: $payload) {
      id
    }
  }
`

interface OrderInstructionModalProps {
  onCancel: () => void
  onOk: () => void
  selectedOrder?: SelectedOrder
  visible: boolean
}

function OrderInstructionModal(props: OrderInstructionModalProps) {
  const { onCancel, onOk, selectedOrder, visible } = props

  if (!selectedOrder) {
    return (
      <Modal title="訂單指示" visible={visible} onOk={onOk} onCancel={onCancel}>
        找不到對應的訂單
      </Modal>
    )
  }

  const [updateOrderInstructionWithOptions] = useMutation<
    UpdateOrderInstructionWithOptionsMutation,
    UpdateOrderInstructionWithOptionsMutationVariables
  >(updateOrderInstructionWithOptionsMutation)

  const [instruction, setInstruction] = useState(selectedOrder.instruction)
  const modalType = isEmptyOrNil(selectedOrder.instruction)
    ? ModalType.Create
    : ModalType.Edit

  const handleOk = async () => {
    try {
      await updateOrderInstructionWithOptions({
        variables: {
          id: selectedOrder.id,
          payload: {
            instruction,
          },
        },
        update: (_cache, { data }) => {
          if (data)
            message.info(`已更新訂單指示: ${data.updateOrder?.id}`)
        },
      })
    }
    catch (e) {
      if (e?.message) {
        /** graphQL errors */
        message.error(e.message)
      }
      console.error(e)
    }
    finally {
      onOk()
    }
  }

  const handleInstructionChange: RadioButtonGroupProps['handleInstructionChange']
    = (updatedInstruction) => {
      setInstruction(updatedInstruction)
    }

  const handleTextAreaChange: TextAreaProps['onChange'] = (e) => {
    const updatedText = e.target.value
    setInstruction(updatedText)
  }

  const modalTitle = getModalTitle({ modalType })

  const textAreaRows = modalType === ModalType.Create ? 10 : 15

  return (
    <CustomizedModal
      title={modalTitle}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      width={470}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={isNil(instruction)}
        >
          確認儲存
        </Button>,
      ]}
      closeIcon={<CloseIcon />}
      maskClosable={false}
    >
      <RadioButtonGroup
        handleInstructionChange={handleInstructionChange}
        modalType={modalType}
        selectedOrderType={selectedOrder.type}
      />
      <CustomizedTextArea
        autoSize={{ minRows: textAreaRows, maxRows: textAreaRows }}
        placeholder="填寫確實有助於牙技師清楚療程設計方向"
        onChange={handleTextAreaChange}
        value={instruction}
      />
    </CustomizedModal>
  )
}

export default OrderInstructionModal
