import { gql } from '@apollo/client'

import { LeaveInfoFragment } from '../../leave/fragment'
import { EmployeeInfoFragment } from '../fragment'

export const employeeLeavesQuery = gql`
  query EmployeeLeavesQuery(
    $id: ID!
    $query: LeavesQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    employee(id: $id) {
      ...EmployeeInfo
      leaves(query: $query, page: $page, limit: $limit, sort: $sort) {
        docs {
          ...LeaveInfo
        }
        limit
        page
        total
      }
      leaveStatus {
        remainAnnual
        usedSickLeave
        usedPersonalLeave
      }
    }
  }
  ${EmployeeInfoFragment}
  ${LeaveInfoFragment}
`
