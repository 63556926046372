import ClinicLink from './Clinic'
import DoctorLink from './Doctor'
import PatientLink from './Patient'
import PatientWithDoctorLink from './PatientWithDoctor'
import StageLink from './Stage'

export default {
  PatientLink,
  ClinicLink,
  StageLink,
  DoctorLink,
  PatientWithDoctorLink,
}
