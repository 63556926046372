import { includes, not } from 'ramda'

import type { Employee, Entity } from '../../types'
import { EntityType, Role } from '../../types'
import type { AuthContextType } from '../context'

export type PartialEntity = Entity & {
  role?: Role
}

// 前端頁面用來判斷 entity 類別的 helper function
export function entityIsEmployee(entity: PartialEntity): entity is Employee {
  return !!(entity as Employee).role
}

export function isRole(requiredRole: Role, auth: AuthContextType) {
  return auth && entityIsEmployee(auth.entity)
    ? auth.entity.role === requiredRole
    : false
}

export function isInRoles(requiredRoles: Role[], auth: AuthContextType) {
  return auth && entityIsEmployee(auth.entity)
    ? includes(auth.entity.role, requiredRoles)
    : false
}

/**
 *
 * @param auth passport user 中的資訊
 * 內部員工並且不是業務才屬於 internal roles
 */
export function isInternalRoles(auth: AuthContextType) {
  return (
    auth
    && auth.entityType === EntityType.Employee
    && not(isRole(Role.Sales, auth))
  )
}

export function isItemOwner(itemId: string, auth: AuthContextType) {
  return auth ? itemId === auth.entity.id : false
}

export function getAuthPrivileges(auth: AuthContextType) {
  return [...(auth?.privileges ?? []), ...(auth?.extraPrivileges ?? [])]
}

export function getUserEntityIdFromAuth(auth: AuthContextType) {
  return auth?.entity.id ?? ''
}

export function getUserEntityFromAuth(auth: AuthContextType) {
  return auth?.entity
}

export function getAccountIdFromAuth(auth: AuthContextType) {
  return auth?.id ?? ''
}

export function getAccountFromAuth(auth: AuthContextType) {
  return auth
}
