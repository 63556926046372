import { gold } from '@ant-design/colors'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { CheckPoint, GqlUpload, StageName } from '@sov/ui'
import CheckPointProgress from '@sov/ui/src/components/CheckPoint/CheckPointProgress'
import type { IAllProps } from '@tinymce/tinymce-react'
import {
  Button,
  Form,
  Layout,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from 'antd'
import type { UploadChangeParam } from 'antd/lib/upload'
import type { UploadFile } from 'antd/lib/upload/interface'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import type {
  CreateCheckPointMutation,
  CreateCheckPointMutationVariables,
  File,
  PatientReportEditQuery,
  PatientReportEditQueryVariables,
  UpdateAnalysisMutation,
  UpdateAnalysisVariables,
  UploadStepsOverViewMutation,
  UploadStepsOverViewMutationVariables,
} from '../../../../graphql/types'
import {
  StageStatus,
  StageType,
} from '../../../../graphql/types'
import RichTextEditor, { EDITOR_HEIGHT } from '../../../components/common/RichTextEditor'
import Page, { Section } from '../../../components/layout/Page'
import { takeLastFromFileList } from '../../../utils'
import CheckPointGroup from './CheckPointGroup'
import PredesignCollapse from './PredesignCollapse'
import useDebounceSave from './useDebounceSave'

const Content = styled(Layout.Content)`
  width: 80%;
  margin: 0 auto;
`

const patientReportEdit = gql`
  query PatientReportEdit($id: ID!) {
    evalStage(id: $id) {
      id
      ...StageName
      ...PredesignCollapse
      analysis {
        digiAnalysis
      }
      stepsOverview {
        ...fileInfo
      }
      isCPMode
      checkPoints {
        ...CheckPointGroup
        ...CheckPointProgress
      }
      patient {
        stages(query: { type: [${StageType.Print}], status: [${StageStatus.Completed}, ${StageStatus.Started}] }, limit: 1, sort: "-serialNumber") {
          docs {
            id
            ... on PrintStage {
              serialNumber
            }
          }
        }
      }
    }
  }
  ${StageName.fragment}
  ${CheckPointGroup.fragments.CheckPointGroup}
  ${CheckPoint.CheckPointProgress.fragments.CheckPointProgress}
  ${PredesignCollapse.fragments.PredesignCollapse}
  ${GqlUpload.Upload.fragment}
`

export const createCheckPoint = gql`
  mutation createCheckPoint($id: ID!) {
    createCheckPoint(id: $id) {
      id
    }
  }
`

export const updateAnalysisMutation = gql`
  mutation UpdateAnalysis($id: ID!, $payload: UpdateAnalysisInput!) {
    updateAnalysis(id: $id, payload: $payload) {
      id
    }
  }
`

export const uploadStepsOverViewMutation = gql`
  mutation UploadStepsOverView($id: ID!, $payload: UpdateEvalStageInput!) {
    updateEvalStage(id: $id, payload: $payload) {
      id
    }
  }
`

interface RouteProps {
  stageId: string
  patientId: string
}

function PatientReportEdit() {
  const match = useRouteMatch<RouteProps>()
  const stageId = match.params.stageId

  const client = useApolloClient()
  const [focusCPserialNumber, setFocusCPserialNumber] = useState(1)
  const [addCPLoading, setAddCPLoading] = useState(false)
  const [form] = Form.useForm()

  const [createCP] = useMutation<
    CreateCheckPointMutation,
    CreateCheckPointMutationVariables
  >(createCheckPoint)
  const [updateAnalysis] = useMutation<
    UpdateAnalysisMutation,
    UpdateAnalysisVariables
  >(updateAnalysisMutation)
  const [uploadStepsOverView] = useMutation<
    UploadStepsOverViewMutation,
    UploadStepsOverViewMutationVariables
  >(uploadStepsOverViewMutation)
  const { data, loading, refetch } = useQuery<
    PatientReportEditQuery,
    PatientReportEditQueryVariables
  >(patientReportEdit, {
    variables: {
      id: stageId,
    },
  })
  const evalStage = data?.evalStage

  const handleUpdateAnalysis: IAllProps['onEditorChange'] = async (value) => {
    /** 若包含 'base64' 字串，代表 tinymce editor 還沒將完成上傳的圖片 url 置換到編輯器內容中 */
    if (value.includes(';base64'))
      return
    await updateAnalysis({
      variables: {
        id: stageId,
        payload: {
          digiAnalysis: value,
        },
      },
    })
    message.info({ content: '已更新診斷報告' })
  }

  const { handleChange } = useDebounceSave({ onSave: handleUpdateAnalysis })

  if (loading)
    return <Page loading />

  if (isNil(evalStage))
    return null

  const checkPoints = evalStage.checkPoints
  const currentStep
    = evalStage.patient.stages?.docs[0]?.__typename === 'PrintStage'
      ? evalStage.patient.stages?.docs[0].serialNumber
      : undefined
  const initialValues = {
    digiAnalysis: evalStage.analysis?.digiAnalysis,
    stepsOverview: GqlUpload.initialValueTransformer(evalStage.stepsOverview),
  }

  const handleFocusCP = (serialNumber: number) => {
    setFocusCPserialNumber(serialNumber)
  }

  const handleRefetch = async () => {
    await refetch()
  }

  const handleAddCP = async () => {
    setAddCPLoading(true)
    await createCP({
      variables: {
        id: stageId,
      },
      update: async (_cache, { data }) => {
        if (data?.createCheckPoint) {
          await handleRefetch()
          message.info('已新增 check point')
        }
        setAddCPLoading(false)
      },
    })
  }

  const handleUploadStepsOverview = async (
    params: UploadChangeParam<UploadFile<File>>,
  ) => {
    const file = params.fileList[0]
    await uploadStepsOverView({
      variables: {
        id: stageId,
        payload: {
          stepsOverview: file?.response ? file.response.id : undefined,
        },
      },
      update: async (_cache, { data }) => {
        if (data?.updateEvalStage) {
          await handleRefetch()
          message.info('已上傳齒列移動表')
        }
      },
    })
  }

  return (
    <Section>
      <Content>
        <Row align="middle" justify="end">
          <Space>
            <Button>
              <Link to={`/viewer?stageId=${stageId}`}>預覽 Viewer</Link>
            </Button>
            <Button>
              <Link to={`/stages/${stageId}/analysis/preview`}>預覽報告</Link>
            </Button>
          </Space>
        </Row>
        <Typography.Title level={4}>
          <Space>
            <span>數位分析內容</span>
            <StageName item={evalStage} />
            {evalStage.isCPMode
              ? (
                <Tag color={gold.primary}>新版 CP 報告</Tag>
                )
              : (
                <Tag color="#cccccc">舊版預設計報告</Tag>
                )}
          </Space>
        </Typography.Title>
        <Form form={form} initialValues={initialValues}>
          {/* 加上 Form Item margin bottom */}
          <div style={{ minHeight: `${EDITOR_HEIGHT + 24}px` }}>
            <Form.Item name="digiAnalysis" trigger="setValue">
              <RichTextEditor
                value={initialValues.digiAnalysis}
                onEditorChange={handleChange}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="stepsOverview"
            label="齒列移動表"
            valuePropName="fileList"
            getValueFromEvent={takeLastFromFileList}
          >
            <GqlUpload.Upload
              client={client}
              fileType="any"
              uploadOptions={{ prefix: 'report', needThumbnail: false }}
              onChange={handleUploadStepsOverview}
            />
          </Form.Item>
          <PredesignCollapse
            evalStage={evalStage}
            handleRefetch={handleRefetch}
          />
        </Form>

        <Row justify="space-between">
          <Space size="large">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Check Point
            </Typography.Title>
            <Typography.Text type="secondary">
              目前共
              {' '}
              {checkPoints.length}
              {' '}
              個 CP
            </Typography.Text>
          </Space>
          <Popconfirm
            title="確定要新增一個 Check Point？"
            onConfirm={handleAddCP}
          >
            <Button
              icon={addCPLoading ? <LoadingOutlined /> : <PlusOutlined />}
              type="ghost"
            >
              {addCPLoading ? '新增中...' : '新增'}
            </Button>
          </Popconfirm>
        </Row>

        <Row style={{ margin: '24px' }}>
          <CheckPointProgress
            checkPoints={checkPoints}
            currentStep={currentStep}
            showAnchor
            showProgress
          />
        </Row>

        {checkPoints.map((cp, index, list) => (
          <CheckPointGroup
            key={`${cp.serialNumber}-${cp.goalStep}`}
            checkPoint={cp}
            isEditing={cp.serialNumber === focusCPserialNumber}
            isFinal={cp.serialNumber === list.length}
            handleFocus={handleFocusCP}
            handleRefetch={handleRefetch}
          />
        ))}
      </Content>
    </Section>
  )
}

export default PatientReportEdit
