import { gql } from '@apollo/client'
import { Enums } from '@sov/common'
import { Checkbox, DatePicker, Form, Input, Radio, Select } from 'antd'
import type { FormInstance } from 'antd/lib/form/Form'
import { flatten, values } from 'ramda'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import type {
  EmployeeFormInfoFragment,
} from '../../../../graphql/types'
import {
  Gender,
  Role,
  StageType,
} from '../../../../graphql/types'
import { authContext } from '../../../context'
import { isInRoles } from '../../../utils'
import { EmployeeSelect } from '../Select'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

const requiredRule: (t: any) => any[] = t => [
  {
    required: true,
    message: t('validate.required'),
  },
]

interface Props {
  form: FormInstance<EmployeeFormInfoFragment>
  initialValues: Omit<Partial<EmployeeFormInfoFragment>, 'approvers'> & {
    approvers?: string[]
  }
  item?: EmployeeFormInfoFragment
}

function EmployeeForm(props: Props) {
  const { form, initialValues } = props
  const { t } = useTranslation()
  const auth = useContext(authContext)
  const isGodOrManager = isInRoles([Role.Manager, Role.God], auth)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter')
      e.preventDefault()
  }

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Form.Item name="name" label="員工姓名" rules={requiredRule(t)}>
        <Input onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item
        name="email"
        label="帳號(電子信箱)"
        rules={[
          {
            type: 'email',
            message: '需要 Email 格式',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="phone" label="手機">
        <Input />
      </Form.Item>
      <Form.Item name="address" label="地址">
        <Input />
      </Form.Item>
      <Form.Item name="backupEmail" label="備用 Email">
        <Input />
      </Form.Item>
      <Form.Item name="lineId" label="LINE ID">
        <Input />
      </Form.Item>
      <Form.Item name="birthDate" label="生日">
        <DatePicker />
      </Form.Item>
      <Form.Item name="gender" label="性別">
        <Radio.Group>
          {values(Gender).map(gender => (
            <Radio key={gender} value={gender}>
              {t(`gender.${gender}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name="isMarried" label="婚姻狀況">
        <Radio.Group>
          <Radio value={false}>未婚</Radio>
          <Radio value>已婚</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="onBoardDate" label="到職日" rules={requiredRule(t)}>
        <DatePicker />
      </Form.Item>
      <Form.Item
        name="role"
        label="角色"
        rules={[
          {
            required: true,
            message: '必填欄位',
          },
        ]}
      >
        <Select disabled={!isGodOrManager}>
          {values(Role).map(role => (
            <Option key={role} value={role} disabled={role === Role.God}>
              {t(`role.${role}`) || role}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="title" label="職稱">
        <Input onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item name="taskOwner" label="可以進行的任務">
        <Select
          mode="multiple"
          placeholder="輸入可以進行的任務種類"
          disabled={!isGodOrManager}
        >
          {flatten(
            Object.values(StageType).map((value: string) => {
              // dynamically create options according to stage type
              const StageTypeOption = (
                <Option value={value} key={value} disabled>
                  {t(`stage.type.${value}`)}
                </Option>
              )
              const TaskTypeList = Enums.stage.taskType[value].map(type => (
                <Option value={type} key={type}>
                  {t(`task.type.${type}`)}
                </Option>
              ))
              return [StageTypeOption, ...TaskTypeList]
            }),
          )}
        </Select>
      </Form.Item>
      <Form.Item name="approvers" label="假單審核者">
        <EmployeeSelect mode="multiple" query={{ roles: [Role.Manager] }} />
      </Form.Item>
      <Form.Item name="resigned" label="已離職" valuePropName="checked">
        <Checkbox disabled={!isGodOrManager} />
      </Form.Item>
    </Form>
  )
}

EmployeeForm.fragments = {
  EmployeeFormInfo: gql`
    fragment EmployeeFormInfo on Employee {
      id
      name
      email
      phone
      address
      backupEmail
      lineId
      birthDate
      gender
      isMarried
      onBoardDate
      role
      title
      taskOwner
      approvers {
        id
        name
      }
      resigned
    }
  `,
}
export default EmployeeForm
