import React from 'react'
import { useTranslation } from 'react-i18next'

import type { TaskType } from '../../../../../../graphql/types'

interface TooltipTitleProps {
  taskTypeList: TaskType[]
}

function TooltipTitle(props: TooltipTitleProps) {
  const { taskTypeList } = props

  const { t } = useTranslation()

  const translatedTaskTypeList = taskTypeList.map(taskType =>
    t(`task.type.${taskType}`),
  )

  return (
    <div>
      <div>只顯示進行至以下任務的工單數</div>
      {translatedTaskTypeList.map(translatedTaskType => (
        <div key={`tooltip-title-${translatedTaskType}`}>
          {translatedTaskType}
        </div>
      ))}
    </div>
  )
}

export default TooltipTitle
