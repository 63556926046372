import Loadable from '@loadable/component'
import { includes } from 'ramda'

export const tabKeys = [
  'account-info',
  'account-privileges',
  'account-settings',
  'account-bind-entity',
  'account-app-info',
] as const

export type TabKey = typeof tabKeys[number]

interface FormProps {
  accountId: string
  tabKey: TabKey
}

type Route<T extends string> = Record<
  T,
  {
    readonly text: string
    readonly component: (props: FormProps) => JSX.Element | null
  }
>

export const accountSubRoutes: Route<TabKey> = {
  'account-info': {
    text: '帳戶資訊',
    /**
     * @todo 由於目前 component 由 Form.create() 包起來，所以 type 會有問題
     * 等更新 ant-design 4.0 使用 useForm 應該可以解決這個問題
     * @workaround 先暫時關閉 type check
     */
    // @ts-ignore
    component: Loadable(() => import('../form/account-info')),
  },
  'account-privileges': {
    text: '角色權限',
    /**
     * @todo 由於目前 component 由 Form.create() 包起來，所以 type 會有問題
     * 等更新 ant-design 4.0 使用 useForm 應該可以解決這個問題
     * @workaround 先暫時關閉 type check
     */
    // @ts-ignore
    component: Loadable(() => import('../form/account-privileges')),
  },
  'account-settings': {
    text: '通知設定',
    /**
     * @todo 由於目前 component 由 Form.create() 包起來，所以 type 會有問題
     * 等更新 ant-design 4.0 使用 useForm 應該可以解決這個問題
     * @workaround 先暫時關閉 type check
     */
    // @ts-ignore
    component: Loadable(() => import('../form/account-settings')),
  },
  'account-bind-entity': {
    text: '實體綁定',
    /**
     * @todo 由於目前 component 由 Form.create() 包起來，所以 type 會有問題
     * 等更新 ant-design 4.0 使用 useForm 應該可以解決這個問題
     * @workaround 先暫時關閉 type check
     */
    // @ts-ignore
    component: Loadable(() => import('../form/account-bind-entity')),
  },
  'account-app-info': {
    text: 'app 資訊',
    /**
     * @todo 由於目前 component 由 Form.create() 包起來，所以 type 會有問題
     * 等更新 ant-design 4.0 使用 useForm 應該可以解決這個問題
     * @workaround 先暫時關閉 type check
     */
    // @ts-ignore
    component: Loadable(() => import('../form/account-app-info')),
  },
}

export function isTabkey(key: string | TabKey): key is TabKey {
  return includes(key, tabKeys)
}
