import { Select } from 'antd'
import type { SelectProps } from 'antd/lib/select'
import React from 'react'

function CustomSelect(props: SelectProps<any>) {
  return (
    <Select
      notFoundContent="無符合資料"
      optionFilterProp="children"
      placeholder="輸入關鍵字"
      showSearch
      style={{ width: 120, marginRight: 8 }}
      {...props}
    />
  )
}

export default CustomSelect
