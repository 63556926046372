import { CheckOutlined } from '@ant-design/icons'
import { InstructionUtils } from '@sov/ui'
import { Menu } from 'antd'
import type { NamePath } from 'antd/lib/form/interface'
import React from 'react'

import type {
  Auxiliary,
  InstructionTeethInfoFragment,
  StageCardQueryDesignStageInlineFragment,
  ToothPosition,
} from '../../../../graphql/types'
import {
  AuxiliaryPosition,
  AuxiliaryType,
} from '../../../../graphql/types'

const { getIndex } = InstructionUtils

interface AuxiliaryMenu {
  getFieldValue: (name: NamePath) => any
  getPositionAuxiliary: (
    type: AuxiliaryType,
    position: ToothPosition,
    instructionItem: InstructionTeethInfoFragment | undefined
  ) => Auxiliary | undefined
  instructionItem: StageCardQueryDesignStageInlineFragment['instructionCard']
  handleAddAuxiliary: (
    toothPosition: ToothPosition,
    auxiliaryPosition: AuxiliaryPosition
  ) => void
  handleRemoveAuxiliary: (toothPosition: ToothPosition) => void
}

/**
 * 根據不同的 auxiliary type 可以放置的位置也不一樣：
 * @param position
 *  1. attachment index > 5: 一般/近心/遠心
 *  2. attachment index <= 5: 一般/切端/齒頸
 *  3. button index > 5: 一般/近心/遠心/齒頸
 *  4. button index <= 5: 一般/齒頸
 *  5. tad: 正上方/近心/遠心
 */
function AuxiliaryMenu({
  getFieldValue,
  getPositionAuxiliary,
  instructionItem,
  handleAddAuxiliary,
  handleRemoveAuxiliary,
}: AuxiliaryMenu) {
  return (position: ToothPosition) => {
    const type = getFieldValue('type')
    if (
      type === AuxiliaryType.StrippingAll
        || type === AuxiliaryType.StrippingHalf
    )
      return null

    const index = getIndex(position)
    const auxiliaryItem = getPositionAuxiliary(type, position, instructionItem)
    if (!auxiliaryItem)
      return null

    const MenuItem = (props) => {
      const {
        position,
        auxiliaryPosition,
        isSelected,
        children,
        ...restProps
      } = props

      return (
        <Menu.Item
          {...restProps}
          key={`add-${position}-${auxiliaryPosition}`}
          onClick={() => handleAddAuxiliary(position, auxiliaryPosition)}
        >
          <div
            style={{
              width: '60px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {isSelected ? <CheckOutlined /> : <div />}
            <div>{children}</div>
          </div>
        </Menu.Item>
      )
    }
    return (
      <Menu style={{ textAlign: 'end' }}>
        <MenuItem
          position={position}
          auxiliaryPosition={AuxiliaryPosition.Center}
          isSelected={
            AuxiliaryPosition.Center === auxiliaryItem.auxiliaryPosition
          }
        >
          {type === AuxiliaryType.Tad ? '正上方' : '一般'}
        </MenuItem>
        {(index > 5 || type === AuxiliaryType.Tad) && (
          <MenuItem
            position={position}
            auxiliaryPosition={AuxiliaryPosition.Near}
            isSelected={
              AuxiliaryPosition.Near === auxiliaryItem.auxiliaryPosition
            }
          >
            近心
          </MenuItem>
        )}
        {(index > 5 || type === AuxiliaryType.Tad) && (
          <MenuItem
            position={position}
            auxiliaryPosition={AuxiliaryPosition.Far}
            isSelected={
              AuxiliaryPosition.Far === auxiliaryItem.auxiliaryPosition
            }
          >
            遠心
          </MenuItem>
        )}
        {index <= 5 && type === AuxiliaryType.Attachment && (
          <MenuItem
            position={position}
            auxiliaryPosition={AuxiliaryPosition.Incisal}
            isSelected={
              AuxiliaryPosition.Incisal === auxiliaryItem.auxiliaryPosition
            }
          >
            切端
          </MenuItem>
        )}
        {(type === AuxiliaryType.Button
        || (index <= 5 && type === AuxiliaryType.Attachment)) && (
          <MenuItem
            position={position}
            auxiliaryPosition={AuxiliaryPosition.Cervical}
            isSelected={
              AuxiliaryPosition.Cervical === auxiliaryItem.auxiliaryPosition
            }
          >
            齒頸
          </MenuItem>
        )}
        <Menu.Divider />
        <Menu.Item
          key={`remove-${position}`}
          style={{ color: 'red' }}
          onClick={() => handleRemoveAuxiliary(position)}
        >
          刪除
        </Menu.Item>
      </Menu>
    )
  }
}

export { AuxiliaryMenu }
