import { exhaustiveCheck } from '@sov/common'
import { Button, Tag } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import type { Moment } from 'moment'
import moment from 'moment'
import { keys, pick } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { OrderStatus, OrderType } from '../../../../../graphql/types'
import type { FilterType, OrderFilterType } from '../index'
import { columnkeyTranslationMap } from '../OrderTable'
import FilterIcon from './FilterIcon'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const Label = styled.div`
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.45);
`
const FixedConditionDescription = styled.div`
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.65);
`
const CustomizedTag = styled(Tag)`
  color: rgba(0, 0, 0, 0.45);
`
const TagListContainer = styled.div`
  display: flex;
`
const CustomizedButton = styled(Button)`
  padding: 0;
`

const pickedFields = ['status', 'type'] as const
type PickedField = typeof pickedFields[number]

interface GetTranslatedKeyArgs {
  field: PickedField
  value: string
}

function getTranslatedKey(args: GetTranslatedKeyArgs) {
  const { field, value } = args
  switch (field) {
    case 'status':
      return `order.status.${value}`
    case 'type':
      return `order.type.${value}`
  }
  exhaustiveCheck(field)
}

interface FixedConditionInfo {
  appointmentIntervalEndDate: Moment
  filterType: FilterType
}
type GetFixedConditionDescription = (
  fixedConditionInfo: FixedConditionInfo
) => string
const getFixedConditionDescription: GetFixedConditionDescription = (
  fixedConditionInfo,
) => {
  const { appointmentIntervalEndDate, filterType } = fixedConditionInfo
  switch (filterType) {
    case 'appointmentIn15BussinessDays':
      return `填寫預計回診日期在 ${moment(appointmentIntervalEndDate).format(
        'YYYY-MM-DD',
      )} 前`
    case 'noAppointment':
      return '無預計回診日'
    case 'moldRelated':
    case 'all':
      return ''
  }
  exhaustiveCheck(filterType)
}

type RemovableCondition =
  | {
    key: 'status'
    value: OrderStatus[]
  }
  | {
    key: 'type'
    value: OrderType[]
  }

type GetRemovableConditionList = (
  orderFilterType: OrderFilterType
) => RemovableCondition[]
const getRemovableConditionList: GetRemovableConditionList = (
  orderFilterType,
) => {
  const picked = pick(['status', 'type'], orderFilterType)
  return keys(picked).map((field) => {
    /** 要讓回傳的 type 符合 RemovableCondition，多使用了 switch case 來限制 type，但實作是一樣的 */
    switch (field) {
      case 'status':
        return {
          key: field,
          value: picked[field] ?? [],
        }
      case 'type':
        return {
          key: field,
          value: picked[field] ?? [],
        }
    }
    exhaustiveCheck(field)
  })
}

interface TagInfo {
  key: PickedField
  value: string
}

export interface FilterConditionProps {
  fixedConditionInfo: FixedConditionInfo
  tableFilterInfo: OrderFilterType
  handleTagRemove: (tagInfo: TagInfo) => void
  handleFilterClear: () => void
}

function FilterCondition(props: FilterConditionProps) {
  const {
    fixedConditionInfo,
    tableFilterInfo,
    handleTagRemove,
    handleFilterClear,
  } = props

  const fixedConditionDescription
    = getFixedConditionDescription(fixedConditionInfo)
  const removableConditionList = getRemovableConditionList(tableFilterInfo)

  const { t } = useTranslation()

  const handleClearButtonClick: ButtonProps['onClick'] = () => {
    handleFilterClear()
  }

  return (
    <Container>
      <FilterIcon />
      <Label>搜索條件:</Label>
      <FixedConditionDescription>
        {fixedConditionDescription}
      </FixedConditionDescription>
      <TagListContainer>
        {removableConditionList.map((removableCondition) => {
          const tagValueList: string[] = removableCondition.value
          return tagValueList.map(value => (
            <CustomizedTag
              key={`removable-tag-${removableCondition.key}-${value}`}
              closable
              onClose={() => {
                handleTagRemove({
                  key: removableCondition.key,
                  value,
                })
              }}
            >
              {columnkeyTranslationMap[removableCondition.key]}
              :
              {' '}
              {t(getTranslatedKey({ field: removableCondition.key, value }))}
            </CustomizedTag>
          ))
        })}
      </TagListContainer>
      <CustomizedButton type="link" onClick={handleClearButtonClick}>
        清除篩選條件
      </CustomizedButton>
    </Container>
  )
}

export default FilterCondition
