import { gql, useQuery } from '@apollo/client'
import { ErrorHandling, TableQuery } from '@sov/common'
import { evolve } from 'ramda'

import type {
  PatientTasksQueryQuery,
  PatientTasksQueryQueryVariables,
} from '../../../../../graphql/types'
import type {
  TaskFilterType,
  TaskSorterField,
} from '../../../../pages/task/TaskList/useTaskList'
import TableTask from '../../../table/Task'

const patientTasksQuery = gql`
  query PatientTasksQuery(
    $query: TasksQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-updated"
  ) {
    tasks(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...TableTask
      }
      total
      limit
      page
    }
  }
  ${TableTask.fragment}
`

export function usePatientTasks({ patientId }: { patientId: string }) {
  const { tableQuery, paginateQuery, handleTableReset, handleTableChange }
    = TableQuery.useTableQuery<TaskFilterType, TaskSorterField>({
      sort: '-updated',
      limit: 20,
    })

  const filterQuery = tableQuery.filters
    ? evolve(
      {
        type: type =>
          typeof type === 'undefined' || Array.isArray(type)
            ? type
            : [type],
        status: status =>
          typeof status === 'undefined' || Array.isArray(status)
            ? status
            : [status],
      },
      tableQuery.filters,
    )
    : {}

  const { toErrorPage } = ErrorHandling.useErrorHandling()

  const { data, loading } = useQuery<
    PatientTasksQueryQuery,
    PatientTasksQueryQueryVariables
  >(patientTasksQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: (error) => {
      toErrorPage(error.message)
    },
    variables: {
      query: {
        patient: patientId,
        ...filterQuery,
      },
      ...paginateQuery,
    },
  })

  return {
    loading,
    tasks: data?.tasks,
    tableFilterInfo: tableQuery.filters,
    tableSortInfo: tableQuery.sort,
    handleTableChange,
    handleRefresh: handleTableReset,
  }
}
