import { gql, useMutation } from '@apollo/client'
import { getEmptyText, isEmptyOrNil } from '@sov/common'
import {
  Alert,
  Button,
  Descriptions,
  Form,
  Input,
  Modal,
  Tag,
  message,
} from 'antd'
import type { FormProps } from 'antd/lib/form'
import type { Moment } from 'moment'
import moment from 'moment'
import { compose, head, isNil, map, reject, sort } from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'

import type {
  ReplyTrackInput,
  ReplyTrackMutation,
  ReplyTrackMutationVariables,
  TrackTableFragment,
  TracksQueryDocs,
} from '../../../graphql/types'

const StyledDescription = styled(Descriptions)`
  .ant-descriptions-item-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
`

const PresetContent = styled(Tag)`
  cursor: pointer;
  margin-bottom: 4px;
`

const StyledAlert = styled(Alert)`
  .ant-alert-message {
    color: #faad14;
  }
`

const replyTrack = gql`
  mutation ReplyTrack($id: ID!, $payload: ReplyTrackInput!) {
    replyTrack(id: $id, payload: $payload) {
      id
    }
  }
`
export const formPickedFields = ['reply', 'replyDate'] as const

const formFieldsNames: (keyof ReplyTrackInput)[] = [...formPickedFields]
type FormFieldsNames = typeof formFieldsNames
type FormFieldsName = FormFieldsNames[number]

type FormGeneralFields = Pick<ReplyTrackInput, FormFieldsName>
type FormInitialValues = Partial<FormGeneralFields>
interface TrackReplyProps extends FormProps {
  visible?: boolean
  track: TrackTableFragment
  refetch?: () => void
  handleClose?: () => void
}

const presetContents = [
  '佩戴狀況良好，繼續保持',
  '建議使用咬咬，讓牙套更密合牙齒',
  '已同步告知醫生狀況，建議在回診前...',
]

function TrackReply(props: TrackReplyProps) {
  const { visible = false, track, refetch, handleClose } = props
  const isReply = !isNil(track.reply)
  const [reply] = useMutation<ReplyTrackMutation, ReplyTrackMutationVariables>(
    replyTrack,
  )
  const [form] = Form.useForm()
  const [hasReplyContent, setHasReplyContent] = useState<boolean>(false)
  const handleSetPresetContent = (content: string) => {
    form.setFieldsValue({ reply: content })
    setHasReplyContent(true)
  }
  const handleSubmit = async () => {
    await reply({
      variables: {
        id: track?.id,
        payload: {
          reply: form.getFieldValue('reply'),
          replyDate: moment().toDate(),
        },
      },
      update: async (cache, { data }) => {
        if (data) {
          handleClose && handleClose()
          message.success('已成功送出')
          refetch && refetch()
        }
      },
    })
  }

  const startWearDate = compose<
    NonNullable<TracksQueryDocs['stage']['subStage']>,
    Moment[],
    Moment[],
    Moment[],
    Moment
  >(
    head,
    sort((a, b) => (a.isAfter(b) ? 1 : -1)),
    reject(isNil),
    map(subStage => subStage.startDate && moment(subStage.startDate)),
  )(track.stage.subStage || [])

  const initialValues: FormInitialValues = { reply: track.reply }
  return (
    <Modal
      width={736}
      title="生理追蹤報告回覆"
      visible={visible}
      onCancel={handleClose}
      footer={
        isReply
          ? [
            <Button key="cancel" type="primary" onClick={handleClose}>
              關閉
            </Button>,
            ]
          : [
            <Button
              key="cancel"
              onClick={handleClose}
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
            >
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!hasReplyContent}
              onClick={handleSubmit}
            >
              送出
            </Button>,
            ]
      }
    >
      <Form initialValues={initialValues} form={form}>
        <Form.Item>
          <StyledDescription layout="vertical" size="small">
            <Descriptions.Item label="病患姓名">
              {track.patient.name}
            </Descriptions.Item>
            <Descriptions.Item label="配戴階段">
              {track.stage.serialNumber}
            </Descriptions.Item>
            <Descriptions.Item label="開始配戴日期">
              {startWearDate
                ? startWearDate.format('YYYY-MM-DD')
                : getEmptyText()}
            </Descriptions.Item>
          </StyledDescription>
        </Form.Item>
        <Form.Item
          label="牙技師回覆內容"
          style={{ marginBottom: '0px' }}
          required
        >
        </Form.Item>

        <Form.Item hidden={isReply} style={{ marginBottom: '0px' }}>
          {map(
            content => (
              <PresetContent onClick={() => handleSetPresetContent(content)}>
                {content}
              </PresetContent>
            ),
            presetContents,
          )}
        </Form.Item>

        <Form.Item
          name="reply"
          style={{ marginBottom: '8px' }}
          rules={[{ required: true, message: '必填欄位' }]}
        >
          {isReply
            ? (
              <p>
                {' '}
                {initialValues?.reply}
              </p>
              )
            : (
              <Input.TextArea
                placeholder="請填入回覆病患內容"
                autoSize={{ minRows: 3 }}
                onChange={(e) => {
                  if (isEmptyOrNil(e.target.value))
                    setHasReplyContent(false)
                  else
                    setHasReplyContent(true)
                }}
              />
              )}
        </Form.Item>
        {isReply
          ? (
            <Form.Item style={{ marginBottom: '0px' }} label="回覆日期">
              {track.replyDate && moment(track.replyDate).format('YYYY-MM-DD')}
            </Form.Item>
            )
          : (
            <StyledAlert
              message="按下送出後內容就無法修改，請在送出前確認內容無誤"
              type="warning"
              style={{ color: '#FAAD14' }}
              showIcon
            />
            )}
      </Form>
    </Modal>
  )
}

export default TrackReply
