import Facebook from './icon-facebook.png'
import FacebookDisabled from './icon-facebook-n.png'
import Google from './icon-google.png'
import GoogleDisabled from './icon-google-n.png'
import Line from './icon-line.png'
import LineDisabled from './icon-line-n.png'

export default {
  facebook: {
    normal: Facebook,
    gray: FacebookDisabled,
  },
  google: {
    normal: Google,
    gray: GoogleDisabled,
  },
  line: {
    normal: Line,
    gray: LineDisabled,
  },
}
