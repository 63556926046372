import { gql } from '@apollo/client'

import { ProductInfoFragment } from '../fragment'

export const createProductMutation = gql`
  mutation CreateProduct($payload: CreateProductInput!) {
    createProduct(payload: $payload) {
      ...ProductInfo
    }
  }
  ${ProductInfoFragment}
`
