import { gql } from '@apollo/client'

export const createInvoiceWithStageMutation = gql`
  mutation CreateInvoiceWithStage($payload: CreateInvoiceWithStageInput!) {
    createInvoiceWithStage(payload: $payload) {
      id
    }
  }
`

export const createInvoiceWithoutStageMutation = gql`
  mutation CreateInvoiceWithoutStage(
    $payload: CreateInvoiceWithoutStageInput!
  ) {
    createInvoiceWithoutStage(payload: $payload) {
      id
    }
  }
`
