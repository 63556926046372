export enum CAMERA_VIEW {
  FRONT = 'FRONT',
  BACK = 'BACK',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum VIEW_METHOD {
  NORMAL = 'NORMAL',
  SPLIT = 'SPLIT',
}

export enum MESH_TYPE {
  TEETH = 'TEETH',
  JAW = 'JAW',
}

export enum MESH_LOCATION {
  UPPER = 'UPPER',
  LOWER = 'LOWER',
}

export enum ALL_OPTION {
  ALL = 'ALL',
}

export type MESH_TYPE_WITH_ALL = MESH_TYPE | ALL_OPTION
export type MESH_LOCATION_WITH_ALL = MESH_LOCATION | ALL_OPTION
