import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"

        d="M7.18426 12.9853H7.18421C6.87879 12.9853 6.5706 12.7533 6.5706 12.2351V7.80565L3.23882 4.127C3.14073 4.01869 3.07191 3.9068 3.03425 3.79443C2.98457 3.64616 2.98901 3.49858 3.04708 3.36764C3.10515 3.2367 3.21155 3.13434 3.35479 3.07163C3.46336 3.0241 3.59249 3 3.73861 3H12.2614C12.4075 3 12.5367 3.0241 12.6452 3.07164C12.7884 3.13434 12.8948 3.2367 12.9529 3.36764C13.011 3.49858 13.0154 3.64616 12.9658 3.79443C12.9281 3.9068 12.8593 4.01869 12.7612 4.127L9.42938 7.8057V10.4707C9.42938 10.9744 9.13614 11.5955 8.74728 11.9155L7.70681 12.7717C7.53461 12.9134 7.35879 12.9853 7.18426 12.9853Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  )
}

const FilterIcon = props => <Icon {...props} component={Svg} />

export default FilterIcon
