import { gql } from '@apollo/client'
import type { HTMLReactParserOptions } from 'html-react-parser'
import parser, { domToReact } from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'

import type { AnalysisPreviewAnalysisFragment } from '../../codegen/types'
import { PageBreak, PageContainerHorizontalMargin, PageTitle } from './Page'

const Container = styled.div`
  color: black;
`

const PageBreakHintBorder = styled.div`
  @media screen {
    border-top: 1px dashed #ececec;
  }
`

const Description = styled.div`
  margin: 20px 0;
  font-family: Helvetica Neue, Helvetica, Arial, 'Microsoft JhengHei',
    'Heiti TC', 'DFKai-SB', 'BiauKai', sans-serif;
  font-size: 16px;

  h1 {
    font-size: 18px;
  }
`

interface Props {
  stage: AnalysisPreviewAnalysisFragment
}

function Analysis(props: Props) {
  const { stage } = props
  const description = stage.analysis?.digiAnalysis

  const replace: HTMLReactParserOptions['replace'] = (node) => {
    const isPageBreak = (node.children ?? [])[0]?.data?.includes(
      '<!-- pagebreak -->',
    )

    if (isPageBreak) {
      return (
        <>
          <PageBreakHintBorder />
          <PageBreak previewInHTML={false} />
        </>
      )
    }

    return (
      <PageContainerHorizontalMargin>
        {domToReact([node])}
      </PageContainerHorizontalMargin>
    )
  }

  return (
    <Container>
      <PageContainerHorizontalMargin>
        <PageTitle>數位分析內容</PageTitle>
      </PageContainerHorizontalMargin>
      <Description>
        {description ? parser(description, { replace }) : ''}
      </Description>
    </Container>
  )
}

Analysis.fragment = gql`
  fragment AnalysisPreviewAnalysis on EvalStage {
    shippingDate
    analysis {
      digiAnalysis
    }
  }
`

export default Analysis
