import { gql, useQuery } from '@apollo/client'
import { getEmptyText } from '@sov/common'
import { Link } from '@sov/ui'
import { Card, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import styled from 'styled-components'

import type {
  StageLinkFragment,
  StageOrderInfoQuery,
  StageOrderInfoQueryVariables,
  StageOrderInfoStage,
} from '../../../../../../graphql/types'
import { getSerializedStageOrderList } from '../../../../../utils'
import EmptyInstructionCard from '../EmptyInstructionCard'
import OrderRow from './OrderRow'

const { StageLink } = Link

function getOrderSourceStage(stage: StageOrderInfoStage) {
  if (!stage.__typename)
    return undefined

  switch (stage.__typename) {
    case 'EvalStage':
    case 'PrintStage':
    case 'AccessoryStage':
      return stage
    case 'DesignStage':
      return stage.latestPrintStage
    case 'MoldStage':
      return stage.latestEvalStage
  }
}

const CustomizedCard = styled(Card)`
  width: 300px;

  .ant-card-body {
    padding: 0;
    margin-top: 1px;
  }
`

const CustomizedSpace = styled(Space)`
  margin: 16px;
`

interface OrderStageSourceProps {
  item?: StageLinkFragment
}

function OrderStageSource(props: OrderStageSourceProps) {
  const { item } = props

  if (!item)
    return null

  return (
    <div>
      <span>(資料來自</span>
      <StageLink item={item} style={{ color: '#1890ff' }} />
      <span>)</span>
    </div>
  )
}

const fragments = {
  OrderInfo: gql`
    fragment OrderInfo on Order {
      id
      displayId
      type
      stageOrder {
        stageType
        number
        items
      }
      creator {
        id
        name
      }
      instruction
      appointment {
        id
        startDate
      }
    }
  `,
}

const stageOrderInfoQuery = gql`
  query StageOrderInfo($stageId: ID!) {
    stage(id: $stageId) {
      ...StageLink
      ... on EvalStage {
        order {
          ...OrderInfo
        }
      }
      ... on PrintStage {
        order {
          ...OrderInfo
        }
      }
      ... on AccessoryStage {
        order {
          ...OrderInfo
        }
      }
      ... on DesignStage {
        latestPrintStage {
          ...StageLink
          order {
            ...OrderInfo
          }
        }
      }
      ... on MoldStage {
        latestEvalStage {
          ...StageLink
          order {
            ...OrderInfo
          }
        }
      }
    }
  }
  ${StageLink.fragment}
  ${fragments.OrderInfo}
`

const orderStageTypesFromOtherStage = ['MoldStage', 'DesignStage']

interface OrderInfoProps {
  stageId: string
}

function OrderInfo(props: OrderInfoProps) {
  const { stageId } = props

  const { t } = useTranslation()

  const { data, loading } = useQuery<
    StageOrderInfoQuery,
    StageOrderInfoQueryVariables
  >(stageOrderInfoQuery, {
    variables: { stageId },
  })

  const stage = data?.stage

  if (loading)
    return <EmptyInstructionCard loading />

  if (!stage)
    return <EmptyInstructionCard loading={false} />

  const orderSourceStage = getOrderSourceStage(stage)
  const order = orderSourceStage?.order

  if (!order)
    return <EmptyInstructionCard loading={false} />

  const serializedStageOrderList = getSerializedStageOrderList({
    stageOrder: order.stageOrder,
    t,
  })

  const shouldDisplayOrderSourceStage = orderStageTypesFromOtherStage.includes(
    stage.__typename as string,
  )

  return (
    <CustomizedCard
      title="訂單資訊"
      size="small"
      extra={(
        <OrderStageSource
          item={shouldDisplayOrderSourceStage ? orderSourceStage : undefined}
        />
      )}
    >
      <CustomizedSpace direction="vertical" size={8}>
        <OrderRow label="訂單編號">
          <ReactRouterDomLink to={`/orders/${order.id}`}>
            #
            {order.displayId}
          </ReactRouterDomLink>
        </OrderRow>
        <OrderRow label="訂單類型">{t(`order.type.${order.type}`)}</OrderRow>
        <OrderRow label="訂單項目">{serializedStageOrderList}</OrderRow>
        <OrderRow label="訂單約診日">
          {order.appointment
            ? moment(order.appointment.startDate).format('YYYY-MM-DD')
            : getEmptyText()}
        </OrderRow>
        <OrderRow label="訂單建立人">{order.creator.name}</OrderRow>
      </CustomizedSpace>
    </CustomizedCard>
  )
}

export default OrderInfo
