import { gql } from '@apollo/client'

export const ProductInfoFragment = gql`
  fragment ProductInfo on Product {
    id
    created
    updated
    serialNumber
    name
    price
    spec
    cost
  }
`
