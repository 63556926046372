import { District } from '../graphql/types'

export const TaiwanDistricts = {
  /** 台北 */
  TPE: District.Tpe,
  /** 新北 */
  NTPC: District.Ntpc,
  /** 基隆 */
  KEL: District.Kel,
  /** 桃園 */
  TYN: District.Tyn,
  /** 新竹 */
  HSZ: District.Hsz,
  /** 苗栗 */
  ZMI: District.Zmi,
  /** 台中 */
  TXG: District.Txg,
  /** 彰化 */
  CHW: District.Chw,
  /** 南投 */
  NTC: District.Ntc,
  /** 雲林 */
  YUN: District.Yun,
  /** 嘉義 */
  CYI: District.Cyi,
  /** 台南 */
  TNN: District.Tnn,
  /** 高雄 */
  KHH: District.Khh,
  /** 屏東 */
  PIF: District.Pif,
  /** 宜蘭 */
  ILA: District.Ila,
  /** 花蓮 */
  HUN: District.Hun,
  /** 台東 */
  TTT: District.Ttt,
  /** 澎湖 */
  PEH: District.Peh,
}

export const ChinaDistricts = {
  /** 安徽 */
  AH: District.Ah,
  /** 北京 */
  BJ: District.Bj,
  /** 福建 */
  FJ: District.Fj,
  /** 甘肃 */
  GS: District.Gs,
  /** 广东 */
  GD: District.Gd,
  /** 广西 */
  GX: District.Gx,
  /** 贵州 */
  GZ: District.Gz,
  /** 海南 */
  HI: District.Hi,
  /** 河北 */
  HE: District.He,
  /** 河南 */
  HA: District.Ha,
  /** 黑龙江 */
  HL: District.Hl,
  /** 湖北 */
  HB: District.Hb,
  /** 湖南 */
  HN: District.Hn,
  /** 吉林 */
  JL: District.Jl,
  /** 江苏 */
  JS: District.Js,
  /** 江西 */
  JX: District.Jx,
  /** 辽宁 */
  LN: District.Ln,
  /** 内蒙古自治区 */
  NM: District.Nm,
  /** 宁夏 */
  NX: District.Nx,
  /** 青海 */
  QH: District.Qh,
  /** 山东 */
  SD: District.Sd,
  /** 山西 */
  SX: District.Sx,
  /** 陕西 */
  SN: District.Sn,
  /** 上海 */
  SH: District.Sh,
  /** 四川 */
  SC: District.Sc,
  /** 天津 */
  TJ: District.Tj,
  /** 西藏 */
  XZ: District.Xz,
  /** 新疆 */
  XJ: District.Xj,
  /** 云南 */
  YN: District.Yn,
  /** 浙江 */
  ZJ: District.Zj,
  /** 重庆 */
  CQ: District.Cq,
  /** 澳门 */
  MO: District.Mo,
  /** 香港 */
  HK: District.Hk,
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N
