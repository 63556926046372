import { Button as AntdButton } from 'antd'
import type { ButtonProps as AntdButtonProps } from 'antd/lib/button'
import Color from 'color'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

/** 參考設計稿 https://www.figma.com/file/apE4aCn8QWPQZOwRWzbOk5/CRM-%2FERP-Library?node-id=0%3A74 */
type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tetiary'
  | 'text'
  | 'danger'
  | 'icon'

const primary = '#1890ff'
const darkenPrimary = Color('#1890ff').darken(0.3).hex()
const lightenPrimary = Color('#1890ff').lightness(95).hex()

const defaultButtonStyle = css`
  padding: 0 16px;
`

const primaryButtonStyle = css`
  border-radius: 4px;
  box-sizing: border-box;

  background-color: ${primary};
  border: 1px solid ${primary};
  color: #ffffff;
  opacity: 1;

  &:focus,
  &:hover,
  &:active {
    background-color: ${darkenPrimary};
    border: 1px solid ${darkenPrimary};
    color: #ffffff;
    opacity: 1;
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${primary};
    border: 1px solid transparent;
    color: #ffffff;
    opacity: 0.45;
  }
`

const secondaryButtonStyle = css`
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;

  border: 1px solid ${primary};
  color: ${primary};
  opacity: 1;

  &:focus,
  &:hover,
  &:active {
    border: 1px solid ${darkenPrimary};
    color: ${darkenPrimary};
    opacity: 1;
  }

  &:disabled,
  &:disabled:hover {
    border: 1px solid transparent;
    color: ${primary};
    opacity: 0.45;
  }
`

const textButtonStyle = css`
  border: none;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;

  background-color: #ffffff;
  color: ${primary};

  &:hover {
    background-color: ${lightenPrimary};
    color: ${primary};
  }

  &:focus,
  &:active {
    background-color: #ffffff;
    color: ${darkenPrimary};
  }

  &:disabled {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.25);
  }
`

interface CustomButtonProps {
  buttonType?: ButtonType
}

const CustomButton = styled(AntdButton)<CustomButtonProps>`
  ${defaultButtonStyle}
  ${props => props.buttonType === 'primary' && primaryButtonStyle}
  ${props => props.buttonType === 'secondary' && secondaryButtonStyle}
  ${props => props.buttonType === 'text' && textButtonStyle}
`

type ButtonProps = AntdButtonProps & CustomButtonProps

function Button(props: ButtonProps) {
  return <CustomButton {...props}>{props.children}</CustomButton>
}

type OnceButtonProps = AntdButtonProps & CustomButtonProps

function OnceButton(props: OnceButtonProps) {
  const [clicked, setClicked] = useState(false)
  const onClick: ButtonProps['onClick'] = (e) => {
    if (props.onClick) {
      setClicked(true)
      props.onClick(e)
    }
  }
  return (
    <CustomButton {...props} onClick={onClick} disabled={clicked}>
      {props.children}
    </CustomButton>
  )
}

export { Button, OnceButton }
