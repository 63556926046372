import { gql, useMutation } from '@apollo/client'
import { Button, Card, Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useState } from 'react'
import { CSVLink } from 'react-csv'

import type {
  ExportInvoicesMutation,
  ExportInvoicesMutationVariables,
  ExportPatientsMutation,
  ExportPatientsMutationVariables,
} from '../../../../graphql/types'

const systemExportPatientsMutation = gql`
  mutation ExportPatients($query: PatientsQuery!) {
    exportPatients(query: $query) {
      data
    }
  }
`

const systemExportInvoicesMutation = gql`
  mutation ExportInvoices($query: InvoiceQuery!) {
    exportInvoices(query: $query) {
      data
    }
  }
`

function SystemExport() {
  const [formPatient] = useForm()
  const [formInvoice] = useForm()

  const [exportPatients] = useMutation<
    ExportPatientsMutation,
    ExportPatientsMutationVariables
  >(systemExportPatientsMutation)

  const [exportInovices] = useMutation<
    ExportInvoicesMutation,
    ExportInvoicesMutationVariables
  >(systemExportInvoicesMutation)

  const [patientCSV, setPatientCSV] = useState('')
  const [invoiceCSV, setInvoiceCSV] = useState('')

  const handleExportPatients = async () => {
    const hide = message.loading('查詢資料中請稍後...', 0)
    await exportPatients({
      variables: {
        query: {},
      },
      update: async (cache, { data }) => {
        setPatientCSV(data?.exportPatients.data ?? '')
        hide()
      },
    })
  }

  const handleExportInvoices = async () => {
    const hide = message.loading('查詢資料中請稍後...', 0)
    await exportInovices({
      variables: {
        query: {},
      },
      update: async (cache, { data }) => {
        setInvoiceCSV(data?.exportInvoices.data ?? '')
        hide()
      },
    })
  }

  return (
    <Card title="匯出資料" style={{ width: 300, height: 600 }}>
      <Form form={formPatient}>
        <Form.Item>
          <Button onClick={handleExportPatients}>查詢病患</Button>
        </Form.Item>
        {patientCSV && (
          <Form.Item>
            <CSVLink data={patientCSV} filename="patients.csv" target="_blank">
              下載病患CSV
            </CSVLink>
          </Form.Item>
        )}
      </Form>
      <Form form={formInvoice}>
        <Form.Item>
          <Button onClick={handleExportInvoices}>查詢出貨單</Button>
        </Form.Item>
        {invoiceCSV && (
          <Form.Item>
            <CSVLink data={invoiceCSV} filename="invoices.csv" target="_blank">
              下載出貨單CSV
            </CSVLink>
          </Form.Item>
        )}
      </Form>
    </Card>
  )
}

export default SystemExport
