import { gql, useMutation } from '@apollo/client'
import { DisplayPatientBrand } from '@sov/ui'
import { Button, DatePicker, Form, Input, Modal, Radio, message } from 'antd'
import { values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type {
  CreatePatientModalMutation,
  CreatePatientModalMutationVariables,
} from '../../../../graphql/types'
import {
  PatientBrand,
  PatientSource,
} from '../../../../graphql/types'
import { PatientSelectGroup } from '../../form/patient/utils/PatientSelectGroup'

const createPatientModalMutation = gql`
  mutation CreatePatientModal($payload: CreatePatientInput!) {
    createPatient(payload: $payload) {
      id
    }
  }
`

interface Props {
  visible: boolean
  setVisible: (boolean) => void
  onCancel: () => void
}

function PatientCreateModal(props: Props) {
  const { t } = useTranslation()
  const { visible, setVisible, onCancel } = props
  const [form] = Form.useForm()
  const [createPatient] = useMutation<
    CreatePatientModalMutation,
    CreatePatientModalMutationVariables
  >(createPatientModalMutation)

  const handleCreate = async () => {
    await form.validateFields()
    const payload = form.getFieldsValue()

    try {
      await createPatient({
        variables: { payload },
        update: (cache, { data }) => {
          if (data?.createPatient)
            message.info('已新增病患')

          setVisible(false)
          form.resetFields()
        },
      })
    }
    catch (e) {
      message.error(e.message, 3)
    }
  }

  return (
    <Modal
      title="快速新增病患"
      footer={(
        <Button type="primary" onClick={handleCreate}>
          快速新增
        </Button>
      )}
      visible={visible}
      width={1024}
      onCancel={onCancel}
    >
      <Form form={form}>
        <Form.Item
          label="姓名"
          name="name"
          rules={[{ required: true, message: '請輸入' }]}
        >
          <Input style={{ width: 120 }} />
        </Form.Item>
        <PatientSelectGroup form={form} />
        <Form.Item label="品牌" name={['payment', 'brand']}>
          <Radio.Group>
            {values(PatientBrand).map(brand => (
              <Radio key={brand} value={brand}>
                <DisplayPatientBrand value={brand} />
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="客源" name={['payment', 'source']}>
          <Radio.Group>
            {values(PatientSource).map(source => (
              <Radio key={source} value={source}>
                {t(`patient.source.${source}`)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="下次約診" name={['appointmentAt']}>
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export { PatientCreateModal }
