import { QuestionCircleFilled } from '@ant-design/icons'
import { Card, Space, Tooltip } from 'antd'
import type { ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'

import type { IconType } from './CardIcon'
import CardIcon from './CardIcon'

interface CustomCardProps {
  borderColor: string
}

const CustomCard = styled(Card)<CustomCardProps>`
  border-top-color: ${props => props.borderColor};
  border-top-style: solid;
  border-top-width: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: -7px;

  /** 覆寫 antd style */
  .ant-card-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const CardTextSection = styled.div`
  width: 100%;
`

const CardIconSection = styled.div`
  display: flex;
  align-items: center;
`

const CardTitleSection = styled.div`
  display: flex;
  align-items: center;
`

const CardTitle = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 24px;
`

const CustomQuestionIcon = styled(QuestionCircleFilled)`
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.2);
`

const PatientCount = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-size: 40px;
  line-height: 40px;
`

const Unit = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px;
`

interface PatientCardProps {
  borderColor: string
  comparisonLabel?: ReactNode
  displayNumber: number
  footer: ReactNode
  iconType: IconType
  title: string
  titleTooltip?: string
}

function PatientCard(props: PatientCardProps) {
  const {
    borderColor,
    comparisonLabel,
    displayNumber,
    footer,
    iconType,
    title,
    titleTooltip,
  } = props

  return (
    <CustomCard borderColor={borderColor}>
      <CardTextSection>
        <Space direction="vertical" size={8}>
          <CardTitleSection>
            <CardTitle>{title}</CardTitle>
            {titleTooltip && (
              <Tooltip title={titleTooltip}>
                <CustomQuestionIcon />
              </Tooltip>
            )}
          </CardTitleSection>
          <Space align="end" size={4}>
            <PatientCount>{displayNumber}</PatientCount>
            <Unit>人</Unit>
            {comparisonLabel}
          </Space>
          {footer}
        </Space>
      </CardTextSection>
      <CardIconSection>
        <CardIcon type={iconType} />
      </CardIconSection>
    </CustomCard>
  )
}

export default PatientCard
