import { blue } from '@ant-design/colors'
import { ToolOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Button, Divider, Popover, Space, Tag, message } from 'antd'
import type { PopoverProps } from 'antd/lib/popover'
import { findIndex, isNil, propEq } from 'ramda'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type {
  BackwardCurrentTaskMutation,
  BackwardCurrentTaskMutationVariables,
  ForwardCurrentTaskMutation,
  ForwardCurrentTaskMutationVariables,
  TagCurrentTaskStageItemCurrentTask,
  TagCurrentTaskStageItemFragment,
} from '../../../../graphql/types'
import {
  StageStatus,
} from '../../../../graphql/types'
import { EmployeeSelect } from '../../../components/form/Select'
import type { EmployeeSelectProps } from '../../../components/form/Select/EmployeeSelect'

const BaseTag = styled(Tag)`
  border: none;
  border-radius: 4px;
`

const ClickableTag = styled(BaseTag as any)`
  cursor: pointer;
  color: ${blue.primary};
  border-color: ${blue[2]};
  background-color: ${blue[0]};
  &:hover {
    background-color: ${blue[1]};
  }
`

const forwardCurrentTask = gql`
  mutation ForwardCurrentTask($id: ID!, $payload: ForwardTaskInput!) {
    forwardTask(id: $id, payload: $payload) {
      id
    }
  }
`

const backwardCurrentTask = gql`
  mutation BackwardCurrentTask($id: ID!) {
    backwardTask(id: $id) {
      id
    }
  }
`

interface PopoverForwardTaskProps extends PopoverProps {
  isFirst: boolean
  isLast: boolean
  currentTask: TagCurrentTaskStageItemCurrentTask
  handleRefetch: () => Promise<void>
}
const PopoverForwardTask: FC<PopoverForwardTaskProps> = (props) => {
  const { isFirst, isLast, currentTask, handleRefetch, children } = props
  const nextTask = currentTask.nextTask

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [nextTaskOwner, setNextTaskOwner] = useState<string>()
  const [forwardTask] = useMutation<
    ForwardCurrentTaskMutation,
    ForwardCurrentTaskMutationVariables
  >(forwardCurrentTask)
  const [backwardTask] = useMutation<
    BackwardCurrentTaskMutation,
    BackwardCurrentTaskMutationVariables
  >(backwardCurrentTask)

  const handleSelect: EmployeeSelectProps['handleItemSelect'] = (
    selectedItem,
  ) => {
    setNextTaskOwner(selectedItem.id)
  }
  const handleVisibleChange: PopoverProps['onVisibleChange'] = (visible) => {
    setVisible(visible)
  }
  const handleForward = async () => {
    try {
      setLoading(true)
      await forwardTask({
        variables: {
          id: currentTask.id,
          payload: {
            nextOwner: nextTaskOwner,
          },
        },
        update: async (cache, { data }) => {
          if (data?.forwardTask) {
            await handleRefetch()
            setLoading(false)
            handleVisibleChange(false)
            message.success('成功完成任務')
          }
        },
      })
    }
    catch (error) {
      message.error(error)
      setLoading(false)
    }
  }
  const handleBackward = async () => {
    try {
      setLoading(true)
      await backwardTask({
        variables: { id: currentTask.id },
        update: async (cache, { data }) => {
          if (data?.backwardTask) {
            await handleRefetch()
            setLoading(false)
            handleVisibleChange(false)
            message.warn('任務已退回')
          }
        },
      })
    }
    catch (error) {
      message.error(error)
      setLoading(false)
    }
  }

  const Content = (
    <Space direction="vertical">
      {!isLast && nextTask
        ? (
          <>
            <span>
              下個任務:
              {t(`task.type.${nextTask.type}`)}
            </span>
            <span>往下傳</span>
            <EmployeeSelect
              placeholder="選擇人員"
              query={{
                taskOwner: [nextTask.type],
              }}
              handleItemSelect={handleSelect}
            />
          </>
          )
        : (
          <span>此為最後一個任務</span>
          )}
      <Button
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={handleForward}
        block
      >
        完成任務
      </Button>
      {!isFirst && (
        <>
          <Divider style={{ margin: '0' }} />
          <Button
            type="ghost"
            danger
            loading={loading}
            disabled={loading}
            onClick={handleBackward}
            block
          >
            退回任務
          </Button>
        </>
      )}
    </Space>
  )
  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={Content}
      {...props}
    >
      {children}
    </Popover>
  )
}

interface TagCurrentTaskProps {
  stageItem: TagCurrentTaskStageItemFragment
  handleRefetch: () => Promise<void>
}

function TagCurrentTask(props: TagCurrentTaskProps) {
  const { stageItem, handleRefetch } = props
  const { status, currentTask, tasks } = stageItem

  const { t } = useTranslation()

  if (
    status === StageStatus.Dropped
    || status === StageStatus.Pending
    || isNil(currentTask)
  )
    return <BaseTag icon={<ToolOutlined />}>無法進行任務</BaseTag>

  if (status === StageStatus.Completed) {
    return (
      <BaseTag icon={<ToolOutlined />} color="success">
        進度：已完成
      </BaseTag>
    )
  }

  const currentTaskIndex = findIndex(
    task => propEq('id', currentTask.id, task),
    tasks,
  )
  const isFirst = currentTaskIndex === 0
  const isLast = currentTaskIndex === tasks.length - 1

  return (
    <PopoverForwardTask
      isFirst={isFirst}
      isLast={isLast}
      currentTask={currentTask}
      handleRefetch={handleRefetch}
    >
      <ClickableTag icon={<ToolOutlined />}>
        進度：
        {t(`task.type.${currentTask.type}`)}
        {' '}
        /
        {' '}
        {currentTask.owner?.name}
      </ClickableTag>
    </PopoverForwardTask>
  )
}

TagCurrentTask.fragments = {
  TagCurrentTaskStageItem: gql`
    fragment TagCurrentTaskStageItem on DesignStage {
      id
      status
      currentTask {
        id
        type
        owner {
          name
        }
        nextTask {
          type
        }
      }
      tasks {
        id
      }
    }
  `,
}

export default TagCurrentTask
