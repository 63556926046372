import './index.less'

import { ErrorHandling } from '@sov/common'
import { Button, message } from 'antd'
import React from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Page, { Section } from '../../components/layout/Page'

type Props = Pick<RouteComponentProps, 'location'>

function ErrorPage({ location }: Props) {
  // 避免登入後還去 login 頁面
  if (window.location.pathname === '/login') {
    window.location.replace(`/`)
    return null
  }

  const {
    title,
    message: errorMessage,
    redirect,
  } = ErrorHandling.getErrorFromLocation(location)

  message.error(`發生錯誤，請IT協助！ERROR[${errorMessage}]`)

  return (
    <Page>
      <Section>
        <div className="pages-error">
          <div className="image-container">
            <div
              className="image"
              style={{
                backgroundImage: `url(https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg)`,
              }}
            />
          </div>
          <div className="description-container">
            <h1>{title}</h1>
            <div className="desc">{errorMessage}</div>
            <div className="actions">
              <Link to={redirect.url}>
                <Button type="primary">
                  返回
                  {redirect.name}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Section>
    </Page>
  )
}

export default ErrorPage
