import Icon from '@ant-design/icons'
import React from 'react'

function Svg() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="28" fill="black" fillOpacity="0.05" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"

        d="M41.5898 38.6615H38.1602V35.2318C38.1602 34.4544 37.5273 33.8216 36.75 33.8216C35.9727 33.8216 35.3398 34.4544 35.3398 35.2318V38.6615H31.9102C31.1328 38.6615 30.5 39.2943 30.5 40.0716C30.5 40.849 31.1328 41.4818 31.9102 41.4818H35.3398V44.9115C35.3398 45.6888 35.9727 46.3216 36.75 46.3216C37.5273 46.3216 38.1602 45.6888 38.1602 44.9115V41.4818H41.5898C42.3672 41.4818 43 40.849 43 40.0716C43 39.2904 42.3672 38.6615 41.5898 38.6615ZM36.75 20.1029C36.75 15.2591 32.832 11.3333 28 11.3333C23.168 11.3333 19.25 15.2591 19.25 20.1029C19.25 24.9466 23.168 28.8724 28 28.8724C32.832 28.8724 36.75 24.9466 36.75 20.1029ZM28 26.0521C24.7227 26.0521 22.0625 23.388 22.0625 20.1029C22.0625 16.8177 24.7227 14.1536 28 14.1536C31.2773 14.1536 33.9375 16.8177 33.9375 20.1029C33.9375 23.388 31.2773 26.0521 28 26.0521ZM36.2031 31.3177C33.8438 29.7708 31.0273 28.8724 28 28.8724C19.7148 28.8724 13 35.6029 13 43.9076H13.0039C13.0039 43.9349 13 43.9583 13 43.9857C13 44.763 13.6289 45.3958 14.4062 45.3958C15.1836 45.3958 15.8125 44.763 15.8125 43.9857C15.8125 43.9583 15.8125 43.9349 15.8086 43.9076H15.8125C15.8125 37.1615 21.2695 31.6927 28 31.6927C29.5312 31.6927 30.9961 31.974 32.3438 32.4935C33.4922 31.8216 34.8008 31.4036 36.2031 31.3177Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  )
}

const AvatarAddIcon = props => <Icon {...props} component={Svg} />

export default AvatarAddIcon
