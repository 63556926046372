import { gql } from '@apollo/client'
import { MultiLine } from '@sov/ui'
import { Divider, Row, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import type { StepCardFragment } from '../../../../graphql/types'
import InstructionTagList from '../../../components/common/InstructionTagList'

const Wrapper = styled.div`
  margin-top: 16px;
  padding-left: 8px;
`
const Offset = styled.div`
  padding-left: 8px;
`

interface StepCardProps {
  stage: StepCardFragment
}

function StepCard(props: StepCardProps) {
  const { stage } = props

  return (
    <Wrapper>
      <Typography.Title level={5}>
        Step
        {stage.serialNumber}
      </Typography.Title>
      <Offset>
        <Typography.Text type="secondary">上顎</Typography.Text>
        <MultiLine text={stage.step.upperStep} />
        <Divider style={{ margin: '4px 0' }} />
        <Typography.Text type="secondary">下顎</Typography.Text>
        <MultiLine text={stage.step.lowerStep} />
        <Row align="middle" style={{ marginTop: '4px' }}>
          <span>臨床指示：</span>
          <InstructionTagList instructionCard={stage.instructionCard} />
        </Row>
      </Offset>
    </Wrapper>
  )
}

StepCard.fragments = {
  StepCard: gql`
    fragment StepCard on DesignStage {
      id
      serialNumber
      step {
        upperStep
        lowerStep
      }
      instructionCard {
        ...InstructionTagList
      }
    }
    ${InstructionTagList.fragments.InstructionTagList}
  `,
}

export default StepCard
