import { Auth } from '@sov/common'
import type { MatcherFunction } from '@testing-library/react'
import type { SortOrder } from 'antd/lib/table/interface'
import type { UploadChangeParam } from 'antd/lib/upload'
import type { TFunction } from 'i18next'
import moment from 'moment'
import {
  either,
  identity,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  prop,
  takeLast,
} from 'ramda'

import type {
  Maybe,
  Order,
  Scalars,
  StageOrderContent,
} from '../graphql/types'
import {
  DeliveryMethod,
  StageType,
} from '../graphql/types'

export const takeLastFromFileList = pipe<
  UploadChangeParam,
  UploadChangeParam['fileList'],
  UploadChangeParam['fileList']
>(prop('fileList'), takeLast(1))

export function isEmptyOrNil(value): value is null | undefined {
  return either(isEmpty, isNil)(value)
}

/**
 * 由於目前 common 那邊還沒有一個良好的架構，引用起來太多層，先在這邊統一輸出
 * @todo 參考其他 library 的設計將 common 調整成適合的架構
 */

const utils = Auth.utils

export const entityIsEmployee = utils.entityIsEmployee
export const isRole = utils.isRole

export const isInRoles = utils.isInRoles

/**
 *
 * @param auth passport user 中的資訊
 * :內部員工並且不是業務才屬於 internal roles
 */

export const isInternalRoles = utils.isInternalRoles

export const isItemOwner = utils.isItemOwner

export const getAuthPrivileges = utils.getAuthPrivileges

export const getUserEntityIdFromAuth = utils.getUserEntityIdFromAuth

export const getUserEntityFromAuth = utils.getUserEntityFromAuth

export const getAccountIdFromAuth = utils.getAccountIdFromAuth

export const getAccountFromAuth = utils.getAccountFromAuth

export function getIsClinicNearCompany(deliveryMethod: DeliveryMethod) {
  return deliveryMethod === DeliveryMethod.Sales
}

/**
 * To find text which is broken up by multiple elements,
 * pass a function to find the text
 * Reference from: https://www.polvara.me/posts/five-things-you-didnt-know-about-testing-library/
 */
export function brokenTextMatcher(matchText: string): MatcherFunction {
  return (content, element) => {
    const hasText = (node: Element) => node.textContent === matchText
    const nodeHasText = hasText(element)
    const childrenDontHaveText = Array.from(element.children).every(
      child => !hasText(child),
    )

    return nodeHasText && childrenDontHaveText
  }
}

interface GetSerializedStageOrderContentArgs {
  stageOrderContent: StageOrderContent
  t: TFunction
}
type GetSerializedStageOrderContent = (
  args: GetSerializedStageOrderContentArgs
) => string
export const getSerializedStageOrderContent: GetSerializedStageOrderContent = (
  args,
) => {
  const { stageOrderContent, t } = args
  const stageType = stageOrderContent.stageType
  const translationType = t(`stage.type.${stageType}`)

  if (stageType === StageType.Accessory) {
    const items = stageOrderContent.items ?? []
    const accessoryCodeList = map(
      accessoryCode => t(`stage.accessoryCode.${accessoryCode}`),
      items,
    )
    const serializedAccessoryCodeList = join(',', accessoryCodeList)
    const serializedAccessoryStages = `${translationType}: ${serializedAccessoryCodeList}`

    return serializedAccessoryStages
  }

  const requiredNumber = stageOrderContent.number
  const serializedPrintAndEvalStages = `${translationType}: ${requiredNumber}`
  return serializedPrintAndEvalStages
}

interface GetSerializedStageOrderListArgs {
  stageOrder: Order['stageOrder']
  t: TFunction
}
type GetSerializedStageOrderList = (
  args: GetSerializedStageOrderListArgs
) => string
export const getSerializedStageOrderList: GetSerializedStageOrderList = (
  args,
) => {
  const { stageOrder, t } = args
  const list = map((stageOrderContent) => {
    return getSerializedStageOrderContent({ stageOrderContent, t })
  }, stageOrder)

  return list.join(', ')
}

export function getNullableDateSorter(sortOrder?: SortOrder) {
  return sortOrder
    ? (a: Maybe<Scalars['Date']>, b: Maybe<Scalars['Date']>) => {
        /** 都沒有日期排序不變 */
        if (!a && !b)
          return 0

        /** 沒有日期往後排 */
        if (!a)
          return 1

        /** 沒有日期往後排 */
        if (!b)
          return -1

        const isAEarlier = moment(a).isBefore(moment(b))
        /** 日期升冪排列（舊到新） */
        if (sortOrder === 'ascend')
          return isAEarlier ? -1 : 1

        /** 日期降冪排列（新到舊） */
        if (sortOrder === 'descend')
          return isAEarlier ? 1 : -1

        return 0
      }
    : identity
}
