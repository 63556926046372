import { useMutation } from '@apollo/client'
import { Col, Form, Row, message } from 'antd'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { createPatientMutation } from '../../../graphql/patient/mutation/create'
import type {
  CreatePatientMutation,
  CreatePatientVariables,
} from '../../../graphql/types'
import {
  AllPrivilege,
  AnglesClassification,
  CephAnalysis,
  FaceRatio,
  Gender,
  ImpactedToothInstruction,
  Instruction,
  LipsEstheticLineRelation,
  Need,
  OcculusalMuscle,
  OcculusalPlaneStatus,
  Offset,
  PatientBrand,
  PatientPaymentType,
  PatientSource,
  ResidualRootInstruction,
  SmileLine,
  ToothType,
  TreatStatus,
  UneruptedWisdomToothInstruction,
} from '../../../graphql/types'
import { authContext } from '../../context'
import { OnceButton } from '../common/button'
import type {
  FormDoctorInstructionInitialValues,
  FormGeneralInitialValues,
  FormIntraOralInitialValues,
  FormProfileInitialValues,
  FormSymptomInitialValues,
  PatientForm,
} from '../form/patient'
import {
  FormPatientBasic,
  FormPatientDoctorInstruction,
  FormPatientIntraOral,
  FormPatientPayment,
  FormPatientPhoto,
  FormPatientProfile,
  FormPatientSymptoms,
  getPayloadTransformer,
} from '../form/patient'
import BreadcrumbCreator from '../layout/BreadcrumbCreator'
import Page, { Section } from '../layout/Page'
import Title from '../layout/Title'

function CreatePatient() {
  const history = useHistory()
  const auth = useContext(authContext)
  const [form] = Form.useForm<PatientForm>()
  const [createPatient] = useMutation<
    CreatePatientMutation,
    CreatePatientVariables
  >(createPatientMutation)

  const handleSubmit = async () => {
    try {
      const fieldsValue = (await form.validateFields()) as PatientForm
      const payload = getPayloadTransformer(fieldsValue)

      await createPatient({
        variables: {
          payload,
        },
        update: async (cache, { data }) => {
          if (data) {
            message.info('已新增病患')
            history.push('/patients')
          }
        },
      })
    }
    catch (e) {
      if (e.errorFields)
        form.scrollToField(e.errorFields[0].name)
      else
        message.error(e.message, 3)
    }
  }

  const generalInitialValues: FormGeneralInitialValues = {
    gender: Gender.Male,
    treatArches: {
      upper: true,
      lower: true,
    },
    payment: {
      brand: PatientBrand.Unknown,
      source: PatientSource.Unknown,
      type: PatientPaymentType.Unknown,
      patientPrice: 0,
      base: 0,
      referral: 0,
      extension: 0,
      discount: 0,
    },
  }

  const profileInitialValues: FormProfileInitialValues = {
    profile: {
      mandibularMidlineToFacialMidline: {
        offset: Offset.Centered,
        instruction: Instruction.Idealize,
      },
      occulusalPlane: {
        occulusalPlaneStatus: OcculusalPlaneStatus.Level,
        instruction: Instruction.Idealize,
      },
      estheticLine: {
        lipsEstheticLineRelation: LipsEstheticLineRelation.In,
        instruction: Instruction.Idealize,
      },
      cephAnalysis: CephAnalysis.Normal,
      faceRatio: FaceRatio.Normal,
      occulusalMuscle: OcculusalMuscle.Normal,
      hasGummySmile: false,
      smileLine: SmileLine.Great,
      hasBuccalCorridor: true,
    },
  }

  const intraOralInitialValues: FormIntraOralInitialValues = {
    intraOral: {
      teethStatus: [],
      temporomandibularJointDisordersTreatStatus: TreatStatus.No,
      periodontalDiseaseTreatStatus: TreatStatus.No,
      residualRoot: {
        hasResidualRoot: false,
        instruction: ResidualRootInstruction.Maintain,
      },
      impactedTooth: {
        hasImpactedTooth: false,
        instruction: ImpactedToothInstruction.DontMove,
      },
      uneruptedWisdomTooth: {
        hasUneruptedWisdomTooth: false,
        instruction: UneruptedWisdomToothInstruction.DontDistalize,
      },
      maxillarMidlineToFacialMidline: {
        offset: Offset.Centered,
        instruction: Instruction.Idealize,
      },
      mandibularMidlineToMaxillarMidline: {
        offset: Offset.Centered,
        instruction: Instruction.Idealize,
      },
    },
  }

  const symptomsInitialValues: FormSymptomInitialValues = {
    symptoms: {
      leftMolarRelationship: AnglesClassification.Class1,
      rightMolarRelationship: AnglesClassification.Class1,
      anteriorCrowding: false,
      posteriorCrowding: false,
      openBite: false,
      deepBite: false,
      highCanine: false,
      anteriorCrossbite: false,
      posteriorCrossbite: false,
      bimaxillaryProtrusion: false,
      vShapeArch: false,
    },
  }

  const doctorInstructionInitialValues: FormDoctorInstructionInitialValues = {
    doctorInstruction: {
      ipr: Need.OnlyIfNeeded,
      tads: Need.OnlyIfNeeded,
      otherOrthdontalTools: Need.OnlyIfNeeded,
      functionalAppliance: Need.OnlyIfNeeded,
      maintainSpace: {
        isMaintainingSpace: false,
        note: '',
      },
      changeProsthesisToTemp: {
        need: Need.OnlyIfNeeded,
        instruction: '',
      },
      extractToothWhenNoSpace: {
        intention: Need.OnlyIfNeeded,
        toothType: ToothType.Wisdom,
      },
      note: '',
    },
  }

  return (
    <Page
      header={(
        <>
          <BreadcrumbCreator
            routes={[{ key: 'Home' }, { key: 'PatientCreate' }]}
          />
          <Title route={{ key: 'PatientCreate' }} />
        </>
      )}
    >
      <Section>
        <Row>
          <Col flex="1">
            {/* 基本資料區 */}
            <h3>基本資料</h3>
            <Form.Item label="建檔人" labelCol={{ span: 6 }}>
              {auth?.entity.name}
            </Form.Item>
            <FormPatientBasic
              form={form}
              initialValues={generalInitialValues}
            />

            {/* 付費方案區 */}
            <h3>付費方案</h3>
            <FormPatientPayment
              form={form}
              initialValues={generalInitialValues}
            />

            {/* 照片區 */}
            <h3>照片</h3>
            <FormPatientPhoto form={form} />

            {/* 顏面觀區域 */}
            <h3>顏面觀</h3>
            <FormPatientProfile
              form={form}
              initialValues={profileInitialValues}
            />

            {/* 口內觀區域 */}
            <h3>口內觀</h3>
            <FormPatientIntraOral
              form={form}
              initialValues={intraOralInitialValues}
            />

            {/* 矯正症狀區域 */}
            <h3>矯正症狀</h3>
            <FormPatientSymptoms
              form={form}
              initialValues={symptomsInitialValues}
            />

            {/* 醫生主要治療指示 */}
            <h3>醫生主要治療指示</h3>
            <FormPatientDoctorInstruction
              form={form}
              initialValues={doctorInstructionInitialValues}
            />

            <Form.Item
              wrapperCol={{ span: 12, offset: 6 }}
              style={{ marginTop: 24 }}
            >
              <OnceButton
                label="新增資料"
                onClick={handleSubmit}
                requiredPrivilege={AllPrivilege.PatientCreate}
                type="primary"
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
    </Page>
  )
}

export default CreatePatient
