import './index.less'

import { gql, useMutation, useQuery } from '@apollo/client'
import { ErrorHandling } from '@sov/common'
import { InstructionTeethInfo, InstructionUtils, Link } from '@sov/ui'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  message,
} from 'antd'
import {
  all,
  allPass,
  append,
  both,
  filter,
  find,
  findIndex,
  map,
  propEq,
  remove,
  update,
} from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import type {
  Auxiliary,
  InstructionCardInput,
  InstructionTeethInfoFragment,
  StageCardQueryInstructionCard,
  StageCardQueryQuery,
  StageCardQueryVariables,
  StageCardQuery_InstructionCard,
  UpdateStageInstructionMutation,
  UpdateStageInstructionMutationVariables,
} from '../../../../graphql/types'
import {
  AuxiliaryPosition,
  AuxiliaryType,
  ToothPosition,
} from '../../../../graphql/types'
import { OnceButton } from '../../../components/common/button'
import BreadcrumbCreator from '../../../components/layout/BreadcrumbCreator'
import Page, { Section } from '../../../components/layout/Page'
import Title from '../../../components/layout/Title'
import { useInstructionRemove } from '../../../helpers/hooks/useInstruction'
import { useRecovery } from '../../../helpers/hooks/useRecovery'
import { isEmptyOrNil } from '../../../utils'
import { AuxiliaryMenu as createAuxiliaryMenu } from './AuxiliaryMenu'
import AuxiliaryRadioGroup from './CustomRadioGroup'
import { RedoIcon, UndoIcon } from './icons'
import { DontMoothTeeth, MissingTeeth } from './icons/ToothIcon'
import InstructionCardPreviewModal from './InstructionCardPreviewModal'
import type { ModalState } from './StrippingModal'
import StrippingModal from './StrippingModal'
import { createPresetContentList } from './utils'

const { isValidAuxiliaryType, isValidStripping } = InstructionUtils
const { PatientLink, StageLink } = Link
const { Option, OptGroup } = Select

// 若符合 isNil, isEmpty 或者為 false 則回傳 true
const isFalsy = val => isEmptyOrNil(val) || !val

const { TextArea } = Input
const FormItem = Form.Item

// 找得到就從 list 移除, 找不到就回傳原本 list,
function findAndRemove(item: {
  type: AuxiliaryType
  toothPosition: ToothPosition
  auxiliaryPosition?: AuxiliaryPosition
}, list: Auxiliary[]) {
  const conditions = item.auxiliaryPosition
    ? [
        propEq('type', item.type),
        propEq('toothPosition', item.toothPosition),
        propEq('auxiliaryPosition', item.auxiliaryPosition),
      ]
    : [propEq('type', item.type), propEq('toothPosition', item.toothPosition)]
  const index = findIndex(allPass(conditions))(list)

  return index !== -1 ? remove(index, 1, list) : list
}

// 找得到就更新, 找不到就加到 list,
function addOrUpdate(item: Auxiliary, list: Auxiliary[]) {
  const index = findIndex(
    both(propEq('type', item.type), propEq('toothPosition', item.toothPosition)),
  )(list)

  return index !== -1 ? update(index, item, list) : append(item, list)
}

const stageCardQuery = gql`
  query StageCardQuery($id: ID!) {
    stage(id: $id) {
      type
      id
      __typename
      patient {
        id
        intraOral {
          teethStatus {
            position
            type
          }
        }
        ...PatientLink
      }

      ... on DesignStage {
        ...StageLink
        instructionCard {
          retrieve
          instruction
          ...InstructionTeethInfo
          ...InstructionCardPreviewModal
        }
      }
      ... on AccessoryStage {
        ...StageLink
        instructionCard {
          retrieve
          instruction
          ...InstructionTeethInfo
          ...InstructionCardPreviewModal
        }
      }
    }
  }
  ${InstructionTeethInfo.fragments.InstructionTeethInfo}
  ${InstructionCardPreviewModal.fragments.InstructionCardPreviewModal}
  ${PatientLink.fragment}
  ${StageLink.fragment}
`

const updateStageInstructionMutation = gql`
  mutation UpdateStageInstruction($id: ID!, $payload: InstructionCardInput!) {
    updateInstructionCard(id: $id, payload: $payload) {
      id
    }
  }
`

interface RouteProps {
  stageId: string
}

// 主要編輯頁面
function InstructionDetail() {
  const match = useRouteMatch<RouteProps>()
  const stageId = match.params.stageId
  const [form] = Form.useForm()
  const { getFieldValue, getFieldsValue, resetFields, setFieldsValue } = form

  const initialModal = {
    visible: false,
    toothPosition: ToothPosition.Fdi_1_1,
  }
  const instructionInputRef = useRef<HTMLInputElement>(null)
  const [modal, setModal] = useState<ModalState>(initialModal)
  const [previewModal, setPreviewModal] = useState<boolean>(false)
  const {
    state: { present: instructionItem },
    set: setInstructionItem,
    redo: handleRedo,
    undo: handleUndo,
    canUndo,
    canRedo,
    reset: handleClearRecovery,
  } = useRecovery<
    StageCardQueryInstructionCard | StageCardQuery_InstructionCard
  >({})
  const [isUpdating, setIsUpdating] = useState(false)
  const [canRemoveInstructionCard, setCanRemoveInstructionCard]
    = useState(false)
  const history = useHistory()
  const { toErrorPage } = ErrorHandling.useErrorHandling()
  const query = useQuery<StageCardQueryQuery, StageCardQueryVariables>(
    stageCardQuery,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onError: (error) => {
        toErrorPage(error.message)
      },
      onCompleted: (data) => {
        if (
          data?.stage
          && (data.stage.__typename === 'DesignStage'
          || data.stage.__typename === 'AccessoryStage')
        ) {
          if (data.stage?.instructionCard) {
            const initialInstructionItem = data.stage.instructionCard
            handleClearRecovery(initialInstructionItem)
          }
        }
        else {
          message.warning('請先新增指示卡')
          history.replace(
            `/patients/${data.stage?.patient.id}/stages/${stageId}`,
          )
        }
      },
      fetchPolicy: 'no-cache',
      variables: {
        id: stageId,
      },
    },
  )

  const { data, loading, refetch } = query
  const stage = data?.stage

  const [update] = useMutation<
    UpdateStageInstructionMutation,
    UpdateStageInstructionMutationVariables
  >(updateStageInstructionMutation)
  const { removeStageInstructionCard } = useInstructionRemove({
    onRemoved: () =>
      history.replace(`/patients/${stage?.patient.id}/stages/${stageId}`),
  })

  useEffect(() => {
    if (
      stage?.__typename === 'AccessoryStage'
      || stage?.__typename === 'DesignStage'
    ) {
      const previousInstructionCard = stage?.instructionCard
      const newInstruction = getFieldValue('instruction')
      const previousInstruction = previousInstructionCard?.instruction
      const newAuxiliaries = instructionItem?.auxiliaries
      const previousAuxiliaries = previousInstructionCard?.auxiliaries
      const newRetrieve = getFieldValue('retrieve')
      const previousRetrieve = previousInstructionCard?.retrieve
      // 只有當頁面上資料與資料庫中資料皆為空的時候才能刪除(必須清空並且儲存)
      if (
        all(isFalsy, [
          newInstruction,
          previousInstruction,
          newAuxiliaries,
          previousAuxiliaries,
          newRetrieve,
          previousRetrieve,
        ])
      )
        setCanRemoveInstructionCard(true)
      else
        setCanRemoveInstructionCard(false)
    }
  }, [
    instructionItem?.auxiliaries,
    getFieldValue('instruction'),
    getFieldValue('retrieve'),
    (stage?.__typename === 'AccessoryStage'
    || stage?.__typename === 'DesignStage')
    && stage?.instructionCard,
  ])
  if (
    stage?.__typename !== 'AccessoryStage'
    && stage?.__typename !== 'DesignStage'
  )
    return null
  if (loading)
    return <Page loading />

  if (!stage)
    return null

  const handleOpenModal = (toothPosition: ToothPosition) => {
    setModal({
      visible: true,
      toothPosition,
    })
  }

  const handleCloseModal = () => {
    setModal(state => ({
      ...state,
      visible: false,
    }))
  }

  const getInstructinoPreview = () => {
    if (instructionItem) {
      const { retrieve, instruction } = getFieldsValue()
      const preview = {
        ...instructionItem,
        auxiliaries: instructionItem.auxiliaries ?? [],
        stepCode: instructionItem.stepCode,
        instruction,
        retrieve,
      }
      return preview
    }
    return undefined
  }
  // 更新工單
  const handleUpdate = async () => {
    try {
      if (instructionItem) {
        setIsUpdating(true)
        const { instruction, teethStatus, retrieve } = getFieldsValue()
        const payload: InstructionCardInput = {
          auxiliaries: instructionItem.auxiliaries ?? [],
          instruction,
          teethStatus,
          retrieve,
        }

        await update({
          variables: {
            id: stageId,
            payload,
          },
          update: async (cache, { data }) => {
            if (data)
              message.info('已更新指示卡')
          },
        })
        refetch()
        setIsUpdating(false)
      }
    }
    catch (errors) {
      setIsUpdating(false)
      message.error(errors[0].message)
    }
  }

  // 移除指示卡
  const handleRemoveInstructionCard = async () => {
    try {
      if (canRemoveInstructionCard) {
        setIsUpdating(true)

        await removeStageInstructionCard(stageId)
        setIsUpdating(false)
      }
    }
    catch (errors) {
      setIsUpdating(false)
      message.error(errors[0].message)
    }
  }

  // 回復成預設值
  const handleReset = async () => {
    await refetch()
    resetFields()
  }

  // 清空當前指示卡
  const handleClear = () => {
    if (instructionItem) {
      setInstructionItem({ ...instructionItem, auxiliaries: [] })
      setFieldsValue({ instruction: '' })
    }
  }

  // 階段牙位資訊更新為病患牙位資訊
  const handleTeethStatusUpdate = () => {
    const latestPatientTeethStatus = stage.patient.intraOral.teethStatus
    if (instructionItem) {
      const updatedInstructionItem = {
        ...instructionItem,
        teethStatus: latestPatientTeethStatus,
      }
      setInstructionItem(updatedInstructionItem)
    }
    const updatedForm = { teethStatus: latestPatientTeethStatus }
    setFieldsValue(updatedForm)
  }

  const getPositionAuxiliary = (
    type: AuxiliaryType,
    position: ToothPosition,
    instructionItem: InstructionTeethInfoFragment | undefined,
  ) => {
    if (!instructionItem)
      return undefined

    return find(
      item => item.type === type && item.toothPosition === position,
      instructionItem.auxiliaries,
    )
  }

  const instructionHasAuxiliary = (
    type: AuxiliaryType,
    position: ToothPosition,
    instructionItem: InstructionTeethInfoFragment | undefined,
  ) => {
    const auxiliaryItem = getPositionAuxiliary(type, position, instructionItem)
    return Boolean(auxiliaryItem)
  }

  // 新增 stripping
  const handleAddStripping = (item: Auxiliary) => {
    // 檢查 stripping 是否重疊，有的話則跳出警告
    if (
      !isValidStripping(
        filter(
          auxiliary =>
            auxiliary.type === AuxiliaryType.StrippingAll
            || auxiliary.type === AuxiliaryType.StrippingHalf,
          instructionItem?.auxiliaries ?? [],
        ),
        item,
      )
    ) {
      message.warning(`有衝突, 不可新增 Stripping!`)
      return
    }
    if (instructionItem) {
      setInstructionItem({
        ...instructionItem,
        auxiliaries: [...instructionItem.auxiliaries, item],
      })
    }
    handleCloseModal()
  }

  const handleRemoveStripping = (item: Auxiliary) => {
    const type = getFieldValue('type')
    if (
      instructionItem
      && isValidAuxiliaryType(type, [
        AuxiliaryType.StrippingAll,
        AuxiliaryType.StrippingHalf,
      ])
    ) {
      setInstructionItem({
        ...instructionItem,
        auxiliaries: findAndRemove(item, instructionItem.auxiliaries),
      })
    }
  }

  // 直接新增 auxiliary
  const handleAddAuxiliary = (
    toothPosition: ToothPosition,
    auxiliaryPosition: AuxiliaryPosition,
  ) => {
    const type = getFieldValue('type')
    const basicAuxiliary = {
      type,
      toothPosition,
      auxiliaryPosition,
    }

    switch (type) {
      case AuxiliaryType.Attachment:
      case AuxiliaryType.Button:
      case AuxiliaryType.Tad: {
        if (instructionItem) {
          setInstructionItem({
            ...instructionItem,
            auxiliaries: addOrUpdate(
              basicAuxiliary,
              instructionItem.auxiliaries,
            ),
          })
        }
        break
      }
    }
  }

  // 點擊牙齒 跳出 modal 以供新增 auxiliary
  const handleClickTooth = (toothPosition: ToothPosition) => {
    const type = getFieldValue('type')
    switch (type) {
      case AuxiliaryType.Attachment:
      case AuxiliaryType.Tad: {
        if (!instructionHasAuxiliary(type, toothPosition, instructionItem)) {
          // 預設是直接新增在位置「一般」
          handleAddAuxiliary(toothPosition, AuxiliaryPosition.Center)
        }
        break
      }
      case AuxiliaryType.Button: {
        if (!instructionHasAuxiliary(type, toothPosition, instructionItem)) {
          // button 預設是直接新增在位置「齒頸」
          handleAddAuxiliary(toothPosition, AuxiliaryPosition.Cervical)
        }
        break
      }
      case AuxiliaryType.StrippingAll:
      case AuxiliaryType.StrippingHalf:
        handleOpenModal(toothPosition)
        break
    }
  }

  const handleRemoveAuxiliary = (toothPosition: ToothPosition) => {
    const type = getFieldValue('type')
    const removeAuxiliary = {
      type,
      toothPosition,
    }

    if (instructionItem) {
      setInstructionItem({
        ...instructionItem,
        auxiliaries: findAndRemove(
          removeAuxiliary,
          instructionItem.auxiliaries,
        ),
      })
    }
  }

  const handleSetPresetContent = (content) => {
    const prevContent = form.getFieldValue('instruction')
    form.setFieldsValue({ instruction: prevContent + content })
    const instructionInputElement = instructionInputRef.current
    if (instructionInputElement) {
      instructionInputElement.focus()
      instructionInputElement.scrollTop = instructionInputElement.scrollHeight
    }
  }

  /**
   * @function renderAuxiliaryMenu
   * @param position
   *
   * 將handler跟item先closure
   * 最後會是(position)=>Jsx.element|null
   */
  const renderAuxiliaryMenu = createAuxiliaryMenu({
    getFieldValue,
    getPositionAuxiliary,
    instructionItem,
    handleAddAuxiliary,
    handleRemoveAuxiliary,
  })

  const initialValues = {
    retrieve: stage.instructionCard?.retrieve,
    instruction: stage.instructionCard?.instruction,
    teethStatus: stage.instructionCard?.teethStatus,
  }

  const presetContentList = createPresetContentList()

  return (
    <Page
      contentStyle={{
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
      }}
      header={(
        <>
          <Row align="middle">
            <div style={{ flex: 1 }}>
              <BreadcrumbCreator
                routes={[{ key: 'Home' }, { key: 'StageInstruction' }]}
              />
              <Title
                route={{
                  key: 'StageInstruction',
                  render: () => (
                    <>
                      <PatientLink
                        item={stage?.patient}
                        style={{ color: 'black' }}
                      />
                      <StageLink
                        item={stage}
                        style={{ color: 'black', marginLeft: 16 }}
                      />
                    </>
                  ),
                }}
              />
            </div>
            <Button
              type="default"
              onClick={() => setPreviewModal(true)}
              style={{ marginRight: 16 }}
            >
              預覽
            </Button>
            <OnceButton
              label="儲存更新"
              type="primary"
              onClick={handleUpdate}
              style={{ marginRight: 16 }}
              loading={isUpdating}
            />
            {/* 用 inline style 無法吃到，需要用寫在 css 裡面 */}
            <Tooltip
              title={
                canRemoveInstructionCard
                  ? ''
                  : '請先清空指示卡內容並儲存，再進行刪除'
              }
              placement="bottomRight"
            >
              <Button
                danger
                onClick={handleRemoveInstructionCard}
                loading={isUpdating}
                disabled={!canRemoveInstructionCard}
              >
                刪除
              </Button>
            </Tooltip>
            <InstructionCardPreviewModal
              onCancel={() => setPreviewModal(false)}
              visible={previewModal}
              instructionItem={getInstructinoPreview()}
            />
          </Row>
        </>
      )}
    >
      <Section>
        <Row>
          <Col flex={1}>
            <Form
              layout="vertical"
              form={form}
              className="form-container"
              initialValues={initialValues}
            >
              <FormItem name="retrieve" valuePropName="checked">
                <Checkbox>需重取模</Checkbox>
              </FormItem>

              <FormItem label="指示卡模板">
                <Select
                  onChange={(value) => {
                    handleSetPresetContent(value)
                  }}
                >
                  {map(
                    category => (
                      <OptGroup label={category.title}>
                        {map(
                          content => (
                            <Option key={content.title} value={content.value}>
                              {content.title}
                            </Option>
                          ),
                          category.option,
                        )}
                      </OptGroup>
                    ),
                    presetContentList,
                  )}
                </Select>
              </FormItem>
              <FormItem label="指示文字" name="instruction">
                <TextArea
                  size="large"
                  rows={5}
                  ref={instructionInputRef as any}
                />
              </FormItem>
              {/** 必須要有FormItem 才能在 setFieldsValue 之後透過 getFieldsValues 取得該欄位的值 */}
              <FormItem hidden name="teethStatus" />
            </Form>
          </Col>
          <Col flex={1}>
            <div className="pages-stage-stagecard">
              <div className="tool-bar">
                <div className="control">
                  <div style={{ margin: '0px 16px' }} onClick={handleReset}>
                    回復初始值
                  </div>
                  <div style={{ marginRight: '16px' }} onClick={handleClear}>
                    全部清空
                  </div>
                  <div
                    style={{ marginRight: '16px' }}
                    onClick={handleTeethStatusUpdate}
                  >
                    牙位資訊更新
                  </div>
                  <Tooltip title="上一步" placement="bottom">
                    <UndoIcon
                      className={
                        canUndo ? 'recovery-icon' : 'recovery-icon-disabled'
                      }
                      style={{ marginRight: '16px' }}
                      onClick={handleUndo}
                    />
                  </Tooltip>
                  <Tooltip title="下一步" placement="bottom">
                    <RedoIcon
                      className={
                        canRedo ? 'recovery-icon' : 'recovery-icon-disabled'
                      }
                      style={{ marginRight: '16px' }}
                      onClick={handleRedo}
                    />
                  </Tooltip>
                </div>
                <Form
                  form={form}
                  initialValues={{ type: AuxiliaryType.Attachment }}
                >
                  <FormItem name="type">
                    <AuxiliaryRadioGroup className="auxiliary-input" />
                  </FormItem>
                </Form>

                <div style={{ margin: 'auto 0', color: 'red' }}>
                  （暫時使用右鍵點擊牙位，以開啟位置選單）
                </div>
              </div>
              <div className="main-canvas">
                <div className="card-container">
                  {instructionItem && (
                    <div className="svg-container">
                      <InstructionTeethInfo
                        renderMenu={renderAuxiliaryMenu}
                        instructionItem={instructionItem}
                        handleClickTooth={handleClickTooth}
                        handleRemoveStripping={handleRemoveStripping}
                        editType={getFieldValue('type')}
                      />
                    </div>
                  )}
                  <div className="card-footer">
                    圖形提示：
                    <DontMoothTeeth />
                    {`Don't Move Teeth`}
                    <MissingTeeth />
                    Missing Teeth
                  </div>
                  <StrippingModal
                    form={form}
                    modalState={modal}
                    handleAddStripping={handleAddStripping}
                    handleCloseModal={handleCloseModal}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    </Page>
  )
}

export { InstructionDetail }

export default InstructionDetail
