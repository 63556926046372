import './style.less'

import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import type { UploadFileStatus } from 'antd/lib/upload/interface'
import { filter, find, isNil, map, propEq, sort, split, values } from 'ramda'
import React from 'react'

import { JawPosition, ToothPosition } from '../../../../graphql/types'

const getQuadrant = (position: ToothPosition) => split('_', position)[1]
const getIndex = (position: ToothPosition) => split('_', position)[2]

const CheckIcon = <CheckOutlined style={{ color: '#52c41a' }} />
const LoadingIcon = <LoadingOutlined />

const upperList = [
  ...sort<ToothPosition>(
    (a, b) => (getIndex(a) > getIndex(b) ? -1 : 1),
    filter(position => getQuadrant(position) === '1', values(ToothPosition)),
  ),
  ...filter(position => getQuadrant(position) === '2', values(ToothPosition)),
]
const lowerList = [
  ...sort<ToothPosition>(
    (a, b) => (getIndex(a) > getIndex(b) ? -1 : 1),
    filter(position => getQuadrant(position) === '4', values(ToothPosition)),
  ),
  ...filter(position => getQuadrant(position) === '3', values(ToothPosition)),
]

interface File {
  position: ToothPosition | JawPosition
  status?: UploadFileStatus
}

interface ViewerFilesOverviewProps {
  files?: File[]
  handleClickItem?: (position: ToothPosition | JawPosition) => void
}

/**
 * +----+----+----+----+----+----+----+----+----+----+----+----+----+----+----+----+-------+
 * | 18 | 17 | 16 | 15 | 14 | 13 | 12 | 11 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | Upper |
 * +----+----+----+----+----+----+----+----+----+----+----+----+----+----+----+----+-------+
 * | 48 | 47 | 46 | 45 | 44 | 43 | 42 | 41 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | Lower |
 * +----+----+----+----+----+----+----+----+----+----+----+----+----+----+----+----+-------+
 */
function ViewerFilesTable(props: ViewerFilesOverviewProps) {
  const { files, handleClickItem } = props
  const clickable = !isNil(handleClickItem)

  const getStatusContent = (position: ToothPosition | JawPosition) => {
    const file = find(propEq('position', position), files || [])

    if (isNil(file))
      return null

    return file.status === 'done' ? CheckIcon : LoadingIcon
  }

  return (
    <>
      <div className="row">
        {map(
          position => (
            <div
              key={position}
              className={`item ${clickable ? 'clickable' : ''}`}
              onClick={() => handleClickItem && handleClickItem(position)}
            >
              <div className="position">
                {`${getQuadrant(position)}${getIndex(
                position,
              )}`}
              </div>
              <div className="status">{getStatusContent(position)}</div>
            </div>
          ),
          upperList,
        )}
        <div
          key={JawPosition.Upper}
          className={`item ${clickable ? 'clickable' : ''}`}
          onClick={() => handleClickItem && handleClickItem(JawPosition.Upper)}
        >
          <div className="position">上牙肉</div>
          <div className="status">{getStatusContent(JawPosition.Upper)}</div>
        </div>
      </div>
      <div className="row">
        {map(
          position => (
            <div
              key={position}
              className={`item ${clickable ? 'clickable' : ''}`}
              onClick={() => handleClickItem && handleClickItem(position)}
            >
              <div className="position">
                {`${getQuadrant(position)}${getIndex(
                position,
              )}`}
              </div>
              <div className="status">{getStatusContent(position)}</div>
            </div>
          ),
          lowerList,
        )}
        <div
          key={JawPosition.Lower}
          className={`item ${clickable ? 'clickable' : ''}`}
          onClick={() => handleClickItem && handleClickItem(JawPosition.Lower)}
        >
          <div className="position">下牙肉</div>
          <div className="status">{getStatusContent(JawPosition.Lower)}</div>
        </div>
      </div>
    </>
  )
}

export default ViewerFilesTable
