import { gql } from '@apollo/client'

import { LeaveInfoFragment } from '../fragment'

export const createLeaveMutation = gql`
  mutation CreateLeave($payload: CreateLeaveInput!) {
    createLeave(payload: $payload) {
      ...LeaveInfo
    }
  }
  ${LeaveInfoFragment}
`
