import { gql } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'

import type { ClinicLinkFragment } from '../../codegen/types'

interface Props {
  item: ClinicLinkFragment
  to?: string
  target?: string
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

function ClinicLink(props: Props) {
  const { item, target = '_self', to, className, style, children } = props

  return (
    <Link
      className={className}
      target={target}
      style={style}
      to={to || `/clinics/${item.id}`}
    >
      {children || item.name}
    </Link>
  )
}

ClinicLink.fragment = gql`
  fragment ClinicLink on Clinic {
    id
    name
  }
`

export default ClinicLink
