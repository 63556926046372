import { gql } from '@apollo/client'

import { LeaveInfoFragment } from '../fragment'

export const leavesQuery = gql`
  query LeavesQuery(
    $query: LeavesQuery = {}
    $page: Int = 1
    $limit: Int = 100
    $sort: String = "-startDate"
  ) {
    leaves(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        ...LeaveInfo
      }
      total
      limit
      page
    }
  }
  ${LeaveInfoFragment}
`
