import React from 'react'
import { Color, Vector3 } from 'three'

function Lights() {
  const directionalLights = [
    {
      color: new Color(0x777777),
      position: new Vector3(1, 0, 0).normalize(),
    },
    {
      color: new Color(0x777777),
      position: new Vector3(-1, 0, 0).normalize(),
    },
    {
      color: new Color(0x333333),
      position: new Vector3(0, 1, 0).normalize(),
    },
    {
      color: new Color(0x707070),
      position: new Vector3(0, -1, 0).normalize(),
    },
    {
      color: new Color(0x4A4A4A),
      position: new Vector3(0, 0, 1).normalize(),
    },
    {
      color: new Color(0x4A4A4A),
      position: new Vector3(0, 0, -1).normalize(),
    },
  ]

  return (
    <>
      <spotLight
        color={new Color(0x888888)}
        position={new Vector3(0, 0, 100)}
      />
      <spotLight
        color={new Color(0x666666)}
        position={new Vector3(0, 0, -500)}
      />
      <spotLight
        color={new Color(0x2A2A2A)}
        position={new Vector3(0, 500, 0)}
      />
      {directionalLights.map((light, number) => {
        return (
          <directionalLight
            position={light.position}
            color={light.color}
            key={number}
          />
        )
      })}
    </>
  )
}

export default Lights
