import type { Patient } from '@sov/ui/src/codegen/types'
import React from 'react'
import styled from 'styled-components'

import chatIcon from './images/chat.png'

const Container = styled.div`
  text-align: center;
`

const Text = styled.div`
  margin-top: 24px;
  font-family: PingFangTC;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`

interface Props {
  patient: Pick<Patient, 'name'>
  width?: string | number
}
export function EmptyConversation(props: Props) {
  const { patient, width = '90px' } = props
  return (
    <Container>
      <img src={chatIcon} width={width} />
      <Text>
        您好，這是舒服美與您討論病患
        {patient.name}
        {' '}
        的病情討論室。
      </Text>
      <Text>
        如果您有任何問題，請留言詢問，
        <br />
        {' '}
        我們會在週一至週五
        <br />
        9:30 - 18:30 上班時間中，儘速回覆您。
      </Text>
    </Container>
  )
}
